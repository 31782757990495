import { useState } from "react"
import { useApi } from "./api"

export const useGetUser = () => {
  const { authAdmin } = useApi()
  const getter = authAdmin("/users").method("get").create()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>()
  const getMany = async (params: { userIds: string[] }) => {
    try {
      setLoading(true)
      const result = await getter({
        userIds: params.userIds,
      })
      return result.data
    } catch (err) {
      setError(err)
    } finally {
      setLoading(true)
    }
  }

  return {
    loading,
    error,
    getMany,
  }
}

export const useGetManyUsersWithEmail = () => {
  const { authAdmin } = useApi()
  const getter = authAdmin("/users-with-email").method("get").create()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>()
  const getManyUsersWithEmail = async (params: { userIds: string[] }) => {
    try {
      setLoading(true)
      const result = await getter({
        userIds: params.userIds,
      })
      return result.data
    } catch (err) {
      setError(err)
    } finally {
      setLoading(true)
    }
  }

  return {
    loading,
    error,
    getManyUsersWithEmail,
  }
}
