import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { genNewId } from "@app/model"
import {
  recommendedShopFormValidator,
  ShopForm,
  ShopInput,
} from "@app/model/homepage/shop"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputImagePicker, useFormHelper } from "@reeeed-mp/ui-common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { upsertById } from "../helper"
import { useHomeManagementStore } from "../store/ctx"

export const EditorRecommendedShop: React.FC = () => {
  const { t } = useTranslation()
  const { t: shopT } = useTranslation("translation", {
    keyPrefix: "homepage-management.recommended-shop",
  })
  const navigate = useNavigate()
  const { uploadFile } = useUploadFile()
  const [loading, setLoading] = useState(false)
  const { notificationStore } = useStore()
  const params = useParams()
  const homeManagementStore = useHomeManagementStore()

  const onSubmit = async (value: ShopForm) => {
    const newShops = upsertById(
      {
        name: value.name,
        url: value.link,
        id: value.id ? value.id : genNewId(),
        ...(value.cover?.fileInput
          ? { image: value.cover?.fileInput?.tmpPath }
          : {}),
        downloadUrl: value.cover?.downloadUrl,
      },
      homeManagementStore.homepage?.recommendShopSection?.shops,
    )
    homeManagementStore.update("recommendShopSection", {
      shops: newShops,
    })

    return {
      id: "",
    }
  }

  const shop: ShopInput | undefined =
    homeManagementStore.homepage?.recommendShopSection?.shops?.find(
      (s) => s.id === params.id,
    )

  const initialValues = {
    id: shop?.id,
    name: shop?.name,
    link: shop?.url,
    cover: shop?.image
      ? {
          downloadUrl: shop.image,
          fileInput: undefined,
        }
      : undefined,
    downloadUrl: shop?.downloadUrl ?? shop?.image,
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<ShopForm, { id: string | undefined }>({
    id: params.id && params.id !== "create" ? params.id : undefined,
    initialValues,
    onGetItem: async (id) => {
      const shop: ShopInput | undefined =
        homeManagementStore.homepage?.recommendShopSection?.shops?.find(
          (s) => s.id === id,
        )
      return {
        id: shop?.id,
        name: shop?.name,
        link: shop?.url,
        cover: shop?.image
          ? {
              downloadUrl: shop.image,
              fileInput: undefined,
            }
          : undefined,
        downloadUrl: shop?.downloadUrl ?? shop?.image,
      }
    },
    onError: () => {},
    onSubmit,
    onSubmitted: () => {
      navigate("/home-management/recommended-shop")
    },
    validationSchema: recommendedShopFormValidator,
  })

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      <div className="mb-10">
        <NavigationHeaderButton
          title={form.id ? shopT("edit-shop") : shopT("add-shop")}
          onClick={() => {
            navigate("/home-management/recommended-shop")
          }}
        />
      </div>
      {
        <>
          <FormInputImagePicker
            isInvalid={!!errors.cover}
            disabled={loading}
            css={css`
              width: 256px !important;
              height: 256px !important;
              .euiFilePicker__promptText {
                width: 256px;
              }
              .euiImage {
                width: 256px !important;
                height: 256px !important;
                border-radius: 6px !important;
              }
              .euiImage__img {
                border-radius: 6px !important;
              }
            `}
            placeholder={
              <div className="flex flex-col text-center  ">
                <div>
                  <p className="mb-0">{shopT("shop-logo")}</p>
                </div>
                <div>
                  <p className="mb-0 whitespace-pre-wrap   text-center text-sm">
                    {shopT("cover-suggestion", {
                      size: "256x256 px",
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            accept={`${contentType.JPG}, ${contentType.PNG}`}
            value={form?.downloadUrl || undefined}
            onChange={async (v: File | null) => {
              if (v) {
                try {
                  setLoading(true)
                  const { input, blob } = await uploadFile(v)
                  setValue("cover.downloadUrl", blob)
                  setValue("cover.fileInput", input)
                  setValue("downloadUrl", blob)
                } catch (err: any) {
                  if (err && err.message) {
                    notificationStore.add({
                      title: "Error",
                      color: "danger",
                      text: err.message,
                    })
                  }
                } finally {
                  setLoading(false)
                }
              }
            }}
          />
          <EuiFormRow
            isInvalid={!!errors.name}
            className="mt-6"
            label={t("common.name")}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              isInvalid={!!errors.name}
              value={form?.name}
              onChange={(e) => {
                setValue("name", e.target.value)
              }}
            />
          </EuiFormRow>
          <EuiFormRow
            isInvalid={!!errors.link}
            className="mt-6"
            label={t("common.link")}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              isInvalid={!!errors.link}
              value={form?.link}
              onChange={(e) => {
                setValue("link", e.target.value)
              }}
            />
          </EuiFormRow>
          <div className="mt-10 flex flex-row">
            <EuiButton
              fill
              css={css`
                width: 255px;
                height: 40px;
              `}
              disabled={loading}
              onClick={() => {
                submit()
              }}
            >
              {t("common.save")}
            </EuiButton>
            <EuiButtonEmpty
              css={css`
                width: 255px;
                height: 40px;
              `}
              className="ml-4 items-center"
              color="text"
              onClick={() => {
                navigate("/home-management/recommended-shop")
              }}
            >
              {t("common.cancel")}
            </EuiButtonEmpty>
          </div>
        </>
      }
    </div>
  )
}
