export enum ErrMsg {
  UN_AUTH = "un-auth",
  UNEXPECT_PAYMENT_CHANNEL = "unexpect-payment-channel",
  UNSUPPORT_DIFF_PAYMENT_CHANNEL = "unsupport-diff-payment-channel",
}

export class AppErr extends Error {
  constructor(
    msg: ErrMsg,
    public detail?: string,
  ) {
    super(msg)
  }
}

export const errUnAuth = new AppErr(ErrMsg.UN_AUTH)

export const errUnExpectPaymentChannel = (channel: string) =>
  new AppErr(ErrMsg.UNEXPECT_PAYMENT_CHANNEL, channel)

export const errUnsupportDiffPaymentChannel = new AppErr(
  ErrMsg.UNSUPPORT_DIFF_PAYMENT_CHANNEL,
)
