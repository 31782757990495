import { EuiButtonIcon } from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export const TitleAndButtonGroup = () => {
  const { t } = useTranslation()

  return (
    <div className="mb-4 flex justify-between">
      <PageTittleWithBackButton tittleText={t("product.product-information")} />
      <div className="flex space-x-2"></div>
    </div>
  )
}

const PageTittleWithBackButton: React.FC<{
  tittleText: string
  onBackButtonClickNavigate?: () => void
  width?: number
}> = ({ tittleText, onBackButtonClickNavigate, width }) => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-center space-x-4">
      <EuiButtonIcon
        iconType="arrowLeft"
        color="text"
        className="scale-125"
        onClick={() => {
          if (onBackButtonClickNavigate) {
            onBackButtonClickNavigate()
          } else {
            navigate(-1)
          }
        }}
      />
      <div
        className={`overflow-hidden overflow-ellipsis text-xl font-bold w-[${
          width || 400
        }px] h-fit`}
      >
        {tittleText}
      </div>
    </div>
  )
}
