import { definitions } from "@app/vendor/shop-specs/shop_service"
import { useTranslation } from "react-i18next"
import { useGetBankAccountType, useGetBankNameFromBankCode } from "../hook"
import { WithdrawAccountCard } from "./card"
import { WithdrawAccountText } from "./text"
import React from "react"

export const BankAccountWithdrawAccountCard = (
  bankAccount: definitions["shop.v1.BankAccount"],
) => {
  const { t: SellerT } = useTranslation("translation", {
    keyPrefix: "seller-management",
  })

  const bankName = useGetBankNameFromBankCode(
    bankAccount.bankCode ?? "BANK_CODE_UNSPECIFIED",
  )

  const bankAccountType = useGetBankAccountType(
    bankAccount.bankAccountType ?? "BANK_ACCOUNT_TYPE_UNSPECIFIED",
  )

  return (
    <WithdrawAccountCard
      section={[
        {
          title: SellerT("withdraw-channel"),
          data: [
            {
              label: SellerT("account-name"),
              value: <WithdrawAccountText text={bankAccount.ownerName ?? ""} />,
            },
            {
              label: SellerT("bank-account-number"),
              value: (
                <WithdrawAccountText text={bankAccount.accountNumber ?? ""} />
              ),
            },
            {
              label: SellerT("bank"),
              value: <WithdrawAccountText text={bankName} />,
            },
            {
              label: SellerT("bank-account-type"),
              value: <WithdrawAccountText text={bankAccountType} />,
            },
          ],
        },
      ]}
    />
  )
}
