import { useApi } from "@app/hook/api"
import { useCallback } from "react"
import useSWR from "swr"

export const useGetNovel = (shopId: string, productId: string) => {
  const { webBff } = useApi()

  return useSWR("/admin/shop/novel", async () => {
    const res = await webBff("/admin/shop/novel/{productId}")
      .method("get")
      .create()({
      productId,
      shopId,
    })
    return res.data
  })
}

export const useGetEBookAudio = (shopId: string, productId: string) => {
  const { webBff } = useApi()

  return useSWR("/admin/shop/e-book-audio", async () => {
    const res = await webBff("/admin/shop/e-book-audio/{productId}")
      .method("get")
      .create()({
      productId,
      shopId,
    })
    return res.data
  })
}

export const useGetPhysicalProduct = (shopId: string, productId: string) => {
  const { webBff } = useApi()

  return useSWR("/admin/shop/physical-product", async () => {
    const res = await webBff("/admin/shop/physical-product/{productId}")
      .method("get")
      .create()({
      productId,
      shopId,
    })
    return res.data
  })
}

export const useSetBanEBookAudio = () => {
  const { shop } = useApi()
  const setBan = useCallback(
    async (productId: string, isBanned: boolean) => {
      const { data } = await shop("/ban/e-book-audio/{id}")
        .method("post")
        .create()({
        id: productId,
        isBanned,
      })
      return data
    },
    [shop],
  )

  return { setBan }
}

export const useGetEVoucher = (shopId: string, productId: string) => {
  const { webBff } = useApi()

  return useSWR(["/admin/shop/e-voucher", productId], async () => {
    const res = await webBff("/admin/shop/e-voucher/{productId}")
      .method("get")
      .create()({
      productId,
      shopId,
    })
    return res.data
  })
}
