import { TextEditor } from "@app/component"
import { UploadItemSection } from "@app/component/upload-and-render"
import { publicConfig } from "@app/config"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { mappingProductTypeInputToShopProductType } from "@app/model/product-table"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { definitions as webbffDefinitions } from "@app/vendor/web-bff-specs/web_bff_service"
import {
  CriteriaWithPagination,
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiText,
  EuiToolTip,
  Pagination,
} from "@elastic/eui"
import { css } from "@emotion/react"
import {
  ToolbarPlugins,
  parseEditorState,
  stringifiedEmptyEditorState,
  stringifyEditorStateOrThrow,
} from "@reeeed-mp/text-editor"
import "@reeeed-mp/text-editor/dist/styles.css"
import { FormInputCropImage, useFormHelper } from "@reeeed-mp/ui-common"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { read as readXlsx, utils as utilsXlsx, writeFile } from "xlsx"
import * as Yup from "yup"
import {
  useGetLandingPage,
  useGetLandingPageProducts,
  useUpsertLandingPage,
} from "./hooks"
import { extendProductsToProductsTable } from "./hooks/table"
import {
  LandingPageForm,
  LandingPageProduct,
  ProductKeys as ProductKey,
  ProductRef,
  UpsertLandingPageRequest,
} from "./type"

export const EditorLandingPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params = useParams()
  const { uploadFile } = useUploadFile()
  const { notificationStore: notification } = useStore()
  const isCreatePage = params.id === "create"

  const [products, setProducts] = useState<LandingPageProduct[]>()
  const [errorIDs, setErrorIDs] = useState<string[]>([])
  const [rawProducts, setRawProducts] = useState<ProductRef[]>([])

  const upsertLandingPage = useUpsertLandingPage()
  const getLandingPage = useGetLandingPage()
  const getLandingPageProducts = useGetLandingPageProducts()

  const exportProduct = async () => {
    if (rawProducts) {
      const worksheetProduct = utilsXlsx.json_to_sheet(rawProducts)
      const workbook = utilsXlsx.book_new()
      utilsXlsx.book_append_sheet(
        workbook,
        worksheetProduct,
        "LandingPageProduct",
      )
      utilsXlsx.sheet_add_aoa(worksheetProduct, [["id", "type"]], {
        origin: "A1",
      })
      writeFile(workbook, "landing-page-product.xlsx")
    }
  }

  const filePickerRef = useRef<any>()

  const handleUploadProducts = async (files: FileList | null) => {
    const existRawProduct: ProductRef[] = []
    const productKeys: ProductKey[] = []
    setErrorIDs([])
    if (files && files[0]) {
      const result = await files[0].arrayBuffer()
      const workbook = readXlsx(result)
      const wsname = workbook.SheetNames[0]
      const ws = workbook.Sheets[wsname]
      existRawProduct.push(...(utilsXlsx.sheet_to_json(ws) as ProductRef[]))
      existRawProduct.forEach(({ id, type }) => {
        if (
          type !== "e-book-audio" &&
          type !== "novel" &&
          type !== "physical-product" &&
          type !== "e-voucher"
        ) {
          setErrorIDs((e) => [...e, id])
        } else {
          const productType = mappingProductTypeInputToShopProductType(type)
          if (productKeys.every((productKey) => id !== productKey.id)) {
            productKeys.push({ id, type: productType })
          }
        }
      })
    }
    setValue("products", productKeys)
    const landingPageProducts = await getLandingPageProducts(
      productKeys.slice(0, pageSize),
    )

    const compareErrorsIDs = compareProductsErrorsIDs(
      landingPageProducts?.products,
      productKeys,
    )

    if (compareErrorsIDs && compareErrorsIDs.length > 0) {
      setErrorIDs((e) => [...e, ...compareErrorsIDs])
      return
    }

    const mapping = extendProductsToProductsTable(
      landingPageProducts?.products ?? [],
    )

    setErrorIDs((e) => [...e, ...mapping.errorIDs])
    setProducts(mapping.extendsProducts)
    setRawProducts(existRawProduct)
    if (filePickerRef && filePickerRef.current) {
      filePickerRef?.current?.removeFiles()
    }
  }

  const compareProductsErrorsIDs = (
    products?: webbffDefinitions["webbff.admin.v1.ExtendProduct"][],
    productKey?: ProductKey[],
  ): string[] => {
    const errorsIDs: string[] = []
    products?.map((p, idx) => {
      if (!p.product && productKey?.[idx]) {
        return errorsIDs.push(productKey?.[idx]?.id ?? "")
      }
    })

    return errorsIDs
  }

  const pageSize = 60
  const frontEndMaxWidth = 832

  const formFuncHandlers = () => ({
    id: params.id === "create" ? undefined : params.id,
    initialValues: {
      title: "",
      slug: "",
      cover: undefined,
      products: [],
      detail:
        params.id === "create"
          ? parseEditorState(stringifiedEmptyEditorState)
          : undefined,
      seoText:
        params.id === "create"
          ? parseEditorState(stringifiedEmptyEditorState)
          : undefined,
    } as LandingPageForm,
    onGetItem: async (id: string) => {
      const formValue = {
        title: "",
        slug: "",
        cover: { name: "", downloadUrl: "", fileInput: undefined },
        products: [] as definitions["webutility.v1.LandingPageProductKey"][],
        detail: parseEditorState(stringifiedEmptyEditorState),
        seoText: parseEditorState(stringifiedEmptyEditorState),
      }

      if (id) {
        const lp = await getLandingPage(id)
        formValue.title = lp.landingPage?.title || ""
        formValue.slug = lp.landingPage?.slug || ""
        formValue.cover.downloadUrl = lp.landingPage?.image || ""
        formValue.products = lp.landingPage?.products || []
        formValue.detail = parseEditorState(
          lp.landingPage?.detail
            ? lp.landingPage?.detail
            : stringifiedEmptyEditorState,
        )
        formValue.seoText = parseEditorState(
          lp.landingPage?.seoText
            ? lp.landingPage?.seoText
            : stringifiedEmptyEditorState,
        )

        const landingPageProducts = await getLandingPageProducts(
          lp.landingPage?.products || [],
        )

        const mapping = extendProductsToProductsTable(
          landingPageProducts?.products ?? [],
        )
        setErrorIDs(mapping.errorIDs)

        if (mapping.extendsProducts.length > 0) {
          setProducts(mapping.extendsProducts.slice(0, pageSize))
          setRawProducts(mapping.extendsProducts)
        }
      }

      return formValue
    },
    onSubmit: async (form: LandingPageForm, id?: string) => {
      const newRawProducts = rawProducts.reduce((newProduct, product) => {
        if (
          !errorIDs.includes(product.id) &&
          !newProduct.some(({ id }) => id === product.id)
        ) {
          newProduct.push({
            id: product.id,
            type: mappingProductTypeInputToShopProductType(product.type),
          })
        }
        return newProduct
      }, [] as ProductKey[])

      const landingPageParam: UpsertLandingPageRequest = {
        landingPage: {
          isActive: true,
          products: newRawProducts,
          image: form.cover?.fileInput,
          title: form.title,
          slug: form.slug,
          detail: form.detail
            ? stringifyEditorStateOrThrow(form.detail ? form.detail : undefined)
            : stringifiedEmptyEditorState,
          seoText: form.detail
            ? stringifyEditorStateOrThrow(
                form.seoText ? form.seoText : undefined,
              )
            : stringifiedEmptyEditorState,
        },
      }

      if (id && landingPageParam?.landingPage) {
        landingPageParam.landingPage.id = id
      }
      return await upsertLandingPage(landingPageParam)
    },
    onSubmitted: (resp: { id?: string }) => {
      if (resp.id) {
        navigate(`/landing-page/detail/${resp.id}`, { replace: true })
      } else {
        navigate(-1)
      }
    },
    onError: () => {},
    validationSchema: Yup.object({
      title: Yup.string().required(),
      slug: Yup.string().required(),
      cover: Yup.object()
        .shape({
          fileInput: Yup.object().shape({
            tmpPath: Yup.string(),
            name: Yup.string(),
          }),
          downloadUrl: Yup.string(),
        })
        .default(undefined)
        .required(),
    }),
  })

  const {
    values: form,
    setFieldValue: setValue,
    errors,
    submitForm,
  } = useFormHelper<LandingPageForm, { id?: string }>(formFuncHandlers())

  const getProductsToRender = (start: number, end: number) => {
    const productKeys = form.products?.slice(start, end) || []
    getLandingPageProducts(productKeys).then((lndPageProd) => {
      const mapping = extendProductsToProductsTable(lndPageProd.products ?? [])
      setProducts(mapping.extendsProducts)
    })
  }

  const paginateHandler = ([pagination, setPagination]: [
    Pagination,
    React.Dispatch<React.SetStateAction<Pagination>>,
  ]): {
    pagination: Pagination
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>
    onChangePagination:
      | ((criteria: CriteriaWithPagination<LandingPageProduct>) => void)
      | undefined
  } => ({
    pagination,
    setPagination,
    onChangePagination: async (p: {
      page: {
        index: number
        size: number
      }
    }) => {
      setPagination((prev) => ({
        ...prev,
        pageIndex: p.page.index,
      }))
      const start = p.page.index * p.page.size
      const end = start + p.page.size
      getProductsToRender(start, end)
    },
  })

  const { pagination, setPagination, onChangePagination } = paginateHandler(
    useState<Pagination>({
      pageIndex: 0,
      pageSize,
      totalItemCount: form.products?.length || 0,
      showPerPageOptions: false,
    }),
  )

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize,
      totalItemCount: form.products?.length || 0,
      showPerPageOptions: false,
    })
  }, [form.products?.length, setPagination])

  return (
    <div className="flex w-full max-w-screen-xl flex-col gap-y-8">
      <div className="flex items-center">
        <EuiIcon
          type="arrowLeft"
          size="l"
          color="text"
          onClick={() => {
            navigate(-1)
          }}
          className="cursor-pointer"
        />
        <div className="ml-2">
          <EuiText>
            <h2 className="font-bold">
              {isCreatePage
                ? t("landing-page.editor.create-landing-page")
                : t("landing-page.editor.edit-landing-page")}
            </h2>
          </EuiText>
        </div>
      </div>

      <div className="grid grid-cols-3">
        <div className="col-span-2 flex flex-col">
          <EuiFormRow
            label={t("landing-page.editor.landing-page-name")}
            fullWidth
            isInvalid={false}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              value={form.title}
              onChange={(e) => {
                e.preventDefault()
                setValue("title", e.target.value)
              }}
              fullWidth
              controlOnly={true}
              isInvalid={!!errors.title}
            />
          </EuiFormRow>

          <EuiFormRow
            label={t("landing-page.editor.url")}
            fullWidth
            isInvalid={false}
            className="mt-8"
          >
            <EuiFieldText
              prepend={
                <EuiToolTip content="content">
                  <EuiText size="s" className="font-semibold">
                    {`${publicConfig.webBaseUri}/pages/`}
                  </EuiText>
                </EuiToolTip>
              }
              placeholder={t("common.please-fill")}
              value={form.slug}
              onChange={(e) => {
                setValue("slug", e.target.value)
              }}
              fullWidth
              isInvalid={!!errors.slug}
            />
          </EuiFormRow>
          <EuiHorizontalRule />

          <FormInputCropImage
            width={1240}
            isInvalid={!!errors.cover}
            height={348}
            css={css`
              max-width: 1240px !important;
              width: 100% !important;
              height: 348px !important;
              .euiImage {
                max-width: 1240px !important;
                width: 100% !important;
                height: 348px !important;
                border-radius: 6px !important;
              }
            `}
            cropStyles={{
              maxWidth: `${1240 / 2}px`,
              maxHeight: `${348 / 2}px`,
              backgroundColor: "#C4C4C4",
            }}
            placeholder={
              <span className="block text-center">
                <p>{t("landing-page.editor.cover")}</p>
                <p className="whitespace-pre-wrap text-center text-sm">
                  {t("landing-page.editor.image-size-suggestion")}
                </p>
              </span>
            }
            accept={`${contentType.JPG}, ${contentType.PNG}`}
            value={form.cover?.downloadUrl}
            onChange={async (file: File) => {
              const { input, blob } = await uploadFile(file)
              setValue("cover.downloadUrl", blob)
              setValue("cover.fileInput", input)
            }}
            headerModal={t("common.cover-crop")}
            textZoomTooltip={t("common.crop-image-zoom-tooltip")}
            textModalSaveButton={t("common.save")}
            textModalCancelButton={t("common.cancel")}
            maxFileSizeKB={3000}
            onError={(err: Error) =>
              notification.add({
                title: "Error",
                color: "danger",
                text: err.message,
              })
            }
          />

          <EuiHorizontalRule />

          <EuiText className="mb-2">
            <h3>{t("landing-page.editor.detail")}</h3>
          </EuiText>
          <EuiFormRow
            css={{
              maxWidth: frontEndMaxWidth,
              ".mpe-editor": {
                minHeight: "400px",
              },
            }}
          >
            <TextEditor
              value={form.detail}
              onChange={(state) => setValue("detail", state)}
              plugins={[
                ToolbarPlugins.UndoRedo,
                ToolbarPlugins.BlockType,
                ToolbarPlugins.RichText,
                ToolbarPlugins.Alignment,
                ToolbarPlugins.Link,
                ToolbarPlugins.ImageUpload,
                ToolbarPlugins.ImageUrl,
                ToolbarPlugins.Youtube,
              ]}
            />
          </EuiFormRow>

          <EuiHorizontalRule />

          <EuiText className="flex flex-row items-center">
            <h3 className="mb-0 mr-2">
              {t("landing-page.editor.add-product")}
            </h3>
            <p className="text-grey">
              {t("landing-page.editor.max-product", { limit: 10000 })}
            </p>
          </EuiText>

          <UploadItemSection
            pagination={pagination}
            onChangePagination={onChangePagination}
            errorIds={errorIDs}
            export={exportProduct}
            loading={false}
            handleUpload={handleUploadProducts}
            items={products}
            tableColumns={[
              {
                field: "id",
                name: (
                  <EuiText>
                    <h4>{t("landing-page.header-table.id")}</h4>
                  </EuiText>
                ),
              },
              {
                field: "name",
                name: (
                  <EuiText>
                    <h4>{t("landing-page.header-table.product-name")}</h4>
                  </EuiText>
                ),
              },
              {
                field: "type",
                name: (
                  <EuiText>
                    <h4>{t("landing-page.header-table.type")}</h4>
                  </EuiText>
                ),
              },
              {
                field: "shopName",
                name: (
                  <EuiText>
                    <h4>{t("landing-page.header-table.shopName")}</h4>
                  </EuiText>
                ),
              },
            ]}
            exampleTemplateStaticPath={`${
              publicConfig.publicUrl
            }/excel-template-upload/product-highlight-template.xlsx`}
          />
          <EuiHorizontalRule />

          <EuiText className="mb-2">
            <h3>{t("landing-page.editor.seo-text")}</h3>
          </EuiText>
          <EuiFormRow
            css={{
              maxWidth: frontEndMaxWidth,
              ".mpe-editor": {
                minHeight: "400px",
              },
            }}
          >
            <TextEditor
              value={form.seoText}
              onChange={(state) => setValue("seoText", state)}
              plugins={[
                ToolbarPlugins.UndoRedo,
                ToolbarPlugins.BlockType,
                ToolbarPlugins.RichText,
                ToolbarPlugins.Alignment,
                ToolbarPlugins.Link,
                ToolbarPlugins.ImageUpload,
                ToolbarPlugins.ImageUrl,
                ToolbarPlugins.Youtube,
              ]}
            />
          </EuiFormRow>

          <EuiHorizontalRule />

          <EuiFlexGroup>
            <EuiFlexItem
              css={css`
                max-width: 255px;
              `}
              grow={3}
            >
              <EuiButton
                onClick={() => {
                  submitForm()
                }}
                fill
              >
                {isCreatePage
                  ? t("landing-page.editor.create-landing-page")
                  : t("landing-page.editor.edit-landing-page")}
              </EuiButton>
            </EuiFlexItem>

            <EuiFlexItem grow={4} />
          </EuiFlexGroup>
        </div>
      </div>
    </div>
  )
}
