import { Title } from "@app/component"
import { Table } from "@app/component/table"
import { useApi } from "@app/hook/api"
import { ProductType } from "@app/model/shop-product"
import { definitions as webBffDefinitions } from "@app/vendor/web-bff-specs/web_bff_service"
import {
  EuiBasicTableColumn,
  EuiBasicTableProps,
  EuiFieldText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilEyeSlash, UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import classNames from "classnames"
import { t } from "i18next"
import { useCallback, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"

type ProductCover = {
  name: string
  coverUrl: string
}

type Product = {
  id: string
  cover: ProductCover
  type: string
  category: string
  buyCount: string
  updatedAt: string
  isBanned?: boolean
  isHidden?: boolean
}

export const TableProductList = () => {
  const { webBff } = useApi()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchNameParamKey = "searchName"
  const searchNameParam = searchParams.get(searchNameParamKey)
  const finder = webBff("/admin/shop/{shopId}/product").method("get").create()
  const [searchName, setSearchName] = useState(searchNameParam ?? "")
  const [debouncedSearchName] = useDebounce(searchName, 500)
  const { shopId } = useParams()

  const inTransform = (
    input: webBffDefinitions["webbff.admin.v1.ShopProduct"],
  ): Product => {
    let data
    let categories
    let coverUrl
    let productType

    if (input?.product?.eBookAudio) {
      data = input?.product?.eBookAudio.eBookAudio
      categories = input?.product?.eBookAudio.categories
      coverUrl = input?.product?.eBookAudio.coverUrl
      productType = ProductType.EBookAudio
    } else if (input?.product?.novel) {
      data = input?.product?.novel.novel
      categories = input?.product?.novel.categories
      coverUrl = input?.product?.novel.coverUrl
      productType = ProductType.Novel
    } else if (input?.product?.physicalProduct) {
      data = input?.product?.physicalProduct?.physicalProduct
      categories = input?.product?.physicalProduct?.categories
      coverUrl = input?.product?.physicalProduct?.coverUrl
      productType = ProductType.PhysicalProduct
    } else if (input?.product?.eVoucher) {
      data = input?.product?.eVoucher?.eVoucher
      categories = input?.product?.eVoucher?.categories
      coverUrl = input?.product?.eVoucher?.coverUrl
      productType = ProductType.EVoucher
    }

    return {
      id: data?.id ?? "",
      cover: { name: data?.name ?? "", coverUrl: coverUrl ?? "" },
      type: productType || ProductType.Unknown,
      category: categories?.[0].name ?? "",
      buyCount: input.buyCount ?? "0",
      updatedAt: data?.updatedAt ?? new Date().toISOString(),
      isBanned: data?.isBanned ?? false,
      isHidden: data?.isHidden ?? false,
    }
  }

  const columns: EuiBasicTableColumn<Product>[] = [
    {
      field: "cover",
      name: t("shop-information.product-name"),
      width: "25%",
      render: (value: ProductCover, product: Product) => {
        return (
          <div className="my-3 flex w-full flex-row items-center gap-4">
            <div className="h-14 w-14 shrink-0 border border-solid border-slate-200">
              <img
                className="mx-auto h-full object-contain"
                alt=""
                src={value.coverUrl ?? ""}
              />
            </div>
            {product.isBanned ? (
              <div className="flex flex-row items-center gap-2 text-eui-disableText">
                <UilEyeSlash width={16} height={16} />
                {value.name}
              </div>
            ) : (
              <div className="line-clamp-2 break-all">{value.name}</div>
            )}
          </div>
        )
      },
    },
    {
      field: "id",
      name: t("shop-information.product-code"),
      width: "20%",
      render: (value: string, product: Product) => {
        return (
          <div>
            {product.isBanned ? (
              <div className="text-eui-disableText">{value}</div>
            ) : (
              <div>{value}</div>
            )}
          </div>
        )
      },
    },
    {
      field: "type",
      name: t("shop-information.product-type"),
      render: (value: string, product: Product) => {
        return (
          <div>
            {product.isBanned ? (
              <div className="text-eui-disableText">
                {t(`shop-information.product-type-variant.${value}`)}
              </div>
            ) : (
              <div>{t(`shop-information.product-type-variant.${value}`)}</div>
            )}
          </div>
        )
      },
    },
    {
      field: "category",
      name: t("shop-information.product-category"),
      render: (value: string, product: Product) => {
        return (
          <div>
            {product.isBanned ? (
              <div className="text-eui-disableText">{value}</div>
            ) : (
              <div>{value}</div>
            )}
          </div>
        )
      },
    },
    {
      field: "buyCount",
      name: t("shop-information.product-buy-count"),
      render: (value: string, product: Product) => {
        return (
          <div>
            {product.isBanned ? (
              <div className="text-eui-disableText">{value}</div>
            ) : (
              <div>{value}</div>
            )}
          </div>
        )
      },
    },
    {
      field: "updatedAt",
      sortable: true,
      name: t("shop-information.product-updated-at"),
      render: (value: string, product: Product) => {
        return (
          <div className="flex flex-col gap-2">
            <div
              className={classNames(product.isBanned && "text-eui-disableText")}
            >
              {t("datetime", { value })}
            </div>
            {product.isHidden && (
              <div
                className="w-fit rounded px-1.5 text-center"
                css={css({
                  backgroundColor: "#FF7E62",
                })}
              >
                {t("shop-information.product-hidden")}
              </div>
            )}
          </div>
        )
      },
    },
  ]

  const onGetItems: OnGetItems<Product> = useCallback(
    async (props) => {
      const resp = await finder({
        "option.limit": props.opts.limit,
        "option.skip": props.opts.skip,
        shopId: shopId ?? "",
        textSearch: props.filter?.searchName,
        "sorter.field": props.sorter?.field,
        "sorter.direction":
          props.sorter?.direction === "asc"
            ? "SORTER_DIRECTION_ASC"
            : "SORTER_DIRECTION_DESC",
      })

      if (!resp.ok) {
        return {
          items: [],
          count: 0,
        }
      }

      return {
        items: (resp.data.products ?? []).map((product) => {
          return inTransform(product)
        }),
        count: parseInt(resp.data.count ?? "0"),
      }
    },
    [finder, shopId],
  )

  const tableRowProps: EuiBasicTableProps<Product>["rowProps"] = (product) => {
    return {
      style: {
        backgroundColor: product.isBanned ? "#eef2f7" : null,
      },
    }
  }

  return (
    <div>
      <Title text={t("shop-information.product-list")} />
      <div className="mb-6 flex items-center justify-between">
        <EuiFieldText
          placeholder={t("shop-information.product-search-placeholder")}
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value)
          }}
          icon={<UilSearch />}
        />
      </div>
      <Table
        columns={columns}
        onGetItems={onGetItems}
        isCanSelect={false}
        swrKey={`/seller-management`}
        filter={{
          searchName: debouncedSearchName,
        }}
        sorter={{ field: "updatedAt", direction: "desc" }}
        onRowClick={(item) => {
          navigate(`/seller-management/${shopId}/${item.type}/${item.id}`)
        }}
        rowProps={tableRowProps}
      />
    </div>
  )
}
