import { useApi } from "@app/hook/api"
import { useStore } from "@app/hook/store"
import { LayoutTypes, useHomeMenuList } from "../../hook/model"
import { useDebouncedCallback } from "use-debounce"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import { isEqual } from "lodash-es"

type MediumBannerDetailProps = {
  title?: string
  description?: string
  shouldShowTitle?: boolean
  shouldShowBannerTitle?: boolean
  layout?: LayoutTypes
}

export const useUpdateFixedMediumBanner = (
  sectionKey: string,
  initialValues: MediumBannerDetailProps,
) => {
  const { notificationStore } = useStore()
  const { webUtility } = useApi()
  const { mutate } = useHomeMenuList()

  const updateMediumBannerApi = webUtility("/admin/fixed-medium-banner-section")
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(
    async (input: MediumBannerDetailProps) => {
      if (isEqual(input, initialValues)) {
        return
      }
      try {
        await updateMediumBannerApi({
          sectionKey,
          input: {
            title: input.title,
            description: input.description,
            shouldShowTitle: input.shouldShowTitle,
          },
        })
        mutate()
        return { sectionKey }
      } catch (error) {
        throw errToDefaultI18nKey(error)
      }
    },
    300,
  ) as (
    form: MediumBannerDetailProps,
    sectionKey?: string,
  ) => Promise<{ sectionKey?: string }>

  const onSubmitted = () => {}

  const onGetItem = async (): Promise<MediumBannerDetailProps> => {
    return {}
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<MediumBannerDetailProps, { sectionKey?: string }>({
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}
export type FixedBannerDetailConfigProps = ReturnType<
  typeof useUpdateFixedMediumBanner
>

export const useUpdateOverflowMediumBanner = (
  sectionKey: string,
  initialValues: MediumBannerDetailProps,
) => {
  const { notificationStore } = useStore()
  const { webUtility } = useApi()
  const { mutate } = useHomeMenuList()

  const updateMediumBannerApi = webUtility(
    "/admin/overflow-medium-banner-section",
  )
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(
    async (input: MediumBannerDetailProps) => {
      if (isEqual(input, initialValues)) {
        return
      }
      try {
        await updateMediumBannerApi({
          sectionKey,
          input: {
            title: input.title,
            description: input.description,
            shouldShowTitle: input.shouldShowTitle,
          },
        })
        mutate()
        return { sectionKey }
      } catch (error) {
        throw errToDefaultI18nKey(error)
      }
    },
    300,
  ) as (
    form: MediumBannerDetailProps,
    sectionKey?: string,
  ) => Promise<{ sectionKey?: string }>

  const onSubmitted = () => {}

  const onGetItem = async (): Promise<MediumBannerDetailProps> => {
    return {}
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<MediumBannerDetailProps, { sectionKey?: string }>({
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}
export type OverflowBannerDetailConfigProps = ReturnType<
  typeof useUpdateOverflowMediumBanner
>
