import { useApi } from "@app/hook/api"
import { useStore } from "@app/hook/store"
import { LayoutTypes } from "../../hook/model"
import { useDebouncedCallback } from "use-debounce"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import { useHomeManagementStore } from "../../store/ctx"
import { isEqual } from "lodash-es"
import * as Yup from "yup"

type BannerDetailProps = {
  sectionKey: string
  title?: string
  description?: string
  layout?: LayoutTypes
}

const validationSchema = Yup.object({
  sectionKey: Yup.string().required(),
  title: Yup.string(),
  description: Yup.string().notRequired(),
  layout: Yup.string().required(),
})

export const useUpdateHeroBanner = (
  sectionKey: string,
  initialValues?: BannerDetailProps,
) => {
  const { notificationStore } = useStore()
  const { webUtility } = useApi()
  const { heroBannerSection, refreshHomepageSection } = useHomeManagementStore()

  const updateHeroBannerApi = webUtility("/admin/hero-banner-section")
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(async (input: BannerDetailProps) => {
    if (isEqual(input, initialValues)) {
      return
    }
    try {
      await updateHeroBannerApi({
        sectionKey,
        input: {
          title: input.title,
          description: input.description,
          shouldShowTitle: true,
        },
      })
      await refreshHomepageSection()

      return { id: sectionKey }
    } catch (error) {
      throw errToDefaultI18nKey(error)
    }
  }, 300) as (form: BannerDetailProps, id?: string) => Promise<{ id?: string }>

  const onSubmitted = async () => {}

  const onGetItem = async (): Promise<BannerDetailProps> => {
    const selectedSection = heroBannerSection
    return {
      sectionKey,
      title: selectedSection?.heroBannerSection?.title,
      description: selectedSection?.heroBannerSection?.description,
      layout: selectedSection?.heroBannerSection?.layout,
    }
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<BannerDetailProps, { id?: string }>({
    id: sectionKey,
    validationSchema,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}
