import { useApi } from "@app/hook/api"
import { ProductTypeInput } from "@app/model/product-table"
import { Filter, OnGetItems } from "@reeeed-mp/ui-common"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useSWR from "swr"
import {
  ActiveLandingPageResponse,
  DeleteLandingPageResponse,
  GetLandingPageResponse,
  LandingPageRow,
  ListLandingPage,
  ProductKeys,
  ProductResp,
  UpsertLandingPageRequest,
  UpsertLandingPageResponse,
} from "../type"

const ROOT_PATH = "/admin/landing-page"

const useLandingPageApi = () => {
  const { webUtility, webBff } = useApi()
  return {
    upsert: useMemo(
      () => webUtility(ROOT_PATH).method("post").create(),
      [webUtility],
    ),
    list: useMemo(
      () => webUtility(ROOT_PATH).method("get").create(),
      [webUtility],
    ),
    get: useMemo(
      () => webUtility(`${ROOT_PATH}/{id}`).method("get").create(),
      [webUtility],
    ),
    remove: useMemo(
      () => webUtility(`${ROOT_PATH}/{id}`).method("delete").create(),
      [webUtility],
    ),
    active: useMemo(
      () => webUtility(`${ROOT_PATH}/{id}/active`).method("patch").create(),
      [webUtility],
    ),
    getProducts: useMemo(
      () => webBff("/admin/product").method("post").create(),
      [webBff],
    ),
  }
}

const converter: Record<string, ProductTypeInput> = {
  eBookAudio: "e-book-audio",
  physicalProduct: "physical-product",
  novel: "novel",
  eVoucher: "e-voucher",
}
export const toProductTypeInput = (key: string): ProductTypeInput =>
  converter[key]

export const useListLandingPage = (): {
  isLoading: boolean
  onGetItems: OnGetItems<LandingPageRow>
} => {
  const { list } = useLandingPageApi()
  const { t } = useTranslation("translation")
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getListLandingPage = useCallback(
    async ({ filter, opts }: Filter): Promise<ListLandingPage> => {
      const landingPageFilter = {
        "filter.isActive": filter.isActive,
        "option.skip": opts.skip,
        "option.limit": opts.limit,
      }
      if (filter?.searchText) {
        Object.assign(landingPageFilter, {
          "filter.textSearch": filter.searchText,
        })
      }
      const res = await list(landingPageFilter)
      return res.data
    },
    [list],
  )

  return {
    isLoading,
    onGetItems: async ({ filter, opts }) => {
      setIsLoading(true)
      const landingPages = await getListLandingPage({ filter, opts }).finally(
        () => {
          setIsLoading(false)
        },
      )

      return {
        count: +(landingPages?.count || 0),
        items: ((landingPages.landingPages || []) as LandingPageRow[]).map(
          ({ createdAt, ...props }) => ({
            ...props,
            createdAt: t("common.datetime", {
              value: createdAt ?? "",
            }),
          }),
        ),
      }
    },
  }
}

export const useGetLandingPageProductsSWR = (productKeys?: ProductKeys[]) => {
  const { getProducts } = useLandingPageApi()

  return useSWR(
    ["/admin/product", productKeys],
    async ([, productKeys]): Promise<ProductResp> => {
      if (!productKeys || !productKeys.length) {
        return {}
      }

      const { data } = await getProducts({ productKeys })
      return data
    },
  )
}

export const useGetLandingPageProducts = () => {
  const { getProducts } = useLandingPageApi()
  return useCallback(
    async (productKeys: ProductKeys[]): Promise<ProductResp> => {
      const { data } = await getProducts({ productKeys })
      return data
    },
    [getProducts],
  )
}

export const useGetLandingPage = () => {
  const { get } = useLandingPageApi()
  return useCallback(
    async (id: string): Promise<GetLandingPageResponse> => {
      const { data } = await get({ id })
      return data
    },
    [get],
  )
}

export const useDeleteLandingPage = () => {
  const { remove } = useLandingPageApi()
  return useCallback(
    async (id: string): Promise<DeleteLandingPageResponse> => {
      const { data } = await remove({ id })
      return data
    },
    [remove],
  )
}

export const useActiveLandingPage = () => {
  const { active } = useLandingPageApi()
  return useCallback(
    async (
      id: string,
      isActive: boolean,
    ): Promise<ActiveLandingPageResponse> => {
      const { data } = await active({ id, isActive })
      return data
    },
    [active],
  )
}

export const useGetLandingPageSWR = (id: string) => {
  const { get } = useLandingPageApi()
  return useSWR([`/${ROOT_PATH}/{id}`, id], async ([, id]) => {
    const { data } = await get({ id })
    return data
  })
}

export const useUpsertLandingPage = () => {
  const { upsert } = useLandingPageApi()
  return useCallback(
    async (
      landingPage: UpsertLandingPageRequest,
    ): Promise<UpsertLandingPageResponse> => {
      const { data } = await upsert(landingPage)
      return data
    },
    [upsert],
  )
}
