import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { useTranslation } from "react-i18next"
import React from "react"

export const VerifyingWithdrawAccountModal = (props: {
  onSubmit: () => void
  close: boolean
  onClose: (v: boolean) => void
  title: string
  description: string
}) => {
  const { t } = useTranslation("translation")
  return (
    <EuiModal
      css={css`
        .euiModal__closeIcon {
          display: none;
        }
        .euiModalHeader {
          padding-right: 24px !important;
        }
      `}
      className="flex flex-col"
      onClose={() => {
        props.onClose(false)
      }}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h2>{props.title}</h2>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText size="s">
          <p className="mb-0">{props.description}</p>
        </EuiText>
      </EuiModalBody>
      <EuiModalFooter className="flex-start flex flex-row gap-x-6">
        <EuiButton
          color="text"
          onClick={() => {
            props.onClose(false)
          }}
        >
          {t("common.cancel")}
        </EuiButton>
        <EuiButton
          fill
          color="primary"
          onClick={() => {
            props.onSubmit()
          }}
        >
          {t("common.confirm")}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}
