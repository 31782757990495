import { EuiBasicTable, EuiBasicTableProps } from "@elastic/eui"
import { css } from "@emotion/react"
import { TableHelperProps, useTableHelper } from "@reeeed-mp/ui-common"
import classNames from "classnames"
import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react"

export type TableProps<Item extends { id: string }> = TableHelperProps<Item> & {
  columns: EuiBasicTableProps<Item>["columns"]
  className?: string
  onRowClick?: (row: Item) => void
  filter?: { [name: string]: any }
  sorter?: { field: keyof Item; direction: "asc" | "desc" }
  loading?: boolean
  isCanSelect: boolean | true
  rowProps?: EuiBasicTableProps<Item>["rowProps"]
}

type TableExtendsId = { id: string }
type TableRef = {
  refetch: () => void
}

export type TableRefImperative = React.MutableRefObject<TableRef>

const useStateFulTable = <T extends TableExtendsId>(
  props: React.PropsWithChildren<TableProps<T>>,
  ref: React.ForwardedRef<TableRef>,
) => {
  const helpers = useTableHelper(props)

  useEffect(() => {
    if (props.filter) {
      helpers.setFilter(props.filter)
    }
  }, [props.filter, helpers])

  useImperativeHandle(ref, () => {
    return {
      refetch() {
        helpers.mutate()
      },
    }
  }, [helpers])

  return (
    <EuiBasicTable<T>
      css={css`
        .euiPagination__list {
          margin: 0;
        }

        .euiPagination__item {
          list-style: none;
        }

        &.clickable {
          .euiTableRowCell {
            cursor: pointer;
          }
        }
      `}
      className={classNames(props.className, {
        clickable: !!props.onRowClick,
      })}
      itemId={"id"}
      loading={props.loading}
      responsive={false}
      isSelectable={!!props.selectedItems && !!props.onSelectedItemsChange}
      selection={props.isCanSelect ? helpers.selection : undefined}
      items={helpers.items}
      columns={props.columns}
      pagination={helpers.pagination}
      sorting={helpers.sorting}
      onChange={helpers.onTableChange}
      cellProps={(row) => {
        return {
          onClick: () => props.onRowClick?.(row),
        }
      }}
      rowProps={props.rowProps}
    />
  )
}

export const Table = forwardRef(useStateFulTable) as <T extends TableExtendsId>(
  p: React.PropsWithChildren<TableProps<T>> & {
    ref?: React.ForwardedRef<TableRef>
  },
) => ReactElement
