import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useSearchParams } from "react-router-dom"
import { Filter, isDefined } from "@reeeed-mp/ui-common"
import { useEffect, useState } from "react"
import { MenuTypes } from "../../hook/model"
import { useGetMediumBanner } from "../../medium-banner/hook/banner"

export type BannerDetailProps = {
  banner: {
    id: string
    name: string
    cover: string
    bannerKey?: string
    numberOfWorks?: string
    duration?: {
      startAt: string
      endAt: string
    }
    link: string
  }[]
  total: string
}

export type SmallBannerItem = {
  bannerKey: string
  order: string
  id: string
  cover: string
  name: string
  link: string
  createdAt: Date
  count: string
}

export const mapBannerToDetail = (
  resp:
    | definitions["webutility.admin.v1.FindFixedMediumBannerResponse"]
    | definitions["webutility.admin.v1.FindOverflowMediumBannerResponse"]
    | definitions["webutility.admin.v1.FindSmallBannerResponse"]
    | definitions["webutility.admin.v1.FindWriterBannerResponse"],
  type: MenuTypes,
): BannerDetailProps => {
  if (type === MenuTypes.midBannerContained) {
    const mediumBannersFixed = (
      resp as definitions["webutility.admin.v1.FindFixedMediumBannerResponse"]
    ).banners
    return {
      banner: (mediumBannersFixed || []).map((banner) => ({
        id: banner.id!,
        name: banner.title!,
        cover: banner.cover!,
        link: banner.link!,
        bannerKey: banner.key!,
      })),
      total: String(resp.banners?.length),
    }
  }

  if (type === MenuTypes.midBannerOverflow) {
    const mediumBannersOverflow =
      resp as definitions["webutility.admin.v1.FindOverflowMediumBannerResponse"]
    return {
      banner: (mediumBannersOverflow.banners || []).map((banner) => ({
        id: banner.id!,
        name: banner.title!,
        cover: banner.cover!,
        link: banner.link!,
        bannerKey: banner.key!,
        duration: {
          startAt: banner.startAt!,
          endAt: banner.endAt!,
        },
      })),
      total: String(mediumBannersOverflow.count),
    }
  }

  if (type === MenuTypes.smallBanner) {
    const smallBanners =
      resp as definitions["webutility.admin.v1.FindSmallBannerResponse"]
    return {
      banner: (smallBanners.banners || []).filter(isDefined).map((banner) => ({
        id: banner.id!,
        name: banner.title!,
        cover: banner.cover!,
        link: banner.link!,
        bannerKey: banner.key!,
      })),
      total: String(smallBanners.count),
    }
  }

  if (type === MenuTypes.writer) {
    const writerBanner =
      resp as definitions["webutility.admin.v1.FindWriterBannerResponse"]
    return {
      banner: (writerBanner.banners || []).map((banner) => ({
        id: banner.id!,
        name: banner.authorName!,
        cover: banner.cover!,
        link: banner.link!,
        numberOfWorks: banner.numberOfWorks!,
        bannerKey: banner.key!,
      })),
      total: String(writerBanner.count),
    }
  }

  throw new Error("Invalid banner type")
}

export const useGetBanner = (sectionKey: string) => {
  const searchParamKey = "textSearch"
  const [searchParams] = useSearchParams()
  const [textSearch, setTextSearch] = useState(
    searchParams.get(searchParamKey) || "",
  )
  const { webUtility } = useApi()

  const getSmallBannerApi = webUtility("/admin/small-banner")
    .method("get")
    .create()

  const getSmallBanner = async ({ filter, opts }: Filter) => {
    const resp = await getSmallBannerApi({
      sectionKey,
      "filter.name": filter?.textSearch,
      "pagination.limit": opts?.limit || 6,
      "pagination.skip": opts?.skip || 0,
    })

    return resp.data
  }

  return {
    getSmallBanner,
    textSearch,
    setTextSearch,
  }
}

export const useFetchBanners = (sectionKey: string, menu: MenuTypes) => {
  const { getSmallBanner } = useGetBanner(sectionKey)
  const { getFixedMediumBanner, getOverflowMediumBanner } =
    useGetMediumBanner(sectionKey)

  const bannerTypeMap: Partial<Record<MenuTypes, any>> = {
    [MenuTypes.smallBanner]: getSmallBanner,
    [MenuTypes.midBannerContained]: getFixedMediumBanner,
    [MenuTypes.midBannerOverflow]: getOverflowMediumBanner,
  }
  const [activeBanner, setActiveBanner] = useState<BannerDetailProps>({
    banner: [],
    total: "0",
  })
  const [pendingBanner, setPendingBanner] = useState<BannerDetailProps>({
    banner: [],
    total: "0",
  })
  useEffect(() => {
    const fetchBanners = async () => {
      const getBanner = bannerTypeMap[menu]

      const [active, pending] = await Promise.all([
        getBanner({ filter: { state: "MEDIUM_BANNER_STATE_ACTIVE" } }),
        getBanner({ filter: { state: "MEDIUM_BANNER_STATE_PENDING" } }),
      ])

      setActiveBanner(mapBannerToDetail(active, menu))
      setPendingBanner(mapBannerToDetail(pending, menu))
    }

    fetchBanners()
  }, [sectionKey, menu])

  return { activeBanner, pendingBanner }
}
