import { useApi } from "@app/hook/api"
import { useCallback, useState } from "react"
import useSWR from "swr"
import { Category } from "../model"

type SWROptions = {
  onError: (err: any) => void
}

export const useGetCategories = (props: SWROptions) => {
  const { find } = useCategoryAPI()

  const { data, error, mutate } = useSWR(
    "/category",
    async () => {
      return await find({
        onError: (err) => {
          props.onError(err)
        },
      })
    },
    {
      revalidateOnFocus: false,
    },
  )

  return {
    categories: data || [],
    isLoading: !error && !data,
    isError: error,
    fetch: mutate,
  }
}

export const useLazyGetCategories = (shouldFetch: boolean) => {
  const { find } = useCategoryAPI()

  return useSWR(shouldFetch ? "/lazy-category" : undefined, find, {
    revalidateOnFocus: false,
  })
}

export const useCategoryAPI = () => {
  const { shop } = useApi()
  const [loading, setLoading] = useState(false)

  const find = useCallback(
    async (props: {
      onError: (err: any) => void
    }): Promise<Array<Category>> => {
      setLoading(true)

      try {
        const resp = await shop("/category").method("get").create()({})
        if (!resp.data.categories) {
          return []
        }

        return resp.data.categories?.map((b): Category => {
          return {
            order: b.category!.order!,
            name: b.category!.name!,
            slug: b.category!.slug!,
            childrenCount: b.category!.childrenCount!,
            id: b.category!.id!,
            parentId: b.category!.parentId,
            banners: b!.banners,
          }
        })
      } catch (err: any) {
        props.onError(err)
      } finally {
        setLoading(false)
      }

      return []
    },
    [shop],
  )

  const create = useCallback(
    async (props: {
      data: Pick<Category, "name" | "slug" | "parentId" | "banners">
      onSuccess: () => void
      onError: (err: any) => void
    }): Promise<string | undefined> => {
      setLoading(true)

      try {
        const resp = await shop("/category").method("post").create()({
          name: props.data.name,
          slug: props.data.slug,
          parentId: props.data.parentId,
          banners: props.data.banners,
        })

        props.onSuccess()

        return resp.data.id
      } catch (err: any) {
        props.onError(err)
      } finally {
        setLoading(false)
      }

      return undefined
    },
    [shop],
  )

  const update = useCallback(
    async (props: {
      data: Pick<Category, "id" | "name" | "slug" | "banners">
      onError: (err: any) => void
      onSuccess: () => void
    }) => {
      if (!props.data.id) {
        console.error("missing id for update category (should not happen)")
        return
      }

      setLoading(true)

      try {
        await shop("/category/{id}").method("put").create()({
          id: props.data.id,
          name: props.data.name,
          slug: props.data.slug,
          banners: props.data.banners,
        })

        props.onSuccess()
      } catch (err) {
        props.onError(err)
      } finally {
        setLoading(false)
      }

      return
    },
    [shop],
  )

  const changeOrder = useCallback(
    async (props: {
      data: { id: string; newOrder: number }
      onError: (err: any) => void
      onSuccess: () => void
    }) => {
      if (!props.data.id) {
        console.error("missing id for update category (should not happen)")
        return
      }

      setLoading(true)

      try {
        await shop("/category/change-order").method("post").create()({
          id: props.data.id,
          newOrder: props.data.newOrder,
        })

        props.onSuccess()
      } catch (err) {
        props.onError(err)
      } finally {
        setLoading(false)
      }

      return
    },
    [shop],
  )

  const deleteOne = useCallback(
    async (props: {
      id: string
      onError: (err: any) => void
      onSuccess: () => void
    }) => {
      if (!props.id) {
        console.error("missing id for update category (should not happen)")
        return
      }

      setLoading(true)

      try {
        await shop("/category/{id}").method("delete").create()({ id: props.id })

        props.onSuccess()
      } catch (err: any) {
        props.onError(err)
      } finally {
        setLoading(false)
      }

      return
    },
    [shop],
  )

  return {
    loading,
    find,
    create,
    update,
    changeOrder,
    deleteOne,
  }
}
