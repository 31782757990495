import { useApi } from "@app/hook/api"
import { useStore } from "@app/hook/store"
import { definitions } from "@app/vendor/order-specs/order_service"
import { EuiBasicTableColumn } from "@elastic/eui"
import { Filter, useFormHelper } from "@reeeed-mp/ui-common"
import { t } from "i18next"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import * as Yup from "yup"

export type AddEBookUser =
  definitions["order.admin.v1.AddFreeDigitalContentGiveawayProductInput"] & {
    remark?: string
  }

type FreeEBookFormProps =
  definitions["order.admin.v1.AddFreeDigitalContentGiveawayRequest"] & {
    createdAt?: string
  }

export const addEbookColumns: EuiBasicTableColumn<AddEBookUser>[] = [
  {
    field: "username",
    name: t("free-e-book.editor.table.username"),
  },
  {
    field: "productId",
    name: t("free-e-book.editor.table.product-id"),
  },
  {
    field: "productName",
    name: t("free-e-book.editor.table.product-name"),
  },
  {
    field: "productType",
    name: t("free-e-book.editor.table.product-type"),
    render: (productType: definitions["shop.v1.ProductType"]) => {
      return t(productType)
    },
  },
  {
    field: "expiresAt",
    name: t("free-e-book.editor.table.expired-at"),
    render: (date: Date) => {
      return t("common.date", {
        value: date,
      })
    },
  },
  {
    field: "remark",
    name: t("free-e-book.editor.table.remark"),
  },
]

const initialFormValues: FreeEBookFormProps = {
  name: "",
  noted: "",
  addFreeDigitalContentGiveawayProducts: [],
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
  noted: Yup.string(),
  addFreeDigitalContentGiveawayProducts: Yup.array().of(
    Yup.object().shape({
      username: Yup.string(),
      productId: Yup.string(),
      productName: Yup.string(),
      productType: Yup.string(),
      expiredDate: Yup.string(),
    }),
  ),
})

export type formControlProps = {
  formControl: ReturnType<typeof useFreeEBookForm>
}

export type FreeEBookItem = {
  id: string
  name: string
  numberOfItemsDistributed: number
  createdAt: Date
}

export const useFreeEBookForm = (id: string) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { order } = useApi()
  const { t } = useTranslation()

  const createApi = order("/add-free-digital-content-giveaway")
    .method("post")
    .create()

  const getApi = order("/add-free-digital-content-giveaway/{id}")
    .method("get")
    .create()

  const onSubmit = useCallback(
    async (input: FreeEBookFormProps, setFieldValue?: any) => {
      const results = await createApi(input)
      const errorRowResults = results.data.errorRowResults || []

      if (errorRowResults.length === 0) {
        return {
          errorRowResults: [],
        }
      }

      const formProducts = input.addFreeDigitalContentGiveawayProducts || []

      let previousItem = 0
      let errorDetail: string[] = []
      errorRowResults!.forEach((item) => {
        const index = item.row! - 1
        const detail = t(`free-e-book.err.${item.detail!}`)
        if (item.row === previousItem) {
          errorDetail.push(detail)
        } else {
          errorDetail = [detail]
        }

        if (index < 0) {
          return
        }

        formProducts[index] = {
          ...formProducts[index],
          remark: errorDetail.join(", "),
        } as AddEBookUser

        previousItem = item.row || 0
      })
      setFieldValue("addFreeDigitalContentGiveawayProducts", formProducts)
      throw new Error(t("free-e-book.err.common"))
    },
    [createApi, t],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(-1)
  }

  const onGetItem = async (): Promise<FreeEBookFormProps> => {
    if (id !== "create") {
      const resp = await getApi({ id })
      return resp.data
    }
    return initialFormValues
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<
    FreeEBookFormProps,
    { errorRowResults: any | undefined }
  >({
    id,
    validationSchema,
    onSubmit: async (input): Promise<any> =>
      onSubmit(input, form.setFieldValue),
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}

export const useGetFreeEBookList = () => {
  const [searchParams] = useSearchParams()
  const textSearchParamKey = "textSearch"
  const textSearchParam = searchParams.get(textSearchParamKey)
  const [textSearch, setTextSearch] = useState(textSearchParam || "")
  const { order } = useApi()
  const findApi = order("/add-free-digital-content-giveaway")
    .method("get")
    .create()

  const find = async ({ filter, sorter, opts }: Filter) => {
    const { data } = await findApi({
      textSearch: filter.textSearch || undefined,
      sorter,
      "option.limit": opts.limit,
      "option.skip": opts.skip,
    })

    return data
  }

  return { find, textSearch, setTextSearch }
}
