import { css } from "@emotion/react"
import YLightbox from "yet-another-react-lightbox"
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails"

import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"

export const Lightbox = (props: {
  open?: boolean
  onClose?: () => void
  slides: Array<{
    src: string
  }>
  index?: number
  onChangeIndex?: (index: number) => void
  desktopMode?: boolean
}) => {
  return (
    <YLightbox
      plugins={[Thumbnails]}
      carousel={{
        finite: true,
      }}
      open={props.open}
      on={{
        view: (index) => {
          props.onChangeIndex?.(index.index)
        },
      }}
      close={props.onClose}
      slides={props.slides}
      index={props.index}
      css={css({
        ".yarl__thumbnails_vignette": {
          display: "none",
        },
      })}
      styles={{
        container: {
          background: props.desktopMode ? "rgba(0, 0, 0, 0.5)" : undefined,
        },
        thumbnailsContainer: {
          background: props.desktopMode ? "rgba(0, 0, 0, 0.5)" : undefined,
        },
        thumbnail: {
          border: "none",
          padding: "0px",
          borderRadius: "0px",
          width: 80,
          height: 80,
        },
      }}
    />
  )
}
