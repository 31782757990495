import React from "react"
import { NavigationHeaderButton } from "@app/component"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
} from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { contentType } from "@app/hook/file"
import { ImagePicker } from "../main-menu/image-picker"
import { useFormSmallBanner } from "./hook"

export const BannerEditorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { bannerKey, sectionKey } = useParams()
  const formControl = useFormSmallBanner(sectionKey!, bannerKey!)
  const navigateBack = () => {
    navigate(-1)
  }
  return (
    <div className="w-full max-w-screen-sm">
      <NavigationHeaderButton
        title={
          bannerKey === "create"
            ? t("home-management.banner.editor.title.create")
            : t("home-management.banner.editor.title.edit")
        }
        onClick={navigateBack}
      />
      <div className="mt-6 flex flex-col gap-y-8">
        <EuiFormRow label={t("common.name")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.title}
            maxLength={25}
            onChange={(e) => formControl.setFieldValue("title", e.target.value)}
            isInvalid={!!formControl.errors.title}
          />
        </EuiFormRow>
        <div className="flex items-center gap-x-4">
          <ImagePicker
            value={formControl.values.cover}
            error={undefined}
            setLoading={formControl.setSubmitting}
            onChange={async (input, blob) => {
              formControl.setValues({
                ...formControl.values,
                cover: blob,
                fileInput: input.tmpPath,
              })
            }}
            acceptType={`${contentType.JPG},${contentType.PNG}`}
            placeholder={
              <div className="flex flex-col text-center">
                <div>
                  <p>{t("home-management.hero-banner.editor.banner")}</p>
                </div>
                <div>
                  <p className="whitespace-pre-wrap text-center text-sm">
                    {t("home-management.cover-suggestion", {
                      size: "160x160 px",
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            width="220"
            height="220"
          />
        </div>
        <EuiFormRow className="" label={t("common.link")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.link}
            onChange={(e) => formControl.setFieldValue("link", e.target.value)}
            isInvalid={!!formControl.errors.link}
          />
        </EuiFormRow>
        <EuiFormRow className="" label={t("Meta Tag")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.metaTag}
            onChange={(e) =>
              formControl.setFieldValue("metaTag", e.target.value)
            }
            isInvalid={!!formControl.errors.metaTag}
          />
        </EuiFormRow>
      </div>
      <div className="mt-10 flex flex-row">
        <EuiButton
          fill
          fullWidth
          onClick={formControl.submitForm}
          disabled={formControl.isSubmitting}
        >
          {t("common.save")}
        </EuiButton>
        <EuiButtonEmpty
          className="ml-4 w-full items-center"
          color="text"
          onClick={navigateBack}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </div>
  )
}
