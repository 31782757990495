import dayjs from "dayjs"
import { useApi } from "@app/hook/api"
import useSWR from "swr"

export enum CodeStatus {
  active = "active",
  inactive = "inactive",
  used = "used",
  expired = "expired",
}

export const mapCouponRedeemStatus = (
  startAt: string,
  endAt: string,
  redeemedAt?: string,
) => {
  let status: CodeStatus = CodeStatus.active

  if (redeemedAt && dayjs().isAfter(dayjs(redeemedAt))) {
    status = CodeStatus.used
  } else if (dayjs().isBefore(dayjs(startAt || ""))) {
    status = CodeStatus.inactive
  } else if (dayjs().isAfter(dayjs(endAt))) {
    status = CodeStatus.expired
  }

  return status
}

export type CampaignContentItem = {
  id: string
  productId: string
  variant: string
  quantity: string
  expiredDay: string
  startAt: string
  endAt: string
}

export const useGetCodeDetail = (codeId: string) => {
  const { webBff } = useApi()

  const { data, error, isLoading } = useSWR(
    ["code-dedemption-code-detail", codeId],
    async ([, couponId]) => {
      const resp = webBff("/admin/shop-coupon-redemption/{couponId}")
        .method("get")
        .create()({
        couponId,
      })

      return resp
    },
    {
      revalidateOnFocus: false,
    },
  )

  return {
    data,
    error,
    isLoading,
  }
}
