import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import * as Yup from "yup"
import { FileForm } from "../form"
import { conditionalCoverShapeValidator } from "./cover"

export type ShopForm = {
  id?: string
  downloadUrl?: string
  cover?: FileForm
  name?: string
  link?: string
}

export const recommendedShopFormValidator = Yup.object({
  name: Yup.string().required(),
  link: Yup.string().required(),
  cover: conditionalCoverShapeValidator("downloadUrl"),
})

export type ShopInput =
  definitions["webutility.admin.v1.RecommendShopSectionInput.Shop"] & {
    downloadUrl?: string
  }
