import { ConfirmDeleteModal, EditModal } from "@app/component"
import { useAlertDefaultErr } from "@app/hook/error"
import { useUnMount } from "@app/hook/life-cycle"
import {
  TopupPackage,
  genNewId,
  paymentTypeToPackageType,
  priceToString,
  stringToPrice,
  validatePackage,
} from "@app/model"
import { PaymentChannel } from "@app/model/payment"
import {
  EuiButton,
  EuiCheckbox,
  EuiDatePicker,
  EuiFieldNumber,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiPopover,
  EuiText,
  EuiThemeComputed,
  EuiTitle,
  IconType,
  useEuiTheme,
} from "@elastic/eui"
import { css } from "@emotion/react"
import * as Unicons from "@iconscout/react-unicons"
import { ReeeedCoin, hooks, theme } from "@reeeed-mp/ui-common"
import { observer } from "mobx-react"
import moment from "moment"
import { HTMLAttributes, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTopupPackageStore } from "./store"

const newContainerCSS = (euiTheme: EuiThemeComputed) => ({
  ul: css({
    "&.list-none": css({
      marginLeft: 0,
    }),
  }),
  container: css({
    width: 511,
    "&.packages-bg": { backgroundColor: euiTheme.colors.lightestShade },
  }),
  item: css({ fontSize: 14 }),
})

const updateStatus = {
  new: { color: "success" },
  original: { color: undefined },
  update: { color: "warning" },
} as const

type TopupPackageListProps = HTMLAttributes<HTMLDivElement> & {
  channel: PaymentChannel
}

export const TopupPackageList: React.FC<TopupPackageListProps> = observer(
  ({ channel, ...restProps }) => {
    const [deletePackage, setDeletePackage] = useState<TopupPackage | null>(
      null,
    )
    const [editPackage, setEditPackage] =
      useState<Partial<TopupPackage> | null>(null)
    const [setPublishTimeline, setSetPublishTimeline] = useState(false)
    const [editPackageErr, setEditPackageErr] = useState<null | Map<
      string,
      string
    >>(null)
    const [openToolTipForPackageId, setOpenToolTipForPackageId] = useState<
      string | null
    >(null)
    const { t } = useTranslation()
    const topupPackageStore = useTopupPackageStore()
    const { euiTheme } = useEuiTheme()
    const css = newContainerCSS(euiTheme)
    const alertDefaultErr = useAlertDefaultErr()
    const { setIsDirty } = hooks.useConfirmLeaveWithoutSaved(
      t("common.confirm-not-save-data"),
    )
    useUnMount(async () => {
      await setIsDirty(false)
    })

    useEffect(() => {
      topupPackageStore.getTopupPackageByPaymentType(channel)
    }, [channel, topupPackageStore])

    useEffect(() => {
      if (topupPackageStore.deletePackageState.error) {
        alertDefaultErr(topupPackageStore.deletePackageState.error)
      }
      if (topupPackageStore.packagesToStore.error) {
        alertDefaultErr(topupPackageStore.packagesToStore.error)
      }
    }, [
      topupPackageStore.deletePackageState.error,
      topupPackageStore.packagesToStore.error,
      topupPackageStore.rootStore.notificationStore,
      alertDefaultErr,
    ])

    return (
      <div className="flex flex-col gap-2" {...restProps}>
        <EuiTitle size="xs">
          <p>{t(`payment-channel.${channel}`)} Packages</p>
        </EuiTitle>
        <EuiPanel css={css.container} className="packages-bg grow-0">
          <div className="flex flex-col gap-4 ">
            <EuiButton
              iconType="plus"
              color="text"
              onClick={() => {
                setEditPackage({
                  id: genNewId(),
                  type: paymentTypeToPackageType(channel),
                  paymentType: channel,
                })
                setSetPublishTimeline(false)
              }}
            >
              Add
            </EuiButton>
            <ul className="flex list-none flex-col gap-2" css={css.ul}>
              {topupPackageStore.mergedPackages(channel).map((p) => (
                <li key={p.id}>
                  <EuiPanel
                    hasShadow={false}
                    color={updateStatus[p.status].color}
                  >
                    <div
                      css={css.item}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center gap-2">
                        <EuiIcon type={ReeeedCoin} size="m" />
                        <EuiText>
                          <p className="m-0">
                            {priceToString(p.getCoin, 0)} Coin
                          </p>
                        </EuiText>
                      </div>
                      <div className="flex items-center gap-2">
                        <EuiText>
                          <p className="m-0">
                            {t("common.currency-symbol")}{" "}
                            {priceToString(p.chargeAmount)}
                          </p>
                        </EuiText>
                        <EuiPopover
                          isOpen={p.id === openToolTipForPackageId}
                          anchorPosition="rightCenter"
                          closePopover={() => setOpenToolTipForPackageId(null)}
                          button={
                            <EuiIcon
                              type={Unicons.UilEllipsisV}
                              size="m"
                              className="cursor-pointer"
                              onClick={() => setOpenToolTipForPackageId(p.id)}
                            />
                          }
                        >
                          <EditToolTip
                            onDelete={() => {
                              setOpenToolTipForPackageId(null)
                              setDeletePackage(p)
                            }}
                            onEdit={() => {
                              setOpenToolTipForPackageId(null)
                              setEditPackage(p)
                              setSetPublishTimeline(!!p.publishTimeline)
                            }}
                          />
                        </EuiPopover>
                      </div>
                    </div>
                  </EuiPanel>
                </li>
              ))}
            </ul>
            {topupPackageStore.newPackages.length ||
            topupPackageStore.updatePackages.length ? (
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiButton
                    fill
                    fullWidth
                    className="mr-4"
                    color="primary"
                    onClick={async () => {
                      await setIsDirty(false)
                      topupPackageStore.storeTopupPackages()
                    }}
                  >
                    {t("common.save")}
                  </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiButton
                    color="text"
                    onClick={topupPackageStore.clearPackageToStoreBuffer}
                  >
                    {t("common.cancel")}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            ) : null}
          </div>
        </EuiPanel>

        {deletePackage && (
          <ConfirmDeleteModal
            description={`${priceToString(deletePackage.getCoin, 0)} Coin : ${t(
              "common.currency-symbol",
            )} ${priceToString(deletePackage.chargeAmount)}`}
            onClose={() => setDeletePackage(null)}
            onConfirm={() => {
              topupPackageStore.deleteTopupPackage(deletePackage)
              setDeletePackage(null)
            }}
            isDeleting={topupPackageStore.deletePackageState.loading}
          />
        )}
        {editPackage && (
          <EditModal
            title={`${t(
              editPackage.id ? "common.edit" : "common.add",
            )} Package`}
            subTitle={t(`payment-channel.${channel}`)}
            onClose={async () => {
              setEditPackageErr(null)
              setEditPackage(null)
            }}
            onSubmit={async () => {
              let pkg = editPackage
              if (!setPublishTimeline) {
                const { ...rest } = pkg
                setEditPackage(rest)
                pkg = rest
              }

              setEditPackageErr(null)
              const err = validatePackage(pkg, setPublishTimeline)
              if (err) {
                setEditPackageErr(err)
                return
              }

              // not handle if topup package data correct -> forward to api to validate topup package
              topupPackageStore.upsertTopupPackageInBuffer(pkg)
              setEditPackage(null)
              await setIsDirty(true)
            }}
            submitText={t("common.accept")}
          >
            <EuiFormRow
              label={t("topup-package.get-coin")}
              fullWidth
              isInvalid={!!editPackageErr?.get("getCoin")}
              error={editPackageErr?.get("getCoin")}
            >
              <EuiFieldNumber
                fullWidth
                controlOnly
                placeholder={t("common.please-fill")}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    getCoin: stringToPrice(e.target.value, { noDigit: true }),
                  })
                }}
                value={editPackage.getCoin ? editPackage.getCoin / 1e6 : ""}
                min={0}
                isInvalid={!!editPackageErr?.get("getCoin")}
              />
            </EuiFormRow>
            <EuiFormRow
              label={t("topup-package.charge-amount")}
              fullWidth
              isInvalid={!!editPackageErr?.get("chargeAmount")}
              error={editPackageErr?.get("chargeAmount")}
            >
              <EuiFieldNumber
                fullWidth
                append={t("topup-package.charge-unit")}
                placeholder={t("common.please-fill")}
                onChange={(e) => {
                  setEditPackage({
                    ...editPackage,
                    chargeAmount: stringToPrice(e.target.value),
                  })
                }}
                value={
                  editPackage.chargeAmount ? editPackage.chargeAmount / 1e6 : ""
                }
                min={0}
                isInvalid={!!editPackageErr?.get("chargeAmount")}
              />
            </EuiFormRow>
            {setPublishTimeline && (
              <EuiFormRow fullWidth>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFormRow
                      label={t("topup-package.publish-timeline.start")}
                      isInvalid={!!editPackageErr?.get("publishStart")}
                      error={editPackageErr?.get("publishStart")}
                    >
                      <EuiDatePicker
                        isInvalid={!!editPackageErr?.get("publishStart")}
                        selected={
                          editPackage.publishTimeline?.start &&
                          moment(editPackage.publishTimeline.start.valueOf())
                        }
                        minDate={moment()}
                        dateFormat="DD/MM/YYYY h:mm A"
                        onChange={(time) => {
                          const edited = {
                            ...editPackage,
                          }
                          const publishStart = time
                            ? time.toDate()
                            : moment().toDate()

                          if (!edited.publishTimeline) {
                            edited.publishTimeline = {
                              start: publishStart,
                            }
                          } else {
                            edited.publishTimeline.start = publishStart
                          }

                          setEditPackage(edited)
                        }}
                        showTimeSelect
                        iconType={Unicons.UilCalender}
                        placeholder={t("common.please-fill")}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFormRow
                      label={t("topup-package.publish-timeline.end")}
                      isInvalid={!!editPackageErr?.get("publishEnd")}
                      error={editPackageErr?.get("publishEnd")}
                    >
                      <EuiDatePicker
                        isInvalid={!!editPackageErr?.get("publishEnd")}
                        selected={
                          editPackage.publishTimeline?.end &&
                          moment(editPackage.publishTimeline.end.valueOf())
                        }
                        minDate={
                          editPackage.publishTimeline?.start &&
                          moment(editPackage.publishTimeline.start)
                        }
                        dateFormat="DD/MM/YYYY h:mm A"
                        onChange={(time) => {
                          const edited = {
                            ...editPackage,
                          }
                          if (!edited.publishTimeline) {
                            return
                          }

                          edited.publishTimeline.end = time?.toDate()
                          setEditPackage(edited)
                        }}
                        showTimeSelect
                        iconType={Unicons.UilCalender}
                        placeholder={t("common.non")}
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFormRow>
            )}
            <EuiFormRow>
              <EuiCheckbox
                id="1"
                label={t("topup-package.set-publish-timeline")}
                checked={setPublishTimeline}
                onChange={() => {
                  setSetPublishTimeline(!setPublishTimeline)
                }}
              />
            </EuiFormRow>
          </EditModal>
        )}
      </div>
    )
  },
)

type EditToolTipProps = {
  onEdit: () => void
  onDelete: () => void
}

const EditToolTip: React.FC<EditToolTipProps> = ({ onDelete, onEdit }) => {
  const { t } = useTranslation()
  return (
    <div className="flex w-52 flex-col gap-4">
      <ActionItem
        iconType={Unicons.UilPen}
        text={t("common.edit")}
        onClick={onEdit}
      />
      <ActionItem
        iconType={Unicons.UilTrash}
        text={t("common.delete")}
        onClick={onDelete}
      />
    </div>
  )
}

type ActionItemProps = HTMLAttributes<HTMLDivElement> & {
  iconType: IconType
  text: string
}

const ActionItem: React.FC<ActionItemProps> = ({
  iconType,
  text,
  ...restProps
}) => {
  return (
    <div
      {...restProps}
      css={{
        "&:hover": {
          color: theme.colors.LIGHT.primaryPurple,
        },
      }}
      className="flex cursor-pointer items-center gap-3"
    >
      <EuiIcon type={iconType} />
      <EuiText className="flex-1">
        <p>{text}</p>
      </EuiText>
    </div>
  )
}
