import {
  EuiButton,
  EuiDatePicker,
  EuiFormRow,
  EuiProgress,
  EuiSwitch,
} from "@elastic/eui"
import moment, { Moment } from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export type TimePeriod = { startDate?: Moment; endDate?: Moment }

type Props = {
  loadingPercentage?: number | null
  onSubmit: (_: TimePeriod) => void
  switchFilter?: {
    value: boolean
    onChange: (_: boolean) => void
    description: string
  }
}

export const DownloadReportForm: React.FC<Props> = ({
  onSubmit,
  loadingPercentage,
  switchFilter: additionalFilter,
}) => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined)
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined)
  const [minDateFrom, setMinDateFrom] = useState<Moment | undefined>(undefined)
  const [maxDateFrom, setMaxDateFrom] = useState<Moment | undefined>(undefined)
  const [minDateTo, setMinDateTo] = useState<Moment | undefined>(undefined)
  const [maxDateTo, setMaxDateTo] = useState<Moment | undefined>(undefined)

  const loading =
    typeof loadingPercentage === "number" && loadingPercentage >= 0

  const dateRange = 30

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center justify-center gap-x-4">
          <EuiFormRow className="my-0">
            <EuiDatePicker
              selected={startDate}
              minDate={minDateFrom}
              maxDate={maxDateFrom}
              onChange={(date) => {
                if (date) {
                  setStartDate(date)
                  setMinDateTo(date)
                  setMaxDateTo(moment(date).add(dateRange, "d"))
                }
              }}
            />
          </EuiFormRow>
          <p className="m-0">{t("common.to")}</p>
          <EuiFormRow className="my-0">
            <EuiDatePicker
              selected={endDate}
              minDate={minDateTo}
              maxDate={maxDateTo}
              onChange={(date) => {
                if (date) {
                  setEndDate(date)
                  setMinDateFrom(moment(date).add(-dateRange, "d"))
                  setMaxDateFrom(date)
                }
              }}
            />
          </EuiFormRow>

          <EuiButton
            color="primary"
            fill
            disabled={startDate === undefined || endDate === undefined}
            className="my-0"
            isLoading={loading}
            onClick={() => onSubmit({ startDate, endDate })}
          >
            {t("common.download")}
          </EuiButton>
        </div>
      </div>
      {additionalFilter ? (
        <EuiSwitch
          label={
            <div className="flex flex-row items-center">
              {additionalFilter.description}
            </div>
          }
          className="flex w-60 items-center"
          compressed
          checked={additionalFilter?.value}
          onChange={(e) => {
            additionalFilter?.onChange(e.target.checked)
          }}
        />
      ) : null}
      {loading && (
        <EuiProgress
          className="ml-[3px] mt-[-3px] w-full max-w-[594px]"
          value={loadingPercentage}
          max={100}
          size="xs"
          color="success"
        />
      )}
    </div>
  )
}
