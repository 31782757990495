import { ConfirmActionModal } from "@app/component/modal-confirm-action"
import { useApi } from "@app/hook/api"
import { useAlertDefaultErr } from "@app/hook/error"
import { useStore } from "@app/hook/store"
import { formatPromotionCode } from "@app/model/promotion-code"
import { datePickerDateFormat, datePickerTimeFormat } from "@app/model/time"
import {
  CodeStatus,
  Divider,
  FieldNumberWithUnit,
  Headline,
  isAlreadyInCollectingPeriod,
  RadioYesNo,
  SummaryPromotionCard,
  TextArea,
  toCodeStatus,
} from "@app/page/coupon-promotion/component"
import {
  SelectJoinPackage,
  SummaryTopupCouponDetail,
} from "@app/page/coupon-promotion/topup/component"
import { apiToForm, formToAPI } from "@app/page/coupon-promotion/topup/conv"
import {
  EuiCheckbox,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFieldText,
  EuiFormRow,
  EuiSelect,
  EuiText,
} from "@elastic/eui"
import { useFormHelper } from "@reeeed-mp/ui-common"
import { isEqual } from "lodash-es"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { CouponEffectType, CouponType, formSchema, TopupCoupon } from "./types"

moment.locale("en")

export const TopupCouponPromotionEditor: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { promotion } = useApi()
  const { notificationStore } = useStore()
  const createPromotion = promotion("/topup-coupon-promotion")
    .method("post")
    .create()

  const updatePromotion = promotion("/topup-coupon-promotion/{promotionId}")
    .method("post")
    .create()
  const [status, setStatus] = useState(CodeStatus.Draft)
  const alertDefaultErr = useAlertDefaultErr()
  const id = !!params.id && params.id !== "create" ? params.id : undefined
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenModalConfirmGoBackPage, setIsOpenModalConfirmGoBackPage] =
    useState<boolean>(false)
  const [isConfirmedBack, setIsConfirmedBack] = useState<boolean>(false)

  const form = useFormHelper<TopupCoupon, { id: string }>({
    id,
    initialValues: {
      limitQuota: false,
      isCanCollectCodeEarly: false,
      couponType: CouponType.Discount,
      applyToAllPackage: true,
      effectType: CouponEffectType.Amount,
      hideFromCollectPage: false,
    },
    async onGetItem(id) {
      const res = await promotion("/topup-coupon-promotion/{promotionId}")
        .method("get")
        .create()({ promotionId: id })
      const coupon = apiToForm(res.data)
      setStatus(
        toCodeStatus(moment(), {
          startAt: coupon.startAt,
          endAt: coupon.endAt,
        }),
      )
      return coupon
    },
    async onSubmit(formData) {
      try {
        setIsLoading(true)
        if (!id) {
          const res = await createPromotion({ data: formToAPI(formData) })
          return { id: res.data.promotion!.id! }
        } else {
          await updatePromotion({ data: formToAPI(formData), promotionId: id })
          return { id }
        }
      } catch (e) {
        if (
          e instanceof createPromotion.Error ||
          e instanceof updatePromotion.Error
        ) {
          const err = e.getActualType()
          let errMsg = ""

          if (err.data.message === "code-dup") {
            errMsg = t("promotion.editor.errors.code-dup")
            form.setFieldError("code", errMsg)
          } else {
            errMsg = e.message
          }

          notificationStore.add({
            title: "Error",
            color: "danger",
            text: errMsg,
          })
        }

        return { id: "" }
      } finally {
        setIsLoading(false)
      }
    },
    onSubmitted({ id }) {
      if (id) {
        navigate(`/topup-coupon-promotion/detail/${id}`, { replace: true })
      }
    },
    onError(e) {
      alertDefaultErr(e)
      // const err = errToAppErr(e)
      // console.log("onError", err)
      // if (err["@type"] === "type.googleapis.com/foundation.error.InvalidArgumentsError") {
      //   const toErrKey = (d: string) => `promotion.editor.errors.${d}`
      //
      //   const errors = err.details.reduce<FormikErrors<TopupCoupon>>((agg, d) => {
      //     switch (d.field) {
      //       case "promotionEffect":
      //         agg.effect = toErrKey(d.description)
      //         break
      //     }
      //
      //     return agg
      //   }, {})
      //
      //   form.setErrors(errors)
      // } else {
      // }
    },
    validationSchema: formSchema,
  })

  const [originFormValues, setOriginFormValues] = useState<
    TopupCoupon | undefined
  >(undefined)
  useEffect(() => {
    if (!originFormValues) {
      if ((id && form.values.code) || !id) {
        setOriginFormValues(form.values)
      }
    }

    const handleBackBrowser = () => {
      if (!isEqual(originFormValues, form.values) && !isConfirmedBack) {
        window.history.go(1)
        setIsOpenModalConfirmGoBackPage(true)
      }
    }

    window.addEventListener("popstate", handleBackBrowser)
    return () => window.removeEventListener("popstate", handleBackBrowser)
  }, [form.values, id, isConfirmedBack, originFormValues])

  window.onbeforeunload = () => {
    if (!isEqual(originFormValues, form.values)) {
      return t("promotion.editor.confirm-draft-go-back-detail")
    }
  }

  const unit =
    form.values.couponType === CouponType.Discount
      ? t("common.currency")
      : t("common.coin")

  const isInCollectPeriod = isAlreadyInCollectingPeriod(params.id!, {
    collectCodeStartAt: form.values.collectCodeStartAt,
    startAt: form.values.startAt,
  })

  return (
    <div className="flex w-full max-w-screen-xl flex-col gap-y-8">
      {isOpenModalConfirmGoBackPage ? (
        <ConfirmActionModal
          onClose={() => {
            setIsOpenModalConfirmGoBackPage(false)
          }}
          onConfirm={async () => {
            setIsConfirmedBack(true)
            navigate(-1)
            setIsOpenModalConfirmGoBackPage(false)
          }}
          textConfirmButton={t("promotion.editor.confirm-exit")}
          textCloseButton={t("promotion.editor.confirm-go-back-to-edit")}
          title={t("promotion.editor.confirm-draft-go-back")}
          description={t("promotion.editor.confirm-draft-go-back-detail")}
        />
      ) : null}
      <Headline
        onGoBackClick={() => {
          if (!isEqual(originFormValues, form.values)) {
            setIsOpenModalConfirmGoBackPage(true)
          } else {
            navigate(-1)
          }
        }}
        text={
          params.id === "create"
            ? t("topup-promotion.editor.create-promotion")
            : t("topup-promotion.editor.edit-promotion-code")
        }
      />
      <div className="grid grid-cols-3 gap-x-5">
        <div className="col-span-2">
          <EuiFormRow
            label={t("promotion.editor.promotion-name")}
            fullWidth
            isInvalid={!!form.errors.name}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              {...form.getFieldProps("name")}
              fullWidth
              controlOnly={true}
              isInvalid={!!form.errors.name}
            />
          </EuiFormRow>
          <EuiFormRow
            label={t("topup-promotion.editor.promotion-code")}
            isInvalid={!!form.errors.code}
            className={"w-2/3"}
            helpText={t("promotion.editor.promotion-code-hint")}
          >
            <EuiFieldText
              placeholder={t("promotion.editor.promotion-code-example")}
              disabled={status === CodeStatus.Active}
              value={form.values.code ?? ""}
              onChange={(e) => {
                const [code, ok] = formatPromotionCode(e.target.value)
                if (!ok) {
                  return
                }
                form.setFieldValue("code", code)
              }}
              fullWidth
              isInvalid={!!form.errors.code}
              maxLength={10}
              controlOnly={true}
            />
          </EuiFormRow>
          <EuiFormRow
            label={t("topup-promotion.editor.quota")}
            className={"w-2/3"}
          >
            <div className="mt-1">
              <RadioYesNo
                disabled={status === CodeStatus.Active || isInCollectPeriod}
                id={"limit-quota"}
                value={form.values.limitQuota}
                onChange={(v) => form.setFieldValue("limitQuota", v)}
                noLabel={t("promotion.editor.un-limit-quota")}
                yesLabel={t("promotion.editor.custom-quota")}
              />
              {form.values.limitQuota && (
                <div className="mt-2 w-full">
                  <FieldNumberWithUnit
                    disabled={status === CodeStatus.Active || isInCollectPeriod}
                    placeholder={t("common.please-fill")}
                    unit={t("promotion.editor.quota-unit")}
                    {...form.getFieldProps("quota")}
                    min={0}
                    isInvalid={!!form.errors.quota}
                  />
                </div>
              )}
            </div>
          </EuiFormRow>

          <Divider />

          <EuiText>
            <h2 className="font-bold">
              {t("topup-promotion.editor.coupon-type")}
            </h2>
          </EuiText>
          <div className="mt-2">
            <EuiFormRow fullWidth>
              <div className="flex h-[38px] items-center pt-2">
                <EuiText size="s">
                  {t("promotion.editor.topup-coupon-type")}
                </EuiText>
                <div className="ml-6 flex-auto">
                  <RadioYesNo
                    id={"coupon-type"}
                    disabled={status === CodeStatus.Active || isInCollectPeriod}
                    value={form.values.couponType === CouponType.ExtraCoin}
                    onChange={(v) => {
                      form.setFieldValue(
                        "couponType",
                        v ? CouponType.ExtraCoin : CouponType.Discount,
                      )
                    }}
                    noLabel={t("promotion.editor.topup-discount")}
                    yesLabel={t("promotion.editor.topup-add-coin")}
                  />
                </div>
              </div>
            </EuiFormRow>
            <EuiFormRow fullWidth>
              <div className="flex items-center pt-2">
                <EuiText size="s">{t("promotion.editor.value")}</EuiText>
                <div className="ml-6 flex-auto">
                  <EuiSelect
                    disabled={status === CodeStatus.Active || isInCollectPeriod}
                    options={[
                      {
                        value: "discountPercentage",
                        text: t("promotion.editor.discount-percentage"),
                      },
                      {
                        value: "customDiscount",
                        text: t("promotion.editor.discount-custom"),
                      },
                    ]}
                    value={
                      form.values.effectType === CouponEffectType.Amount
                        ? "customDiscount"
                        : "discountPercentage"
                    }
                    onChange={(e) => {
                      form.setFieldValue(
                        "effectType",
                        e.target.value === "customDiscount"
                          ? CouponEffectType.Amount
                          : CouponEffectType.Percentage,
                      )
                    }}
                  />
                </div>
                <div className="ml-4 flex-auto">
                  {form.values.effectType === CouponEffectType.Amount ? (
                    <FieldNumberWithUnit
                      key={"amount"}
                      disabled={
                        status === CodeStatus.Active || isInCollectPeriod
                      }
                      placeholder={t("common.please-fill")}
                      unit={unit}
                      {...form.getFieldProps("effect.amount.value")}
                      min={0}
                      isInvalid={!!form.getError("effect.amount.value")}
                    />
                  ) : (
                    <FieldNumberWithUnit
                      key={"percentage"}
                      disabled={
                        status === CodeStatus.Active || isInCollectPeriod
                      }
                      placeholder={t("common.please-fill")}
                      unit={t("promotion.editor.percentage-symbol")}
                      {...form.getFieldProps("effect.percentage.value")}
                      min={0}
                      max={100}
                      isInvalid={!!form.getError("effect.percentage.value")}
                    />
                  )}
                </div>
              </div>
            </EuiFormRow>
            {form.values.effectType === CouponEffectType.Percentage && (
              <EuiFormRow fullWidth>
                <div className="flex h-[38px] items-center pt-2">
                  <EuiText size="s">
                    {t("promotion.editor.max-discount")}
                  </EuiText>
                  <div className="ml-6 flex-auto">
                    <RadioYesNo
                      id={"max-percentage-amount"}
                      disabled={
                        status === CodeStatus.Active || isInCollectPeriod
                      }
                      value={
                        form.values.effect?.percentage?.haveMaxAmount ?? false
                      }
                      onChange={(v) =>
                        form.setFieldValue("effect.percentage.haveMaxAmount", v)
                      }
                      yesLabel={t("promotion.editor.custom-max-discount")}
                      noLabel={t("promotion.editor.un-limit")}
                    />
                  </div>
                  {form.values.effect?.percentage?.haveMaxAmount && (
                    <div className="ml-4 flex-auto">
                      <FieldNumberWithUnit
                        placeholder={t("common.please-fill")}
                        disabled={
                          status === CodeStatus.Active || isInCollectPeriod
                        }
                        unit={unit}
                        {...form.getFieldProps("effect.percentage.maxAmount")}
                        min={0}
                        isInvalid={
                          !!form.getError("effect.percentage.maxAmount")
                        }
                      />
                    </div>
                  )}
                </div>
              </EuiFormRow>
            )}
          </div>

          <Divider />

          <EuiText>
            <h2 className="font-bold">
              {t("promotion.editor.date-time-to-use")}
            </h2>
          </EuiText>
          <div className="mt-2 flex">
            <EuiDatePickerRange
              disabled={status === CodeStatus.Active}
              className={"max-w-[500px]"}
              startDateControl={
                <EuiDatePicker
                  selected={form.values.startAt}
                  onChange={(date) => {
                    if (
                      date &&
                      form.values.endAt &&
                      form.values.endAt.isBefore(date)
                    ) {
                      form.setFieldValue("endAt", null)
                    }
                    form.setFieldValue("startAt", date)
                  }}
                  isInvalid={!!form.errors.startAt}
                  minTime={moment().hours(0).minutes(0)}
                  maxTime={form.values.endAt || moment().hours(23).minutes(59)}
                  maxDate={form.values.endAt || undefined}
                  timeIntervals={1}
                  showTimeSelect
                  dateFormat={datePickerDateFormat}
                  timeFormat={datePickerTimeFormat}
                />
              }
              endDateControl={
                <EuiDatePicker
                  selected={form.values.endAt}
                  onChange={(date) => {
                    form.setFieldValue("endAt", date)
                  }}
                  minDate={
                    form.values.startAt
                      ? moment.max(moment(), form.values.startAt)
                      : moment()
                  }
                  showTimeSelect
                  minTime={form.values.startAt || moment().hours(0).minutes(0)}
                  maxTime={moment().hours(23).minutes(59)}
                  timeIntervals={1}
                  dateFormat={datePickerDateFormat}
                  timeFormat={datePickerTimeFormat}
                  isInvalid={!!form.errors.endAt}
                />
              }
            />
          </div>
          <div className="mt-6">
            <EuiCheckbox
              id="hideFromCollectPage"
              label={
                <EuiText size="s">
                  {t("promotion.editor.hide-from-collect-page")}
                </EuiText>
              }
              checked={form.values.hideFromCollectPage}
              onChange={(e) => {
                form.setFieldValue("hideFromCollectPage", e.target.checked)
              }}
            />
          </div>
          <div className="mt-6">
            <EuiCheckbox
              id={"collect-code-early"}
              disabled={status === CodeStatus.Active}
              label={
                <EuiText size="s">
                  {t("promotion.editor.collect-code-early")}
                </EuiText>
              }
              checked={form.values.isCanCollectCodeEarly}
              onChange={(e) => {
                form.setFieldValue("isCanCollectCodeEarly", e.target.checked)
              }}
            />
          </div>
          {form.values.isCanCollectCodeEarly && (
            <>
              <div className="mt-2">
                <EuiText size="s" className="font-bold">
                  {t("promotion.editor.collect-code-date-time-before")}
                </EuiText>
              </div>
              <div className="mt-2">
                <EuiDatePicker
                  disabled={status === CodeStatus.Active}
                  selected={form.values.collectCodeStartAt}
                  onChange={(date) => {
                    form.setFieldValue("collectCodeStartAt", date)
                  }}
                  isInvalid={!!form.errors.collectCodeStartAt}
                  maxDate={form.values.startAt}
                  showTimeSelect
                  dateFormat={datePickerDateFormat}
                  timeFormat={datePickerTimeFormat}
                  placeholder={t(
                    "promotion.editor.collect-code-date-time-before",
                  )}
                />
              </div>
            </>
          )}

          <Divider />

          <EuiText>
            <h2 className="font-bold">{t("promotion.editor.join-packages")}</h2>
          </EuiText>
          <div className="mt-4">
            <RadioYesNo
              id={"select-join-package"}
              value={form.values.applyToAllPackage}
              onChange={(v) => form.setFieldValue("applyToAllPackage", v)}
              yesLabel={t("promotion.editor.join-package-all")}
              noLabel={t("promotion.editor.join-package-custom")}
            />
            {!form.values.applyToAllPackage && (
              <div className="mt-6">
                <SelectJoinPackage
                  selectPackages={form.values.joinPackage || []}
                  onChange={(packageIds) =>
                    form.setFieldValue("joinPackage", packageIds)
                  }
                />
              </div>
            )}
          </div>

          <Divider />

          <TextArea
            label={t("promotion.editor.additional")}
            optional
            {...form.getFieldProps("additional")}
          />

          <Divider />

          <TextArea
            label={t("promotion.editor.noted")}
            optional
            {...form.getFieldProps("note")}
          />
        </div>

        <div className="sticky top-10">
          <SummaryPromotionCard
            isLoading={isLoading}
            onClick={form.submitForm}
            btnText={
              id
                ? t("common.save")
                : t("topup-promotion.editor.create-promotion")
            }
          >
            <EuiText size="s" className="font-bold">
              {form.values.name || t("promotion.editor.promotion-name")}
            </EuiText>
            <SummaryTopupCouponDetail
              code={form.values.code}
              startAt={form.values.startAt}
              endAt={form.values.endAt}
              additional={form.values.additional}
              isDiscount={form.values.couponType === CouponType.Discount}
              isByAmount={form.values.effectType === CouponEffectType.Amount}
              amountValue={form.values.effect?.amount?.value}
              percentageValue={form.values.effect?.percentage?.value}
              percentageHaveMaxAmount={
                form.values.effect?.percentage?.haveMaxAmount
              }
              percentageMaxAmount={form.values.effect?.percentage?.maxAmount}
            />
          </SummaryPromotionCard>
        </div>
      </div>
    </div>
  )
}
