import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import * as Yup from "yup"
import { FileForm } from "../form"

export type SquareBannerForm = {
  id?: string
  name?: string
  cover?: string
  link?: string
  alt?: string
  fileInput?: FileForm["fileInput"]
  downloadUrl?: string
}

export type SquareBannerInput =
  definitions["webutility.admin.v1.SquareBannerInput"] & {
    downloadUrl?: string
  }

export const SquareBannerFormValidator = Yup.object({
  name: Yup.string().required(),
  link: Yup.string().required(),
  downloadUrl: Yup.string().required(),
})
