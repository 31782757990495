import { useStore } from "@app/hook/store"
import { definitions } from "@app/vendor/shop-specs/shop_service"
import { EuiButton } from "@elastic/eui"
import { css } from "@emotion/react"
import classNames from "classnames"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import useSWR from "swr"
import {
  getBankIconAndColorPath,
  useGetBankNameFromBankCode,
  useWithdrawAccount,
} from "../hook"

export const ContainerShopBankAccountCard = (props: {
  showAccountApprovalButton?: boolean
}) => {
  const { getWithdrawAccount } = useWithdrawAccount()
  const { shopId } = useParams()
  const { notificationStore } = useStore()

  const {
    data,
    error,
    isLoading: loading,
  } = useSWR(["/withdraw-account", shopId ?? ""], async (args: string[]) => {
    if (args[1]) {
      const res = await getWithdrawAccount(args[1])
      return {
        withdrawAccount: res.data.withdrawAccount,
      }
    }
    return
  })

  useEffect(() => {
    if (error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: error.message,
      })
    }
  }, [error, notificationStore])

  if (data?.withdrawAccount?.companyWithdrawAccount) {
    return (
      <ShopBankAccountCard
        loading={loading}
        showAccountApprovalButton={props.showAccountApprovalButton}
        bankCode={
          data?.withdrawAccount?.companyWithdrawAccount.bankAccount?.bankCode ??
          "BANK_CODE_UNSPECIFIED"
        }
        bankAccountName={
          data?.withdrawAccount?.companyWithdrawAccount.bankAccount?.ownerName
        }
        bankAccountNo={
          data?.withdrawAccount?.companyWithdrawAccount.bankAccount
            ?.accountNumber
        }
        status={data?.withdrawAccount?.verificationStatus}
      />
    )
  }

  if (data?.withdrawAccount?.individualWithdrawAccount) {
    return (
      <ShopBankAccountCard
        loading={loading}
        showAccountApprovalButton={props.showAccountApprovalButton}
        bankCode={
          data?.withdrawAccount?.individualWithdrawAccount.bankAccount
            ?.bankCode ?? "BANK_CODE_UNSPECIFIED"
        }
        bankAccountName={
          data?.withdrawAccount?.individualWithdrawAccount.bankAccount
            ?.ownerName
        }
        bankAccountNo={
          data?.withdrawAccount?.individualWithdrawAccount.bankAccount
            ?.accountNumber
        }
        status={data?.withdrawAccount?.verificationStatus}
      />
    )
  }

  return <ShopBankAccountCard loading={loading} />
}

const ShopBankAccountCard: React.FC<{
  bankCode?: definitions["shop.v1.BankCode"]
  bankAccountNo?: string
  bankAccountName?: string
  loading: boolean
  status?: definitions["shop.v1.WithdrawAccountVerificationStatus"]
  showAccountApprovalButton?: boolean
}> = (props: {
  bankCode?: definitions["shop.v1.BankCode"]
  bankAccountNo?: string
  bankAccountName?: string
  loading: boolean
  status?: definitions["shop.v1.WithdrawAccountVerificationStatus"]
  showAccountApprovalButton?: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { shopId } = useParams()
  const bankName = useGetBankNameFromBankCode(
    props?.bankCode ?? "BANK_CODE_UNSPECIFIED",
  )
  const bankIcon = getBankIconAndColorPath(props.bankCode)

  return (
    <div className="flex flex-col justify-between rounded-xl border border-solid border-[#D3DAE6] p-6">
      <div className={`flex flex-col space-y-4 `}>
        <div className="text-m font-bold">
          {t("shop-information.bank-account.title")}
        </div>

        {!props.loading && (
          <div className={classNames("flex", bankIcon.path ? "space-x-4" : "")}>
            {!props.loading && bankIcon.path ? (
              <img
                alt={props.bankCode}
                src={bankIcon.path}
                css={css`
                  background-color: ${bankIcon.backgroundColor};
                `}
                className={`h-[50px] w-[50px] rounded-full`}
              />
            ) : null}
            <div>
              {!props?.loading &&
                props.status ===
                  "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_APPROVED" && (
                  <div className="mb-2 w-fit rounded-[4px] bg-success px-2 py-[1px] text-xs">
                    {t("shop-information.bank-account-status.approved")}
                  </div>
                )}
              {!props?.loading &&
                props.status ===
                  "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_VERIFYING" && (
                  <div className="mb-2 w-fit rounded-[4px] bg-warning px-2 py-[1px] text-xs">
                    {t("shop-information.bank-account-status.verifying")}
                  </div>
                )}
              {!props?.loading && props.bankAccountNo ? (
                <>
                  <div className="">{bankName}</div>
                  <div className="mb-4 font-bold">{props?.bankAccountNo}</div>
                  <div className="text-grey">{props?.bankAccountName}</div>
                </>
              ) : null}
            </div>
          </div>
        )}
        {!props.bankCode && (
          <div className="flex justify-center pt-[95px] font-bold text-grey">
            {t("shop-information.bank-account.no-data")}
          </div>
        )}
      </div>

      {props.showAccountApprovalButton &&
        props?.status === "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_VERIFYING" && (
          <EuiButton
            fill
            onClick={() => {
              navigate(`/seller-approve/${shopId}`)
            }}
          >
            {t("shop-information.bank-account.verify-document")}
          </EuiButton>
        )}
      {props.showAccountApprovalButton &&
        (props?.status === "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_APPROVED" ||
          props?.status ===
            "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_REJECTED") && (
          <EuiButton
            fill
            onClick={() => {
              navigate(`/seller-approve/${shopId}`)
            }}
          >
            {t("shop-information.bank-account.view-document")}
          </EuiButton>
        )}
    </div>
  )
}
