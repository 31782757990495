import React from "react"
import { Title } from "@app/component"
import {
  EuiButton,
  EuiPagination,
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderButton,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useListAddCoinHistory } from "./hook"
import { constants } from "@app/model/constants"
import { getAssetPath } from "@app/model/asset"
import { definitions } from "@app/vendor/payment-specs/payment_service"
import { UilPlus } from "@iconscout/react-unicons"
import { hooks } from "@reeeed-mp/ui-common"

const itemPerPage = 10

export const AddReeeedCoinListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { query, setQuery } = hooks.useURLQuery()
  const currentPage = query.page ? Number(query.page) - 1 : 0
  const sorter =
    query.sorter as definitions["payment.admin.v1.AddCoinHistorySorter"]

  const { data } = useListAddCoinHistory({
    sorter,
    option: {
      limit: itemPerPage,
      skip: currentPage * itemPerPage,
    },
  })

  return (
    <div>
      <div className="flex flex-row justify-between">
        <Title text={t("add-reeeed-coin.list.title")} />
        <EuiButton
          fill
          style={{
            width: "240px",
          }}
          onClick={() => {
            navigate("/reeeed-coin/create")
          }}
        >
          <UilPlus width={16} height={16} />
          {t("add-reeeed-coin.list.add-coin-button")}
        </EuiButton>
      </div>
      <>
        <EuiTable>
          <EuiTableHeader>
            <EuiTableHeaderCell>
              {t("add-reeeed-coin.list.table.name")}
            </EuiTableHeaderCell>
            <EuiTableHeaderCell align="center" width={160}>
              {t("add-reeeed-coin.list.table.total-user")}
            </EuiTableHeaderCell>
            <EuiTableHeaderCell align="center" width={160}>
              {t("add-reeeed-coin.list.table.total-coin")}
            </EuiTableHeaderCell>
            <EuiTableHeaderButton
              style={{
                width: 240,
              }}
              className="flex flex-row"
              css={css`
                .euiTableCellContent__text {
                  display: flex;
                  flex-direction: row;
                  cursor: pointer;
                }
                & > span {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 32px;
                  padding: 8px;
                }
              `}
              onClick={() => {
                if (sorter === "ADD_COIN_HISTORY_SORTER_CREATED_AT_ASC") {
                  setQuery(
                    {
                      ...query,
                      sorter: "ADD_COIN_HISTORY_SORTER_CREATED_AT_DESC",
                    },
                    { replace: true },
                  )
                } else if (
                  sorter === "ADD_COIN_HISTORY_SORTER_CREATED_AT_DESC"
                ) {
                  setQuery(
                    { ...query, sorter: "ADD_COIN_HISTORY_SORTER_UNSPECIFIED" },
                    { replace: true },
                  )
                } else {
                  setQuery(
                    {
                      ...query,
                      sorter: "ADD_COIN_HISTORY_SORTER_CREATED_AT_ASC",
                    },
                    { replace: true },
                  )
                }
              }}
            >
              <EuiText>
                <p className="text-xs font-bold">
                  {t("add-reeeed-coin.list.table.created-at")}
                </p>
              </EuiText>
              <img className="ml-1" src={getAssetPath("images/filter.svg")} />
            </EuiTableHeaderButton>
          </EuiTableHeader>
          {data?.history?.map((history) => {
            return (
              <EuiTableRow
                onClick={() => {
                  navigate(`/reeeed-coin/${history.id}`)
                }}
              >
                <EuiTableRowCell>{history.name}</EuiTableRowCell>
                <EuiTableRowCell align="center" width={160}>
                  {history.totalUser}
                </EuiTableRowCell>
                <EuiTableRowCell align="center" width={160}>
                  {Number(history.totalCoin) / constants.power10OfDecimalPrice}
                </EuiTableRowCell>
                <EuiTableRowCell width={240}>
                  {t("common.datetime", {
                    value: history.createdAt,
                  })}
                </EuiTableRowCell>
              </EuiTableRow>
            )
          })}
        </EuiTable>
        <div className="flex flex-row justify-end">
          <EuiPagination
            css={css({
              ".euiPagination__list": {
                "margin-inline-start": 0,
                "list-style-type": "none",
              },
            })}
            className="mt-4"
            pageCount={Math.ceil(Number(data?.count ?? "0") / itemPerPage)}
            activePage={currentPage}
            onPageClick={(pageIndex) => {
              setQuery(
                { ...query, page: String(pageIndex + 1) },
                { replace: true },
              )
            }}
          />
        </div>
      </>
    </div>
  )
}
