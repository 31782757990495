import { UploadItemSection } from "@app/component/upload-and-render"
import { EuiText } from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { getProductsTemplate } from "../products/hook/product"
import {
  PopularProductDetailConfigProps,
  RecommendedProductDetailConfigProps,
} from "../products/hook/update-detail"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { useHomeManagementStore } from "../store/ctx"

export const ProductUploadFromXLSX = (props: {
  form: PopularProductDetailConfigProps | RecommendedProductDetailConfigProps
  selectedSection: { [key: string]: any } | undefined
  handleUploadProducts: (files: FileList | null) => Promise<void>
  isSubmitting: boolean
  errorIds?: string[]
}) => {
  const { t } = useTranslation()
  const { refreshing } = useHomeManagementStore()
  const displayItems = (
    props.selectedSection?.adminSelectedProducts ||
    props.form.values.adminSelectedProducts ||
    []
  ).slice(0, 20)

  return (
    <div className="flex flex-col gap-4">
      <div className="mt-4 flex items-center gap-2">
        <EuiText size="xs">
          <h3>{t("home-management.product.header")}</h3>
        </EuiText>
        <EuiText size="xs">
          <p className="text-eui-subduedText">
            {t("home-management.product.hint")}
          </p>
        </EuiText>
      </div>
      <UploadItemSection<{
        productName?: string
        productType?: definitions["shop.v1.ProductType"]
        productId?: string
        shopName?: string
      }>
        errorIds={props.errorIds ?? []}
        export={getProductsTemplate}
        loading={props.form.isSubmitting || props.isSubmitting || refreshing}
        handleUpload={props.handleUploadProducts}
        items={displayItems}
        tableColumns={[
          {
            field: "productName",
            name: "name",
          },
          {
            field: "productType",
            name: "Type",
            render: (productType: string) => {
              return t(productType)
            },
            width: "110px",
          },
          {
            field: "productId",
            name: "ID",
          },
          {
            field: "shopName",
            name: "Shop",
          },
        ]}
        pagination={{
          pageIndex: 0,
          pageSize: 10,
          totalItemCount: displayItems.length,
          showPerPageOptions: false,
        }}
        defaultFileName="Template.xlsx"
      />
    </div>
  )
}
