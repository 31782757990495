import { useApi } from "@app/hook/api"
import useSWR from "swr"

export const useGetOrder = (id: string) => {
  const { webBff } = useApi()
  const getter = webBff("/admin/order/{id}").method("get").create()

  return useSWR(["/admin/order/{id}", id], async ([, id]) => {
    return getter({ id })
  })
}

export const useGetTrackPaymentStatus = (orderId?: string) => {
  const { payment } = useApi()
  const getter = payment("/admin/track-payment-statuses/{orderId}")
    .method("get")
    .create()

  return useSWR(
    ["/admin/track-payment-statuses/{orderId}", orderId],
    async ([, orderId]) => {
      if (!orderId) return null

      return getter({ orderId })
    },
  )
}

export const useGetDeliveryTrackingStatus = (orderId?: string) => {
  const { delivery } = useApi()
  const getter = delivery("/raw-delivery-tracking/{orderId}")
    .method("get")
    .create()

  return useSWR(
    ["/raw-delivery-tracking/{orderId}", orderId],
    async ([, orderId]) => {
      if (!orderId) return null

      return getter({ orderId })
    },
  )
}
