import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import {
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"

import {
  MpTextEditor,
  stringifiedEmptyEditorState,
} from "@reeeed-mp/text-editor"
import { useTranslation } from "react-i18next"

export const EVoucherDetailModal = (props: {
  open: boolean
  onClose: () => void
  product: definitions["webbff.seller.v1.DigitalContentOrderProduct"]
  eVoucherCode?: definitions["order.v1.EVoucher"]
}) => {
  const { t } = useTranslation()
  if (!props.open) {
    return null
  }

  return (
    <EuiModal onClose={props.onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t("order-management.detail-page.e-voucher.title")}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <div className="grid auto-cols-max grid-flow-col gap-4">
          <img
            src={props.product.eVoucher?.cover}
            alt=""
            css={css({
              width: "85px",
              height: "85px",
            })}
          />
          <div className="flex w-full grow">
            <div className="flex flex-col gap-4">
              <EuiText size="s">
                <p>{props.product.eVoucher?.name}</p>
              </EuiText>
              <EuiText
                size="xs"
                className="w-fit rounded-lg bg-ci-grey-light p-2"
              >
                {t("order-management.detail-page.e-voucher.label")}
              </EuiText>
            </div>
          </div>
        </div>

        <EuiHorizontalRule margin="s" />

        <div className="rounded-lg bg-ci-grey-light p-6">
          <div className="flex flex-col gap-4">
            <div className="flex justify-start gap-4">
              <div className="w-1/5">
                <EuiText size="s">
                  <p>{t("order-management.detail-page.e-voucher.email")}</p>
                </EuiText>
              </div>
              <div className="w-4/5">
                <EuiText size="s">
                  <p>{props.eVoucherCode?.eVoucherEmail}</p>
                </EuiText>
              </div>
            </div>

            <div className="flex justify-start gap-4">
              <div className="w-1/5">
                <EuiText size="s">
                  <p>{t("order-management.detail-page.e-voucher.code")}</p>
                </EuiText>
              </div>
              <div className="w-4/5">
                <EuiText size="s" className="text-ci-primary">
                  {(props.eVoucherCode?.eVoucherCodes || []).map(
                    ({ code }, index) => (
                      <p key={index}>{code}</p>
                    ),
                  )}
                </EuiText>
              </div>
            </div>

            <div className="flex justify-start gap-4">
              <div className="w-1/5">
                <EuiText size="s">
                  <p>{t("order-management.detail-page.e-voucher.condition")}</p>
                </EuiText>
              </div>
              <div className="w-4/5">
                <MpTextEditor
                  isEditable={false}
                  initEditorState={
                    props.product.eVoucher?.usageDetail ||
                    stringifiedEmptyEditorState
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </EuiModalBody>
    </EuiModal>
  )
}
