import { NavbarRoute } from "@app/component/navbar/left"
import { HeroBannerListPage } from "@app/page/home-management-2/hero-banner/list"
import { HeroBannerEditorPage } from "@app/page/home-management-2/hero-banner/edit"
import { BannerEditorPage } from "@app/page/home-management-2/small-banner/edit"
import { SmallBannerListPage } from "@app/page/home-management-2/small-banner/list"
import {
  FixedMediumBannerEditorPage,
  OverflowMediumBannerEditorPage,
} from "@app/page/home-management-2/medium-banner/editor"
import {
  FixedMediumBannerListPage,
  OverflowMediumBannerListPage,
} from "@app/page/home-management-2/medium-banner/list"
import { WriterBannerEditorPage } from "@app/page/home-management-2/writer-banner/edit"
import { WriterBannerListPage } from "@app/page/home-management-2/writer-banner/list"
import { HomeManagementNew } from "@app/page/home-management-2"
import {
  EditHighlightMenu,
  EditMainMenu,
} from "@app/page/home-management-2/main-menu/edit"

export const flexibleHomepageManagementRoute: NavbarRoute["subRoutes"] = [
  {
    path: "/home-management",
    i18nKey: "home-management",
    component: <HomeManagementNew />,
  },
  {
    path: "/home-management/main-menu/:id",
    i18nKey: "home-management",
    component: <EditMainMenu />,
    hidden: true,
  },
  {
    path: "/home-management/highlight-menu/:id",
    i18nKey: "home-management",
    component: <EditHighlightMenu />,
    hidden: true,
  },
  {
    path: "/home-management/hero-banner",
    i18nKey: "home-management",
    component: <HeroBannerListPage />,
    hidden: true,
  },
  {
    path: "/home-management/hero-banner/:id",
    i18nKey: "home-management",
    component: <HeroBannerEditorPage />,
    hidden: true,
  },
  {
    path: "/home-management/fixed-medium-banner/:sectionKey",
    i18nKey: "home-management",
    component: <FixedMediumBannerListPage />,
    hidden: true,
  },
  {
    path: "/home-management/overflow-medium-banner/:sectionKey",
    i18nKey: "home-management",
    component: <OverflowMediumBannerListPage />,
    hidden: true,
  },
  {
    path: "/home-management/small-banner/:sectionKey",
    i18nKey: "home-management",
    component: <SmallBannerListPage />,
    hidden: true,
  },
  {
    path: "/home-management/writer-banner/:sectionKey",
    i18nKey: "home-management",
    component: <WriterBannerListPage />,
    hidden: true,
  },
  {
    path: "/home-management/fixed-medium-banner/:sectionKey/editor/:bannerKey",
    i18nKey: "home-management",
    component: <FixedMediumBannerEditorPage />,
    hidden: true,
  },
  {
    path: "/home-management/overflow-medium-banner/:sectionKey/editor/:bannerKey",
    i18nKey: "home-management",
    component: <OverflowMediumBannerEditorPage />,
    hidden: true,
  },
  {
    path: "/home-management/small-banner/:sectionKey/editor/:bannerKey",
    i18nKey: "home-management",
    component: <BannerEditorPage />,
    hidden: true,
  },
  {
    path: "/home-management/writer-banner/:sectionKey/editor/:bannerKey",
    i18nKey: "home-management",
    component: <WriterBannerEditorPage />,
    hidden: true,
  },
]

export const flexibleHomepageManagementRouteWithSuffix: NavbarRoute["subRoutes"] =
  flexibleHomepageManagementRoute.map((route) => ({
    ...route,
    hidden: true,
    path: route.path.replace("/home-management", "/home-management-2"),
  }))
