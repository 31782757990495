import { Page } from "./page"
import { TopupPackageStoreProvider } from "./store"

export const TopupPackagePage = () => {
  return (
    <TopupPackageStoreProvider>
      <Page />
    </TopupPackageStoreProvider>
  )
}
