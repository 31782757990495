import { HomeManagementPopOver } from "@app/component/home-management/pop-over"
import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { HomeManagementMode } from "@app/model/homepage/homepage"
import { LIGHTEST_GREY } from "@app/utils/color"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  DropResult,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiFieldText,
  EuiTab,
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTabs,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilGripHorizontalLine, UilSearch } from "@iconscout/react-unicons"
import moment from "moment"
import "moment/locale/th"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ReorderButton } from "../component/reorder-button"
import { useHomeManagementStore } from "../store/ctx"

const NAME_WIDTH_PX = "450"
const START_DATE_WIDTH_PX = "300"
const CREATE_AT_WIDTH_PX = "250"
const ACTIVATED_ID = "activate"
const DEACTIVATE_ID = "deactivate"

export const ListHighlightBanner: React.FC = () => {
  const { t } = useTranslation()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "homepage-management.highlight-banner",
  })
  const [searchText, setSearchText] = useState("")
  const homeManagementStore = useHomeManagementStore()
  const [mode, setMode] = useState<HomeManagementMode>(HomeManagementMode.IDLE)
  const navigate = useNavigate()

  const [selectedTabId, setSelectedTabId] = useState(ACTIVATED_ID)

  const Tabs: {
    id: string
    onSelect: () => void
    content: string
  }[] = [
    {
      id: ACTIVATED_ID,
      onSelect: () => {
        setSelectedTabId(ACTIVATED_ID)
      },
      content: editorT("activate"),
    },
    {
      id: DEACTIVATE_ID,
      onSelect: () => {
        setSelectedTabId(DEACTIVATE_ID)
      },
      content: editorT("not-activate"),
    },
  ]

  const filterActivatedHighlight = useCallback(
    (h: definitions["webutility.admin.v1.Banner"]) => {
      const now = new Date()
      const activated =
        moment(h.startDate).isBefore(now) && moment(h.endDate).isAfter(now)

      return activated && h.name?.includes(searchText)
    },
    [searchText],
  )

  const filterDeactivatedHighlight = useCallback(
    (h: definitions["webutility.admin.v1.Banner"]) => {
      const now = new Date()
      const deactivated = !(
        moment(h.startDate).isBefore(now) && moment(h.endDate).isAfter(now)
      )
      return deactivated && h.name?.includes(searchText)
    },
    [searchText],
  )

  const [deactivatedHighlight, setDeactivatedHighlight] = useState<
    definitions["webutility.admin.v1.Banner"][]
  >(
    homeManagementStore.homepage?.highlightBannerSection?.banners?.filter(
      filterDeactivatedHighlight,
    ) ?? [],
  )

  const [activatedHighlight, setActivatedHighlight] = useState<
    definitions["webutility.admin.v1.Banner"][]
  >(
    homeManagementStore.homepage?.highlightBannerSection?.banners?.filter(
      filterActivatedHighlight,
    ) ?? [],
  )

  const defaultColumns: Array<
    EuiBasicTableColumn<definitions["webutility.admin.v1.Banner"]>
  > = [
    {
      field: "name",
      name: editorT("banner-title-table"),

      truncateText: true,
      width: NAME_WIDTH_PX,
      sortable: true,
    },
    {
      field: "startDate",
      name: editorT("banner-duration"),
      truncateText: true,
      width: START_DATE_WIDTH_PX,
    },
    {
      field: "createdAt",
      name: editorT("banner-created-at"),
      truncateText: true,
      width: CREATE_AT_WIDTH_PX,
    },
  ]

  const [columns] =
    useState<
      Array<EuiBasicTableColumn<definitions["webutility.admin.v1.Banner"]>>
    >(defaultColumns)

  const renderCell = (
    data: definitions["webutility.admin.v1.Banner"],
    index: number,
  ) => {
    return (
      <EuiTableRowCell
        css={css`
          .euiTableCellContent__text {
            width: 100%;
          }
          background-color: white;
        `}
      >
        <EuiDraggable
          isDragDisabled={mode !== HomeManagementMode.REORDER}
          key={data.id}
          index={index}
          draggableId={data.id ?? index.toString()}
        >
          {() => {
            return (
              <div className="my-4 flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <div
                    css={css`
                      width: ${NAME_WIDTH_PX}px;
                    `}
                    className="flex flex-row"
                  >
                    {mode === HomeManagementMode.REORDER ? (
                      <UilGripHorizontalLine className="mr-5" />
                    ) : null}
                    <p className="mb-0">{data.name}</p>
                  </div>

                  <p
                    className="mb-0"
                    css={css`
                      width: ${START_DATE_WIDTH_PX}px;
                    `}
                  >
                    {moment(data.startDate)
                      .add(543, "years")
                      .locale("th")
                      .format("DD MMM YYYY HH:mm น.")}{" "}
                    -{" "}
                    {moment(data.endDate)
                      .add(543, "years")
                      .locale("th")
                      .format("DD MMM YYYY HH:mm น.")}
                  </p>
                  <p
                    className="mb-0"
                    css={css`
                      width: ${CREATE_AT_WIDTH_PX}px;
                    `}
                  >
                    {moment(data.createdAt)
                      .locale("th")
                      .format("DD MMM YY HH:mm น.")}
                  </p>
                </div>
                <div className="flex items-center justify-end">
                  <HomeManagementPopOver
                    url={data.url ?? ""}
                    onPressDelete={() => {
                      const index =
                        homeManagementStore.homepage?.highlightBannerSection?.banners?.findIndex(
                          (b) => {
                            return b.id === data.id
                          },
                        )
                      if (index !== undefined && index !== -1) {
                        homeManagementStore.update("highlightBannerSection", {
                          ...homeManagementStore.homepage
                            ?.highlightBannerSection,
                          banners: [
                            ...(homeManagementStore.homepage?.highlightBannerSection?.banners?.slice(
                              0,
                              index,
                            ) ?? []),
                            ...(homeManagementStore.homepage?.highlightBannerSection?.banners?.slice(
                              index + 1,
                            ) ?? []),
                          ],
                        })
                      }
                    }}
                    onPressEdit={() => {
                      navigate(`/home-management/highlight-banner/${data.id}`)
                    }}
                  />
                </div>
              </div>
            )
          }}
        </EuiDraggable>
      </EuiTableRowCell>
    )
  }

  const onDragEnd = (result: DropResult) => {
    if (result.source && result.destination) {
      if (deactivatedHighlight && selectedTabId === DEACTIVATE_ID) {
        const items = euiDragDropReorder(
          deactivatedHighlight,
          result.source.index,
          result.destination.index,
        )

        setDeactivatedHighlight(items)
      }

      if (activatedHighlight && selectedTabId === ACTIVATED_ID) {
        const items = euiDragDropReorder(
          activatedHighlight,
          result.source.index,
          result.destination.index,
        )

        setActivatedHighlight(items)
      }
    }
  }

  useEffect(() => {
    if (homeManagementStore.homepage?.highlightBannerSection?.banners) {
      setDeactivatedHighlight(
        homeManagementStore.homepage?.highlightBannerSection?.banners?.filter(
          filterDeactivatedHighlight,
        ) ?? [],
      )
      setActivatedHighlight(
        homeManagementStore.homepage?.highlightBannerSection?.banners?.filter(
          filterActivatedHighlight,
        ) ?? [],
      )
    }
  }, [
    filterActivatedHighlight,
    filterDeactivatedHighlight,
    homeManagementStore.homepage?.highlightBannerSection?.banners,
  ])

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      {
        <>
          <div className="mb-9 flex flex-row justify-between">
            <NavigationHeaderButton
              title={"Highlight Banner"}
              onClick={() => {
                navigate("/home-management")
              }}
            />
            <div className="flex flex-row">
              {mode === HomeManagementMode.IDLE ? (
                <>
                  <ReorderButton
                    disabled={searchText.length > 0}
                    onPress={() => {
                      setMode(HomeManagementMode.REORDER)
                    }}
                  />
                  <EuiButton
                    className="ml-4"
                    css={css`
                      width: 240px;
                      height: 40px;
                    `}
                    fill
                    onClick={() => {
                      navigate(`/home-management/highlight-banner/create`)
                    }}
                  >
                    {editorT("add-banner")}
                  </EuiButton>
                </>
              ) : null}
              {mode === HomeManagementMode.REORDER ? (
                <>
                  <EuiButtonEmpty
                    color="text"
                    onClick={() => {
                      setMode(HomeManagementMode.IDLE)
                      setDeactivatedHighlight(
                        homeManagementStore.homepage?.highlightBannerSection
                          ?.banners ?? [],
                      )
                    }}
                    css={css`
                      width: 240px;
                      height: 40px;
                    `}
                  >
                    {t("common.cancel")}
                  </EuiButtonEmpty>
                  <EuiButton
                    fill
                    color="primary"
                    onClick={() => {
                      setMode(HomeManagementMode.IDLE)
                      homeManagementStore.update("highlightBannerSection", {
                        ...homeManagementStore.homepage?.highlightBannerSection,
                        banners: [
                          ...activatedHighlight,
                          ...deactivatedHighlight,
                        ],
                      })
                    }}
                    css={css`
                      width: 240px;
                      height: 40px;
                    `}
                  >
                    {t("common.save")}
                  </EuiButton>
                </>
              ) : null}
            </div>
          </div>
          <div className="mb-10 flex flex-row justify-between">
            <EuiTabs bottomBorder={false}>
              {Tabs.map((t) => {
                return (
                  <EuiTab
                    key={t.id}
                    isSelected={t.id === selectedTabId}
                    onClick={t.onSelect}
                    css={css`
                      .euiTab__content {
                        color: ${t.id !== selectedTabId ? LIGHTEST_GREY : ""};
                      }
                    `}
                  >
                    {t.content}
                  </EuiTab>
                )
              })}
            </EuiTabs>
            <div>
              <EuiFieldText
                icon={<UilSearch />}
                placeholder="ค้นหาชื่อ Banner"
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
                value={searchText}
              />
            </div>
          </div>
          <EuiDragDropContext onDragEnd={onDragEnd}>
            <EuiDroppable
              droppableId="LIST_HIGHLIGHT_BANNER"
              isDropDisabled={mode !== HomeManagementMode.REORDER}
              direction="vertical"
              css={css`
                height: 100vh;
              `}
            >
              <EuiTable responsive={false}>
                <EuiTableHeader className="border-x-0 border-b border-t-0 border-solid border-grey-light">
                  <EuiTableHeaderCell
                    css={css`
                      background-color: white;

                      .euiTableCellContent__text {
                        display: flex;
                        flex-direction: row;
                      }
                    `}
                    className="flex flex-row "
                  >
                    {columns.map((c, index) => {
                      return (
                        <p
                          className="mb-0"
                          key={c.description ?? index.toString()}
                          css={css`
                            width: ${c.width}px;
                          `}
                        >
                          {c.name}
                        </p>
                      )
                    })}
                  </EuiTableHeaderCell>
                </EuiTableHeader>
                {selectedTabId === DEACTIVATE_ID && deactivatedHighlight
                  ? deactivatedHighlight.map((h, index) => {
                      return (
                        <EuiTableRow
                          key={h.id ?? index.toString()}
                          css={css`
                            background-color: #fafbfd;
                          `}
                        >
                          {renderCell(h, index)}
                        </EuiTableRow>
                      )
                    })
                  : []}
                {selectedTabId === ACTIVATED_ID && activatedHighlight
                  ? activatedHighlight.map((h, index) => {
                      return (
                        <EuiTableRow
                          key={h.id ?? index.toString()}
                          css={css`
                            background-color: #fafbfd;
                          `}
                        >
                          {renderCell(h, index)}
                        </EuiTableRow>
                      )
                    })
                  : []}
              </EuiTable>
            </EuiDroppable>
          </EuiDragDropContext>
        </>
      }
    </div>
  )
}
