import { NavbarRoute } from "@app/component/navbar/left"
import { HomeManagement } from "@app/page/home-management"
import { EditorBanner } from "@app/page/home-management/banner/editor"
import { EditorCategory } from "@app/page/home-management/category/editor"
import { ListCategory } from "@app/page/home-management/category/list"
import { EditorHighlightBanner } from "@app/page/home-management/highlight-banner/editor"
import { ListHighlightBanner } from "@app/page/home-management/highlight-banner/list"
import { EditorProductHighlight } from "@app/page/home-management/product-highlight/editor"
import { EditorRecommendedShop } from "@app/page/home-management/recommended-shop/editor"
import { ListRecommendedShop } from "@app/page/home-management/recommended-shop/list"
import { EditorSquareBanner } from "@app/page/home-management/square-banner/editor"
import { ListSquareBanner } from "@app/page/home-management/square-banner/list"
import { EditorSubBanner } from "@app/page/home-management/sub-banner/editor"
import { ListSubBanner } from "@app/page/home-management/sub-banner/list"
import { EditorWriter } from "@app/page/home-management/writer/editor"
import { ListWriter } from "@app/page/home-management/writer/list"

export const homepageManagementRoute: NavbarRoute["subRoutes"] = [
  {
    path: "/home-management",
    i18nKey: "home-management",
    component: <HomeManagement />,
  },
  {
    path: "/home-management/highlight-banner/:id",
    i18nKey: "home-management",
    component: <EditorHighlightBanner />,
    hidden: true,
  },
  {
    path: "/home-management/highlight-banner",
    i18nKey: "home-management",
    component: <ListHighlightBanner />,
    hidden: true,
  },
  {
    path: "/home-management/sub-banner-1",
    i18nKey: "home-management",
    component: <EditorBanner page="subBanner1" title="Sub Banner 1" />,
    hidden: true,
  },
  {
    path: "/home-management/sub-banner-2",
    i18nKey: "home-management",
    component: <EditorBanner page="subBanner2" title="Sub Banner 2" />,
    hidden: true,
  },
  {
    path: "/home-management/sub-banner-3",
    i18nKey: "home-management",
    component: <EditorBanner page="subBanner3" title="Sub Banner 3" />,
    hidden: true,
  },
  {
    path: "/home-management/category/:id",
    i18nKey: "home-management",
    component: <EditorCategory />,
    hidden: true,
  },
  {
    path: "/home-management/category",
    i18nKey: "home-management",
    component: <ListCategory />,
    hidden: true,
  },
  {
    path: "/home-management/recommended-shop/:id",
    i18nKey: "home-management",
    component: <EditorRecommendedShop />,
    hidden: true,
  },
  {
    path: "/home-management/recommended-shop",
    i18nKey: "home-management",
    component: <ListRecommendedShop />,
    hidden: true,
  },
  {
    path: "/home-management/square-banner",
    i18nKey: "square-banner",
    component: <ListSquareBanner />,
    hidden: true,
  },
  {
    path: "/home-management/square-banner/:id",
    i18nKey: "square-banner",
    component: <EditorSquareBanner />,
    hidden: true,
  },
  {
    path: "/home-management/writer/:id",
    i18nKey: "home-management",
    component: <EditorWriter />,
    hidden: true,
  },
  {
    path: "/home-management/writer",
    i18nKey: "home-management",
    component: <ListWriter />,
    hidden: true,
  },
  {
    path: "/home-management/product-highlight-weekly",
    i18nKey: "home-management",
    component: <EditorProductHighlight page="highlightProduct1Section" />,
    hidden: true,
  },
  {
    path: "/home-management/product-highlight-monthly",
    i18nKey: "home-management",
    component: <EditorProductHighlight page="highlightProduct2Section" />,
    hidden: true,
  },
  {
    path: "/home-management/product-highlight-recently",
    i18nKey: "home-management",
    component: <EditorProductHighlight page="highlightProduct3Section" />,
    hidden: true,
  },
  {
    path: "/home-management/sub-banner",
    i18nKey: "sub-banner",
    component: <ListSubBanner />,
    hidden: true,
  },
  {
    path: "/home-management/sub-banner/:id",
    i18nKey: "sub-banner",
    component: <EditorSubBanner />,
    hidden: true,
  },
]
