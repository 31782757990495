import { useStore } from "@app/hook/store"
import { PaymentChannel } from "@app/model/payment"
import { EuiTitle } from "@elastic/eui"
import { observer } from "mobx-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useTopupPackageStore } from "./store"
import { TopupChannelList } from "./topup-channel-list"
import { TopupPackageList } from "./topup-package-list"

export const Page = observer(() => {
  const [selectPaymentChannel, setSelectPaymentChannel] =
    useState<PaymentChannel | null>(null)
  const { t } = useTranslation()
  const { notificationStore: noti } = useStore()
  const topupPackageStore = useTopupPackageStore()

  return (
    <div>
      <EuiTitle size="m" className="mb-6">
        <h1>{t("nav.topup-package")}</h1>
      </EuiTitle>
      <div className="flex gap-16">
        <TopupChannelList
          selectedPaymentChannel={selectPaymentChannel}
          onPaymentChannelSelect={(c) => {
            if (
              topupPackageStore.newPackages.length ||
              topupPackageStore.updatePackages.length
            ) {
              noti.add({
                title: "Error",
                color: "danger",
                text: t("common.not-saved"),
              })
              return
            }
            setSelectPaymentChannel(c)
          }}
        />
        {selectPaymentChannel && (
          <TopupPackageList channel={selectPaymentChannel} />
        )}
      </div>
    </div>
  )
})
