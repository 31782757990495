import { joinIds, splitIds } from "@app/model"
import { GREY_BUTTON } from "@app/utils/color"
import {
  DropResult,
  EuiButton,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiTextColor,
} from "@elastic/eui"
import { css } from "@emotion/react"
import * as Unicons from "@iconscout/react-unicons"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { Category, isRestrictedCategory } from "../model"

const BACKGROUND_COLOR_PANEL = "#F5F7FA"

export const CategoryPanel: React.FC<{
  categories: Category[]
  selectedId?: string
  onCreate: () => void
  onPressStore: (data?: Category) => void
  onPressDelete: (data: Category) => void
  onSelectedCategoryId: (id: string) => void
  onDragEnd: (result: DropResult) => void
  loading: boolean
}> = (props) => {
  const { t: commonT } = useTranslation("translation", { keyPrefix: "common" })

  const isSelected = (id: string): boolean => {
    if (!props.selectedId) {
      return false
    }

    if (id === props.selectedId) {
      return true
    }

    const parentIds = splitIds(props.selectedId)
    const root = parentIds[0]

    if (root === id) {
      return true
    }

    if (parentIds.length > 1) {
      return joinIds([parentIds[0], parentIds[1]]) === id
    }

    return false
  }

  return (
    <div
      className="rounded-lg px-4 py-4"
      css={css({
        height: "calc(100vh - 200px)",
        backgroundColor: BACKGROUND_COLOR_PANEL,
      })}
    >
      <EuiButton
        fill
        fullWidth
        className="mb-2"
        css={css`
          background-color: ${GREY_BUTTON} !important;
          text-decoration: none !important;
          border: none;
        `}
        onClick={() => {
          props.onCreate()
        }}
      >
        <div className=" flex flex-row items-center">
          <Unicons.UilPlus color="black" className="mr-2" size="18" />
          <EuiText className="mb-0 " color="default">
            {commonT("add")}
          </EuiText>
        </div>
      </EuiButton>

      <div
        className="overflow-auto"
        css={css({
          height: "calc(100% - 40px)",
        })}
      >
        <EuiDragDropContext onDragEnd={props.onDragEnd}>
          <EuiDroppable droppableId="category-droppable-id" spacing="m">
            {props.categories
              .sort((a, b) => (a.order < b.order ? -1 : 1))
              .map((category, idx) => {
                return (
                  <EuiDraggable
                    spacing="m"
                    key={category.id}
                    index={idx}
                    isDragDisabled={props.loading}
                    draggableId={category.id}
                    hasInteractiveChildren={true}
                    className="mb-2 p-0"
                  >
                    {(provided) => {
                      return (
                        <EuiPanel
                          paddingSize="s"
                          hasShadow={false}
                          className={classNames({
                            "bg-primary": isSelected(category.id),
                          })}
                          onClick={() => {
                            props.onSelectedCategoryId(category.id ?? "")
                          }}
                          css={css({
                            ".euiIcon": {
                              color: isSelected(category.id)
                                ? "white !important"
                                : undefined,
                            },
                          })}
                        >
                          <EuiFlexGroup alignItems="center" gutterSize="s">
                            <EuiFlexItem grow={false}>
                              <EuiPanel
                                color="transparent"
                                paddingSize="s"
                                {...provided.dragHandleProps}
                                aria-label="Category Drag Handle"
                              >
                                <EuiIcon
                                  type="grab"
                                  color={
                                    isSelected(category.id)
                                      ? "white"
                                      : "default"
                                  }
                                />
                              </EuiPanel>
                            </EuiFlexItem>
                            {category?.banners?.at(0) && (
                              <EuiFlexItem>
                                <img
                                  alt={category.name}
                                  src={category?.banners?.at(0)?.imageUrl}
                                  loading="lazy"
                                  className="rounded-md object-contain"
                                />
                              </EuiFlexItem>
                            )}
                            <EuiFlexItem>
                              <EuiText
                                className={classNames(
                                  {
                                    "text-white": isSelected(category.id),
                                  },
                                  "text-base",
                                )}
                              >
                                {category.name}
                              </EuiText>
                            </EuiFlexItem>
                            <EuiTextColor
                              className="mr-4"
                              color={
                                isSelected(category.id) ? "white" : "#acacac"
                              }
                            >
                              ({category.childrenCount})
                            </EuiTextColor>
                            <>
                              {!isRestrictedCategory(category.id) ? (
                                <Unicons.UilTrash
                                  onClick={() => {
                                    props.onPressDelete(category)
                                  }}
                                  color={
                                    isSelected(category.id) ? "white" : "black"
                                  }
                                  className="mr-2"
                                  size="12"
                                />
                              ) : null}
                              <Unicons.UilEdit
                                onClick={() => {
                                  props.onPressStore(category)
                                }}
                                color={
                                  isSelected(category.id) ? "white" : "black"
                                }
                                className="mr-2"
                                size="12"
                              />
                            </>
                          </EuiFlexGroup>
                        </EuiPanel>
                      )
                    }}
                  </EuiDraggable>
                )
              })}
          </EuiDroppable>
        </EuiDragDropContext>
      </div>
    </div>
  )
}
