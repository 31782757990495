import { useEffect, useState } from "react"

type SyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any

export const useCallAsync = <T extends (...args: any[]) => Promise<any>>(
  fn: T,
) => {
  const [loading, setLoading] = useState(false)

  return {
    loading,
    async fn(...args: Parameters<T>): Promise<SyncReturnType<T>> {
      setLoading(true)
      return fn(...args).finally(() => {
        setLoading(false)
      })
    },
  }
}

export const useWrapAsyncWithLoading = () => {
  const [loading, setLoading] = useState(false)

  return {
    loading,
    wrap<T extends (...args: any[]) => Promise<any>>(
      fn: T,
    ): (...args: Parameters<T>) => Promise<SyncReturnType<T>> {
      return async (...args) => {
        if (loading) return
        setLoading(true)
        return fn(...args).finally(() => {
          setLoading(false)
        })
      }
    },
  }
}

export const useDebounce = <T>(
  value: T,
  delay: number,
): { value: T; isDebouncing: boolean } => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [isDebouncing, setIsDebouncing] = useState(false)

  useEffect(() => {
    setIsDebouncing(true)

    const handler = setTimeout(() => {
      setDebouncedValue(value)
      setIsDebouncing(false)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return { value: debouncedValue, isDebouncing }
}
