import { useApi } from "@app/hook/api"
import { useSearchParams } from "react-router-dom"
import { Filter } from "@reeeed-mp/ui-common"
import { useHomeManagementStore } from "../../store/ctx"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useState } from "react"

type BannerPublishState = definitions["webutility.v1.HeroBannerState"]

export type BannerDetailProps = {
  banner: {
    id: string
    name: string
    cover: string
    duration?: {
      startAt: string
      endAt: string
    }
    link: string
  }[]
  total: string
}

export type HeroBannerDetailProps =
  definitions["webutility.admin.v1.FindHeroBannerResponse"]

export type HeroBannerItem = {
  order: string
  id: string
  bannerKey: string
  cover: string
  title: string
  link: string
  activePeriod: {
    startAt: Date
    endAt: Date
  }
  createdAt: Date
  count: number
}

export const useGetHeroBanner = () => {
  const searchParamKey = "textSearch"
  const publishStatusParamKey = "publishStatus"
  const [searchParams] = useSearchParams()
  const [textSearch, setTextSearch] = useState(
    searchParams.get(searchParamKey) || "",
  )

  const { webUtility } = useApi()
  const { heroBannerSection } = useHomeManagementStore()
  const sectionKey = heroBannerSection?.sectionKey
  const [publishStatus, setPublishStatus] = useState<BannerPublishState>(
    (searchParams.get(publishStatusParamKey) as BannerPublishState) ||
      "HERO_BANNER_STATE_ACTIVE",
  )

  const getHeroBannerApi = webUtility("/admin/hero-banner")
    .method("get")
    .create()

  const getHeroBanner = async ({ filter, opts }: Filter) => {
    const resp = await getHeroBannerApi({
      sectionKey,
      "filter.name": filter.textSearch,
      "filter.state": filter?.state,
      "pagination.limit": opts.limit,
      "pagination.skip": opts.skip,
    })

    return resp.data
  }

  return {
    getHeroBanner,
    textSearch,
    setTextSearch,
    publishStatus,
    setPublishStatus,
  }
}
