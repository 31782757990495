import { useApi } from "@app/hook/api"
import { useDebouncedCallback } from "use-debounce"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import { useAlertDefaultErr } from "@app/hook/error"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useHomeManagementStore } from "../../store/ctx"

type NewArrivalChapterDetailProps =
  definitions["webutility.v1.NewArrivalChapterProductSection"] & {
    sectionKey?: string
  }

export const useUpdateNewArrivalChapterProduct = (sectionKey: string) => {
  const { webUtility } = useApi()
  const { refreshHomepageSection } = useHomeManagementStore()
  const alertDefaultErr = useAlertDefaultErr()

  const updateNewArrivalChapterProductApi = webUtility(
    "/admin/new-arrival-chapter-product-section",
  )
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(
    async (input: NewArrivalChapterDetailProps) => {
      try {
        await updateNewArrivalChapterProductApi({
          sectionKey,
          input: {
            title: input.title,
            description: input.description,
            shouldShowTitle: input.shouldShowTitle,
          },
        }).then(() => {
          refreshHomepageSection()
        })
        return { id: sectionKey }
      } catch (error) {
        throw errToDefaultI18nKey(error)
      }
    },
    300,
  ) as (
    form: NewArrivalChapterDetailProps,
    id?: string,
  ) => Promise<{ id?: string }>

  const onSubmitted = () => {}

  const onGetItem = async (): Promise<NewArrivalChapterDetailProps> => {
    return {}
  }

  const form = useFormHelper<NewArrivalChapterDetailProps, { id?: string }>({
    id: sectionKey,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError: alertDefaultErr,
  })

  return form
}

export type NewArrivalChapterProductDetailConfigProps = ReturnType<
  typeof useUpdateNewArrivalChapterProduct
>
