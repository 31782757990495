import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { UploadItemSection } from "@app/component/upload-and-render"
import { publicConfig } from "@app/config"
import {
  HighlightProduct,
  HighlightProductSection,
  ProductHighlightForm,
  productHighlightValidator,
} from "@app/model/homepage/product-highlight"
import {
  extendProductsToProductsTable,
  mappingProductTypeInputToShopProductType,
  mappingShopProductTypeToProductTypeInput,
  ProductTypeInput,
} from "@app/model/product-table"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { theme, useFormHelper } from "@reeeed-mp/ui-common"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { read as readXlsx, utils as utilsXlsx, writeFile } from "xlsx"
import { useGetHighlightProductApi } from "../hook/homepage"
import { useHomeManagementStore } from "../store/ctx"

export const EditorProductHighlight = (props: {
  page:
    | "highlightProduct1Section"
    | "highlightProduct2Section"
    | "highlightProduct3Section"
}) => {
  const filePickerRef = useRef<any>()
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [rawProducts, setRawProducts] = useState<
    {
      id: string
      type: ProductTypeInput
    }[]
  >([])
  const [products, setProducts] = useState<HighlightProduct[]>()
  const [errorIDs, setErrorIDs] = useState<string[]>([])
  const { get: getHighlightProduct } = useGetHighlightProductApi()
  const { t } = useTranslation()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "homepage-management.product-highlight.editor",
  })
  const homeManagementStore = useHomeManagementStore()
  const [highlightProductSize, setHighlightProductSize] = useState(
    homeManagementStore.countHightlightProduct[props.page],
  )
  const [pageProductIndex, setPageProductIndex] = useState<number>(0)
  const [productsForRender, setProductsForRender] =
    useState<HighlightProduct[]>()
  const paginationSize = 10
  const banner: HighlightProductSection | undefined =
    homeManagementStore.homepage?.[props.page]
  const navigate = useNavigate()

  const onSubmit = async (
    values: ProductHighlightForm,
  ): Promise<{
    id: string | undefined
  }> => {
    const highlightProductSection: HighlightProductSection = {
      name: values.name,
      products: rawProducts
        .filter((r) => !errorIDs.includes(r.id))
        .map((p) => {
          return {
            id: p.id,
            type: mappingProductTypeInputToShopProductType(p.type),
          }
        }),
      enabled: homeManagementStore.homepage?.[props.page]?.enabled,
    }
    homeManagementStore.update(props.page, highlightProductSection)

    return {
      id: "",
    }
  }

  const initialValues = {
    name: banner?.name,
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<ProductHighlightForm, { id: string | undefined }>({
    id: undefined,
    initialValues,
    onGetItem: async () => {
      return {
        name: banner?.name,
      }
    },
    onError: () => {},
    onSubmit,
    onSubmitted: () => {
      navigate("/home-management")
    },
    validationSchema: productHighlightValidator,
  })

  const handleUploadProducts = async (files: FileList | null) => {
    let existRawProduct: {
      id: string
      type: ProductTypeInput
    }[] = []
    const productKeys: definitions["webutility.admin.v1.HighlightProductKey"][] =
      []
    setErrorIDs([])
    if (files && files[0]) {
      const result = await files[0].arrayBuffer()
      const workbook = readXlsx(result)
      const wsname = workbook.SheetNames[0]
      const ws = workbook.Sheets[wsname]
      existRawProduct = utilsXlsx.sheet_to_json(ws)
      for (let i = 0; i < existRawProduct.length; i++) {
        if (
          existRawProduct[i].type !== "e-book-audio" &&
          existRawProduct[i].type !== "novel" &&
          existRawProduct[i].type !== "physical-product"
        ) {
          setErrorIDs((e) => [...e, existRawProduct[i].id])
        } else {
          const productType = mappingProductTypeInputToShopProductType(
            existRawProduct[i].type,
          )

          productKeys.push({
            id: existRawProduct[i].id,
            type: productType,
          })
        }
      }
    }
    setHighlightProductSize(productKeys.length)
    const highlights = await getHighlightProduct(productKeys)
    const mapping = extendProductsToProductsTable(
      highlights?.products ?? [],
      productKeys,
    )

    setErrorIDs((e) => [...e, ...mapping.errorIDs])
    setProducts(
      mapping.extendsProducts
        .filter((t) => t.id)
        .map((p) => {
          return {
            ...p,
            type: p.type.map((t) =>
              mappingShopProductTypeToProductTypeInput(t),
            ),
          }
        }),
    )
    setRawProducts(existRawProduct)
    if (filePickerRef && filePickerRef.current) {
      filePickerRef?.current?.removeFiles()
    }
  }

  const exportHighlightProduct = async () => {
    if (rawProducts) {
      const worksheetHighlightProduct = utilsXlsx.json_to_sheet(rawProducts)
      const workbook = utilsXlsx.book_new()
      utilsXlsx.book_append_sheet(
        workbook,
        worksheetHighlightProduct,
        "HighlightProduct",
      )
      utilsXlsx.sheet_add_aoa(worksheetHighlightProduct, [["id", "type"]], {
        origin: "A1",
      })
      writeFile(workbook, "highlight-product.xlsx")
    }
  }

  const getProduct = async () => {
    const highlights = await getHighlightProduct(
      homeManagementStore.homepage?.[props.page]?.products ?? [],
    )
    const mapping = extendProductsToProductsTable(
      highlights?.products ?? [],
      [],
    )
    setErrorIDs(mapping.errorIDs)

    if (mapping.extendsProducts.length > 0) {
      const allProducts = mapping.extendsProducts.map((p) => {
        return {
          ...p,
          type: p.type.map((t) => mappingShopProductTypeToProductTypeInput(t)),
        }
      })
      setProducts(allProducts)
      setProductsForRender(allProducts)

      setRawProducts(
        mapping.extendsProducts.map((p) => {
          const productType = p.type.join(
            "",
          ) as definitions["shop.v1.ProductType"]
          return {
            id: p.id,
            type: mappingShopProductTypeToProductTypeInput(productType),
          }
        }),
      )
    }
  }

  useEffect(() => {
    getProduct().then(() => {})
    setHighlightProductSize(
      homeManagementStore.countHightlightProduct[props.page],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeManagementStore.homepage, homeManagementStore.countHightlightProduct])

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      <div>
        <div className="mb-10">
          <NavigationHeaderButton
            title={editorT("title")}
            onClick={() => {
              navigate("/home-management")
            }}
          />
        </div>
        <EuiFlexGroup className="mt-6">
          <EuiFlexItem
            css={css`
              .euiFormRow__fieldWrapper {
                width: 736px !important;
              }
            `}
            grow={8}
          >
            <EuiFormRow
              isInvalid={!!errors.name}
              label={<h4>{t("common.name")}</h4>}
              fullWidth
            >
              <EuiFieldText
                isInvalid={!!errors.name}
                placeholder={t("common.please-fill")}
                value={form?.name}
                fullWidth
                onChange={(e) => {
                  setValue("name", e.target.value)
                }}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule />

        <EuiText className="flex flex-row items-center">
          <h3 className="mb-0 mr-2">{editorT("show-product")}</h3>
          <p
            css={css`
              color: ${theme.colors.LIGHT.midGrey};
            `}
          >{`${editorT("total-amount-product", {
            amount: 200,
          })}`}</p>
        </EuiText>
        <UploadItemSection
          errorIds={errorIDs}
          export={exportHighlightProduct}
          loading={loading}
          handleUpload={handleUploadProducts}
          items={productsForRender?.slice(0, paginationSize)}
          pagination={{
            pageIndex: pageProductIndex,
            pageSize: paginationSize,
            totalItemCount: highlightProductSize,
            showPerPageOptions: false,
          }}
          onChangePagination={async (p: {
            page: {
              index: number
              size: number
            }
          }) => {
            setPageProductIndex(p.page.index)
            if (products) {
              const from = paginationSize * p.page.index
              setProductsForRender(products.slice(from, from + paginationSize))
            }
          }}
          tableColumns={[
            {
              field: "id",
              name: (
                <EuiText>
                  <h4>{editorT("id")}</h4>
                </EuiText>
              ),
            },
            {
              field: "name",
              name: (
                <EuiText>
                  <h4>{editorT("product-name")}</h4>
                </EuiText>
              ),
            },
            {
              field: "type",
              name: (
                <EuiText>
                  <h4>{editorT("product-type")}</h4>
                </EuiText>
              ),
            },
            {
              field: "shopName",
              name: (
                <EuiText>
                  <h4>{editorT("shop")}</h4>
                </EuiText>
              ),
            },
          ]}
          exampleTemplateStaticPath={`${
            publicConfig.publicUrl
          }/excel-template-upload/product-highlight-template.xlsx`}
        />

        <EuiFlexGroup className="mt-10">
          <EuiFlexItem
            css={css`
              max-width: 255px;
            `}
            grow={3}
          >
            <EuiButton
              onClick={() => {
                submit()
              }}
              fill
            >
              {t("common.save")}
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem
            css={css`
              max-width: 255px;
            `}
            grow={3}
          >
            <EuiButtonEmpty
              color="text"
              onClick={() => {
                navigate("/home-management")
              }}
            >
              {t("common.cancel")}
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={4} />
        </EuiFlexGroup>
      </div>
    </div>
  )
}
