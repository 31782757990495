import { NavigationHeaderButton } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { EuiButton, EuiFieldText, EuiTab, EuiTabs, EuiText } from "@elastic/eui"
import { UilPlus, UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import dayjs from "dayjs"
import { ReOrderSelect, TableAction } from "../components/banner-list-action"
import { useGetMediumBanner } from "./hook/banner"
import { useApi } from "@app/hook/api"
import { useAlertDefaultErr } from "@app/hook/error"
import {
  fixedMediumBannerPath,
  homepageManagementPath,
  overflowMediumBannerPath,
} from "../hook/path"
import { ImagePreview } from "../components/banner-common"
import { useDebounce } from "use-debounce"

export type MediumBannerItem = {
  bannerKey: string
  order: string
  id: string
  cover: string
  name: string
  link: string
  createdAt: Date
  count: string
  activePeriod: {
    startAt: Date
    endAt: Date
  }
}

export const OverflowMediumBannerListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const ref = useRef() as TableRefImperative
  const { webUtility } = useApi()
  const {
    publishStatus,
    setPublishStatus,
    textSearch,
    setTextSearch,
    getOverflowMediumBanner,
  } = useGetMediumBanner(params.sectionKey!)
  const alertDefaultError = useAlertDefaultErr()
  const [debouncedTextSearch] = useDebounce(textSearch, 500)

  const reorderBannerApi = webUtility(
    "/admin/section/{sectionKey}/overflow-medium-banner/{bannerKey}/order",
  )
    .method("post")
    .create()

  const deleteBannerApi = webUtility(
    "/admin/overflow-medium-banner/{bannerKey}",
  )
    .method("delete")
    .create({ sectionKey: true })

  const onEditBanner = (bannerKey: string) => {
    navigate(overflowMediumBannerPath(params.sectionKey!, bannerKey))
  }

  const deleteBanner = async (bannerKey: string) => {
    try {
      await deleteBannerApi({
        sectionKey: params.sectionKey!,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const reorderBanner = async (bannerKey: string, order: string) => {
    try {
      await reorderBannerApi({
        order,
        sectionKey: params.sectionKey!,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const onGetItems: OnGetItems<MediumBannerItem> = async (props) => {
    const data = await getOverflowMediumBanner({
      filter: props.filter!,
      opts: props.opts,
    })

    return {
      items:
        data?.banners?.map((p) => {
          return {
            order: p.order!,
            id: p.id!,
            bannerKey: p.key!,
            name: p.title!,
            cover: p.cover!,
            link: p.link!,
            activePeriod: {
              startAt: dayjs(p.startAt!).toDate(),
              endAt: dayjs(p.endAt).toDate(),
            },
            createdAt: dayjs(p.createdAt!).toDate(),
            count: String(data.count || 0),
          }
        }) || [],

      count: data.count || 0,
    }
  }

  const columns = [
    {
      name: t("home-management.banner.list.table.order-col"),
      render: (item: MediumBannerItem) => {
        return (
          <ReOrderSelect
            bannerKey={item.bannerKey!}
            sectionKey={params.sectionKey!}
            count={parseInt(item.count, 10)}
            order={item.order!}
            onReorder={reorderBanner}
          />
        )
      },
      width: "100",
    },
    {
      field: "cover",
      name: t("home-management.banner.list.table.cover-col"),
      render: (cover: MediumBannerItem["cover"]) => {
        return <ImagePreview src={cover} alt="cover" className="max-h-20" />
      },
    },
    {
      field: "name",
      name: t("home-management.banner.list.table.name-col"),
    },
    {
      field: "activePeriod",
      name: t("home-management.banner.list.table.active-period-col"),
      render: (activePeriod: MediumBannerItem["activePeriod"]) => {
        return t("time-range", {
          value: {
            startAt: activePeriod?.startAt,
            endAt: activePeriod?.endAt,
          },
        })
      },
      width: "35%",
    },
    {
      field: "createdAt",
      name: t("home-management.banner.list.table.create-at-col"),
      render: (createdAt: MediumBannerItem["createdAt"]) => {
        return t("common.datetime", {
          value: createdAt ?? "",
        })
      },
    },
    {
      width: "50",
      actions: [
        {
          render: (item: MediumBannerItem) => {
            return (
              <TableAction
                onDelete={deleteBanner}
                onEdit={onEditBanner}
                sectionKey={params.sectionKey!}
                {...item}
              />
            )
          },
        },
      ],
    },
  ]

  return (
    <div className="flex max-w-screen-xl flex-col gap-y-6">
      <div className="flex w-full items-center justify-between">
        <NavigationHeaderButton
          title={t("home-management.banner.list.title")}
          onClick={() => {
            navigate(homepageManagementPath())
          }}
        />
        <EuiButton
          fill
          iconType={UilPlus}
          onClick={() => {
            navigate(overflowMediumBannerPath(params.sectionKey!, "create"))
          }}
        >
          {t("home-management.banner.list.add-button")}
        </EuiButton>
      </div>
      <div className="flex justify-between">
        <EuiTabs>
          <EuiTab
            isSelected={publishStatus === "MEDIUM_BANNER_STATE_ACTIVE"}
            onClick={() => setPublishStatus("MEDIUM_BANNER_STATE_ACTIVE")}
          >
            <EuiText size="m" className="font-bold">
              <h5>{t("home-management.banner.list.filter.active")}</h5>
            </EuiText>
          </EuiTab>
          <EuiTab
            isSelected={publishStatus === "MEDIUM_BANNER_STATE_PENDING"}
            onClick={() => setPublishStatus("MEDIUM_BANNER_STATE_PENDING")}
          >
            <EuiText size="m" className="font-bold">
              <h5>{t("home-management.banner.list.filter.pending")}</h5>
            </EuiText>
          </EuiTab>
          <EuiTab
            isSelected={publishStatus === "MEDIUM_BANNER_STATE_INACTIVE"}
            onClick={() => setPublishStatus("MEDIUM_BANNER_STATE_INACTIVE")}
          >
            <EuiText size="m" className="font-bold">
              <h5>{t("home-management.banner.list.filter.inactive")}</h5>
            </EuiText>
          </EuiTab>
        </EuiTabs>
        <EuiFieldText
          icon={<UilSearch />}
          placeholder={t("home-management.banner.list.filter.text-search")}
          onChange={(e) => {
            setTextSearch(e.target.value)
          }}
        />
      </div>

      <Table<MediumBannerItem>
        ref={ref}
        columns={columns}
        sorter={{
          field: "order",
          direction: "desc",
        }}
        swrKey={`/overflow-medium-banner-list-${params.sectionKey}`}
        onGetItems={onGetItems}
        isCanSelect={false}
        filter={{
          textSearch: debouncedTextSearch,
          state: publishStatus,
        }}
      />
    </div>
  )
}

export const FixedMediumBannerListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const ref = useRef() as TableRefImperative
  const { webUtility } = useApi()
  const { textSearch, getFixedMediumBanner } = useGetMediumBanner(
    params.sectionKey!,
  )
  const [debouncedTextSearch] = useDebounce(textSearch, 500)
  const [isMaxBanner, setIsMaxBanner] = useState(false)

  const alertDefaultError = useAlertDefaultErr()

  const deleteBannerApi = webUtility("/admin/fixed-medium-banner/{bannerKey}")
    .method("delete")
    .create({ sectionKey: true })

  const reorderBannerApi = webUtility(
    "/admin/section/{sectionKey}/fixed-medium-banner/{bannerKey}/order",
  )
    .method("post")
    .create()

  const reorderBanner = async (bannerKey: string, order: string) => {
    try {
      await reorderBannerApi({
        order,
        sectionKey: params.sectionKey!,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const onEditBanner = (bannerKey: string) => {
    navigate(fixedMediumBannerPath(params.sectionKey!, bannerKey))
  }

  const deleteBanner = async (bannerKey: string) => {
    try {
      await deleteBannerApi({
        sectionKey: params.sectionKey!,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const onGetItems: OnGetItems<MediumBannerItem> = async () => {
    const data = await getFixedMediumBanner()

    setIsMaxBanner(data.banners?.length === 3)

    return {
      items:
        data?.banners?.map((p) => {
          return {
            order: p.order!,
            id: p.id!,
            bannerKey: p.key!,
            name: p.title!,
            cover: p.cover!,
            link: p.link!,
            activePeriod: {
              startAt: dayjs(p.startAt!).toDate(),
              endAt: dayjs(p.endAt).toDate(),
            },
            createdAt: dayjs(p.createdAt!).toDate(),
            count: String(data.banners?.length),
          }
        }) || [],

      count: data.banners?.length || 0,
    }
  }

  const columns = [
    {
      name: t("home-management.banner.list.table.order-col"),
      render: (item: MediumBannerItem) => {
        return (
          <ReOrderSelect
            bannerKey={item.bannerKey!}
            sectionKey={params.sectionKey!}
            count={parseInt(item.count, 10)}
            order={item.order!}
            onReorder={reorderBanner}
          />
        )
      },
      width: "100",
    },
    {
      field: "cover",
      name: t("home-management.banner.list.table.cover-col"),
      render: (cover: MediumBannerItem["cover"]) => {
        return <ImagePreview src={cover} alt="cover" className="max-h-14" />
      },
    },
    {
      field: "name",
      name: t("home-management.banner.list.table.name-col"),
    },
    {
      field: "createdAt",
      name: t("home-management.banner.list.table.create-at-col"),
      render: (createdAt: MediumBannerItem["createdAt"]) => {
        return t("common.datetime", {
          value: createdAt ?? "",
        })
      },
    },
    {
      width: "50",
      actions: [
        {
          render: (item: MediumBannerItem) => {
            return (
              <TableAction
                onDelete={deleteBanner}
                onEdit={onEditBanner}
                sectionKey={params.sectionKey!}
                {...item}
              />
            )
          },
        },
      ],
    },
  ]

  return (
    <div className="flex max-w-screen-xl flex-col gap-y-6">
      <div className="flex w-full items-center justify-between">
        <NavigationHeaderButton
          title={t("home-management.banner.list.title")}
          onClick={() => {
            navigate(homepageManagementPath())
          }}
        />
        <EuiButton
          fill
          iconType={UilPlus}
          disabled={isMaxBanner}
          onClick={() => {
            navigate(fixedMediumBannerPath(params.sectionKey!, "create"))
          }}
        >
          {t("home-management.banner.list.add-button")}
        </EuiButton>
      </div>

      <Table<MediumBannerItem>
        ref={ref}
        columns={columns}
        sorter={{
          field: "order",
          direction: "desc",
        }}
        swrKey={`/fixed-medium-banner-list-${params.sectionKey}`}
        onGetItems={onGetItems}
        isCanSelect={false}
        filter={{
          textSearch: debouncedTextSearch,
        }}
      />
    </div>
  )
}
