import { constants } from "@app/model/constants"
import { EuiLoadingSpinner } from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetTopSellingProduct } from "../hook"

export const TopProductCard: React.FC = () => {
  const { t } = useTranslation()
  const { shopId } = useParams()

  const { data, isLoading } = useGetTopSellingProduct(shopId ?? "")
  const eBookAudios = data?.eBookAudios
  const novels = data?.novels
  const physicalProducts = data?.physicalProducts

  return (
    <div className="rounded-xl border border-solid border-[#D3DAE6] p-6">
      <div className="text-m font-bold">
        {t("shop-information.top-product")}
      </div>
      {isLoading ? (
        <LoadingTopProduct />
      ) : eBookAudios || novels || physicalProducts ? (
        <div className="mt-4 flex h-full w-full flex-col gap-4">
          <div className="flex flex-col gap-2">
            {eBookAudios?.slice(0, 1).map((product, index) => {
              return (
                <TopProductDetail
                  key={`ebook-audio-${index}`}
                  productName={product.eBookAudio?.eBookAudio?.name ?? ""}
                  coverUrl={product.eBookAudio?.coverUrl ?? ""}
                  netPrice={Number(product.netPrice)}
                />
              )
            })}
          </div>
          <div className="flex flex-col gap-2">
            {novels?.slice(0, 1).map((product, index) => {
              return (
                <TopProductDetail
                  key={`novel-${index}`}
                  productName={product.novel?.novel?.name ?? ""}
                  coverUrl={product.novel?.coverUrl ?? ""}
                  netPrice={Number(product.netPrice)}
                />
              )
            })}
          </div>
          <div className="flex flex-col gap-2">
            {physicalProducts?.slice(0, 1).map((product, index) => {
              return (
                <TopProductDetail
                  key={`physical-product-${index}`}
                  productName={
                    product.physicalProduct?.physicalProduct?.name ?? ""
                  }
                  coverUrl={product.physicalProduct?.coverUrl ?? ""}
                  netPrice={Number(product.netPrice)}
                />
              )
            })}
          </div>
        </div>
      ) : (
        <EmptyTopProduct />
      )}
    </div>
  )
}

const TopProductDetail: React.FC<{
  productName: string
  coverUrl: string
  netPrice: number
}> = ({ productName, coverUrl, netPrice }) => {
  const { t } = useTranslation()

  return (
    <div className="flex gap-2">
      <div className="h-[52px] w-[52px] shrink-0 items-center justify-center border border-solid border-gray-300">
        <img src={coverUrl} className="mx-auto h-full object-contain" />
      </div>
      <div className="flex flex-col justify-center gap-2">
        <div className="line-clamp-1 break-all">{productName}</div>
        <div className="text-xs text-gray-400">
          {t("common.price", {
            value: netPrice / constants.power10OfDecimalPrice,
          })}
        </div>
      </div>
    </div>
  )
}

const EmptyTopProduct = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full items-center justify-center text-center">
      <div className="text-gray-400">
        {t("seller-management.not-found-product")}
      </div>
    </div>
  )
}

const LoadingTopProduct = () => {
  return (
    <div className="flex h-full w-full items-center justify-center text-center">
      <EuiLoadingSpinner size="xl" />
    </div>
  )
}
