import { definitions } from "@app/vendor/web-utility-specs/web_utility_static_page_service"
import { useCallback } from "react"
import { useApi } from "./api"

type Filter = definitions["webutility.admin.v1.FindStaticPageRequest.Filter"]
export type StaticPage = definitions["webutility.admin.v1.StaticPage"] & {
  id: string
}

export const useGetStaticPages = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (filter?: Filter) => {
      const { data } = await webUtility("/static-pages").method("get").create()(
        {
          ...(filter && {
            "filter.group": filter.group,
            "filter.publish": filter.publish,
          }),
        },
      )
      return {
        items: (data.staticPages ?? []) as Array<StaticPage>,
        count: data.staticPages?.length ?? 0,
      }
    },
    [webUtility],
  )
}

export const useGetStaticPage = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (id: string) => {
      const { data } = await webUtility("/static-pages/{id}")
        .method("get")
        .create()({ id })
      return data.staticPage
    },
    [webUtility],
  )
}

export const useUpsertStaticPage = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (staticPage: definitions["webutility.admin.v1.StaticPage"]) => {
      const { data } = await webUtility("/static-pages")
        .method("post")
        .create()({
        staticPage,
      })
      return data.staticPage
    },
    [webUtility],
  )
}

export const useUpdateContentReservedStaticPage = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (id: string, content: string) => {
      const { data } = await webUtility("/static-pages/reserved")
        .method("post")
        .create()({
        id,
        content,
      })
      return data.staticPage
    },
    [webUtility],
  )
}

export const useDeleteStaticPage = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (id: string) => {
      const { data } = await webUtility("/static-pages/{id}")
        .method("delete")
        .create()({
        id,
      })
      return data.staticPage
    },
    [webUtility],
  )
}

export const useSetStaticPagePublishStatus = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (id: string, publish: boolean) => {
      const { data } = await webUtility("/static-pages/publish-status")
        .method("post")
        .create()({
        id,
        publishStatus: publish
          ? "STATIC_PAGE_PUBLISH_STATUS_SHOW"
          : "STATIC_PAGE_PUBLISH_STATUS_HIDE",
      })
      return data.staticPage
    },
    [webUtility],
  )
}
