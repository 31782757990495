import { publicConfig } from "@app/config"
import { newFetcher } from "@app/lib/api"
import { paths as pathsAuth } from "@app/vendor/admin-auth-specs/admin_auth_service"
import { action, computed, makeObservable, observable } from "mobx"

const fetcher = newFetcher<pathsAuth>(publicConfig.authAdminEndpoint, []).path
const getAccessToken = fetcher("/get-access-token").method("post").create()

export class AuthStore {
  public accessToken: string | null = null
  public isInitialed: boolean = false

  constructor() {
    makeObservable(this, {
      accessToken: observable,
      isLoggedIn: computed,
      isInitialed: observable,
      initial: action,
      login: action,
      logout: action,
      getAccessToken: action,
    })
  }

  async initial() {
    return this.getAccessToken()
      .then(() => {
        this.isInitialed = true
      })
      .catch((e) => {
        console.log(e)
        if (
          (e instanceof getAccessToken.Error && e.getActualType().status) ||
          (e.message || "").indexOf("auth") !== -1
        ) {
          this.isInitialed = true
        } else {
          throw e
        }
      })
  }

  async getAccessToken() {
    return getAccessToken(
      {},
      {
        credentials: "include",
      },
    ).then((r) => {
      this.accessToken = r.data.accessToken!
    })
  }

  get isLoggedIn(): boolean {
    return !!this.accessToken
  }

  async login(user: string, password: string) {
    return fetcher("/login")
      .method("post")
      .create()({ username: user, password }, { credentials: "include" })
      .then(() => {
        return this.getAccessToken()
      })
  }

  async logout() {
    return fetcher("/logout")
      .method("post")
      .create()({}, { credentials: "include" })
      .then(() => {
        this.accessToken = null
      })
  }
}
