import { Title } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { useStore } from "@app/hook/store"
import { Options } from "@app/page/coupon-promotion/component"
import { LIGHTEST_GREY } from "@app/utils/color"
import {
  EuiBasicTableColumn,
  EuiFieldText,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@elastic/eui"
import { ClassNames, css } from "@emotion/react"
import { UilSearch } from "@iconscout/react-unicons"
import { theme } from "@reeeed-mp/ui-common"
import moment from "moment"
import React, { useCallback, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  Item,
  useDeletePromotion,
  useEndPromotion,
  useListPromotion,
} from "./hook/promotion"
import { tenPowerPriceToStrPrice } from "./utils"
import { useDebounce } from "use-debounce"

export const PromotionPage: React.FC = () => {
  const { t } = useTranslation()
  const { notificationStore: notification } = useStore()
  const navigate = useNavigate()
  const {
    loading,
    setLoading,
    onGetItems,
    selectedItems,
    setSelectedItems,
    filter,
    setFilter,
  } = useListPromotion()
  const ref = useRef() as TableRefImperative
  const textSearch = filter.textSearch || ""
  const [debounceTextSearch] = useDebounce(textSearch, 500)

  const endPromotion = useEndPromotion()
  const onEndPromotion = useCallback(
    async (id: string) => {
      setLoading(true)
      try {
        await endPromotion(id)
        ref.current.refetch()
        notification.add({
          title: "Success",
          color: "success",
        })
      } catch (err) {
        console.error(err)
        notification.add({
          title: "Error",
          color: "danger",
          text: err instanceof Error ? err.message : (err as string),
        })
      }
      setLoading(false)
    },
    [notification, endPromotion, setLoading],
  )

  const deletePromotion = useDeletePromotion()
  const onDeletePromotion = useCallback(
    async (id: string) => {
      setLoading(true)
      try {
        await deletePromotion(id)
        ref.current.refetch()
        notification.add({
          title: "Success",
          color: "success",
        })
      } catch (err) {
        console.error(err)
        notification.add({
          title: "Error",
          color: "danger",
          text: err instanceof Error ? err.message : (err as string),
        })
      }
      setLoading(false)
    },
    [notification, deletePromotion, setLoading],
  )

  const columns: Array<EuiBasicTableColumn<Item>> = [
    {
      name: t("promotion.list.promotion-name"),
      truncateText: true,
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.name}</EuiText>
            <EuiText
              size="xs"
              css={css`
                color: ${LIGHTEST_GREY} !important;
              `}
            >
              {row.note}
            </EuiText>
          </div>
        )
      },
    },
    {
      field: "code",
      name: t("promotion.list.promotion-code"),
      truncateText: true,
    },
    {
      field: "type",
      name: t("promotion.list.promotion-type"),
    },
    {
      name: t("promotion.list.discount"),
      render: (row: Item) => {
        return (
          <div>
            {row.discountPercentage !== undefined
              ? `${row.discountPercentage}${t("promotion.editor.percentage-symbol")}`
              : `${tenPowerPriceToStrPrice(row.discount)}${t("common.currency-symbol")}`}
          </div>
        )
      },
    },
    {
      name: t("promotion.list.subsidize"),
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.platformSubsidize}</EuiText>
            <EuiText size="xs">{row.sellerSubsidize}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("promotion.list.range-publish"),
      render: (row: Item) => {
        return (
          <div style={{ width: 125 }}>
            <EuiText size="xs">
              {t("time-range-short", {
                value: { startAt: row.startAt, endAt: row.endAt },
              })}
            </EuiText>
          </div>
        )
      },
    },
    {
      align: "right",
      width: "40px",
      isExpander: true,
      onClick: (e) => {
        e.stopPropagation()
      },
      render: (row: Item) => {
        return (
          <Options
            id={row.id}
            disableRenew={row.isForSelectedUser}
            codeActive={{
              startAt: row.startAt ? moment(row.startAt) : undefined,
              endAt: row.endAt ? moment(row.endAt) : undefined,
            }}
            loading={loading}
            onEdit={() => {
              navigate(`/promotion/${row.id}`, { replace: true })
            }}
            onDelete={onDeletePromotion}
            onEnd={onEndPromotion}
            onReNew={() => {
              navigate(`/promotion/create?renew=${row.id}`, { replace: true })
            }}
          />
        )
      },
    },
  ]

  return (
    <div>
      <Title
        text={t("nav.promotion-code")}
        btn={{
          iconName: "plus",
          text: t("promotion.editor.create-promotion"),
          onClick: () => navigate("/promotion/create"),
        }}
      />
      <div className="mb-6 flex w-full flex-auto justify-between">
        <div className="self-center">
          <EuiTabs
            css={css({
              ".euiTab": {
                ".euiTab__content": {
                  color: "#69707d !important",
                },
                "&.euiTab-isSelected": {
                  ".euiTab__content": {
                    color: `${theme.colors.LIGHT.primaryPurple} !important`,
                  },
                },
              },
              "&.euiTabs--bottomBorder": {
                boxShadow: "none",
              },
            })}
            size="l"
          >
            <EuiTab
              isSelected={filter.publishStatus === "PUBLISH_STATUS_ACTIVE"}
              onClick={() => {
                setFilter({
                  ...filter,
                  publishStatus: "PUBLISH_STATUS_ACTIVE",
                })
              }}
            >
              <EuiText size="m" className="font-bold">
                {t("promotion.list.active-promotion")}
              </EuiText>
            </EuiTab>
            <EuiTab
              isSelected={filter.publishStatus === "PUBLISH_STATUS_WAITING"}
              onClick={() => {
                setFilter({
                  ...filter,
                  publishStatus: "PUBLISH_STATUS_WAITING",
                })
              }}
            >
              <EuiText size="m" className="font-bold">
                {t("promotion.list.will-active-promotion")}
              </EuiText>
            </EuiTab>
            <EuiTab
              isSelected={filter.publishStatus === "PUBLISH_STATUS_ENDED"}
              onClick={() => {
                setFilter({
                  ...filter,
                  publishStatus: "PUBLISH_STATUS_ENDED",
                })
              }}
            >
              <EuiText size="m" className="font-bold">
                {t("promotion.list.ended-promotion")}
              </EuiText>
            </EuiTab>
          </EuiTabs>
        </div>
        <div>
          <EuiFieldText
            placeholder={t("promotion.list.text-search")}
            value={filter.textSearch}
            onChange={(e) => {
              if (e.target.value === "") {
                delete filter.textSearch
              } else {
                filter.textSearch = e.target.value
              }

              setFilter({
                ...filter,
              })
            }}
            style={{
              width: 376,
            }}
            icon={<UilSearch />}
          />
        </div>
      </div>

      <ClassNames>
        {({ css }) => {
          return (
            <div className="w-full">
              <Table<Item>
                ref={ref}
                swrKey="/list-platform-coupon"
                selectedItems={selectedItems}
                onSelectedItemsChange={setSelectedItems}
                columns={columns}
                onGetItems={onGetItems}
                filter={{
                  ...filter,
                  textSearch: debounceTextSearch,
                }}
                loading={loading}
                css={css`
                  .p-center {
                    p {
                      margin: auto 0;
                    }
                  }
                `}
                isCanSelect={false}
                onRowClick={(row) => {
                  navigate(`/promotion/detail/${row.id}`)
                }}
              />
            </div>
          )
        }}
      </ClassNames>
    </div>
  )
}
