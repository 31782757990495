import { useAuth } from "@app/hook/store"
import {
  EuiButton,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
} from "@elastic/eui"
import { UilUser } from "@iconscout/react-unicons"
import { observer } from "mobx-react"
import { useState } from "react"

export const LoginPage = observer(() => {
  const auth = useAuth()

  const [password, setPassword] = useState("")
  const [username, setUsername] = useState("")
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isLoginFailed, setIsLoginFailed] = useState(false)

  if (!auth.isInitialed) {
    return (
      <div className="flex h-screen items-center justify-center">
        <EuiLoadingSpinner className="" size="l" />
      </div>
    )
  }

  return (
    <EuiForm
      onSubmit={(e) => {
        e.preventDefault()
        setIsLoggingIn(true)
        auth
          .login(username, password)
          .then(() => {
            setUsername("")
            setPassword("")
          })
          .catch(() => {
            setIsLoginFailed(true)
          })
          .finally(() => {
            setIsLoggingIn(false)
          })
      }}
      component="form"
      className="m-auto flex h-screen flex-col items-center justify-center"
      style={{
        width: 300,
      }}
    >
      <EuiFormRow
        className="w-full"
        isInvalid={isLoginFailed}
        error={"incorrect username or password"}
      >
        <EuiFieldText
          icon={<UilUser />}
          placeholder="username"
          isInvalid={isLoginFailed}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value)
            setIsLoginFailed(false)
          }}
        />
      </EuiFormRow>

      <EuiFormRow className="w-full">
        <EuiFieldPassword
          placeholder={"password"}
          value={password}
          isInvalid={isLoginFailed}
          onChange={(e) => {
            setPassword(e.target.value)
            setIsLoginFailed(false)
          }}
          type="dual"
        />
      </EuiFormRow>
      <EuiButton
        type="submit"
        className="mt-4 self-end"
        isLoading={isLoggingIn}
      >
        Login
      </EuiButton>
    </EuiForm>
  )
})
