import { SmallModal } from "@app/component/modal/small"
import { useDownloadFile, useStreamApi as useStreamAPI } from "@app/hook/api"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { useStore } from "@app/hook/store"
import {
  dateOnlyAPIInputFormat,
  datePickerDateOnlyFormat,
} from "@app/model/time"
import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiProgress,
  EuiRadioGroup,
} from "@elastic/eui"
import moment, { Moment } from "moment"
import "moment/locale/th"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type ReportDownloadModalProps = {
  open: boolean
  onClose: () => void
  asOverviewReport?: boolean
}

const maxDateRange = 180

type Filter = {
  filterBy: "registeredWithinRange" | "lastAccessedWithinRange"
  startAt: Moment | null
  endAt: Moment | null
}

export const UserListReportDownloadModal: React.FC<
  ReportDownloadModalProps
> = ({ open, onClose }) => {
  const { notificationStore: notification } = useStore()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<Filter>({
    filterBy: "registeredWithinRange",
    startAt: null,
    endAt: null,
  })
  const { auth } = useStreamAPI()
  const {
    doDownloadFile,
    cancelDownload,
    loadingPercentage,
    error: downloadReportError,
  } = useDownloadFile(
    auth("/list-users/download-users").method("get").create(),
    {
      autoDownload: true,
    },
  )

  useAlertIfDefaultErr([downloadReportError])

  useEffect(() => {
    return cancelDownload
  }, [cancelDownload])

  return (
    <SmallModal
      loading={loadingPercentage != null}
      css={{ zIndex: 1000 }}
      onConfirm={() => {
        if (!filter.startAt || !filter.endAt) {
          notification.add({
            text: t("user-list.report.modal.error.date-range-required"),
            color: "warning",
          })

          return
        }

        if (filter.filterBy === "lastAccessedWithinRange") {
          doDownloadFile({
            "filter.lastAccessedWithinRange.startAt": filter.startAt.format(
              dateOnlyAPIInputFormat,
            ),
            "filter.lastAccessedWithinRange.endAt": filter.endAt.format(
              dateOnlyAPIInputFormat,
            ),
          })
        }

        if (filter.filterBy === "registeredWithinRange") {
          doDownloadFile({
            "filter.registeredWithinRange.startAt": filter.startAt.format(
              dateOnlyAPIInputFormat,
            ),
            "filter.registeredWithinRange.endAt": filter.endAt.format(
              dateOnlyAPIInputFormat,
            ),
          })
        }
      }}
      confirmBtnText={t("user-list.report.modal.download")}
      onCancel={() => {
        cancelDownload()
        onClose()
      }}
      cancelBtnText={t("user-list.report.modal.cancel")}
      header={t("user-list.report.modal.header")}
      body={
        <div className="mb-2 flex flex-col gap-2">
          <EuiRadioGroup
            className="my-4"
            options={[
              {
                id: "registeredWithinRange",
                label: t("user-list.report.modal.filter-by-registered-date"),
              },
              {
                id: "lastAccessedWithinRange",
                label: t("user-list.report.modal.filter-by-last-access-date"),
              },
            ]}
            idSelected={filter.filterBy}
            onChange={(id) =>
              setFilter({ ...filter, filterBy: id as Filter["filterBy"] })
            }
          />
          <EuiDatePickerRange
            startDateControl={
              <EuiDatePicker
                selected={filter.startAt}
                onChange={(date) => {
                  const newFilter = { ...filter, startAt: date }
                  if (date && filter.endAt) {
                    const diff = filter.endAt.diff(date, "days") + 1
                    if (filter.endAt.isBefore(date) || diff > maxDateRange) {
                      newFilter.endAt = null
                    }
                  }

                  setFilter(newFilter)
                }}
                maxDate={moment()}
                isInvalid={!filter.startAt}
                dateFormat={datePickerDateOnlyFormat}
              />
            }
            endDateControl={
              <EuiDatePicker
                disabled={!filter.startAt}
                selected={filter.endAt}
                onChange={(date) => {
                  setFilter({ ...filter, endAt: date })
                }}
                maxDate={
                  filter.startAt
                    ? moment.min(
                        moment(filter.startAt).add(maxDateRange - 1, "day"),
                        moment(),
                      )
                    : undefined
                }
                isInvalid={!filter.endAt}
                dateFormat={datePickerDateOnlyFormat}
              />
            }
          />
          {filter.startAt && filter.endAt && (
            <p className="text-sm text-gray-400">
              {t("user-list.report.modal.date-range-count", {
                value: filter.endAt.diff(filter.startAt, "days") + 1,
              })}
            </p>
          )}
          {loadingPercentage != null && (
            <EuiProgress value={loadingPercentage} max={100} />
          )}
        </div>
      }
      isOpen={open}
    />
  )
}
