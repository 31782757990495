import { EuiText } from "@elastic/eui"
import React from "react"

export const WithdrawAccountText = (props: { text: string }) => {
  return (
    <EuiText size="s">
      <p className="mb-0">{props.text}</p>
    </EuiText>
  )
}
