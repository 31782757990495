import { useAlertIfDefaultErr } from "@app/hook/error"
import { priceStrToNum } from "@app/model/price"
import { definitions } from "@app/vendor/promotion-specs/promotion_service"
import {
  EuiAccordion,
  EuiCheckbox,
  EuiPanel,
  EuiSkeletonText,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { theme } from "@reeeed-mp/ui-common"
import { Moment } from "moment"
import React, { PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCountTopupPackages, usePackages } from "./hook"

type SummaryTopupCouponDetailProps = {
  code?: string
  startAt?: Moment
  endAt?: Moment
  additional?: string
  isDiscount: boolean
  isByAmount: boolean
  amountValue?: number
  percentageValue?: number
  percentageHaveMaxAmount?: boolean
  percentageMaxAmount?: number
}

export const SummaryTopupCouponDetail: React.FC<
  SummaryTopupCouponDetailProps
> = ({
  code,
  startAt,
  endAt,
  additional,
  isDiscount,
  isByAmount,
  amountValue,
  percentageValue,
  percentageHaveMaxAmount,
  percentageMaxAmount,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <EuiText className="mt-4">
        <h2>
          {t("coupon-effect", {
            value: {
              isDiscount,
              isByAmount,
              amountValue,
              percentageValue,
              percentageHaveMaxAmount,
              percentageMaxAmount,
            },
          })}
        </h2>
      </EuiText>
      {code ? (
        <EuiText
          css={css({
            color: theme.colors.LIGHT.primaryPurple,
          })}
        >
          {code}
        </EuiText>
      ) : (
        <EuiText>{t("promotion.editor.promotion-code")}</EuiText>
      )}
      <EuiText size="xs" className="mt-1 text-grey">
        {startAt || endAt
          ? t("date-range", { value: { startAt, endAt } })
          : t("promotion.editor.datetime-can-use-not-set")}
      </EuiText>
      <EuiText className="mt-6" size="s">
        {additional
          ? additional
          : t("promotion.editor.additional-and-condition")}
      </EuiText>
    </>
  )
}

const renderTopupPaymentChannelSequent = [
  "PAYMENT_CHANNEL_PAYSOL_PROMPT_PAY",
  "PAYMENT_CHANNEL_2C2P_BANK",
  "PAYMENT_CHANNEL_2C2P_CREDIT_CARD",
  "PAYMENT_CHANNEL_2C2P_LINE_PAY",
  "PAYMENT_CHANNEL_2C2P_TRUE_WALLET_ONLINE",
  "PAYMENT_CHANNEL_2C2P_SHOPEE_PAY",
] as const

type SelectJoinPackageProps = {
  selectPackages: Array<string>
  onChange?: (packageIds: Array<string>) => void
  editable?: boolean
}

export const SelectJoinPackage: React.FC<SelectJoinPackageProps> = ({
  selectPackages,
  onChange,
  editable = true,
}) => {
  const { t } = useTranslation()
  const { data: packages, isLoading, error } = useCountTopupPackages()
  const avaliablePackges = packages?.reduce((acc, current) => {
    acc.set(current.paymentChannel!, current)
    return acc
  }, new Map<string, NonNullable<typeof packages>[number]>())

  const selectPackageRepo = selectPackages.reduce((acc, current) => {
    acc.set(current, true)
    return acc
  }, new Map<string, boolean>())

  useAlertIfDefaultErr([error])

  return (
    <>
      {editable && (
        <EuiText size="s" color="gray">
          <p>{t("promotion.editor.select-join-package-notice")}</p>
        </EuiText>
      )}
      <div className="mt-2 flex flex-col gap-2">
        {isLoading ? (
          <EuiSkeletonText lines={5} />
        ) : (
          renderTopupPaymentChannelSequent
            .filter(
              (channel) => Number(avaliablePackges?.get(channel)?.total) > 0,
            )
            .map((c) => {
              const p = avaliablePackges?.get(c)

              return (
                <SummaryPackage
                  key={p!.paymentChannel}
                  channel={p!.paymentChannel!}
                  editable={editable}
                  selectPackageRepo={selectPackageRepo}
                  onAddPackages={(addIds) => {
                    const repo = new Map<string, boolean>()
                    selectPackages.forEach((id) => repo.set(id, true))
                    addIds.forEach((id) => repo.set(id, true))

                    onChange?.(Array.from(repo, ([key]) => key))
                  }}
                  onRemovePackages={(removeIds) => {
                    const toBeRemove = removeIds.reduce((acc, current) => {
                      acc.set(current, true)
                      return acc
                    }, new Map<string, boolean>())

                    onChange?.(
                      selectPackages.filter((id) => !toBeRemove.get(id)),
                    )
                  }}
                >
                  <Packages
                    paymentChannel={p!.paymentChannel!}
                    selectPackageRepo={selectPackageRepo}
                    onAddPackage={(addId) =>
                      onChange?.([...selectPackages, addId])
                    }
                    onRemovePackage={(removeId) =>
                      onChange?.(selectPackages.filter((id) => id !== removeId))
                    }
                    editable={editable}
                  />
                </SummaryPackage>
              )
            })
        )}
      </div>
    </>
  )
}

type PackagesProps = {
  selectPackageRepo: Map<string, boolean>
  paymentChannel: definitions["payment.v1.PaymentChannel"]
  onAddPackage: (id: string) => void
  onRemovePackage: (id: string) => void
  editable: boolean
}

const Packages: React.FC<PackagesProps> = ({
  paymentChannel,
  selectPackageRepo,
  onAddPackage,
  onRemovePackage,
  editable,
}) => {
  const { data } = usePackages(paymentChannel)
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4 pt-4">
      {data?.package?.map((p) => {
        return (
          <div key={p.id!} className="flex justify-between px-6 py-2">
            <EuiCheckbox
              id={p.id!}
              label={t("topup-package-name", {
                value: {
                  getCoin: priceStrToNum(p.getCoin),
                  chargeAmount: priceStrToNum(p.chargeAmount),
                },
              })}
              checked={selectPackageRepo.get(p.id!)}
              disabled={!editable}
              onChange={(e) => {
                if (e.target.checked) {
                  onAddPackage(p.id!)
                } else {
                  onRemovePackage(p.id!)
                }
              }}
            />
            <EuiText size="s" color="gray">
              <p>
                {t("time-range", {
                  value: { startAt: p.publishStart, endAt: p.publishEnd },
                })}
              </p>
            </EuiText>
          </div>
        )
      })}
    </div>
  )
}

const useCountSelectPackgesInChannel = (input: {
  channel: definitions["payment.v1.PaymentChannel"]
  selectPackageRepo: Map<string, boolean>
}) => {
  const { data } = usePackages(input.channel)
  const total = Number(data?.total || 0)
  const select =
    data?.package?.reduce((acc, current) => {
      if (input.selectPackageRepo.get(current.id!)) {
        return acc + 1
      }
      return acc
    }, 0) || 0

  const allPackageIds =
    data?.package?.reduce((acc, current) => {
      acc.push(current.id!)
      return acc
    }, [] as Array<string>) || []

  return {
    total,
    select,
    allPackageIds,
  }
}

type SummaryPackageProps = {
  channel: definitions["payment.v1.PaymentChannel"]
  selectPackageRepo: Map<string, boolean>
  onAddPackages: (ids: Array<string>) => void
  onRemovePackages: (ids: Array<string>) => void
  editable: boolean
}

const SummaryPackage: React.FC<PropsWithChildren<SummaryPackageProps>> = ({
  children,
  channel,
  selectPackageRepo,
  onAddPackages,
  onRemovePackages,
  editable,
}) => {
  const [open, setOpen] = useState(false)
  const { total, select, allPackageIds } = useCountSelectPackgesInChannel({
    channel,
    selectPackageRepo,
  })
  const { t } = useTranslation()

  return (
    <EuiPanel hasBorder>
      <EuiAccordion
        key={channel}
        forceState={open ? "open" : "closed"}
        onToggle={setOpen}
        id={channel}
        css={{
          "& .euiAccordion__buttonContent": { width: "100%" },
        }}
        buttonElement="div"
        buttonContent={
          <div className="flex justify-between">
            <div onClick={(e) => e.stopPropagation()}>
              <EuiCheckbox
                css={{ "& .euiCheckbox__label": { fontWeight: 700 } }}
                label={t(
                  `topup-coupon-promotion-editor.payment-channel.${channel}`,
                )}
                checked={select > 0 && select === total}
                disabled={!editable}
                indeterminate={select !== total && select > 0}
                id={channel}
                onChange={(e) => {
                  if (e.target.checked) {
                    onAddPackages(allPackageIds)
                  } else {
                    onRemovePackages(allPackageIds)
                  }
                }}
              />
            </div>
            <EuiText size="s">
              <p>{t("count-package", { value: total })}</p>
            </EuiText>
          </div>
        }
        arrowDisplay="right"
      >
        {open ? children : null}
      </EuiAccordion>
    </EuiPanel>
  )
}
