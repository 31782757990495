import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/order-specs/order_service"
import { Filter } from "@reeeed-mp/ui-common"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

export type giftCodeVariant =
  definitions["order.admin.v1.GiftCodeProductVariant"]

export const productsType: giftCodeVariant[] = [
  "GIFT_CODE_PRODUCT_VARIANT_UNSPECIFIED",
  "GIFT_CODE_PRODUCT_VARIANT_EBOOK",
  "GIFT_CODE_PRODUCT_VARIANT_AUDIO",
]
export const defaultTypeSorter =
  "GIFT_CODE_PRODUCT_VARIANT_UNSPECIFIED" as const

export type GiftCodeDetail = definitions["order.v1.GiftCode"] & {
  id: string
  productType: string
  owner: {
    username: string
    dateTime: string
  }
  redeemer?: {
    username: string
    dateTime: string
  }
}
export const useGetGiftCodeList = () => {
  const searchParamKey = "textSearch"
  const typeParamKey = "typeFilter"
  const [searchParams] = useSearchParams()
  const textSearchParam = searchParams.get(searchParamKey)
  const typeParam = searchParams.get(typeParamKey)
  const [textSearch, setTextSearch] = useState(textSearchParam || "")
  const [loading, setLoading] = useState<boolean>(false)
  const { webBff } = useApi()
  const findApi = webBff("/admin/gift-code").method("get").create()
  const [typeFilter, setTypeFilter] = useState<giftCodeVariant>(
    (typeParam as giftCodeVariant) || defaultTypeSorter,
  )

  const find = async ({ filter, sorter, opts }: Filter) => {
    const { data } = await findApi({
      "filter.code": filter.textSearch,
      "filter.productVariant": filter.typeFilter,
      sorter,
      "option.limit": opts.limit,
      "option.skip": opts.skip,
    })

    return data
  }

  return {
    find,
    textSearch,
    setTextSearch,
    typeFilter,
    setTypeFilter,
    loading,
    setLoading,
  }
}
