import { Table } from "@app/component/table"
import { EuiFieldText, EuiText } from "@elastic/eui"
import { UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  CodeRedemptionIndivCodeItem,
  useGetCodeRedemptionCodeList,
} from "./hook/list"
import { CodeStatus, mapCouponRedeemStatus } from "./hook/code-detail"
import { CodeStatusBadge } from "./components/code-status-badge"
import { CodeRedemptionReportButton } from "./components/report-button"

export const CodeRedemptionListPage = () => {
  const { t } = useTranslation()
  return (
    <div className="grid gap-y-8">
      <div className="flex w-full items-center justify-between">
        <EuiText className="font-bold">
          <h2>{t("code-redemption.title")}</h2>
        </EuiText>
        <CodeRedemptionReportButton />
      </div>

      <CouponTable />
    </div>
  )
}

const CouponTable = () => {
  const { find, setTextSearch, filter } = useGetCodeRedemptionCodeList()

  const onGetItems: OnGetItems<CodeRedemptionIndivCodeItem> = async (props) => {
    const data = await find({
      filter: props.filter!,
      opts: props.opts,
    })

    return {
      items:
        data?.items?.map(({ productName, shopCouponRedemption }) => {
          return {
            id: shopCouponRedemption?.coupon?.id!,
            productName: productName!,
            variant: shopCouponRedemption?.set?.variant!,
            code: shopCouponRedemption?.coupon?.code!,
            status: mapCouponRedeemStatus(
              shopCouponRedemption?.set?.startAt!,
              shopCouponRedemption?.set?.endAt!,
              shopCouponRedemption?.coupon?.redeemedAt,
            ),
            dayOfRental: shopCouponRedemption?.set?.dayOfRental!,
            startAt: shopCouponRedemption?.set?.startAt!,
            endAt: shopCouponRedemption?.set?.endAt!,
            createdAt: shopCouponRedemption?.campaign?.createdAt!,
            note: shopCouponRedemption?.campaign?.note!,
          }
        }) || [],
      count: parseInt(data?.count || "0"),
    }
  }

  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex w-full flex-row-reverse">
        <EuiFieldText
          icon={<UilSearch />}
          onChange={(e) => setTextSearch(e.target.value)}
          placeholder={t("code-redemption.filter.code-search")}
        />
      </div>
      <Table<CodeRedemptionIndivCodeItem>
        columns={[
          {
            field: "productName",
            name: t("code-redemption.table.product-name"),
          },
          {
            field: "variant",
            name: t("code-redemption.table.variant"),
          },
          {
            field: "code",
            name: t("code-redemption.table.code"),
            width: "25%",
          },
          {
            field: "status",
            name: t("code-redemption.table.status"),
            render: (status: CodeStatus) => {
              return <CodeStatusBadge status={status} />
            },
            width: "150",
            align: "center",
          },
          {
            field: "dayOfRental",
            name: t("code-redemption.table.expired-day"),
            width: "75",
            align: "center",
          },
          {
            field: "startAt",
            name: t("code-redemption.table.start-at"),
            render: (startAt: string) => {
              return t("common.date", { date: startAt })
            },
          },
          {
            field: "endAt",
            name: t("code-redemption.table.end-at"),
            render: (endAt: string) => {
              return t("common.date", { date: endAt })
            },
          },
          {
            field: "createdAt",
            name: t("code-redemption.table.created-at"),
            render: (createdAt: string) => {
              return t("common.datetime", { value: createdAt })
            },
            align: "center",
          },
          {
            field: "note",
            name: t("code-redemption.table.note"),
            render: (note: string) => {
              return <div className="line-clamp-1">{note || "-"}</div>
            },
          },
        ]}
        isCanSelect={false}
        swrKey="/shop-coupon-redemption"
        onGetItems={onGetItems}
        onRowClick={(row) => {
          navigate(`/coupon-redemption/${row.id}`)
        }}
        filter={filter}
      />
    </div>
  )
}
