import { ConfirmDeleteModal } from "@app/component"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { DownloadReportAPI, useDownloadReport } from "@app/hook/file"
import { GREY_BUTTON, LIGHTEST_GREY } from "@app/utils/color"
import {
  EuiBadge,
  EuiButton,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFieldNumber,
  EuiHorizontalRule,
  EuiIcon,
  EuiPopover,
  EuiProgress,
  EuiRadioGroup,
  EuiText,
  EuiTextArea,
  EuiToolTip,
} from "@elastic/eui"
import { css } from "@emotion/react"
import {
  UilCloudDownload,
  UilEllipsisV,
  UilMultiply,
  UilPen,
  UilRedo,
  UilTimes,
  UilTrash,
} from "@iconscout/react-unicons"
import moment, { Moment } from "moment"
import React, { PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"

export const Divider = () => {
  return (
    <div className="mt-10">
      <EuiHorizontalRule />
    </div>
  )
}

type RadioYesNoProps = {
  id: string
  value: boolean
  onChange: (value: boolean) => void
  yesLabel: string
  noLabel?: string
  disabled?: boolean
}

export const RadioYesNo: React.FC<RadioYesNoProps> = ({
  id,
  value,
  onChange,
  yesLabel,
  noLabel,
  disabled,
}) => {
  const yesId = `${id}_yes`
  const noId = `${id}_no`

  let options = [
    {
      id: yesId,
      label: <EuiText size="s">{yesLabel}</EuiText>,
    },
  ]
  if (noLabel) {
    options = [
      {
        id: noId,
        label: <EuiText size="s">{noLabel}</EuiText>,
      },
      ...options,
    ]
  }

  return (
    <EuiRadioGroup
      disabled={disabled}
      className="flex"
      css={{
        ".euiRadioGroup__item": {
          marginTop: 0,
        },
        ".euiRadioGroup__item + .euiRadioGroup__item": {
          marginLeft: 72,
        },
      }}
      options={options}
      idSelected={value ? yesId : noId}
      onChange={(id) => onChange(id === yesId)}
    />
  )
}

type HeadlineProps = {
  onGoBackClick: () => void
  text: string
  action?: React.ReactNode
}

export const Headline: React.FC<HeadlineProps> = ({
  text,
  onGoBackClick,
  action,
}) => {
  return (
    <div className="flex items-center">
      <EuiIcon
        type="arrowLeft"
        size="l"
        color="text"
        onClick={onGoBackClick}
        className="cursor-pointer"
      />
      <div
        className={`ml-2 ${
          action ? "flex w-full items-center justify-between" : ""
        }`}
      >
        <EuiText>
          <h2 className="font-bold">{text}</h2>
        </EuiText>
        {action}
      </div>
    </div>
  )
}

type FieldNumberWithUnitProps = {
  unit: string
  name?: string
  value?: number
  placeholder?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  isInvalid?: boolean
  min?: number
  max?: number
  disabled?: boolean
}

export const FieldNumberWithUnit: React.FC<FieldNumberWithUnitProps> = ({
  placeholder,
  unit,
  value,
  onChange,
  name,
  isInvalid,
  min,
  max,
  disabled = false,
}) => {
  return (
    <EuiFieldNumber
      disabled={disabled}
      fullWidth
      placeholder={placeholder}
      append={
        <EuiToolTip content="content">
          <EuiText size="s">{unit}</EuiText>
        </EuiToolTip>
      }
      value={value}
      name={name}
      onChange={(e) => {
        const num = Number(e.target.value)
        if (
          (typeof min === "number" && num < min) ||
          (typeof max === "number" && num > max)
        ) {
          return
        }
        onChange(e)
      }}
      isInvalid={isInvalid}
      min={min}
      max={max}
    />
  )
}

type TextAreaProps = {
  label: string
  name: string
  value: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  optional?: boolean
}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  optional,
  name,
  value,
  onChange,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex items-center">
        <EuiText>
          <h2 className="font-bold">{label}</h2>
        </EuiText>
        {optional && (
          <EuiText
            size="s"
            className="ml-2"
            css={css`
              color: ${LIGHTEST_GREY} !important;
            `}
          >
            {t("promotion.editor.optional")}
          </EuiText>
        )}
      </div>
      <div className="mt-2 flex">
        <EuiTextArea
          fullWidth
          placeholder={label}
          name={name}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  )
}

type SummaryPromotionCardProps = {
  onClick: () => void
  isLoading?: boolean
  btnText: string
}

export const SummaryPromotionCard: React.FC<
  PropsWithChildren<SummaryPromotionCardProps>
> = ({ children, onClick, isLoading, btnText }) => {
  const { t } = useTranslation()

  return (
    <>
      <EuiText size="s" className="font-bold">
        {t("promotion.editor.example-promotion-detail")}
      </EuiText>
      <div
        className="rounded-md p-8"
        css={{
          backgroundColor: "#fbfcfd",
          border: "solid 0.5px #e5e7eb",
        }}
      >
        {children}
        <EuiButton
          color="primary"
          fill
          fullWidth
          onClick={onClick}
          className={"mt-4"}
          isLoading={isLoading}
        >
          {btnText}
        </EuiButton>
      </div>
    </>
  )
}

type TimeLine = {
  startAt?: Moment
  endAt?: Moment
}

type RowOption = {
  id: string
  codeActive: TimeLine
  disableRenew: boolean
  loading: boolean
  onEnd?: (id: string) => void
  onEdit?: (id: string) => void
  onReNew?: (id: string) => void
  onDelete?: (id: string) => void
}

export enum CodeStatus {
  Draft = 0,
  Waiting,
  Active,
  End,
}

export const isAlreadyInCollectingPeriod = (
  id: string,
  {
    collectCodeStartAt,
    startAt,
  }: {
    collectCodeStartAt?: Moment
    startAt?: Moment
  },
) => {
  if (id === "create") {
    return false
  }

  return collectCodeStartAt
    ? moment().isAfter(collectCodeStartAt)
    : moment().isAfter(startAt)
}

export const toCodeStatus = (
  now: Moment,
  { startAt, endAt }: TimeLine,
): CodeStatus => {
  if (!startAt) {
    return CodeStatus.Draft
  }

  if (startAt.isAfter(now)) {
    return CodeStatus.Waiting
  }

  if (!endAt || endAt.isAfter(now)) {
    return CodeStatus.Active
  }

  return CodeStatus.End
}

type TitleDescriptionModal = {
  title: string
  description: string
}

export const Options: React.FC<RowOption> = (props: RowOption) => {
  const { t } = useTranslation()
  const [isOpenPopOver, setIsOpenPopOver] = useState<boolean>(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false)
  const [titleDescriptionModal, setTitleDescriptionModal] = useState<
    TitleDescriptionModal | undefined
  >({
    title: t("common.please-confirm"),
    description: "",
  })
  const item = []
  const now = moment()
  const [funcActionConfirmButton, setFuncActionConfirmButton] = useState<
    ((id: string) => void) | undefined
  >(undefined)
  const codeStatus = toCodeStatus(now, props.codeActive)

  switch (codeStatus) {
    case CodeStatus.Active:
      item.push(
        <div key={props.id}>
          <EuiContextMenuItem
            key={`end-${props.id}`}
            className="text-ci-red"
            icon={<UilMultiply size={"16"} />}
            onClick={() => {
              setTitleDescriptionModal({
                title: t("promotion.editor.confirm-end-promotion"),
                description: t("promotion.editor.confirm-end-promotion-detail"),
              })
              setFuncActionConfirmButton(() => props.onEnd)
              setIsOpenModalConfirm(true)
            }}
          >
            {t("promotion.list.set-end-promotion")}
          </EuiContextMenuItem>
        </div>,
      )
      break
    case CodeStatus.Draft:
    case CodeStatus.Waiting:
      item.push(
        <>
          <EuiContextMenuItem
            key="edit"
            icon={<UilPen size={"16"} />}
            onClick={() => {
              setTitleDescriptionModal(undefined)
              if (props.onEdit) {
                props.onEdit(props.id)
              }

              setIsOpenPopOver(false)
            }}
          >
            {t("promotion.list.edit")}
          </EuiContextMenuItem>
          <EuiContextMenuItem
            key="delete"
            icon={<UilTrash size={"16"} />}
            onClick={() => {
              setTitleDescriptionModal({
                title: t("promotion.editor.confirm-delete-promotion-title"),
                description: t("promotion.editor.confirm-delete-promotion"),
              })
              setFuncActionConfirmButton(() => props.onDelete)
              setIsOpenModalConfirm(true)
            }}
          >
            {t("promotion.list.delete")}
          </EuiContextMenuItem>
        </>,
      )
      break
    case CodeStatus.End:
      item.push(
        <>
          {!props.disableRenew && (
            <EuiContextMenuItem
              key="renew"
              icon={
                <UilRedo
                  size={"16"}
                  onClick={() => {
                    if (props.onReNew) {
                      props.onReNew(props.id)
                    }
                    setIsOpenPopOver(false)
                  }}
                />
              }
              onClick={() => {
                setTitleDescriptionModal(undefined)
                setIsOpenModalConfirm(false)
                setIsOpenPopOver(false)
                if (props.onReNew) {
                  props.onReNew(props.id)
                }
              }}
            >
              {t("promotion.list.renew-promotion")}
            </EuiContextMenuItem>
          )}

          <EuiContextMenuItem
            key="delete"
            icon={<UilTrash size={"16"} />}
            onClick={() => {
              setTitleDescriptionModal({
                title: t("promotion.editor.confirm-delete-promotion-title"),
                description: t("promotion.editor.confirm-delete-promotion"),
              })
              setFuncActionConfirmButton(() => props.onDelete)
              setIsOpenModalConfirm(true)
            }}
          >
            {t("promotion.list.delete")}
          </EuiContextMenuItem>
        </>,
      )
  }

  return (
    <EuiPopover
      id={`${props.id}-actions`}
      key={`${props.id}-actions`}
      button={
        <UilEllipsisV
          size={"14"}
          onClick={() => {
            setIsOpenPopOver(!isOpenPopOver)
          }}
        />
      }
      isOpen={isOpenPopOver}
      closePopover={() => setIsOpenPopOver(false)}
      anchorPosition="leftCenter"
    >
      <EuiContextMenuPanel items={item} />
      {isOpenModalConfirm ? (
        <ConfirmDeleteModal
          onClose={() => {
            setIsOpenPopOver(false)
            setIsOpenModalConfirm(false)
          }}
          onConfirm={async () => {
            if (funcActionConfirmButton) {
              await funcActionConfirmButton(props.id)
            }

            setIsOpenPopOver(false)
            setIsOpenModalConfirm(false)
          }}
          isDeleting={props.loading}
          textConfirmButton={t("common.confirm")}
          title={titleDescriptionModal ? titleDescriptionModal.title : ""}
          description={
            titleDescriptionModal ? titleDescriptionModal.description : ""
          }
        />
      ) : null}
    </EuiPopover>
  )
}

export const ActionButtonInDetail: React.FC<{
  codeActive: TimeLine
  promotionId: string
  disableRenew: boolean
  onEdit?: () => void
  onDelete?: () => Promise<void>
  onRenew?: () => void
  onEnd?: () => Promise<void>
  downloadReport?: DownloadReportAPI
  loading?: boolean
}> = (props) => {
  let actionBtns: React.ReactNode = null
  const { t } = useTranslation()
  const status = toCodeStatus(moment(), props.codeActive)
  const [openModalConfirmFor, setOpenModalConfirmFor] = useState<
    null | "delete" | "end"
  >(null)
  const [titleDescriptionModal, setTitleDescriptionModal] = useState<
    TitleDescriptionModal | undefined
  >({
    title: t("common.please-confirm"),
    description: "",
  })
  const { download, loadingPercentage, error } = useDownloadReport(
    props.downloadReport,
    {
      autoDownload: true,
    },
  )
  useAlertIfDefaultErr([error])

  switch (status) {
    case CodeStatus.Waiting:
    case CodeStatus.Draft:
      actionBtns = (
        <div className="flex">
          <EuiButton
            isLoading={props.loading}
            iconType={UilTrash}
            className="mr-1 w-[128px] bg-white"
            css={css({
              color: "#B4251D !important",
            })}
            onClick={() => {
              setTitleDescriptionModal({
                title: t("promotion.editor.confirm-delete-promotion-title"),
                description: t("promotion.editor.confirm-delete-promotion"),
              })
              setOpenModalConfirmFor("delete")
            }}
          >
            <EuiText size="xs">
              {t("promotion.editor.delete-promotion")}
            </EuiText>
          </EuiButton>
          <EuiButton
            isLoading={props.loading}
            color="text"
            css={css({
              backgroundColor: `${GREY_BUTTON} !important`,
            })}
            className="w-[128px] text-black"
            onClick={props.onEdit}
          >
            <EuiText size="xs">{t("promotion.editor.edit")}</EuiText>
          </EuiButton>
        </div>
      )
      break
    case CodeStatus.Active:
      actionBtns = (
        <div className="flex">
          {props.downloadReport && (
            <EuiButton
              isLoading={
                loadingPercentage && loadingPercentage > 0 ? true : false
              }
              iconType={UilCloudDownload}
              onClick={download}
              className="relative overflow-hidden"
            >
              <EuiText size="xs">
                {t("promotion.editor.download-report")}
              </EuiText>
              <div className="absolute bottom-0 w-full">
                {loadingPercentage && loadingPercentage > 0 ? (
                  <EuiProgress
                    value={loadingPercentage}
                    max={100}
                    size="xs"
                    color="primary"
                    className="w-full"
                  />
                ) : null}
              </div>
            </EuiButton>
          )}
          <EuiButton
            isLoading={props.loading}
            iconType={UilTimes}
            className="mr-1 w-[128px] bg-white"
            css={css({
              color: "#B4251D !important",
            })}
            onClick={() => {
              setTitleDescriptionModal({
                title: t("promotion.editor.confirm-end-promotion"),
                description: t("promotion.editor.confirm-end-promotion-detail"),
              })
              setOpenModalConfirmFor("end")
            }}
          >
            <EuiText size="xs">{t("promotion.editor.end-promotion")}</EuiText>
          </EuiButton>
        </div>
      )
      break
    case CodeStatus.End:
      actionBtns = (
        <div className="flex" key={3}>
          {props.downloadReport && (
            <EuiButton
              isLoading={
                loadingPercentage && loadingPercentage > 0 ? true : false
              }
              iconType={UilCloudDownload}
              onClick={download}
              className="relative overflow-hidden"
            >
              <EuiText size="xs">
                {t("promotion.editor.download-report")}
              </EuiText>
              <div className="absolute bottom-0 w-full">
                {loadingPercentage && loadingPercentage > 0 ? (
                  <EuiProgress
                    value={loadingPercentage}
                    max={100}
                    size="xs"
                    color="primary"
                    className="w-full"
                  />
                ) : null}
              </div>
            </EuiButton>
          )}
          <EuiButton
            isLoading={props.loading}
            iconType={UilTrash}
            className="mr-1 w-[128px] bg-white"
            css={css({
              color: "#B4251D !important",
            })}
            onClick={() => {
              setTitleDescriptionModal({
                title: t("promotion.editor.confirm-delete-promotion-title"),
                description: t("promotion.editor.confirm-delete-promotion"),
              })
              setOpenModalConfirmFor("delete")
            }}
          >
            <EuiText size="xs">
              {t("promotion.editor.delete-promotion")}
            </EuiText>
          </EuiButton>
          {!props.disableRenew && (
            <EuiButton
              isLoading={props.loading}
              color="text"
              iconType={UilRedo}
              css={css({
                backgroundColor: `${GREY_BUTTON} !important`,
              })}
              className="w-[159px] text-black"
              onClick={props.onRenew}
            >
              <EuiText size="xs">
                {t("promotion.editor.renew-promotion")}
              </EuiText>
            </EuiButton>
          )}
        </div>
      )
      break
    default:
      return null
  }

  return (
    <>
      {actionBtns}
      {openModalConfirmFor && (
        <ConfirmDeleteModal
          onClose={() => {
            setOpenModalConfirmFor(null)
          }}
          onConfirm={async () => {
            switch (openModalConfirmFor) {
              case "delete":
                await props.onDelete?.()
                break
              case "end":
                await props.onEnd?.()
                break
            }

            setOpenModalConfirmFor(null)
          }}
          isDeleting={props.loading}
          textConfirmButton={t("common.confirm")}
          title={titleDescriptionModal ? titleDescriptionModal.title : ""}
          description={
            titleDescriptionModal ? titleDescriptionModal.description : ""
          }
        />
      )}
    </>
  )
}

type StatusBadgeProps = {
  codeActive: TimeLine
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ codeActive }) => {
  const { t } = useTranslation()
  const status = toCodeStatus(moment(), codeActive)
  const configMap = new Map<CodeStatus, { className?: string; text: string }>([
    [
      CodeStatus.Active,
      { className: "bg-green", text: "promotion.detail.promotion-started" },
    ],
    [
      CodeStatus.Waiting,
      { className: "bg-yellow", text: "promotion.detail.promotion-will-start" },
    ],
    [CodeStatus.Draft, { text: "promotion.detail.promotion-draft" }],
    [
      CodeStatus.End,
      { className: "bg-red", text: "promotion.detail.promotion-ended" },
    ],
  ])
  const c = configMap.get(status)

  return (
    <div className="mt-8">
      <EuiBadge className={c!.className}>
        <EuiText size="xs">{t(c!.text)}</EuiText>
      </EuiBadge>
    </div>
  )
}

type CountCouponUsageProps = {
  usage: number
  limit: number
}

export const CountCouponUsage: React.FC<CountCouponUsageProps> = ({
  usage,
  limit,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="mt-6 flex w-full justify-between">
        <EuiText size="xs">{t("promotion.detail.promotion-usage")}</EuiText>
        <EuiText size="xs">{`${usage}/${limit}`}</EuiText>
      </div>
      <EuiProgress value={usage} max={limit} size="m" color={"primary"} />
    </>
  )
}
