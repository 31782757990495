import { useStore } from "@app/hook/store"
import { EuiSwitch, EuiText, UseEuiTheme, useEuiTheme } from "@elastic/eui"
import { css } from "@emotion/react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useSWR from "swr"
import { useGetShop, useShop } from "../hook"
import { ShopInfoCard } from "./shop-info-card"

const toggleClassCSS = (theme: UseEuiTheme) => {
  return {
    ".euiSwitch .euiSwitch__button[aria-checked=true] .euiSwitch__body .euiSwitch__thumb":
      {
        borderColor: "white !important",
      },

    ".euiSwitch .euiSwitch__button[aria-checked=true] .euiSwitch__body": {
      background: `${theme.euiTheme.colors.success} !important`,
    },
    ".euiSwitch .euiSwitch__button:disabled .euiSwitch__thumb": {
      backgroundColor: `white !important`,
    },
    ".euiSwitch .euiSwitch__button:disabled .euiSwitch__icon": {
      fill: "white !important",
    },
  }
}

export const ContainerShopInfoSettingCard: React.FC = () => {
  const { getTotalOrder } = useShop()
  const { shopId } = useParams()
  const { notificationStore } = useStore()
  const { data, isLoading } = useGetShop(shopId ?? "")

  const {
    data: totalOrder,
    error,
    isLoading: isLoadingTotalOrder,
  } = useSWR(["/shop-order", shopId], async ([, shopId]) => {
    if (shopId) {
      return await getTotalOrder(shopId)
    }
    return
  })

  useEffect(() => {
    if (error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: error.message,
      })
    }
  }, [error, notificationStore])

  return (
    <ShopInfoCard
      loading={isLoading}
      loadingNumberOfOrder={isLoadingTotalOrder}
      verificationStatus={
        data?.shop?.withdrawAccountVerificationStatus ??
        "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_UNSPECIFIED"
      }
      url={data?.shop?.cover?.path ?? ""}
      name={data?.shop?.name ?? ""}
      phoneNumber={data?.extendedShopDetail?.phoneNumber ?? ""}
      numberOfOrder={totalOrder?.numberOfOrder ?? 0}
      productNumber={data?.shop?.totalNumberOfProduct ?? ""}
      email={data?.extendedShopDetail?.email ?? ""}
      shopId={shopId ?? ""}
    />
  )
}

export const ToggleSwitchList = (props: {
  toggles: {
    title: string
    onToggle: (value: boolean) => void
    enabled: boolean
    disabled: boolean

    hidden?: boolean
  }[]
}) => {
  const theme = useEuiTheme()
  const { t } = useTranslation()
  return (
    <div css={css(toggleClassCSS(theme))}>
      <EuiText className="mb-6">
        <h3 className="text-l font-bold">
          {t("shop-information.setting-header")}
        </h3>
      </EuiText>
      <div className="flex flex-col gap-y-6">
        {props.toggles
          .filter((toggle) => !toggle.hidden)
          .map((toggle, index) => (
            <div key={index} className="flex items-center space-x-4">
              <span className="w-64 font-semibold">{toggle.title}</span>
              <EuiSwitch
                label={
                  toggle.enabled
                    ? t("shop-information.toggle.enabled")
                    : t("shop-information.toggle.disabled")
                }
                checked={toggle.enabled}
                onChange={(e) => toggle.onToggle(e.target.checked)}
                disabled={toggle.disabled}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
