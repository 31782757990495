import {
  EuiButton,
  EuiButtonColor,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import React from "react"

export const SmallModal: React.FC<{
  onConfirm: () => void
  confirmBtnText: string
  onCancel: () => void
  cancelBtnText: string
  header: string
  body?: React.ReactElement
  isOpen: boolean
  className?: string
  loading?: boolean
  color?: EuiButtonColor
  disabledConfirmBtn?: boolean
}> = ({
  isOpen,
  body,
  cancelBtnText,
  confirmBtnText,
  header,
  onCancel,
  onConfirm,
  className,
  loading,
  color,
  disabledConfirmBtn,
}) => {
  return (
    <>
      {isOpen ? (
        <EuiModal
          className={className}
          title={header}
          maxWidth={448}
          onClose={() => {}}
          css={css`
            .euiModal__closeIcon {
              display: none;
            }
          `}
        >
          <EuiModalHeader>
            <EuiText>
              <h2 className="title">{header}</h2>
            </EuiText>
          </EuiModalHeader>

          <EuiModalBody className="-mt-2">{body}</EuiModalBody>

          <EuiModalFooter className="w-full">
            <EuiButton
              isLoading={loading}
              disabled={loading || disabledConfirmBtn}
              color={color || "primary"}
              fill
              onClick={onConfirm}
              className="w-1/2"
            >
              {confirmBtnText}
            </EuiButton>

            <EuiButtonEmpty
              color={color || "primary"}
              className="w-1/2"
              onClick={onCancel}
              disabled={loading}
            >
              {cancelBtnText}
            </EuiButtonEmpty>
          </EuiModalFooter>
        </EuiModal>
      ) : (
        <></>
      )}
    </>
  )
}
