import { EuiSwitch } from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useFetchBanners } from "./hook/banner"
import { useNavigate } from "react-router-dom"
import { useAdditionalSection, useHomeManagementStore } from "../store/ctx"
import { useUpdateSmallBanner } from "./hook/update-detail"
import { BannerConfigSection, BannerList } from "../components/banner-common"
import { useFormSubmit, useInitializeForm } from "../hook/form"
import { smallBannerPath } from "../hook/path"
import { MenuTypes } from "../hook/model"

export const SmallBannerConfigSection = () => {
  const { selectedId } = useHomeManagementStore()
  const { activeBanner } = useFetchBanners(selectedId!, MenuTypes.smallBanner)
  const { sections } = useAdditionalSection()
  const { t } = useTranslation()
  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail

  const form = useUpdateSmallBanner(selectedId!, selectedSection!)
  const navigate = useNavigate()

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)

  return (
    <BannerConfigSection
      type={MenuTypes.smallBanner}
      form={form}
      availableLayouts={[
        "SMALL_BANNER_LAYOUT_OVERFLOW",
        "SMALL_BANNER_LAYOUT_OVERFLOW_TWO_ROWS",
        "SMALL_BANNER_LAYOUT_FIXED",
      ]}
      isLoading={isSubmitting}
    >
      <BannerList
        menu={MenuTypes.smallBanner}
        activeBanner={activeBanner}
        pendingBanner={undefined}
        onClickEdit={(bannerKey) => {
          navigate(smallBannerPath(selectedId!, bannerKey))
        }}
        onClickManage={() => {
          navigate(smallBannerPath(selectedId!))
        }}
        toggleSwitch={
          <EuiSwitch
            label={t("home-management.banner.show-banner-name")}
            checked={form.values?.shouldShowBannerTitle || false}
            onChange={(e) => {
              form.setFieldValue("shouldShowBannerTitle", e.target.checked)
            }}
          />
        }
      />
    </BannerConfigSection>
  )
}
