import { useStore } from "@app/hook/store"
import { createContext, useContext, useState } from "react"
import { TopupPackageStore } from "./index"

const topupStoreCtx = createContext<TopupPackageStore | null>(null)

type ToupPackageStoreProviderProps = {
  children?: React.ReactNode
}

export const TopupPackageStoreProvider: React.FC<
  ToupPackageStoreProviderProps
> = ({ children }) => {
  const rootStore = useStore()
  const [store] = useState(new TopupPackageStore(rootStore))
  return (
    <topupStoreCtx.Provider value={store}>{children}</topupStoreCtx.Provider>
  )
}

export const useTopupPackageStore = () => {
  const topupPackageStore = useContext(topupStoreCtx)
  if (!topupPackageStore) {
    throw new Error("component must be wrap with TopuPackageStoreProvider")
  }
  return topupPackageStore
}
