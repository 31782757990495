import { HomeManagementCard } from "@app/component/home-management/card"
import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { HomeManagementMode } from "@app/model/homepage/homepage"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  DropResult,
  EuiButton,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddItemButton } from "../component/add-item-button"
import { ReorderButton } from "../component/reorder-button"
import { useHomeManagementStore } from "../store/ctx"

export const ListCategory: React.FC = () => {
  const { t } = useTranslation()
  const { t: categoryT } = useTranslation("translation", {
    keyPrefix: "homepage-management.category",
  })
  const [mode, setMode] = useState(HomeManagementMode.IDLE)
  const homeManagementStore = useHomeManagementStore()

  const [tempCategories, setTempCategories] = useState<
    | definitions["webutility.admin.v1.RecommendCategorySection.Category"][]
    | undefined
  >(homeManagementStore.homepage?.categorySection?.categories)

  const navigate = useNavigate()

  const onDragEnd = (result: DropResult) => {
    if (result.source && result.destination && tempCategories) {
      const items = euiDragDropReorder(
        tempCategories,
        result.source.index,
        result.destination.index,
      )

      setTempCategories(items)
    }
  }

  useEffect(() => {
    setTempCategories(homeManagementStore.homepage?.categorySection?.categories)
  }, [homeManagementStore.homepage?.categorySection?.categories])

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      {mode === HomeManagementMode.IDLE ? (
        <div className="flex flex-row justify-between">
          <NavigationHeaderButton
            title={categoryT("title")}
            onClick={() => {
              navigate("/home-management")
            }}
          />
          <ReorderButton
            onPress={() => {
              setMode(HomeManagementMode.REORDER)
            }}
          />
        </div>
      ) : mode === HomeManagementMode.REORDER ? (
        <div className="flex flex-row items-center justify-between">
          <EuiText>
            <h1 className="p-0">{categoryT("reorder-category")}</h1>
          </EuiText>
          <div>
            <EuiButton
              css={css`
                width: 210px;
                height: 40px;
              `}
              color="text"
              onClick={() => {
                setMode(HomeManagementMode.IDLE)
              }}
            >
              {t("common.cancel")}
            </EuiButton>
            <EuiButton
              css={css`
                width: 210px;
                height: 40px;
              `}
              className="ml-4"
              fill
              onClick={async () => {
                setMode(HomeManagementMode.IDLE)
                if (tempCategories) {
                  homeManagementStore.update("categorySection", {
                    categories: tempCategories,
                  })
                }
              }}
            >
              {t("common.save")}
            </EuiButton>
          </div>
        </div>
      ) : null}
      {mode === HomeManagementMode.IDLE ||
      mode === HomeManagementMode.REORDER ? (
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <EuiDroppable
            className="mt-10 grid grid-cols-4 gap-x-8 gap-y-16 "
            droppableId="LIST_CATEGORY"
            type="TYPE_ONE"
            isDropDisabled={mode !== HomeManagementMode.REORDER}
            direction="horizontal"
          >
            <>
              {tempCategories?.map((c, index) => {
                return (
                  <EuiDraggable
                    isDragDisabled={mode !== HomeManagementMode.REORDER}
                    key={c.id}
                    index={index}
                    draggableId={c.id ?? index.toString()}
                  >
                    {() => (
                      <HomeManagementCard
                        imageCss={css`
                          width: 128px !important;
                          height: 128px;
                        `}
                        cardCss={css`
                          .euiPopover {
                            position: absolute;
                            top: 0px;
                            right: 0px;
                          }
                        `}
                        url={c.url}
                        imageUrl={
                          (
                            c as definitions["webutility.admin.v1.RecommendCategorySection.Category"] & {
                              downloadUrl?: string
                            }
                          )?.downloadUrl ?? c.image
                        }
                        id={c.id ?? ""}
                        editMode={mode === HomeManagementMode.REORDER}
                        title={c.name ?? ""}
                        onPressEdit={() => {
                          navigate(`/home-management/category/${c.id}`)
                        }}
                        onPressDelete={() => {
                          const index =
                            homeManagementStore.homepage?.categorySection?.categories?.findIndex(
                              (h) => {
                                return c.id === h.id
                              },
                            )
                          if (index !== undefined && index !== -1) {
                            homeManagementStore.update("categorySection", {
                              categories: [
                                ...(homeManagementStore.homepage?.categorySection?.categories?.slice(
                                  0,
                                  index,
                                ) ?? []),
                                ...(homeManagementStore.homepage?.categorySection?.categories?.slice(
                                  index + 1,
                                ) ?? []),
                              ],
                            })
                          }
                        }}
                      />
                    )}
                  </EuiDraggable>
                )
              })}
              {mode === HomeManagementMode.IDLE ? (
                <AddItemButton
                  onClick={() => {
                    navigate("/home-management/category/create")
                  }}
                  label={categoryT("add-category")}
                  height={218}
                />
              ) : null}
            </>
          </EuiDroppable>
        </EuiDragDropContext>
      ) : null}
    </div>
  )
}
