import { useApi } from "@app/hook/api"
import { useAuth } from "@app/hook/store"
import {
  EuiButtonEmpty,
  EuiContextMenu,
  EuiIcon,
  EuiPopover,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui"
import * as Unicons from "@iconscout/react-unicons"
import { useState } from "react"
import useSWR from "swr"
import "./style.scss"

export const TopNavbar = () => {
  const { authAdmin } = useApi()
  const [isPopoverOpen, setPopover] = useState(false)
  const contextMenuPopoverId = useGeneratedHtmlId({
    prefix: "contextMenuPopover",
  })
  const auth = useAuth()

  const me = authAdmin("/me").method("get").create()
  const { data } = useSWR("/admin/me", async () => me({}))

  return (
    <div className="top-navigation flex h-12 items-center justify-end bg-white pr-4">
      <EuiPopover
        id={contextMenuPopoverId}
        closePopover={() => setPopover(false)}
        isOpen={isPopoverOpen}
        button={
          <EuiButtonEmpty onClick={() => setPopover(true)}>
            <span className="flex flex-row items-center">
              <EuiText className="underline">
                <p>{data?.data?.username || "-"}</p>
              </EuiText>
              <EuiText className="mr-2"></EuiText>
              <Unicons.UilAngleDown size="16" className="text-primary" />
            </span>
          </EuiButtonEmpty>
        }
        panelPaddingSize="none"
      >
        <EuiContextMenu
          initialPanelId={0}
          panels={[
            {
              id: 0,
              items: [
                {
                  name: "Logout",
                  icon: <EuiIcon type="push" size="m" />,
                  onClick: () => auth.logout(),
                },
              ],
            },
          ]}
        />
      </EuiPopover>
    </div>
  )
}
