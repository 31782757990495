import { EuiButton, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import { UilPlus } from "@iconscout/react-unicons"

type AddItemButtonProps = {
  onClick: () => void
  label: string
  height?: number
}

export const AddItemButton = (props: AddItemButtonProps) => {
  return (
    <EuiButton
      className="h-full w-full border border-solid border-grey-light bg-lightest"
      color="text"
      css={css({
        minHeight: props.height || 270,
      })}
      onClick={props.onClick}
    >
      <div className="flex flex-col items-center">
        <UilPlus width={16} height={16} className="mb-5" color="black" />
        <EuiText className="font-bold">
          <p>{props.label}</p>
        </EuiText>
      </div>
    </EuiButton>
  )
}
