import { css } from "@emotion/react"
import { useTranslation } from "react-i18next"

export const ProductHiddenBadge: React.FC<{
  isHidden: boolean | undefined
}> = (props) => {
  const { t } = useTranslation()

  return props.isHidden ? (
    <div
      className="rounded px-2 py-0.5 text-center text-base w-fit"
      css={css({
        backgroundColor: "#FF7E62",
      })}
    >
      {t("shop-information.product-hidden")}
    </div>
  ) : null
}
