import { NavigationHeaderButton } from "@app/component"
import { VerificationStatus } from "@app/component/withdraw-account/status"
import { useStore } from "@app/hook/store"
import { GREY_BUTTON } from "@app/utils/color"
import { definitions } from "@app/vendor/shop-specs/shop_service"
import { EuiButton, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import useSWR from "swr"
import { useWithdrawAccount } from "../hook"
import { AddressWithdrawAccountCard } from "./address-card"
import { WithdrawAccountAttachmentCard } from "./attachment-card"
import { BankAccountWithdrawAccountCard } from "./bank-account-card"
import { WithdrawAccountCard } from "./card"
import { VerifyingWithdrawAccountModal } from "./modal"
import { WithdrawAccountText } from "./text"

enum VerifyStatus {
  APPROVED = 0,
  REJECTED = 1,
}

export const VerifySellerManagement: React.FC = () => {
  const navigate = useNavigate()
  const { verifyWithdrawAccount, getWithdrawAccount, getDownloadUrl } =
    useWithdrawAccount()
  const { shopId } = useParams()
  const { notificationStore } = useStore()
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [verifyStatus, setVerifyStatus] = useState<VerifyStatus>()
  const { t: SellerT } = useTranslation("translation", {
    keyPrefix: "seller-management",
  })

  const {
    data,
    mutate,
    error: err,
  } = useSWR([shopId!, "/withdraw-account"], (props) => {
    return getWithdrawAccount(props[0])
  })

  const verify = async (
    status: definitions["shop.v1.WithdrawAccountVerificationStatus"],
  ) => {
    if (shopId) {
      try {
        await verifyWithdrawAccount(shopId, status)
        notificationStore.add({
          title: "Success",
          color: "success",
        })
        mutate()
      } catch (err: unknown) {
        if (err instanceof Error) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: err.message,
          })
        }
      }
    }
  }

  const downloadURL = async (
    shopId: string,
    documentType: definitions["shop.v1.DownloadDocumentWithdrawAccountType"],
    fileName: string,
  ) => {
    try {
      const url = await getDownloadUrl(shopId, documentType)
      if (url) {
        const response = await fetch(url, {
          method: "GET",
          headers: {},
        })

        const buffer = await response.arrayBuffer()
        const downloadUrl = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement("a")
        link.href = downloadUrl
        link.setAttribute("download", fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: err.message,
        })
      }
    }
  }

  const withdrawAccount = data?.data.withdrawAccount

  useEffect(() => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }, [err, notificationStore])

  const bankOwnerInformation = []
  if (withdrawAccount?.individualWithdrawAccount) {
    bankOwnerInformation.push({
      title: SellerT("bank-owner-information"),
      data: [
        {
          label: SellerT("withdraw-account-type"),
          value: <WithdrawAccountText text={SellerT("individual")} />,
        },
        {
          label: SellerT("name"),
          value: (
            <WithdrawAccountText
              text={withdrawAccount?.individualWithdrawAccount?.firstName ?? ""}
            />
          ),
        },
        {
          label: SellerT("last-name"),
          value: (
            <WithdrawAccountText
              text={withdrawAccount?.individualWithdrawAccount?.lastName ?? ""}
            />
          ),
        },
        {
          label: SellerT("identification-number"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.individualWithdrawAccount?.nationalId ?? ""
              }
            />
          ),
        },
        {
          label: SellerT("email"),
          value: (
            <WithdrawAccountText
              text={withdrawAccount?.individualWithdrawAccount?.email ?? ""}
            />
          ),
        },
        {
          label: SellerT("phone"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.individualWithdrawAccount?.phoneNumber ?? ""
              }
            />
          ),
        },
      ],
    })
  }

  if (withdrawAccount?.companyWithdrawAccount) {
    bankOwnerInformation.push({
      title: SellerT("bank-owner-information"),
      data: [
        {
          label: SellerT("withdraw-account-type"),
          value: <WithdrawAccountText text={SellerT("company")} />,
        },
        {
          label: SellerT("firstname-lastname"),
          value: (
            <WithdrawAccountText
              text={withdrawAccount?.companyWithdrawAccount?.name ?? ""}
            />
          ),
        },
        {
          label: SellerT("tax-payer-identification-number"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount
                  ?.taxpayerIdentificationNumber ?? ""
              }
            />
          ),
        },
      ],
    })
  }

  if (withdrawAccount?.companyWithdrawAccount?.operationStaff) {
    bankOwnerInformation.push({
      title: SellerT("operating-department"),
      data: [
        {
          label: SellerT("firstname-lastname"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount?.operationStaff?.name ??
                ""
              }
            />
          ),
        },
        {
          label: SellerT("email"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount?.operationStaff
                  ?.email ?? ""
              }
            />
          ),
        },
        {
          label: SellerT("phone"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount?.operationStaff
                  ?.phoneNumber ?? ""
              }
            />
          ),
        },
      ],
    })
  }

  if (withdrawAccount?.companyWithdrawAccount?.accountStaff) {
    bankOwnerInformation.push({
      title: SellerT("accounting-department"),
      data: [
        {
          label: SellerT("firstname-lastname"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount?.accountStaff?.name ??
                ""
              }
            />
          ),
        },
        {
          label: SellerT("email"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount?.accountStaff?.email ??
                ""
              }
            />
          ),
        },
        {
          label: SellerT("phone"),
          value: (
            <WithdrawAccountText
              text={
                withdrawAccount?.companyWithdrawAccount?.accountStaff
                  ?.phoneNumber ?? ""
              }
            />
          ),
        },
      ],
    })
  }

  return (
    <div>
      {openConfirmationModal ? (
        <VerifyingWithdrawAccountModal
          onSubmit={() => {
            if (verifyStatus === VerifyStatus.APPROVED) {
              verify("WITHDRAW_ACCOUNT_VERIFICATION_STATUS_APPROVED")
              setOpenConfirmationModal(false)
            } else if (verifyStatus === VerifyStatus.REJECTED) {
              verify("WITHDRAW_ACCOUNT_VERIFICATION_STATUS_REJECTED")
              setOpenConfirmationModal(false)
            }
          }}
          title={
            verifyStatus === VerifyStatus.REJECTED
              ? SellerT("confirmation-reject-withdraw-account-header")
              : SellerT("confirmation-approve-withdraw-account-header")
          }
          description={
            verifyStatus === VerifyStatus.REJECTED
              ? SellerT("confirmation-reject-withdraw-account-description")
              : SellerT("confirmation-approve-withdraw-account-description")
          }
          close={openConfirmationModal}
          onClose={() => {
            setOpenConfirmationModal(false)
          }}
        />
      ) : null}
      <div className="mb-4 flex flex-row items-center justify-between">
        <div>
          <NavigationHeaderButton
            title={SellerT("withdraw-account-information")}
            onClick={() => {
              navigate(-1)
            }}
          />
          <WithdrawAccountText
            text={SellerT("withdraw-account-updated-at", {
              updatedAt: dayjs(withdrawAccount?.updatedAt ?? "")
                .locale("th")
                .format("D MMM YYYY"),
            })}
          />
        </div>
        <div>
          {withdrawAccount?.verificationStatus ===
            "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_APPROVED" ||
          withdrawAccount?.verificationStatus ===
            "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_REJECTED" ? (
            <VerificationStatus status={withdrawAccount.verificationStatus} />
          ) : (
            <>
              <EuiButton
                color="text"
                className="mr-2 w-[240px]"
                css={css`
                  background-color: ${GREY_BUTTON};
                `}
                onClick={() => {
                  setVerifyStatus(VerifyStatus.REJECTED)
                  setOpenConfirmationModal(true)
                }}
              >
                <EuiText size="s">
                  <p className="mb-0">{SellerT("withdraw-account-reject")}</p>
                </EuiText>
              </EuiButton>
              <EuiButton
                color="primary"
                fill
                className="w-[240px]"
                onClick={() => {
                  setVerifyStatus(VerifyStatus.APPROVED)
                  setOpenConfirmationModal(true)
                }}
              >
                <EuiText size="s">
                  <p className="mb-0">{SellerT("withdraw-account-validate")}</p>
                </EuiText>
              </EuiButton>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-x-8">
        <div className="flex-[3]">
          {withdrawAccount?.companyWithdrawAccount?.bankAccount ? (
            <BankAccountWithdrawAccountCard
              {...withdrawAccount?.companyWithdrawAccount?.bankAccount}
            />
          ) : null}

          {withdrawAccount?.individualWithdrawAccount?.bankAccount ? (
            <BankAccountWithdrawAccountCard
              {...withdrawAccount?.individualWithdrawAccount?.bankAccount}
            />
          ) : null}
          <WithdrawAccountCard section={bankOwnerInformation} />

          {withdrawAccount?.individualWithdrawAccount?.address ? (
            <AddressWithdrawAccountCard
              title={SellerT("identification-address")}
              address={withdrawAccount?.individualWithdrawAccount?.address}
            />
          ) : null}

          {withdrawAccount?.companyWithdrawAccount?.address ? (
            <AddressWithdrawAccountCard
              title={SellerT("identification-address")}
              address={withdrawAccount?.companyWithdrawAccount?.address}
            />
          ) : null}

          {withdrawAccount?.individualWithdrawAccount
            ?.documentReceivedAddress &&
          withdrawAccount?.individualWithdrawAccount?.documentReceivedAddress
            .postCode ? (
            <AddressWithdrawAccountCard
              title={SellerT("document-received-address")}
              address={
                withdrawAccount?.individualWithdrawAccount
                  ?.documentReceivedAddress
              }
            />
          ) : null}

          {withdrawAccount?.companyWithdrawAccount?.documentReceivedAddress &&
          withdrawAccount?.companyWithdrawAccount?.documentReceivedAddress ? (
            <AddressWithdrawAccountCard
              title={SellerT("document-received-address")}
              address={
                withdrawAccount?.companyWithdrawAccount?.documentReceivedAddress
              }
            />
          ) : null}
        </div>
        <div className="max-w-[352px] flex-[1]">
          <div className="mb-4">
            <WithdrawAccountAttachmentCard
              hasBankDocument={
                !!withdrawAccount?.individualWithdrawAccount?.bankAccount
                  ?.bankDocument?.path ||
                !!withdrawAccount?.companyWithdrawAccount?.bankAccount
                  ?.bankDocument?.path
              }
              hasRegistrationCertification={
                !!withdrawAccount?.companyWithdrawAccount
                  ?.registrationCertification?.path
              }
              hasValueAddedTaxCertification={
                !!withdrawAccount?.companyWithdrawAccount
                  ?.valueAddedTaxCertification?.path
              }
              downloadBankDocumentUrl={async () => {
                if (shopId) {
                  if (
                    withdrawAccount?.individualWithdrawAccount?.bankAccount
                      ?.bankDocument?.name
                  ) {
                    await downloadURL(
                      shopId,
                      "DOWNLOAD_DOCUMENT_WITHDRAW_ACCOUNT_TYPE_BANK_ACCOUNT",
                      withdrawAccount?.individualWithdrawAccount?.bankAccount
                        ?.bankDocument?.name,
                    )
                  }

                  if (
                    withdrawAccount?.companyWithdrawAccount?.bankAccount
                      ?.bankDocument?.name
                  ) {
                    await downloadURL(
                      shopId,
                      "DOWNLOAD_DOCUMENT_WITHDRAW_ACCOUNT_TYPE_BANK_ACCOUNT",
                      withdrawAccount?.companyWithdrawAccount?.bankAccount
                        ?.bankDocument?.name,
                    )
                  }
                }
              }}
              downloadRegistrationCertificationUrl={async () => {
                if (shopId) {
                  if (
                    withdrawAccount?.companyWithdrawAccount
                      ?.registrationCertification?.name
                  ) {
                    await downloadURL(
                      shopId,
                      "DOWNLOAD_DOCUMENT_WITHDRAW_ACCOUNT_TYPE_REGISTRATION_CERTIFICATION",
                      withdrawAccount?.companyWithdrawAccount
                        ?.registrationCertification?.name,
                    )
                  }
                }
              }}
              downloadValueAddedTaxCertificationUrl={async () => {
                if (shopId) {
                  if (
                    withdrawAccount?.companyWithdrawAccount
                      ?.valueAddedTaxCertification?.name
                  ) {
                    await downloadURL(
                      shopId,
                      "DOWNLOAD_DOCUMENT_WITHDRAW_ACCOUNT_TYPE_VALUE_ADDED_TAX_CERTIFICATION",
                      withdrawAccount?.companyWithdrawAccount
                        ?.valueAddedTaxCertification?.name,
                    )
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
