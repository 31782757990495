import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin"
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import { ParagraphNode } from "lexical"
import React, { useCallback } from "react"
import { EditorProps, ToolbarPlugins } from "../../types"
import { Footer } from "./component/footer"
import "./editor-font.css"
import "./editor.css"
import { allNodes } from "./nodes"
import { ExtendedParagraphNode } from "./nodes/paragraph"
import AutoEmbedPlugin from "./plugins/auto-embed"
import { InsertImagePlugin } from "./plugins/image/common"
import { InsertLinkPlugin } from "./plugins/link/link"
import { ModifyTabIndentationPlugin } from "./plugins/modify-tab-indentation"
import { ToolbarPlugin } from "./plugins/toolbar"
import { ReInitialEditorState } from "./plugins/update-state"
import { InsertYouTubePlugin } from "./plugins/youtube"
import theme from "./theme/theme"

export const LexicalTextEditor: React.FC<EditorProps> = (props) => {
  if (props.isEditable) {
    if (
      props.toolbarPlugins.includes(ToolbarPlugins.ImageUpload) &&
      !props.getUploadURL
    ) {
      throw new Error("ImageUpload plugin requires getUploadUrl prop")
    }
  }

  const onError = props.onError ? props.onError : (e: Error) => console.error(e)
  let nodes = allNodes

  const isLinkNodeEnabled = props.isEditable
    ? !!props.toolbarPlugins.find((v) => v === ToolbarPlugins.Link)
    : true

  if (!isLinkNodeEnabled) {
    nodes = nodes.filter((v) => {
      if ("name" in v) {
        return v.name !== "LinkNode" && v.name !== "AutoLinkNode"
      }

      return true
    })
  }

  const initialConfig: InitialConfigType = {
    namespace: "mp-editor",
    theme: theme,
    editable: props.isEditable,
    editorState: props.initEditorState,
    nodes: [
      ...nodes,
      ExtendedParagraphNode,
      {
        replace: ParagraphNode,
        with: (node: ParagraphNode) => new ExtendedParagraphNode(node.__key),
      },
    ],
    onError: onError,
  }

  const onChange: Parameters<typeof OnChangePlugin>[0]["onChange"] =
    useCallback(
      (editorState, editor, tags) => {
        if (props.isEditable) {
          props.onEditorStateChange(editorState)
        }
      },
      [props],
    )

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div
        className={`mpe-editor-shell ${
          props.isEditable ? "mpe-is-editable" : ""
        }`}
      >
        {props.isEditable && (
          <ToolbarPlugin toolbarPlugins={props.toolbarPlugins} />
        )}
        <div
          className={`mpe-editor-container ${
            props.isEditable ? "" : "mpe-full-height"
          }`}
        >
          <RichTextPlugin
            contentEditable={
              <div
                id="mpe-editor"
                className={`mpe-editor ${
                  props.isEditable ? "mpe-is-editable" : ""
                }`}
              >
                <ContentEditable className="mpe-no-outline mpe-base-font-size" />
              </div>
            }
            placeholder={
              props.isEditable ? (
                <div className="mpe-editor-placeholder">
                  {props.placeholder || "Write something..."}
                </div>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <TabIndentationPlugin />
          <ModifyTabIndentationPlugin />
          {props.isEditable ? (
            <>
              {isLinkNodeEnabled && (
                <InsertLinkPlugin
                  allowedLinkPrefixes={props.allowedLinkPrefixes}
                />
              )}
              <InsertImagePlugin
                getUploadURL={props.getUploadURL}
                maxUploadImage={props.maxUploadImage}
                onError={props.onError}
              />
              <InsertYouTubePlugin />
              {isLinkNodeEnabled && <AutoEmbedPlugin />}
              <OnChangePlugin
                onChange={onChange}
                ignoreSelectionChange
                ignoreHistoryMergeTagChange
              />
              <HistoryPlugin />
            </>
          ) : (
            <ReInitialEditorState state={props.initEditorState} />
          )}
          <ListPlugin />
          <CheckListPlugin />

          {props.isEditable && <Footer maxUploadImage={props.maxUploadImage} />}
        </div>
      </div>
    </LexicalComposer>
  )
}
