import { PackageType, TotalTopupPackage } from "@app/model"
import { PaymentChannel } from "@app/model/payment"
import { EuiPanel, EuiText, EuiTitle } from "@elastic/eui"
import { css } from "@emotion/react"
import { theme } from "@reeeed-mp/ui-common"
import classNames from "classnames"
import { observer } from "mobx-react"
import { HTMLAttributes } from "react"
import { useTranslation } from "react-i18next"

type TopupPackagesByTypeProps = HTMLAttributes<HTMLDivElement> & {
  type: PackageType
  packages: TotalTopupPackage[]
  selectedPaymentChannel: PaymentChannel | null
  onPaymentChannelSelect: (p: PaymentChannel) => void
}

export const TopupPackagesByType: React.FC<TopupPackagesByTypeProps> = observer(
  ({
    type,
    packages,
    selectedPaymentChannel,
    onPaymentChannelSelect,
    ...restProps
  }) => {
    const { t } = useTranslation()

    const topupPackagesByType = {
      ul: css({
        "&.list-none": css({
          marginLeft: 0,
        }),
      }),
      pannel: css({
        "&.active": css({
          backgroundColor: theme.colors.LIGHT.exLightPurple,
          borderColor: theme.colors.LIGHT.primaryPurple,
        }),
      }),
      package: css({ fontSize: 12.25 }),
    }

    return (
      <div className="flex flex-col gap-2" {...restProps}>
        <EuiTitle size="xs">
          <p>{t(`topup-package-type.${type}`)}</p>
        </EuiTitle>
        <ul
          className="flex list-none flex-col gap-2"
          css={topupPackagesByType.ul}
        >
          {packages.map((p) => (
            <li key={p.type} className="m-0">
              <EuiPanel
                className={classNames("cursor-pointer", {
                  active: p.paymentType === selectedPaymentChannel,
                })}
                onClick={() => onPaymentChannelSelect(p.paymentType)}
                hasBorder
                css={topupPackagesByType.pannel}
              >
                <div className="flex content-center justify-between">
                  <EuiTitle size="xxs">
                    <p>{t(`payment-channel.${p.paymentType}`)}</p>
                  </EuiTitle>
                  <EuiText textAlign="right">
                    <p css={topupPackagesByType.package} className="m-0">
                      {p.total} packages
                    </p>
                  </EuiText>
                </div>
              </EuiPanel>
            </li>
          ))}
        </ul>
      </div>
    )
  },
)
