import { newApi } from "@app/api"
import { publicConfig } from "@app/config"
import { newStreamFetcher } from "@app/lib/api"
import {
  paths as authPaths,
  definitions,
} from "@app/vendor/admin-auth-specs/admin_auth_service"
import { paths as orderPaths } from "@app/vendor/order-specs/order_service"
import { paths as paymentPaths } from "@app/vendor/payment-specs/payment_service"
import { paths as shopPaths } from "@app/vendor/shop-specs/shop_service"
import { paths as webBFFPaths } from "@app/vendor/web-bff-specs/web_bff_service"
import { paths as webUtilityPaths } from "@app/vendor/web-utility-specs/web_utility_service"
import { useRef, useState } from "react"
import { useAuth } from "./store"

export const useApi = () => {
  const authStore = useAuth()
  return newApi(authStore)
}

export const useStreamApi = () => {
  const auth = useAuth()
  const tokenGetter = async () => auth.accessToken
  return {
    order: newStreamFetcher<orderPaths>(
      publicConfig.orderEndpoint,
      tokenGetter,
    ),
    payment: newStreamFetcher<paymentPaths>(
      publicConfig.paymentEndpoint,
      tokenGetter,
    ),
    webBFF: newStreamFetcher<webBFFPaths>(
      publicConfig.webBffEndpoint,
      tokenGetter,
    ),
    auth: newStreamFetcher<authPaths>(
      publicConfig.authAdminEndpoint,
      tokenGetter,
    ),
    webUtility: newStreamFetcher<webUtilityPaths>(
      publicConfig.webUtilityEndpoint,
      tokenGetter,
    ),
    shop: newStreamFetcher<shopPaths>(publicConfig.shopEndpoint, tokenGetter),
  }
}

const doNothing = () => {}

export const useDownloadFile = <
  T extends (...args: any[]) => Promise<
    ReadableStream<{
      result?: {
        reportProgress?: definitions["foundation.report.ReportProgressResponse"]
      }
      error?: definitions["google.rpc.Status"]
    }>
  >,
>(
  fn: T,
  opts?: { autoDownload: boolean },
) => {
  const [loadingPercentage, setLoadingPercentage] = useState<null | number>(
    null,
  )
  const [error, setError] = useState<Error | null>(null)
  const [downloadURL, setDownloadURL] = useState<string | null>(null)
  const cancelDownloadRef = useRef(doNothing)

  const doDownloadFile = async (...args: Parameters<T>) => {
    cancelDownloadRef.current()
    setLoadingPercentage(0)
    setDownloadURL(null)
    setError(null)
    try {
      const reader = await fn(...args).then((r) => r.getReader())
      cancelDownloadRef.current = () => {
        reader.cancel()
      }

      /* eslint-disable no-constant-condition */
      while (true) {
        const data = await reader.read()
        if (data.done) {
          return
        }
        if (data.value.error) {
          console.error(data.value.error.message)
        } else {
          setLoadingPercentage(
            data.value.result?.reportProgress?.percentageRatio || 0,
          )
          if (data.value.result?.reportProgress?.downloadUrl) {
            const downloadPath = data.value.result?.reportProgress?.downloadUrl
            setDownloadURL(downloadPath)
            if (opts?.autoDownload) {
              const a = document.createElement("a")
              a.href = downloadPath
              a.click()
            }
            break
          }
        }
      }
    } catch (err: any) {
      let e: Error

      if (err instanceof Error) {
        e = err
      } else {
        e = new Error(err)
      }
      setError(e)
    } finally {
      setLoadingPercentage(null)
      cancelDownloadRef.current = doNothing
    }
  }

  return {
    doDownloadFile,
    cancelDownload: cancelDownloadRef.current,
    downloadURL,
    loadingPercentage,
    error,
  }
}
