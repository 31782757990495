import { publicConfig } from "@app/config"
import { useWrapAsyncWithLoading } from "@app/lib/async"
import { EuiButtonIcon, EuiHorizontalRule } from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { ContainerShopBankAccountCard } from "./detail/shop-bank-account-card"
import { ContainerShopRevenueCard } from "./detail/shop-revenue-card"
import { TopProductCard } from "./detail/shop-top-product-card"
import {
  useEnableCancelOrder,
  useEnableCodeRedemption,
  useEnableEVoucher,
  useGetShop,
  useSetActiveInternationalShipping,
  useToggleTaxInvoiceRequest,
} from "./hook"
import {
  ContainerShopInfoSettingCard,
  ToggleSwitchList,
} from "./detail/shop-setting-card"
import React from "react"
import { ConfirmActionModal } from "@app/component/modal-confirm-action"
import { useAlertDefaultErr } from "@app/hook/error"
import { useModalState } from "./hook/modal"

export const SellerManagementSetting: React.FC = () => {
  const { shopId } = useParams()
  const setActiveInternationalShipping = useSetActiveInternationalShipping()
  const { enableEVoucher } = useEnableEVoucher()
  const { enableCancelOrder } = useEnableCancelOrder()
  const { enable: enableTaxInvoiceRequest, disable: disableTaxInvoiceRequest } =
    useToggleTaxInvoiceRequest()
  const { enableCodeRedemption } = useEnableCodeRedemption()

  const alertDefaultError = useAlertDefaultErr()

  const { wrap } = useWrapAsyncWithLoading()
  const { t } = useTranslation()
  const { data, refetch } = useGetShop(shopId ?? "")
  const isEnableTaxInvoiceRequest = data?.shop?.enabledTaxInvoiceRequest

  const { modalState, openModal, closeModal } = useModalState()

  const onActiveInternationalShipping = wrap(async (isActive: boolean) => {
    if (!data?.shop) return

    openModal(
      t(
        "shop-information.toggle-international-shipping.confirmation-modal.title",
        {
          action: isActive
            ? t("common.actions.enable")
            : t("common.actions.disable"),
        },
      ),
      t(
        "shop-information.toggle-international-shipping.confirmation-modal.description",
        {
          action: isActive
            ? t("common.actions.enable")
            : t("common.actions.disable"),
        },
      ),
      async () => {
        try {
          await setActiveInternationalShipping(shopId || "", isActive)
          refetch()
        } catch (e) {
          alertDefaultError(e)
        }
      },
    )
  })

  const onActiveEVoucerFeature = () => {
    openModal(
      t("shop-information.toggle-e-voucher.enable-confirmation-modal.title"),
      t(
        "shop-information.toggle-e-voucher.enable-confirmation-modal.description",
      ),
      async () => {
        try {
          await enableEVoucher(shopId || "")
          refetch()
        } catch (e) {
          alertDefaultError(e)
        }
      },
    )
  }

  const onActiveCancelOrderFeature = wrap(async (status: boolean) => {
    openModal(
      t("shop-information.toggle-cancel-order.confirmation-modal.title", {
        action: status
          ? t("common.actions.enable")
          : t("common.actions.disable"),
      }),
      t("shop-information.toggle-cancel-order.confirmation-modal.description", {
        action: status
          ? t("common.actions.enable")
          : t("common.actions.disable"),
      }),
      async () => {
        try {
          await enableCancelOrder(shopId || "", status)
          refetch()
        } catch (e) {
          alertDefaultError(e)
        }
      },
    )
  })

  const onToggleTaxInvoiceRequest = () => {
    openModal(
      isEnableTaxInvoiceRequest
        ? t(
            "shop-information.toggle-tax-invoice-request.disable-confirmation-modal.title",
          )
        : t(
            "shop-information.toggle-tax-invoice-request.enable-confirmation-modal.title",
          ),
      isEnableTaxInvoiceRequest
        ? t(
            "shop-information.toggle-tax-invoice-request.disable-confirmation-modal.description",
          )
        : t(
            "shop-information.toggle-tax-invoice-request.enable-confirmation-modal.description",
          ),
      async () => {
        try {
          if (isEnableTaxInvoiceRequest) {
            await disableTaxInvoiceRequest(shopId || "")
          } else {
            await enableTaxInvoiceRequest(shopId || "")
          }
        } catch (e) {
          alertDefaultError(e)
        }
      },
    )
  }

  const onActiveCodeRedemptionFeature = wrap(async (status: boolean) => {
    openModal(
      t("shop-information.toggle-code-redemption.confirmation-modal.title", {
        action: status
          ? t("common.actions.enable")
          : t("common.actions.disable"),
      }),
      t(
        "shop-information.toggle-code-redemption.confirmation-modal.description",
        {
          action: status
            ? t("common.actions.enable")
            : t("common.actions.disable"),
        },
      ),
      async () => {
        try {
          await enableCodeRedemption(shopId || "", status)
          refetch()
        } catch (e) {
          alertDefaultError(e)
        }
      },
    )
  })

  const navigate = useNavigate()
  return (
    <>
      <div className="flex h-full w-full flex-col space-y-12">
        <PageTitleWithBackButton
          title={t("shop-information.shop-setting")}
          onBackButtonClickNavigate={() => {
            navigate(-1)
          }}
        />
        <ContainerShopInfoSettingCard />
        <EuiHorizontalRule margin="none" />

        <ToggleSwitchList
          toggles={[
            {
              title: t("shop-information.toggle-international-shipping.label"),
              onToggle: onActiveInternationalShipping,
              enabled: data?.shop?.isActiveInternationalShipping || false,
              disabled: false,
              hidden: !publicConfig.isFeatureInternationalShipping,
            },
            {
              title: t("shop-information.toggle-e-voucher.label"),
              onToggle: onActiveEVoucerFeature,
              enabled: data?.shop?.enabledEVoucher || false,
              disabled: data?.shop?.enabledEVoucher || false,
              hidden: !publicConfig.eVoucher,
            },
            {
              title: t("shop-information.toggle-cancel-order.label"),
              onToggle: onActiveCancelOrderFeature,
              enabled: data?.shop?.enabledCancelOrder || false,
              disabled: false,
              hidden: !publicConfig.isFeatureCancelOrder,
            },
            {
              title: t("shop-information.toggle-tax-invoice-request.label"),
              onToggle: onToggleTaxInvoiceRequest,
              enabled: isEnableTaxInvoiceRequest || false,
              disabled: false,
              hidden: false,
            },
            {
              title: t("shop-information.toggle-code-redemption.label"),
              onToggle: onActiveCodeRedemptionFeature,
              enabled: data?.shop?.enabledShopCouponRedemptionCampaign || false,
              disabled: false,
              hidden: !publicConfig.eBookAudio,
            },
          ]}
        />

        <EuiHorizontalRule margin="none" />

        <div className="grid grid-cols-3 gap-4">
          <TopProductCard />
          <ContainerShopRevenueCard editable />
          <ContainerShopBankAccountCard showAccountApprovalButton />
        </div>
      </div>
      {modalState.open && (
        <ConfirmActionModal
          onConfirm={() => {
            modalState.onConfirm()
            closeModal()
          }}
          onClose={closeModal}
          title={modalState.title}
          description={modalState.description}
        />
      )}
    </>
  )
}

const PageTitleWithBackButton: React.FC<{
  title: string
  onBackButtonClickNavigate?: () => void
  width?: number
}> = ({ title, onBackButtonClickNavigate, width }) => {
  const navigate = useNavigate()

  return (
    <div className="flex h-10 items-center justify-start space-x-4">
      <EuiButtonIcon
        iconType="arrowLeft"
        color="text"
        className="scale-125"
        onClick={() => {
          if (onBackButtonClickNavigate) {
            onBackButtonClickNavigate()
          } else {
            navigate(-1)
          }
        }}
      />
      <div
        className={`overflow-hidden overflow-ellipsis text-xl font-bold w-[${
          width || 400
        }px] h-fit`}
      >
        {title}
      </div>
    </div>
  )
}
