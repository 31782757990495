import { NavigationHeaderButton } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { EuiButton, EuiFieldText } from "@elastic/eui"
import { UilPlus, UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useApi } from "@app/hook/api"
import dayjs from "dayjs"
import { useGetWriterBanner, WriterBannerItem } from "./hook/banner"
import { ReOrderSelect, TableAction } from "../components/banner-list-action"
import { useAlertDefaultErr } from "@app/hook/error"
import { homepageManagementPath, writerBannerPath } from "../hook/path"
import { ImagePreview } from "../components/banner-common"
import { useDebounce } from "use-debounce"

export const WriterBannerListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()
  const { webUtility } = useApi()
  const ref = useRef() as TableRefImperative
  const alertDefaultError = useAlertDefaultErr()
  const { textSearch, setTextSearch, getWriterBanner } = useGetWriterBanner(
    params.sectionKey!,
  )
  const [debouncedTextSearch] = useDebounce(textSearch, 500)

  const reorderBannerApi = webUtility(
    "/admin/section/{sectionKey}/writer-banner/{bannerKey}/order",
  )
    .method("post")
    .create()

  const deleteSmallBannerApi = webUtility("/admin/writer-banner/{bannerKey}")
    .method("delete")
    .create({
      sectionKey: true,
    })

  const deleteBanner = async (bannerKey: string) => {
    try {
      await deleteSmallBannerApi({
        sectionKey: params.sectionKey,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const editBanner = (bannerKey: string) => {
    navigate(writerBannerPath(params.sectionKey!, bannerKey))
  }

  const reorderBanner = async (bannerKey: string, order: string) => {
    try {
      await reorderBannerApi({
        order,
        sectionKey: params.sectionKey!,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const onGetItems: OnGetItems<WriterBannerItem> = async (props) => {
    const data = await getWriterBanner({
      filter: props.filter!,
      opts: props.opts,
    })

    return {
      items:
        data?.banners?.map((banner) => {
          return {
            order: banner?.order!,
            id: banner?.id!,
            name: banner?.authorName!,
            cover: banner?.cover!,
            link: banner?.link!,
            bannerKey: banner?.key!,
            numberOfWorks: banner?.numberOfWorks!,
            createdAt: dayjs(banner?.createdAt!).toDate(),
            count: String(data.count || 0),
          }
        }) || [],

      count: data.count || 0,
    }
  }

  const columns = [
    {
      name: t("home-management.banner.list.table.order-col"),
      render: (item: WriterBannerItem) => {
        return (
          <ReOrderSelect
            bannerKey={item.bannerKey!}
            sectionKey={params.sectionKey!}
            count={parseInt(item.count, 10)}
            order={item.order!}
            onReorder={reorderBanner}
          />
        )
      },
      width: "100",
    },
    {
      field: "cover",
      name: t("home-management.banner.list.table.cover-col"),
      render: (cover: WriterBannerItem["cover"]) => {
        return <ImagePreview src={cover} alt="cover" className="max-h-20" />
      },
    },
    {
      field: "name",
      name: t("home-management.banner.list.table.writer-col"),
    },
    {
      field: "createdAt",
      name: t("home-management.banner.list.table.create-at-col"),
      render: (createdAt: WriterBannerItem["createdAt"]) => {
        return t("common.datetime", {
          value: createdAt ?? "",
        })
      },
    },
    {
      width: "50",
      actions: [
        {
          render: (item: WriterBannerItem) => {
            return (
              <TableAction
                onDelete={deleteBanner}
                onEdit={editBanner}
                sectionKey={params.sectionKey!}
                {...item}
              />
            )
          },
        },
      ],
    },
  ]

  return (
    <div className="flex max-w-screen-xl flex-col gap-y-6">
      <div className="flex w-full items-center justify-between">
        <NavigationHeaderButton
          title={t("home-management.writer.list")}
          onClick={() => {
            navigate(homepageManagementPath())
          }}
        />
        <EuiButton
          fill
          iconType={UilPlus}
          onClick={() => {
            navigate(writerBannerPath(params.sectionKey!, "create"))
          }}
        >
          {t("home-management.writer.add-writer")}
        </EuiButton>
      </div>
      <div className="flex justify-end">
        <EuiFieldText
          icon={<UilSearch />}
          placeholder={t("home-management.banner.list.filter.text-search")}
          onChange={(e) => {
            setTextSearch(e.target.value)
          }}
        />
      </div>

      <Table<WriterBannerItem>
        ref={ref}
        columns={columns}
        sorter={{
          field: "order",
          direction: "desc",
        }}
        swrKey={`/writer-banner-list-${params.sectionId}`}
        onGetItems={onGetItems}
        isCanSelect={false}
        filter={{
          textSearch: debouncedTextSearch,
        }}
      />
    </div>
  )
}
