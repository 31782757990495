import { publicConfig } from "@app/config"

const baseHomeManagementPath = publicConfig.enableFlexibleHomepageManagement
  ? "/home-management"
  : "/home-management-2"

export const homepageManagementPath = () => {
  return baseHomeManagementPath
}

export const mainMenuPath = (key: string) => {
  return `${baseHomeManagementPath}/main-menu/${key}`
}

export const highlightMenuPath = (key: string) => {
  return `${baseHomeManagementPath}/highlight-menu/${key}`
}

export const smallBannerPath = (sectionKey: string, bannerKey?: string) => {
  return `${baseHomeManagementPath}/small-banner/${sectionKey}${bannerKey ? `/editor/${bannerKey}` : ""}`
}

export const fixedMediumBannerPath = (
  sectionKey: string,
  bannerKey?: string,
) => {
  return `${baseHomeManagementPath}/fixed-medium-banner/${sectionKey}${bannerKey ? `/editor/${bannerKey}` : ""}`
}

export const overflowMediumBannerPath = (
  sectionKey: string,
  bannerKey?: string,
) => {
  return `${baseHomeManagementPath}/overflow-medium-banner/${sectionKey}${bannerKey ? `/editor/${bannerKey}` : ""}`
}

export const writerBannerPath = (sectionKey: string, bannerKey?: string) => {
  return `${baseHomeManagementPath}/writer-banner/${sectionKey}${bannerKey ? `/editor/${bannerKey}` : ""}`
}

export const heroBannerPath = (bannerKey?: string) => {
  return `${baseHomeManagementPath}/hero-banner/${bannerKey || ""}`
}
