import {
  $isAutoLinkNode,
  $isLinkNode,
  LinkNode,
  TOGGLE_LINK_COMMAND,
} from "@lexical/link"
import {
  LinkMatcher,
  createLinkMatcherWithRegExp,
} from "@lexical/react/LexicalAutoLinkPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { LinkPlugin as LexicalLinkPlugin } from "@lexical/react/LexicalLinkPlugin"
import { $getSelection, $isRangeSelection } from "lexical"
import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  InternalRuleViolation,
  getErrorTranslation,
} from "../../constant/error"
import { Button } from "../../ui/button"
import { TextInput } from "../../ui/text-input"
import { useOnClickOutside } from "../../utils/click-outside"
import { getSelectedNode } from "../../utils/node"
import { urlRegex } from "../../utils/url"
import "./link.css"
import { CustomAutoLinkPlugin } from "../../lexical-react/lexical-auto-link-plugin"

const newLinkMatcher = (allowedLinkPrefixes?: Array<string>): LinkMatcher => {
  return (link: string) => {
    const regexMatcher = createLinkMatcherWithRegExp(urlRegex)
    const result = regexMatcher(link)
    if (!result) {
      return null
    }

    if (allowedLinkPrefixes !== undefined) {
      let valid = false

      for (const prefix of allowedLinkPrefixes) {
        if (result.url.startsWith(prefix)) {
          valid = true
          break
        }
      }

      if (!valid) {
        return null
      }
    }

    return {
      ...result,
      attributes: { target: "_blank" },
    }
  }
}

export const InsertLinkPlugin: React.FC<{
  allowedLinkPrefixes?: Array<string>
}> = (props) => {
  const validateLink = useCallback(newLinkMatcher(props.allowedLinkPrefixes), [
    props.allowedLinkPrefixes,
  ])

  return (
    <>
      <LexicalLinkPlugin />
      <CustomAutoLinkPlugin matchers={[validateLink]} />
      <ManualLinkEditorPlugin allowedLinkPrefixes={props.allowedLinkPrefixes} />
    </>
  )
}

export const ToolbarInsertLink: React.FC = () => {
  const [editor] = useLexicalComposerContext()
  const [isLink, setIsLink] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar()
      })
    })
  }, [updateToolbar, editor])

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://")
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  return (
    <button
      onClick={insertLink}
      className={"mpe-toolbar-item mpe-spaced " + (isLink ? "mpe-active" : "")}
      type="button"
    >
      <i className="mpe-format mpe-link" />
    </button>
  )
}

const ManualLinkEditorPlugin: React.FC<{
  allowedLinkPrefixes?: Array<string>
}> = (props) => {
  const [editor] = useLexicalComposerContext()
  const [anchorElem, setAnchorElem] = useState<HTMLElement>(undefined)
  const [link, setLink] = useState<string>(undefined)
  const ref = useRef()
  const [saveButtonTxt, setSaveButtonTxt] = useState("Save")
  const [error, setError] = useState<InternalRuleViolation | undefined>(
    undefined,
  )
  const validateLink = useCallback(newLinkMatcher(props.allowedLinkPrefixes), [
    props.allowedLinkPrefixes,
  ])

  useOnClickOutside(ref, () => {
    setAnchorElem(undefined)
    setLink(undefined)

    editor.update(() => {
      const result = validateLink(link)
      if (!result) {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection).getParent()
          if ($isLinkNode(node) && $isAutoLinkNode(node) === false) {
            const linkNode = node as LinkNode
            const currentURL = validateLink(linkNode.getURL())
            if (!currentURL) {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
            }
          }
        }
      }
    })
  })

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("scroll", () => {
        setAnchorElem(undefined)
      })

      const editorEle = document.getElementById("mpe-editor")
      if (editorEle) {
        editorEle.addEventListener("scroll", () => {
          setAnchorElem(undefined)
        })
      }
    }

    return () => {
      if (typeof document !== "undefined") {
        document.addEventListener("scroll", () => {
          setAnchorElem(undefined)
        })

        const editorEle = document.getElementById("mpe-editor")
        if (editorEle) {
          editorEle.removeEventListener("scroll", () => {})
        }
      }
    }
  }, [setAnchorElem])

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection).getParent()
      if ($isLinkNode(node) && $isAutoLinkNode(node) === false) {
        const linkNode = node as LinkNode
        const ele = editor.getElementByKey(linkNode.getKey())
        setAnchorElem(ele)
        setLink(linkNode.getURL())
      } else {
        setAnchorElem(undefined)
      }
    } else {
      setAnchorElem(undefined)
    }
  }, [editor, setAnchorElem, setLink])

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar()
      })
    })
  }, [updateToolbar, editor])

  const onSave = useCallback(() => {
    if (error) {
      return
    }

    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection).getParent()

        if ($isLinkNode(node) && $isAutoLinkNode(node) === false) {
          const linkNode = node as LinkNode
          linkNode.setURL(link)
          linkNode.setTarget("_blank")
        }
      }
    })

    setSaveButtonTxt("Saved")
    setTimeout(() => {
      setSaveButtonTxt("Save")
    }, 2000)
  }, [editor, link, setAnchorElem, setSaveButtonTxt, error])

  const onRemoveLink = useCallback(() => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
  }, [editor])

  const validateAndSetLink = useCallback(
    (newLink: string) => {
      setError(undefined)
      if (!validateLink(newLink)) {
        setError(InternalRuleViolation.LinkPrefixNotAllowed)
      }

      setLink(newLink)
    },
    [validateLink, setLink, setError],
  )

  return (
    <>
      {anchorElem && (
        <div
          ref={ref}
          className="mpe-link-editor"
          style={{
            top:
              anchorElem.getBoundingClientRect().top +
              anchorElem.getBoundingClientRect().height +
              5,
            left: anchorElem.getBoundingClientRect().left,
          }}
        >
          <TextInput label="" onChange={validateAndSetLink} value={link} />
          {error && (
            <span className="mpe-error">{getErrorTranslation(error)}</span>
          )}
          <div style={{ display: "flex", justifyContent: "right", gap: "8px" }}>
            <Button onClick={onRemoveLink}>Remove Link</Button>
            <Button onClick={onSave} disabled={!validateLink(link)}>
              {saveButtonTxt}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
