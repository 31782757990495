import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import * as Yup from "yup"
import { FileForm } from "../form"

export type SecondaryBannerForm = {
  id?: string
  cover?: string
  link?: string
  alt?: string
  fileInput?: FileForm["fileInput"]
  downloadUrl?: string
  image?: string
}

export type SecondaryBannerInput =
  definitions["webutility.admin.v1.CustomizedSecondaryBanner"] & {
    downloadUrl?: string
  }

export const SecondaryBannerFormValidator = Yup.object({
  link: Yup.string().required(),
  downloadUrl: Yup.string().required(),
})
