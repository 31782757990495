import { useStore } from "@app/hook/store"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useSWR from "swr"
import { useGetShop, useShop } from "../hook"

export const ContainerShopOrderCard: React.FC = () => {
  const { getTotalOrder } = useShop()
  const { shopId } = useParams()
  const { notificationStore } = useStore()
  const { data, isLoading } = useGetShop(shopId ?? "")
  const { t } = useTranslation()
  const {
    data: totalOrder,
    error,
    isLoading: isLoadingTotalOrder,
  } = useSWR(["/shop-order", shopId], async ([, shopId]) => {
    if (shopId) {
      return await getTotalOrder(shopId)
    }
    return
  })

  useEffect(() => {
    if (error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: error.message,
      })
    }
  }, [error, notificationStore])
  return (
    <div className="w-full rounded-xl border border-solid border-[#D3DAE6] p-6">
      <div className="*:flex-grow flex h-full w-full items-center justify-between">
        <div className="flex w-full flex-col items-start space-y-2">
          <div>{t("shop-information.product-amount")}</div>
          {!isLoading && (
            <div className="text-l font-bold">
              {t("shop-information.product-number", {
                amount: data?.shop?.totalNumberOfProduct || 0,
              })}
            </div>
          )}
        </div>
        <div className="flex w-full flex-col items-start space-y-2">
          <div>{t("shop-information.order-amount")}</div>
          {!isLoadingTotalOrder ? (
            <div className="text-l font-bold">
              {t("shop-information.order-number", {
                amount: totalOrder?.numberOfOrder || 0,
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
