import { useStore } from "@app/hook/store"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import {
  authorToInput,
  bannerInputOutTransform,
  objectsWithIDAndCoverToInput,
  objectsWithIDAndImageToInput,
} from "../helper"
import { useStoreHomeManagementApi } from "../hook/homepage"

type HomeManagementContextProps = {
  countHightlightProduct: {
    highlightProduct1Section: number
    highlightProduct2Section: number
    highlightProduct3Section: number
  }
  homepage: definitions["webutility.admin.v1.CustomizedHomepage"] | undefined
  ready: boolean
  initError: null | string
  edited: boolean
  loading: boolean
  submit: () => void
  setUpdated: (input: boolean) => void
  update: <
    K extends keyof definitions["webutility.admin.v1.CustomizedHomepage"],
  >(
    field: K,
    data: definitions["webutility.admin.v1.CustomizedHomepage"][K],
  ) => void
  reset: () => void
}

const HomeManagementContext = createContext<HomeManagementContextProps>({
  countHightlightProduct: {
    highlightProduct1Section: 0,
    highlightProduct2Section: 0,
    highlightProduct3Section: 0,
  },
  homepage: undefined,
  update: () => {},
  ready: false,
  loading: false,
  initError: null,
  edited: false,
  submit: () => {},
  setUpdated: () => {},
  reset: () => {},
})

export const HomeManagementProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { store, get } = useStoreHomeManagementApi()
  const [homepage, setHomepage] = useState<
    definitions["webutility.admin.v1.GetCustomizedHomepageResponse"] | undefined
  >()

  const [highlightProductCount1, setHighlightProductCount1] = useState(0)
  const [highlightProductCount2, setHighlightProductCount2] = useState(0)
  const [highlightProductCount3, setHighlightProductCount3] = useState(0)

  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const [initError, setInitError] = useState<null | string>(null)
  const [edited, setEdited] = useState(false)
  const { t } = useTranslation()

  const { notificationStore } = useStore()

  const onSubmit = async () => {
    if (!ready) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t("homepage-management.error.not-ready"),
      })
      return
    }

    setLoading(true)
    const subBanner1 = homepage?.homepage?.highlightBannerSection?.subBanner1
      ? bannerInputOutTransform(
          homepage?.homepage?.highlightBannerSection?.subBanner1,
        )
      : undefined

    const subBanner2 = homepage?.homepage?.highlightBannerSection?.subBanner2
      ? bannerInputOutTransform(
          homepage?.homepage?.highlightBannerSection?.subBanner2,
        )
      : undefined

    const subBanner3 = homepage?.homepage?.highlightBannerSection?.subBanner3
      ? bannerInputOutTransform(
          homepage?.homepage?.highlightBannerSection?.subBanner3,
        )
      : undefined

    const banners = objectsWithIDAndImageToInput(
      homepage?.homepage?.highlightBannerSection?.banners ?? [],
    )
    const categories = objectsWithIDAndImageToInput(
      homepage?.homepage?.categorySection?.categories ?? [],
    )
    const authors = authorToInput(
      homepage?.homepage?.popularAuthorSection?.authors ?? [],
    )
    const shops = objectsWithIDAndImageToInput(
      homepage?.homepage?.recommendShopSection?.shops ?? [],
    )

    const squareBanner = objectsWithIDAndCoverToInput(
      homepage?.homepage?.squareBannerSection ?? [],
    )

    const secondaryBanner = objectsWithIDAndCoverToInput(
      homepage?.homepage?.secondaryBannerSection ?? [],
    )

    const newArrivalSection = homepage?.homepage?.newArrivalSection
    const newArrivalChapterSection =
      homepage?.homepage?.newArrivalChapterSection
    const popularSection = homepage?.homepage?.popularSection
    const recommendProductSection = homepage?.homepage?.recommendProductSection
    const popularNovelChapterSection =
      homepage?.homepage?.popularNovelChapterSection

    const highlightProduct1Section =
      homepage?.homepage?.highlightProduct1Section
    const highlightProduct2Section =
      homepage?.homepage?.highlightProduct2Section
    const highlightProduct3Section =
      homepage?.homepage?.highlightProduct3Section

    try {
      await store({
        ...homepage,
        homepage: {
          highlightBannerSection: {
            ...(subBanner1 ? { subBanner1 } : {}),
            ...(subBanner2 ? { subBanner2 } : {}),
            ...(subBanner3 ? { subBanner3 } : {}),
            banners: [...(banners ?? [])],
          },
          newArrivalSection,
          newArrivalChapterSection,
          popularSection,
          recommendProductSection,
          popularNovelChapterSection,

          categorySection: {
            ...(categories ? { categories } : {}),
          },
          squareBannerSection: [...(squareBanner ?? [])],
          secondaryBannerSection: [...(secondaryBanner ?? [])],
          recommendShopSection: {
            ...(shops ? { shops } : {}),
          },
          popularAuthorSection: {
            ...(authors ? { authors } : {}),
          },
          highlightProduct1Section: {
            enabled: highlightProduct1Section?.enabled,
            products: highlightProduct1Section?.products || [],
            name: highlightProduct1Section?.name || "",
          },
          highlightProduct2Section: {
            enabled: highlightProduct2Section?.enabled,
            products: highlightProduct2Section?.products || [],
            name: highlightProduct2Section?.name || "",
          },
          highlightProduct3Section: {
            enabled: highlightProduct3Section?.enabled,
            products: highlightProduct3Section?.products || [],
            name: highlightProduct3Section?.name || "",
          },
        },
      })
      notificationStore.add({
        color: "success",
        title: "Success",
      })
      setEdited(false)
      const result =
        (await get()) as definitions["webutility.admin.v1.GetCustomizedHomepageResponse"]
      setHomepage(result)
      setHighlightProductCount1(
        parseInt(result?.countHightlightProduct1 ?? "0"),
      )
      setHighlightProductCount2(
        parseInt(result?.countHightlightProduct2 ?? "0"),
      )
      setHighlightProductCount3(
        parseInt(result?.countHightlightProduct3 ?? "0"),
      )
    } catch (err: any) {
      if (err && err.message) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: err.message,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    get()
      .then((d) => {
        const data =
          d as definitions["webutility.admin.v1.GetCustomizedHomepageResponse"]

        setHomepage(data)
        setHighlightProductCount1(
          parseInt(data?.countHightlightProduct1 ?? "0"),
        )
        setHighlightProductCount2(
          parseInt(data?.countHightlightProduct2 ?? "0"),
        )
        setHighlightProductCount3(
          parseInt(data?.countHightlightProduct3 ?? "0"),
        )

        setReady(true)
      })
      .catch((err: Error) => {
        setInitError(err.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomeManagementContext.Provider
      value={{
        homepage: homepage?.homepage,
        countHightlightProduct: {
          highlightProduct1Section: highlightProductCount1,
          highlightProduct2Section: highlightProductCount2,
          highlightProduct3Section: highlightProductCount3,
        },
        loading,
        ready,
        initError,
        reset: async () => {
          setEdited(false)
          const result = await get()
          setHomepage(
            result as definitions["webutility.admin.v1.GetCustomizedHomepageResponse"],
          )
        },
        edited,
        setUpdated: (input) => {
          setEdited(input)
        },
        submit: () => {
          onSubmit()
        },
        update: (key, input) => {
          if (
            input ||
            key !== "newArrivalChapterSection" ||
            key !== "newArrivalSection" ||
            key !== "popularSection" ||
            key !== "recommendProductSection" ||
            key !== "popularNovelChapterSection"
          ) {
            setEdited(true)
            setHomepage({
              homepage: {
                ...homepage?.homepage,
                [key]: input,
              },
            })
          }
        },
      }}
    >
      {children}
    </HomeManagementContext.Provider>
  )
}

export const useHomeManagementStore = () => {
  const homeManagementStore = useContext(HomeManagementContext)
  if (!homeManagementStore) {
    throw new Error("component must be wrap with homeManagementStore")
  }
  return homeManagementStore
}
