import { useStreamApi } from "@app/hook/api"
import { useDownloadReport } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { LIGHTEST_GREY } from "@app/utils/color"
import { definitions } from "@app/vendor/order-specs/order_service"
import {
  EuiAccordion,
  EuiBasicTable,
  EuiButton,
  EuiPagination,
  EuiTableFieldDataColumnType,
  EuiText,
} from "@elastic/eui"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Headline } from "../coupon-promotion/component"
import { addEbookColumns, useFreeEBookForm } from "./hook/index"
import { useGetFreeGiveawayProducts } from "./hook/products"

export type AddEBookUser =
  definitions["order.admin.v1.AddFreeDigitalContentGiveawayProductInput"] & {
    remark?: string
  }

const itemPerPage = 50

export const FreeEBooDetailPage = () => {
  const params = useParams()
  const formControl = useFreeEBookForm(params.id || "create")
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [page, setPage] = useState(0)

  const { data } = useGetFreeGiveawayProducts(params.id!, page, itemPerPage)

  return (
    <div className="flex min-h-screen max-w-screen-xl flex-col gap-y-8">
      <Headline
        onGoBackClick={() => navigate(-1)}
        text={formControl.values?.name ?? ""}
        action={<FreeEbookReportButton />}
      />
      <div className="grid h-full grid-cols-3">
        <div className="col-span-full flex flex-col gap-y-4">
          <div className="mt-8 flex items-center">
            <EuiText size="s" style={{ color: LIGHTEST_GREY }}>
              {t("promotion.detail.transaction-date")}
            </EuiText>
            <EuiText size="xs" className="ml-2">
              {t("promotion.detail.promotion-create-datetime", {
                value: formControl.values.createdAt,
              })}
            </EuiText>
          </div>
          <div>
            <EuiText size="m" className="font-bold">
              {t("free-e-book.editor.noted")}
            </EuiText>
            <EuiText size="s">
              <p>{formControl.values.noted || "-"}</p>
            </EuiText>
          </div>
          <div className="col-span-full grid-rows-1">
            <FreeEbookDetailTableSection
              data={data}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const FreeEbookReportButton = () => {
  const { webBFF } = useStreamApi()
  const { notificationStore: notification } = useStore()
  const { t } = useTranslation()
  const { id } = useParams()
  const { download, loadingPercentage, error } = useDownloadReport(
    webBFF("/admin/report/add-free-digital-content-giveaway")
      .method("post")
      .create(),
    { autoDownload: true },
  )

  useEffect(() => {
    if (error) {
      notification.add({
        color: "danger",
        title: t("common.noti.error"),
        text: error.message,
      })
    }
  }, [error, notification, t])

  return (
    <EuiButton
      fill
      color="primary"
      className="px-10"
      onClick={() =>
        download({
          giveawayId: id,
        })
      }
      isLoading={loadingPercentage === 100}
    >
      {t("free-e-book.detail.download-btn")}
    </EuiButton>
  )
}

const FreeEbookDetailTableSection = ({
  data,
  page,
  setPage,
}: {
  data: { items: AddEBookUser[]; count?: number }
  page: number
  setPage: (page: number) => void
}) => {
  const { t } = useTranslation()
  return (
    <EuiAccordion
      id="table"
      buttonElement={"div"}
      buttonContent={t("free-e-book.detail.table")}
      buttonClassName="text-xl font-bold"
      arrowDisplay="right"
      css={{
        ".euiAccordion__childWrapper": {
          blockSize: "unset !important",
        },
      }}
    >
      <div className="mt-4">
        <EuiBasicTable<Omit<AddEBookUser, "remark">>
          items={data.items || []}
          columns={addEbookColumns.filter((column) => {
            const col = column as EuiTableFieldDataColumnType<AddEBookUser>
            return col.field !== "remark"
          })}
          responsive={false}
        />
        <EuiPagination
          css={{
            ".euiPagination__list": {
              "margin-inline-start": 0,
              "list-style-type": "none",
            },
          }}
          className="mt-4"
          pageCount={Math.ceil((data.count || 0) / itemPerPage)}
          activePage={page}
          onPageClick={(activePage) => {
            setPage(activePage)
          }}
        />
      </div>
    </EuiAccordion>
  )
}
