import { HomeManagementCard } from "@app/component/home-management/card"
import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { HomeManagementMode } from "@app/model/homepage/homepage"
import { WriterInput } from "@app/model/homepage/writer"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  DropResult,
  EuiButton,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddItemButton } from "../component/add-item-button"
import { ReorderButton } from "../component/reorder-button"
import { useHomeManagementStore } from "../store/ctx"

export const ListWriter: React.FC = () => {
  const { t } = useTranslation()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "homepage-management.writer",
  })
  const [mode, setMode] = useState<HomeManagementMode>(HomeManagementMode.IDLE)
  const homeManagementStore = useHomeManagementStore()
  const [tempWriters, setTempWriters] = useState<
    definitions["webutility.admin.v1.RecommendAuthorSection.Author"][]
  >(homeManagementStore.homepage?.popularAuthorSection?.authors ?? [])

  const navigate = useNavigate()

  const onDragEnd = (result: DropResult) => {
    if (result.source && result.destination && tempWriters) {
      const items = euiDragDropReorder(
        tempWriters,
        result.source.index,
        result.destination.index,
      )
      setTempWriters(items)
    }
  }

  useEffect(() => {
    setTempWriters(
      homeManagementStore.homepage?.popularAuthorSection?.authors ?? [],
    )
  }, [homeManagementStore.homepage?.popularAuthorSection?.authors])

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      {mode === HomeManagementMode.IDLE ? (
        <div className="flex flex-row items-center justify-between">
          <NavigationHeaderButton
            title={editorT("popular-writer")}
            onClick={() => {
              navigate("/home-management")
            }}
          />
          <ReorderButton
            onPress={() => {
              setMode(HomeManagementMode.REORDER)
            }}
          />
        </div>
      ) : null}
      {mode === HomeManagementMode.REORDER ? (
        <div className="flex flex-row items-center justify-between">
          <div>
            <EuiText>
              <h2>{editorT("reorder-writer")}</h2>
            </EuiText>
          </div>
          <div>
            <EuiButton
              css={css`
                width: 210px;
                height: 40px;
              `}
              color="text"
              onClick={() => {
                setMode(HomeManagementMode.IDLE)
              }}
            >
              {t("common.cancel")}
            </EuiButton>
            <EuiButton
              css={css`
                width: 210px;
                height: 40px;
              `}
              className="ml-4"
              fill
              onClick={() => {
                setMode(HomeManagementMode.IDLE)
                if (tempWriters) {
                  homeManagementStore.update("popularAuthorSection", {
                    authors: tempWriters,
                  })
                }
              }}
            >
              {t("common.save")}
            </EuiButton>
          </div>
        </div>
      ) : null}
      {mode === HomeManagementMode.IDLE ||
      mode === HomeManagementMode.REORDER ? (
        <EuiDragDropContext onDragEnd={onDragEnd}>
          <EuiDroppable
            className="mt-10 grid grid-cols-4 gap-x-8 gap-y-16"
            droppableId="LIST_CATEGORY"
            type="TYPE_ONE"
            isDropDisabled={mode !== HomeManagementMode.REORDER}
            direction="horizontal"
          >
            <>
              {tempWriters?.map((c: WriterInput, index) => {
                return (
                  <EuiDraggable
                    isDragDisabled={mode !== HomeManagementMode.REORDER}
                    key={c.id}
                    index={index}
                    draggableId={c.id ?? index.toString()}
                  >
                    {() => (
                      <HomeManagementCard
                        imageCss={css({
                          width: "100px !important",
                          height: "auto",
                          aspectRatio: "1/1",
                          borderRadius: "50%",
                          objectFit: "contain",
                        })}
                        titleCss={css`
                          margin-top: 16px !important;
                        `}
                        cardCss={css({
                          minHeight: "100%",
                          ".euiPopover": {
                            position: "absolute",
                            top: "0",
                            right: "0",
                          },
                        })}
                        url={c.url}
                        isAvatar
                        id={c.id ?? ""}
                        imageUrl={c.downloadUrl ?? c.avatar}
                        description={c.authorIntroduction}
                        editMode={mode === HomeManagementMode.REORDER}
                        title={c.name ?? ""}
                        onPressEdit={() => {
                          navigate(`/home-management/writer/${c.id}`)
                        }}
                        onPressDelete={() => {
                          const index =
                            homeManagementStore.homepage?.popularAuthorSection?.authors?.findIndex(
                              (h) => {
                                return c.id === h.id
                              },
                            )
                          if (index !== undefined && index !== -1) {
                            homeManagementStore.update("popularAuthorSection", {
                              authors: [
                                ...(homeManagementStore.homepage?.popularAuthorSection?.authors?.slice(
                                  0,
                                  index,
                                ) ?? []),
                                ...(homeManagementStore.homepage?.popularAuthorSection?.authors?.slice(
                                  index + 1,
                                ) ?? []),
                              ],
                            })
                          }
                        }}
                      />
                    )}
                  </EuiDraggable>
                )
              })}
              {mode === HomeManagementMode.IDLE ? (
                <AddItemButton
                  onClick={() => {
                    navigate("/home-management/writer/create")
                  }}
                  label={editorT("add-writer")}
                  height={200}
                />
              ) : null}
            </>
          </EuiDroppable>
        </EuiDragDropContext>
      ) : null}
    </div>
  )
}
