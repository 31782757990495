import { useState } from "react"

export const useModalState = () => {
  const [state, setState] = useState<{
    title: string
    description: string
    open: boolean
    onConfirm: () => void
  }>({
    title: "",
    description: "",
    open: false,
    onConfirm: () => {},
  })

  const openModal = (
    title: string,
    description: string,
    onConfirm: () => void,
  ) => {
    setState({
      title,
      description,
      open: true,
      onConfirm,
    })
  }

  const closeModal = () => {
    setState((prev) => ({ ...prev, open: false }))
  }

  return { modalState: state, openModal, closeModal }
}
