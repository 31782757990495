import React, { useCallback, useState } from "react"
import { Modal } from "../../ui/modal"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { FileInput } from "../../ui/file-input"
import { TextInput } from "../../ui/text-input"
import { urlRegex } from "../../utils/url"
import { INSERT_IMAGE_COMMAND, INSERT_UPLOAD_IMAGES_COMMAND } from "./common"
import "./image.css"

export const InsertImageFilesModal: React.FC<{ onClose: () => void }> = (
  props,
) => {
  const [editor] = useLexicalComposerContext()
  const [files, setFiles] = useState<FileList | null>(null)

  const onSave = useCallback(() => {
    if (files !== null) {
      editor.dispatchCommand(INSERT_UPLOAD_IMAGES_COMMAND, files)

      props.onClose()
    }
  }, [files, editor, props.onClose])

  return (
    <Modal onClose={props.onClose} title="Select Image(s)">
      <FileInput
        label="Select image(s)"
        accept="image/*"
        onChange={(f) => setFiles(f)}
        multiple
      />

      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          className="mpe-button"
          disabled={files === null}
          onClick={() => onSave()}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export const InsertImageUrlModal: React.FC<{
  onClose: () => void
}> = (props) => {
  const [editor] = useLexicalComposerContext()
  const [imageUrl, setImageUrl] = useState("")
  const [imageAlt, setImageAlt] = useState("")

  const onSave = useCallback(
    (_imageUrl: string, _imageAlt: string) => {
      if (!_imageUrl || !urlRegex.test(_imageUrl)) {
        throw new Error("Invalid image url")
      }

      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        src: _imageUrl,
        altText: _imageAlt,
      })
      props.onClose()
    },
    [urlRegex, props.onClose, editor],
  )

  return (
    <Modal onClose={props.onClose} title="Insert Image">
      <TextInput
        label="Image Url"
        onChange={setImageUrl}
        value={imageUrl}
        placeholder="https://awesome-web-site/awesome-image.jpg"
      />
      <TextInput
        label="Image Alt"
        onChange={setImageAlt}
        value={imageAlt}
        placeholder="awesome image"
      />

      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          className="mpe-button"
          disabled={!imageUrl}
          onClick={() => onSave(imageUrl, imageAlt)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}
