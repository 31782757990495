import { splitIds } from "@app/model"
import { definitions } from "@app/vendor/shop-specs/shop_service"

export const bookCategoryIdPrefix = "book"
export const eVoucherCategoryIdPrefix = "voucher"

export const isRestrictedCategory = (id?: string) => {
  return id === bookCategoryIdPrefix || id === eVoucherCategoryIdPrefix
}

export type Category = {
  id: string
  name: string
  slug: string
  childrenCount: number
  order: number
  parentId?: string
  banners?: CategoryBanner[]
}

export type CategoryBanner = definitions["shop.v1.CategoryBanner"] & {
  fileInput?: definitions["foundation.file.FileInput"]
  blobImage?: string
}

export type CategoryBannerRequest = definitions["shop.v1.CategoryBannerInput"]

export const getCategoriesByLevel = (
  categories: Array<Category>,
  level: number,
) => {
  if (level > 3) {
    throw new Error("Level must be less than or equal to 3")
  }

  return categories
    .filter((c) => !!c)
    .filter((c) => {
      return c.id && splitIds(c.id).length === level
    })
}

export const categoryBannerIntoRequest = (
  b: CategoryBanner,
  index: number,
): CategoryBannerRequest => {
  return {
    id: b.id,
    link: b.link,
    order: index,
    image: b.fileInput,
  }
}
