import { ProductTypeInput } from "@app/model/product-table"
import { PlatformCouponCartPromotionSetup } from "./hook/promotion"

export enum ConditionType {
  ALL = "all",
  BY_CATEGORY = "by-category",
  BY_SHOP = "by-shop",
  BY_PRODUCT = "by-product",
}

export enum ProductParticipate {
  PARTICIPATE_EBOOK = "participate-ebook",
  PARTICIPATE_AUDIO_BOOK = "participate-audio-book",
  PARTICIPATE_PRODUCT = "participate-product",
}

export type FormPromotion = Omit<PlatformCouponCartPromotionSetup, "id"> & {
  isCustomDiscount: boolean
  discount?: string
  isCustomMaxDiscount: boolean
  customMaxDiscount?: string
  minNetPrice?: string
  platformSubsidizePercent?: string
  isCanCollectCodeEarly: boolean
}

export type ProductForExport = {
  id: string
  type: ProductTypeInput
}
