import { Title } from "@app/component"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { FileForm } from "@app/model/form"
import { EuiButton, EuiFieldText, EuiFormRow } from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputCropImage, useFormHelper } from "@reeeed-mp/ui-common"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { useCampaignCoupon } from "./hook"

type CampaignCouponBannerForm = {
  id?: string
  url?: string
  cover?: FileForm
  sellerId?: string
}

const validationSchema = Yup.object({
  url: Yup.string().required(),
  sellerId: Yup.string().required(),
  cover: Yup.object()
    .shape({
      fileInput: Yup.object().shape({
        tmpPath: Yup.string(),
        name: Yup.string(),
      }),
      downloadUrl: Yup.string(),
    })
    .default(undefined)
    .required(),
})

export const CampaignCouponBannerEditor = () => {
  const { notificationStore: notification } = useStore()
  const { uploadFile } = useUploadFile()
  const { t } = useTranslation()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "campaign-coupon",
  })

  const { getCampaignCouponBanner, storeCampaignCouponBanner } =
    useCampaignCoupon()

  const onGetItem = async (): Promise<CampaignCouponBannerForm | undefined> => {
    try {
      const data = await getCampaignCouponBanner()

      let image: FileForm | undefined
      if (data?.campaignCouponBanner?.image) {
        image = {
          downloadUrl: data?.campaignCouponBanner?.image,
          fileInput: undefined,
          name: data?.campaignCouponBanner?.url,
        }
      }

      return {
        url: data?.campaignCouponBanner?.url,
        cover: image,
        sellerId: data?.shopId,
      }
    } catch (err) {}
  }

  const onSubmit = async (
    values: CampaignCouponBannerForm,
  ): Promise<{ id: string | undefined }> => {
    await storeCampaignCouponBanner({
      image: values.cover?.fileInput?.tmpPath,
      url: values.url,
      shopId: values.sellerId,
    })
    return {
      id: "",
    }
  }

  const onSubmitted = async () => {
    notification.add({
      title: "Success",
      color: "success",
    })
  }

  const onError = (err: unknown) => {
    if (err instanceof Error) {
      notification.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm,
    errors,
  } = useFormHelper<CampaignCouponBannerForm, { id: string | undefined }>({
    id: "campaign-coupon",
    onGetItem,
    onSubmit,
    onSubmitted,
    onError,
    validationSchema,
  })

  return (
    <div>
      <div>
        <Title text={editorT("title")} />
      </div>

      <div className="mt-6">
        <EuiFormRow label={editorT("url")} fullWidth isInvalid={!!errors.url}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={form.url || ""}
            onChange={(e) => {
              setValue("url", e.target.value)
            }}
            isInvalid={!!errors.url}
            controlOnly={true}
            name="url"
          />
        </EuiFormRow>
      </div>

      <div className="mt-6">
        <FormInputCropImage
          width={1240}
          isInvalid={!!errors.cover}
          height={348}
          css={css`
            max-width: 1240px !important;
            width: 100% !important;
            height: 348px !important;
            .euiImage {
              max-width: 1240px !important;
              width: 100% !important;
              height: 348px !important;
              border-radius: 6px !important;
            }
          `}
          cropStyles={{
            maxWidth: `${1240 / 2}px`,
            maxHeight: `${348 / 2}px`,
            backgroundColor: "#C4C4C4",
          }}
          placeholder={
            <span className="block text-center">
              <p>{editorT("cover-label")}</p>
              <p className="whitespace-pre-wrap text-center text-sm">
                {editorT("cover-suggestion", {
                  size: "1240 x 348px",
                  type: ".jpg, .png ขนาดไม่เกิน 3mb",
                })}
              </p>
            </span>
          }
          accept={`${contentType.JPG}, ${contentType.PNG}`}
          value={form.cover?.downloadUrl || undefined}
          onChange={async (v: File) => {
            const { input, blob } = await uploadFile(v)
            setValue("cover.downloadUrl", blob)
            setValue("cover.fileInput", input)
          }}
          headerModal={t("common.cover-crop")}
          textZoomTooltip={t("common.crop-image-zoom-tooltip")}
          textModalSaveButton={t("common.save")}
          textModalCancelButton={t("common.cancel")}
          maxFileSizeKB={3000}
          onError={(err: Error) =>
            notification.add({
              title: "Error",
              color: "danger",
              text: err.message,
            })
          }
          onSubmitCropError={() => {
            notification.add({
              title: "Error",
              color: "danger",
              text: t("err.failed-to-upload-file"),
            })
          }}
        />
      </div>
      <div className="mt-6">
        <EuiFormRow
          label={editorT("seller-id")}
          fullWidth
          isInvalid={!!errors.sellerId}
        >
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={form.sellerId || ""}
            onChange={(e) => {
              setValue("sellerId", e.target.value)
            }}
            isInvalid={!!errors.sellerId}
            controlOnly={true}
            name="name"
          />
        </EuiFormRow>
      </div>
      <div className="mt-10">
        <EuiButton
          className="mr-4"
          onClick={() => {
            submitForm()
          }}
          fill
          style={{ width: 180 }}
        >
          {t("common.save")}
        </EuiButton>
      </div>
    </div>
  )
}
