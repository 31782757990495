import { BannerInput } from "@app/model/homepage/banner"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"

export const upsertById = <
  T extends { id?: string },
  U extends { id?: string },
>(
  value: U,
  arr?: T[],
): (T | U)[] => {
  let newArr: (T | U)[] = []
  if (arr) {
    const index = arr.findIndex((c) => {
      return c.id === value.id
    })
    if (index !== -1) {
      newArr = [...arr.slice(0, index), value, ...arr.slice(index + 1)]
    } else {
      newArr = [value, ...arr]
    }
  } else {
    newArr = [value]
  }
  return newArr
}

export const bannerInputOutTransform = (
  input: BannerInput,
): definitions["webutility.admin.v1.BannerInput"] => {
  return {
    ...(input.id ? { id: input.id } : {}),
    name: input.name,
    url: input.url,
    ...(isInvalidImagePath(input.image)
      ? {}
      : {
          image: input.image,
        }),
    imageAlt: input.imageAlt,
    startDate: input.startDate,
    endDate: input.endDate,
  }
}

export const objectsWithIDAndImageToInput = <
  T extends { id?: string; image?: string },
>(
  data: T[],
) => {
  return data.map((d) => {
    if (isInvalidImagePath(d.image)) {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { image, ...rest } = d
      return removeIdNew(rest)
    }

    return removeIdNew(d)
  })
}

export const objectsWithIDAndCoverToInput = <
  T extends { id?: string; cover?: string },
>(
  data: T[],
) => {
  return data.map((d) => {
    if (isInvalidImagePath(d.cover)) {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { cover, ...rest } = d
      return removeIdNew(rest)
    }

    return removeIdNew(d)
  })
}

export const authorToInput = <T extends { id?: string; avatar?: string }>(
  data: T[],
) => {
  return data.map((d) => {
    if (isInvalidImagePath(d.avatar)) {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { avatar, ...rest } = d
      return removeIdNew(rest)
    }

    return removeIdNew(d)
  })
}

export const highlightProductToInput = (
  input?: definitions["webutility.admin.v1.HighlightProductSection"],
): definitions["webutility.admin.v1.HighlightProductSectionInput"] => {
  return {
    ...(isInvalidImagePath(input?.bannerDesktopImage)
      ? {}
      : { bannerDesktopImage: input?.bannerDesktopImage }),
    ...(isInvalidImagePath(input?.bannerMobileImage)
      ? {}
      : { bannerMobileImage: input?.bannerMobileImage }),
    name: input?.name,
    products: input?.products,
    url: input?.url,
  }
}

const isInvalidImagePath = (image?: string): boolean => {
  return !image || image.startsWith("http")
}

const isValidId = (id?: string): boolean => {
  return !!id && !id?.startsWith("new")
}

const removeIdNew = <T extends { id?: string }>(data: T) => {
  const { id, ...rest } = data
  return {
    ...rest,
    ...(isValidId(id) ? { id } : {}),
  }
}
