import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import * as Yup from "yup"
import { ProductTypeInput } from "../product-table"

export type HighlightProductSection =
  definitions["webutility.admin.v1.CustomizedHighlightProductSection"]

export type ProductHighlightForm = {
  name?: string
}

export type HighlightProduct = {
  id: string
  name: string
  type: ProductTypeInput[]
  shopName: string
}

export const productHighlightValidator = Yup.object({
  name: Yup.string().required(),
})
