import { Title } from "@app/component"
import { Table } from "@app/component/table"
import { UserListReportDownloadModal } from "@app/component/user-list-report-download-modal"
import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/admin-auth-specs/admin_auth_service"
import {
  EuiBasicTableColumn,
  EuiButton,
  EuiFieldText,
  EuiText,
} from "@elastic/eui"
import { UilSearch } from "@iconscout/react-unicons"
import { OnGetItems, presetTimeColumn } from "@reeeed-mp/ui-common"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"

type UserList = {
  id: string
  userId: string
  username: string
  email: string
  firstName: string
  lastName: string
  lastAccessAt: string
  signupProvider: string
  acquisitionChannel: string
}

const inTransform = (data: definitions["auth.admin.v1.ListUser"]): UserList => {
  if (data) {
    return {
      id: data.userId || "-",
      userId: data.userId || "-",
      username: data.username || "-",
      email: data.email || "-",
      firstName: data.firstName || "-",
      lastName: data.lastName || "-",
      lastAccessAt: data.lastAccessAt!,
      signupProvider: data.signUpProvider || "-",
      acquisitionChannel: data.acquisitionChannel || "-",
    }
  }

  throw new Error("Invalid user type")
}

export const UserListPage: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const searchTextParamKey = "searchText"
  const searchTextParam = searchParams.get(searchTextParamKey)
  const [searchText, setSearchText] = useState(searchTextParam || "")
  const { authAdmin } = useApi()
  const finder = authAdmin("/list-users").method("get").create()
  const [openDownloadReportModal, setOpenDownloadReportModal] = useState(false)
  const [debounceSearchText] = useDebounce(searchText, 500)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const columns: EuiBasicTableColumn<UserList>[] = [
    {
      field: "userId",
      name: t("user-list.user-id"),
    },
    {
      field: "username",
      name: t("user-list.username"),
    },
    {
      field: "email",
      name: t("user-list.email"),
    },
    {
      name: t("user-list.full-name"),
      render: (row: UserList) => {
        return (
          <>
            <EuiText size="s">
              {row.firstName !== "-" && row.lastName !== "-"
                ? `${row.firstName} ${row.lastName}`
                : row.firstName !== "-"
                  ? row.firstName
                  : "-"}
            </EuiText>
          </>
        )
      },
    },
    {
      name: t("user-list.signup-provider.title"),
      render: (row: UserList) => {
        return (
          <EuiText size="s">
            {row.signupProvider !== "-"
              ? t(`user-list.signup-provider.${row.signupProvider}`)
              : "-"}
          </EuiText>
        )
      },
    },
    {
      name: t("user-list.acquisition-channel.title"),
      render: (row: UserList) => {
        return (
          <EuiText size="s">
            {row.acquisitionChannel !== "-"
              ? t(`user-list.acquisition-channel.${row.acquisitionChannel}`)
              : "-"}
          </EuiText>
        )
      },
    },
    {
      ...presetTimeColumn({
        field: "lastAccessAt",
        name: t("user-list.last-access-at"),
      }),
      sortable: false,
      width: "150px",
    } as EuiBasicTableColumn<UserList>,
  ]

  const onGetItems: OnGetItems<UserList> = useCallback(
    async (props) => {
      setIsLoading(true)
      const resp = await finder({
        "option.limit": props.opts.limit,
        "option.skip": props.opts.skip,
        filter: props.filter?.searchText || undefined,
      })
      setIsLoading(false)

      if (!resp.ok) {
        return {
          items: [],
          count: 0,
        }
      }

      return {
        items: resp.data.users!.map(inTransform),
        count: resp.data.total!,
      }
    },
    [finder],
  )

  return (
    <div>
      <UserListReportDownloadModal
        open={openDownloadReportModal}
        onClose={() => {
          setOpenDownloadReportModal(false)
        }}
        asOverviewReport
      />
      <div className="flex justify-end">
        <EuiButton
          fill
          iconType="document"
          onClick={() => {
            setOpenDownloadReportModal(true)
          }}
        >
          {t("user-list.download-report")}
        </EuiButton>
      </div>
      <Title text={t("user-list.user")} />
      <div className="mb-6 flex items-center justify-between">
        <EuiFieldText
          placeholder={t("user-list.search-filter")}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value)
          }}
          icon={<UilSearch />}
        />
      </div>

      <Table
        columns={columns}
        onGetItems={onGetItems}
        isCanSelect={false}
        swrKey={`/list-users`}
        filter={{
          searchText: debounceSearchText,
        }}
        sorter={{ field: "lastAccessAt", direction: "desc" }}
        loading={isLoading}
      />
    </div>
  )
}
