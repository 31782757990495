import React, { useCallback, useEffect, useState } from "react"

import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
  HeadingTagType,
} from "@lexical/rich-text"
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
  $setBlocksType,
} from "@lexical/selection"
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils"
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
} from "lexical"
import { DropDown, DropDownItem } from "../ui/dropdown"
import "./toolbar.css"
import { ToolbarPlugins } from "../../../types"
import { ToolbarInsertLink } from "./link/link"
import { InsertImageFilesModal, InsertImageUrlModal } from "./image/component"
import { AutoEmbedDialog, EmbedConfigs, YoutubeEmbedConfig } from "./auto-embed"

const blockTypeToBlockName = {
  bullet: "Bulleted List",
  check: "Check List",
  code: "Code Block",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  h6: "Heading 6",
  number: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
}

export const ToolbarPlugin: React.FC<{
  toolbarPlugins: Array<ToolbarPlugins>
}> = (props) => {
  if (props.toolbarPlugins.length === 0) {
    return null
  }

  const toolbarItems: Array<React.ReactNode> = []

  if (
    props.toolbarPlugins.find((v) => v === ToolbarPlugins.UndoRedo) !==
    undefined
  ) {
    toolbarItems.push(<ToolbarUndoRedo />)
  }

  if (
    props.toolbarPlugins.find((v) => v === ToolbarPlugins.BlockType) !==
    undefined
  ) {
    toolbarItems.push(<ToolbarBlockType />)
  }

  if (
    props.toolbarPlugins.find((v) => v === ToolbarPlugins.RichText) !==
    undefined
  ) {
    toolbarItems.push(<ToolbarRichText />)
  }

  if (
    props.toolbarPlugins.find((v) => v === ToolbarPlugins.Link) !== undefined
  ) {
    toolbarItems.push(<ToolbarInsertLink />)
  }

  if (
    props.toolbarPlugins.find((v) => v === ToolbarPlugins.Alignment) !==
    undefined
  ) {
    toolbarItems.push(<ToolbarAlignment />)
  }

  toolbarItems.push(
    <ToolbarInsertCustomNode toolbarPlugins={props.toolbarPlugins} />,
  )

  return (
    <div className="mpe-toolbar">
      {toolbarItems.map((ele, i) => {
        return (
          <React.Fragment key={`editor-${i}`}>
            {ele}
            {i + 1 < toolbarItems.length && <Divider />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const Divider: React.FC = () => {
  return <div className="mpe-divider" />
}

const dropDownActiveClass = (active: boolean) => {
  return active ? "mpe-active mpe-dropdown-item-active" : ""
}

const ToolbarRichText: React.FC = () => {
  const [editor] = useLexicalComposerContext()
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isSubscript, setIsSubscript] = useState(false)
  const [isSuperscript, setIsSuperscript] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))
      setIsSubscript(selection.hasFormat("subscript"))
      setIsSuperscript(selection.hasFormat("superscript"))
    }
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar()
      })
    })
  }, [updateToolbar, editor])

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode
            }

            if (textNode.__style !== "") {
              textNode.setStyle("")
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat("")
            }
            node = textNode
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat("")
          }
        })
      }
    })
  }, [editor])

  return (
    <div style={{ display: "flex" }}>
      <button
        disabled={false}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
        }}
        className={
          "mpe-toolbar-item mpe-spaced " + (isBold ? "mpe-active" : "")
        }
      >
        <i className="mpe-format mpe-bold" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
        }}
        className={
          "mpe-toolbar-item mpe-spaced " + (isItalic ? "mpe-active" : "")
        }
      >
        <i className="mpe-format mpe-italic" />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
        }}
        className={
          "mpe-toolbar-item mpe-spaced " + (isUnderline ? "mpe-active" : "")
        }
      >
        <i className="mpe-format mpe-underline" />
      </button>
      <DropDown
        buttonClassName="mpe-toolbar-item mpe-spaced"
        buttonIconClassName="mpe-icon mpe-dropdown-more"
      >
        <DropDownItem
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
          }}
          className={"mpe-item " + dropDownActiveClass(isStrikethrough)}
          title="Strikethrough"
        >
          <i className="mpe-icon mpe-strikethrough" />
          <span className="mpe-text">Strikethrough</span>
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript")
          }}
          className={"mpe-item " + dropDownActiveClass(isSubscript)}
          title="Subscript"
        >
          <i className="mpe-icon mpe-subscript" />
          <span className="mpe-text">Subscript</span>
        </DropDownItem>
        <DropDownItem
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript")
          }}
          className={"mpe-item " + dropDownActiveClass(isSuperscript)}
          title="Superscript"
        >
          <i className="mpe-icon mpe-superscript" />
          <span className="mpe-text">Superscript</span>
        </DropDownItem>
        <DropDownItem
          onClick={clearFormatting}
          className="mpe-item"
          title="Clear text formatting"
        >
          <i className="mpe-icon mpe-clear" />
          <span className="mpe-text">Clear Formatting</span>
        </DropDownItem>
      </DropDown>
    </div>
  )
}

const ToolbarUndoRedo: React.FC = () => {
  const [editor] = useLexicalComposerContext()
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [editor])

  return (
    <div style={{ display: "flex" }}>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined)
        }}
        className="mpe-toolbar-item mpe-spaced"
      >
        <i className="mpe-format mpe-undo" />
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined)
        }}
        className="mpe-toolbar-item"
      >
        <i className="mpe-format mpe-redo" />
      </button>
    </div>
  )
}

const ToolbarBlockType: React.FC = () => {
  const [editor] = useLexicalComposerContext()
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph")

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          )
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
        }
      }
    }
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar()
      })
    })
  }, [updateToolbar, editor])

  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if (
        $isRangeSelection(selection)
        // || DEPRECATED_$isGridSelection(selection)
      ) {
        $setBlocksType(selection, () => $createParagraphNode())
      }
    })
  }

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if (
          $isRangeSelection(selection)
          // || DEPRECATED_$isGridSelection(selection)
        ) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection()
        if (
          $isRangeSelection(selection)
          //  || DEPRECATED_$isGridSelection(selection)
        ) {
          $setBlocksType(selection, () => $createQuoteNode())
        }
      })
    }
  }

  return (
    <DropDown
      buttonClassName="mpe-toolbar-item mpe-block-controls"
      buttonIconClassName={"mpe-icon mpe-block-type " + "mpe-" + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
    >
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "paragraph")}
        onClick={formatParagraph}
      >
        <i className="mpe-icon mpe-paragraph" />
        <span className="mpe-text">Normal</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "h1")}
        onClick={() => formatHeading("h1")}
      >
        <i className="mpe-icon mpe-h1" />
        <span className="mpe-text">Heading 1</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "h2")}
        onClick={() => formatHeading("h2")}
      >
        <i className="mpe-icon mpe-h2" />
        <span className="mpe-text">Heading 2</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "h3")}
        onClick={() => formatHeading("h3")}
      >
        <i className="mpe-icon mpe-h3" />
        <span className="mpe-text">Heading 3</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "h4")}
        onClick={() => formatHeading("h4")}
      >
        <i className="mpe-icon mpe-h4" />
        <span className="mpe-text">Heading 4</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "h5")}
        onClick={() => formatHeading("h5")}
      >
        <i className="mpe-icon mpe-h5" />
        <span className="mpe-text">Heading 5</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "h6")}
        onClick={() => formatHeading("h6")}
      >
        <i className="mpe-icon mpe-h6" />
        <span className="mpe-text">Heading 6</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "bullet")}
        onClick={formatBulletList}
      >
        <i className="mpe-icon mpe-bullet-list" />
        <span className="mpe-text">Bullet List</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "number")}
        onClick={formatNumberedList}
      >
        <i className="mpe-icon mpe-numbered-list" />
        <span className="mpe-text">Numbered List</span>
      </DropDownItem>
      <DropDownItem
        className={"mpe-item " + dropDownActiveClass(blockType === "quote")}
        onClick={formatQuote}
      >
        <i className="mpe-icon mpe-quote" />
        <span className="mpe-text">Quote</span>
      </DropDownItem>
    </DropDown>
  )
}

const ToolbarAlignment: React.FC = () => {
  const [editor] = useLexicalComposerContext()

  return (
    <DropDown
      buttonLabel="Align"
      buttonIconClassName="mpe-icon mpe-left-align"
      buttonClassName="mpe-toolbar-item mpe-spaced mpe-alignment"
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
        }}
        className="mpe-item"
      >
        <i className="mpe-icon mpe-left-align" />
        <span className="mpe-text">Left Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
        }}
        className="mpe-item"
      >
        <i className="mpe-icon mpe-center-align" />
        <span className="mpe-text">Center Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
        }}
        className="mpe-item"
      >
        <i className="mpe-icon mpe-right-align" />
        <span className="mpe-text">Right Align</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")
        }}
        className="mpe-item"
      >
        <i className="mpe-icon mpe-justify-align" />
        <span className="mpe-text">Justify Align</span>
      </DropDownItem>
      <Divider />
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
        }}
        className="mpe-item"
      >
        <i className="mpe-icon mpe-outdent" />
        <span className="mpe-text">Outdent</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
        }}
        className="mpe-item"
      >
        <i className="mpe-icon mpe-indent" />
        <span className="mpe-text">Indent</span>
      </DropDownItem>
    </DropDown>
  )
}

const ToolbarInsertCustomNode: React.FC<{
  toolbarPlugins: Array<ToolbarPlugins>
}> = ({ toolbarPlugins }) => {
  const [modal, setModal] = useState<
    | ToolbarPlugins.ImageUrl
    | ToolbarPlugins.ImageUpload
    | ToolbarPlugins.Youtube
    | undefined
  >(undefined)

  const isImageUploadAllowed = toolbarPlugins.includes(
    ToolbarPlugins.ImageUpload,
  )
  const isImageUrlAllowed = toolbarPlugins.includes(ToolbarPlugins.ImageUrl)
  const allowedEmbeds = EmbedConfigs.filter((e) => {
    return toolbarPlugins.includes(e.type)
  })

  if (
    allowedEmbeds.length === 0 &&
    !isImageUploadAllowed &&
    !isImageUrlAllowed
  ) {
    return null
  }

  return (
    <>
      <DropDown
        buttonClassName="mpe-toolbar-item mpe-spaced "
        buttonLabel="Insert"
        buttonIconClassName="mpe-icon mpe-plus"
      >
        {isImageUploadAllowed && (
          <DropDownItem
            onClick={() => setModal(ToolbarPlugins.ImageUpload)}
            className="mpe-item"
          >
            <i className="mpe-icon mpe-image" />
            <span className="mpe-text">Upload Image(s)</span>
          </DropDownItem>
        )}
        {isImageUrlAllowed && (
          <DropDownItem
            onClick={() => setModal(ToolbarPlugins.ImageUrl)}
            className="mpe-item"
          >
            <i className="mpe-icon mpe-image" />
            <span className="mpe-text">Image URL</span>
          </DropDownItem>
        )}
        {allowedEmbeds.map((embedConfig) => (
          <DropDownItem
            key={embedConfig.type}
            onClick={() => setModal(embedConfig.type as any)}
            className="mpe-item"
          >
            {embedConfig.icon}
            <span className="mpe-text">{embedConfig.contentName}</span>
          </DropDownItem>
        ))}
      </DropDown>

      {modal === ToolbarPlugins.ImageUpload && (
        <InsertImageFilesModal onClose={() => setModal(undefined)} />
      )}
      {modal === ToolbarPlugins.ImageUrl && (
        <InsertImageUrlModal onClose={() => setModal(undefined)} />
      )}
      {modal === ToolbarPlugins.Youtube && (
        <AutoEmbedDialog
          embedConfig={YoutubeEmbedConfig}
          onClose={() => setModal(undefined)}
        />
      )}
    </>
  )
}
