import { EuiForm, EuiFormRow, EuiPanel, EuiSelect, EuiText } from "@elastic/eui"
import React from "react"
import { homeMenuListOptions, MenuTypes, useSelectedMenu } from "../hook/model"
import { useHomeManagementStore } from "../store/ctx"
import { t } from "i18next"

type LayoutConfigProps = {
  menu?: MenuTypes | ""
  children: React.ReactNode
}

export const LayoutConfig = ({ children }: LayoutConfigProps) => {
  const { homeMenuList, selectedId } = useHomeManagementStore()
  const selectedMenu = useSelectedMenu(selectedId!)
  if (!selectedId || Object.keys(homeMenuList || {}).length < 1) {
    return null
  }

  return (
    <div className="col-span-3 flex flex-col">
      <EuiText size="s" className="mb-4 font-bold">
        {t("home-management.detail-config-section")}
      </EuiText>

      <EuiPanel hasShadow={false} hasBorder className="max-h-fit">
        <EuiForm className="relative flex flex-col gap-y-4 p-2">
          <EuiFormRow
            label={t("home-management.layout-config.menu-type-label")}
            className="max-w-sm"
          >
            <EuiSelect
              options={homeMenuListOptions}
              value={selectedMenu}
              disabled
            />
          </EuiFormRow>
          {children}
        </EuiForm>
      </EuiPanel>
    </div>
  )
}
