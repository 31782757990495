import React, { useEffect } from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  INSERT_TAB_COMMAND,
  KEY_TAB_COMMAND,
  LexicalEditor,
  RangeSelection,
} from "lexical"

export const ModifyTabIndentationPlugin: React.FC = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return registerModifyTabIndentationPlugin(editor)
  })

  return null
}

const registerModifyTabIndentationPlugin = (editor: LexicalEditor) => {
  return editor.registerCommand(
    KEY_TAB_COMMAND,
    (event) => {
      const selection = $getSelection()
      if (
        !$isRangeSelection(selection) ||
        !$isCursorOnFirstCharacterOfParagraph(selection)
      ) {
        return false
      }

      event.preventDefault()
      return editor.dispatchCommand(INSERT_TAB_COMMAND, undefined)
    },
    COMMAND_PRIORITY_LOW,
  )
}

const $isCursorOnFirstCharacterOfParagraph = (
  selection: RangeSelection,
): boolean => {
  return selection.anchor.is(selection.focus) && selection.anchor.offset === 0
}
