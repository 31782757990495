import React, { useEffect } from "react"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  DraggableProvidedDragHandleProps,
  DropResult,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPanel,
  EuiPopover,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import {
  UilEllipsisV,
  UilExclamationCircle,
  UilLink,
  UilPen,
  UilPlus,
  UilTrash,
} from "@iconscout/react-unicons"
import classNames from "classnames"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import {
  useDeleteHighlightNavigationTab,
  useDeleteNavigationTab,
  useUpdateMainMenu,
} from "./hook"
import {
  useHighlightNavigationTabs,
  useNavigationTabs,
  useReorderNavigationTab,
} from "./hook/navigation-tab"
import { NavigationMenuType, useHomeManagementStore } from "../store/ctx"
import { highlightMenuPath, mainMenuPath } from "../hook/path"

const TableAction = (
  props: (
    | definitions["webutility.v1.Menu"]
    | definitions["webutility.v1.HighlightMenu"]
  ) & {
    onClickEdit: () => void
    onClickDelete: () => void
  },
) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const onClickClipboard = () => {
    navigator.clipboard.writeText(props.link!)
  }

  const onClickEdit = () => {
    props.onClickEdit()
    setIsOpen(false)
  }

  const onClickDelete = async () => {
    props.onClickDelete()
    setIsOpen(false)
  }

  return (
    <>
      <EuiPopover
        button={
          <EuiButtonIcon
            iconType={UilEllipsisV}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            aria-label="Actions"
          />
        }
        isOpen={isOpen}
        anchorPosition="leftCenter"
        closePopover={() => setIsOpen((isOpen) => !isOpen)}
        panelPaddingSize="s"
      >
        <div className="flex w-44 flex-col items-start gap-2">
          <EuiButtonEmpty
            iconType={UilLink}
            color="text"
            onClick={onClickClipboard}
          >
            {t("common.copy-url")}
          </EuiButtonEmpty>
          <EuiButtonEmpty iconType={UilPen} color="text" onClick={onClickEdit}>
            {t("common.edit")}
          </EuiButtonEmpty>
          <EuiButtonEmpty
            iconType={UilTrash}
            color="text"
            onClick={onClickDelete}
          >
            {t("common.delete")}
          </EuiButtonEmpty>
        </div>
      </EuiPopover>
    </>
  )
}

const MenuPanel = (props: {
  className?: string
  dragHandleProps: DraggableProvidedDragHandleProps
  menuItem:
    | definitions["webutility.v1.Menu"]
    | definitions["webutility.v1.HighlightMenu"]
  onClickEdit: () => void
  onClickDelete: () => void
}) => {
  return (
    <EuiPanel
      hasShadow={false}
      hasBorder
      className={classNames(
        "flex h-14 items-center justify-between rounded-md border border-solid !bg-grey-lightest px-4 py-2",
        props.className,
      )}
    >
      <EuiPanel
        paddingSize="s"
        aria-label="Drag Handle"
        hasShadow={false}
        {...props.dragHandleProps}
        color="transparent"
        className="flex items-center gap-x-2"
      >
        <EuiIcon type="grab" />
        <EuiText className="font-bold">{props.menuItem.name}</EuiText>
      </EuiPanel>
      <div className="ml-auto flex items-center gap-x-2">
        <TableAction
          {...props.menuItem}
          onClickDelete={props.onClickDelete}
          onClickEdit={props.onClickEdit}
        />
      </div>
    </EuiPanel>
  )
}

const MainMenuConfigs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onDelete } = useDeleteNavigationTab()
  const { tabsData, setTabsData } = useNavigationTabs()
  const { reorderMainNavigation } = useReorderNavigationTab()

  const [debouncedReorder] = useDebounce((menuKeys: string[]) => {
    reorderMainNavigation(menuKeys)
  }, 300)

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (tabsData && source && destination) {
      const reorderedTabs = euiDragDropReorder(
        tabsData,
        source.index,
        destination.index,
      )
      debouncedReorder(reorderedTabs.map((tab) => tab.key!))
      setTabsData(reorderedTabs)
    }
  }

  return (
    <div>
      <div className="mb-4 flex gap-x-2">
        <EuiButton
          fill
          minWidth={170}
          iconType={UilPlus}
          disabled={(tabsData || []).length >= 10}
          onClick={() => navigate(mainMenuPath("create"))}
        >
          {t("home-management.main-menu.menu-button")}
        </EuiButton>
        <EuiText className="flex items-center gap-x-1 text-ci-primary">
          <UilExclamationCircle size={16} color="#6750A3" />
          <span>{t("home-management.main-menu.menu-hint")}</span>
        </EuiText>
      </div>
      {!tabsData ? (
        <div className="grid h-40 w-full place-items-center">
          <EuiLoadingSpinner size="xl" />
        </div>
      ) : (
        <EuiDragDropContext onDragEnd={handleDragEnd}>
          <EuiDroppable
            droppableId="CUSTOM_HANDLE_DROPPABLE_AREA"
            className="flex flex-col p-0 shadow-none"
            withPanel
            spacing="m"
          >
            {tabsData.map((props, idx) => (
              <EuiDraggable
                spacing="m"
                key={`eui-draggable-${idx}`}
                index={idx}
                draggableId={`eui-draggable-${idx}`}
                customDragHandle={true}
                hasInteractiveChildren={true}
                isRemovable={false}
              >
                {(provided) => (
                  <MenuPanel
                    key={`main-navigation-tab-${idx}`}
                    menuItem={props}
                    dragHandleProps={provided.dragHandleProps!}
                    onClickEdit={() => navigate(mainMenuPath(props.key!))}
                    onClickDelete={() => {
                      onDelete(props.key!)
                    }}
                  />
                )}
              </EuiDraggable>
            ))}
          </EuiDroppable>
        </EuiDragDropContext>
      )}
    </div>
  )
}

const HighlightMenuConfigs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { onDelete } = useDeleteHighlightNavigationTab()
  const { tabsData, setTabsData } = useHighlightNavigationTabs()
  const { reorderHighlightNavigation } = useReorderNavigationTab()

  const [debouncedReorder] = useDebounce((highlightKeys: string[]) => {
    reorderHighlightNavigation(highlightKeys)
  }, 300)

  const handleDragEnd = ({ source, destination }: DropResult) => {
    if (tabsData && source && destination) {
      const reorderedTabs = euiDragDropReorder(
        tabsData,
        source.index,
        destination.index,
      )
      debouncedReorder(reorderedTabs.map((tab) => tab.key!))
      setTabsData(reorderedTabs)
    }
  }

  return (
    <div>
      <div className="mb-4 flex gap-x-2">
        <EuiButton
          fill
          minWidth={170}
          iconType={UilPlus}
          disabled={(tabsData || []).length >= 3}
          onClick={() => navigate(highlightMenuPath("create"))}
        >
          {t("home-management.main-menu.highlight-button")}
        </EuiButton>
        <EuiText className="flex items-center gap-x-1 text-ci-primary">
          <UilExclamationCircle size={16} color="#6750A3" />
          <span>{t("home-management.main-menu.highlight-hint")}</span>
        </EuiText>
      </div>
      {!tabsData ? (
        <div className="grid h-40 w-full place-items-center">
          <EuiLoadingSpinner size="xl" />
        </div>
      ) : (
        <EuiDragDropContext onDragEnd={handleDragEnd}>
          <EuiDroppable
            droppableId="CUSTOM_HANDLE_DROPPABLE_AREA"
            className="flex flex-col p-0 shadow-none"
            withPanel
            spacing="m"
          >
            {tabsData.map((props, idx) => (
              <EuiDraggable
                spacing="m"
                key={`eui-draggable-${idx}`}
                index={idx}
                draggableId={`eui-draggable-${idx}`}
                customDragHandle={true}
                hasInteractiveChildren={true}
                isRemovable={false}
              >
                {(provided) => (
                  <MenuPanel
                    key={`highlight-navigation-tab-${idx}`}
                    menuItem={props!}
                    dragHandleProps={provided.dragHandleProps!}
                    onClickEdit={() => navigate(highlightMenuPath(props.key!))}
                    onClickDelete={() => {
                      onDelete(props.key!)
                    }}
                  />
                )}
              </EuiDraggable>
            ))}
          </EuiDroppable>
        </EuiDragDropContext>
      )}
    </div>
  )
}

export const MainMenuConfigSection = () => {
  const { t } = useTranslation()
  const { mainMenuSection, mainMenuTab, setMainMenuTab } =
    useHomeManagementStore()
  const sectionKey = mainMenuSection?.sectionKey
  const { updateMainMenuDetail } = useUpdateMainMenu(sectionKey!)
  const [description, setDescription] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [debouncedDescription] = useDebounce(description, 500)

  useEffect(() => {
    if (mainMenuSection?.mainMenuSection?.description) {
      setDescription(mainMenuSection?.mainMenuSection?.description || "")
    }
  }, [mainMenuSection])

  useEffect(() => {
    if (
      debouncedDescription !== mainMenuSection?.mainMenuSection?.description
    ) {
      setIsSubmitting(true)
      updateMainMenuDetail({
        input: { description: debouncedDescription },
      })
      setIsSubmitting(false)
    }
  }, [debouncedDescription, mainMenuSection, updateMainMenuDetail])

  return (
    <div>
      {isSubmitting && (
        <div className="absolute right-2 top-0">
          <EuiLoadingSpinner size="xl" />
        </div>
      )}
      <EuiTabs className="my-6">
        <EuiTab
          onClick={() => setMainMenuTab(NavigationMenuType.mainMenu)}
          isSelected={mainMenuTab === NavigationMenuType.mainMenu}
        >
          {t("home-management.main-menu.menu-label")}
        </EuiTab>
        <EuiTab
          onClick={() => setMainMenuTab(NavigationMenuType.highlightMenu)}
          isSelected={mainMenuTab === NavigationMenuType.highlightMenu}
        >
          {t("home-management.main-menu.highlight-label")}
        </EuiTab>
      </EuiTabs>
      {mainMenuTab === NavigationMenuType.mainMenu && <MainMenuConfigs />}
      {mainMenuTab === NavigationMenuType.highlightMenu && (
        <HighlightMenuConfigs />
      )}

      <div>
        <div className="mt-4 flex flex-col gap-2">
          <EuiText size="xs">
            <h3>{t("home-management.main-menu.noted")}</h3>
          </EuiText>
          <EuiText size="xs">
            <p className="text-eui-subduedText">
              {t("home-management.main-menu.noted-hint")}
            </p>
          </EuiText>
        </div>

        <EuiTextArea
          className="mt-2"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
          fullWidth
        />
      </div>
    </div>
  )
}
