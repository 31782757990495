import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/order-specs/order_service"
import { PaginationOption } from "@reeeed-mp/ui-common"
import { useCallback, useMemo } from "react"

export type UploadSellerValidateWithdrawError =
  definitions["order.admin.v1.UploadSellerWithdrawResultResponse.ValidateUploadError"][]

export const useFindSellerWithdrawRequestApi = () => {
  const { order } = useApi()
  const getter = useMemo(
    () => order("/withdraw-management").method("get").create(),
    [order],
  )

  return useCallback(
    async (
      input: {
        month: number
        year: number
      },
      opt: PaginationOption,
    ): Promise<
      definitions["order.admin.v1.FindSellerWithdrawResponse"] | undefined
    > => {
      const { data } = await getter({
        "filter.month": input.month,
        "filter.year": input.year,
        "option.limit": opt.limit,
        "option.skip": opt.skip,
      })
      return data
    },
    [getter],
  )
}
