import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons"
import { css } from "@emotion/react"
import cx from "classnames"
import { useEffect, useRef, useState } from "react"
import AliceCarousel from "react-alice-carousel"
import ReactPlaceholder from "react-placeholder"
import { Lightbox } from "@app/component/lightbox"
import "react-alice-carousel/lib/alice-carousel.css"

function easeInOutQuad(x: number): number {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2
}

const galleriesCarouselScrollDurationInMs = 300

export const GalleriesCarousel = (props: {
  galleries: Array<{
    id: string
    url: string
  }>
  selected: string
  onChange: (v: string) => void
  desktopMode?: boolean
  ready: boolean
}) => {
  const { galleries } = props
  const thumbnailDom = useRef<HTMLDivElement>(null)
  const [isScrolledTop, setIsScrolledTop] = useState(true)
  const [isScrolledBottom, setIsScrolledBottom] = useState(true)
  const [scrollTarget, setScrollTarget] = useState(-1)
  const [openLightBox, setOpenLightBox] = useState(false)

  const activeIndex = galleries.findIndex(
    (gallery) => gallery.id === props.selected,
  )

  useEffect(() => {
    if (!thumbnailDom.current || scrollTarget === -1) {
      return
    }
    let cancelled = false
    const start = Date.now()
    const currentScroll = thumbnailDom.current.scrollTop
    let aniId: number

    const loop = () => {
      if (!thumbnailDom.current) {
        return
      }

      const cur = Date.now()
      const percent =
        cur - start < galleriesCarouselScrollDurationInMs
          ? (cur - start) / galleriesCarouselScrollDurationInMs
          : 1

      thumbnailDom.current.scrollTo({
        top:
          currentScroll +
          easeInOutQuad(percent) * (scrollTarget - currentScroll),
      })

      if (cancelled || percent === 1) {
        setScrollTarget(-1)
        return
      }
      aniId = requestAnimationFrame(loop)
    }

    aniId = requestAnimationFrame(loop)
    return () => {
      cancelled = true
      cancelAnimationFrame(aniId)
    }
  }, [scrollTarget])

  useEffect(() => {
    if (!thumbnailDom.current) {
      return
    }
    const handler = () => {
      if (!thumbnailDom.current) {
        return
      }
      const newIsScrolledTop = thumbnailDom.current.scrollTop === 0
      const newIsScrolledBottom =
        thumbnailDom.current.scrollTop + thumbnailDom.current.offsetHeight ===
        thumbnailDom.current.scrollHeight
      if (newIsScrolledTop !== isScrolledTop) {
        setIsScrolledTop(newIsScrolledTop)
      }
      if (newIsScrolledBottom !== isScrolledBottom) {
        setIsScrolledBottom(newIsScrolledBottom)
      }
    }
    handler()
    thumbnailDom.current.onscroll = handler
  }, [
    thumbnailDom.current,
    isScrolledTop,
    setIsScrolledBottom,
    setIsScrolledTop,
    isScrolledBottom,
  ])

  return (
    <ReactPlaceholder type="rect" ready={props.ready}>
      <Lightbox
        desktopMode={props.desktopMode}
        open={openLightBox}
        onChangeIndex={(index) => {
          props.onChange(galleries[index].id)
        }}
        onClose={() => setOpenLightBox(false)}
        slides={props.galleries.map((gal) => {
          return {
            src: gal.url,
          }
        })}
        index={activeIndex}
      />
      <div
        className="relative flex h-full w-full bg-white"
        css={css({
          ".alice-carousel__slide-info": {
            top: "unset",
            right: "50%",
            bottom: "10px",
            background: "rgba(0, 0, 0, 0.4)",
            borderRadius: "40px",
            transform: "translateX(50%)",
          },
        })}
      >
        {props.desktopMode && props.galleries.length > 1 && (
          <div className="relative">
            {!isScrolledTop && (
              <span
                onClick={() => {
                  if (!thumbnailDom.current) {
                    return
                  }
                  const target =
                    thumbnailDom.current.scrollTop -
                    (thumbnailDom.current.offsetHeight / 4) * 3
                  setScrollTarget(target < 0 ? 0 : target)
                }}
                className="absolute left-1/2 top-0 z-10 mt-1 translate-x-1/2 border border-solid border-slate-300 bg-white p-1 hover:bg-white"
              >
                <UilAngleUp className="fill-black" />
              </span>
            )}
            {!isScrolledBottom && (
              <span
                className="absolute bottom-0 left-1/2 z-10 mb-1 translate-x-1/2 border border-solid border-slate-300 bg-white p-1 hover:bg-white"
                onClick={() => {
                  if (!thumbnailDom.current) {
                    return
                  }
                  const target =
                    thumbnailDom.current.scrollTop +
                    (thumbnailDom.current.offsetHeight / 4) * 3
                  const maxScroll =
                    thumbnailDom.current.scrollHeight -
                    thumbnailDom.current.offsetHeight
                  setScrollTarget(target > maxScroll ? maxScroll : target)
                }}
              >
                <UilAngleDown className="fill-black" />
              </span>
            )}
            <div
              ref={thumbnailDom}
              css={css`
                ::-webkit-scrollbar {
                  display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
              `}
              className="mr-2 h-full w-[72px] shrink-0 overflow-scroll"
            >
              <div>
                {galleries.map((gallery) => {
                  const isActive = props.selected === gallery.id
                  return (
                    <div key={gallery.id} className="mb-2">
                      <div
                        onClick={() => {
                          if (!isActive) {
                            props.onChange(gallery.id)
                          }
                        }}
                        css={css({
                          borderColor: isActive ? "black" : "",
                        })}
                        className={cx(
                          "h-[72px] w-[72px] w-full cursor-pointer rounded-md bg-contain bg-top bg-no-repeat",
                          {
                            "border border-solid opacity-100 hover:opacity-100":
                              isActive,
                            "opacity-50 hover:opacity-75": !isActive,
                          },
                        )}
                        style={{
                          backgroundImage: `url("${gallery.url}")`,
                        }}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        <div
          css={css({
            ".alice-carousel": {
              "div:first-child": {
                height: "100%",
              },
              height: "100%",
            },
          })}
          className="h-full w-0 grow"
        >
          <AliceCarousel
            mouseTracking
            activeIndex={activeIndex}
            onSlideChanged={(v) => props.onChange(galleries[v.item].id)}
            disableButtonsControls
            disableDotsControls
            syncStateOnPropsUpdate={false}
            renderSlideInfo={(info) => {
              if (info.itemsCount <= 1) {
                return null
              }
              return (
                <div className="px-1">
                  <div className="w-18">
                    {info.item} / {info.itemsCount}
                  </div>
                </div>
              )
            }}
            disableSlideInfo={false}
            items={galleries.map((gallery) => {
              return (
                <div
                  key={gallery.id}
                  onClick={() => {
                    setOpenLightBox(true)
                  }}
                  className="h-full w-full cursor-pointer bg-contain bg-center bg-no-repeat"
                  style={{
                    backgroundImage: `url("${gallery.url}")`,
                  }}
                />
              )
            })}
          />
        </div>
      </div>
    </ReactPlaceholder>
  )
}
