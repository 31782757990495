import { useStore } from "@app/hook/store"
import React, { useCallback, useEffect, useState } from "react"
import { Item, ItemGroup, ItemGroups, groupIdxToPagination } from "./common"
import { ChapterGroup } from "./group"

export type ChapterListProps<T extends Item> = {
  totalItemCount: number
  limitItemPerGroup: number
  getItems: (opt: { skip: number; limit: number }) => Promise<Array<T>>
  emptyStateComp: () => React.ReactNode
  itemComp: (item: T, mutate: () => void) => React.ReactNode
  accordionPrefix?: string
}

export const ChapterList = <T extends Item>(props: ChapterListProps<T>) => {
  const { totalItemCount, itemComp, accordionPrefix } = props
  const { notificationStore: notification } = useStore()
  const limitItemPerGroup = props.limitItemPerGroup || 20

  const [groups, setGroups] = useState<ItemGroups<T>>(
    Array.from({
      length: Math.ceil(totalItemCount / limitItemPerGroup),
    }).map(() => {
      return {
        isOpen: false,
        items: [],
      }
    }),
  )

  const init = async () => {
    const newGroups = Array.from({
      length: Math.ceil(totalItemCount / limitItemPerGroup),
    }).map((_, i): ItemGroup<T> => {
      const isOpening = i < groups.length ? groups[i].isOpen : false
      return {
        isOpen: isOpening,
        items: [],
      }
    })

    if (newGroups.length === 1) {
      const items = await props.getItems(
        groupIdxToPagination(0, limitItemPerGroup),
      )
      newGroups[0].items = items
    } else {
      for (let i = 0; i < newGroups.length; i++) {
        if (newGroups[i].isOpen) {
          const items = await props.getItems(
            groupIdxToPagination(i, limitItemPerGroup),
          )
          newGroups[i].items = items
        }
      }
    }
    setGroups(newGroups)
  }

  useEffect(() => {
    init()

    // eslint-disable-next-line
  }, [totalItemCount, limitItemPerGroup])

  const catchError = useCallback(
    (e: unknown) => {
      console.error("fail get items", e)
      notification.add({
        text: e instanceof Error ? e.message : (e as string),
        color: "danger",
      })
    },
    [notification],
  )

  const setItemToGroup = useCallback(
    (groupIdx: number, items: Array<T>) => {
      const group = [...groups]
      if (group[groupIdx]) {
        group[groupIdx].items = items
      }

      setGroups(group)
    },
    [groups, setGroups],
  )

  const loadItemsToGroup = useCallback(
    async (groupIdx: number) => {
      try {
        const items = await props.getItems(
          groupIdxToPagination(groupIdx, limitItemPerGroup),
        )
        setItemToGroup(groupIdx, items)
      } catch (e) {
        catchError(e)
      }
    },
    [props, setItemToGroup, catchError, limitItemPerGroup],
  )

  const toggleOpenAccordion = useCallback(
    (groupIdx: number) => {
      const group = [...groups]
      group[groupIdx].isOpen = !group[groupIdx].isOpen

      if (group[groupIdx].isOpen) {
        loadItemsToGroup(groupIdx)
      }

      setGroups(group)
    },
    [groups, setGroups, loadItemsToGroup],
  )

  return (
    <div>
      {totalItemCount === 0 ? (
        props.emptyStateComp()
      ) : (
        <div>
          {groups.map((group, groupIdx) => {
            return (
              <ChapterGroup<T>
                key={groupIdx}
                groupIdx={groupIdx}
                isHideAccordion={groups.length === 1}
                itemComp={itemComp}
                limitItemPerGroup={limitItemPerGroup}
                isAccordionOpen={group.isOpen}
                toggleOpenAccordion={() => toggleOpenAccordion(groupIdx)}
                items={group.items}
                mutate={() => loadItemsToGroup(groupIdx)}
                totalItemCount={totalItemCount}
                accordionPrefix={accordionPrefix || ""}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
