import { Title } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import {
  EuiBadge,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFieldText,
  EuiPopover,
  EuiText,
} from "@elastic/eui"
import { UilFilter, UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
  defaultTypeSorter,
  GiftCodeDetail,
  giftCodeVariant,
  productsType,
  useGetGiftCodeList,
} from "./hook"
import {
  definitions,
  operations,
} from "@app/vendor/web-bff-specs/web_bff_service"
import { ButtonDownloadOrderManagementReport } from "./util"
import React from "react"
import { useDebounce } from "use-debounce"

type HeaderFilterProps = {
  title: string
  setTypeFilter: (s: giftCodeVariant) => void
  typeFilter: string
}

const HeaderFilter: React.FC<HeaderFilterProps> = ({
  title,
  typeFilter,
  setTypeFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <div className="flex items-center gap-2">
      {title}
      <EuiPopover
        button={
          <EuiButtonIcon
            iconType={UilFilter}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          ></EuiButtonIcon>
        }
        anchorPosition="rightUp"
        isOpen={isOpen}
        closePopover={() => setIsOpen(false)}
        panelPaddingSize="s"
      >
        <div className="min-w-44 flex flex-col gap-2">
          {productsType.map((type) => {
            return (
              <EuiButtonEmpty
                key={type}
                color={typeFilter === type ? "primary" : "text"}
                onClick={() => {
                  setTypeFilter(type as giftCodeVariant)
                  setIsOpen(false)
                }}
              >
                <EuiText size="xs">
                  {t(
                    type === defaultTypeSorter
                      ? "gift-code.filter.all"
                      : `gift-code.filter.${type}`,
                  )}
                </EuiText>
              </EuiButtonEmpty>
            )
          })}
        </div>
      </EuiPopover>
    </div>
  )
}

const GiftStatus = ({ used }: { used: boolean }) => {
  const { t } = useTranslation()
  return (
    <EuiBadge color={used ? "#E0E5EE" : "#FBBE24"}>
      {t(`gift-code.${used ? "redeemed" : "not-redeemed"}`)}
    </EuiBadge>
  )
}

const UserOrderDetail = (props: { name?: string; dateTime?: string }) => {
  const { t } = useTranslation()

  return (
    <div>
      <EuiText size="s" className="mb-2">
        {props.name}
      </EuiText>
      <EuiText size="xs" className="text-gray-500">
        {t("common.datetime", {
          value: props.dateTime,
        })}
      </EuiText>
    </div>
  )
}

export const GiftCodeList = () => {
  const { t } = useTranslation()
  const ref = useRef() as TableRefImperative
  const {
    find,
    textSearch,
    setTextSearch,
    typeFilter,
    setTypeFilter,
    loading,
    setLoading,
  } = useGetGiftCodeList()
  const [debounceTextSearch] = useDebounce(textSearch, 500)

  const onGetItems: OnGetItems<GiftCodeDetail> = async (props) => {
    let sorter: operations["AdminAggregateService_FindGiftCode"]["parameters"]["query"]["sorter"] =
      undefined
    if (props.sorter && props.sorter.field === "owner") {
      if (props.sorter.direction === "asc") {
        sorter = "GIFT_CODE_SORTER_CREATED_AT_ASC"
      } else {
        sorter = "GIFT_CODE_SORTER_CREATED_AT_DESC"
      }
    }

    const data = await find({
      filter: props.filter,
      opts: props.opts,
      sorter,
    })

    return {
      items:
        data.items?.map(({ giftCode, owner, redeemedBy }) => ({
          ...giftCode?.purchasedGiftCode,
          productType:
            giftCode?.purchasedGiftCode?.productRewards?.[0].variant || "",
          id: giftCode?.purchasedGiftCode?.id!,
          owner: {
            username: owner?.username!,
            dateTime: giftCode?.purchasedGiftCode?.createdAt!,
          },
          redeemer: redeemedBy
            ? {
                username: redeemedBy?.username!,
                dateTime: giftCode?.purchasedGiftCode?.redeemedAt!,
              }
            : undefined,
        })) || [],
      count: parseInt(data.count || "0", 10),
    }
  }

  const columns = [
    {
      field: "code",
      name: t("gift-code.table.code"),
    },
    {
      field: "orderId",
      name: t("gift-code.table.order-id"),
      render: (orderId: string) => {
        return (
          <Link
            to={{
              pathname: `/order-management/${orderId}`,
            }}
          >
            {orderId}
          </Link>
        )
      },
    },
    {
      field: "owner",
      name: t("gift-code.table.buyer"),
      sortable: true,
      render: (owner: { username: string; dateTime: string }) => {
        if (!owner) {
          return <div>-</div>
        }

        return (
          <UserOrderDetail dateTime={owner.dateTime} name={owner.username} />
        )
      },
    },
    {
      field: "redeemer",
      name: t("gift-code.table.redeemer"),
      render: (redeemer: { username?: string; dateTime?: string }) => {
        if (!redeemer) {
          return <div>-</div>
        }

        return (
          <UserOrderDetail
            dateTime={redeemer?.dateTime}
            name={redeemer?.username}
          />
        )
      },
    },
    {
      field: "productType",
      name: (
        <HeaderFilter
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          title={t("gift-code.table.product-type")}
        />
      ),
      render: (productType: definitions["shop.v1.ProductType"]) =>
        t(`gift-code.${productType}`),
    },
    {
      field: "used",
      name: t("gift-code.table.status"),
      render: (used: boolean) => {
        return <GiftStatus used={used} />
      },
    },
  ]

  return (
    <div className="grid gap-y-8">
      <div className="flex w-full items-center justify-between">
        <Title text={t("gift-code.title")} />
        <ButtonDownloadOrderManagementReport
          statuses={[
            "ORDER_STATUS_SHOP_PREPARING_ORDER",
            "ORDER_STATUS_WAITING_CONFIRM_ORDER",
            "ORDER_STATUS_SUCCEEDED",
          ]}
          isLoading={loading}
          setLoading={setLoading}
        />
      </div>
      <div className="flex justify-between">
        <EuiFieldText
          icon={<UilSearch />}
          placeholder={t("gift-code.search-placeholder")}
          value={textSearch}
          onChange={(e) => {
            setTextSearch(e.target.value)
          }}
        />
      </div>
      <Table<GiftCodeDetail>
        ref={ref}
        columns={columns}
        sorter={{
          field: "owner",
          direction: "desc",
        }}
        swrKey="/gift-code"
        onGetItems={onGetItems}
        isCanSelect={false}
        filter={{
          textSearch: debounceTextSearch,
          typeFilter,
        }}
      />
    </div>
  )
}
