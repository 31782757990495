import {
  EuiButtonEmpty,
  EuiHorizontalRule,
  EuiLoadingSpinner,
  EuiText,
} from "@elastic/eui"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { mapCouponRedeemStatus, useGetCodeDetail } from "./hook/code-detail"
import { Title } from "@app/component"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { CodeStatusBadge } from "./components/code-status-badge"
import { useGetShop } from "../seller-management/hook"
import { UilShop } from "@iconscout/react-unicons"
import { publicConfig } from "@app/config"

type CouponDetailCardProps =
  definitions["webbff.admin.v1.GetShopCouponRedemptionResponse"]

type ProductDetailCardProps = definitions["shop.admin.v1.EBookAudio"]

export const CodeRedemptionCodeDetailPage = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetCodeDetail(id!)
  const couponDetail = data?.data
  const navigate = useNavigate()
  if (isLoading) {
    return <EuiLoadingSpinner size="xl" />
  }
  return (
    <div className="grid gap-y-6">
      <div>
        <Title
          text={couponDetail?.coupon?.code || ""}
          backBtn={{
            onClick: () => {
              navigate(-1)
            },
          }}
        />
      </div>
      <CouponCard couponDetail={couponDetail!} />

      <CouponProductCard
        product={{
          eBookAudio: couponDetail?.eBookAudio!,
        }}
      />

      <EuiHorizontalRule margin="none" />

      <div>
        <EuiText size="s">
          <h4>{t("noted")}</h4>
          <p>{couponDetail?.campaign?.note || "-"}</p>
        </EuiText>
      </div>
    </div>
  )
}

const CouponCard = ({
  couponDetail,
}: {
  couponDetail: CouponDetailCardProps
}) => {
  const { t } = useTranslation()
  const { data: shop, isLoading } = useGetShop(couponDetail.eBookAudio?.shopId!)
  const { set, coupon, redeemedUsername } = couponDetail
  return (
    <div className="w-full max-w-screen-md rounded-lg border border-solid border-ci-grey-mid p-6">
      <section className="mb-4 flex items-center justify-between">
        {isLoading ? (
          <div className="grid h-16 place-items-center">
            <EuiLoadingSpinner size="xl" />
          </div>
        ) : (
          <div className="flex items-center gap-x-2">
            <img
              src={shop?.shop?.cover?.url || ""}
              alt={shop?.shop?.name || ""}
              className="h-16 w-16 bg-ci-grey-mid"
            />
            <EuiText size="s" className="font-semibold">
              {shop?.shop?.name || "-"}
            </EuiText>
          </div>
        )}
        <a
          href={`${publicConfig.webBaseUri}/shop/${shop?.shop?.id}`}
          target="_blank"
          rel="noreferrer"
        >
          <EuiButtonEmpty
            iconType={UilShop}
            className="border border-solid"
            style={{
              width: 180,
            }}
          >
            {t("code-redemption.go-to-shop")}
          </EuiButtonEmpty>
        </a>
      </section>

      <div>
        <CodeStatusBadge
          status={mapCouponRedeemStatus(
            set?.startAt!,
            set?.endAt!,
            coupon?.redeemedAt,
          )}
        />
      </div>

      <div className="*:mb-2 mt-4 flex w-full flex-col gap-y-2">
        <DetailLabel
          label={t("code-redemption.table.created-at")}
          value={t("common.datetime", {
            date: set?.createdAt,
          })}
        />
        <DetailLabel
          label={t("code-redemption.table.start-at")}
          value={t("common.datetime", { value: set?.startAt })}
        />
        <DetailLabel
          label={t("code-redemption.table.end-at")}
          value={t("common.datetime", { value: set?.endAt })}
        />

        <DetailLabel
          label={t("code-redemption.table.code")}
          value={coupon?.code}
          className="last:text-primary"
        />
        <DetailLabel
          label={t("code-redemption.table.redeem-at")}
          value={
            coupon?.redeemedAt
              ? t("common.datetime", { value: coupon?.redeemedAt })
              : "-"
          }
        />
        <DetailLabel
          label={t("code-redemption.table.expired-day")}
          value={`${set?.dayOfRental} ${t("common.day")}`}
        />
        <DetailLabel
          label={t("code-redemption.table.redeem-by")}
          value={redeemedUsername || "-"}
        />
      </div>
    </div>
  )
}

const DetailLabel = (
  props: { label: string; value?: string } & Partial<HTMLDivElement>,
) => {
  return (
    <div
      className={classNames(
        "flex max-w-xs items-center gap-x-2",
        props.className,
      )}
    >
      <EuiText className="max-w-28 w-full font-semibold" size="s">
        {props.label}
      </EuiText>
      <EuiText className="line-clamp-1 w-full" size="s">
        {props.value}
      </EuiText>
    </div>
  )
}

const CouponProductCard = ({
  product,
}: {
  product: ProductDetailCardProps
}) => {
  return (
    <div className="flex items-center gap-4 gap-x-4">
      <img
        className="h-24 w-20 bg-ci-grey-mid"
        src={product.eBookAudio?.coverUrl}
        alt={product.eBookAudio?.name || "Product image"}
      />
      <div className="min-w-96 flex w-full flex-col gap-y-6">
        <EuiText className="font-semibold">
          {product.eBookAudio?.name || "-"}
        </EuiText>
      </div>
    </div>
  )
}
