import { useStore } from "@app/hook/store"
import { useNavigate } from "react-router-dom"
import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useCallback } from "react"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import * as Yup from "yup"
import {
  fixedMediumBannerPath,
  overflowMediumBannerPath,
} from "../../hook/path"
import { useTranslation } from "react-i18next"

type FixedMediumBannerFormProps =
  definitions["webutility.v1.FixedMediumBannerInput"] & {
    fileInput?: string
  }

type OverflowMediumBannerFormProps =
  definitions["webutility.v1.OverflowMediumBannerInput"] & {
    fileInput?: string
  }

const validationSchema = Yup.object({
  title: Yup.string().required(),
  link: Yup.string().required(),
  metaTag: Yup.string().required(),
  cover: Yup.string().notRequired(),
  fileInput: Yup.string().notRequired(),
})

const validationSchemaWithDate = Yup.object({
  title: Yup.string().required(),
  link: Yup.string().required(),
  metaTag: Yup.string().required(),
  cover: Yup.string().notRequired(),
  fileInput: Yup.string().notRequired(),
  startAt: Yup.string().required(),
  endAt: Yup.string().required(),
})

export const useFormFixedMediumBanner = (
  sectionKey: string,
  bannerKey: string,
) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { webUtility } = useApi()
  const { t } = useTranslation()

  const createMediumBannerApi = webUtility("/admin/fixed-medium-banner")
    .method("post")
    .create()

  const getMediumBannerApi = webUtility(
    "/admin/fixed-medium-banner/{bannerKey}",
  )
    .method("get")
    .create()

  const updateMediumBannerApi = webUtility(
    "/admin/fixed-medium-banner/{bannerKey}",
  )
    .method("put")
    .create()

  const onSubmit = useCallback(
    async (
      input: FixedMediumBannerFormProps,
    ): Promise<{ bannerKey: string | undefined }> => {
      if (bannerKey !== "create") {
        try {
          await updateMediumBannerApi({
            bannerKey,
            sectionKey,
            input: {
              metaTag: input.metaTag,
              cover: input.fileInput,
              link: input.link,
              title: input.title,
            },
          })
          return { bannerKey: undefined }
        } catch (error) {
          throw errToDefaultI18nKey(error)
        }
      }

      if (input.fileInput === undefined) {
        throw new Error(t("home-management.error.banner-cover-missing"))
      }

      const resp = await createMediumBannerApi({
        sectionKey,
        input: {
          metaTag: input.metaTag,
          cover: input.fileInput,
          link: input.link,
          title: input.title,
        },
      })
      return { bannerKey: resp.data.bannerKey }
    },
    [updateMediumBannerApi, createMediumBannerApi, bannerKey, sectionKey],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(fixedMediumBannerPath(sectionKey))
  }

  const onGetItem = async (): Promise<FixedMediumBannerFormProps> => {
    if (bannerKey === "create") {
      return {
        link: "",
        title: "",
        metaTag: "",
      }
    }
    const resp = await getMediumBannerApi({
      sectionKey,
      bannerKey,
    })
    const data =
      resp.data as definitions["webutility.admin.v1.GetFixedMediumBannerResponse"]
    return {
      cover: data.banner!.cover,
      link: data.banner!.link,
      title: data.banner!.title,
      metaTag: data.banner!.metaTag,
    }
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t(errToDefaultI18nKey(err)),
      })
    }
  }

  const form = useFormHelper<
    FixedMediumBannerFormProps,
    { bannerKey?: string }
  >({
    id: bannerKey,
    validationSchema,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}

export type FixedBannerDetailForm = ReturnType<typeof useFormFixedMediumBanner>

export const useFormOverflowMediumBanner = (
  sectionKey: string,
  bannerKey: string,
) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { webUtility } = useApi()
  const { t } = useTranslation()

  const createMediumBannerApi = webUtility("/admin/overflow-medium-banner")
    .method("post")
    .create()

  const getMediumBannerApi = webUtility(
    "/admin/overflow-medium-banner/{bannerKey}",
  )
    .method("get")
    .create()

  const updateMediumBannerApi = webUtility(
    "/admin/overflow-medium-banner/{bannerKey}",
  )
    .method("put")
    .create()

  const onSubmit = useCallback(
    async (
      input: OverflowMediumBannerFormProps,
    ): Promise<{ bannerKey: string | undefined }> => {
      if (bannerKey !== "create") {
        try {
          await updateMediumBannerApi({
            bannerKey,
            sectionKey,
            input: {
              metaTag: input.metaTag,
              cover: input.fileInput,
              link: input.link,
              title: input.title,
              startAt: input.startAt,
              endAt: input.endAt,
            },
          })
          return { bannerKey: undefined }
        } catch (error) {
          throw errToDefaultI18nKey(error)
        }
      }

      if (input.fileInput === undefined) {
        throw new Error(t("home-management.error.banner-cover-missing"))
      }

      const resp = await createMediumBannerApi({
        sectionKey,
        input: {
          metaTag: input.metaTag,
          cover: input.fileInput,
          link: input.link,
          title: input.title,
          startAt: input.startAt,
          endAt: input.endAt,
        },
      })
      return { bannerKey: resp.data.bannerKey }
    },
    [updateMediumBannerApi, createMediumBannerApi, bannerKey, sectionKey],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(overflowMediumBannerPath(sectionKey))
  }

  const onGetItem = async (): Promise<OverflowMediumBannerFormProps> => {
    if (bannerKey !== "create") {
      const resp = await getMediumBannerApi({
        bannerKey,
        sectionKey,
      })

      const data =
        resp.data as definitions["webutility.admin.v1.GetOverflowMediumBannerResponse"]
      return {
        cover: data.banner!.cover,
        link: data.banner!.link,
        title: data.banner!.title,
        metaTag: data.banner!.metaTag,
        startAt: data.banner!.startAt,
        endAt: data.banner!.endAt,
      }
    }
    return {}
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t(errToDefaultI18nKey(err)),
      })
    }
  }

  const form = useFormHelper<
    OverflowMediumBannerFormProps,
    { bannerKey?: string }
  >({
    id: bannerKey,
    validationSchema: validationSchemaWithDate,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}

export type OverflowBannerDetailForm = ReturnType<
  typeof useFormOverflowMediumBanner
>
