import React from "react"
import { NavigationHeaderButton } from "@app/component"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFieldText,
  EuiFormRow,
  EuiText,
} from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { contentType } from "@app/hook/file"
import { ImagePicker } from "../main-menu/image-picker"
import {
  FixedBannerDetailForm,
  OverflowBannerDetailForm,
  useFormFixedMediumBanner,
  useFormOverflowMediumBanner,
} from "./hook"
import moment, { Moment } from "moment"

const BANNER_CONFIG = {
  midBannerContained: {
    width: "620",
    height: "295",
    imageSize: "620x295 px",
    showDuration: false,
  },
  midBannerOverflow: {
    width: "220",
    height: "220",
    imageSize: "400x400 px",
    showDuration: true,
  },
} as const

const BasicFields = ({
  formControl,

  children,
}: {
  formControl: OverflowBannerDetailForm | FixedBannerDetailForm
  children?: React.ReactNode
}) => {
  const { t } = useTranslation()
  return (
    <>
      <EuiFormRow label={t("common.name")}>
        <EuiFieldText
          placeholder={t("common.please-fill")}
          value={formControl.values.title}
          maxLength={25}
          onChange={(e) => formControl.setFieldValue("title", e.target.value)}
          isInvalid={!!formControl.errors.title}
        />
      </EuiFormRow>
      {children}
      <EuiFormRow label={t("common.link")}>
        <EuiFieldText
          placeholder={t("common.please-fill")}
          value={formControl.values.link}
          onChange={(e) => formControl.setFieldValue("link", e.target.value)}
          isInvalid={!!formControl.errors.link}
        />
      </EuiFormRow>
      <EuiFormRow label={t("Meta Tag")}>
        <EuiFieldText
          placeholder={t("common.please-fill")}
          value={formControl.values.metaTag}
          onChange={(e) => formControl.setFieldValue("metaTag", e.target.value)}
          isInvalid={!!formControl.errors.metaTag}
        />
      </EuiFormRow>
    </>
  )
}

const DurationFields = ({
  formControl,
}: {
  formControl: OverflowBannerDetailForm
}) => {
  const { t } = useTranslation()
  return (
    <EuiFormRow label={t("home-management.banner.editor.duration")}>
      <div className="flex w-full items-center gap-x-4">
        <EuiDatePicker
          showTimeSelect
          selected={
            formControl.values.startAt
              ? moment(formControl.values.startAt)
              : undefined
          }
          minDate={moment()}
          minTime={moment().startOf("day")}
          maxTime={moment().endOf("day")}
          onChange={(date: Moment) =>
            formControl.setFieldValue("startAt", date.toDate())
          }
          isInvalid={!!formControl.errors.startAt}
          timeIntervals={10}
        />
        <EuiText size="s">
          <span>{t("common.to")}</span>
        </EuiText>
        <EuiDatePicker
          showTimeSelect
          selected={
            formControl.values.endAt
              ? moment(formControl.values.endAt)
              : undefined
          }
          minDate={moment()}
          minTime={moment().startOf("day")}
          maxTime={moment().endOf("day")}
          onChange={(date: Moment) =>
            formControl.setFieldValue("endAt", date.toDate())
          }
          isInvalid={!!formControl.errors.endAt}
          timeIntervals={10}
        />
      </div>
    </EuiFormRow>
  )
}

const MediumBannerFormBase = ({
  formControl,
  config,
  type,
  navigateBack,
  bannerKey,
}: {
  type: "midBannerOverflow" | "midBannerContained"
  formControl: OverflowBannerDetailForm | FixedBannerDetailForm
  config: (typeof BANNER_CONFIG)[typeof type]
  navigateBack: () => void
  bannerKey: string
}) => {
  const { t } = useTranslation()
  return (
    <>
      <NavigationHeaderButton
        title={
          bannerKey === "create"
            ? t("home-management.banner.editor.title.create")
            : t("home-management.banner.editor.title.edit")
        }
        onClick={navigateBack}
      />
      <div key={type} className="mt-6 flex flex-col gap-y-4">
        <BasicFields formControl={formControl}>
          <ImagePicker
            value={formControl.values.cover}
            error={undefined}
            setLoading={formControl.setSubmitting}
            onChange={async (input, blob) => {
              formControl.setValues({
                ...formControl.values,
                cover: blob,
                fileInput: input.tmpPath,
              })
            }}
            acceptType={`${contentType.JPG},${contentType.PNG}`}
            placeholder={
              <div className="flex flex-col text-center">
                <div>
                  <p>{t("home-management.hero-banner.editor.banner")}</p>
                </div>
                <div>
                  <p className="whitespace-pre-wrap text-center text-sm">
                    {t("home-management.cover-suggestion", {
                      size: config.imageSize,
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            width={config.width}
            height={config.height}
          />
        </BasicFields>

        {config.showDuration && <DurationFields formControl={formControl} />}
      </div>

      <div className="mt-10 flex flex-row">
        <EuiButton
          fill
          fullWidth
          onClick={formControl.submitForm}
          disabled={formControl.isSubmitting}
        >
          {t("common.save")}
        </EuiButton>
        <EuiButtonEmpty
          className="ml-4 w-full items-center"
          color="text"
          onClick={navigateBack}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </>
  )
}

export const OverflowMediumBannerEditorPage = () => {
  const navigate = useNavigate()
  const { bannerKey, sectionKey } = useParams()

  const formControl = useFormOverflowMediumBanner(sectionKey!, bannerKey!)

  const config = BANNER_CONFIG["midBannerOverflow"]

  const navigateBack = () => {
    navigate(-1)
  }

  return (
    <div className="w-full max-w-screen-sm">
      <MediumBannerFormBase
        type="midBannerOverflow"
        formControl={formControl}
        config={config}
        navigateBack={navigateBack}
        bannerKey={bannerKey!}
      />
    </div>
  )
}

export const FixedMediumBannerEditorPage = () => {
  const navigate = useNavigate()
  const { bannerKey, sectionKey } = useParams()

  const formControl = useFormFixedMediumBanner(sectionKey!, bannerKey!)

  const config = BANNER_CONFIG["midBannerContained"]

  const navigateBack = () => {
    navigate(-1)
  }

  return (
    <div className="w-full max-w-screen-sm">
      <MediumBannerFormBase
        type="midBannerContained"
        formControl={formControl}
        config={config}
        navigateBack={navigateBack}
        bannerKey={bannerKey!}
      />
    </div>
  )
}
