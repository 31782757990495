import {
  EuiCheckbox,
  EuiFieldText,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import React from "react"
import { useTranslation } from "react-i18next"
import { LayoutStyleRadio } from "../components/style-radio"

import { useAdditionalSection, useHomeManagementStore } from "../store/ctx"
import { useUpdateNewArrivalChapterProduct } from "./hook/update-detail"
import { useFormSubmit, useInitializeForm } from "../hook/form"
import { LayoutTypes } from "../hook/model"

const maxTitleNameLength = 50

const availableLayouts: LayoutTypes[] = [
  "NEW_ARRIVAL_CHAPTER_PRODUCT_SECTION_LAYOUT_OVERFLOW",
]

export const NewArrivalChapterProductSection = () => {
  const { t } = useTranslation()
  const { selectedId } = useHomeManagementStore()
  const { sections } = useAdditionalSection()
  const form = useUpdateNewArrivalChapterProduct(selectedId!)

  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)

  return (
    <div className="flex flex-col gap-y-6">
      {isSubmitting && (
        <div className="absolute right-2 top-0">
          <EuiLoadingSpinner size="m" />
        </div>
      )}
      <div className="flex flex-col gap-y-2">
        <EuiFormRow
          label={t("home-management.new-arrival-chapter-product.name")}
          helpText={t("home-management.max-length-help-text", {
            current: form.values.title?.length || 0,
            max: maxTitleNameLength,
          })}
        >
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={form.values.title}
            maxLength={maxTitleNameLength}
            isInvalid={!!form.errors.title}
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </EuiFormRow>
        <EuiFormRow className="mt-0">
          <EuiCheckbox
            id="showTitle"
            label={t("home-management.new-arrival-chapter-product.show-title")}
            checked={form.values.shouldShowTitle}
            onChange={(e) => {
              form.setFieldValue("shouldShowTitle", e.target.checked)
            }}
          />
        </EuiFormRow>
      </div>

      <LayoutStyleRadio
        availableLayouts={availableLayouts}
        layout={form.values.layout!}
        onChange={(id) => {
          form.setFieldValue("layout", id)
        }}
      />

      <div>
        <div className="mt-4 flex flex-col gap-2">
          <EuiText size="xs">
            <h3>{t("home-management.new-arrival-chapter-product.noted")}</h3>
          </EuiText>
          <EuiText size="xs">
            <p className="text-eui-subduedText">
              {t("home-management.new-arrival-chapter-product.noted-hint")}
            </p>
          </EuiText>
        </div>

        <EuiTextArea
          className="mt-2"
          fullWidth
          value={form.values.description}
          onChange={(e) => form.setFieldValue("description", e.target.value)}
        />
      </div>
    </div>
  )
}
