import { definitions } from "@app/vendor/order-specs/order_service"
import { EuiBadge } from "@elastic/eui"
import React from "react"
import { useTranslation } from "react-i18next"

export const OrderStatusBadge: React.FC<{
  status?: definitions["order.v1.OrderStatus"]
}> = ({ status }) => {
  const { t } = useTranslation()

  if (!status) {
    return null
  }

  let text = ""
  let color = ""
  if (status === "ORDER_STATUS_PAYMENT_PENDING") {
    color = "#E0E5EE"
    text = t("order-management.status.payment-pending")
  } else if (status === "ORDER_STATUS_WAITING_CONFIRM_ORDER") {
    color = "#FBBE24"
    text = t("order-management.status.waiting-for-confirm")
  } else if (status === "ORDER_STATUS_SHOP_PREPARING_ORDER") {
    color = "#FBBE24"
    text = t("order-management.status.preparing-order")
  } else if (status === "ORDER_STATUS_DELIVERING") {
    color = "#0077CC"
    text = t("order-management.status.delivering")
  } else if (
    ["ORDER_STATUS_DELIVERED", "ORDER_STATUS_SUCCEEDED"].indexOf(status) !== -1
  ) {
    color = "success"
    text = t("order-management.status.delivered")
  } else if (status === "ORDER_STATUS_CANCELLED") {
    color = "#FF7E62"
    text = t("order-management.status.cancelled")
  }

  return <EuiBadge color={color}>{text}</EuiBadge>
}
