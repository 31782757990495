import { VerificationStatus } from "@app/component/withdraw-account/status"
import { useStore } from "@app/hook/store"
import { LIGHTEST_GREY } from "@app/utils/color"
import { definitions } from "@app/vendor/shop-specs/shop_service"
import {
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiImage,
  EuiPagination,
  EuiProgress,
  EuiTab,
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTabs,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilSearch } from "@iconscout/react-unicons"
import classNames from "classnames"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"
import { useDebounce } from "use-debounce"
import { FindShopProps, useShop } from "./hook"
import { useDownloadReport } from "@app/hook/file"
import { useStreamApi } from "@app/hook/api"
import { ShopReportModal } from "./modal-download"
import { hooks } from "@reeeed-mp/ui-common"

const MAX_LIMIT = 10

enum TabID {
  All = "all",
  UnRegistered = "unregistered",
  Verifying = "verifying",
  Verified = "verified",
  Rejected = "rejected",
  Banned = "banned",
}

type Tab = {
  id: string
  name: string
}

export const ListSellerManagement = () => {
  const navigate = useNavigate()
  const { findShop, getAccountStatusCount } = useShop()
  const { t } = useTranslation("translation")

  const { data: accountStatusCount } = useSWR(
    "/account-status-count",
    async () => {
      return await getAccountStatusCount()
    },
  )

  const { query, setQuery } = hooks.useURLQuery()
  const searchText = query.searchText
  const currentPage = query.page ? Number(query.page) - 1 : 0

  const [loading, setLoading] = useState(false)
  const [debouncedSearchText] = useDebounce(searchText, 1000)
  const [data, setData] = useState<{
    shops: definitions["shop.admin.v1.Shop"][]
    count: number
  }>({
    shops: [],
    count: 0,
  })
  const { webBFF } = useStreamApi()
  const { download, loadingPercentage, error } = useDownloadReport(
    webBFF("/admin/report/shop").method("post").create(),
    { autoDownload: true },
  )
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const { notificationStore: notification } = useStore()

  const { notificationStore } = useStore()
  const tabId: TabID = (query.tabId as TabID) || TabID.All
  const getStatusFromTabId = (
    tabId: string,
  ): definitions["shop.v1.WithdrawAccountVerificationStatus"][] | undefined => {
    switch (tabId) {
      case TabID.UnRegistered:
        return ["WITHDRAW_ACCOUNT_VERIFICATION_STATUS_UNREGISTERED"]
      case TabID.Rejected:
        return ["WITHDRAW_ACCOUNT_VERIFICATION_STATUS_REJECTED"]
      case TabID.Verified:
        return ["WITHDRAW_ACCOUNT_VERIFICATION_STATUS_APPROVED"]
      case TabID.Verifying:
        return ["WITHDRAW_ACCOUNT_VERIFICATION_STATUS_VERIFYING"]
      default:
        return undefined
    }
  }

  const TABS: Tab[] = [
    {
      id: TabID.All,
      name: t("seller-management.show-all"),
    },
    {
      id: TabID.UnRegistered,
      name:
        accountStatusCount?.countUnregisteredAccount !== "0"
          ? `${t("seller-management.show-unregistered")} ` +
            `(${accountStatusCount?.countUnregisteredAccount})`
          : t("seller-management.show-unregistered"),
    },
    {
      id: TabID.Verifying,
      name:
        accountStatusCount?.countVerifyingAccount !== "0"
          ? `${t("seller-management.show-verifying")} ` +
            `(${accountStatusCount?.countVerifyingAccount})`
          : t("seller-management.show-verifying"),
    },
    {
      id: TabID.Verified,
      name: t("seller-management.show-verified"),
    },
    {
      id: TabID.Rejected,
      name:
        accountStatusCount?.countRejectedAccount !== "0"
          ? `${t("seller-management.show-rejected")} ` +
            `(${accountStatusCount?.countRejectedAccount})`
          : t("seller-management.show-rejected"),
    },
    {
      id: TabID.Banned,
      name:
        accountStatusCount?.countBannedAccount !== "0"
          ? `${t("seller-management.show-banned")} ` +
            `(${accountStatusCount?.countBannedAccount})`
          : t("seller-management.show-banned"),
    },
  ]

  const renderTab = (t: Tab) => {
    return (
      <EuiTab
        className="mr-5"
        key={t.id}
        onClick={() => {
          findAllShop({
            status: getStatusFromTabId(t.id),
            isBanned: t.id === TabID.Banned ? true : undefined,
            searchText: debouncedSearchText,
            limit: MAX_LIMIT,
            skip: 0,
          }).then(() => {})
          setQuery({
            ...query,
            tabId: t.id,
            page: "1",
          })
        }}
        css={css({
          ".euiTab__content": {
            color: t.id !== tabId ? LIGHTEST_GREY : "",
          },
        })}
        disabled={loading}
        isSelected={t.id === tabId}
      >
        {t.name}
      </EuiTab>
    )
  }

  const findAllShop = useCallback(
    async (input: FindShopProps) => {
      try {
        setLoading(true)
        const res = await findShop(input)
        setData({
          shops: res?.shops ?? [],
          count: parseInt(res?.count ?? "0"),
        })
      } catch (err) {
        if (err instanceof Error) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: err.message,
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [findShop, notificationStore],
  )

  useEffect(() => {
    if (error) {
      notification.add({
        color: "danger",
        title: t("common.noti.error"),
        text: error.message,
      })
    }

    findAllShop({
      status: getStatusFromTabId(tabId),
      isBanned: tabId === TabID.Banned ? true : undefined,
      searchText: debouncedSearchText,
      limit: MAX_LIMIT,
      skip: currentPage * (MAX_LIMIT || 10),
    }).then(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, error, notification])

  const pageCount = Math.ceil(data.count / (MAX_LIMIT || 10))

  return (
    <div>
      <EuiText>
        <h2>{t("seller-management.title")}</h2>
      </EuiText>
      {openDownloadModal && (
        <ShopReportModal
          onClose={() => {
            setOpenDownloadModal(false)
          }}
          onConfirm={(res) => {
            if (res.startDate && res.endDate) {
              download({
                filter: {
                  createdAt: {
                    from: res.startDate.startOf("day").toISOString(),
                    to: res.endDate.endOf("day").toISOString(),
                  },
                },
              })
            } else {
              download()
            }
            setOpenDownloadModal(false)
          }}
        />
      )}
      <div className="my-10 flex flex-col justify-between gap-y-4">
        <div className="flex flex-row justify-between">
          <EuiFormRow className="min-w-[376px]">
            <EuiFieldText
              icon={<UilSearch />}
              placeholder={t("seller-management.seller-search")}
              value={searchText}
              onChange={(e) => {
                setQuery(
                  { ...query, searchText: e.target.value },
                  { replace: true },
                )
              }}
            />
          </EuiFormRow>
          <div>
            <EuiButton
              color="primary"
              fill
              disabled={loadingPercentage !== null}
              onClick={() => {
                setOpenDownloadModal(true)
              }}
            >
              {t("seller-management.report.download-shop-report")}
            </EuiButton>
            {loadingPercentage !== null && (
              <EuiProgress
                size="xs"
                color="primary"
                value={loadingPercentage || 0}
              />
            )}
          </div>
        </div>

        <EuiTabs className="flex">{TABS.map(renderTab)}</EuiTabs>
      </div>
      <EuiTable responsive={false}>
        <EuiTableHeader>
          <EuiTableHeaderCell
            css={css`
              width: 30%;
            `}
          >
            <p className="mb-0">{t("seller-management.shop-name")}</p>
          </EuiTableHeaderCell>
          <EuiTableHeaderCell>
            <p className="mb-0">{t("seller-management.shop-type")}</p>
          </EuiTableHeaderCell>
          <EuiTableHeaderCell>
            <p className="mb-0">{t("seller-management.number-of-product")}</p>
          </EuiTableHeaderCell>
          <EuiTableHeaderCell>
            <p className="mb-0">
              {t("seller-management.withdraw-type-status")}
            </p>
          </EuiTableHeaderCell>
          <EuiTableHeaderCell>
            <p className="mb-0">{t("seller-management.created-at")}</p>
          </EuiTableHeaderCell>
          <EuiTableHeaderCell isSorted={false}>
            <p className="mb-0">{t("seller-management.updated-at")}</p>
          </EuiTableHeaderCell>
        </EuiTableHeader>
        {data?.shops?.map((s) => {
          return (
            <EuiTableRow
              onClick={() => {
                navigate(`/seller-management/${s.id}`)
              }}
              className={s.isBanned ? "bg-eui-disabledBg" : ""}
            >
              <EuiTableRowCell>
                <div className="my-3 flex flex-row items-center justify-center">
                  <EuiImage
                    className="mr-4 h-[52px] w-[52px] rounded-full border border-solid border-grey-light"
                    alt={"book store name"}
                    src={s.cover?.path ?? ""}
                  />
                  <div>
                    <p
                      className={classNames("mb-0", {
                        "text-eui-disableText": s.isBanned,
                      })}
                    >
                      {s.name}
                    </p>
                  </div>
                </div>
              </EuiTableRowCell>
              <EuiTableRowCell>
                <p
                  className={classNames("mb-0", {
                    "text-eui-disableText": s.isBanned,
                  })}
                >
                  {s.withdrawType === "WITHDRAW_TYPE_COMPANY"
                    ? t("seller-management.bank-account-type-partner")
                    : t("seller-management.bank-account-type-standard")}
                </p>
              </EuiTableRowCell>
              <EuiTableRowCell>
                <p
                  className={classNames("mb-0", {
                    "text-eui-disableText": s.isBanned,
                  })}
                >
                  {s.totalNumberOfProduct}
                </p>
              </EuiTableRowCell>
              <EuiTableRowCell>
                <VerificationStatus
                  status={
                    s.withdrawAccountVerificationStatus ??
                    "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_UNSPECIFIED"
                  }
                />
              </EuiTableRowCell>
              <EuiTableRowCell>
                <p
                  className={classNames("mb-0", {
                    "text-eui-disableText": s.isBanned,
                  })}
                >
                  {t("common.datetime", {
                    value: s.createdAt ?? "",
                  })}
                </p>
              </EuiTableRowCell>
              <EuiTableRowCell>
                <p
                  className={classNames("mb-0", {
                    "text-eui-disableText": s.isBanned,
                  })}
                >
                  {t("common.datetime", {
                    value: s.updatedAt ?? "",
                  })}
                </p>
              </EuiTableRowCell>
            </EuiTableRow>
          )
        })}
      </EuiTable>
      <div className="mt-10 flex flex-row justify-end">
        {pageCount > 1 ? (
          <EuiPagination
            css={css`
              .euiPagination__list {
                margin-inline-start: 0px !important;
              }
              .euiPagination__item {
                list-style: none;
              }
            `}
            pageCount={pageCount}
            activePage={currentPage}
            onPageClick={(pageIndex) => {
              findAllShop({
                status: getStatusFromTabId(tabId),
                isBanned: tabId === TabID.Banned ? true : undefined,
                searchText: debouncedSearchText,
                limit: MAX_LIMIT,
                skip: pageIndex * (MAX_LIMIT || 10),
              }).then(() => {})
              setQuery(
                { ...query, tabId, page: String(pageIndex + 1) },
                { replace: true },
              )
            }}
          />
        ) : null}
      </div>
    </div>
  )
}
