import "./modal.css"

import * as React from "react"
import { ReactNode, useEffect, useRef } from "react"
import { createPortal } from "react-dom"

function PortalImpl({
  onClose,
  children,
  title,
  closeOnClickOutside,
}: {
  children: ReactNode
  closeOnClickOutside: boolean
  onClose: () => void
  title: string
}) {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (modalRef.current !== null) {
      modalRef.current.focus()
    }
  }, [])

  useEffect(() => {
    let modalOverlayElement: HTMLElement | null = null
    const handler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose()
      }
    }
    const clickOutsideHandler = (event: MouseEvent) => {
      const target = event.target
      if (
        modalRef.current !== null &&
        !modalRef.current.contains(target as Node) &&
        closeOnClickOutside
      ) {
        onClose()
      }
    }
    const modelElement = modalRef.current
    if (modelElement !== null) {
      modalOverlayElement = modelElement.parentElement
      if (modalOverlayElement !== null) {
        modalOverlayElement.addEventListener("click", clickOutsideHandler)
      }
    }

    window.addEventListener("keydown", handler)

    return () => {
      window.removeEventListener("keydown", handler)
      if (modalOverlayElement !== null) {
        modalOverlayElement?.removeEventListener("click", clickOutsideHandler)
      }
    }
  }, [closeOnClickOutside, onClose])

  return (
    <div className="mpe-modal-overlay" role="dialog">
      <div className="mpe-modal-modal" tabIndex={-1} ref={modalRef}>
        <h2 className="mpe-modal-title">{title}</h2>
        <button
          className="mpe-modal-close-button"
          aria-label="Close modal"
          type="button"
          onClick={onClose}
        >
          X
        </button>
        <div className="mpe-modal-content">{children}</div>
      </div>
    </div>
  )
}

export const Modal: React.FC<{
  children: ReactNode
  closeOnClickOutside?: boolean
  onClose: () => void
  title: string
}> = ({ onClose, children, title, closeOnClickOutside = false }) => {
  return createPortal(
    <PortalImpl
      onClose={onClose}
      title={title}
      closeOnClickOutside={closeOnClickOutside}
    >
      {children}
    </PortalImpl>,
    document.body,
  )
}
