import { Title } from "@app/component"
import { useApi } from "@app/hook/api"
import { priceStrToNum } from "@app/model"
import { definitions } from "@app/vendor/payment-specs/payment_service"
import {
  EuiBadge,
  EuiIcon,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTimeline,
  EuiTimelineItemProps,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { ReeeedCoin, theme } from "@reeeed-mp/ui-common"
import { useTranslation } from "react-i18next"
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import useSWR from "swr"
import { useGetPaymentChannelText } from "./util"

const useGetTopHistory = (id: string) => {
  const { payment } = useApi()
  return useSWR(["/admin/topup", id], async ([, id]) => {
    const res = await payment("/admin/topup/{id}").method("get").create()({
      id,
    })

    return res.data
  })
}

enum Tab {
  DETAIL = "detail",
  LOG = "log",
}

export const TopupHistoryDetail = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [params] = useSearchParams()
  const result = useGetTopHistory(id ?? "")
  const topup = result.data?.topup
  const trackPaymentStatus = result.data?.trackPaymentStatus
  const tab = (params.get("tab") as Tab) ?? Tab.DETAIL
  const getPaymentChannel = useGetPaymentChannelText()

  if (!topup) {
    return <div></div>
  }

  const price =
    priceStrToNum(topup.discount) + priceStrToNum(topup.chargeAmount)
  const netPrice = priceStrToNum(topup.chargeAmount)

  return (
    <div>
      <Title
        text={t("topup-history.editor.title")}
        backBtn={{
          onClick: () => {
            navigate(-1)
          },
        }}
      />
      <EuiTabs
        className="mb-8"
        css={css({
          ".euiTab": {
            ".euiTab__content": {
              color: "#69707d !important",
            },
            "&.euiTab-isSelected": {
              ".euiTab__content": {
                color: `${theme.colors.LIGHT.primaryPurple} !important`,
              },
            },
          },
          "&.euiTabs--bottomBorder": {
            boxShadow: "none",
          },
        })}
        size="l"
      >
        <EuiTab
          isSelected={tab === "detail"}
          onClick={() => {
            navigate({
              pathname: `/topup-history/${id}`,
              search: `?${createSearchParams({
                tab: Tab.DETAIL.toString(),
              })}`,
            })
          }}
        >
          <EuiText size="m" className="font-bold">
            {t(`topup-history.editor.detail`)}
          </EuiText>
        </EuiTab>
        <EuiTab
          isSelected={tab === "log"}
          onClick={() => {
            navigate({
              pathname: `/topup-history/${id}`,
              search: `?${createSearchParams({
                tab: Tab.LOG.toString(),
              })}`,
            })
          }}
        >
          <EuiText size="m" className="font-bold">
            {t(`topup-history.editor.log`)}
          </EuiText>
        </EuiTab>
      </EuiTabs>
      {topup === undefined ? (
        <div />
      ) : tab === Tab.DETAIL ? (
        <div>
          <div className="flex flex-col rounded-md border border-solid border-grey-light p-6">
            <div>
              <EuiBadge
                color={
                  topup.status === "TOPUP_STATUS_SUCCESS"
                    ? "success"
                    : topup.status === "TOPUP_STATUS_PENDING"
                      ? "default"
                      : "danger"
                }
              >
                {t(`topup-history.status.${topup.status}`)}
              </EuiBadge>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <EuiText size="xs" className="mt-4 basis-1/6">
                  <p className="font-bold">{t("topup-history.editor.id")}</p>
                </EuiText>
                <EuiText size="xs" className="mt-4">
                  <p>{topup.id}</p>
                </EuiText>
              </div>
              <div className="flex flex-row items-center">
                <EuiText size="xs" className="mt-4 basis-1/6">
                  <p className="font-bold">
                    {t("topup-history.editor.created-at")}
                  </p>
                </EuiText>
                <EuiText size="xs" className="mt-4">
                  <p>
                    {t("datetime", {
                      value: topup.createdAt,
                    })}
                  </p>
                </EuiText>
              </div>
              <div className="flex flex-row items-center">
                <EuiText size="xs" className="mt-4 basis-1/6">
                  <p className="font-bold">
                    {t("topup-history.editor.payment-channel")}
                  </p>
                </EuiText>
                <EuiText size="xs" className="mt-4">
                  <p>{getPaymentChannel(topup.channel)}</p>
                </EuiText>
              </div>
              <div className="flex flex-row items-center">
                <EuiText size="xs" className="mt-4 basis-1/6">
                  <p className="font-bold">
                    {t("topup-history.editor.payment-id")}
                  </p>
                </EuiText>
                <EuiText size="xs" className="mt-4">
                  <p>{topup.paymentId}</p>
                </EuiText>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <div className="flex flex-row items-center justify-between">
              <EuiText size="m">
                <p className="font-bold">
                  {" "}
                  {t("topup-history.editor.item", {
                    item: 1,
                  })}
                </p>
              </EuiText>
              <EuiText size="xs">
                <p className="font-bold">
                  {t("topup-history.editor.number-of-item", {
                    item: 1,
                  })}
                </p>
              </EuiText>
            </div>
            <div className="my-6 flex flex-col justify-between">
              <div className=" flex flex-row justify-between">
                <div className="flex flex-row items-center gap-x-2">
                  <EuiIcon type={ReeeedCoin} size="m" />
                  <EuiText size="xs">
                    <p>
                      {t("common.coin-amount", {
                        coin: priceStrToNum(topup.getCoin),
                      })}
                    </p>
                  </EuiText>
                </div>
                <EuiText size="xs" className="">
                  <p className="font-bold">
                    {t("topup-history.editor.number-of-item", {
                      item: 1,
                    })}
                  </p>
                </EuiText>
              </div>
              <div className="flex flex-col items-end">
                <EuiText size="xs" className="mt-4">
                  <p>
                    {t("common.price", {
                      value: price,
                    })}
                  </p>
                </EuiText>
              </div>
            </div>
          </div>
          <hr className="my-6" />
          <div className="flex flex-row justify-end">
            <div className="flex flex-col justify-end">
              <div className="mb-4">
                <EuiText size="s">
                  <p className="font-bold">{t("topup-history.editor.total")}</p>
                </EuiText>
                <div />
              </div>
              <div
                className="flex flex-col gap-y-4"
                style={{
                  width: 320,
                }}
              >
                <div className="flex basis-1/2 flex-row justify-between">
                  <EuiText size="s" className="">
                    <p>{t("topup-history.editor.price-summary")}</p>
                  </EuiText>
                  <EuiText size="s" className="font-bold">
                    {t("topup-history.editor.price", {
                      price,
                    })}
                  </EuiText>
                </div>
                <div className="flex flex-row justify-between">
                  <EuiText size="s" className="basis-1/2">
                    <p>{t("topup-history.editor.discount-summary")}</p>
                  </EuiText>
                  <EuiText size="s" className="font-bold">
                    {t("common.price", {
                      value: priceStrToNum(topup.discount),
                    })}
                  </EuiText>
                </div>
                <div className="flex flex-row justify-between">
                  <EuiText size="s" className="basis-1/2">
                    <p>{t("topup-history.editor.coin-summary")}</p>
                  </EuiText>
                  <EuiText size="s" className="font-bold">
                    {t("common.coin-amount", {
                      coin:
                        priceStrToNum(topup.getCoin) -
                        priceStrToNum(topup.extraCoin),
                    })}
                  </EuiText>
                </div>
                <div className="flex flex-row justify-between">
                  <EuiText size="s" className="basis-1/2">
                    <p>{t("topup-history.editor.extra-coin-summary")}</p>
                  </EuiText>
                  <EuiText size="s" className="font-bold">
                    {t("common.coin-amount", {
                      coin: priceStrToNum(topup.extraCoin),
                    })}
                  </EuiText>
                </div>
                <hr />
                <div className="flex flex-row justify-between">
                  <EuiText size="s" className="basis-1/2">
                    <p>{t("topup-history.editor.total-price-summary")}</p>
                  </EuiText>
                  <EuiText size="s" className="font-bold">
                    {t("topup-history.editor.price", {
                      price: netPrice,
                    })}
                  </EuiText>
                </div>
                <div className="flex flex-row justify-between">
                  <EuiText size="s" className="basis-1/2">
                    <p>{t("topup-history.editor.total-coin-summary")}</p>
                  </EuiText>
                  <EuiText size="s" className="font-bold">
                    {t("common.coin-amount", {
                      coin: priceStrToNum(topup.getCoin),
                    })}
                  </EuiText>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Logs topup={topup} trackPaymentStatus={trackPaymentStatus} />
        </div>
      )}
    </div>
  )
}

const SuccessBadge = () => {
  const { t } = useTranslation()
  return (
    <EuiBadge color="success">{t("topup-history.editor.success")}</EuiBadge>
  )
}

const FailedBadge = () => {
  const { t } = useTranslation()
  return <EuiBadge color="danger">{t("topup-history.editor.failed")}</EuiBadge>
}

const Logs = ({
  topup,
  trackPaymentStatus,
}: {
  topup: definitions["payment.admin.v1.Topup"]
  trackPaymentStatus?: definitions["payment.v1.TrackPaymentStatus"][]
}) => {
  const { t } = useTranslation()

  const timelines: EuiTimelineItemProps[] = [
    {
      icon: "documentEdit",
      children: (
        <div>
          <EuiText>
            <p className="text-xs text-eui-darkShade">
              {t("common.datetime", {
                value: topup.createdAt,
              })}
            </p>
          </EuiText>
          <EuiText className="mt-2 font-bold">
            <p>{t("topup-history.editor.log-start")}</p>
          </EuiText>
        </div>
      ),
    },
  ]

  if (trackPaymentStatus && trackPaymentStatus?.length > 0) {
    for (let i = 0; i < trackPaymentStatus.length; i++) {
      const track = trackPaymentStatus[i]
      if (track.to === "PAYMENT_STATUS_SUCCEEDED") {
        timelines.push({
          icon: "documentEdit",
          children: (
            <div>
              <div className="flex flex-row items-center gap-x-2">
                <EuiText>
                  <p className="text-xs text-eui-darkShade">
                    {t("common.datetime", {
                      value: track.createdAt,
                    })}
                  </p>
                </EuiText>
                <SuccessBadge />
              </div>
              <EuiText className="mt-2 font-bold">
                <p>{t("topup-history.editor.log-success")}</p>
              </EuiText>
            </div>
          ),
        })
      }

      if (track.to === "PAYMENT_STATUS_CANCEL") {
        timelines.push({
          icon: "documentEdit",
          children: (
            <div>
              <div className="flex flex-row items-center gap-x-2">
                <EuiText>
                  <p className="text-xs text-eui-darkShade">
                    {t("common.datetime", {
                      value: track.createdAt,
                    })}
                  </p>
                </EuiText>
                <FailedBadge />
              </div>
              <EuiText className="mt-2 font-bold">
                <p>{t("topup-history.editor.log-cancel")}</p>
              </EuiText>
            </div>
          ),
        })
      }

      if (track.to === "PAYMENT_STATUS_FAILED") {
        timelines.push({
          icon: "documentEdit",
          children: (
            <div>
              <div className="flex flex-row items-center gap-x-2">
                <EuiText>
                  <p className="text-xs text-eui-darkShade">
                    {t("common.datetime", {
                      value: track.createdAt,
                    })}
                  </p>
                </EuiText>
                <FailedBadge />
              </div>
              <EuiText className="mt-2 font-bold">
                <p>{t("topup-history.editor.log-fail")}</p>
              </EuiText>
            </div>
          ),
        })
      }
    }
  }

  return <EuiTimeline items={timelines} />
}
