import { useApi } from "@app/hook/api"
import { useSearchParams } from "react-router-dom"
import { Filter } from "@reeeed-mp/ui-common"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useState } from "react"

type BannerPublishState = definitions["webutility.v1.MediumBannerState"]

export const useGetMediumBanner = (sectionKey: string) => {
  const searchParamKey = "textSearch"
  const publishStatusParamKey = "publishStatus"
  const [searchParams] = useSearchParams()
  const [textSearch, setTextSearch] = useState(
    searchParams.get(searchParamKey) || "",
  )
  const { webUtility } = useApi()

  const [publishStatus, setPublishStatus] = useState<BannerPublishState>(
    (searchParams.get(publishStatusParamKey) as BannerPublishState) ||
      "MEDIUM_BANNER_STATE_ACTIVE",
  )

  const getFixedMediumBannerApi = webUtility("/admin/fixed-medium-banner")
    .method("get")
    .create()

  const getOverflowMediumBannerApi = webUtility("/admin/overflow-medium-banner")
    .method("get")
    .create()

  const getFixedMediumBanner = async () => {
    try {
      const resp = await getFixedMediumBannerApi({
        sectionKey,
      })

      return resp.data
    } catch (e) {
      return { banners: [] }
    }
  }

  const getOverflowMediumBanner = async ({ filter, opts }: Filter) => {
    try {
      const resp = await getOverflowMediumBannerApi({
        sectionKey,
        "filter.state": filter.state,
        "filter.name": filter?.textSearch,
        "pagination.limit": opts?.limit || 6,
        "pagination.skip": opts?.skip || 0,
      })

      return resp.data
    } catch (e) {
      return { banners: [] }
    }
  }

  const getSmallBannerApi = webUtility("/admin/small-banner")
    .method("get")
    .create()

  const getSmallBanner = async ({ filter, opts }: Filter) => {
    const resp = await getSmallBannerApi({
      sectionKey,
      "filter.name": filter?.textSearch,
      "pagination.limit": opts?.limit || 6,
      "pagination.skip": opts?.skip || 0,
    })

    return resp.data
  }

  return {
    getSmallBanner,
    getFixedMediumBanner,
    getOverflowMediumBanner,
    textSearch,
    setTextSearch,
    publishStatus,
    setPublishStatus,
  }
}
