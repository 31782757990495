import { useApi } from "@app/hook/api"
import { Filter } from "@reeeed-mp/ui-common"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useDebouncedCallback } from "use-debounce"
import { CodeStatus } from "./code-detail"

export type CodeRedemptionIndivCodeItem = {
  id: string
  productName: string
  variant: string
  code: string
  status: CodeStatus
  startAt: string
  endAt: string
  dayOfRental: string
  createdAt: string
  note: string
}

export type CodeRedemptionCampaignItem = {
  id: string
  name: string
  couponCount: string
  productCount: string
  createdAt: string
}

export const useGetCodeRedemptionCodeList = () => {
  const searchParamKey = "search"
  const [searchParams] = useSearchParams()
  const textSearch = searchParams.get(searchParamKey)
  const { webBff } = useApi()

  const finder = webBff("/admin/list-shop-coupon-redemption")
    .method("get")
    .create()

  const [filter, setFilter] = useState<Filter>({
    textSearch: textSearch || "",
  })

  const setTextSearch = useDebouncedCallback((s: string) => {
    setFilter((prev) => ({ ...prev, textSearch: s }))
  }, 200)

  const find = async ({ filter, opts }: Filter) => {
    const { data } = await finder({
      "option.limit": opts.limit,
      "option.skip": opts.skip,
      "filter.codeSearch": filter.textSearch || undefined,
      sorter: filter.sorter || "SHOP_COUPON_REDEMPTION_SORTER_CREATED_AT_ASC",
    })

    return data
  }

  return {
    find,
    filter,
    setTextSearch,
  }
}
