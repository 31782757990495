import { SmallModal } from "@app/component/modal/small"
import { useStreamApi } from "@app/hook/api"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { useDownloadReport } from "@app/hook/file"
import { definitions } from "@app/vendor/order-specs/order_service"
import {
  EuiButton,
  EuiDatePicker,
  EuiProgress,
  EuiRadioGroup,
  EuiText,
} from "@elastic/eui"
import moment, { Moment } from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

type BtnProps = {
  onClick?: () => void
  isLoading?: boolean
  disabled?: boolean
  style?: React.CSSProperties | undefined
} & React.PropsWithChildren

type ButtonSellerActionProps = {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
} & Omit<BtnProps, "children">

type ButtonDownloadOrderManagementReportProps = {
  statuses: definitions["order.v1.OrderStatus"][]
} & ButtonSellerActionProps

export const ButtonDownloadOrderManagementReport: React.FC<
  ButtonDownloadOrderManagementReportProps
> = ({ statuses }) => {
  const { t } = useTranslation()
  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false)

  return (
    <>
      <SellerOrderReportDownloadModal
        open={showDownloadReportModal}
        statuses={statuses}
        onClose={() => {
          setShowDownloadReportModal(false)
        }}
      />
      <EuiButton
        fill
        iconType="document"
        onClick={() => setShowDownloadReportModal(true)}
      >
        {t("gift-code.btn.download-report")}
      </EuiButton>
    </>
  )
}

type SellerOrderReportDownloadModalProps = {
  open: boolean
  statuses: definitions["order.v1.OrderStatus"][]
  onClose: () => void
}

const dateRange = 31

const SellerOrderReportDownloadModal: React.FC<
  SellerOrderReportDownloadModalProps
> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined)
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined)
  const [minDateFrom, setMinDateFrom] = useState<Moment | undefined>(undefined)
  const [maxDateFrom, setMaxDateFrom] = useState<Moment | undefined>(undefined)
  const [minDateTo, setMinDateTo] = useState<Moment | undefined>(undefined)
  const [maxDateTo, setMaxDateTo] = useState<Moment | undefined>(undefined)
  const [reportType, setReportType] = useState<
    "REPORT_TYPE_REDEEMED_AT" | "REPORT_TYPE_CREATED_AT"
  >("REPORT_TYPE_REDEEMED_AT")

  const { webBFF } = useStreamApi()

  const {
    download,
    loadingPercentage,
    error: downloadReportError,
  } = useDownloadReport(
    webBFF("/admin/report/gift-code").method("get").create(),
    { autoDownload: true },
  )

  useAlertIfDefaultErr([downloadReportError])

  return (
    <SmallModal
      loading={loadingPercentage != null}
      css={{ zIndex: 1000 }}
      onConfirm={() => {
        if (startDate && endDate)
          download({
            "filter.used":
              reportType === "REPORT_TYPE_REDEEMED_AT" ? true : undefined,
            "filter.createdWithinRange.from":
              reportType === "REPORT_TYPE_CREATED_AT"
                ? startDate.format("YYYY-MM-DD")
                : undefined,
            "filter.createdWithinRange.to":
              reportType === "REPORT_TYPE_CREATED_AT"
                ? endDate.format("YYYY-MM-DD")
                : undefined,
            "filter.redeemedWithinRange.from":
              reportType === "REPORT_TYPE_REDEEMED_AT"
                ? startDate.format("YYYY-MM-DD")
                : undefined,
            "filter.redeemedWithinRange.to":
              reportType === "REPORT_TYPE_REDEEMED_AT"
                ? endDate.format("YYYY-MM-DD")
                : undefined,
          })
      }}
      confirmBtnText={t("gift-code.btn.download-report")}
      onCancel={() => {
        onClose()
        setStartDate(undefined)
        setEndDate(undefined)
      }}
      cancelBtnText={t("common.cancel")}
      header={t("gift-code.modal.report.header")}
      disabledConfirmBtn={!startDate || !endDate}
      body={
        <div className="flex flex-col gap-2">
          <EuiRadioGroup
            options={[
              {
                id: "REPORT_TYPE_REDEEMED_AT",
                label: t("gift-code.modal.report.redeemed-at"),
              },
              {
                id: "REPORT_TYPE_CREATED_AT",
                label: t("gift-code.modal.report.created-at"),
              },
            ]}
            idSelected={reportType}
            onChange={(e) => {
              setReportType(e as typeof reportType)
            }}
          />
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-y-1">
              <EuiText>
                <p>{t("gift-code.modal.report.from")}</p>
              </EuiText>
              <EuiDatePicker
                selected={startDate}
                minDate={minDateFrom}
                maxDate={maxDateFrom}
                onChange={(date) => {
                  if (date) {
                    setStartDate(date)
                    setMinDateTo(date)
                    setMaxDateTo(moment(date).add(dateRange, "d"))
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <EuiText>
                <p>{t("gift-code.modal.report.to")}</p>
              </EuiText>
              <EuiDatePicker
                selected={endDate}
                minDate={minDateTo}
                maxDate={maxDateTo}
                onChange={(date) => {
                  if (date) {
                    setEndDate(date)
                    setMinDateFrom(moment(date).add(-dateRange, "d"))
                    setMaxDateFrom(date)
                  }
                }}
              />
            </div>
          </div>
          {loadingPercentage != null && (
            <EuiProgress value={loadingPercentage} max={100} />
          )}
        </div>
      }
      isOpen={open}
    />
  )
}
