import React from "react"
import {
  EuiButton,
  EuiButtonIcon,
  EuiFieldText,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSplitPanel,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { UilAngleRight, UilCog } from "@iconscout/react-unicons"
import { LayoutStyleRadio } from "../components/style-radio"
import { BannerDetailProps } from "./hook/hero-banner"
import { useNavigate } from "react-router-dom"
import { useUpdateHeroBanner } from "./hook/update-detail"
import { useHomeManagementStore } from "../store/ctx"
import { LayoutTypes } from "../hook/model"
import { useFormSubmit, useInitializeForm } from "../hook/form"
import { heroBannerPath } from "../hook/path"
import { ImagePreview } from "../components/banner-common"

const MenuPanel = (
  props: BannerDetailProps["banner"][number] & {
    className?: string
    onClick?: () => void
  },
) => {
  const { t } = useTranslation()
  return (
    <EuiPanel
      hasShadow={false}
      hasBorder
      className={classNames(
        "flex h-20 items-center justify-between rounded-md border border-solid px-4 py-2",
        props.className,
      )}
    >
      <EuiPanel
        paddingSize="s"
        aria-label="Drag Handle"
        hasShadow={false}
        color="transparent"
        className="flex items-center gap-x-2"
      >
        <ImagePreview src={props.cover} alt={props.name} />
        <div>
          <EuiText className="mb-2 font-bold">{props.name}</EuiText>
          {props.duration && (
            <EuiText size="xs">
              {t("date-range", {
                value: props.duration,
              })}
            </EuiText>
          )}
        </div>
      </EuiPanel>
      <div className="ml-auto flex items-center gap-x-2">
        <EuiButtonIcon
          iconType={() => <UilAngleRight width={20} height={20} />}
          onClick={props.onClick}
          size="m"
          aria-label="Edit"
        />
      </div>
    </EuiPanel>
  )
}
const HeroBannerList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { heroBannerSection } = useHomeManagementStore()
  const activeHeroBanner = heroBannerSection?.activeBanners
  const pendingHeroBanner = heroBannerSection?.pendingBanners

  if (!activeHeroBanner || !pendingHeroBanner) {
    return (
      <div className="grid h-40 w-full place-items-center">
        <EuiLoadingSpinner size="xl" />
      </div>
    )
  }

  return (
    <>
      <EuiSplitPanel.Outer hasShadow={false} hasBorder>
        <EuiSplitPanel.Inner color="subdued" grow={false}>
          <EuiText className="font-bold">
            {t("home-management.hero-banner.banner-list")}
          </EuiText>
        </EuiSplitPanel.Inner>
        <EuiSplitPanel.Inner grow>
          <div className="flex flex-col gap-y-6 py-6">
            <EuiButton
              className="w-56"
              iconType={UilCog}
              onClick={() => {
                navigate(heroBannerPath())
              }}
            >
              {t("home-management.hero-banner.manage-button")}
            </EuiButton>
            <div className="flex flex-col gap-y-2">
              <EuiText size="s" className="font-bold">
                {t("home-management.hero-banner.active-count", {
                  count: activeHeroBanner.length || 0,
                })}
              </EuiText>
              <div className="flex flex-col gap-y-4">
                {activeHeroBanner?.map((heroBanner) => (
                  <MenuPanel
                    key={heroBanner.id}
                    link={heroBanner.link!}
                    id={heroBanner.id!}
                    cover={heroBanner.cover!}
                    name={heroBanner.title!}
                    duration={{
                      startAt: heroBanner.startAt!,
                      endAt: heroBanner.endAt!,
                    }}
                    onClick={() => navigate(heroBannerPath(heroBanner.key))}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-y-2">
              <EuiText size="s" className="font-bold">
                {t("home-management.hero-banner.pending-count", {
                  count: pendingHeroBanner.length || 0,
                })}
              </EuiText>
              <div className="flex flex-col gap-y-4">
                {pendingHeroBanner?.map((heroBanner) => (
                  <MenuPanel
                    key={heroBanner.id}
                    link={heroBanner.link!}
                    id={heroBanner.id!}
                    cover={heroBanner.cover!}
                    name={heroBanner.title!}
                    duration={{
                      startAt: heroBanner.startAt!,
                      endAt: heroBanner.endAt!,
                    }}
                    onClick={() => navigate(heroBannerPath(heroBanner.key))}
                  />
                ))}
              </div>
            </div>
          </div>
        </EuiSplitPanel.Inner>
      </EuiSplitPanel.Outer>
    </>
  )
}

export const HeroBannerConfigSection = () => {
  const { t } = useTranslation()
  const { heroBannerSection } = useHomeManagementStore()
  const form = useUpdateHeroBanner(heroBannerSection?.sectionKey || "")

  useInitializeForm(
    form,
    {
      title: heroBannerSection?.heroBannerSection?.title,
      description: heroBannerSection?.heroBannerSection?.description,
      layout: heroBannerSection?.heroBannerSection?.layout,
      sectionKey: heroBannerSection?.sectionKey,
    },
    heroBannerSection?.sectionKey!,
  )

  const isSubmitting = useFormSubmit(form, {
    title: heroBannerSection?.heroBannerSection?.title,
    description: heroBannerSection?.heroBannerSection?.description,
    layout: heroBannerSection?.heroBannerSection?.layout,
    sectionKey: heroBannerSection?.sectionKey,
  })

  return (
    <div className="flex flex-col gap-y-6">
      {isSubmitting && (
        <div className="absolute right-2 top-0">
          <EuiLoadingSpinner size="m" />
        </div>
      )}

      <EuiFormRow
        label={t("home-management.hero-banner.name")}
        helpText={t("home-management.max-length-help-text", {
          current: form.values.title?.length,
          max: 50,
        })}
      >
        <EuiFieldText
          placeholder={t("common.please-fill")}
          value={form.values.title}
          maxLength={50}
          isInvalid={form.values.title?.length === 0}
          onChange={(e) => form.setFieldValue("title", e.target.value)}
        />
      </EuiFormRow>

      <LayoutStyleRadio
        availableLayouts={["HERO_BANNER_LAYOUT_FULL_SCREEN"]}
        layout={form.values.layout as LayoutTypes}
        onChange={(layout) => form.setFieldValue("layout", layout)}
      />
      <HeroBannerList />
      <div>
        <div className="mt-4 flex flex-col gap-2">
          <EuiText size="xs">
            <h3>{t("home-management.main-menu.noted")}</h3>
          </EuiText>
          <EuiText size="xs">
            <p className="text-eui-subduedText">
              {t("home-management.main-menu.noted-hint")}
            </p>
          </EuiText>
        </div>

        <EuiTextArea
          className="mt-2"
          value={form.values.description}
          onChange={(e) => form.setFieldValue("description", e.target.value)}
          fullWidth
        />
      </div>
    </div>
  )
}
