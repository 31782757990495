import { AuthStore } from "./auth"
import { NotificationStore } from "./notification"

export class RootStore {
  authStore: AuthStore
  notificationStore: NotificationStore

  constructor() {
    this.authStore = new AuthStore()
    this.notificationStore = new NotificationStore()
  }
}
