import { constants } from "@app/model/constants"
import {
  MpTextEditor,
  parseEditorState,
  stringifiedEmptyEditorState,
} from "@reeeed-mp/text-editor"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { HideProductButton } from "./hide-product-button"
import { useGetEBookAudio, useSetBanEBookAudio } from "./hook"
import { ProductHiddenBadge } from "./product-hidden-badge"
import { TitleAndButtonGroup } from "./product-title-button"
import { RevenueBox } from "./revenue-box"

export const EBookAudioManagement = () => {
  const { t } = useTranslation()
  const { shopId, productId } = useParams()

  const shopProduct = useGetEBookAudio(shopId ?? "", productId ?? "")
  const ebook = shopProduct.data?.eBookAudio
  const isBanned = ebook?.eBookAudio?.eBookAudio?.isBanned
  const isHidden = ebook?.eBookAudio?.eBookAudio?.isHidden
  const { setBan } = useSetBanEBookAudio()

  const setBanEBookAudio = async () => {
    await setBan(productId ?? "", !isBanned)
    shopProduct.mutate()
  }

  return (
    <div className="flex h-full w-full flex-col space-y-4 px-4">
      <div className="flex flex-row justify-between">
        <TitleAndButtonGroup />
        <HideProductButton onClick={setBanEBookAudio} isHide={isBanned} />
      </div>
      <div className="flex w-full flex-row divide-x divide-y-0 divide-solid divide-slate-300 rounded-md border border-solid border-slate-300 py-3">
        <RevenueBox
          title={t("product.revenue-header")}
          text={t("product.revenue", {
            revenue: Number(ebook?.revenue) / constants.power10OfDecimalPrice,
          })}
        />
        <RevenueBox
          title={t("product.e-book-sell-count")}
          text={t("product.buy-count", {
            buyCount: ebook?.eBookBuyCount,
          })}
          gift={t("product.buy-count", {
            buyCount: ebook?.eBookBuyAsGiftCount,
          })}
          displayIcon
        />
        <RevenueBox
          title={t("product.audio-sell-count")}
          text={t("product.buy-count", {
            buyCount: ebook?.audioBuyCount,
          })}
          gift={t("product.buy-count", {
            buyCount: ebook?.audioBuyAsGiftCount,
          })}
          displayIcon
        />
      </div>
      <div className="flex w-full flex-row gap-4 pt-6">
        <div className="w-96">
          {ebook?.eBookAudio?.coverUrl ? (
            <img
              src={ebook?.eBookAudio?.coverUrl}
              alt=""
              className="w-60 rounded-lg"
            />
          ) : (
            <div className="h-96 w-60 rounded-lg bg-slate-200"></div>
          )}
        </div>
        <div className="w-full">
          <div className="flex flex-row">
            <div className="w-2/3 text-xl font-semibold">
              {ebook?.eBookAudio?.eBookAudio?.name}
            </div>
            <div className="w-1/3 justify-items-end">
              <ProductHiddenBadge isHidden={isHidden} />
            </div>
          </div>

          {ebook?.eBookAudio?.eBookAudio?.tags?.length ? (
            <div className="mt-4 flex flex-wrap text-base">
              {(ebook?.eBookAudio?.eBookAudio?.tags ?? []).map((tag) => (
                <div className="mb-2 mr-2 rounded-md bg-slate-200 px-2 py-1">
                  {tag}
                </div>
              ))}
            </div>
          ) : null}
          <div className="mt-4 flex flex-row text-base">
            <div className="w-1/2">
              <div className="font-semibold">{t("product.author")}</div>
              <div className="mt-2 text-gray-600">
                {ebook?.eBookAudio?.eBookAudio?.author
                  ? ebook?.eBookAudio?.eBookAudio?.author
                  : "-"}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-semibold">{t("product.translator")}</div>
              <div className="mt-2 text-gray-600">
                {ebook?.eBookAudio?.eBookAudio?.translator
                  ? ebook?.eBookAudio?.eBookAudio?.translator
                  : "-"}
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row text-base">
            <div className="w-1/2">
              <div className="font-semibold">{t("product.category")}</div>
              <div className="mt-2 text-gray-600">
                {(ebook?.eBookAudio?.categories ?? []).map(
                  (category, index) => (
                    <span>
                      {index === 0 ? category?.name : `, ${category?.name}`}
                    </span>
                  ),
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-semibold">{t("product.rating")}</div>
              <div className="mt-2 text-gray-600">
                {t(
                  `product.${ebook?.eBookAudio?.eBookAudio?.rating ?? "CONTENT_RATING_UNSPECIFIED"}`,
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row text-base">
            <div className="w-1/3">
              <div className="font-semibold">{t("product.price-header")}</div>

              {ebook?.eBookAudio?.eBookAudio?.eBook && (
                <div className="mt-2 flex flex-row text-gray-600">
                  <div className="w-1/2">
                    {t("product.e-book-price-header")}
                  </div>
                  <div className="w-1/2 text-end">
                    {ebook?.eBookAudio?.eBookAudio?.eBook?.price
                      ? t("product.e-book-sell-price", {
                          price: ebook?.eBookAudio?.eBookAudio?.eBook?.price
                            ? Number(
                                ebook?.eBookAudio?.eBookAudio?.eBook?.price,
                              ) / constants.power10OfDecimalPrice
                            : 0,
                        })
                      : t("product.e-book-audio-none-price")}
                    {ebook?.eBookAudio?.eBookAudio?.eBook?.rental
                      ? t("product.e-book-rent-price", {
                          price: ebook?.eBookAudio?.eBookAudio?.eBook?.rental
                            ? Number(
                                ebook?.eBookAudio?.eBookAudio?.eBook?.rental
                                  ?.price,
                              ) / constants.power10OfDecimalPrice
                            : 0,
                        })
                      : t("product.e-book-audio-none-rent-price")}
                  </div>
                </div>
              )}

              {ebook?.eBookAudio?.eBookAudio?.audio && (
                <div className="mt-2 flex flex-row text-gray-600">
                  <div className="w-1/2">{t("product.audio-price-header")}</div>
                  <div className="w-1/2 text-end">
                    {ebook?.eBookAudio?.eBookAudio?.audio?.price
                      ? t("product.audio-sell-price", {
                          price: ebook?.eBookAudio?.eBookAudio?.audio?.price
                            ? Number(
                                ebook?.eBookAudio?.eBookAudio?.audio?.price,
                              ) / constants.power10OfDecimalPrice
                            : 0,
                        })
                      : t("product.e-book-audio-none-price")}
                    {ebook?.eBookAudio?.eBookAudio?.audio?.rental
                      ? t("product.audio-rent-price", {
                          price: ebook?.eBookAudio?.eBookAudio?.audio?.rental
                            ? Number(
                                ebook?.eBookAudio?.eBookAudio?.audio?.rental
                                  ?.price,
                              ) / constants.power10OfDecimalPrice
                            : 0,
                        })
                      : t("product.e-book-audio-none-rent-price")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-6">
        <div className="mb-2 text-xl font-semibold">
          {t("product.introduction")}
        </div>
        <MpTextEditor
          isEditable={false}
          initEditorState={parseEditorState(
            ebook?.eBookAudio?.eBookAudio?.intro ?? stringifiedEmptyEditorState,
          )}
        />
      </div>
    </div>
  )
}
