import { UploadItemSection } from "@app/component/upload-and-render"
import { publicConfig } from "@app/config"
import { useAlertDefaultErr, useAlertIfDefaultErr } from "@app/hook/error"
import { useAvailableFeature } from "@app/hook/feature"
import { useUnMount } from "@app/hook/life-cycle"
import { useStore } from "@app/hook/store"
import { useGetManyUsersWithEmail, useGetUser } from "@app/hook/user"
import { sleep } from "@app/lib/time"
import {
  PaymentChannel,
  paymentTypeJsonToModel,
  paymentTypeModelToJson,
} from "@app/model/payment"
import {
  extendProductsToProductsTable,
  mappingProductTypeInputToShopProductType,
  mappingShopProductTypeToProductTypeInput,
  ProductKeyInputXlsx,
  ProductTable,
  ProductTypeInput,
} from "@app/model/product-table"
import { formatPromotionCode } from "@app/model/promotion-code"
import { datePickerDateFormat, datePickerTimeFormat } from "@app/model/time"
import {
  Divider,
  FieldNumberWithUnit,
  isAlreadyInCollectingPeriod,
  RadioYesNo,
  SummaryPromotionCard,
  TextArea,
} from "@app/page/coupon-promotion/component"
import { useGetHighlightProductApi } from "@app/page/home-management/hook/homepage"
import {
  EuiBasicTableColumn,
  EuiButton,
  EuiCheckbox,
  EuiCheckboxGroup,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFieldNumber,
  EuiFieldText,
  EuiFormRow,
  EuiIcon,
  EuiRadioGroup,
  EuiSelect,
  EuiText,
  EuiToolTip,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilGift, UilTruck } from "@iconscout/react-unicons"
import {
  appErrorToDefaultI18nKey,
  ConditionSelectedSection,
  errToAppErr,
  hooks,
  theme,
  useFormHelper,
} from "@reeeed-mp/ui-common"
import { cloneDeep, isEmpty, uniqWith } from "lodash-es"
import moment from "moment"
import React, { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import useSWR from "swr"
import { read as readXLSX, utils as utilsXLSX, writeFile } from "xlsx"
import * as Yup from "yup"
import { ExamplePromotionDetailRenderer } from "./example-promotion-detail"
import {
  GetAllShopResponse,
  GetCategoriesInPromotionResponse,
  PlatformCouponCartPromotionSetup,
  UpdateProductInPromotionInput,
  useCreatePromotion,
  useFindAllCategory,
  useGetAllShop,
  useGetAllUserInPromotion,
  useGetCategoriesInPromotion,
  useGetProductInPromotion,
  useGetPromotion,
  useGetShopsInPromotion,
  UserInPromotion,
  useSetUserInPromotion,
  useUpdateProductInPromotion,
  useUpdatePromotion,
} from "./hook/promotion"
import {
  ConditionType,
  FormPromotion,
  ProductForExport,
  ProductParticipate,
} from "./types"
import { strPriceToStrTenPowerPrice, tenPowerPriceToStrPrice } from "./utils"
import { CategorySelectSection } from "../component/condition-selected-section"

moment.locale("en")

const paginationSize = 20
const selectedUserPaginationSize = 10

const formValidator = Yup.object({
  platformCouponPromotion: Yup.object({
    name: Yup.string().required(),
    code: Yup.string().required(),
    startAt: Yup.date().required(),
    endAt: Yup.date().required(),
  }),
  discount: Yup.number().required().min(0),
  minNetPrice: Yup.number().required().min(0),
  platformSubsidizePercent: Yup.number().required().min(0),
})

export const PromotionEditor: React.FC = () => {
  const { t } = useTranslation()
  const { notificationStore: notification } = useStore()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const getPromotion = useGetPromotion()
  const createPromotion = useCreatePromotion()
  const updatePromotion = useUpdatePromotion()
  const getAllShop = useGetAllShop()
  const { get: getHighlightProduct } = useGetHighlightProductApi()
  const updateProductInPromotion = useUpdateProductInPromotion()
  const setSelectedUsersAPI = useSetUserInPromotion()
  const getAllSelectedUsersAPI = useGetAllUserInPromotion()
  const getProductInPromotion = useGetProductInPromotion()
  const getShopsInPromotion = useGetShopsInPromotion()
  const getCategoriesInPromotion = useGetCategoriesInPromotion()
  const alertDefaultErr = useAlertDefaultErr()
  const feature = useAvailableFeature()
  const { isDirty, setIsDirty } = hooks.useConfirmLeaveWithoutSaved(
    t("common.confirm-not-save-data"),
  )
  useUnMount(async () => {
    await setIsDirty(false)
  })

  const reNewFromId = searchParams.get("renew")

  const [discountType, setDiscountType] = useState<
    "product" | "delivery" | "gift"
  >("product")

  const { data: categories } = useFindAllCategory("")
  const { data: eBookAudioCategories } = useFindAllCategory("gift")

  const [selectedChannelTypes, setSelectedChannelTypes] = useState<
    EuiComboBoxOptionOption<{ label: string; key: PaymentChannel }>[]
  >([])

  const [allShop, setAllShop] = useState<GetAllShopResponse>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [productParticipate, setProductParticipate] = useState<{
    [key in ProductParticipate]: boolean
  }>({
    "participate-ebook": false,
    "participate-audio-book": false,
    "participate-product": feature.eBookAudio ? false : true,
  })
  const [fileNameUpload, setFileNameUpload] = useState<string>(
    "product-in-promotion.xlsx",
  )

  const [
    selectedCategoryConditionOptions,
    setSelectedCategoryConditionOptions,
  ] = useState<EuiComboBoxOptionOption<string>[]>([])

  const [selectedShopConditionOptions, setSelectedShopConditionOptions] =
    useState<EuiComboBoxOptionOption<string>[]>([])

  const [checkboxCategory, setCheckboxCategory] = useState<{
    [key: string]: boolean
  }>({})
  const [checkboxShop, setCheckboxShop] = useState<{
    [key: string]: boolean
  }>({})

  const [pageProductIndex, setPageProductIndex] = useState<number>(0)
  const [productsForRender, setProductsForRender] = useState<ProductTable[]>()
  const [allProducts, setAllProducts] = useState<ProductTable[] | undefined>(
    undefined,
  )
  const [errorIDs, setErrorIDs] = useState<string[]>([])
  const [totalItemCount, setTotalItemCount] = useState<number>(0)

  const [originConditionType, setOriginConditionType] = useState<
    ConditionType | undefined
  >(undefined)
  const [originCategoriesInPromotion, setOriginCategoriesInPromotion] =
    useState<GetCategoriesInPromotionResponse | undefined>(undefined)
  const [originShopsInPromotion, setOriginShopsInPromotion] = useState<
    string[] | undefined
  >(undefined)

  const [conditionType, setConditionType] = useState<ConditionType>(
    ConditionType.ALL,
  )
  const [selectedUsers, setSelectedUsers] = useState<UserInPromotion[]>([])

  const [isConflictPromotionCode, setIsConflictPromotionCode] =
    useState<boolean>(false)
  const promotionCodeRef = useRef<HTMLInputElement | null>(null)

  const onGetItem = useCallback(
    async (id: string): Promise<FormPromotion | undefined> => {
      setIsLoading(true)

      const promotion = await getPromotion(id)
      if (!promotion) {
        return undefined
      }

      setIsLoading(false)
      const promotionSetting = promotion.platformCouponPromotion

      const conditionByShopAndCate =
        promotion.conditionByCategory || promotion.conditionByShop
      if (conditionByShopAndCate) {
        setProductParticipate({
          "participate-ebook":
            conditionByShopAndCate.isParticipateEbookType || false,
          "participate-audio-book":
            conditionByShopAndCate.isParticipateAudioBookType || false,
          "participate-product":
            conditionByShopAndCate.isParticipatePhysicalProductType || false,
        })
      }

      if (promotion.conditionByCategory) {
        setOriginConditionType(ConditionType.BY_CATEGORY)
        const categoriesInPromotion = await getCategoriesInPromotion(id)

        if (categoriesInPromotion) {
          setOriginCategoriesInPromotion(categoriesInPromotion)

          const selectedCate = []
          const checkboxCate: { [key: string]: boolean } = {}
          if (categoriesInPromotion && categoriesInPromotion.categoryIds) {
            const cateSelected = categoriesInPromotion.categoryIds?.map(
              (id) => {
                const found = categories?.find((c) => c.id === id)

                return {
                  label: found?.name || "Missing",
                  value: id,
                  key: id,
                }
              },
            )
            selectedCate.push(...cateSelected)

            for (const cate of categoriesInPromotion.categoryIds) {
              checkboxCate[cate] = true
            }
          }

          setSelectedCategoryConditionOptions(selectedCate)
          setCheckboxCategory(checkboxCate)
        }
      } else if (promotion.conditionByShop) {
        setOriginConditionType(ConditionType.BY_SHOP)
        const shopsInPromotion = await getShopsInPromotion(id)

        if (shopsInPromotion && shopsInPromotion.shopIds) {
          const shopIds = shopsInPromotion.shopIds
          setOriginShopsInPromotion(shopIds)
          const allShopFinding = await getAllShop()
          setAllShop(allShopFinding)

          setSelectedShopConditionOptions(
            shopIds.map((s) => {
              const findShop = allShopFinding?.shops?.find((e) => e.id === s)
              return {
                label: findShop?.name || "",
                value: s,
                key: s,
              }
            }),
          )

          const checkboxShop: { [key: string]: boolean } = {}
          for (const shopId of shopIds) {
            checkboxShop[shopId] = true
          }
          setCheckboxShop(checkboxShop)
        }
      } else if (promotion.conditionByProduct) {
        setOriginConditionType(ConditionType.BY_PRODUCT)
        const productInPromotion = await getProductInPromotion(
          id,
          pageProductIndex,
          paginationSize,
        )
        if (productInPromotion && Number(productInPromotion.count) > 0) {
          setTotalItemCount(Number(productInPromotion.count) || 0)
          setProductsForRender(
            productInPromotion.productsIds!.map((p) => {
              return {
                id: p.productId!,
                name: p.productName!,
                shopName: p.shopName!,
                type: mappingShopProductTypeToProductTypeInput(p.productType!),
              }
            }),
          )
          setAllProducts(undefined)
        }
      } else {
        setOriginConditionType(ConditionType.ALL)
      }

      if (promotionSetting?.productDiscountMethod) {
        setDiscountType("product")
      } else if (promotionSetting?.deliveryDiscountMethod) {
        setDiscountType("delivery")
      } else {
        setDiscountType("gift")
      }

      if (reNewFromId) {
        delete promotion.platformCouponPromotion?.collectCodeStartAt
        delete promotion.platformCouponPromotion?.startAt
        delete promotion.platformCouponPromotion?.endAt
      }

      setConditionType(
        promotion.conditionByCategory
          ? ConditionType.BY_CATEGORY
          : promotion.conditionByShop
            ? ConditionType.BY_SHOP
            : promotion.conditionByProduct
              ? ConditionType.BY_PRODUCT
              : ConditionType.ALL,
      )

      const discount =
        promotionSetting?.productDiscountMethod?.productDiscountPercentage
          ?.discountPercentage ||
        tenPowerPriceToStrPrice(
          promotionSetting?.deliveryDiscountMethod?.discount ||
            promotionSetting?.productDiscountMethod?.productDiscountCustom
              ?.discount,
        )

      const minNetPrice = tenPowerPriceToStrPrice(
        promotionSetting?.productDiscountMethod?.minNetPrice ||
          promotionSetting?.deliveryDiscountMethod?.minNetPrice,
      )

      const maxDiscount = tenPowerPriceToStrPrice(
        promotionSetting?.productDiscountMethod?.productDiscountPercentage
          ?.maxDiscount,
      )

      setSelectedChannelTypes(
        promotionSetting?.paymentChannels?.map((c) => {
          return {
            label: t(`payment-channel.${String(paymentTypeJsonToModel(c))}`),
            key: paymentTypeJsonToModel(c),
          }
        }) || [],
      )

      if (promotion.platformCouponPromotion?.isForSelectedUsers) {
        const users = await getAllSelectedUsersAPI(id)
        setSelectedUsers(
          users?.map(
            (u): UserInPromotion => ({
              userId: u.userId,
              email: "",
              username: "",
            }),
          ),
        )
      }

      return {
        ...promotion,
        discount,
        isCustomDiscount:
          !!promotionSetting?.productDiscountMethod?.productDiscountCustom ||
          !!promotionSetting?.deliveryDiscountMethod?.discount,
        isCustomMaxDiscount:
          !!promotionSetting?.productDiscountMethod?.productDiscountPercentage
            ?.maxDiscount,
        customMaxDiscount: maxDiscount,
        minNetPrice,
        platformSubsidizePercent:
          promotionSetting?.productDiscountMethod?.platformSubsidizePercent ||
          promotionSetting?.deliveryDiscountMethod?.platformSubsidizePercent ||
          "0",
        isCanCollectCodeEarly: !!promotionSetting?.collectCodeStartAt,
      }
    },
    [
      getPromotion,
      getProductInPromotion,
      pageProductIndex,
      getCategoriesInPromotion,
      getShopsInPromotion,
      getAllShop,
      categories,
      reNewFromId,
      t,
    ],
  )

  // smell, it should not manipulate the input by delete all unwanted
  const outTransform = (
    input: FormPromotion,
  ): PlatformCouponCartPromotionSetup => {
    const form = cloneDeep(input)

    if (form.platformCouponPromotion?.isUnlimitedQuota) {
      delete form.platformCouponPromotion?.quotaPerCode
      delete form.platformCouponPromotion?.isForSelectedUsers
    } else if (form.platformCouponPromotion?.isForSelectedUsers) {
      delete form.platformCouponPromotion?.quotaPerCode
      delete form.platformCouponPromotion?.isUnlimitedQuota
    } else {
      delete form.platformCouponPromotion?.isForSelectedUsers
      delete form.platformCouponPromotion?.isUnlimitedQuota
    }

    form.platformCouponPromotion!.paymentChannels = selectedChannelTypes.map(
      (c) => {
        return paymentTypeModelToJson(c.key as PaymentChannel)
      },
    )

    if (discountType === "delivery") {
      delete form.platformCouponPromotion?.productDiscountMethod
      form.platformCouponPromotion!.deliveryDiscountMethod = {
        discount: strPriceToStrTenPowerPrice(form.discount),
        minNetPrice: strPriceToStrTenPowerPrice(form.minNetPrice),
        platformSubsidizePercent: form.platformSubsidizePercent,
      }
    } else {
      delete form.platformCouponPromotion?.deliveryDiscountMethod
      if (form.isCustomDiscount) {
        delete form.platformCouponPromotion?.productDiscountMethod
          ?.productDiscountPercentage
        form.platformCouponPromotion!.productDiscountMethod = {
          productDiscountCustom: {
            discount: strPriceToStrTenPowerPrice(form.discount),
          },
        }
      } else {
        delete form.platformCouponPromotion?.productDiscountMethod
          ?.productDiscountCustom
        form.platformCouponPromotion!.productDiscountMethod = {
          productDiscountPercentage: {
            discountPercentage: form.discount,
            maxDiscount: form.isCustomMaxDiscount
              ? strPriceToStrTenPowerPrice(form.customMaxDiscount)
              : undefined,
          },
        }
      }

      form.platformCouponPromotion!.productDiscountMethod!.minNetPrice =
        strPriceToStrTenPowerPrice(form.minNetPrice)
      form.platformCouponPromotion!.productDiscountMethod!.platformSubsidizePercent =
        form.platformSubsidizePercent
      form.platformCouponPromotion!.productDiscountMethod!.onlyGift =
        discountType == "gift"
    }

    if (!form.isCanCollectCodeEarly) {
      delete form.platformCouponPromotion?.collectCodeStartAt
    }

    delete form.conditionAll
    delete form.conditionByCategory
    delete form.conditionByProduct
    delete form.conditionByShop
    switch (conditionType) {
      case ConditionType.BY_CATEGORY:
        form.conditionByCategory = {
          isParticipateEbookType: productParticipate["participate-ebook"],
          isParticipateAudioBookType:
            productParticipate["participate-audio-book"],
          isParticipatePhysicalProductType:
            productParticipate["participate-product"],
        }
        break
      case ConditionType.BY_SHOP:
        form.conditionByShop = {
          isParticipateEbookType: productParticipate["participate-ebook"],
          isParticipateAudioBookType:
            productParticipate["participate-audio-book"],
          isParticipatePhysicalProductType:
            productParticipate["participate-product"],
        }
        break
      case ConditionType.BY_PRODUCT:
        form.conditionByProduct = true
        break
      default:
        form.conditionAll = true
        break
    }

    return form
  }

  const onSubmit = async (
    forms: FormPromotion,
    id?: string,
  ): Promise<{ id: string | undefined }> => {
    setIsLoading(true)
    id = reNewFromId ? undefined : id
    const resp: { id: string | undefined } = { id }

    try {
      const getInitUpdateProductInput = (): UpdateProductInPromotionInput => {
        return {
          addShopsIds: [],
          deleteShopsIds: [],
          addCategoryIds: [],
          deleteCategoryIds: [],
          isDeleteAllProduct: false,
          addProducts: [],
        }
      }

      if (id) {
        // clear previous condition when changing condition type
        if (originConditionType && conditionType !== originConditionType) {
          const input = getInitUpdateProductInput()
          if (originConditionType === ConditionType.BY_CATEGORY) {
            input.deleteCategoryIds =
              originCategoriesInPromotion?.categoryIds || []
          }
          if (originConditionType === ConditionType.BY_SHOP) {
            input.deleteShopsIds = originShopsInPromotion || []
          }
          if (originConditionType === ConditionType.BY_PRODUCT) {
            input.isDeleteAllProduct = true
          }

          await updateProductInPromotion(id, input)
        }

        await updatePromotion(id, outTransform(forms))
      } else {
        resp.id = await createPromotion(outTransform(forms))
      }

      if (!id && forms.platformCouponPromotion?.isForSelectedUsers) {
        await setSelectedUsersAPI(
          resp.id!,
          selectedUsers.map((s) => s.userId!),
        )
      }

      if (conditionType !== ConditionType.ALL) {
        const inputProductForUpdate = getInitUpdateProductInput()
        if (conditionType === ConditionType.BY_CATEGORY) {
          const cateIdsForAdd: string[] = []

          for (const cateSelected of selectedCategoryConditionOptions) {
            const found = originCategoriesInPromotion?.categoryIds?.find(
              (s) => s === cateSelected.key,
            )
            if (!found) {
              const id = cateSelected.value!
              cateIdsForAdd.push(id)

              originCategoriesInPromotion?.categoryIds?.push(id)
              setOriginCategoriesInPromotion(originCategoriesInPromotion)
            }
          }

          inputProductForUpdate.addCategoryIds = cateIdsForAdd

          if (
            originCategoriesInPromotion &&
            originCategoriesInPromotion.categoryIds
          ) {
            const bookCateIdsForDelete: string[] = []
            for (const originCategoryId of originCategoriesInPromotion.categoryIds) {
              const found = selectedCategoryConditionOptions?.find(
                (s) => s.key === originCategoryId,
              )
              if (!found) {
                bookCateIdsForDelete.push(originCategoryId)

                originCategoriesInPromotion?.categoryIds?.filter(
                  (bId) => bId === originCategoryId,
                )
                setOriginCategoriesInPromotion(originCategoriesInPromotion)
              }
            }
            inputProductForUpdate.deleteCategoryIds = bookCateIdsForDelete
          }
        } else if (conditionType === ConditionType.BY_SHOP) {
          const shopIdsForAdd: string[] = []
          for (const shopSelected of selectedShopConditionOptions) {
            const found = originShopsInPromotion?.find(
              (s) => s === shopSelected.value,
            )
            if (!found) {
              shopIdsForAdd.push(shopSelected.value!)
              originShopsInPromotion?.push(shopSelected.value!)
              setOriginShopsInPromotion(originShopsInPromotion)
            }
          }
          inputProductForUpdate.addShopsIds = shopIdsForAdd

          if (originShopsInPromotion && originShopsInPromotion.length > 0) {
            const shopIdsForDelete: string[] = []
            for (const originShop of originShopsInPromotion) {
              const found = selectedShopConditionOptions?.find(
                (s) => s.value === originShop,
              )
              if (!found) {
                shopIdsForDelete.push(originShop)
                originShopsInPromotion.filter((s) => s === originShop)
                setOriginShopsInPromotion(originShopsInPromotion)
              }
            }
            inputProductForUpdate.deleteShopsIds = shopIdsForDelete
          }
        } else if (conditionType === ConditionType.BY_PRODUCT && allProducts) {
          inputProductForUpdate.isDeleteAllProduct = true
          inputProductForUpdate.addProducts = allProducts.map((p) => {
            return {
              productId: p.id,
              productType: mappingProductTypeInputToShopProductType(p.type),
            }
          })

          const chuckSize = 1000
          if (
            inputProductForUpdate.addProducts?.length &&
            inputProductForUpdate.addProducts?.length > chuckSize
          ) {
            let i = 0
            const allAddProductUniq = uniqWith(
              inputProductForUpdate.addProducts,
              (pA, pB) =>
                pA.productId === pB.productId &&
                pA.productType === pB.productType,
            )

            while (i < allAddProductUniq?.length) {
              const arrUpdateProduct = getInitUpdateProductInput()
              arrUpdateProduct.addProducts =
                inputProductForUpdate.addProducts.slice(i, i + chuckSize)
              if (i === 0) {
                arrUpdateProduct.isDeleteAllProduct = true
              }

              await updateProductInPromotion(resp.id!, arrUpdateProduct)
              i += chuckSize
              await sleep(100)
            }

            inputProductForUpdate.addProducts = []
            inputProductForUpdate.isDeleteAllProduct = false
          }
        }

        await updateProductInPromotion(resp.id!, inputProductForUpdate)
      }

      notification.add({
        title: "Success",
        color: "success",
      })
    } catch (err) {
      const e = errToAppErr(err)
      const i18nKey = appErrorToDefaultI18nKey(e)
      if (
        e["@type"] ===
        "type.googleapis.com/foundation.error.DocumentAlreadyExistsError"
      ) {
        setIsConflictPromotionCode(true)
        promotionCodeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
        notification.add({
          title: "Error",
          color: "danger",
          text: t(i18nKey),
        })
      }
    } finally {
      setIsLoading(false)
    }

    return resp
  }

  const {
    getFieldProps,
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    getError,
  } = useFormHelper<FormPromotion, { id: string | undefined }>({
    id:
      params.id && params.id !== "create"
        ? params.id
        : reNewFromId
          ? reNewFromId!
          : undefined,
    onGetItem,
    onSubmit,
    onSubmitted: async (resp: { id: string | undefined }) => {
      await setIsDirty(false)
      if (resp.id) {
        navigate(`/promotion/detail/${resp.id}`, { replace: true })
      }
    },
    onError: alertDefaultErr,
    validationSchema: formValidator,
    validateOnChange: true,
    validate: (v: FormPromotion) => {
      if (
        (params.id === "create" && !isEmpty(v)) ||
        (params.id !== "create" && !isEmpty(form) && !isDirty)
      ) {
        setIsDirty(true)
      }
    },
    initialValues: {
      platformCouponPromotion: {
        isUnlimitedQuota: true,
      },
    } as FormPromotion,
  })

  const isInCollectPeriod =
    !!form.platformCouponPromotion?.collectCodeStartAt &&
    isAlreadyInCollectingPeriod(params.id!, {
      collectCodeStartAt: moment(
        form.platformCouponPromotion?.collectCodeStartAt,
      ),
      startAt: moment(form.platformCouponPromotion?.startAt),
    })

  const renderDiscountHeader = () => {
    if (params.id === "create") {
      return (
        <EuiText>
          <h2 className="font-bold">{t("promotion.editor.discount")}</h2>
        </EuiText>
      )
    }

    let discountHeaderText = ""

    if (form?.platformCouponPromotion?.deliveryDiscountMethod) {
      discountHeaderText = t("promotion.editor.delivery-discount")
    } else if (form?.platformCouponPromotion?.productDiscountMethod?.onlyGift) {
      discountHeaderText = t("promotion.editor.gift-discount")
    } else {
      discountHeaderText = t("promotion.editor.product-discount")
    }

    return (
      <EuiText>
        <h2 className="font-bold">{discountHeaderText}</h2>
      </EuiText>
    )
  }

  const getProductPaticipateChoices = () => {
    if (discountType == "gift") {
      return [
        {
          id: ProductParticipate.PARTICIPATE_EBOOK,
          label: t("promotion.editor.participate-ebook"),
          hidden: !feature.eBookAudio,
        },
        {
          id: ProductParticipate.PARTICIPATE_AUDIO_BOOK,
          label: t("promotion.editor.participate-audio-book"),
          hidden: !feature.eBookAudio,
        },
      ]
    } else {
      return [
        {
          id: ProductParticipate.PARTICIPATE_EBOOK,
          label: t("promotion.editor.participate-ebook"),
          hidden: !feature.eBookAudio,
        },
        {
          id: ProductParticipate.PARTICIPATE_AUDIO_BOOK,
          label: t("promotion.editor.participate-audio-book"),
          hidden: !feature.eBookAudio,
        },
        {
          id: ProductParticipate.PARTICIPATE_PRODUCT,
          label: t("promotion.editor.participate-product"),
          hidden: !feature.physical,
        },
      ]
    }
  }

  const onDiscountTypeChange = (discountType: string) => {
    if (discountType === "gift") {
      const selectedCategories = selectedCategoryConditionOptions.filter(
        (category) => category.key?.substring(0, 4) === "book",
      )

      setSelectedCategoryConditionOptions(selectedCategories)

      const newCheckboxState: { [key: string]: boolean } = {}

      selectedCategories.forEach((category) => {
        if (typeof category.key === "string") {
          newCheckboxState[category.key] = true
        }
      })

      setCheckboxCategory(newCheckboxState)

      if (allProducts && allProducts?.length > 0) {
        const allProductCanUse: ProductTable[] = []
        allProducts.forEach((p) => {
          if (p.type === "e-book-audio") {
            allProductCanUse.push(p)
          }
        })
        setAllProducts(allProductCanUse)
        setTotalItemCount(allProductCanUse.length)
        setProductsForRender(allProductCanUse.slice(0, paginationSize))
      }
    }
  }

  return (
    <div className="flex w-full max-w-screen-xl flex-col gap-y-8">
      <div className="flex items-center">
        <EuiIcon
          type="arrowLeft"
          size="l"
          color="text"
          onClick={() => navigate("/promotion-code")}
          className="cursor-pointer"
        />
        <div className="ml-2">
          <EuiText>
            <h2 className="font-bold">
              {params.id === "create"
                ? t("promotion.editor.create-promotion")
                : t("promotion.editor.edit-promotion-code")}
            </h2>
          </EuiText>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-x-5">
        <div className="col-span-2">
          <EuiFormRow
            label={t("promotion.editor.promotion-name")}
            fullWidth
            isInvalid={!!getError("platformCouponPromotion.name")}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              value={form.platformCouponPromotion?.name || ""}
              onChange={(e) => {
                setValue("platformCouponPromotion.name", e.target.value)
              }}
              fullWidth
              controlOnly={true}
              isInvalid={!!getError("platformCouponPromotion.name")}
            />
          </EuiFormRow>
          <EuiFormRow
            label={t("promotion.editor.promotion-code")}
            isInvalid={
              !!getError("platformCouponPromotion.code") ||
              isConflictPromotionCode
            }
            className={"w-2/3"}
            helpText={t("promotion.editor.promotion-code-hint")}
            error={
              isConflictPromotionCode
                ? t("promotion.editor.errors.conflict-promotion")
                : null
            }
          >
            <EuiFieldText
              placeholder={t("promotion.editor.promotion-code-example")}
              value={form.platformCouponPromotion?.code || ""}
              onChange={(e) => {
                const [code, ok] = formatPromotionCode(e.target.value)
                if (!ok) {
                  return
                }
                setValue("platformCouponPromotion.code", code)
                setIsConflictPromotionCode(false)
              }}
              fullWidth
              isInvalid={
                !!getError("platformCouponPromotion.code") ||
                isConflictPromotionCode
              }
              maxLength={10}
              controlOnly={true}
              inputRef={promotionCodeRef}
            />
          </EuiFormRow>
          <EuiFormRow label={t("promotion.editor.quota")} fullWidth>
            <div className="mt-1">
              <EuiRadioGroup
                disabled={isInCollectPeriod}
                className="flex"
                css={{
                  ".euiRadioGroup__item": {
                    marginTop: 0,
                  },
                  ".euiRadioGroup__item + .euiRadioGroup__item": {
                    marginLeft: 72,
                  },
                }}
                options={[
                  {
                    id: "unlimited-quota",
                    label: t("promotion.editor.un-limit-quota"),
                  },
                  {
                    id: "limited-quota",
                    label: t("promotion.editor.custom-quota"),
                  },
                  {
                    id: "selected-users",
                    label: t("promotion.editor.selected-users"),
                  },
                ]}
                idSelected={
                  form.platformCouponPromotion?.isUnlimitedQuota
                    ? "unlimited-quota"
                    : form.platformCouponPromotion?.isForSelectedUsers
                      ? "selected-users"
                      : "limited-quota"
                }
                onChange={(id) => {
                  if (id === "unlimited-quota") {
                    setValue("platformCouponPromotion.isUnlimitedQuota", true)
                    setValue(
                      "platformCouponPromotion.isForSelectedUsers",
                      false,
                    )
                  }

                  if (id === "limited-quota") {
                    setValue("platformCouponPromotion.isUnlimitedQuota", false)
                    setValue(
                      "platformCouponPromotion.isForSelectedUsers",
                      false,
                    )
                  }

                  if (id === "selected-users") {
                    setValue("platformCouponPromotion.isUnlimitedQuota", false)
                    setValue("platformCouponPromotion.isForSelectedUsers", true)
                    setValue(
                      "platformCouponPromotion.hideFromCollectPage",
                      true,
                    )
                    setValue("isCanCollectCodeEarly", true)
                  }
                }}
              />
              {form.platformCouponPromotion?.isUnlimitedQuota}
              {!form.platformCouponPromotion?.isUnlimitedQuota &&
              !form.platformCouponPromotion?.isForSelectedUsers ? (
                <div className="mt-4 w-1/3">
                  <FieldNumberWithUnit
                    unit={t("promotion.editor.quota-unit")}
                    placeholder={t("common.please-fill")}
                    {...getFieldProps("platformCouponPromotion.quotaPerCode")}
                    min={0}
                    disabled={isInCollectPeriod}
                  />
                </div>
              ) : null}
              {form.platformCouponPromotion?.isForSelectedUsers ? (
                <PlatformCouponUserInPromotionTable
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
              ) : null}
            </div>
          </EuiFormRow>

          <Divider />

          <EuiText>
            <h2 className="font-bold">{renderDiscountHeader()}</h2>
          </EuiText>

          <div className="mt-2">
            {params.id === "create" && (
              <EuiFormRow fullWidth>
                <React.Fragment>
                  <EuiButton
                    color="primary"
                    fill
                    iconType="analyzeEvent"
                    className={"w-[190px]"}
                    style={{
                      backgroundColor:
                        discountType === "product"
                          ? ""
                          : theme.colors.LIGHT.lightGrey,
                      color:
                        discountType === "product"
                          ? ""
                          : theme.colors.LIGHT.darkGrey,
                    }}
                    onClick={() => setDiscountType("product")}
                  >
                    {t("promotion.editor.product-discount")}
                  </EuiButton>
                  <EuiButton
                    className={"ml-2 w-[190px]"}
                    style={{
                      backgroundColor:
                        discountType === "delivery"
                          ? ""
                          : theme.colors.LIGHT.lightGrey,
                      color:
                        discountType === "delivery"
                          ? ""
                          : theme.colors.LIGHT.darkGrey,
                    }}
                    color="primary"
                    fill
                    onClick={() => {
                      setDiscountType("delivery")
                      setValue("isCustomDiscount", true)
                    }}
                  >
                    <div className="flex place-items-center">
                      <UilTruck size={"16"} className={"mr-1"}></UilTruck>
                      {t("promotion.editor.delivery-discount")}
                    </div>
                  </EuiButton>
                  {publicConfig.featureGiftCode && (
                    <EuiButton
                      color="primary"
                      fill
                      className={"ml-2 w-[190px]"}
                      style={{
                        backgroundColor:
                          discountType === "gift"
                            ? ""
                            : theme.colors.LIGHT.lightGrey,
                        color:
                          discountType === "gift"
                            ? ""
                            : theme.colors.LIGHT.darkGrey,
                      }}
                      onClick={() => {
                        setDiscountType("gift")
                        onDiscountTypeChange("gift")
                      }}
                    >
                      <div className="flex place-items-center">
                        <UilGift size={"16"} className={"mr-1"}></UilGift>
                        {t("promotion.editor.gift-discount")}
                      </div>
                    </EuiButton>
                  )}
                </React.Fragment>
              </EuiFormRow>
            )}

            <EuiFormRow fullWidth>
              <div className="flex items-center gap-6 pt-2">
                <EuiText size="s" className="w-1/6">
                  {t("promotion.editor.discount-value")}
                </EuiText>
                <div className="flex w-5/6 gap-4">
                  <div className="w-1/2 flex-auto">
                    <EuiSelect
                      options={[
                        {
                          value: "discountPercentage",
                          text: t("promotion.editor.discount-percentage"),
                        },
                        {
                          value: "customDiscount",
                          text: t("promotion.editor.custom-discount"),
                        },
                      ]}
                      value={
                        form.isCustomDiscount
                          ? "customDiscount"
                          : "discountPercentage"
                      }
                      onChange={(e) => {
                        const isCustom = e.target.value === "customDiscount"
                        setValue("isCustomDiscount", isCustom)
                        setValue("discount", "")
                      }}
                      disabled={
                        discountType === "delivery" || isInCollectPeriod
                      }
                      className="w-full"
                    />
                  </div>
                  <div className="w-1/2 flex-auto">
                    <FieldNumberWithUnit
                      unit={
                        form.isCustomDiscount
                          ? t("promotion.editor.baht-unit")
                          : t("promotion.editor.percentage-symbol")
                      }
                      placeholder={t("common.please-fill")}
                      {...getFieldProps("discount")}
                      max={form.isCustomDiscount ? undefined : 100}
                      min={0}
                      isInvalid={!!getError("discount")}
                      disabled={isInCollectPeriod}
                    />
                  </div>
                </div>
              </div>
            </EuiFormRow>

            {!form.isCustomDiscount ? (
              <EuiFormRow fullWidth>
                <div className="flex h-[38px] items-center gap-6 pt-2">
                  <EuiText size="s" className="w-1/6">
                    {t("promotion.editor.max-discount")}
                  </EuiText>
                  <div className="w-5/6 flex-auto">
                    <RadioYesNo
                      id={"limit-discount"}
                      value={form.isCustomMaxDiscount}
                      onChange={(v) => setValue("isCustomMaxDiscount", v)}
                      yesLabel={t("promotion.editor.custom-max-discount")}
                      noLabel={t("promotion.editor.un-limit")}
                      disabled={isInCollectPeriod}
                    />
                  </div>
                  {form.isCustomMaxDiscount && (
                    <div className="ml-4 flex-auto">
                      <FieldNumberWithUnit
                        unit={t("promotion.editor.baht-unit")}
                        placeholder={t("common.please-fill")}
                        {...getFieldProps("customMaxDiscount")}
                        min={0}
                        disabled={isInCollectPeriod}
                      />
                    </div>
                  )}
                </div>
              </EuiFormRow>
            ) : null}

            <EuiFormRow fullWidth>
              <div className="flex items-center gap-6 pt-2">
                <EuiText size="s" className="w-1/6">
                  {t("promotion.editor.min-price")}
                </EuiText>
                <div className="w-5/6 flex-auto">
                  <FieldNumberWithUnit
                    unit={t("promotion.editor.baht-unit")}
                    placeholder={t("common.please-fill")}
                    min={0}
                    isInvalid={!!getError("minNetPrice")}
                    {...getFieldProps("minNetPrice")}
                    disabled={isInCollectPeriod}
                  />
                </div>
              </div>
            </EuiFormRow>

            <EuiFormRow fullWidth>
              <div className="flex items-center gap-6 pt-2">
                <EuiText size="s" className="w-1/6">
                  {t("promotion.editor.subsidize")}
                </EuiText>
                <div className="flex w-5/6 flex-auto items-center">
                  <EuiText size="s" className="font-bold">
                    {t("promotion.editor.platform")}
                  </EuiText>
                  <div className="ml-2 w-[110px]">
                    <FieldNumberWithUnit
                      unit={t("promotion.editor.percentage-symbol")}
                      placeholder={t("common.please-fill")}
                      min={0}
                      max={100}
                      isInvalid={!!getError("platformSubsidizePercent")}
                      {...getFieldProps("platformSubsidizePercent")}
                    />
                  </div>

                  <EuiText size="s" className="ml-6 font-bold">
                    {t("promotion.editor.seller")}
                  </EuiText>
                  <div className="ml-2 w-[110px]">
                    <EuiFieldNumber
                      placeholder={t("common.please-fill")}
                      append={
                        <EuiToolTip content="content">
                          <EuiText size="s">
                            {t("promotion.editor.percentage-symbol")}
                          </EuiText>
                        </EuiToolTip>
                      }
                      value={100 - Number(form.platformSubsidizePercent)}
                      min={0}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </EuiFormRow>

            {discountType !== "gift" && (
              <EuiFormRow fullWidth>
                <div className="flex items-center gap-6 pt-2">
                  <EuiText size="s" className="w-1/6">
                    {t("promotion.editor.new-user-header")}
                  </EuiText>
                  <div className="w-5/6">
                    <EuiCheckbox
                      id={"is-for-new-user"}
                      label={
                        <EuiText size="s">
                          {t("promotion.editor.new-user")}
                        </EuiText>
                      }
                      checked={form?.platformCouponPromotion?.isForNewBuyer}
                      onChange={(e) => {
                        setValue(
                          "platformCouponPromotion.isForNewBuyer",
                          e.target.checked,
                        )
                      }}
                    />
                  </div>
                </div>
              </EuiFormRow>
            )}
          </div>

          <Divider />

          <EuiText>
            <h2 className="font-bold">
              {t("promotion.editor.date-time-to-use")}
            </h2>
          </EuiText>
          <div className="mt-2 flex">
            <EuiDatePickerRange
              className={"max-w-[500px]"}
              startDateControl={
                <EuiDatePicker
                  selected={
                    form.platformCouponPromotion?.startAt
                      ? moment(form.platformCouponPromotion?.startAt)
                      : undefined
                  }
                  onChange={(date) => {
                    if (
                      date &&
                      form.platformCouponPromotion?.endAt &&
                      date > moment(form.platformCouponPromotion?.endAt)
                    ) {
                      return
                    }
                    setValue("platformCouponPromotion.startAt", date)
                  }}
                  maxDate={
                    form.platformCouponPromotion?.endAt
                      ? moment(form.platformCouponPromotion?.endAt)
                      : undefined
                  }
                  minTime={moment().hours(0).minutes(0)}
                  maxTime={
                    form.platformCouponPromotion?.endAt
                      ? moment(form.platformCouponPromotion?.endAt)
                      : moment().hours(23).minutes(59)
                  }
                  timeIntervals={1}
                  isInvalid={!!getError("platformCouponPromotion.startAt")}
                  showTimeSelect
                  dateFormat={datePickerDateFormat}
                  timeFormat={datePickerTimeFormat}
                />
              }
              endDateControl={
                <EuiDatePicker
                  selected={
                    form.platformCouponPromotion?.endAt
                      ? moment(form.platformCouponPromotion?.endAt)
                      : undefined
                  }
                  onChange={(date) => {
                    if (
                      date &&
                      date < moment(form.platformCouponPromotion?.startAt)
                    ) {
                      return
                    }
                    setValue("platformCouponPromotion.endAt", date)
                  }}
                  minTime={
                    form.platformCouponPromotion?.startAt
                      ? moment(form.platformCouponPromotion?.startAt)
                      : moment().hours(0).minutes(0)
                  }
                  maxTime={moment().hours(23).minutes(59)}
                  minDate={
                    form.platformCouponPromotion?.startAt
                      ? moment(form.platformCouponPromotion?.startAt)
                      : undefined
                  }
                  timeIntervals={1}
                  isInvalid={!!getError("platformCouponPromotion.endAt")}
                  showTimeSelect
                  dateFormat="DD/MM/YYYY HH:mm"
                  timeFormat="HH:mm"
                />
              }
            />
          </div>
          <div className="mt-6">
            <EuiCheckbox
              id="hideFromCollectPage"
              label={
                <EuiText size="s">
                  {t("promotion.editor.hide-from-collect-page")}
                </EuiText>
              }
              checked={form.platformCouponPromotion?.hideFromCollectPage}
              onChange={() => {
                if (!form.platformCouponPromotion?.hideFromCollectPage) {
                  setValue("isCanCollectCodeEarly", false)
                }

                setValue(
                  "platformCouponPromotion.hideFromCollectPage",
                  !form.platformCouponPromotion?.hideFromCollectPage,
                )
              }}
              disabled={!!form.platformCouponPromotion?.isForSelectedUsers}
            />
          </div>
          <div className="mt-6">
            <EuiCheckbox
              id={"collect-code-early"}
              label={
                <EuiText size="s">
                  {t("promotion.editor.collect-code-early")}
                </EuiText>
              }
              disabled={!!form.platformCouponPromotion?.isForSelectedUsers}
              checked={form.isCanCollectCodeEarly}
              onChange={() => {
                setValue("isCanCollectCodeEarly", !form.isCanCollectCodeEarly)
              }}
            />
          </div>
          <div className="mt-3">
            <EuiText size="s" className="font-bold">
              {t("promotion.editor.collect-code-date-time-before")}
            </EuiText>
          </div>
          <div className="mt-2">
            <EuiDatePicker
              selected={
                form.platformCouponPromotion?.collectCodeStartAt
                  ? moment(form.platformCouponPromotion?.collectCodeStartAt)
                  : undefined
              }
              onChange={(date) => {
                if (
                  date &&
                  date > moment(form.platformCouponPromotion?.endAt)
                ) {
                  return
                }
                setValue("platformCouponPromotion.collectCodeStartAt", date)
              }}
              isInvalid={
                !!getError("platformCouponPromotion.collectCodeStartAt")
              }
              maxDate={moment(form.platformCouponPromotion?.endAt)}
              showTimeSelect
              dateFormat="DD/MM/YYYY HH:mm a"
              timeFormat="HH:mm a"
              placeholder={t("promotion.editor.collect-code-date-time-before")}
              disabled={
                !form.isCanCollectCodeEarly ||
                !!form.platformCouponPromotion?.isForSelectedUsers
              }
            />
          </div>

          <Divider />

          <EuiText>
            <h2 className="font-bold">
              {t("promotion.editor.payment-method")}
            </h2>
          </EuiText>
          <div className="mt-2 flex">
            <EuiFormRow className={"w-2/3"}>
              <EuiComboBox
                placeholder={t("promotion.editor.payment-channel-placeholder")}
                options={[
                  {
                    label: t("payment-channel.bank"),
                    key: "bank",
                  },
                  {
                    label: t("payment-channel.credit-card"),
                    key: "credit-card",
                  },
                  {
                    label: t("payment-channel.true-wallet-online"),
                    key: "true-wallet-online",
                  },
                  {
                    label: t("payment-channel.line-pay"),
                    key: "line-pay",
                  },
                  {
                    label: t("payment-channel.shopee-pay"),
                    key: "shopee-pay",
                  },
                  {
                    label: t("payment-channel.prompt-pay"),
                    key: "prompt-pay",
                  },
                  {
                    label: t("payment-channel.coin"),
                    key: "coin",
                  },
                ]}
                selectedOptions={selectedChannelTypes}
                onChange={(s) => {
                  setSelectedChannelTypes(s)
                }}
              />
            </EuiFormRow>
          </div>

          <Divider />

          <EuiText>
            <h2 className="font-bold">
              {t("promotion.editor.product-involve-in-promotion")}
            </h2>
          </EuiText>
          <div className="my-2 flex">
            <EuiRadioGroup
              className="flex"
              css={css`
                .euiRadioGroup__item {
                  margin-top: 0;
                }

                .euiRadioGroup__item + .euiRadioGroup__item {
                  margin-left: 36px;
                }
              `}
              options={[
                {
                  id: ConditionType.ALL,
                  label: (
                    <EuiText size="s">{t("promotion.editor.all")}</EuiText>
                  ),
                },
                {
                  id: ConditionType.BY_CATEGORY,
                  label: (
                    <EuiText size="s">
                      {t("promotion.editor.condition-by-category")}
                    </EuiText>
                  ),
                },
                {
                  id: ConditionType.BY_SHOP,
                  label: (
                    <EuiText size="s">
                      {t("promotion.editor.condition-by-shop")}
                    </EuiText>
                  ),
                },
                {
                  id: ConditionType.BY_PRODUCT,
                  label: (
                    <EuiText size="s">
                      {t("promotion.editor.condition-by-upload-product")}
                    </EuiText>
                  ),
                },
              ]}
              idSelected={conditionType}
              onChange={async (id) => {
                if (id === ConditionType.BY_CATEGORY) {
                  onDiscountTypeChange(discountType)
                } else if (id === ConditionType.BY_SHOP) {
                  setAllShop(await getAllShop())
                }
                setConditionType(id as ConditionType)
              }}
            />
          </div>
          {conditionType === ConditionType.BY_CATEGORY ? (
            <div className="mt-4" key={discountType}>
              <CategorySelectSection
                options={
                  discountType !== "gift"
                    ? categories?.map((c) => {
                        return {
                          id: c.id!,
                          label: c.name!,
                          name: c.name!,
                          level: c.level!,
                          parentId: c.parentId!,
                        }
                      }) || []
                    : eBookAudioCategories?.map((c) => {
                        return {
                          id: c.id!,
                          label: c.name!,
                          name: c.name!,
                          level: c.level!,
                          parentId: c.parentId!,
                        }
                      }) || []
                }
                classNames="mt-2 w-[600px] max-w-[600px]"
                title={t("promotion.editor.discount")}
                selectedConditionOptions={selectedCategoryConditionOptions}
                setSelectedConditionOptions={(s) =>
                  setSelectedCategoryConditionOptions(s)
                }
                checkbox={checkboxCategory}
                setCheckbox={setCheckboxCategory}
              />
            </div>
          ) : null}
          {conditionType === ConditionType.BY_SHOP ? (
            <div className="mt-4">
              <ConditionSelectedSection
                classNames="mt-2 w-[600px] max-w-[600px]"
                title={t("promotion.editor.discount")}
                options={
                  allShop?.shops?.map((s) => {
                    return {
                      id: s.id!,
                      label: (
                        <EuiText size="s" className="font-bold">
                          {s.name}
                        </EuiText>
                      ),
                      name: s.name!,
                    }
                  }) || []
                }
                selectedConditionOptions={selectedShopConditionOptions}
                setSelectedConditionOptions={(s) => {
                  setSelectedShopConditionOptions(s)
                }}
                checkbox={checkboxShop}
                setCheckbox={setCheckboxShop}
              />
            </div>
          ) : null}
          {conditionType === ConditionType.BY_PRODUCT ? (
            <UploadItemSection
              loading={isLoading}
              errorIds={errorIDs}
              export={async () => {
                setIsLoading(true)
                let productExport: ProductForExport[] | undefined = allProducts
                let fileName = fileNameUpload

                if (!productExport) {
                  fileName = `product-in-promotion${
                    params.id ? `-${params.id}` : ""
                  }.xlsx`
                  const fetchAllProduct = await getProductInPromotion(
                    params.id!,
                    0,
                    totalItemCount,
                  )
                  productExport = fetchAllProduct!.productsIds!.map((p) => {
                    return {
                      id: p.productId!,
                      type: mappingShopProductTypeToProductTypeInput(
                        p.productType!,
                      ),
                    }
                  })
                }

                const worksheetProduct = utilsXLSX.json_to_sheet(productExport!)
                const workbook = utilsXLSX.book_new()
                utilsXLSX.book_append_sheet(
                  workbook,
                  worksheetProduct,
                  "Product",
                )
                utilsXLSX.sheet_add_aoa(worksheetProduct, [["id", "type"]], {
                  origin: "A1",
                })
                writeFile(workbook, fileName)
                setIsLoading(false)
              }}
              fileNameUpload={fileNameUpload}
              handleUpload={async (files: FileList | null) => {
                const productKeysInput: Array<ProductKeyInputXlsx> = []
                setIsLoading(true)
                if (files && files[0]) {
                  setFileNameUpload(files[0].name)
                  const result = await files[0].arrayBuffer()
                  const workbook = readXLSX(result)
                  const wsname = workbook.SheetNames[0]
                  const ws = workbook.Sheets[wsname]
                  const data: {
                    id: string
                    type: ProductTypeInput
                  }[] = utilsXLSX.sheet_to_json(ws)

                  if (
                    data.length > publicConfig.defaultMaxProductRowInPromotion
                  ) {
                    setIsLoading(false)
                    notification.add({
                      title: "Error",
                      color: "danger",
                      text: t("promotion.editor.err.xlsx.too-many-rows"),
                    })
                    return
                  }

                  for (let i = 0; i < data.length; i++) {
                    productKeysInput.push({
                      id: data[i].id,
                      type: data[i].type,
                    })
                  }
                }

                const allProductCanUse: ProductTable[] = []
                const errIDs: string[] = []
                const chuckSize = 1000
                let i = 0
                while (i < productKeysInput.length) {
                  const productKeys = productKeysInput
                    .slice(i, i + chuckSize)
                    .map((p) => {
                      return {
                        id: p.id,
                        type: mappingProductTypeInputToShopProductType(p.type),
                      }
                    })

                  const highlights = await getHighlightProduct(productKeys)
                  const mapping = extendProductsToProductsTable(
                    highlights?.products ?? [],
                    productKeys,
                  )

                  // eslint-disable-next-line no-loop-func
                  mapping.extendsProducts.forEach((p, idx) => {
                    if (
                      p.id === "" ||
                      (discountType === "gift" &&
                        !p.type.includes("PRODUCT_TYPE_E_BOOK_AUDIO"))
                    ) {
                      errIDs.push(productKeysInput[idx + i].id)
                      return
                    }
                    const findInput = productKeysInput.find((pInput, idx2) => {
                      return pInput.id === p.id && idx + i === idx2
                    })
                    if (findInput) {
                      allProductCanUse.push({
                        id: p.id,
                        name: p.name,
                        shopName: p.shopName,
                        type: findInput!.type,
                      } as ProductTable)
                    }
                  })

                  i += chuckSize
                  await sleep(100)
                }

                setAllProducts(allProductCanUse)
                setTotalItemCount(allProductCanUse.length)
                setProductsForRender(allProductCanUse.slice(0, paginationSize))
                setErrorIDs(errIDs)
                setIsLoading(false)
              }}
              items={productsForRender}
              tableColumns={[
                {
                  field: "id",
                  name: (
                    <EuiText className="font-bold" size="xs">
                      {t("promotion.editor.id")}
                    </EuiText>
                  ),
                },
                {
                  field: "name",
                  name: (
                    <EuiText className="font-bold" size="xs">
                      {t("promotion.editor.product-name")}
                    </EuiText>
                  ),
                },
                {
                  field: "type",
                  name: (
                    <EuiText className="font-bold" size="xs">
                      {t("promotion.editor.product-type")}
                    </EuiText>
                  ),
                },
                {
                  field: "shopName",
                  name: (
                    <EuiText className="font-bold" size="xs">
                      {t("promotion.editor.shop")}
                    </EuiText>
                  ),
                },
              ]}
              pagination={{
                pageIndex: pageProductIndex,
                pageSize: paginationSize,
                totalItemCount,
                showPerPageOptions: false,
              }}
              onChangePagination={async (p: {
                page: {
                  index: number
                  size: number
                }
              }) => {
                setPageProductIndex(p.page.index)
                if (allProducts) {
                  const from = paginationSize * p.page.index
                  setProductsForRender(
                    allProducts.slice(from, from + paginationSize),
                  )
                } else {
                  const nextProduct = await getProductInPromotion(
                    params.id!,
                    p.page.index * paginationSize,
                    paginationSize,
                  )
                  if (nextProduct) {
                    setProductsForRender(
                      nextProduct.productsIds!.map((p) => {
                        return {
                          id: p.productId!,
                          name: p.productName!,
                          shopName: p.shopName!,
                          type: mappingShopProductTypeToProductTypeInput(
                            p.productType!,
                          ),
                        }
                      }),
                    )
                  }
                }
              }}
              exampleTemplateStaticPath={
                discountType === "gift"
                  ? `${
                      publicConfig.publicUrl
                    }/excel-template-upload/gift-in-promotion.xlsx`
                  : `${
                      publicConfig.publicUrl
                    }/excel-template-upload/product-in-promotion.xlsx`
              }
            />
          ) : null}
          {[ConditionType.BY_CATEGORY, ConditionType.BY_SHOP].indexOf(
            conditionType,
          ) !== -1 ? (
            <div className="mt-4 items-center">
              <EuiText size="s" className="font-bold">
                {t("promotion.editor.participate-type")}
              </EuiText>

              <EuiCheckboxGroup
                css={css`
                  .euiCheckbox {
                    margin-top: 0;
                  }

                  .euiCheckbox + .euiCheckboxGroup__item {
                    margin-left: 36px;
                  }
                `}
                className={"flex"}
                options={getProductPaticipateChoices().filter(
                  (choice) => !choice.hidden,
                )}
                idToSelectedMap={productParticipate}
                onChange={(id: string) => {
                  setProductParticipate({
                    ...productParticipate,
                    ...{
                      [id]: !productParticipate[id as ProductParticipate],
                    },
                  })
                }}
              />
            </div>
          ) : null}

          <Divider />

          <TextArea
            label={t("promotion.editor.additional")}
            optional
            {...getFieldProps("platformCouponPromotion.additional")}
          />

          <Divider />

          <TextArea
            label={t("promotion.editor.noted")}
            optional
            {...getFieldProps("platformCouponPromotion.note")}
          />
        </div>

        <div>
          <div className="sticky top-10">
            <SummaryPromotionCard
              onClick={submit}
              isLoading={isLoading}
              btnText={
                params.id === "create"
                  ? t("promotion.editor.create-promotion")
                  : t("common.save")
              }
            >
              <EuiText size="s" className="font-bold">
                {t("promotion.editor.example-promotion-detail")}
              </EuiText>
              <ExamplePromotionDetailRenderer
                {...form}
                discount={`${form.discount ?? "-"} ${
                  discountType && !form.isCustomDiscount
                    ? t("promotion.editor.percentage-symbol")
                    : t("promotion.editor.baht-unit")
                }`}
                minNetPrice={t("promotion.editor.condition-min-price", {
                  minPrice: form.minNetPrice || 0,
                })}
                paymentChannel={selectedChannelTypes.map(
                  (c) => c.key as PaymentChannel,
                )}
                isForNewBuyer={form.platformCouponPromotion?.isForNewBuyer}
              />
            </SummaryPromotionCard>
          </div>
        </div>
      </div>
    </div>
  )
}

type UserInPromotionRow = {
  id: string
}

const PlatformCouponUserInPromotionTable: React.FC<{
  selectedUsers: UserInPromotion[]
  setSelectedUsers: (users: UserInPromotion[]) => void
}> = ({ selectedUsers, setSelectedUsers }) => {
  const { t } = useTranslation()
  const { getMany: getManyUsers } = useGetUser()
  const { getManyUsersWithEmail } = useGetManyUsersWithEmail()
  const alertDefaultErr = useAlertDefaultErr()
  const [pageIndex, setPageIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [errorIds, setErrorIds] = useState<string[]>([])
  const [duplicatedIds, setDuplicatedIds] = useState<string[]>([])
  const [customErrorText, setCustomErrorText] = useState("")

  const columns: EuiBasicTableColumn<UserInPromotion>[] = [
    {
      field: "userId",
      name: t("promotion.editor.selected-user.column.id"),
      width: "200px",
    },
    {
      field: "username",
      name: t("promotion.editor.selected-user.column.username"),
      width: "150px",
    },
    {
      field: "email",
      name: t("promotion.editor.selected-user.column.email"),
    },
  ]

  const handleUpload = async (files: FileList | null): Promise<void> => {
    if (files && files[0]) {
      setLoading(true)
      setErrorIds([])
      setDuplicatedIds([])
      setPageIndex(0)
      setSelectedUsers([])
      setCustomErrorText("")

      const maxRowCountAllowed = 1000

      const result = await files[0].arrayBuffer()
      const workbook = readXLSX(result)
      const wsname = workbook.SheetNames[0]
      const ws = workbook.Sheets[wsname]
      const rows: UserInPromotionRow[] = utilsXLSX.sheet_to_json(ws)

      if (rows.length > maxRowCountAllowed) {
        setCustomErrorText(t("promotion.editor.selected-user.max-rows-exceed"))
        setLoading(false)
        return
      }

      let hasError = false
      const setOfUniqueId = new Set<string>()
      const uniqueRows: UserInPromotionRow[] = []

      for (const row of rows) {
        if (!setOfUniqueId.has(row.id)) {
          setOfUniqueId.add(row.id)
          uniqueRows.push(row)
        } else {
          setDuplicatedIds((old) => {
            old.push(row.id)
            return old
          })
          hasError = true
        }
      }

      const ids = uniqueRows.filter((r) => r).map((r) => r.id)
      const batchSize = 100
      const filteredUsers: UserInPromotion[] = []

      try {
        for (let i = 0; i < uniqueRows.length; i += batchSize) {
          const res = await getManyUsers({
            userIds: ids.slice(i, i + batchSize),
          })
          for (let j = 0; j < (res?.users ?? []).length; j++) {
            const user = res?.users?.[j]
            if (user?.id && user.id !== "") {
              filteredUsers.push({
                userId: uniqueRows[i + j].id,
                username: "",
                email: "",
              })
            } else {
              setErrorIds((old) => {
                old.push(uniqueRows[i + j].id)
                return old
              })

              hasError = true
            }
          }
        }
      } catch (e) {
        alertDefaultErr(e)
      } finally {
        setLoading(false)
      }

      if (!hasError) {
        setSelectedUsers(filteredUsers)
      }
    }
  }

  const onChangePage = (p: {
    page: {
      index: number
    }
  }) => {
    setPageIndex(p.page.index)
  }

  const { data: pageItems, error: errGetUsersDetail } = useSWR(
    ["coupon-promotion/selected-users/edit/page", pageIndex, selectedUsers],
    async () => {
      if (!selectedUsers) {
        return []
      }

      const userIds = selectedUsers
        .slice(
          pageIndex * selectedUserPaginationSize,
          (pageIndex + 1) * selectedUserPaginationSize,
        )
        .map((u) => u.userId!)
      if (userIds.length == 0) {
        return []
      }

      const resp = await getManyUsersWithEmail({ userIds: userIds || [] })
      return (
        resp?.users?.map(
          (u, i): UserInPromotion => ({
            email: u.email || "",
            userId: u.userId || userIds[i] || "",
            username: u.username || "",
          }),
        ) || []
      )
    },
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  )
  useAlertIfDefaultErr([errGetUsersDetail])

  return (
    <div className="mt-4">
      <UploadItemSection
        errorIds={errorIds}
        duplicatedIds={duplicatedIds}
        warningText={customErrorText !== "" ? customErrorText : undefined}
        export={() => {
          const url = `${
            publicConfig.publicUrl
          }/excel-template-upload/distribute-coupon-template.xlsx`
          window.open(url, "_blank")
        }}
        loading={loading}
        handleUpload={handleUpload}
        items={pageItems}
        tableColumns={columns}
        pagination={{
          pageIndex,
          pageSize: selectedUserPaginationSize,
          totalItemCount: selectedUsers.length,
          showPerPageOptions: false,
        }}
        onChangePagination={onChangePage}
        defaultFileName="Template.xlsx"
        exampleTemplateStaticPath={`${
          publicConfig.publicUrl
        }/excel-template-upload/distribute-coupon-template.xlsx`}
      />
    </div>
  )
}
