import * as Yup from "yup"

export const coverShapeValidator = Yup.object({
  fileInput: Yup.object().shape({
    tmpPath: Yup.string(),
    name: Yup.string(),
  }),
  downloadUrl: Yup.string(),
})

export const conditionalCoverShapeValidator = (imageField: string) =>
  coverShapeValidator
    .when(imageField, (image) => {
      if (image !== undefined && image[0] === undefined) {
        return coverShapeValidator.default(undefined).required()
      }
      return coverShapeValidator.default(undefined)
    })
    .default(undefined)
