import { Toast } from "@elastic/eui/src/components/toast/global_toast_list"
import { makeObservable, observable } from "mobx"
import { v4 as uuidv4 } from "uuid"

export type NotiPayload = Omit<Toast, "id">

export class NotificationStore {
  public toasts: Toast[] = []
  public toastDuration: number = 5000

  constructor() {
    makeObservable(this, {
      toasts: observable,
    })
  }

  async add(payload: NotiPayload) {
    const id = uuidv4()
    this.toasts = this.toasts.concat({
      ...payload,
      id,
    })

    setTimeout(() => {
      this.remove(id)
    }, this.toastDuration)
  }

  async remove(id: string) {
    this.toasts = this.toasts.filter((t) => t.id !== id)
  }
}
