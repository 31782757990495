import { EuiText } from "@elastic/eui"
import { UilBookOpen, UilGift } from "@iconscout/react-unicons"
import classNames from "classnames"

type RevenueBoxProps = {
  title: string
  text: string
  gift?: string
  displayIcon?: boolean
  border?: boolean
}

export const RevenueBox = (props: RevenueBoxProps) => {
  return (
    <div
      className={classNames("flex grow flex-col p-4", {
        "border border-y-0 border-r-0 border-solid border-slate-300":
          props.border,
      })}
    >
      <div className="w-full text-center">{props.title}</div>
      <div className="flex w-full justify-evenly divide-x divide-y-0 divide-solid divide-slate-300">
        <EuiText className="mt-2 flex w-full items-center justify-center gap-2 text-xl font-semibold">
          {props.displayIcon && <UilBookOpen size={24} color="#ABB4C4" />}
          {props.text}
        </EuiText>
        {props.gift && (
          <EuiText className="mt-2 flex w-full items-center justify-center gap-2 text-xl font-semibold">
            <UilGift size={24} color="#F04E98" />
            {props.gift}
          </EuiText>
        )}
      </div>
    </div>
  )
}
