import { useAlertDefaultErr, useAlertIfDefaultErr } from "@app/hook/error"
import { useGetManyUsersWithEmail } from "@app/hook/user"
import { useWrapAsyncWithLoading } from "@app/lib/async"
import { paymentTypeJsonToModel } from "@app/model/payment"
import {
  ProductTable,
  mappingShopProductTypeToProductTypeInput,
} from "@app/model/product-table"
import {
  ActionButtonInDetail,
  CountCouponUsage,
  Headline,
  StatusBadge,
} from "@app/page/coupon-promotion/component"
import { LIGHTEST_GREY } from "@app/utils/color"
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiSkeletonText,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import useSWR from "swr"
import { ExamplePromotionDetailRenderer } from "./example-promotion-detail"
import {
  GetPlatformCouponResponse,
  UserInPromotion,
  useCountCouponUsage,
  useDeletePromotion,
  useEndPromotion,
  useFindAllCategory,
  useGetAllShopSWR,
  useGetAllUserInPromotion,
  useGetCategoriesInPromotionSWR,
  useGetProductInPromotionSWR,
  useGetPromotionSWR,
  useGetShopsInPromotionSWR,
  usePromotionApi,
} from "./hook/promotion"
import { tenPowerPriceToStrPrice } from "./utils"

export const PromotionDetail: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const deletePromotion = useDeletePromotion()
  const { downloadUserInPromotionReport } = usePromotionApi()
  const endPromotion = useEndPromotion()
  const { t } = useTranslation()
  const alertDefaultErr = useAlertDefaultErr()

  const promotionId = params.id as string
  const { loading, wrap } = useWrapAsyncWithLoading()

  const {
    data: getPromotion,
    loading: promotionLoading,
    mutate: refetchPromotion,
  } = useGetPromotionSWR(promotionId, false)
  const { data: countUsage, loading: countLoading } =
    useCountCouponUsage(promotionId)

  const platformCouponPromotion = getPromotion?.platformCouponPromotion

  const platformSubsidize = Number(
    platformCouponPromotion?.deliveryDiscountMethod
      ? platformCouponPromotion.deliveryDiscountMethod.platformSubsidizePercent
      : platformCouponPromotion?.productDiscountMethod
          ?.platformSubsidizePercent || 0,
  )

  const discountPrice =
    platformCouponPromotion?.productDiscountMethod?.productDiscountPercentage
      ?.discountPercentage ||
    tenPowerPriceToStrPrice(
      platformCouponPromotion?.deliveryDiscountMethod?.discount ||
        platformCouponPromotion?.productDiscountMethod?.productDiscountCustom
          ?.discount,
    )

  if (promotionLoading || countLoading) {
    return <EuiSkeletonText className="w-full" lines={10} />
  }

  const timeline = {
    startAt: getPromotion?.platformCouponPromotion?.startAt
      ? moment(getPromotion.platformCouponPromotion.startAt)
      : undefined,
    endAt: getPromotion?.platformCouponPromotion?.endAt
      ? moment(getPromotion.platformCouponPromotion.endAt)
      : undefined,
  }

  return (
    <div className="my-0 grid max-w-screen-xl grid-cols-3">
      <div className="col-span-2 flex flex-col">
        <Headline
          onGoBackClick={() => navigate(-1)}
          text={getPromotion?.platformCouponPromotion?.name ?? ""}
          action={
            <ActionButtonInDetail
              loading={loading}
              codeActive={timeline}
              disableRenew={
                !!getPromotion?.platformCouponPromotion?.isForSelectedUsers
              }
              promotionId={promotionId}
              downloadReport={
                getPromotion?.platformCouponPromotion?.isForSelectedUsers
                  ? async () => {
                      return downloadUserInPromotionReport({ promotionId })
                    }
                  : undefined
              }
              onRenew={wrap(async () => {
                navigate(`/promotion/create?renew=${promotionId}`, {
                  replace: true,
                })
              })}
              onEdit={() => {
                navigate(`/promotion/${promotionId}`)
              }}
              onEnd={wrap(async () => {
                try {
                  await endPromotion(promotionId)

                  refetchPromotion()
                } catch (e) {
                  alertDefaultErr(e)
                }
              })}
              onDelete={wrap(async () => {
                try {
                  await deletePromotion(promotionId)

                  navigate(-1)
                } catch (e) {
                  alertDefaultErr(e)
                }
              })}
            />
          }
        />
        <div className="mt-8 flex items-center">
          <EuiText size="s" style={{ color: LIGHTEST_GREY }}>
            {t("promotion.detail.transaction-date")}
          </EuiText>
          <EuiText size="xs" className="ml-2">
            {t("promotion.detail.promotion-create-datetime", {
              value: getPromotion?.platformCouponPromotion?.updatedAt,
            })}
          </EuiText>
        </div>
        <StatusBadge codeActive={timeline} />
        {getPromotion?.platformCouponPromotion?.quotaPerCode && (
          <CountCouponUsage
            usage={Number(countUsage) || 0}
            limit={Number(getPromotion.platformCouponPromotion.quotaPerCode)}
          />
        )}
        <div
          className="mt-6 rounded-md p-8"
          css={css`
            border: solid 1px #e5e7eb;
          `}
        >
          <ExamplePromotionDetailRenderer
            {...getPromotion}
            discount={`${discountPrice} ${
              getPromotion?.platformCouponPromotion?.productDiscountMethod
                ?.productDiscountPercentage != null
                ? t("promotion.editor.percentage-symbol")
                : t("promotion.editor.baht-unit")
            }`}
            minNetPrice={t("promotion.editor.condition-min-price", {
              minPrice: tenPowerPriceToStrPrice(
                platformCouponPromotion?.productDiscountMethod?.minNetPrice ||
                  platformCouponPromotion?.deliveryDiscountMethod
                    ?.minNetPrice ||
                  "0",
              ),
            })}
            paymentChannel={getPromotion?.platformCouponPromotion?.paymentChannels?.map(
              (c) => paymentTypeJsonToModel(c),
            )}
            isForNewBuyer={getPromotion?.platformCouponPromotion?.isForNewBuyer}
          />
        </div>
        {getPromotion?.platformCouponPromotion?.note ? (
          <div className="mt-6">
            <EuiText size="xs" className="font-bold">
              {t("promotion.editor.noted")}
            </EuiText>
            <EuiText size="s">
              {getPromotion.platformCouponPromotion.note}
            </EuiText>
          </div>
        ) : null}
        {getPromotion?.platformCouponPromotion?.collectCodeStartAt ? (
          <div className="mt-6">
            <EuiText size="xs" className="font-bold">
              {t("promotion.editor.collect-code-date-time")}
            </EuiText>
            <EuiText size="s">
              {t("common.datetime", {
                value:
                  getPromotion?.platformCouponPromotion?.collectCodeStartAt,
              })}
            </EuiText>
          </div>
        ) : null}
        
        <div className="mt-6">
          <EuiText size="xs" className="font-bold">
            {t("promotion.editor.coupon-type")}
          </EuiText>
          <EuiText size="s">
            {getPromotion?.platformCouponPromotion?.deliveryDiscountMethod
              ? t("promotion.editor.delivery-discount")
              : getPromotion?.platformCouponPromotion?.productDiscountMethod
                    ?.onlyGift
                ? t("promotion.editor.gift-discount")
                : t("promotion.editor.product-discount")}
          </EuiText>
        </div>

        <div className="mt-6">
          <EuiText size="xs" className="font-bold">
            {t("promotion.editor.subsidize")}
          </EuiText>
          <div className="flex">
            <EuiText size="s">
              {t("promotion.list.platform-subsidize", {
                platformSub: platformSubsidize,
              })}
              {" : "}
            </EuiText>
            <EuiText size="s" className="ml-1">
              {t("promotion.list.seller-subsidize", {
                sellerSub: 100 - platformSubsidize,
              })}
            </EuiText>
          </div>
        </div>
        <ConditionPromotionDetail {...getPromotion} promotionId={promotionId} />
        {getPromotion?.platformCouponPromotion?.isForSelectedUsers && (
          <UserInPromotionTable promotionId={promotionId} />
        )}
      </div>
    </div>
  )
}

const ConditionPromotionDetail: React.FC<
  GetPlatformCouponResponse & { promotionId: string }
> = (props: GetPlatformCouponResponse & { promotionId: string }) => {
  const { t } = useTranslation()
  const [pageProductIndex, setPageProductIndex] = useState<number>(0)
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  let productsForRender: ProductTable[] | undefined = []
  const paginationSize = 20

  let title = ""
  let conditionDetail: string[] = []

  const getAllShop = useGetAllShopSWR()
  const { data: categories } = useFindAllCategory(
    props.platformCouponPromotion?.productDiscountMethod?.onlyGift
      ? "book"
      : "",
  )

  let shouldGetCateInPro = false
  let shouldGetShopInPro = false
  let shouldGetProductInPro = false
  if (props.conditionByCategory) {
    shouldGetCateInPro = true
  } else if (props.conditionByShop) {
    shouldGetShopInPro = true
  } else if (props.conditionByProduct) {
    shouldGetProductInPro = true
  }

  const getShopsInPromotion = useGetShopsInPromotionSWR(
    props.promotionId,
    shouldGetShopInPro,
    true,
  )
  const getCategoriesInPromotion = useGetCategoriesInPromotionSWR(
    props.promotionId,
    shouldGetCateInPro,
    true,
  )
  const getProductInPromotion = useGetProductInPromotionSWR(
    props.promotionId,
    paginationSize,
    pageProductIndex,
    shouldGetProductInPro,
    true,
  )

  if (props.conditionByCategory || props.conditionByShop) {
    const condition = props.conditionByCategory || props.conditionByShop!
    const stack: string[] = []
    if (condition.isParticipateAudioBookType) {
      stack.push(t("promotion.detail.participate-audio-book"))
    }
    if (condition.isParticipateEbookType) {
      stack.push(t("promotion.detail.participate-ebook"))
    }
    if (condition.isParticipatePhysicalProductType) {
      stack.push(t("promotion.detail.participate-product"))
    }

    title = `${stack.join(", ")}${stack.length > 0 ? " " : ""}${
      props.conditionByCategory
        ? t("promotion.detail.condition-in-category")
        : t("promotion.detail.condition-in-shop")
    }`

    if (props.conditionByCategory) {
      const catesInPro = getCategoriesInPromotion
      const cateNameDetail: string[] = []

      if (catesInPro?.data?.categoryIds && categories) {
        for (const cateId of catesInPro.data.categoryIds!) {
          const findCate = categories.find((b) => b.id === cateId)
          if (findCate && findCate.name) {
            cateNameDetail.push(findCate.name)
          }
        }
      }

      conditionDetail = cateNameDetail
    } else {
      const allShopFinding = getAllShop?.data?.shops
      const shopsInPro = getShopsInPromotion?.data?.shopIds
      const shopNameDetail: string[] = []

      if (shopsInPro && allShopFinding) {
        for (const shopId of shopsInPro) {
          const findCate = allShopFinding.find((s) => s.id === shopId)

          if (findCate && findCate.name) {
            shopNameDetail.push(findCate.name)
          }
        }
      }
      conditionDetail = shopNameDetail
    }
  }

  if (props.conditionByProduct && getProductInPromotion.data) {
    productsForRender = getProductInPromotion?.data?.productsIds?.map((p) => {
      return {
        id: p.productId!,
        name: p.productName!,
        shopName: p.shopName!,
        type: mappingShopProductTypeToProductTypeInput(p.productType!),
      }
    })
  }

  useEffect(() => {
    setTotalItemCount(Number(getProductInPromotion?.data?.count) || 0)
  }, [getProductInPromotion])

  if (!props || props.conditionAll) {
    return <></>
  }

  return (
    <div className="mt-6">
      <EuiText size="m" className="font-bold">
        <h3>{t("promotion.detail.product-involve-in-promotion")}</h3>
      </EuiText>

      <EuiText size="s" className="mt-4" style={{ color: LIGHTEST_GREY }}>
        {title}
      </EuiText>

      {props.conditionByProduct ? (
        <div>
          <EuiText size="m" className="mt-2 font-bold">
            {t("common.amount-product", { amount: totalItemCount })}
          </EuiText>
          <EuiBasicTable
            className="mt-2 rounded-md p-4"
            css={css`
              border: solid 1px #e5e7eb;

              .euiPagination__list {
                margin: 0;
              }
              .euiPagination__item {
                list-style: none;
              }
              &.clickable {
                .euiTableRowCell {
                  cursor: pointer;
                }
              }
              .euiFlexGroup {
                justify-content: left;
                margin-left: -6px;
              }
              .euiFlexItem {
                margin: 0;
              }
            `}
            items={productsForRender || []}
            responsive={false}
            columns={[
              {
                field: "id",
                name: (
                  <EuiText className="font-bold" size="xs">
                    {t("promotion.editor.id")}
                  </EuiText>
                ),
              },
              {
                field: "name",
                name: (
                  <EuiText className="font-bold" size="xs">
                    {t("promotion.editor.product-name")}
                  </EuiText>
                ),
              },
              {
                field: "type",
                name: (
                  <EuiText className="font-bold" size="xs">
                    {t("promotion.editor.product-type")}
                  </EuiText>
                ),
              },
              {
                field: "shopName",
                name: (
                  <EuiText className="font-bold" size="xs">
                    {t("promotion.editor.shop")}
                  </EuiText>
                ),
              },
            ]}
            pagination={{
              pageIndex: pageProductIndex,
              pageSize: paginationSize,
              totalItemCount,
              showPerPageOptions: false,
            }}
            onChange={async (p: {
              page: {
                index: number
                size: number
              }
            }) => {
              setPageProductIndex(p.page.index)
            }}
          />
        </div>
      ) : (
        <div className="mt-2">
          {conditionDetail.map((c, i) => {
            return (
              <EuiText size="xs" className="font-bold" key={i}>
                {t("promotion.detail.bullet-text")} {c}
              </EuiText>
            )
          })}
        </div>
      )}
    </div>
  )
}

const UserInPromotionTable: React.FC<{ promotionId: string }> = ({
  promotionId,
}) => {
  const pageSize = 10
  const { t } = useTranslation()
  const getAllSelectedUsersAPI = useGetAllUserInPromotion()
  const { getManyUsersWithEmail } = useGetManyUsersWithEmail()
  const [pageIndex, setPageIndex] = useState(0)
  const { data: allUserIds, error: errGetAllUsers } = useSWR(
    ["platform-coupon-promotion/user/detail/all", promotionId],
    async () => {
      const users = await getAllSelectedUsersAPI(promotionId)
      return users.map((u) => u.userId!) || []
    },
  )
  useAlertIfDefaultErr([errGetAllUsers])

  const { data: pageItems, error: errGetUsersDetail } = useSWR(
    [
      "platform-coupon-promotion/user/detail/page",
      promotionId,
      pageIndex,
      allUserIds,
    ],
    async () => {
      if (!allUserIds) {
        return []
      }

      const userIds = allUserIds.slice(
        pageIndex * pageSize,
        (pageIndex + 1) * pageSize,
      )
      if (userIds.length == 0) {
        return []
      }

      const resp = await getManyUsersWithEmail({ userIds: userIds || [] })
      return (
        resp?.users?.map(
          (u, i): UserInPromotion => ({
            email: u.email || "",
            userId: u.userId || userIds[i] || "",
            username: u.username || "",
          }),
        ) || []
      )
    },
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
    },
  )
  useAlertIfDefaultErr([errGetUsersDetail])

  const columns: EuiBasicTableColumn<UserInPromotion>[] = [
    {
      field: "userId",
      name: t("promotion.editor.selected-user.column.id"),
      width: "200px",
    },
    {
      field: "username",
      name: t("promotion.editor.selected-user.column.username"),
      width: "150px",
    },
    {
      field: "email",
      name: t("promotion.editor.selected-user.column.email"),
    },
  ]

  return (
    <div className="mt-8">
      <EuiText size="xs" className="mt-2 font-bold">
        {t("promotion.detail.selected-users-title")}
      </EuiText>
      <EuiBasicTable
        className="mt-2"
        items={pageItems || []}
        responsive={false}
        columns={columns}
        pagination={{
          pageIndex,
          pageSize,
          totalItemCount: allUserIds?.length || 0,
          showPerPageOptions: false,
        }}
        onChange={async (p: {
          page: {
            index: number
            size: number
          }
        }) => {
          setPageIndex(p.page.index)
        }}
      />
    </div>
  )
}
