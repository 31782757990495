import { NavigationHeaderButton } from "@app/component"
import { publicConfig } from "@app/config"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { addressToString } from "@app/model/address"
import { constants } from "@app/model/constants"
import {
  deliveryMethodMapping,
  isShippopStatusComplete,
  isShippopStatusFailed,
  shippopCourierToCourierProvider,
} from "@app/model/delivery"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import {
  EuiBadge,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiIcon,
  EuiImage,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTimeline,
  EuiTimelineItemProps,
  copyToClipboard,
} from "@elastic/eui"
import { css } from "@emotion/react"
import {
  UilEnvelope,
  UilExclamationCircle,
  UilGift,
  UilPhone,
  UilReceipt,
  UilShop,
} from "@iconscout/react-unicons"
import { isDefined } from "@reeeed-mp/ui-common"
import classNames from "classnames"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { OrderStatusBadge } from "./common"
import { EVoucherDetailModal } from "./component/e-voucher-modal"
import {
  useGetDeliveryTrackingStatus,
  useGetOrder,
  useGetTrackPaymentStatus,
} from "./hook"

export const OrderManagementDetailPage: React.FC = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const orderId = params.orderId as string
  const { data, error } = useGetOrder(orderId)
  useAlertIfDefaultErr([error])

  const orderIdWithoutRunningNumber = orderId.substring(0, 16)
  const orderRunningNumber = orderId.substring(16)

  const { physicalOrder, digitalContentOrder } = data?.data?.order || {}

  const physicalTotalAmount = physicalOrder?.products?.reduce((prev, curr) => {
    return prev + (curr.amount || 0)
  }, 0)

  const paymentId = physicalOrder?.paymentId || digitalContentOrder?.paymentId
  const payment =
    physicalOrder?.paymentMethod || digitalContentOrder?.paymentMethod
  const paymentDate =
    physicalOrder?.paymentDate || digitalContentOrder?.paymentDate
  const paymentDueDate =
    physicalOrder?.paymentExpiresAt || digitalContentOrder?.paymentExpiresAt
  const status = physicalOrder?.status || digitalContentOrder?.status
  const orderCreatedAt =
    physicalOrder?.createdAt || digitalContentOrder?.createdAt
  const deliveryMethod = physicalOrder?.delivery?.deliveryMethod
  const deliveryTracking = physicalOrder?.delivery?.deliveryTracking
  const taxInvoice =
    physicalOrder?.taxInvoice || digitalContentOrder?.taxInvoice

  const productPriceBeforeProductDiscount = parseInt(
    physicalOrder?.productPrice || digitalContentOrder?.price || "0",
    10,
  )

  const productDiscount = parseInt(
    physicalOrder?.sellerDiscountCampaignDiscount ||
      digitalContentOrder?.sellerDiscountCampaignDiscount ||
      "0",
    10,
  )

  const addOnDealDiscount = parseInt(
    physicalOrder?.addOnDealDiscount ||
      digitalContentOrder?.addOnDealDiscount ||
      "0",
    10,
  )

  const shopCouponDiscount = parseInt(
    physicalOrder?.shopCouponDiscount ||
      digitalContentOrder?.shopCouponDiscount ||
      "0",
    10,
  )

  const bundleDealDiscount = parseInt(
    physicalOrder?.bundleDealDiscount ||
      digitalContentOrder?.bundleDealDiscount ||
      "0",
  )

  const autoAppliedDeliveryDealDiscount = parseInt(
    physicalOrder?.autoAppliedDeliveryDealDiscount || "0",
  )

  const couponDiscount = parseInt(
    physicalOrder?.platformCouponDiscount ||
      digitalContentOrder?.platformCouponDiscount ||
      "0",
    10,
  )
  const productPriceAfterProductDiscount =
    productPriceBeforeProductDiscount - productDiscount
  const deliveryPrice = parseInt(physicalOrder?.delivery?.price || "0", 10)
  const netPrice =
    productPriceAfterProductDiscount +
    deliveryPrice -
    bundleDealDiscount -
    shopCouponDiscount -
    couponDiscount

  const CourierLogo = (() => {
    if (!deliveryMethod?.shippopMethod?.shippopCourier) {
      return null
    }

    const findMapping = deliveryMethodMapping.find(
      (m) =>
        m.courier ===
        shippopCourierToCourierProvider(
          deliveryMethod?.shippopMethod?.shippopCourier!,
        ),
    )

    return (
      <EuiIcon
        type={findMapping?.image || ""}
        size="xl"
        css={css`
          width: 35px !important;
          margin-bottom: 0 !important;
        `}
      />
    )
  })()

  let paymentChannel = ""
  if (payment?.bank2c2p) {
    paymentChannel = t("payment-channel.bank")
  } else if (payment?.coin) {
    paymentChannel = t("payment-channel.coin")
  } else if (payment?.creditCard2c2p) {
    paymentChannel = t("payment-channel.credit-card")
  } else if (payment?.linePay2c2p) {
    paymentChannel = t("payment-channel.line-pay")
  } else if (payment?.promptPayPaysol) {
    paymentChannel = t("payment-channel.prompt-pay")
  } else if (payment?.shopeePay2c2p) {
    paymentChannel = t("payment-channel.shopee-pay")
  } else if (payment?.trueWalletOnline2c2p) {
    paymentChannel = t("payment-channel.true-wallet-online")
  }

  const shop = digitalContentOrder?.shop || physicalOrder?.shop
  const shopId = shop?.id

  const [tab, setTab] = useState<"detail" | "logs">("detail")

  return (
    <div>
      <div className="flex items-center">
        <div className="flex w-full justify-between">
          <NavigationHeaderButton
            onClick={() => navigate(-1)}
            title={t("order-management.detail-page.title")}
          />
        </div>
      </div>

      <EuiTabs className="my-6">
        <EuiTab onClick={() => setTab("detail")} isSelected={tab === "detail"}>
          {t("order-management.detail-page.tab.order-detail")}
        </EuiTab>
        <EuiTab onClick={() => setTab("logs")} isSelected={tab === "logs"}>
          {t("order-management.detail-page.tab.logs")}
        </EuiTab>
      </EuiTabs>

      {tab === "detail" && (
        <div className="flex">
          <div className="flex-auto">
            <div className="rounded-md border border-solid border-grey-light p-6">
              <div className="flex items-center justify-between pb-4">
                <div className="flex items-center gap-4">
                  <div>
                    <EuiImage
                      alt="shop logo"
                      src={shop?.shopLogoUrl!}
                      style={{
                        width: 48,
                        height: 48,
                      }}
                    />
                  </div>
                  <div>
                    <EuiText>
                      <h4>{shop?.shopName}</h4>
                    </EuiText>
                  </div>
                </div>
                <div>
                  <a
                    href={`${publicConfig.webBaseUri}/shop/${shopId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <EuiButtonEmpty
                      iconType={UilShop}
                      className="border border-solid"
                      style={{
                        width: 180,
                      }}
                    >
                      {t("order-management.detail-page.go-to-shop")}
                    </EuiButtonEmpty>
                  </a>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <OrderStatusBadge status={status} />
                  {physicalOrder?.cancellation && (
                    <EuiText size="xs" color="danger">
                      <p>{`${t(physicalOrder.cancellation.reason!)} ${physicalOrder.cancellation.description ? `: ${physicalOrder.cancellation.description}` : ""}`}</p>
                    </EuiText>
                  )}
                </div>

                {taxInvoice && (
                  <EuiBadge color={"#F5ECFF"}>
                    <div className="flex gap-x-2 text-ci-primary">
                      <UilReceipt size={"16"} />
                      {t("order-management.detail-page.request-tax-invoice")}
                    </div>
                  </EuiBadge>
                )}
              </div>
              <div className="flex">
                <div>
                  <EuiText size="xs" className="mt-4">
                    <p className="font-bold">
                      {t("order-management.detail-page.order-number")}
                    </p>
                  </EuiText>
                  <EuiText size="xs" className="mt-4">
                    <p className="font-bold">
                      {t("order-management.detail-page.order-date")}
                    </p>
                  </EuiText>
                  {paymentDate && (
                    <EuiText size="xs" className="mt-4">
                      <p className="font-bold">
                        {t("order-management.detail-page.datetime-to-pay")}
                      </p>
                    </EuiText>
                  )}
                  {paymentDueDate &&
                    status === "ORDER_STATUS_PAYMENT_PENDING" && (
                      <EuiText size="xs" className="mt-4">
                        <p className="font-bold">
                          {t("order-management.detail-page.due-date-payment")}
                        </p>
                      </EuiText>
                    )}
                  {status !== "ORDER_STATUS_PAYMENT_PENDING" && (
                    <EuiText size="xs" className="mt-4">
                      <p className="font-bold">
                        {t("order-management.detail-page.payment-channel")}
                      </p>
                    </EuiText>
                  )}
                  {paymentId && (
                    <EuiText size="xs" className="mt-4">
                      <p className="font-bold">
                        {t("order-management.detail-page.payment-id")}
                      </p>
                    </EuiText>
                  )}
                </div>
                <div className="ml-16">
                  <EuiText size="xs" className="mt-4">
                    <p>
                      <span>{orderIdWithoutRunningNumber}</span>
                      <span className="text-ci-grey-mid">
                        {orderRunningNumber}
                      </span>
                    </p>
                  </EuiText>
                  <EuiText size="xs" className="mt-4">
                    <p>
                      {t("common.datetime", {
                        value: orderCreatedAt,
                      })}
                    </p>
                  </EuiText>
                  {paymentDate && (
                    <EuiText size="xs" className="mt-4">
                      <p>
                        {t("common.datetime", {
                          value: new Date(paymentDate),
                        })}
                      </p>
                    </EuiText>
                  )}
                  {paymentDueDate &&
                    status === "ORDER_STATUS_PAYMENT_PENDING" && (
                      <EuiText size="xs" className="mt-4">
                        <p>
                          {t("common.datetime", {
                            value: paymentDueDate,
                          })}
                        </p>
                      </EuiText>
                    )}
                  {status !== "ORDER_STATUS_PAYMENT_PENDING" && (
                    <EuiText size="xs" className="mt-4">
                      <p>{paymentChannel}</p>
                    </EuiText>
                  )}
                  {paymentId && (
                    <EuiText
                      size="xs"
                      className="mt-4 flex flex-row items-center"
                    >
                      <p>{paymentId}</p>
                    </EuiText>
                  )}
                </div>
              </div>
            </div>

            {physicalOrder?.products && physicalOrder?.products.length > 0 && (
              <div className="mt-12">
                <div className="flex justify-between">
                  <EuiText size="m">
                    <p className="font-bold">
                      {t("order-management.detail-page.physical-order-detail")}
                    </p>
                  </EuiText>
                  <EuiText size="m">
                    <p className="font-bold">
                      {t("common.piece", { value: physicalTotalAmount })}
                    </p>
                  </EuiText>
                </div>

                <div className="mt-4">
                  {physicalOrder.products.map((p, idx) => {
                    return <RenderPhysicalProduct key={idx} {...p} />
                  })}
                </div>
              </div>
            )}

            {digitalContentOrder?.products &&
              digitalContentOrder?.products.length > 0 && (
                <div className="mt-12">
                  <div className="flex">
                    <EuiText size="m">
                      <p className="font-bold">
                        {t("order-management.detail-page.digital-order-detail")}
                      </p>
                    </EuiText>
                    <EuiText size="m" className="ml-2 text-eui-darkShade">
                      <p>
                        {t(
                          "order-management.detail-page.digital-order-detail-subfix",
                        )}
                      </p>
                    </EuiText>
                  </div>

                  <div className="mt-4">
                    {digitalContentOrder.products.map((p, idx) => {
                      return (
                        <RenderDigitalContentProduct
                          key={idx}
                          product={p}
                          eVoucherCode={digitalContentOrder.eVoucher}
                        />
                      )
                    })}
                  </div>
                </div>
              )}

            <div className="mt-8">
              <EuiHorizontalRule />
            </div>

            <div className="mt-8 flex justify-between">
              <div></div>
              <div
                style={{
                  width: "320px",
                }}
              >
                <EuiText size="s" className="mt-2">
                  <p className="font-bold">
                    {t("order-management.detail-page.summarize-price")}
                  </p>
                </EuiText>

                <OrderSummary
                  title={t("order-management.detail-page.goods-price")}
                  price={productPriceBeforeProductDiscount}
                />

                {physicalOrder && (
                  <OrderSummary
                    title={t("order-management.detail-page.delivery-price")}
                    price={deliveryPrice}
                  />
                )}

                <OrderSummary
                  title={t(
                    "order-management.detail-page.discount-seller-campaign",
                  )}
                  price={-(productDiscount + addOnDealDiscount)}
                />

                <OrderSummary
                  title={t("order-management.detail-page.discount-bundle-deal")}
                  price={-bundleDealDiscount}
                />

                {physicalOrder && (
                  <OrderSummary
                    title={t(
                      "order-management.detail-page.discount-auto-applied-delivery-deal",
                    )}
                    price={-autoAppliedDeliveryDealDiscount}
                  />
                )}

                <OrderSummary
                  title={t("order-management.detail-page.discount-shop-coupon")}
                  price={-shopCouponDiscount}
                />

                <OrderSummary
                  title={t("order-management.detail-page.discount-cart-coupon")}
                  price={-couponDiscount}
                />

                <EuiHorizontalRule
                  style={{
                    marginTop: 12,
                    marginBottom: 10,
                  }}
                />

                <OrderSummary
                  title={t("order-management.detail-page.net-price")}
                  price={netPrice}
                  primary
                />
              </div>
            </div>
          </div>

          {digitalContentOrder && digitalContentOrder.taxInvoice && (
            <div
              className="ml-4 h-min flex-auto rounded-md border border-solid border-grey-light p-6"
              style={{
                maxWidth: "400px",
              }}
            >
              <TaxInvoiceSection taxInvoice={digitalContentOrder.taxInvoice!} />
            </div>
          )}

          {physicalOrder && (
            <div
              className="ml-4 h-min flex-auto rounded-md border border-solid border-grey-light p-6"
              style={{
                maxWidth: "400px",
              }}
            >
              <AddressSection
                userAddress={physicalOrder.delivery?.userAddress}
              />

              <div className="my-6">
                <EuiHorizontalRule
                  style={{
                    marginTop: 12,
                    marginBottom: 10,
                  }}
                />
              </div>
              {physicalOrder.taxInvoice && (
                <>
                  <TaxInvoiceSection taxInvoice={physicalOrder.taxInvoice!} />
                  <div className="my-6">
                    <EuiHorizontalRule
                      style={{
                        marginTop: 12,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                </>
              )}

              <EuiText size="s" className="mt-2">
                <p className="font-bold">
                  {t("order-management.detail-page.courier")}
                </p>
              </EuiText>

              {deliveryMethod?.shippopMethod?.shippopCourier && (
                <div className="mt-2 flex items-center">
                  {CourierLogo}
                  <EuiText size="xs" className="ml-2">
                    <p>{deliveryMethod?.shippopMethod?.courierName}</p>
                  </EuiText>
                </div>
              )}

              {deliveryMethod?.shippopMethod?.courierTrackingCode &&
                deliveryMethod?.shippopMethod?.courierTrackingCode !== "" &&
                status &&
                [
                  "ORDER_STATUS_PAYMENT_PENDING",
                  "ORDER_STATUS_WAITING_CONFIRM_ORDER",
                ].indexOf(status) === -1 && (
                  <div className="mt-4">
                    <EuiText size="xs" className="mt-2">
                      <p className="font-bold">
                        {t("order-management.detail-page.tracking-number")}
                      </p>
                    </EuiText>
                    <EuiText size="xs" className="flex items-center">
                      <p
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        {deliveryMethod.shippopMethod.courierTrackingCode}
                      </p>
                      <EuiButtonIcon
                        color="text"
                        iconType="copy"
                        className="ml-1"
                        css={css({
                          height: "10px",
                          width: "10px",
                          svg: {
                            width: "10px",
                          },
                        })}
                        size="xs"
                        onClick={() => {
                          copyToClipboard(
                            deliveryMethod.shippopMethod?.courierTrackingCode ||
                              "",
                          )
                        }}
                      />
                    </EuiText>
                  </div>
                )}

              {status &&
                ["ORDER_STATUS_DELIVERED", "ORDER_STATUS_SUCCEEDED"].indexOf(
                  status,
                ) !== -1 &&
                deliveryTracking?.shippopShippingState?.shippopState && (
                  <div className="mt-4">
                    <EuiText size="xs" className="mt-2">
                      <p className="font-bold">
                        {t("order-management.detail-page.shipped-date")}
                      </p>
                    </EuiText>
                    <EuiText size="xs">
                      <p>
                        {t("common.datetime", {
                          value:
                            deliveryTracking.shippopShippingState.shippopState[
                              deliveryTracking.shippopShippingState.shippopState
                                .length - 1
                            ].dateTime,
                        })}
                      </p>
                    </EuiText>
                  </div>
                )}

              {deliveryTracking?.shippopShippingState?.shippopState && (
                <div className="mt-4">
                  <EuiText size="xs" className="mt-2">
                    <p className="font-bold">
                      {t("order-management.detail-page.shipping-state")}
                    </p>
                  </EuiText>
                  {deliveryTracking.shippopShippingState?.shippopState
                    .reverse()
                    .map(
                      (
                        s: definitions["delivery.v1.ShippopShippingStatus.ShippopState"],
                        i: number,
                      ) => {
                        return (
                          <div
                            key={i}
                            className="border-y-0 border-l border-r-0 border-solid border-grey-light p-2"
                          >
                            <EuiText size="xs" className="mt-1">
                              <p
                                className={
                                  i === 0
                                    ? "text-[#007E77]"
                                    : "text-eui-darkShade"
                                }
                              >
                                {t("common.datetime", { value: s.dateTime })}
                              </p>
                            </EuiText>
                            <EuiText size="xs" className="mt-1">
                              <p className={i === 0 ? "text-[#007E77]" : ""}>
                                {s.description} {` [${s.location}]`}
                              </p>
                            </EuiText>
                          </div>
                        )
                      },
                    )}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {tab === "logs" && <Logs />}
    </div>
  )
}

const AddressSection = (props: {
  userAddress?: definitions["delivery.v1.DeliveryAddress"]
}) => {
  const { t } = useTranslation()

  if (!props.userAddress) {
    return null
  }

  // TODO: handle international address
  const { userAddress: local } = props.userAddress

  return (
    <>
      <EuiText size="s" className="mt-2">
        <p className="font-bold">
          {t("order-management.detail-page.customer-detail")}
        </p>
      </EuiText>
      <EuiText size="xs" className="mt-2">
        <p className="break-words font-bold">{local?.name}</p>
      </EuiText>
      <EuiText size="xs" className="mt-2">
        <p className="break-words">{local ? addressToString(local) : ""}</p>
      </EuiText>
      <EuiText size="xs" className="mt-2 flex">
        <UilPhone size={"12"} />
        <p className="ml-1">{local?.phoneNumber}</p>
      </EuiText>
    </>
  )
}
const TaxInvoiceSection = (props: {
  taxInvoice: definitions["order.v1.TaxInvoice"]
}) => {
  const { t } = useTranslation()
  return (
    <>
      <EuiText size="s" className="mt-2">
        <p className="font-bold">
          {t("order-management.detail-page.tax-invoice-detail")}
        </p>
      </EuiText>
      <EuiText size="xs" className="mt-2">
        <p className="font-bold">{props.taxInvoice?.name}</p>
      </EuiText>
      <EuiText size="xs" className="mt-2 flex">
        <span>
          {props.taxInvoice.taxInvoiceType === "TAX_INVOICE_TYPE_PERSONAL"
            ? t("order-management.detail-page.tax-id-individual")
            : t("order-management.detail-page.tax-id-company")}
        </span>
        <span className="ml-1">{props.taxInvoice?.taxId}</span>
      </EuiText>
      <EuiText size="xs" className="mt-2 flex">
        <UilEnvelope size={"12"} />
        <p className="ml-1">{props.taxInvoice?.email}</p>
      </EuiText>

      {props.taxInvoice.phoneNumber && (
        <EuiText size="xs" className="mt-2 flex">
          <UilPhone size={"12"} />
          <p className="ml-1">{props.taxInvoice?.phoneNumber}</p>
        </EuiText>
      )}

      {props.taxInvoice.companyBranchName && (
        <div className="flex flex-col">
          <EuiText size="xs" className="mt-2">
            <p>{props.taxInvoice?.companyBranchName}</p>
          </EuiText>

          <EuiText size="xs" className="mt-2">
            <p>{props.taxInvoice?.companyBranchCode}</p>
          </EuiText>
        </div>
      )}

      <EuiText size="xs" className="mt-2">
        <p>{addressToString(props.taxInvoice)}</p>
      </EuiText>
    </>
  )
}

const RenderPhysicalProduct = (
  props: definitions["webbff.seller.v1.PhysicalOrderProduct"],
) => {
  const { t } = useTranslation()
  const { product } = props
  const { name, cover, variant } = product || {}

  let price = parseInt(props.price || "0") / constants.power10OfDecimalPrice
  const originalPrice = price
  const sellerDiscountCampaignDiscount =
    parseInt(props.sellerDiscountCampaignDiscountPerUnit || "0") *
    (props.amount || 1)

  const addOnDealDiscountPerUnit =
    parseInt(props.addOnDealDiscountPerUnit || "0", 10) * (props.amount || 1)

  if (sellerDiscountCampaignDiscount > 0 || addOnDealDiscountPerUnit > 0) {
    const discount =
      Math.max(sellerDiscountCampaignDiscount, addOnDealDiscountPerUnit) /
      constants.power10OfDecimalPrice
    price = price - discount
  }

  return (
    <>
      <div
        className="mt-6 flex"
        style={{
          height: "60px",
        }}
      >
        <div
          style={{
            width: "60px",
          }}
        >
          {cover && <EuiImage size={60} alt="" src={cover} />}
        </div>
        <div className="ml-2 w-full py-2">
          <div className="flex items-center justify-between">
            <EuiText size="xs">
              <p>{name || ""}</p>
            </EuiText>
            <EuiText size="xs">
              <p className="font-bold">
                {t("common.piece", { value: props.amount })}
              </p>
            </EuiText>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-row gap-2">
              <div>
                {variant && (
                  <EuiText className="mt-2">
                    <p
                      className="text-eui-darkShade"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {[variant.mainAttributeName, variant.subAttributeName]
                        .filter(isDefined)
                        .join(", ")}
                    </p>
                  </EuiText>
                )}
                {props.isPreOrder && (
                  <div className="rounded-md bg-slate-200 px-2 py-1 align-middle text-xs font-medium">
                    {t("order-management.detail-page.pre-order")}
                  </div>
                )}
              </div>
            </div>

            <EuiText size="s" className="mt-2">
              <p>
                {`${t("number", {
                  number: price,
                })} ${t("common.currency")}`}
              </p>
            </EuiText>
          </div>
          <div className=" flex items-center justify-between">
            <div></div>
            <div>
              {sellerDiscountCampaignDiscount > 0 && (
                <EuiText size="xs" className="text-ci-grey-mid line-through">
                  <p>
                    {`${t("number", { number: originalPrice })} ${t(
                      "common.currency",
                    )}`}
                  </p>
                </EuiText>
              )}
            </div>
          </div>
        </div>
      </div>
      {(props.addOnDealPhysicalProducts || []).map((p, idx) => (
        <RenderPhysicalProduct key={idx} {...p} />
      ))}
    </>
  )
}

const RenderDigitalContentProduct: React.FC<{
  product: definitions["webbff.seller.v1.DigitalContentOrderProduct"]
  eVoucherCode?: definitions["order.v1.EVoucher"]
}> = ({ product, eVoucherCode }) => {
  const { t } = useTranslation()
  const { eBookAudio, novelChapter, eVoucher } = product
  const cover = eBookAudio?.cover || novelChapter?.cover || eVoucher?.cover
  const name = eBookAudio?.name || novelChapter?.name || eVoucher?.name || "-"
  const productType: definitions["shop.v1.ProductType"] = eBookAudio
    ? "PRODUCT_TYPE_E_BOOK_AUDIO"
    : novelChapter
      ? "PRODUCT_TYPE_NOVEL"
      : eVoucher
        ? "PRODUCT_TYPE_E_VOUCHER"
        : "PRODUCT_TYPE_UNSPECIFIED"
  const isGift = product.isGift
  const amount = product.amount || 1
  let price = parseInt(product.price || "0") / constants.power10OfDecimalPrice
  const originalPrice = price
  const [openEVoucherDetailModal, setOpenEVoucherDetailModal] = useState(false)

  const sellerDiscountCampaignDiscount = parseInt(
    product.sellerDiscountCampaignDiscount || "0",
    10,
  )

  const addOnDealDiscountPerUnit = parseInt(
    product.addOnDealDiscountPerUnit || "0",
    10,
  )

  const productDiscount = Math.max(
    sellerDiscountCampaignDiscount,
    addOnDealDiscountPerUnit,
  )

  if (productDiscount > 0) {
    const discount = productDiscount / constants.power10OfDecimalPrice
    price = price - discount
  }

  return (
    <>
      <div
        className="mt-6 flex"
        style={{
          height: "60px",
        }}
      >
        <div
          style={{
            width: "60px",
          }}
        >
          {cover && <EuiImage size={60} alt="" src={cover} />}
        </div>
        <div className="ml-2 w-full py-2">
          <div className="flex items-center justify-between">
            <EuiText size="xs">
              <p>{name}</p>
            </EuiText>
            {isGift && (
              <EuiText size="xs">
                <p className="font-bold">
                  {t("common.piece", { value: amount })}
                </p>
              </EuiText>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex">
              {productType && (
                <EuiText>
                  <p
                    className="text-eui-darkShade"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    {t(productType)}
                  </p>
                </EuiText>
              )}
              {isGift && (
                <EuiText
                  className="mt-2 flex items-center gap-1"
                  color="#EA458A"
                  style={{
                    fontSize: "10px",
                  }}
                >
                  <UilGift size={12} />
                  <span>{t("order-management.detail-page.gift")}</span>
                </EuiText>
              )}
            </div>
            <EuiText size="s" className="mt-2">
              <p>
                {`${t("number", {
                  number: price,
                })} ${t("common.currency")}`}
              </p>
            </EuiText>
          </div>
          <div className="flex items-center justify-between">
            <div></div>
            <div>
              {productDiscount > 0 && (
                <EuiText size="xs" className="text-ci-grey-mid line-through">
                  <p>
                    {`${t("number", { number: originalPrice })} ${t(
                      "common.currency",
                    )}`}
                  </p>
                </EuiText>
              )}
            </div>
          </div>
          {productType === "PRODUCT_TYPE_E_VOUCHER" && (
            <div className="flex items-center justify-between">
              <EuiText
                className="flex cursor-pointer items-center gap-1 text-xxs text-blue-500 underline"
                onClick={() => {
                  setOpenEVoucherDetailModal(true)
                }}
              >
                <UilExclamationCircle size={12} />
                <span>
                  {t("order-management.detail-page.usage-detail-button-label")}
                </span>
              </EuiText>
            </div>
          )}
        </div>
      </div>
      {(product.addOnDealDigitalContentProducts || []).map((p, idx) => (
        <RenderDigitalContentProduct
          key={idx}
          product={p}
          eVoucherCode={undefined}
        />
      ))}
      <EVoucherDetailModal
        open={openEVoucherDetailModal}
        onClose={() => {
          setOpenEVoucherDetailModal(false)
        }}
        product={product!}
        eVoucherCode={eVoucherCode}
      />
    </>
  )
}

const PaymentBadge = () => {
  const { t } = useTranslation()
  return (
    <EuiBadge color="warning">
      {t("order-management.detail-page.logs.payment")}
    </EuiBadge>
  )
}

const DeliveryBadge = () => {
  const { t } = useTranslation()
  return (
    <EuiBadge color="#BADA55">
      {t("order-management.detail-page.logs.delivery")}
    </EuiBadge>
  )
}

const FailedBadge = () => {
  const { t } = useTranslation()
  return (
    <EuiBadge color="danger">
      {t("order-management.detail-page.logs.failed")}
    </EuiBadge>
  )
}

const SuccessBadge = () => {
  const { t } = useTranslation()
  return (
    <EuiBadge color="success">
      {t("order-management.detail-page.logs.success")}
    </EuiBadge>
  )
}

const Logs: React.FC = () => {
  const params = useParams()
  const { t } = useTranslation()
  const orderId = params.orderId as string
  const { data, error } = useGetOrder(orderId)
  useAlertIfDefaultErr([error])

  const paymentOrderId =
    data?.data.order?.digitalContentOrder?.paymentOrderId ||
    data?.data.order?.physicalOrder?.paymentOrderId

  const paymentTrack = useGetTrackPaymentStatus(paymentOrderId)
  useAlertIfDefaultErr([paymentTrack.error])

  const deliveryTrack = useGetDeliveryTrackingStatus(
    data?.data.order?.physicalOrder !== undefined
      ? data?.data.order?.physicalOrder.delivery?.orderId
      : undefined,
  )
  useAlertIfDefaultErr([deliveryTrack.error])

  const timelines = useMemo(() => {
    const timelines: EuiTimelineItemProps[] = [
      {
        icon: "documentEdit",
        children: (
          <div>
            <EuiText>
              <p className="text-xs text-eui-darkShade">
                {t("common.datetime", {
                  value:
                    data?.data.order?.digitalContentOrder?.createdAt ||
                    data?.data.order?.physicalOrder?.createdAt,
                })}
              </p>
            </EuiText>
            <EuiText className="mt-2 font-bold">
              <p>{t("order-management.detail-page.logs.order-created")}</p>
            </EuiText>
          </div>
        ),
      },
    ]

    for (const track of paymentTrack?.data?.data.tracks || []) {
      timelines.push({
        icon: "payment",
        children: (
          <div>
            <div className="flex items-center gap-1">
              <EuiText>
                <p className="text-xs text-eui-darkShade">
                  {t("common.datetime", {
                    value: track.createdAt,
                  })}
                </p>
              </EuiText>
              <PaymentBadge />
              {track.to === "PAYMENT_STATUS_FAILED" && <FailedBadge />}
              {track.to === "PAYMENT_STATUS_SUCCEEDED" && <SuccessBadge />}
            </div>
            <EuiText className="mt-2 flex flex-col gap-1">
              <p className="m-0">
                <span className="font-bold">
                  {t("order-management.detail-page.logs.payment-status-prefix")}
                </span>
                {t(track.to!)}
              </p>
              {track.remark && (
                <p>
                  <span className="font-bold">
                    {t("order-management.detail-page.logs.remark")}
                  </span>
                  {track.remark}
                </p>
              )}
            </EuiText>
          </div>
        ),
      })
    }

    if (deliveryTrack.data?.data.shippop) {
      const track = deliveryTrack.data?.data.shippop
      let latestUpdateTime = "-"
      if (track.states?.shippopState && track.states.shippopState.length > 0) {
        const lastState =
          track.states.shippopState[track.states.shippopState.length - 1]
        if (lastState.dateTime) {
          latestUpdateTime = t("common.datetime", {
            value: lastState.dateTime,
          })
        }
      }

      timelines.push({
        icon: "package",
        children: (
          <div>
            <div className="flex items-center gap-1">
              <EuiText>
                <p className="text-xs text-eui-darkShade">{latestUpdateTime}</p>
              </EuiText>
              <DeliveryBadge />
              {isShippopStatusFailed(track.status) && <FailedBadge />}
              {isShippopStatusComplete(track.status) && <SuccessBadge />}
            </div>
            <EuiText className="mt-2 flex flex-col gap-1">
              <p className="m-0">
                <span className="font-bold">
                  {t(
                    "order-management.detail-page.logs.delivery-status-prefix",
                  )}
                </span>
                {t(
                  `order-management.detail-page.logs.delivery-status.${track.status}`,
                )}
              </p>
              {track.cancelDetail && (
                <p>
                  <span className="font-bold">
                    {t(
                      "order-management.detail-page.logs.delivery-cancel-detail",
                    )}
                  </span>
                  {track.cancelDetail}
                </p>
              )}
            </EuiText>
          </div>
        ),
      })
    }

    return timelines
  }, [data?.data.order, paymentTrack?.data?.data, deliveryTrack.data?.data]) // eslint-disable-line

  return <EuiTimeline items={timelines} />
}

const OrderSummary = (props: {
  title: string
  price: number
  primary?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <div className="mt-4 flex justify-between">
      <EuiText size="s">
        <p>{props.title}</p>
      </EuiText>
      <EuiText size="s" className={classNames(props.primary && "text-primary")}>
        <p className="font-bold">
          {props.price ? props.price / constants.power10OfDecimalPrice : 0}{" "}
          {t("common.currency")}
        </p>
      </EuiText>
    </div>
  )
}
