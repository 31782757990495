import { publicConfig } from "@app/config"
import { useAlertDefaultErr } from "@app/hook/error"
import { useWrapAsyncWithLoading } from "@app/lib/async"
import { EuiButtonEmpty, EuiButtonIcon } from "@elastic/eui"
import { css } from "@emotion/react"
import { UilBan, UilShop } from "@iconscout/react-unicons"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { ContainerShopBankAccountCard } from "./detail/shop-bank-account-card"
import { ContainerShopInfoCard } from "./detail/shop-info-card"
import { TableProductList } from "./detail/shop-product-list"
import { ContainerShopRevenueCard } from "./detail/shop-revenue-card"
import { TopProductCard } from "./detail/shop-top-product-card"
import { useBanShop, useGetShop } from "./hook"
import { ContainerShopOrderCard } from "./detail/shop-order-card"

export const DetailSellerManagement = () => {
  return (
    <div className="flex h-full w-full flex-col space-y-4">
      <TitleAndButtonGroup />
      <ContainerShopInfoCard />

      <div className="grid w-full grid-cols-3 justify-start gap-4">
        <TopProductCard />
        <ContainerShopRevenueCard />
        <div className="grid grid-flow-row grid-cols-1 gap-4">
          <ContainerShopOrderCard />
          <ContainerShopBankAccountCard />
        </div>
      </div>

      <TableProductList />
    </div>
  )
}

const TitleAndButtonGroup: React.FC = () => {
  const { t } = useTranslation()
  const { shopId } = useParams()
  const { data: shop, refetch } = useGetShop(shopId || "")
  const { banShop, unBanShop } = useBanShop()
  const { loading, wrap } = useWrapAsyncWithLoading()
  const alertDefaultError = useAlertDefaultErr()

  const banOrUnBan = wrap(async () => {
    if (!shop?.shop) {
      return
    }

    try {
      if (shop.shop?.isBanned) {
        await unBanShop(shopId || "")
      } else {
        await banShop(shopId || "")
      }
    } catch (err) {
      alertDefaultError(err)
    }

    refetch()
  })

  return (
    <div className="mb-4 flex justify-between">
      <PageTittleWithBackButton
        tittleText={t("shop-information.shop-detail")}
      />
      <div className="flex space-x-2">
        <EuiButtonEmpty
          color="danger"
          css={css({
            width: "180px",
          })}
          className={classNames(
            "border border-solid",
            !shop?.shop?.isBanned
              ? "border-eui-darkestShade text-eui-darkestShade"
              : "",
          )}
          onClick={banOrUnBan}
          isLoading={loading}
          disabled={loading}
        >
          <div className="flex items-center space-x-2">
            <UilBan width={16} height={16} />
            {shop?.shop?.isBanned ? (
              <div className="text-s">{t("shop-information.un-ban-shop")}</div>
            ) : (
              <div className="text-s">{t("shop-information.ban-shop")}</div>
            )}
          </div>
        </EuiButtonEmpty>
        <EuiButtonEmpty
          color="text"
          css={css({
            width: "180px",
          })}
          className="border border-solid border-primary text-primary"
          onClick={() => {
            window.open(`${publicConfig.webBaseUri}/shop/${shopId}`, "_blank")
          }}
        >
          <div className="flex items-center space-x-2">
            <UilShop width={16} height={16} />
            <div className="text-s">{t("shop-information.view-shop")}</div>
          </div>
        </EuiButtonEmpty>
      </div>
    </div>
  )
}

const PageTittleWithBackButton: React.FC<{
  tittleText: string
  onBackButtonClickNavigate?: () => void
  width?: number
}> = ({ tittleText, onBackButtonClickNavigate, width }) => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-center space-x-4">
      <EuiButtonIcon
        iconType="arrowLeft"
        color="text"
        className="scale-125"
        onClick={() => {
          if (onBackButtonClickNavigate) {
            onBackButtonClickNavigate()
          } else {
            navigate(-1)
          }
        }}
      />
      <div
        className={`overflow-hidden overflow-ellipsis text-xl font-bold w-[${
          width || 400
        }px] h-fit`}
      >
        {tittleText}
      </div>
    </div>
  )
}
