import { Title } from "@app/component"
import { UploadItemSection } from "@app/component/upload-and-render"
import { publicConfig } from "@app/config"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { useStore } from "@app/hook/store"
import { useGetUser } from "@app/hook/user"
import { constants } from "@app/model/constants"
import { genUniqueId } from "@app/model/id"
import {
  EuiBasicTableColumn,
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiHorizontalRule,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import { useFormHelper } from "@reeeed-mp/ui-common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { read as readXLSX, utils as utilsXLSX } from "xlsx"
import * as Yup from "yup"
import { useAddCoinUsers } from "./hook"
import { useNavigate, useParams } from "react-router-dom"
import { DetailAddReeeedCoin } from "./detail"

type AddCoinUser = {
  id: string
  username: string
  email: string
  coin: number
}

type AddCoinForm = {
  name: string
  noted?: string
  addCoinUsers: {
    userId: string
    amount: string
  }[]
  password: string
}

const addCoinUserValidator = Yup.object().shape({
  userId: Yup.string().required(),
  amount: Yup.string().required(),
})

const AddCoinFormValidator = Yup.object().shape({
  name: Yup.string().required(),
  noted: Yup.string(),
  addCoinUsers: Yup.array().of(addCoinUserValidator),
})

const paginationSize = 20

export const EditorAddReeeedCoinPage: React.FC = () => {
  const params = useParams()
  const id = params.id as string
  const { t } = useTranslation()
  const [idempotentId] = useState<string>(genUniqueId())
  const [errorIDs, setErrorIDs] = useState<string[]>([])
  const [duplicateIDs, setDuplicateIDs] = useState<string[]>([])
  const [currentAddCoinUsers, setCurrentAddCoinUsers] = useState<AddCoinUser[]>(
    [],
  )
  const [totalAddCoinUser, setTotalAddCoinUser] = useState<AddCoinUser[]>([])
  const [pageProductIndex, setPageProductIndex] = useState<number>(0)
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  const { addCoin, loading, error } = useAddCoinUsers()
  const { notificationStore } = useStore()
  const { getMany } = useGetUser()
  const [processingLoading, setProcessingLoading] = useState(false)
  const [processingError, setProcessingError] = useState<unknown>()

  const navigate = useNavigate()
  const columns: EuiBasicTableColumn<AddCoinUser>[] = [
    {
      field: "id",
      name: t("add-reeeed-coin.table.column.id"),
    },
    {
      field: "username",
      name: t("add-reeeed-coin.table.column.username"),
    },
    {
      field: "email",
      name: t("add-reeeed-coin.table.column.email"),
    },
    {
      field: "coin",
      name: t("add-reeeed-coin.table.column.coin"),
    },
  ]

  const onSubmit = async (form: AddCoinForm) => {
    const res = await addCoin({
      name: form.name,
      noted: form.noted,
      addCoinUsers: form.addCoinUsers,
      password: form.password,
      idempotentId,
    })
    if (res && res.errorUserIds?.length === 0) {
      notificationStore.add({
        title: "Success",
        color: "success",
      })
      return {
        id: res.id,
      }
    }
    return {
      id: undefined,
    }
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<AddCoinForm, { id: string | undefined }>({
    id: undefined,
    onGetItem: async () => {
      return {
        name: "",
        noted: "",
        addCoinUsers: [],
        password: "",
      }
    },
    onError: (err: unknown) => {
      if (err instanceof Error) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: err.message,
        })
      }
    },
    onSubmit,
    onSubmitted: (resp) => {
      if (resp.id) {
        navigate(`/reeeed-coin/${resp.id}`, { replace: true })
      }
    },
    validationSchema: AddCoinFormValidator,
  })

  const handleUploadUsers = async (files: FileList | null) => {
    if (files && files[0]) {
      setProcessingLoading(true)
      setCurrentAddCoinUsers([])
      setTotalAddCoinUser([])
      setErrorIDs([])
      setDuplicateIDs([])
      setTotalItemCount(0)
      setPageProductIndex(0)
      const result = await files[0].arrayBuffer()
      const workbook = readXLSX(result)
      const wsname = workbook.SheetNames[0]
      const ws = workbook.Sheets[wsname]
      const resultInJson: AddCoinUser[] = utilsXLSX.sheet_to_json(ws)
      const ids = resultInJson.filter((r) => r).map((r) => r.id)
      const batchSize = 100

      const duplicateIds = ids.filter((id, index) => ids.indexOf(id) !== index)
      if (duplicateIds.length > 0) {
        setDuplicateIDs((oldDuplicateIDs) => [
          ...oldDuplicateIDs,
          ...duplicateIds,
        ])
        return
      }

      let userIndex = 0
      const errorRowIDs: string[] = []
      try {
        for (let i = 0; i < resultInJson.length; i += batchSize) {
          const res = await getMany({ userIds: ids.slice(i, i + batchSize) })
          for (let j = 0; j < (res?.users ?? []).length; j++) {
            if (res?.users?.[j]?.id === "") {
              errorRowIDs.push(resultInJson[userIndex].id)
            }

            userIndex++

            if (errorRowIDs.length > 0) {
              setErrorIDs((oldErrorRowIDs) => [
                ...oldErrorRowIDs,
                ...errorRowIDs,
              ])
              setCurrentAddCoinUsers([])
              setTotalAddCoinUser([])
              setTotalItemCount(0)
              setPageProductIndex(0)
              setValue("addCoinUsers", [])
            }
          }
        }
      } catch (err) {
        setProcessingError(err)
      } finally {
        if (errorRowIDs.length === 0) {
          setTotalAddCoinUser(resultInJson)
          setErrorIDs([])
          setCurrentAddCoinUsers(resultInJson.slice(0, paginationSize))
          setTotalItemCount(resultInJson.length)
          setValue(
            "addCoinUsers",
            resultInJson.map((r) => {
              return {
                userId: r.id,
                amount: r.coin * constants.power10OfDecimalPrice,
              }
            }),
          )
          setPageProductIndex(0)
        }

        setProcessingLoading(false)
      }
    }
  }

  const goToNextPage = (pageIndex: number) => {
    setCurrentAddCoinUsers(
      totalAddCoinUser
        .slice(pageIndex * paginationSize, (pageIndex + 1) * paginationSize)
        .map((r) => {
          return {
            id: r.id,
            username: r.username,
            email: r.email,
            coin: r.coin,
          }
        }),
    )
    setValue(
      "addCoinUsers",
      totalAddCoinUser.map((r) => {
        return {
          userId: r.id,
          amount: r.coin * constants.power10OfDecimalPrice,
        }
      }),
    )
    setPageProductIndex(pageIndex)
  }

  useAlertIfDefaultErr([error, processingError])
  if (id && id !== "create") {
    return <DetailAddReeeedCoin id={id} />
  }

  const loadingState = loading || processingLoading

  return (
    <div className="flex w-full max-w-screen-xl flex-col">
      <div className="grid grid-cols-3">
        <div className="col-span-2 flex flex-col gap-y-6">
          <Title text={t("add-reeeed-coin.title")} />
          <EuiFormRow label={t("add-reeeed-coin.form.promotion-name-label")}>
            <EuiFieldText
              placeholder={t("add-reeeed-coin.form.promotion-name-placeholder")}
              value={form.name}
              isInvalid={!!errors.name}
              onChange={(e) => {
                setValue("name", e.target.value)
              }}
            />
          </EuiFormRow>

          <EuiHorizontalRule />

          <EuiText>
            <h3>{t("add-reeeed-coin.add-coin-header")}</h3>
          </EuiText>
          <UploadItemSection
            errorIds={errorIDs}
            duplicateIds={duplicateIDs}
            export={() => {
              const url = `${
                publicConfig.publicUrl
              }/excel-template-upload/add-coin-template.xlsx`
              window.open(url, "_blank")
            }}
            loading={loadingState}
            handleUpload={handleUploadUsers}
            items={currentAddCoinUsers}
            tableColumns={columns}
            pagination={{
              pageIndex: pageProductIndex,
              pageSize: paginationSize,
              totalItemCount,
              showPerPageOptions: false,
            }}
            onChangePagination={async (p: {
              page: {
                index: number
                size: number
              }
            }) => {
              goToNextPage(p.page.index)
            }}
            defaultFileName="Template.xlsx"
            exampleTemplateStaticPath={`${
              publicConfig.publicUrl
            }/excel-template-upload/add-coin-template.xlsx`}
          />
          <EuiHorizontalRule />

          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row gap-x-2">
              <EuiText>
                <h3>{t("add-reeeed-coin.form.noted-label")}</h3>
              </EuiText>
              <EuiText>
                <p className="text-grey">
                  {t("add-reeeed-coin.form.noted-optional")}
                </p>
              </EuiText>
            </div>

            <EuiTextArea
              fullWidth
              placeholder={t("add-reeeed-coin.form.noted-placeholder")}
              value={form.noted}
              isInvalid={!!errors.noted}
              onChange={(e) => {
                setValue("noted", e.target.value)
              }}
            />
          </div>

          <EuiFormRow label={t("add-reeeed-coin.form.password-label")}>
            <EuiFieldText
              placeholder={t("add-reeeed-coin.form.password-placeholder")}
              value={form.password}
              isInvalid={!!errors.password}
              type="password"
              onChange={(e) => {
                setValue("password", e.target.value)
              }}
            />
          </EuiFormRow>

          <EuiButton
            color="primary"
            fill
            fullWidth
            onClick={submit}
            className={"mt-4 w-[280px]"}
            isLoading={loadingState}
          >
            {t("common.save")}
          </EuiButton>
        </div>
      </div>
    </div>
  )
}
