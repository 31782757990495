import { useApi } from "@app/hook/api"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { getAssetPath } from "@app/model/asset"
import { getTranslationFromBankCode } from "@app/model/bank-account"
import { definitions as OrderDefinitions } from "@app/vendor/order-specs/order_service"
import { definitions } from "@app/vendor/shop-specs/shop_service"
import { useTranslation } from "react-i18next"
import useSWR from "swr"

export type FindShopProps = {
  status?: definitions["shop.v1.WithdrawAccountVerificationStatus"][]
  searchText?: string
  isBanned?: boolean
  limit?: number
  skip?: number
}

export const useShop = () => {
  const { shop, order } = useApi()

  const findShop = async (props: FindShopProps) => {
    const result = await shop("/shop").method("get").create()({
      sorter: "SHOP_SORTER_UPDATED_AT_DESC",
      "filter.status": props.status ? props.status : undefined,
      "filter.searchText": props.searchText ? props.searchText : undefined,
      "filter.isBanned": props.isBanned,
      "option.limit": props.limit ? props.limit : undefined,
      "option.skip": props.skip ? props.skip : undefined,
    })

    return {
      shops: result.data.shop,
      count: result.data.count,
    }
  }

  const getAccountStatusCount = async () => {
    const result = await shop("/account-status-count").method("get").create()(
      {},
    )

    return result.data
  }

  const getTotalOrder = async (shopId: string) => {
    const result = await order("/total-shop-order/{shopId}")
      .method("get")
      .create()({
      shopId,
    })

    return {
      numberOfOrder: result.data.totalNumberOfOrder,
    }
  }

  const getShopRevenue = async (id: string) => {
    const res = await order("/shop-revenue-ratio/{id}").method("get").create()({
      id,
    })

    return res
  }

  const storeShopRevenue = async (
    input: OrderDefinitions["order.admin.v1.ShopRevenueRatioInput"],
  ) => {
    await order("/shop-revenue-ratio").method("post").create()({
      input,
    })
  }

  return {
    findShop,
    getAccountStatusCount,
    getTotalOrder,
    storeShopRevenue,
    getShopRevenue,
  }
}

export const useWithdrawAccount = () => {
  const { shop } = useApi()

  const verifyWithdrawAccount = async (
    shopId: string,
    status: definitions["shop.v1.WithdrawAccountVerificationStatus"],
  ) => {
    await shop("/withdraw-account").method("post").create()({
      shopId,
      status,
    })
  }

  const getWithdrawAccount = async (shopId: string) => {
    const withdrawAccount = await shop("/withdraw-account/{id}")
      .method("get")
      .create()({
      id: shopId,
    })
    return withdrawAccount
  }

  const getDownloadUrl = async (
    shopId: string,
    documentType: definitions["shop.v1.DownloadDocumentWithdrawAccountType"],
  ) => {
    const res = await shop("/download-document-withdraw-account")
      .method("post")
      .create()({
      shopId,
      documentType,
    })
    return res.data.url
  }

  return {
    verifyWithdrawAccount,
    getWithdrawAccount,
    getDownloadUrl,
  }
}

export const useGetBankNameFromBankCode = (
  bankCode: definitions["shop.v1.BankCode"],
) => {
  const { t: SellerT } = useTranslation("translation", {})
  return SellerT(getTranslationFromBankCode(bankCode))
}

export const useGetBankAccountType = (
  bankAccountType: definitions["shop.v1.BankAccountType"],
) => {
  const { t: SellerT } = useTranslation("translation", {
    keyPrefix: "seller-management",
  })
  let accountType = "-"
  if (bankAccountType === "BANK_ACCOUNT_TYPE_CURRENT") {
    accountType = SellerT("bank-account-type-current")
  }

  if (bankAccountType === "BANK_ACCOUNT_TYPE_FIXED") {
    accountType = SellerT("bank-account-type-fixed")
  }

  if (bankAccountType === "BANK_ACCOUNT_TYPE_FOREIGN_CURRENCY") {
    accountType = SellerT("bank-account-type-foreign-currency")
  }

  if (bankAccountType === "BANK_ACCOUNT_TYPE_SAVING") {
    accountType = SellerT("bank-account-type-saving")
  }

  return accountType
}

// bank logo background-color from https://github.com/AomDEV/css-finances/blob/main/banks.css
export const getBankIconAndColorPath = (
  bankCode?: definitions["shop.v1.BankCode"],
) => {
  switch (bankCode) {
    case "BANK_CODE_BAAC":
      return {
        path: getAssetPath("images/bank/baac.svg"),
        backgroundColor: "#4b9b1d",
      }
    case "BANK_CODE_BAY":
      return {
        path: getAssetPath("images/bank/bay.svg"),
        backgroundColor: "#fec43b",
      }
    case "BANK_CODE_BBL":
      return {
        path: getAssetPath("images/bank/bbl.svg"),
        backgroundColor: "#1e4598",
      }
    case "BANK_CODE_GHB":
      return {
        path: getAssetPath("images/bank/ghb.svg"),
        backgroundColor: "#f57d23",
      }
    case "BANK_CODE_GSB":
      return {
        path: getAssetPath("images/bank/gsb.svg"),
        backgroundColor: "#eb198d",
      }
    case "BANK_CODE_KBANK":
      return {
        path: getAssetPath("images/bank/kbank.svg"),
        backgroundColor: "#138f2d",
      }
    case "BANK_CODE_KKP":
      return {
        path: getAssetPath("images/bank/kk.svg"),
        backgroundColor: "#199cc5",
      }
    case "BANK_CODE_KTB":
      return {
        path: getAssetPath("images/bank/ktb.svg"),
        backgroundColor: "#1ba5e1",
      }
    case "BANK_CODE_SCB":
      return {
        path: getAssetPath("images/bank/scb.svg"),
        backgroundColor: "#4e2e7f",
      }
    case "BANK_CODE_TISCO":
      return {
        path: getAssetPath("images/bank/tisco.svg"),
        backgroundColor: "#12549f",
      }
    case "BANK_CODE_TTB":
      return {
        path: getAssetPath("images/bank/ttb.svg"),
        backgroundColor: "#ecf0f1",
      }
    case "BANK_CODE_UOBT":
      return {
        path: getAssetPath("image/bank/uob.svg"),
        backgroundColor: "#0b3979",
      }
    default:
      return {}
  }
}

export const useGetTopSellingProduct = (shopId: string) => {
  const { webBff } = useApi()

  return useSWR("/admin/shop", async () => {
    const res = await webBff("/admin/shop/{shopId}/top-selling-product")
      .method("get")
      .create()({
      shopId,
    })
    return res.data
  })
}

export const useGetShop = (shopId: string) => {
  const { shop } = useApi()

  const { data, error, mutate, isLoading } = useSWR(
    ["/shop/{id}", shopId],
    async () => {
      return shop("/shop/{id}").method("get").create()({
        id: shopId,
      })
    },
  )
  useAlertIfDefaultErr([error])

  return {
    data: data?.data,
    refetch: mutate,
    isLoading,
  }
}

export const useBanShop = () => {
  const { shop } = useApi()

  const banShop = async (shopId: string) => {
    await shop("/ban-shop").method("put").create()({
      shopId,
    })
  }

  const unBanShop = async (shopId: string) => {
    await shop("/un-ban-shop").method("put").create()({
      shopId,
    })
  }

  return {
    banShop,
    unBanShop,
  }
}

export const useEnableEVoucher = () => {
  const { shop } = useApi()

  const enableEVoucher = async (shopId: string) => {
    await shop("/enable-e-voucher").method("post").create()({
      shopId,
    })
  }

  return {
    enableEVoucher,
  }
}

export const useEnableCancelOrder = () => {
  const { shop } = useApi()

  const enableCancelOrder = async (shopId: string, status: boolean) => {
    await shop("/enable-cancel-order").method("post").create()({
      shopId,
      status,
    })
  }

  return {
    enableCancelOrder,
  }
}
export const useEnableCodeRedemption = () => {
  const { shop } = useApi()

  const enableCodeRedemption = async (shopId: string, isActive: boolean) => {
    if (!isActive) {
      await shop("/disable-shop-coupon-redemption-campaign")
        .method("post")
        .create()({
        shopId,
      })
    } else {
      await shop("/enable-shop-coupon-redemption-campaign")
        .method("post")
        .create()({
        shopId,
      })
    }
  }

  return {
    enableCodeRedemption,
  }
}

export const useSetActiveInternationalShipping = () => {
  const { shop } = useApi()

  return async (shopId: string, isActive: boolean) => {
    await shop("/active-international-delivery/{id}").method("post").create()({
      id: shopId,
      isActive,
    })
  }
}

export const useToggleTaxInvoiceRequest = () => {
  const { shop } = useApi()

  const enable = async (shopId: string) => {
    await shop("/enable-tax-invoice-request").method("post").create()({
      shopId,
    })
  }

  const disable = async (shopId: string) => {
    await shop("/disable-tax-invoice-request").method("post").create()({
      shopId,
    })
  }

  return { enable, disable }
}
