import { css } from "@emotion/react"
import { FormInputImagePicker } from "@reeeed-mp/ui-common"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { TempFile } from "@app/model/form"
import { useTranslation } from "react-i18next"

interface ImagePickerProps {
  value: string | undefined
  onChange: (input: TempFile, blob: string) => void
  error?: string
  setLoading: (isSubmitting: boolean) => void
  placeholder?: React.ReactNode
  acceptType?: string
  width?: string
  height?: string
  maxFileSizeInKB?: number
}

export const ImagePicker = ({
  value,
  onChange,
  error,
  setLoading,
  placeholder,
  acceptType,
  width,
  height,
  maxFileSizeInKB,
}: ImagePickerProps) => {
  const { notificationStore } = useStore()
  const { uploadFile } = useUploadFile()
  const { t } = useTranslation()

  const commonStyles = {
    borderRadius: "6px !important",
  }

  const svgStyles = {
    width: "80px !important",
    height: "80px !important",
    ".euiImage": {
      ...commonStyles,
      width: "80px !important",
      height: "80px !important",
    },
    ".euiImage__img": {
      ...commonStyles,
      width: "80px !important",
      height: "80px !important",
    },
    ".euiFilePicker__prompt > svg": {
      margin: "0 !important",
    },
  }

  const defaultStyles = css({
    width: `${width || 736}px !important`,
    height: `${height || 366}px !important`,
    ".euiImage": {
      ...commonStyles,
      width: `${width || 736}px !important`,
      height: `${height || 366}px !important`,
      objectFit: "cover",
    },
    ".euiImage__img": {
      ...commonStyles,
      width: `${width || 736}px !important`,
      height: `${height || 366}px !important`,
      objectFit: "inherit",
    },
  })

  const cssProperty = acceptType === contentType.SVG ? svgStyles : defaultStyles

  return (
    <FormInputImagePicker
      isInvalid={!!error}
      css={css(cssProperty)}
      placeholder={placeholder || null}
      accept={acceptType}
      value={value || undefined}
      onChange={async (v: File | null) => {
        if (maxFileSizeInKB && v && v.size > (maxFileSizeInKB ?? 0) * 1000) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: t("common.exceed-max-file-size", {
              size: KBToMB(maxFileSizeInKB),
              unit: "MB",
            }),
          })

          return
        }
        if (v) {
          try {
            setLoading(true)
            const { input, blob } = await uploadFile(v)
            onChange(input, blob)
          } catch (err: any) {
            if (err && err.message) {
              notificationStore.add({
                title: "Error",
                color: "danger",
                text: err.message,
              })
            }
          } finally {
            setLoading(false)
          }
        }
      }}
    />
  )
}

export const KBToMB = (size: number): number => {
  return size / 1000
}
