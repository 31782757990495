import { InvalidArgumentsError } from "@reeeed-mp/foundation/error"
import { errToAppErr, errToDefaultI18nKey } from "@reeeed-mp/ui-common"

export const aggInvalidArgErr = (details: InvalidArgumentsError["details"]) => {
  const fieldErrs: { [key: string]: string | undefined } = {}
  let i = 0

  while (i < details.length) {
    fieldErrs[details[i].field] = details[i].description
    i++
  }
  return fieldErrs
}

export const mapErrToI18n = (prefix: string, err: unknown): string => {
  const appErr = errToAppErr(err)

  if (
    appErr["@type"] ===
    "type.googleapis.com/foundation.error.InvalidArgumentsError"
  ) {
    return `${prefix}.${appErr.details[0].description}`
  } else if (
    appErr["@type"] === "type.googleapis.com/foundation.error.BadRequestError"
  ) {
    return `${prefix}.${appErr.detail}`
  } else if (
    appErr["@type"] ===
    "type.googleapis.com/foundation.error.PermissionDeniedError"
  ) {
    return `${prefix}.permission-denied`
  }

  return errToDefaultI18nKey(appErr)
}
