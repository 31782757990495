import { publicConfig } from "@app/config"
import { useApi } from "@app/hook/api"
import { useStore } from "@app/hook/store"
import {
  EditorState,
  MpTextEditor,
  ToolbarPlugins,
} from "@reeeed-mp/text-editor"
import "@reeeed-mp/text-editor/dist/styles.css"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"

type TextEditorProps = {
  value: EditorState | null | undefined
  onChange: (state: EditorState) => void
  plugins: ToolbarPlugins[]
}

// TextEditor is not controlled input editor. The first value supply will be initial value.
export const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  plugins,
}) => {
  const { t } = useTranslation()
  const { notificationStore: noti } = useStore()
  const { authWeb } = useApi()
  const getUploadURL = useCallback(
    async (contentType: string) => {
      const r = await authWeb("/upload-url").method("post").create()({
        contentType,
      })

      return {
        signedUrl: r.data.signedUrl!,
        tmpUrl: r.data.tmpUrl!,
      }
    },
    [authWeb],
  )

  return (
    value && (
      <MpTextEditor
        isEditable
        initEditorState={value}
        onEditorStateChange={onChange}
        toolbarPlugins={plugins}
        getUploadURL={getUploadURL}
        maxUploadImage={publicConfig.richTextUploadImageMaxFileCount}
        onError={(err) => {
          noti.add({
            color: "warning",
            text: t(`editor.warning.${err.message}`),
          })
        }}
      />
    )
  )
}
