import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { Title } from "@app/component"
import {
  DropResult,
  EuiButton,
  EuiButtonEmpty,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiText,
} from "@elastic/eui"
import { useAdditionalSection, useHomeManagementStore } from "./store/ctx"
import { useTranslation } from "react-i18next"
import { CardBlock } from "./components/home-section-card"
import { LayoutConfig } from "./components/layout-config"
import { HeroBannerConfigSection } from "./hero-banner/config-section"
import { ModalConfirmation } from "./components/modal-confirmation"
import { MainMenuConfigSection } from "./main-menu/config-section"
import { NewAdditionalSectionButton } from "./components/new-section-button"
import {
  MappedAdditionalSection,
  MenuTypes,
  useReorderAdditionalSection,
  useSelectedMenu,
} from "./hook/model"
import {
  PopularProductSection,
  RecommmendedProductSection,
} from "./products/config-section"
import { useDebouncedCallback } from "use-debounce"
import { SmallBannerConfigSection } from "./small-banner/config-section"
import {
  FixedMediumBannerConfigSection,
  OverflowMediumBannerConfigSection,
} from "./medium-banner/config-section"
import { WriterBannerConfigSection } from "./writer-banner/config-section"
import { NewArrivalChapterProductSection } from "./new-arrival-chapter-product-section/config-section"

const SectionConfigContainer = ({
  selectedId,
}: {
  selectedId: string | undefined
}) => {
  const selectedMenu = useSelectedMenu(selectedId!)

  if (!selectedId || !selectedMenu) {
    return null
  }

  const menuComponentMap = {
    [MenuTypes.mainMenu]: MainMenuConfigSection,
    [MenuTypes.heroBanner]: HeroBannerConfigSection,
    [MenuTypes.recommendedProduct]: RecommmendedProductSection,
    [MenuTypes.popularProduct]: PopularProductSection,
    [MenuTypes.smallBanner]: SmallBannerConfigSection,
    [MenuTypes.midBannerContained]: FixedMediumBannerConfigSection,
    [MenuTypes.midBannerOverflow]: OverflowMediumBannerConfigSection,
    [MenuTypes.writer]: WriterBannerConfigSection,
    [MenuTypes.newArrivalChapterProduct]: NewArrivalChapterProductSection,
  }

  const ConfigComponent = menuComponentMap[selectedMenu]

  return (
    <LayoutConfig>
      {ConfigComponent && <ConfigComponent key={selectedId} />}
    </LayoutConfig>
  )
}

const HomeManagementEditorSection = () => {
  const { selectedId } = useHomeManagementStore()

  return (
    <div className="grid grid-cols-5 gap-4">
      <HomeManagementDisplay />
      <SectionConfigContainer selectedId={selectedId} />
    </div>
  )
}

export const HomeManagementNew = () => {
  return (
    <>
      <HomeManagementContainer>
        <HomeManagementEditorSection />
      </HomeManagementContainer>
      <ModalConfirmation hasDraft={false} />
    </>
  )
}

export const HomeManagementContainer = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { homeMenuList, discardChanges, commitChanges, refreshing } =
    useHomeManagementStore()
  const { t } = useTranslation()

  const [isEdited, setIsEdited] = React.useState(false)

  useEffect(() => {
    if (homeMenuList?.state === "PAGE_LAYOUT_STATE_DRAFT") {
      setIsEdited(true)
    } else {
      setIsEdited(false)
    }
  }, [homeMenuList?.state])

  return (
    <>
      <div className="flex max-w-screen-xl flex-col gap-y-6">
        <div className="flex justify-between">
          <Title text="Home Management" />
          {isEdited && (
            <div className="flex gap-4">
              {homeMenuList?.initialized && (
                <EuiButtonEmpty
                  color="text"
                  onClick={discardChanges}
                  disabled={refreshing}
                >
                  {t("home-management.discard-changes")}
                </EuiButtonEmpty>
              )}
              <EuiButton
                fill
                onClick={() => commitChanges(homeMenuList!)}
                disabled={refreshing}
              >
                {t("common.save")}
              </EuiButton>
            </div>
          )}
        </div>
        {children}
      </div>
    </>
  )
}

const HomeManagementAdditionalSection = ({
  sections,
  setSections,
}: {
  sections: MappedAdditionalSection[]
  setSections: Dispatch<SetStateAction<MappedAdditionalSection[]>>
}) => {
  const { selectedId, setSelectedId, removeSection, errors } =
    useHomeManagementStore()
  const { reorder } = useReorderAdditionalSection()
  const debouncedReorder = useDebouncedCallback(
    async (reorderedSection: MappedAdditionalSection[]) => {
      await reorder(reorderedSection.map((section) => section.sectionKey!))
    },
    300,
  )

  const handleDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (sections && source && destination) {
        const reorderedSection = euiDragDropReorder(
          sections,
          source.index,
          destination.index,
        )
        setSections(reorderedSection)
        debouncedReorder(reorderedSection)
      }
    },
    [sections, setSections, debouncedReorder],
  )

  return (
    <div className="col-span-3">
      <EuiDragDropContext onDragEnd={handleDragEnd}>
        <EuiDroppable
          droppableId="CUSTOM_HANDLE_DROPPABLE_AREA"
          className="mb-4 flex flex-col p-0 shadow-none"
          withPanel
          spacing="m"
        >
          {(sections || []).map((props, idx) => {
            return (
              <EuiDraggable
                spacing="m"
                key={`eui-draggable-${idx}`}
                index={idx}
                draggableId={`eui-draggable-${idx}`}
                customDragHandle={true}
                hasInteractiveChildren={true}
                isRemovable={false}
              >
                {(provided) => (
                  <CardBlock
                    key={idx}
                    {...props}
                    dragHandleProps={provided.dragHandleProps!}
                    menu={props.menu}
                    name={props.detail.title}
                    onRemove={() => removeSection(props.sectionKey)}
                    count={props.count}
                    onSelect={() => setSelectedId(props.sectionKey)}
                    isSelected={selectedId === props.sectionKey}
                    layout={props.detail.layout}
                    hasError={errors
                      .map((e) => e.key)
                      ?.includes(props.sectionKey)}
                    draggable
                  />
                )}
              </EuiDraggable>
            )
          })}
        </EuiDroppable>
      </EuiDragDropContext>
      <NewAdditionalSectionButton disabled={false} />
    </div>
  )
}

const HomeManagementDisplay = () => {
  const {
    selectedId,
    setSelectedId,
    heroBannerSection,
    mainMenuSection,
    errors,
  } = useHomeManagementStore()
  const { sections, setSections } = useAdditionalSection()
  const { t } = useTranslation()

  return (
    <div className="col-span-2">
      <EuiText size="s" className="mb-4 font-bold">
        {t("home-management.display-section")}
      </EuiText>
      <div className="flex flex-col gap-2 p-1 pt-0">
        <CardBlock
          count={String(
            parseInt(mainMenuSection?.menuCount || "0", 10) +
              parseInt(mainMenuSection?.highlightMenuCount || "0", 10),
          )}
          menu={MenuTypes.mainMenu}
          onSelect={() => setSelectedId(mainMenuSection?.sectionKey)}
          isSelected={selectedId === mainMenuSection?.sectionKey}
        />
        <CardBlock
          count={heroBannerSection?.bannerCount || "0"}
          layout={heroBannerSection?.heroBannerSection?.layout}
          menu={MenuTypes.heroBanner}
          onSelect={() => setSelectedId(heroBannerSection?.sectionKey)}
          isSelected={selectedId === heroBannerSection?.sectionKey}
          hasError={errors
            .map((e) => e.key)
            ?.includes(heroBannerSection?.sectionKey ?? "")}
        />
      </div>

      <HomeManagementAdditionalSection
        sections={sections}
        setSections={setSections}
      />
    </div>
  )
}
