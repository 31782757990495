import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"

export const DropDown: React.FC<
  React.PropsWithChildren<{
    disabled?: boolean
    buttonClassName: string
    buttonIconClassName?: string
    buttonLabel?: string
    stopCloseOnClickSelf?: boolean
  }>
> = ({
  disabled = false,
  buttonLabel,
  buttonClassName,
  buttonIconClassName,
  children,
  stopCloseOnClickSelf,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isShowDropDown, setIsShowDropDown] = useState(false)

  const handleClose = () => {
    setIsShowDropDown(false)
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus()
    }
  }

  useEffect(() => {
    const button = buttonRef.current
    const dropDown = dropDownRef.current

    if (isShowDropDown && button !== null && dropDown !== null) {
      const { top, left } = button.getBoundingClientRect()
      dropDown.style.top = `${top + 40}px`
      dropDown.style.left = `${Math.min(
        left,
        window.innerWidth - dropDown.offsetWidth - 20,
      )}px`
    }
  }, [dropDownRef, buttonRef, isShowDropDown])

  useEffect(() => {
    const button = buttonRef.current

    if (button !== null && isShowDropDown) {
      const handle = (event: MouseEvent) => {
        const target = event.target
        if (stopCloseOnClickSelf) {
          if (
            dropDownRef.current &&
            dropDownRef.current.contains(target as Node)
          )
            return
        }
        if (!button.contains(target as Node)) {
          setIsShowDropDown(false)
        }
      }
      document.addEventListener("click", handle)

      return () => {
        document.removeEventListener("click", handle)
      }
    }
  }, [dropDownRef, buttonRef, isShowDropDown, stopCloseOnClickSelf])

  return (
    <div>
      <button
        disabled={disabled}
        className={buttonClassName}
        onClick={() => setIsShowDropDown(!isShowDropDown)}
        ref={buttonRef}
      >
        {buttonIconClassName && <span className={buttonIconClassName} />}
        {buttonLabel && (
          <span className="mpe-text mpe-dropdown-button-text">
            {buttonLabel}
          </span>
        )}
        <i className="mpe-chevron-down" />
      </button>

      {isShowDropDown &&
        createPortal(
          <DropDownItems onClose={handleClose} dropDownRef={dropDownRef}>
            {children}
          </DropDownItems>,
          document.body,
        )}
    </div>
  )
}

type DropDownContextType = {
  registerItem: (ref: React.RefObject<HTMLButtonElement>) => void
}

const dropDownContext = React.createContext<DropDownContextType | null>(null)

export const DropDownItems: React.FC<{
  children: React.ReactNode
  dropDownRef: React.Ref<HTMLDivElement>
  onClose: () => void
}> = ({ children, dropDownRef, onClose }) => {
  const [items, setItems] = useState<React.RefObject<HTMLButtonElement>[]>()
  const [highlightedItem, setHighlightedItem] =
    useState<React.RefObject<HTMLButtonElement>>()

  const registerItem = useCallback(
    (itemRef: React.RefObject<HTMLButtonElement>) => {
      setItems((prev) => (prev ? [...prev, itemRef] : [itemRef]))
    },
    [setItems],
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!items) return

    const key = event.key

    if (["Escape", "ArrowUp", "ArrowDown", "Tab"].includes(key)) {
      event.preventDefault()
    }

    if (key === "Escape" || key === "Tab") {
      onClose()
    } else if (key === "ArrowUp") {
      setHighlightedItem((prev) => {
        if (!prev) return items[0]
        const index = items.indexOf(prev) - 1
        return items[index === -1 ? items.length - 1 : index]
      })
    } else if (key === "ArrowDown") {
      setHighlightedItem((prev) => {
        if (!prev) return items[0]
        return items[items.indexOf(prev) + 1]
      })
    }
  }

  const contextValue = useMemo(
    () => ({
      registerItem,
    }),
    [registerItem],
  )

  useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0])
    }

    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus()
    }
  }, [items, highlightedItem])

  return (
    <dropDownContext.Provider value={contextValue}>
      <div className="mpe-dropdown" ref={dropDownRef} onKeyDown={handleKeyDown}>
        {children}
      </div>
    </dropDownContext.Provider>
  )
}

export const DropDownItem: React.FC<{
  children: React.ReactNode
  className: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
}> = ({ children, className, onClick, title }) => {
  const ref = useRef<HTMLButtonElement>(null)

  const ctx = React.useContext(dropDownContext)

  if (ctx === null) {
    throw new Error("DropDownItem must be used within a DropDown")
  }

  const { registerItem } = ctx

  useEffect(() => {
    if (ref && ref.current) {
      registerItem(ref)
    }
  }, [ref, registerItem])

  return (
    <button
      className={className}
      onClick={onClick}
      ref={ref}
      title={title}
      type="button"
    >
      {children}
    </button>
  )
}
