import { useTranslation } from "react-i18next"

export const ComingSoonPage = () => {
  const { t } = useTranslation("translation")
  return (
    <div>
      <h2 className="mb-2 text-xl font-bold">{t("coming-soon.title")}</h2>
      <p>{t("coming-soon.description")}</p>
    </div>
  )
}
