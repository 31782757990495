import { VerificationStatus } from "@app/component/withdraw-account/status"
import { definitions } from "@app/vendor/shop-specs/shop_service"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useGetShop } from "../hook"
import { cx } from "@emotion/css"
import { EuiButtonEmpty } from "@elastic/eui"
import { UilPen } from "@iconscout/react-unicons"
import { publicConfig } from "@app/config"
import { isDefined } from "@reeeed-mp/ui-common"

export const ContainerShopInfoCard: React.FC = () => {
  const { shopId } = useParams()
  const { data, isLoading } = useGetShop(shopId ?? "")
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <ShopInfoCard
      withBorder
      loading={isLoading}
      verificationStatus={
        data?.shop?.withdrawAccountVerificationStatus ??
        "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_UNSPECIFIED"
      }
      url={data?.shop?.cover?.path ?? ""}
      name={data?.shop?.name ?? ""}
      phoneNumber={data?.extendedShopDetail?.phoneNumber ?? ""}
      email={data?.extendedShopDetail?.email ?? ""}
      shopId={shopId ?? ""}
      enableEVoucher={data?.shop?.enabledEVoucher}
      enableInternationalShipping={data?.shop?.isActiveInternationalShipping}
      trailingButton={
        <EuiButtonEmpty
          className="border border-solid border-primary text-primary"
          onClick={() => {
            navigate(`/seller-management/${shopId}/setting`)
          }}
          css={{
            width: "180px",
          }}
          iconType={UilPen}
        >
          {t("shop-information.shop-setting")}
        </EuiButtonEmpty>
      }
    />
  )
}

type ShopInfoCardProps = {
  withBorder?: boolean
  url: string
  name: string
  phoneNumber: string
  email: string
  productNumber?: string
  numberOfOrder?: number
  verificationStatus: definitions["shop.v1.WithdrawAccountVerificationStatus"]
  loading: boolean
  loadingNumberOfOrder?: boolean
  shopId: string
  trailingButton?: React.ReactNode
  enableEVoucher?: boolean
  enableInternationalShipping?: boolean
}

export const ShopInfoCard: React.FC<ShopInfoCardProps> = (
  props: ShopInfoCardProps,
) => {
  const { t } = useTranslation()

  return (
    <div
      className={cx(
        "flex justify-between",
        props.withBorder &&
          "rounded-xl border border-solid border-[#D3DAE6] p-6",
      )}
    >
      <div className="flex space-x-6">
        <div className="flex items-center">
          {!props.loading ? (
            <img
              alt=""
              src={props.url}
              className="m-0 h-[80px] w-[80px] rounded-full"
            />
          ) : null}
        </div>
        <div className={classNames("flex flex-col space-y-3")}>
          {!props.loading ? (
            <div>
              <div className="mb-2 text-l font-bold">{props.name}</div>
              <VerificationStatus status={props.verificationStatus} />
            </div>
          ) : null}
          <div className="mt-2 flex space-x-16">
            {!props.loading && props.phoneNumber ? (
              <div>
                <div className="mb-1 text-s font-bold">
                  {t("shop-information.phone-number")}
                </div>
                <div>{props.phoneNumber}</div>
              </div>
            ) : null}
            {!props.loading && props.email ? (
              <div>
                <div className="mb-1 text-s font-bold">
                  {t("shop-information.email")}
                </div>
                <div>{props.email}</div>
              </div>
            ) : null}
            {!props.loading && isDefined(props.productNumber) ? (
              <div>
                <div className="mb-1 text-s font-bold">
                  {t("shop-information.product-amount")}
                </div>
                <div>
                  {t("shop-information.product-number", {
                    amount: props.productNumber,
                  })}
                </div>
              </div>
            ) : null}
            {!props.loadingNumberOfOrder && isDefined(props.numberOfOrder) ? (
              <div>
                <div className="mb-1 text-s font-bold">
                  {t("shop-information.order-amount")}
                </div>
                <div>
                  {t("shop-information.order-number", {
                    amount: props.numberOfOrder,
                  })}
                </div>
              </div>
            ) : null}
            {publicConfig.isFeatureInternationalShipping &&
            isDefined(props.enableInternationalShipping) ? (
              <div>
                <div className="mb-1 text-s font-bold">
                  {t("shop-information.feature.shipping.label")}
                </div>
                <div>
                  {props.enableInternationalShipping
                    ? t("shop-information.feature.shipping.international")
                    : t("shop-information.feature.shipping.domestic")}
                </div>
              </div>
            ) : null}
            {publicConfig.eVoucher && isDefined(props.enableEVoucher) ? (
              <div>
                <div className="mb-1 text-s font-bold">
                  {t("shop-information.feature.e-voucher.label")}
                </div>
                <div>
                  {props.enableEVoucher
                    ? t("shop-information.feature.e-voucher.enabled")
                    : t("shop-information.feature.e-voucher.disabled")}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="flex justify-end self-end">{props.trailingButton}</div>
    </div>
  )
}
