import i18n from "@app/i18n"
import moment from "moment"
import { PaymentChannel } from "./payment"
import { Price } from "./price"
import { PublishTimeline } from "./publish-timeline"

export type PackageType = "mobile" | "web"

export type TopupPackage = {
  id: string
  type: PackageType
  paymentType: PaymentChannel
  getCoin: Price
  chargeAmount: Price
  publishTimeline?: PublishTimeline | null
}

export type TotalTopupPackage = {
  type: PackageType
  paymentType: PaymentChannel
  total: number
}

export function paymentTypeToPackageType(p: PaymentChannel): PackageType {
  switch (p) {
    case "bank":
    case "credit-card":
    case "line-pay":
    case "shopee-pay":
    case "true-wallet-online":
    case "prompt-pay":
      return "web"
    default:
      throw new Error("conv payment type: should not reach here")
  }
}

export function validatePackage(
  p: Partial<TopupPackage>,
  setPublishTimeline = false,
): null | Map<string, string> {
  const { t } = i18n
  const errs = new Map()
  if (!p.getCoin || isNaN(p.getCoin)) {
    errs.set("getCoin", t("topup-package-err.get-coin"))
  }
  if (!p.chargeAmount || isNaN(p.chargeAmount)) {
    errs.set("chargeAmount", t("topup-package-err.charge-amount"))
  }
  if (setPublishTimeline) {
    if (!p.publishTimeline || !p.publishTimeline.start) {
      errs.set("publishStart", t("topup-package-err.publish-start"))
    }
    if (p.publishTimeline && p.publishTimeline.end) {
      if (p.publishTimeline.start >= p.publishTimeline.end) {
        errs.set("publishEnd", t("topup-package-err.publish-end.before-start"))
      } else if (
        moment(p.publishTimeline.end).isBefore(moment().add(5, "minute"))
      ) {
        errs.set("publishEnd", t("topup-package-err.publish-end.too-soon"))
      }
    }
  }
  if (!Array.from(errs.values()).length) {
    return null
  }
  return errs
}
