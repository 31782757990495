import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"

export type Address = {
  name: string
  code: string
}

export type PostCodeAddress = Address & {
  postcode?: string
}

export const provinces: Address[] = require("@reeeed-mp/foundation/dest/address/provinces.json")
export const districts: Address[] = require("@reeeed-mp/foundation/dest/address/districts.json")
export const subDistricts: PostCodeAddress[] = require("@reeeed-mp/foundation/dest/address/subdistricts.json")

export const getDistrictByCode = (code: string) => {
  const district = districts.find((s) => s.code === code)
  if (!district) {
    return undefined
  }

  return district
}

export const getProvinceByCode = (code: string) => {
  const province = provinces.find((s) => s.code === code)
  if (!province) {
    return undefined
  }

  return province
}

export const addressToString = (
  address: definitions["delivery.v1.Address"],
) => {
  const province = provinces.find((p) => p.code === address.provinceCode)
  const district = districts.find((d) => d.code === address.districtCode)
  const subDistrict = subDistricts.find((s) => s.postcode === address.postCode)

  return [
    address.address,
    subDistrict?.name,
    district?.name,
    province?.name,
    address.postCode,
  ]
    .filter((a) => a)
    .join(" ")
}
