import { paths as pathsAuthAdmin } from "@app/vendor/admin-auth-specs/admin_auth_service"
import { paths as pathsAuthWeb } from "@app/vendor/auth-specs/auth_service"
import { paths as deliveryPaths } from "@app/vendor/delivery-specs/admin_service"
import { paths as eLibraryPath } from "@app/vendor/e-library-specs/admin_service"
import { paths as orderPaths } from "@app/vendor/order-specs/order_service"
import { paths as payment } from "@app/vendor/payment-specs/payment_service"
import { paths as pathsWebUtility } from "@app/vendor/web-utility-specs/web_utility_service"
import { paths as pathsWebUtilityStaticPage } from "@app/vendor/web-utility-specs/web_utility_static_page_service"

import { newAuthMiddleware, newFetcher } from "../lib/api"

import { publicConfig } from "@app/config"
import { AuthStore } from "@app/store/auth"
import { paths as promotionPaths } from "@app/vendor/promotion-specs/promotion_service"
import { paths } from "@app/vendor/shop-specs/shop_service"
import { paths as webBffPaths } from "@app/vendor/web-bff-specs/web_bff_service"
import jwtDecode, { JwtPayload } from "jwt-decode"
import { Middleware } from "openapi-typescript-fetch"

const isAccessTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode<JwtPayload>(token)
    if (decoded?.exp) {
      return decoded?.exp < Math.round(new Date().getTime() / 1000) + 60
    }
    return false
  } catch (error) {
    return false
  }
}

export const newApi = (auth?: AuthStore) => {
  const middlewares: Middleware[] = auth
    ? [
        async (url, init, next) => {
          if (auth!.accessToken && isAccessTokenExpired(auth!.accessToken)) {
            await auth!.getAccessToken()
          }
          return next(url, init)
        },
        newAuthMiddleware(() => (auth?.accessToken ? auth.accessToken : "")),
      ]
    : []

  return {
    shop: newFetcher<paths>(publicConfig.shopEndpoint, middlewares).path,
    promotion: newFetcher<promotionPaths>(
      publicConfig.promotionEndpoint,
      middlewares,
    ).path,
    webBff: newFetcher<webBffPaths>(publicConfig.webBffEndpoint, middlewares)
      .path,
    authWeb: newFetcher<pathsAuthWeb>(publicConfig.authWebEndpoint, middlewares)
      .path,
    authAdmin: newFetcher<pathsAuthAdmin>(
      publicConfig.authAdminEndpoint,
      middlewares,
    ).path,
    webUtility: newFetcher<pathsWebUtility & pathsWebUtilityStaticPage>(
      publicConfig.webUtilityEndpoint,
      middlewares,
    ).path,
    payment: newFetcher<payment>(publicConfig.paymentEndpoint, middlewares)
      .path,
    order: newFetcher<orderPaths>(publicConfig.orderEndpoint, middlewares).path,
    eLibrary: newFetcher<eLibraryPath>(
      publicConfig.eLibraryEndpoint,
      middlewares,
    ).path,
    delivery: newFetcher<deliveryPaths>(
      publicConfig.deliveryEndpoint,
      middlewares,
    ).path,
  }
}
