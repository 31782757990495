import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from "lexical"

import { $applyNodeReplacement, DecoratorNode } from "lexical"
import { Suspense } from "react"
import { ImageComponent } from "./image-component"

export interface ImagePayload {
  altText: string
  src: string
  height?: number
  key?: NodeKey
  width?: number

  tempBucketUrl?: string
  isUploading?: boolean
  uploadFailed?: string
  isPublic?: boolean
  fullPath?: string
}

const convertImageElement = (domNode: Node): null | DOMConversionOutput => {
  if (domNode instanceof HTMLImageElement) {
    const { alt: altText, src, width, height } = domNode
    const node = $createImageNode({ altText, height, src, width })
    return { node }
  }
  return null
}

export type SerializedImageNode = Spread<
  {
    altText: string
    height?: number
    src: string
    width?: number
    tempBucketUrl?: string
    isPublic?: boolean
    fullPath?: string
    isUploading?: boolean
    uploadFailed?: string
  },
  SerializedLexicalNode
>

export class ImageNode extends DecoratorNode<JSX.Element> {
  src: string
  altText: string
  width: "inherit" | number
  height: "inherit" | number

  tempBucketUrl?: string
  isUploading: boolean = false
  uploadFailed?: string = undefined
  isPublic?: boolean = undefined // from backend
  fullPath?: string = undefined // from backend

  static getType(): string {
    return "image"
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.src,
      node.altText,
      node.width,
      node.height,
      node.__key,
      node.tempBucketUrl,
      node.isUploading,
      node.uploadFailed,
      node.isPublic,
      node.fullPath,
    )
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const {
      altText,
      height,
      width,
      src,
      isPublic,
      fullPath,
      uploadFailed,
      isUploading,
    } = serializedNode
    const node = $createImageNode({
      altText,
      height,
      src,
      width,
      isPublic,
      fullPath,
      isUploading,
      uploadFailed,
    })
    return node
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("img")
    element.setAttribute("src", this.src)
    element.setAttribute("alt", this.altText)
    element.setAttribute("width", this.width.toString())
    element.setAttribute("height", this.height.toString())
    return { element }
  }

  constructor(
    src: string,
    altText: string,
    width?: "inherit" | number,
    height?: "inherit" | number,
    key?: NodeKey,
    tempBucketUrl?: string,
    isUploading?: boolean,
    uploadFailed?: string,
    isPublic?: boolean,
    fullPath?: string,
  ) {
    super(key)
    this.src = src
    this.altText = altText
    this.width = width || "inherit"
    this.height = height || "inherit"
    this.tempBucketUrl = tempBucketUrl
    this.isUploading = isUploading || false
    this.isPublic = isPublic
    this.fullPath = fullPath
    this.uploadFailed = uploadFailed
  }

  exportJSON(): SerializedImageNode {
    return {
      altText: this.getAltText(),
      height: this.height === "inherit" ? 0 : this.height,
      src: this.getSrc(),
      type: "image",
      version: 1,
      width: this.width === "inherit" ? 0 : this.width,
      tempBucketUrl: this.tempBucketUrl, // backend will check this to move file into permanent bucket.
      isPublic: this.isPublic,
      isUploading: this.isUploading,
      uploadFailed: this.uploadFailed,
    }
  }

  setWidthAndHeight(
    width: "inherit" | number,
    height: "inherit" | number,
  ): void {
    const writable = this.getWritable()
    writable.width = width
    writable.height = height
  }

  setFinishedUploading(tempBucketUrl: string): void {
    const writable = this.getWritable()
    writable.tempBucketUrl = tempBucketUrl
    writable.isUploading = false
  }

  setUploadFailed(message: string): void {
    const writable = this.getWritable()
    writable.uploadFailed = message
    writable.isUploading = false
  }

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("span")
    const theme = config.theme
    const className = theme.image
    if (className !== undefined) {
      span.className = className
    }
    return span
  }

  updateDOM(): false {
    return false
  }

  getSrc(): string {
    return this.src
  }

  getAltText(): string {
    return this.altText
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={<>Some thing went wrong</>}>
        <ImageComponent
          src={this.fullPath || this.src}
          altText={this.altText}
          width={this.width}
          height={this.height}
          nodeKey={this.getKey()}
          resizable={true}
          isUploading={this.isUploading}
          isUploadFailed={this.uploadFailed !== undefined}
        />
      </Suspense>
    )
  }

  getTextContentSize(): number {
    return this.src.length
  }

  srcType(): "upload" | "url" {
    return this.src.startsWith("http") ? "url" : "upload"
  }
}

export const $createImageNode = ({
  altText,
  height,
  src,
  width,
  key,
  tempBucketUrl,
  isUploading,
  uploadFailed,
  isPublic,
  fullPath,
}: ImagePayload): ImageNode => {
  return $applyNodeReplacement(
    new ImageNode(
      src,
      altText,
      width,
      height,
      key,
      tempBucketUrl,
      isUploading,
      uploadFailed,
      isPublic,
      fullPath,
    ),
  )
}

export const $isImageNode = (
  node: LexicalNode | null | undefined,
): node is ImageNode => {
  return node instanceof ImageNode
}
