import { PaymentChannel } from "@app/model/payment"
import { EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import { theme } from "@reeeed-mp/ui-common"
import React from "react"
import { useTranslation } from "react-i18next"
import { GetPlatformCouponResponse } from "./hook/promotion"

export const ExamplePromotionDetailRenderer: React.FC<
  Omit<GetPlatformCouponResponse, "selectedUserIds"> & {
    discount?: string
    minNetPrice?: string
    paymentChannel?: PaymentChannel[]
    isForNewBuyer?: boolean
  }
> = (
  props: Omit<GetPlatformCouponResponse, "selectedUserIds"> & {
    discount?: string
    minNetPrice?: string
    paymentChannel?: PaymentChannel[]
    isForNewBuyer?: boolean
  },
) => {
  const { t } = useTranslation()

  return (
    <>
      {props.discount ? (
        <EuiText className="mt-4">
          <h2>
            {`${t("promotion.editor.discount-viewer")} ${props.discount}`}
          </h2>
        </EuiText>
      ) : (
        <EuiText className="mt-4">
          <h2>{t("promotion.editor.discount-value")}</h2>
        </EuiText>
      )}

      {props.platformCouponPromotion?.code ? (
        <EuiText
          css={css({
            color: theme.colors.LIGHT.primaryPurple,
          })}
        >
          {props.platformCouponPromotion?.code}
        </EuiText>
      ) : (
        <EuiText>{t("promotion.editor.promotion-code")}</EuiText>
      )}

      {props.minNetPrice ? (
        <EuiText size="s" className="mt-4">
          {props.minNetPrice}
        </EuiText>
      ) : (
        <EuiText className="mt-4">
          {t("promotion.editor.condition-buyer")}
        </EuiText>
      )}

      {props.platformCouponPromotion?.startAt &&
      props.platformCouponPromotion.endAt ? (
        <div className="flex">
          <EuiText size="xs" className="mt-1 text-grey">
            {t("common.date", {
              value: props.platformCouponPromotion?.startAt,
            })}{" "}
            {" - "}
          </EuiText>
          <EuiText size="xs" className="ml-1 mt-1 text-grey">
            {t("common.date", {
              value: props.platformCouponPromotion?.endAt,
            })}
          </EuiText>
        </div>
      ) : (
        <EuiText className="mt-1" size="xs">
          {t("promotion.editor.datetime-can-use")}
        </EuiText>
      )}

      {props.isForNewBuyer ? (
        <EuiText size="s" className="mt-4">
          {t("promotion.editor.new-user")}
        </EuiText>
      ) : null}

      {props.paymentChannel && props.paymentChannel.length > 0 ? (
        <div className="mt-4">
          <EuiText className="mr-1 flex-none" size="s">
            {t("promotion.editor.only-payment-channel")}
          </EuiText>

          {props.paymentChannel.map((c: PaymentChannel) => {
            //   return `
            //     ${i === 0 ? "" : ", "}
            //     ${t(`payment-channel.${c}`)}
            //  `
            return (
              <EuiText size="s">{`- ${t(`payment-channel.${c}`)}`}</EuiText>
            )
          })}
        </div>
      ) : (
        <EuiText className="mr-1 mt-4" size="s">
          {t("promotion.editor.can-use-all-payment-channel")}
        </EuiText>
      )}

      <EuiText className="mt-6" size="s">
        {props.platformCouponPromotion?.additional
          ? props.platformCouponPromotion.additional
          : t("promotion.editor.additional-and-condition")}
      </EuiText>
    </>
  )
}
