import { getDistrictByCode, getProvinceByCode } from "@app/model/address"
import { definitions } from "@app/vendor/shop-specs/shop_service"
import { useTranslation } from "react-i18next"
import { WithdrawAccountCard } from "./card"
import { WithdrawAccountText } from "./text"
import React from "react"

export const AddressWithdrawAccountCard = (props: {
  address: definitions["shop.v1.WithdrawAddress"]
  title: string
}) => {
  const { address, title } = props
  const { t: SellerT } = useTranslation("translation", {
    keyPrefix: "seller-management",
  })

  const district = getDistrictByCode(address.districtCode ?? "")
  const province = getProvinceByCode(address.provinceCode ?? "")

  return (
    <WithdrawAccountCard
      section={[
        {
          title,
          data: [
            {
              label: SellerT("house-number"),
              value: <WithdrawAccountText text={address.houseNumber ?? ""} />,
            },
            {
              label: SellerT("village-number"),
              value: <WithdrawAccountText text={address.villageNumber ?? ""} />,
            },
            {
              label: SellerT("building-name"),
              value: <WithdrawAccountText text={address.buildingName ?? ""} />,
            },
            {
              label: SellerT("address"),
              value: <WithdrawAccountText text={address.address ?? ""} />,
            },
            {
              label: SellerT("district"),
              value: <WithdrawAccountText text={district?.name ?? ""} />,
            },
            {
              label: SellerT("province"),
              value: <WithdrawAccountText text={province?.name ?? ""} />,
            },
            {
              label: SellerT("post-code"),
              value: <WithdrawAccountText text={address.postCode ?? ""} />,
            },
          ],
        },
      ]}
    />
  )
}
