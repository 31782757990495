import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/payment-specs/payment_service"
import useSWR from "swr"
import { usePromotionApi } from "../../discount/hook/promotion"

export const useCountTopupPackages = () => {
  const { payment } = useApi()

  return useSWR("/count-topup-packages", async () => {
    const res = await payment("/admin/count-topup-package")
      .method("get")
      .create()({})
    return res.data.packages
  })
}

export const usePackages = (
  paymentChannel?: definitions["payment.v1.PaymentChannel"],
) => {
  const { payment } = useApi()

  return useSWR(
    !paymentChannel ? null : `/topup-packages/${paymentChannel}`,
    async () => {
      const res = await payment("/admin/topup-package/{paymentChannel}")
        .method("get")
        .create()({ paymentChannel: paymentChannel! })
      return res.data
    },
  )
}

export const useTopupCouponPromotion = (id?: string) => {
  const { promotion } = useApi()
  return useSWR(!id ? null : ["/topup-coupon-promotion", id], async () => {
    const res = await promotion("/topup-coupon-promotion/{promotionId}")
      .method("get")
      .create()({ promotionId: id! })
    return res.data
  })
}

export const useCountTopupCouponPromotionUsage = (promotionId: string) => {
  const { countTopupCouponUsage } = usePromotionApi()
  const { data, error, mutate, isLoading } = useSWR(
    "/count-topup-coupon-promotion-usage/{promotionId}",
    async (): Promise<number> => {
      const res = await countTopupCouponUsage({ promotionId })
      return res.data ? Number(res.data.count) : 0
    },
  )

  return {
    data,
    loading: isLoading,
    error,
    mutate,
  }
}
