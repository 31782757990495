import { useApi } from "@app/hook/api"
import { useAlertDefaultErr } from "@app/hook/error"
import { useStore } from "@app/hook/store"
import { useWrapAsyncWithLoading } from "@app/lib/async"
import {
  ActionButtonInDetail,
  CountCouponUsage,
  Headline,
  StatusBadge,
} from "@app/page/coupon-promotion/component"
import {
  SelectJoinPackage,
  SummaryTopupCouponDetail,
} from "@app/page/coupon-promotion/topup/component"
import { apiToForm } from "@app/page/coupon-promotion/topup/conv"
import {
  useCountTopupCouponPromotionUsage,
  useTopupCouponPromotion,
} from "@app/page/coupon-promotion/topup/hook"
import {
  CouponEffectType,
  CouponType,
  TopupCoupon,
} from "@app/page/coupon-promotion/topup/types"
import { LIGHTEST_GREY } from "@app/utils/color"
import { EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

export const TopupCouponPromotionDetail: React.FC = () => {
  const params = useParams()
  const promotionId = params.id!
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    data,
    isLoading,
    mutate: refetchPromotion,
  } = useTopupCouponPromotion(promotionId)
  const { data: countUsage } = useCountTopupCouponPromotionUsage(promotionId)
  const { loading, wrap } = useWrapAsyncWithLoading()
  const { promotion } = useApi()
  const alertDefaultErr = useAlertDefaultErr()
  const { notificationStore } = useStore()

  let coupon: TopupCoupon | null = null

  if (data) {
    coupon = apiToForm(data)
  }

  const timeline = { startAt: coupon?.startAt, endAt: coupon?.endAt }

  return (
    <div className="my-0 grid max-w-screen-xl grid-cols-3">
      <div className="col-span-2 flex flex-col">
        {isLoading ? null : (
          <>
            <Headline
              onGoBackClick={() => navigate(-1)}
              text={coupon?.name ?? ""}
              action={
                <ActionButtonInDetail
                  loading={loading}
                  codeActive={timeline}
                  disableRenew={false}
                  promotionId={promotionId}
                  onRenew={wrap(async () => {
                    // TODO
                    // navigate(`/promotion/create?renew=${promotionId}`, {
                    //   replace: true,
                    // })
                    notificationStore.add({
                      title: "Warning",
                      color: "warning",
                      text: "comming soon",
                    })
                  })}
                  onEdit={() => {
                    navigate(`/topup-coupon-promotion/${promotionId}`)
                  }}
                  onEnd={wrap(async () => {
                    try {
                      await promotion(
                        "/topup-coupon-promotion/{promotionId}/end",
                      )
                        .method("post")
                        .create()({ promotionId })

                      refetchPromotion()
                    } catch (e) {
                      alertDefaultErr(e)
                    }
                  })}
                  onDelete={wrap(async () => {
                    try {
                      await promotion("/topup-coupon-promotion/{promotionId}")
                        .method("delete")
                        .create()({ promotionId })

                      navigate(-1)
                    } catch (e) {
                      alertDefaultErr(e)
                    }
                  })}
                />
              }
            />
            <div className="mt-8 flex items-center">
              <EuiText size="s" style={{ color: LIGHTEST_GREY }}>
                {t("promotion.detail.transaction-date")}
              </EuiText>
              <EuiText size="xs" className="ml-2">
                {t("promotion.detail.promotion-create-datetime", {
                  value: data?.promotion?.createdAt,
                })}
              </EuiText>
            </div>
            <StatusBadge
              codeActive={{ startAt: coupon?.startAt, endAt: coupon?.endAt }}
            />

            {data?.promotion?.quota && (
              <CountCouponUsage
                usage={Number(countUsage) || 0}
                limit={Number(data?.promotion?.quota)}
              />
            )}

            <div
              className="mt-6 rounded-md p-8"
              css={css`
                border: solid 1px #e5e7eb;
              `}
            >
              {coupon && (
                <SummaryTopupCouponDetail
                  code={coupon.code}
                  startAt={coupon.startAt}
                  endAt={coupon.endAt}
                  additional={coupon.additional}
                  isDiscount={coupon.couponType === CouponType.Discount}
                  isByAmount={coupon.effectType === CouponEffectType.Amount}
                  amountValue={coupon.effect?.amount?.value}
                  percentageValue={coupon.effect?.percentage?.value}
                  percentageHaveMaxAmount={
                    coupon.effect?.percentage?.haveMaxAmount
                  }
                  percentageMaxAmount={coupon.effect?.percentage?.maxAmount}
                />
              )}
            </div>

            {coupon?.note && (
              <div className="mt-6">
                <EuiText size="xs" className="font-bold">
                  {t("promotion.editor.noted")}
                </EuiText>
                <EuiText size="s">{coupon.note}</EuiText>
              </div>
            )}

            {coupon?.collectCodeStartAt && (
              <div className="mt-6">
                <EuiText size="xs" className="font-bold">
                  {t("promotion.editor.collect-code-date-time")}
                </EuiText>
                <EuiText size="s">
                  {t("common.datetime", {
                    value: coupon?.collectCodeStartAt,
                  })}
                </EuiText>
              </div>
            )}

            <div className="mt-6">
              <EuiText size="m" className="font-bold">
                <h3>{t("promotion.editor.join-packages")}</h3>
              </EuiText>
              {data?.joinPackage?.all ? (
                <EuiText>
                  <p>{t("promotion.editor.join-package-all")}</p>
                </EuiText>
              ) : (
                <SelectJoinPackage
                  selectPackages={data?.joinPackage?.package?.ids || []}
                  editable={false}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
