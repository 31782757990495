import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { TitleAndButtonGroup } from "./product-title-button"
import {
  MpTextEditor,
  parseEditorState,
  stringifiedEmptyEditorState,
} from "@reeeed-mp/text-editor"
import { constants } from "@app/model/constants"
import { GalleriesCarousel } from "./carousel"
import { isDefined } from "@reeeed-mp/ui-common"
import { useGetPhysicalProduct } from "./hook"
import { ProductHiddenBadge } from "./product-hidden-badge"

export const PhysicalProductManagement = () => {
  const { t } = useTranslation()
  const { shopId, productId } = useParams()

  const shopProduct = useGetPhysicalProduct(shopId ?? "", productId ?? "")
  const physicalProduct = shopProduct.data?.physicalProduct
  const isHidden = physicalProduct?.physicalProduct?.physicalProduct?.isHidden

  const [gallerySelected, setGallerySelected] = useState("cover")

  const displayVariantPrice = (
    variants: definitions["shop.v1.PhysicalProductVariant"][],
    mainAttributeId: string,
  ): string => {
    for (let i = 0; i < variants.length; i++) {
      if (variants[i].mainAttributeId === mainAttributeId) {
        return t("product.price", {
          price: Number(variants[i].price) / constants.power10OfDecimalPrice,
        })
      }
    }
    return t("product.price", { price: "-" })
  }

  const ready = !!physicalProduct
  const galleries = [
    {
      id: "cover",
      url: physicalProduct?.physicalProduct?.coverUrl || "",
    },
    ...(physicalProduct?.physicalProduct?.gallariesUrl || []).map((url, i) => {
      return {
        id: `gal-${i}`,
        url,
      }
    }),
  ].filter(isDefined)

  return (
    <div className="flex h-full w-full flex-col space-y-4 px-4">
      <TitleAndButtonGroup />
      <div className="flex w-full flex-row rounded-md border border-solid border-slate-300 py-3">
        <div className="flex w-1/2 flex-col py-4">
          <div className="w-full text-center">
            {t("product.revenue-header")}
          </div>
          <div className="mt-2 w-full text-center text-xl font-semibold">
            {t("product.revenue", {
              revenue:
                Number(physicalProduct?.revenue) /
                constants.power10OfDecimalPrice,
            })}
          </div>
        </div>
        <div className="flex w-1/2 flex-col border border-y-0 border-r-0 border-solid border-slate-300 py-4">
          <div className="w-full text-center">{t("product.sell-count")}</div>
          <div className="mt-2 w-full text-center text-xl font-semibold">
            {t("product.buy-count", {
              buyCount: physicalProduct?.buyCount,
            })}
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row pt-6">
        {galleries[0].url !== "" || galleries[1] ? (
          <div className="mr-14 w-2/5">
            <div className="h-96 w-full shrink-0">
              <GalleriesCarousel
                desktopMode={true}
                ready={ready}
                onChange={setGallerySelected}
                selected={gallerySelected}
                galleries={galleries}
              />
            </div>
          </div>
        ) : (
          <div className="w-2/5">
            <div className="h-96 w-96 rounded-lg bg-slate-200"></div>
          </div>
        )}
        <div className="w-3/5">
          <div className="flex">
            <div className="w-2/3 text-xl font-semibold">
              {physicalProduct?.physicalProduct?.physicalProduct?.name}
            </div>
            <div className="w-1/3 justify-items-end">
              <ProductHiddenBadge isHidden={isHidden} />
            </div>
          </div>
          {physicalProduct?.physicalProduct?.physicalProduct?.tags?.length ? (
            <div className="mt-4 flex flex-wrap text-base">
              {(
                physicalProduct?.physicalProduct?.physicalProduct?.tags ?? []
              ).map((tag) => (
                <div className="mb-2 mr-2 rounded-md bg-slate-200 px-2 py-1">
                  {tag}
                </div>
              ))}
            </div>
          ) : null}
          <div className="mt-4 w-full text-base">
            <div className="font-semibold">{t("product.category")}</div>
            {(physicalProduct?.physicalProduct?.categories ?? []).map(
              (category, index) => (
                <span>
                  {index === 0 ? category?.name : `, ${category?.name}`}
                </span>
              ),
            )}
          </div>
          {physicalProduct?.physicalProduct?.physicalProduct?.variants
            ?.length ? (
            <div className="mt-4 flex flex-row text-base">
              <div className="w-1/2">
                <div className="font-semibold">
                  {t("product.weight-header")}
                </div>
                <div className="mt-2 text-gray-600">
                  {t("product.weight", {
                    weight:
                      Number(
                        physicalProduct?.physicalProduct?.physicalProduct
                          ?.variants[0]?.weightInMg,
                      ) / constants.power10OfThousand,
                  })}
                </div>
              </div>
              <div className="w-1/2">
                <div className="font-semibold">{t("product.size-header")}</div>
                <div className="mt-2 text-gray-600">
                  {t("product.size", {
                    width:
                      Number(
                        physicalProduct?.physicalProduct?.physicalProduct
                          ?.variants[0]?.widthInMm,
                      ) / 10,
                    long:
                      Number(
                        physicalProduct?.physicalProduct?.physicalProduct
                          ?.variants[0]?.longInMm,
                      ) / 10,
                    height:
                      Number(
                        physicalProduct?.physicalProduct?.physicalProduct
                          ?.variants[0]?.heightInMm,
                      ) / 10,
                  })}
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-4 flex w-full flex-col">
            <div className="w-1/3">
              <div className="font-semibold">
                {t("product.price-and-choice")}
              </div>
              {physicalProduct?.physicalProduct?.physicalProduct
                ?.mainAttributeSet ? (
                (
                  physicalProduct?.physicalProduct?.physicalProduct
                    ?.mainAttributeSet?.attributes ?? []
                ).map((attr) => (
                  <div className="mt-2 flex w-full flex-row text-gray-600">
                    <div className="w-1/2">{attr.name}</div>
                    <div className="w-1/2">
                      {displayVariantPrice(
                        physicalProduct?.physicalProduct?.physicalProduct
                          ?.variants ?? [],
                        attr.id ?? "",
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-2 flex w-full flex-row text-gray-600">
                  <div className="w-1/2">{t("product.variant-default")}</div>
                  <div className="w-1/2">
                    {t("product.price", {
                      price: physicalProduct?.physicalProduct?.physicalProduct
                        ?.variants
                        ? Number(
                            physicalProduct?.physicalProduct?.physicalProduct
                              ?.variants[0].price,
                          ) / constants.power10OfDecimalPrice
                        : 0,
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-6">
        <div className="mb-2 text-xl font-semibold">{t("product.detail")}</div>
        <MpTextEditor
          isEditable={false}
          initEditorState={parseEditorState(
            physicalProduct?.physicalProduct?.physicalProduct?.detail ??
              stringifiedEmptyEditorState,
          )}
        />
      </div>
    </div>
  )
}
