import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useState } from "react"

export const useStoreHomeManagementApi = () => {
  const { webUtility } = useApi()
  const [loading, setLoading] = useState(false)

  const creator = webUtility("/admin/customized-homepage")
    .method("post")
    .create()
  const getter = webUtility("/admin/customized-homepage").method("get").create()

  const store = async (
    input: definitions["webutility.admin.v1.GetCustomizedHomepageResponse"],
  ) => {
    setLoading(true)
    try {
      const { data } = await creator(input)
      return data
    } catch (err) {
      if (err) {
        throw err
      }
    } finally {
      setLoading(false)
    }
  }

  const get = async () => {
    setLoading(true)
    try {
      const { data } = await getter({})
      return data
    } catch (err) {
      if (err) {
        throw err
      }
    } finally {
      setLoading(false)
    }
  }

  return {
    store,
    get,
    loading,
  }
}

export const useGetHighlightProductApi = () => {
  const { webBff } = useApi()
  const [loading, setLoading] = useState(false)
  const getter = webBff("/admin/product").method("post").create()
  const [error, setError] = useState<unknown>()

  const get = async (
    productKeys: definitions["webutility.admin.v1.HighlightProductKey"][],
  ) => {
    setLoading(true)
    try {
      const { data } = await getter({
        productKeys,
      })
      return data
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return {
    error,
    get,
    loading,
  }
}
