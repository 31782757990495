import { useEffect, useRef, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { isEqual } from "lodash-es"
import { useHomeManagementStore } from "../store/ctx"
import { ErrorSectionType, validateHomeLayout } from "./model"
import { t } from "i18next"
import { useAdminProductApi } from "../products/hook/update-detail"
export const useFormSubmit = (
  form: any,
  selectedSection: { [key: string]: any } | undefined,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { homeMenuList, errors, setErrors } = useHomeManagementStore()
  const submitSuccessRef = useRef(false)
  const { getExistProductIDs } = useAdminProductApi()

  useEffect(() => {
    validate()
  }, [homeMenuList])

  const validate = async () => {
    if (submitSuccessRef.current) {
      const invalidSectionKeys = await validateHomeLayout(
        homeMenuList ?? {},
        getExistProductIDs,
      )
      if (errors.length > 0 && invalidSectionKeys.length > 0) {
        setErrors(invalidSectionKeys)
      } else {
        setErrors([])
      }
      submitSuccessRef.current = false
    }
  }

  const debounceSubmit = useDebouncedCallback(async () => {
    try {
      await form.submitForm()
      submitSuccessRef.current = true
      setIsSubmitting(false)
    } catch (error) {
      setIsSubmitting(false)
    }
  }, 300)

  useEffect(() => {
    if (
      Object.keys(form.values).length !== 0 &&
      !isEqual(form.values, selectedSection)
    ) {
      setIsSubmitting(true)
      debounceSubmit()
    }
  }, [form.values])

  return isSubmitting
}

export const useInitializeForm = (
  form: any,
  selectedSection: { [key: string]: any } | undefined,
  selectedId: string,
) => {
  const { errors } = useHomeManagementStore()

  useEffect(() => {
    if (errors.length > 0) {
      errors.find((error) => {
        if (error.key === selectedId) {
          if (error.errorType === ErrorSectionType.Title) {
            form.setErrors({
              title: t("home-managemnet.error.title-missing"),
            })
          } else if (error.errorType === ErrorSectionType.Product) {
            form.setErrors({
              adminSelectedProductKeys: t(
                "home-management.error.product-is-deleted-or-not-exist",
              ),
            })
          }
        }
      })
    } else {
      form.setErrors([])
    }
  }, [errors])

  const isInitialized = useRef(false)
  useEffect(() => {
    if (!isInitialized.current && selectedSection) {
      form.setValues({
        ...selectedSection,
        layout: selectedSection?.layout,
      })
      isInitialized.current = true
    }
  }, [selectedSection, selectedId])
}
