import * as Yup from "yup"
import { FileForm } from "../form"
import { conditionalCoverShapeValidator } from "./cover"

export type HighlightBannerForm = {
  id?: string
  name?: string
  url?: string
  imageAlt?: string
  cover?: FileForm
  startDate?: moment.Moment
  endDate?: moment.Moment
  downloadUrl?: string
}

export const editorHighlightFormValidator = Yup.object({
  name: Yup.string().required(),
  cover: conditionalCoverShapeValidator("downloadUrl"),
  startDate: Yup.date().required(),
  endDate: Yup.date().required(),
  url: Yup.string().required(),
})
