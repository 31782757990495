import { useEffect } from "react"

export const useUnMount = (fn: () => Promise<void>) => {
  useEffect(() => {
    return () => {
      fn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
