import { Table, TableRefImperative } from "@app/component/table"
import { publicConfig } from "@app/config"
import { EuiBasicTableColumn } from "@elastic/eui"
import { css } from "@emotion/react"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  useActiveLandingPage,
  useDeleteLandingPage,
  useGetLandingPage,
  useListLandingPage,
} from "../hooks"
import { LandingPageRow } from "../type"
import { Options } from "./index"

export const BasicTableLandingPage = ({
  isActive,
  searchText,
}: {
  isActive: boolean
  searchText: string
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onGetItems, isLoading } = useListLandingPage()
  const removeLandingPage = useDeleteLandingPage()
  const activeLandingPage = useActiveLandingPage()
  const getLandingPage = useGetLandingPage()

  const columns: EuiBasicTableColumn<LandingPageRow>[] = [
    {
      field: "title",
      name: t("landing-page.table.table.landing-page"),
      truncateText: true,
      mobileOptions: {
        header: false,
        truncateText: false,
        enlarge: true,
        width: "100%",
      },
    },
    {
      field: "productAmount",
      name: t("landing-page.table.table.product-amount"),
      truncateText: true,
    },
    {
      field: "createdAt",
      sortable: true,
      name: t("landing-page.table.table.created-at"),
      truncateText: true,
      textOnly: true,
    },
    {
      align: "right",
      width: "40px",
      isExpander: true,
      onClick: (e) => {
        e.stopPropagation()
      },
      render: (row: LandingPageRow) => {
        return (
          <Options
            id={row.id}
            loading={false}
            isActive={row.isActive}
            onEdit={(id) => {
              navigate(id)
            }}
            onDelete={async (id) => {
              removeLandingPage(id).finally(() => {
                ref.current.refetch()
              })
            }}
            onCopyURL={(id) => {
              getLandingPage(id).then(({ landingPage }) => {
                navigator.clipboard.writeText(
                  `${publicConfig.webBaseUri}/pages/${landingPage?.slug}`,
                )
              })
            }}
            onActive={(id) => {
              activeLandingPage(id, !row.isActive).finally(() => {
                ref.current.refetch()
              })
            }}
          />
        )
      },
    },
  ]
  const ref = useRef() as TableRefImperative

  const [selectedItems, setSelectedItems] = useState<LandingPageRow[]>([])

  return (
    <Table<LandingPageRow>
      ref={ref}
      swrKey="/list-landing-page"
      selectedItems={selectedItems}
      onSelectedItemsChange={setSelectedItems}
      columns={columns}
      onGetItems={onGetItems}
      filter={{ isActive, searchText }}
      sorter={{ field: "createdAt", direction: "desc" }}
      loading={isLoading}
      css={css`
        .p-center {
          p {
            margin: auto 0;
          }
        }
      `}
      isCanSelect={true}
      onRowClick={(row) => {
        navigate(`detail/${row.id}`)
      }}
    />
  )
}
