import { EuiAccordion } from "@elastic/eui"
import React from "react"
import { Item, groupIdxToPagination } from "./common"
import { ChapterItem } from "./item"

export type ChapterGroupProps<T extends Item> = {
  groupIdx: number
  items: Array<T>
  totalItemCount: number
  limitItemPerGroup: number
  accordionPrefix: string
  mutate: () => void
  itemComp: (item: T, mutate: () => void) => React.ReactNode
  isHideAccordion: boolean
  isAccordionOpen: boolean
  toggleOpenAccordion: () => void
}

export const ChapterGroup = <T extends Item>(props: ChapterGroupProps<T>) => {
  const {
    items,
    totalItemCount,
    limitItemPerGroup,
    groupIdx,
    isHideAccordion,
    accordionPrefix,
    mutate,
    itemComp,
    isAccordionOpen,
    toggleOpenAccordion,
  } = props
  const pagination = groupIdxToPagination(groupIdx, limitItemPerGroup)

  const startAt = pagination.skip
  let endAt = startAt + limitItemPerGroup
  if (endAt > totalItemCount) {
    endAt = totalItemCount
  }

  const accordionTitle = `${accordionPrefix} ${startAt + 1} - ${endAt}`

  return (
    <>
      {isHideAccordion ? (
        <div>
          {items.map((item) => {
            return (
              <ChapterItem
                item={item}
                itemComp={itemComp}
                mutate={mutate}
                key={item.id}
              />
            )
          })}
        </div>
      ) : (
        <EuiAccordion
          id={`acc-${groupIdx}`}
          buttonContent={accordionTitle}
          buttonClassName="font-bold"
          className="-ml-1 py-5"
          forceState={isAccordionOpen ? "open" : "closed"}
          onToggle={toggleOpenAccordion}
        >
          {isAccordionOpen && (
            <div>
              {items.map((item) => {
                return (
                  <ChapterItem
                    item={item}
                    itemComp={itemComp}
                    mutate={mutate}
                    key={item.id}
                  />
                )
              })}
            </div>
          )}
        </EuiAccordion>
      )}
    </>
  )
}
