import { definitions } from "@app/vendor/shop-specs/shop_service"
import { EuiText } from "@elastic/eui"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const Status = (props: {
  title: string
  containerCss?: string
  textCss?: string
}) => {
  return (
    <div className={classNames("w-fit rounded px-2 px-2", props.containerCss)}>
      <EuiText size="xs">
        <p className={classNames("mb-0", props.textCss)}>{props.title}</p>
      </EuiText>
    </div>
  )
}

export const VerificationStatus = (props: {
  status: definitions["shop.v1.WithdrawAccountVerificationStatus"]
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "seller-management",
  })

  if (props.status === "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_APPROVED") {
    return (
      <Status
        title={t("verification-status-verified")}
        containerCss="bg-success"
      />
    )
  }

  if (props.status === "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_UNREGISTERED") {
    return (
      <Status
        title={t("verification-status-unregistered")}
        containerCss="bg-grey-neutral"
      />
    )
  }

  if (props.status === "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_VERIFYING") {
    return (
      <Status
        title={t("verification-status-verifying")}
        containerCss="bg-warning"
      />
    )
  }

  if (props.status === "WITHDRAW_ACCOUNT_VERIFICATION_STATUS_REJECTED") {
    return (
      <Status
        title={t("verification-status-rejected")}
        containerCss="bg-red"
        textCss={"text-white"}
      />
    )
  }

  return null
}
