import { TextEditor } from "@app/component"
import { useStore } from "@app/hook/store"
import { extractCategoryIdFromSeoId, seoHomepageId } from "@app/model/seo"
import { aggInvalidArgErr } from "@app/utils/error"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiTitle,
} from "@elastic/eui"
import { css } from "@emotion/react"
import {
  EditorState,
  ToolbarPlugins,
  isEditorStateReadyToExport,
  parseEditorState,
  stringifiedEmptyEditorState,
  stringifyEditorStateOrThrow,
  whyEditorStateIsNotReadyToExport,
} from "@reeeed-mp/text-editor"
import "@reeeed-mp/text-editor/dist/styles.css"
import {
  appErrorToDefaultI18nKey,
  errToAppErr,
  hooks,
  useFormHelper,
} from "@reeeed-mp/ui-common"
import _ from "lodash-es"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useGetCategory, useGetSeo, useUpdateSeo } from "./hook"

type Form = {
  id: string
  description: EditorState | null
}

type Seo = definitions["webutility.admin.v1.GetSeoResponse"]

const { useCallAsync } = hooks
const frontEndMaxWidth = 832

const inTransForm = (d: Seo): Form => {
  return {
    id: d.seo?.id!,
    description: parseEditorState(
      d.seo?.description ? d.seo.description : stringifiedEmptyEditorState,
    ),
  }
}

const outTransForm = (d: Form): Seo => {
  return {
    seo: {
      id: extractCategoryIdFromSeoId(d.id),
      description: d.description
        ? stringifyEditorStateOrThrow(d.description)
        : stringifiedEmptyEditorState,
    },
  }
}

export const SeoPageEditor: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams()
  const id = params.id === undefined ? "" : (params.id as string)
  const navigate = useNavigate()
  const { notificationStore } = useStore()
  const { fn: updateSeo, loading: updatingSeo } = useCallAsync(useUpdateSeo())
  const getSeo = useGetSeo()
  const { data: categories } = useGetCategory()
  const { isDirty, setIsDirty } = hooks.useConfirmLeaveWithoutSaved(
    t("common.confirm-not-save-data"),
  )

  const titleName =
    (categories &&
      categories.filter((a) => a.id === id).map((b) => b.name)[0]) ||
    ""

  const initialValues: Form = {
    id,
    description: null,
  }

  const {
    submitForm,
    setFieldValue,
    setValues,
    setErrors,
    values: form,
  } = useFormHelper<Form, Seo>({
    id,
    initialValues,
    onGetItem: async () => {
      const res = await getSeo(id)
      if (res?.id === "") {
        res.id = id
      }
      return inTransForm({ seo: res! })
    },
    onSubmit: async (form) => {
      const seo = outTransForm(form)
      const res = await updateSeo(seo)
      return res!
    },
    onSubmitted: async (res) => {
      notificationStore.add({
        title: "Success",
        color: "success",
        text: t("common.noti.saved"),
      })
      setValues(inTransForm(res))

      await setIsDirty(false)
      navigate(`/seo-editor/${res.seo?.id}`, { replace: true })
    },
    onError: (err) => {
      const e = errToAppErr(err)
      if (
        e["@type"] ===
        "type.googleapis.com/foundation.error.InvalidArgumentsError"
      ) {
        const fieldErrs = aggInvalidArgErr(e.details)
        setErrors({
          id: fieldErrs["id"],
          description: fieldErrs["description"],
        })
        return
      }
      const i18nKey = appErrorToDefaultI18nKey(e)
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t(i18nKey),
      })
    },
    validate: (v: Form) => {
      if (
        !_.isEmpty(v) &&
        !isDirty &&
        (id ? !_.isEqual(initialValues, form) : !_.isEqual(initialValues, v))
      ) {
        setIsDirty(true)
      }

      if (form.description && !isEditorStateReadyToExport(form.description)) {
        const reasons = whyEditorStateIsNotReadyToExport(form.description)
        for (const reason of reasons) {
          notificationStore.add({
            color: "warning",
            text: t(`static-page.editor.warning.${reason}`),
          })
        }

        return { description: "invalid" }
      }
    },
    validateOnChange: true,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div
        className="mb-8 flex h-10 items-center justify-between"
        css={css({
          width: 1119,
        })}
      >
        <EuiTitle size="m">
          <h2>
            {id === seoHomepageId
              ? t("seo-editor.footer", { footer: "Homepage" })
              : t("seo-editor.footer", { footer: titleName })}
          </h2>
        </EuiTitle>
        <div className="flex gap-2">
          <EuiButtonEmpty
            color="text"
            onClick={() => navigate("/seo-editor")}
            css={{ minWidth: 180 }}
          >
            {t("common.cancel")}
          </EuiButtonEmpty>
          <EuiButton
            isLoading={updatingSeo}
            onClick={submitForm}
            color="primary"
            fill
            css={{ minWidth: 180 }}
          >
            {t("common.save")}
          </EuiButton>
        </div>
      </div>
      <EuiForm>
        <EuiFormRow
          label={t("seo-editor.seo-text")}
          css={{
            maxWidth: frontEndMaxWidth,
            ".mpe-editor": {
              minHeight: "530px",
            },
          }}
        >
          <TextEditor
            value={form.description}
            onChange={(state) => setFieldValue("description", state)}
            plugins={[
              ToolbarPlugins.UndoRedo,
              ToolbarPlugins.BlockType,
              ToolbarPlugins.RichText,
              ToolbarPlugins.Alignment,
              ToolbarPlugins.Link,
            ]}
          />
        </EuiFormRow>
      </EuiForm>
    </>
  )
}
