import { definitions } from "@app/vendor/payment-specs/payment_service"
import { useTranslation } from "react-i18next"

export const useGetPaymentChannelText = () => {
  const { t } = useTranslation()

  return (channel?: definitions["payment.v1.PaymentChannel"]) => {
    if (channel === undefined) return ""

    switch (channel) {
      case "PAYMENT_CHANNEL_2C2P_BANK":
        return t("payment-channel.bank")
      case "PAYMENT_CHANNEL_2C2P_CREDIT_CARD":
        return t("payment-channel.credit-card")
      case "PAYMENT_CHANNEL_2C2P_LINE_PAY":
        return t("payment-channel.line-pay")
      case "PAYMENT_CHANNEL_2C2P_SHOPEE_PAY":
        return t("payment-channel.shopee-pay")
      case "PAYMENT_CHANNEL_2C2P_TRUE_WALLET_ONLINE":
        return t("payment-channel.true-wallet-online")
      case "PAYMENT_CHANNEL_COIN":
        return t("payment-channel.coin")
      case "PAYMENT_CHANNEL_PAYSOL_PROMPT_PAY":
        return t("payment-channel.prompt-pay")
      default:
        return "-"
    }
  }
}
