import { PackageType } from "@app/model"
import { PaymentChannel } from "@app/model/payment"
import { EuiSkeletonText } from "@elastic/eui"
import { groupBy } from "lodash-es"
import { observer } from "mobx-react"
import { HTMLAttributes, useEffect } from "react"
import { useTopupPackageStore } from "./store"
import { TopupPackagesByType } from "./topup-packages-by-type"

type TopupChannelListProps = HTMLAttributes<HTMLDivElement> & {
  selectedPaymentChannel: PaymentChannel | null
  onPaymentChannelSelect: (p: PaymentChannel) => void
}

export const TopupChannelList: React.FC<TopupChannelListProps> = observer(
  ({ selectedPaymentChannel, onPaymentChannelSelect, ...restProps }) => {
    const topupPackageStore = useTopupPackageStore()
    const { totalPackage } = topupPackageStore

    useEffect(() => {
      topupPackageStore.getTotalTopupPackage()
    }, [topupPackageStore])

    useEffect(() => {
      if (totalPackage.error?.message) {
        topupPackageStore.rootStore.notificationStore.add({
          title: "Error",
          color: "danger",
          text: totalPackage.error.message,
        })
      }
    }, [
      totalPackage.error?.message,
      topupPackageStore.rootStore.notificationStore,
    ])

    if (totalPackage.loading || !totalPackage.data) {
      return <EuiSkeletonText className="w-full" lines={10} />
    }

    const packagesByType = groupBy(totalPackage.data, "type")
    return (
      <div css={{ width: 352 }} className="flex flex-col gap-6" {...restProps}>
        {Object.keys(packagesByType).map((type) => (
          <TopupPackagesByType
            key={type}
            type={type as PackageType}
            packages={packagesByType[type]}
            selectedPaymentChannel={selectedPaymentChannel}
            onPaymentChannelSelect={onPaymentChannelSelect}
          />
        ))}
      </div>
    )
  },
)
