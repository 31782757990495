import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import useSWR from "swr"
import { useEffect, useState } from "react"
import { useHomeManagementStore } from "../../store/ctx"

export type MenuTab = definitions["webutility.v1.Menu"]
export type HighlightTab = definitions["webutility.v1.HighlightMenu"]

export const useNavigationTabs = () => {
  const { webUtility } = useApi()
  const { mainMenuSection } = useHomeManagementStore()
  const [tabsData, setTabsData] = useState<MenuTab[] | null>(null)

  const { data, error, mutate } = useSWR(
    [
      "/main-navigation-tab",
      mainMenuSection?.sectionKey,
      mainMenuSection?.menuCount,
    ],
    async ([, sectionKey]): Promise<{
      menus?: MenuTab[]
    }> => {
      const res = await webUtility("/admin/menu").method("get").create()({
        sectionKey,
      })
      return res.data
    },
    {
      revalidateOnFocus: false,
    },
  )

  useEffect(() => {
    if (data?.menus) {
      setTabsData(data.menus)
    }
  }, [data])

  return { tabsData, error, mutate, setTabsData }
}

export const useHighlightNavigationTabs = () => {
  const { webUtility } = useApi()
  const { mainMenuSection } = useHomeManagementStore()

  const [tabsData, setTabsData] = useState<HighlightTab[] | null>(null)

  const { data, error, mutate } = useSWR(
    [
      "/highlight-navigation-tab",
      mainMenuSection?.sectionKey,
      mainMenuSection?.highlightMenuCount,
    ],
    async ([, sectionKey]): Promise<{
      menus?: HighlightTab[]
    }> => {
      const res = await webUtility("/admin/highlight-menu")
        .method("get")
        .create()({
        sectionKey,
      })
      return {
        menus: res.data.highlightMenus,
      }
    },
    {
      revalidateOnFocus: false,
    },
  )

  useEffect(() => {
    if (data?.menus) {
      setTabsData(data.menus)
    }
  }, [data])

  return { tabsData, error, mutate, setTabsData }
}

export const useReorderNavigationTab = () => {
  const { mutate: refreshNavigation } = useNavigationTabs()
  const { mutate: refreshHighlight } = useHighlightNavigationTabs()
  const { mainMenuSection, refreshHomepageSection } = useHomeManagementStore()
  const sectionKey = mainMenuSection?.sectionKey || ""
  const { webUtility } = useApi()

  const reorderMainNavigationApi = webUtility(
    "/admin/section/{sectionKey}/menu/order",
  )
    .method("post")
    .create()

  const reorderHighlightNavigationApi = webUtility(
    "/admin/section/{sectionKey}/highlight-menu/order",
  )
    .method("post")
    .create()

  const reorderMainNavigation = async (menuKeys: string[]) => {
    await reorderMainNavigationApi({ sectionKey, menuKeys })
    await refreshHomepageSection()
    await refreshNavigation()
  }

  const reorderHighlightNavigation = async (highlightMenuKeys: string[]) => {
    await reorderHighlightNavigationApi({ sectionKey, highlightMenuKeys })
    await refreshHomepageSection()
    await refreshHighlight()
  }
  return {
    reorderMainNavigation,
    reorderHighlightNavigation,
  }
}
