export enum EditorBusyReason {
  ImagesBeingUploaded = "images-being-uploaded",
  ImagesFailedUpload = "images-failed-upload",
}

export enum RuleViolation {
  ImageUploadLimitExceeded = "upload-image-max-file-count-exceeded",
  InvalidFileType = "invalid-file-type",
}

// use only inside this lib
export enum InternalRuleViolation {
  LinkPrefixNotAllowed = "link-prefix-not-allowed",
}

export const getErrorTranslation = (error: InternalRuleViolation) => {
  switch (error) {
    case InternalRuleViolation.LinkPrefixNotAllowed:
      return "ต้องใช้ URL ของระบบเท่านั้น"
  }

  return "-"
}
