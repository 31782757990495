import { HomeManagementCard } from "@app/component/home-management/card"
import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { HomeManagementMode } from "@app/model/homepage/homepage"
import { ShopInput } from "@app/model/homepage/shop"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  DropResult,
  EuiButton,
  EuiDragDropContext,
  euiDragDropReorder,
  EuiDraggable,
  EuiDroppable,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AddItemButton } from "../component/add-item-button"
import { ReorderButton } from "../component/reorder-button"
import { useHomeManagementStore } from "../store/ctx"

export const ListRecommendedShop: React.FC = () => {
  const { t } = useTranslation()
  const { t: shopT } = useTranslation("translation", {
    keyPrefix: "homepage-management.recommended-shop",
  })

  const homeManagementStore = useHomeManagementStore()

  const [mode, setMode] = useState<HomeManagementMode>(HomeManagementMode.IDLE)
  const [tempShops, setTempShops] = useState<
    definitions["webutility.admin.v1.RecommendShopSection.Shop"][]
  >(homeManagementStore.homepage?.recommendShopSection?.shops ?? [])

  const navigate = useNavigate()

  const onDragEnd = (result: DropResult) => {
    if (result.source && result.destination && tempShops) {
      const items = euiDragDropReorder(
        tempShops,
        result.source.index,
        result.destination.index,
      )
      setTempShops(items)
    }
  }

  useEffect(() => {
    setTempShops(
      homeManagementStore.homepage?.recommendShopSection?.shops ?? [],
    )
  }, [homeManagementStore.homepage?.recommendShopSection?.shops])

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      {mode === HomeManagementMode.IDLE ||
      mode === HomeManagementMode.REORDER ? (
        <div className="  ">
          {mode === HomeManagementMode.REORDER ? (
            <div className="flex flex-row items-center justify-between">
              <div>
                <EuiText>
                  <h2>{shopT("reorder-shop")}</h2>
                </EuiText>
              </div>
              <div>
                <EuiButton
                  css={css`
                    width: 210px;
                    height: 40px;
                  `}
                  color="text"
                  onClick={() => {
                    setMode(HomeManagementMode.IDLE)
                  }}
                >
                  {t("common.cancel")}
                </EuiButton>
                <EuiButton
                  css={css`
                    width: 210px;
                    height: 40px;
                  `}
                  className="ml-4"
                  fill
                  onClick={() => {
                    setMode(HomeManagementMode.IDLE)
                    homeManagementStore.update("recommendShopSection", {
                      shops: tempShops,
                    })
                  }}
                >
                  {t("common.save")}
                </EuiButton>
              </div>
            </div>
          ) : null}
          {mode === HomeManagementMode.IDLE ? (
            <div className="flex flex-row items-center justify-between">
              <NavigationHeaderButton
                title={shopT("title")}
                onClick={() => {
                  navigate("/home-management")
                }}
              />
              <ReorderButton
                onPress={() => {
                  setMode(HomeManagementMode.REORDER)
                }}
              />
            </div>
          ) : null}
          <div>
            <EuiDragDropContext onDragEnd={onDragEnd}>
              <EuiDroppable
                className="mt-10 grid grid-cols-4 gap-x-8 gap-y-16"
                droppableId="LIST_SHOP"
                type="TYPE_ONE"
                isDropDisabled={mode !== HomeManagementMode.REORDER}
                direction="horizontal"
              >
                <>
                  {tempShops?.map((c, index) => {
                    return (
                      <EuiDraggable
                        isDragDisabled={mode !== HomeManagementMode.REORDER}
                        key={c.id}
                        index={index}
                        draggableId={c.id ?? index.toString()}
                      >
                        {() => (
                          <HomeManagementCard
                            imageCss={css({
                              width: "100px !important",
                              height: "auto",
                              aspectRatio: "1/1",
                              objectFit: "contain",
                            })}
                            cardCss={css({
                              minHeight: "100%",
                              ".euiPopover": {
                                position: "absolute",
                                top: "0",
                                right: "0",
                              },
                            })}
                            url={c.url}
                            imageUrl={(c as ShopInput)?.downloadUrl ?? c.image}
                            id={c.id ?? ""}
                            editMode={mode === HomeManagementMode.REORDER}
                            title={c.name ?? ""}
                            onPressEdit={() => {
                              navigate(
                                `/home-management/recommended-shop/${c.id}`,
                              )
                            }}
                            onPressDelete={() => {
                              const index =
                                homeManagementStore.homepage?.recommendShopSection?.shops?.findIndex(
                                  (h) => {
                                    return c.id === h.id
                                  },
                                )
                              if (index !== undefined && index !== -1) {
                                homeManagementStore.update(
                                  "recommendShopSection",
                                  {
                                    shops: [
                                      ...(homeManagementStore.homepage?.recommendShopSection?.shops?.slice(
                                        0,
                                        index,
                                      ) ?? []),
                                      ...(homeManagementStore.homepage?.recommendShopSection?.shops?.slice(
                                        index + 1,
                                      ) ?? []),
                                    ],
                                  },
                                )
                              }
                            }}
                          />
                        )}
                      </EuiDraggable>
                    )
                  })}
                  {mode === HomeManagementMode.IDLE ? (
                    <AddItemButton
                      onClick={() => {
                        navigate("/home-management/recommended-shop/create")
                      }}
                      label={shopT("add-shop")}
                      height={200}
                    />
                  ) : null}
                </>
              </EuiDroppable>
            </EuiDragDropContext>
          </div>
        </div>
      ) : null}
    </div>
  )
}
