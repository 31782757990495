import { InitialEditorStateType } from "@lexical/react/LexicalComposer"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import React, { useEffect } from "react"
import { parseEditorState } from "../utils/editor-state"

export const ReInitialEditorState: React.FC<{
  state: InitialEditorStateType
}> = ({ state }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.isEditable()) {
      if (typeof state === "string") {
        editor.setEditorState(parseEditorState(state))
      } else if (typeof state === "object") {
        editor.setEditorState(state)
      }
    }
  }, [state, editor])

  return null
}
