import { EuiButtonEmpty, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import { UilAngleLeft } from "@iconscout/react-unicons"

export const NavigationHeaderButton = (props: {
  title: string
  onClick: () => void
}) => {
  return (
    <EuiButtonEmpty
      color="text"
      contentProps={{
        className: "py-0   ",
      }}
      css={css`
        .euiButtonEmpty__content {
          padding: 0px !important;
        }
        .euiButtonEmpty__text {
          background-color: transparent !important;
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      `}
      onClick={props.onClick}
    >
      <UilAngleLeft width={32} height={32} className="mr-2" />
      <EuiText className=" pr-4">
        <h2>{props.title}</h2>
      </EuiText>
    </EuiButtonEmpty>
  )
}
