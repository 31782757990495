import { EuiButtonEmpty, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import { UilDownloadAlt } from "@iconscout/react-unicons"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import React from "react"

export const WithdrawAccountAttachmentCard = (props: {
  hasBankDocument: boolean
  hasRegistrationCertification: boolean
  hasValueAddedTaxCertification: boolean
  downloadBankDocumentUrl?: () => void
  downloadRegistrationCertificationUrl?: () => void
  downloadValueAddedTaxCertificationUrl?: () => void
}) => {
  const { t: SellerT } = useTranslation("translation", {
    keyPrefix: "seller-management",
  })

  const { t } = useTranslation("translation")
  const DownloadButton = (props: { onClick: () => void }) => {
    return (
      <div className=" ">
        <EuiButtonEmpty
          color="primary"
          onClick={props.onClick}
          className="h-8"
          css={css`
            .euiButtonEmpty {
              height: 20px !important;
            }
            .euiButtonEmpty__text {
              display: flex;
            }
            .euiButtonContent {
              height: 20px !important;
            }
          `}
        >
          <UilDownloadAlt size={16} className="mb-1 mr-2 fill-primary" />
          <EuiText size="s">
            <p>{t("common.download")}</p>
          </EuiText>
        </EuiButtonEmpty>
      </div>
    )
  }

  const data = []
  if (props.hasBankDocument && props.downloadBankDocumentUrl) {
    data.push({
      label: SellerT("bank-document-cover"),
      value: <DownloadButton onClick={props.downloadBankDocumentUrl} />,
    })
  }

  if (
    props.hasRegistrationCertification &&
    props.downloadRegistrationCertificationUrl
  ) {
    data.push({
      label: SellerT("registration-certification-cover"),
      value: (
        <DownloadButton onClick={props.downloadRegistrationCertificationUrl} />
      ),
    })
  }

  if (
    props.hasValueAddedTaxCertification &&
    props.downloadValueAddedTaxCertificationUrl
  ) {
    data.push({
      label: SellerT("value-added-tax-certification-cover"),
      value: (
        <DownloadButton onClick={props.downloadValueAddedTaxCertificationUrl} />
      ),
    })
  }

  if (data.length === 0) {
    return null
  }

  return (
    <div className="rounded-lg border border-solid border-grey-light px-6 pb-6 ">
      <div>
        <EuiText size="m" className="pb-6 pt-6">
          <h4 className="font-bold">{SellerT("attachment-card")}</h4>
        </EuiText>
        {data.map((d, index) => {
          return (
            <div
              className={classNames(
                "flex flex-row items-center justify-between",
                index !== data.length - 1 ? "mb-3" : "",
              )}
            >
              <EuiText size="s">
                <p className="mb-0 w-[170px] leading-[21px]">{d.label}</p>
              </EuiText>
              {d.value}
            </div>
          )
        })}
      </div>
    </div>
  )
}
