import { useApi } from "@app/hook/api"
import useSWR from "swr"
import { utils as utilsXlsx, writeFile } from "xlsx"

export const getFreeEBookTemplate = async () => {
  const data = [
    [
      "username",
      "productId",
      "productName",
      "productType",
      "variant",
      "expiresAt",
    ],
  ]

  const exampleDataArray = [
    [
      "exampleUsername",
      "nzgaSc9iZAYvvpMU",
      "product-name",
      "e-book-audio",
      "e-book",
      "2023-12-19 23:59:59",
    ],
    [
      "exampleUsername",
      "nzgaSc9iZAYvvpMU",
      "product-name",
      "e-book-audio",
      "audio",
      "2023-12-19 23:59:59",
    ],
  ]

  const worksheet = utilsXlsx.aoa_to_sheet(data)
  worksheet["!cols"] = Array(data[0].length).fill({ wpx: 100 })

  const workbook = utilsXlsx.book_new()
  utilsXlsx.book_append_sheet(workbook, worksheet, "template")
  utilsXlsx.sheet_add_json(worksheet, exampleDataArray, {
    skipHeader: true,
    origin: "A2",
  })

  writeFile(workbook, "free-e-book-template.xlsx")
}

export const useGetFreeGiveawayProducts = (
  id: string,
  page: number,
  itemPerPage: number,
) => {
  const { webBff } = useApi()

  const { data, error, isLoading, mutate } = useSWR(
    ["/seller-discount-coupon-products/{promotionId}", id, page, itemPerPage],
    async () => {
      try {
        return webBff("/add-free-digital-content-giveaway-product")
          .method("get")
          .create()({
          giveawayId: id,
          "option.limit": itemPerPage,
          "option.skip": page * itemPerPage,
        })
      } catch (e) {
        throw error
      }
    },
    {
      revalidateOnFocus: false,
    },
  )

  const items = (data?.data.addFreeDigitalContentGiveawayProducts || []).map(
    (item) => {
      return {
        ...item.addFreeDigitalContentGiveawayProduct,
        productName: item.product?.eBookAudio?.name,
      }
    },
  )

  return {
    data: {
      items,
      count: data?.data.count,
    },
    isLoading,
    mutate,
  }
}
