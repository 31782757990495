import * as Yup from "yup"

import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { FileForm } from "../form"
import { conditionalCoverShapeValidator } from "./cover"

export type WriterInput =
  definitions["webutility.admin.v1.RecommendAuthorSectionInput.Author"] & {
    downloadUrl?: string
  }

export type WriterForm = {
  id?: string
  cover?: FileForm
  name?: string
  url?: string
  introduction?: string
  productCount?: number
  downloadUrl?: string
}

export const writerFormValidator = Yup.object({
  introduction: Yup.string().required(),
  productCount: Yup.number().required(),
  name: Yup.string().required(),
  url: Yup.string().required(),
  cover: conditionalCoverShapeValidator("downloadUrl"),
})
