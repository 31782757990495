import { Title } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { useApi } from "@app/hook/api"
import { useAlertDefaultErr } from "@app/hook/error"
import { useStore } from "@app/hook/store"
import { useCallAsync, useWrapAsyncWithLoading } from "@app/lib/async"
import { Options } from "@app/page/coupon-promotion/component"
import { EuiFieldText, EuiTab, EuiTabs, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import { UilSearch } from "@iconscout/react-unicons"
import { OnGetItems, theme } from "@reeeed-mp/ui-common"
import moment, { Moment } from "moment"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CouponType } from "./types"
import { useDebounce } from "use-debounce"

const allStatusFilter = [
  "STATUS_ACTIVE",
  "STATUS_WAITING",
  "STATUS_END",
  "STATUS_DRAFT",
] as const

type TopupCouponPromotion = {
  id: string
  code: string
  name: string
  couponType: CouponType
  startAt?: Moment
  endAt?: Moment
}

export const ListTopupCouponPromotionPage: React.FC = () => {
  const { t } = useTranslation()
  const ref = useRef() as TableRefImperative
  const { promotion } = useApi()
  const alertDefaultErr = useAlertDefaultErr()
  const { notificationStore } = useStore()
  const [params] = useSearchParams()
  const statusParamKey = "status"
  const textSearchParamKey = "textSearch"
  const statusParam = params.get(
    statusParamKey,
  ) as (typeof allStatusFilter)[number]
  const textSearchParam = params.get(textSearchParamKey)
  const [filterStatus, setFilterStatus] = useState<
    (typeof allStatusFilter)[number]
  >(statusParam || allStatusFilter[0])
  const [textSearch, setTextSearch] = useState(textSearchParam || "")
  const [debouncedTextSearch] = useDebounce(textSearch, 500)

  const { fn, loading: loadingPromotions } = useCallAsync(
    useApi().promotion("/topup-coupon-promotion").method("get").create(),
  )
  const { loading: processingAction, wrap } = useWrapAsyncWithLoading()

  const getTopupCouponPromotion: OnGetItems<TopupCouponPromotion> = async ({
    opts,
  }) => {
    const { data } = await fn({
      status: filterStatus,
      textSearch: debouncedTextSearch,
      sorter: "TOPUP_COUPON_SORTER_UPDATED_AT_DESC",
      "pagination.skip": opts.skip,
      "pagination.limit": opts.limit,
    })

    return {
      items: (data.promotions || []).map((p) => ({
        id: p.id!,
        code: p.code!,
        name: p.name!,
        couponType:
          p.discount && Object.keys(p.discount).length > 0
            ? CouponType.Discount
            : CouponType.ExtraCoin,
        startAt: p.startAt ? moment(p.startAt) : undefined,
        endAt: p.endAt ? moment(p.endAt) : undefined,
      })),
      count: data.count ?? 0,
    }
  }

  const navigate = useNavigate()

  return (
    <div>
      <Title
        text={t("nav.topup-coupon-promotion")}
        btn={{
          iconName: "plus",
          text: t("topup-promotion.editor.create-promotion"),
          onClick: () => navigate("/topup-coupon-promotion/create"),
        }}
      />
      <div className="mb-6 flex w-full flex-auto justify-between">
        <div className="self-center">
          <EuiTabs
            css={css({
              ".euiTab": {
                ".euiTab__content": {
                  color: "#69707d !important",
                },
                "&.euiTab-isSelected": {
                  ".euiTab__content": {
                    color: `${theme.colors.LIGHT.primaryPurple} !important`,
                  },
                },
              },
              "&.euiTabs--bottomBorder": {
                boxShadow: "none",
              },
            })}
            size="l"
          >
            {allStatusFilter.map((s) => (
              <EuiTab
                key={s}
                value={s}
                isSelected={filterStatus === s}
                onClick={() => {
                  setFilterStatus(s)
                }}
              >
                <EuiText size="m" className="font-bold">
                  {t(`topup-coupon-promotion.status.${s}`)}
                </EuiText>
              </EuiTab>
            ))}
          </EuiTabs>
        </div>
        <div>
          <EuiFieldText
            placeholder={t("topup-promotion.list.text-search")}
            value={textSearch}
            onChange={(e) => {
              setTextSearch(e.target.value)
            }}
            style={{
              width: 376,
            }}
            icon={<UilSearch />}
          />
        </div>
      </div>

      <div className="w-full">
        <Table<TopupCouponPromotion>
          swrKey={`/topup-list`}
          ref={ref}
          onGetItems={getTopupCouponPromotion}
          columns={[
            {
              name: t("promotion.list.promotion-name"),
              truncateText: true,
              render: (row: TopupCouponPromotion) => {
                return <EuiText size="xs">{row.name}</EuiText>
              },
            },
            {
              name: t("promotion.list.promotion-code"),
              field: "code",
            },
            {
              name: t("promotion.list.promotion-type"),
              render: (row: TopupCouponPromotion) => {
                return row.couponType === CouponType.Discount
                  ? "ส่วนลด"
                  : "เพิ่ม Coin"
              },
            },
            {
              name: t("promotion.list.range-publish"),
              render: (row: TopupCouponPromotion) => {
                return (
                  <div style={{ width: 125 }}>
                    <EuiText size="xs">
                      {t("time-range-short", {
                        value: { startAt: row.startAt, endAt: row.endAt },
                      })}
                    </EuiText>
                  </div>
                )
              },
            },
            {
              align: "right",
              width: "40px",
              isExpander: true,
              onClick: (e) => {
                e.stopPropagation()
              },
              render: (row: TopupCouponPromotion) => {
                return (
                  <Options
                    id={row.id}
                    disableRenew={false}
                    codeActive={{
                      startAt: row.startAt,
                      endAt: row.endAt,
                    }}
                    loading={processingAction}
                    onEnd={wrap(async (promotionId) => {
                      try {
                        await promotion(
                          "/topup-coupon-promotion/{promotionId}/end",
                        )
                          .method("post")
                          .create()({ promotionId })
                        ref.current.refetch()
                      } catch (e) {
                        alertDefaultErr(e)
                      }
                    })}
                    onEdit={wrap(async (promotionId) => {
                      navigate(`/topup-coupon-promotion/${promotionId}`)
                    })}
                    onReNew={wrap(async () => {
                      // TODO
                      // navigate(`/promotion/create?renew=${promotionId}`, {
                      //   replace: true,
                      // })
                      notificationStore.add({
                        title: "Warning",
                        color: "warning",
                        text: "comming soon",
                      })
                    })}
                    onDelete={wrap(async (promotionId) => {
                      try {
                        await promotion("/topup-coupon-promotion/{promotionId}")
                          .method("delete")
                          .create()({ promotionId })
                        ref.current.refetch()
                      } catch (e) {
                        alertDefaultErr(e)
                      }
                      ref.current.refetch()
                    })}
                  />
                )
              },
            },
          ]}
          loading={loadingPromotions}
          isCanSelect={false}
          onRowClick={(row) => {
            navigate(`/topup-coupon-promotion/detail/${row.id}`)
          }}
          filter={{
            textSearch: debouncedTextSearch,
            status: filterStatus,
          }}
        />
      </div>
    </div>
  )
}
