import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
} from "@elastic/eui"
import { UilEllipsisV, UilTrash } from "@iconscout/react-unicons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export type PrivilegeActionProps = {
  id: string
  onDelete: (id: string) => void
}

export const PrivilegeAction: React.FC<PrivilegeActionProps> = ({
  id,
  onDelete,
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <EuiPopover
      isOpen={open}
      closePopover={() => setOpen(false)}
      anchorPosition="leftCenter"
      button={<UilEllipsisV size={14} onClick={() => setOpen(!open)} />}
    >
      <EuiContextMenuPanel
        items={[
          <EuiContextMenuItem
            key={`del-${id}`}
            icon={<UilTrash size={16} />}
            className={`text-ci-red`}
            onClick={() => {
              setOpen(false)
              onDelete(id)
            }}
          >
            {t("common.delete")}
          </EuiContextMenuItem>,
        ]}
      />
    </EuiPopover>
  )
}
