import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils"
import {
  $createParagraphNode,
  $createRangeSelection,
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  EditorState,
  LexicalCommand,
  LexicalEditor,
  NodeKey,
  createCommand,
} from "lexical"
import React, { useEffect } from "react"
import { RuleViolation } from "../../constant/error"
import {
  $createImageNode,
  $isImageNode,
  ImageNode,
  ImagePayload,
} from "../../nodes"

const getDOMSelection = (targetWindow: Window | null): Selection | null =>
  targetWindow || window ? (targetWindow || window).getSelection() : null

export const INSERT_IMAGE_COMMAND: LexicalCommand<ImagePayload> = createCommand(
  "INSERT_IMAGE_COMMAND",
)

export const INSERT_UPLOAD_IMAGES_COMMAND: LexicalCommand<FileList> =
  createCommand("INSERT_UPLOAD_IMAGES_COMMAND")

export type GetUploadURL = (
  contentType: string,
) => Promise<{ signedUrl: string; tmpUrl: string }>

export const countUploadImageNode = (editorState: EditorState): number => {
  let count = 0

  for (const [nodeType, node] of editorState._nodeMap) {
    if ($isImageNode(node)) {
      count += 1
    }
  }

  return count
}

export const InsertImagePlugin: React.FC<{
  getUploadURL: GetUploadURL | undefined
  maxUploadImage?: number
  onError?: (error: Error) => void
}> = (props) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor")
    }

    return mergeRegister(
      editor.registerCommand<FileList>(
        INSERT_UPLOAD_IMAGES_COMMAND,
        (files) => {
          if (!files || !files.length) {
            return false
          }

          if (props.maxUploadImage !== undefined) {
            const currentImageCount = countUploadImageNode(
              editor.getEditorState(),
            )

            if (currentImageCount + files.length > props.maxUploadImage) {
              throw new Error(RuleViolation.ImageUploadLimitExceeded)
            }
          }

          for (const file of files) {
            if (!file.type.startsWith("image/")) {
              throw new Error(RuleViolation.InvalidFileType)
            }

            const placeHolderImageNode = $createImageNode({
              altText: "",
              src: URL.createObjectURL(file),
              isUploading: true,
            })
            $insertNodes([placeHolderImageNode])
            if ($isRootOrShadowRoot(placeHolderImageNode.getParentOrThrow())) {
              $wrapNodeInElement(
                placeHolderImageNode,
                $createParagraphNode,
              ).selectEnd()
            }

            uploadImage(
              placeHolderImageNode.getKey(),
              file,
              editor,
              props.getUploadURL,
            )
          }

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand<ImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload)
          $insertNodes([imageNode])
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd()
          }

          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand<DragEvent>(
        DRAGSTART_COMMAND,
        (event) => {
          return onDragStart(event)
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand<DragEvent>(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event)
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand<DragEvent>(
        DROP_COMMAND,
        (event) => {
          return onDrop(event, editor)
        },
        COMMAND_PRIORITY_HIGH,
      ),
    )
  }, [editor])

  return null
}

const TRANSPARENT_IMAGE =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
const img = document.createElement("img")
img.src = TRANSPARENT_IMAGE

const onDragStart = (event: DragEvent): boolean => {
  const node = getImageNodeInSelection()
  if (!node) {
    return false
  }
  const dataTransfer = event.dataTransfer
  if (!dataTransfer) {
    return false
  }
  dataTransfer.setData("text/plain", "_")
  dataTransfer.setDragImage(img, 0, 0)
  dataTransfer.setData(
    "application/x-lexical-drag",
    JSON.stringify({
      data: {
        altText: node.altText,
        height: node.height,
        key: node.getKey(),
        src: node.src,
        width: node.width,
        tempBucketUrl: node.tempBucketUrl,
        isUploading: node.isUploading,
        isPublic: node.isPublic,
      } as ImagePayload,
      type: "image",
    }),
  )

  return true
}

const onDragover = (event: DragEvent): boolean => {
  const node = getImageNodeInSelection()
  if (!node) {
    return false
  }
  if (!canDropImage(event)) {
    event.preventDefault()
  }
  return true
}

const onDrop = (event: DragEvent, editor: LexicalEditor): boolean => {
  const node = getImageNodeInSelection()
  if (!node) {
    return false
  }
  const data = getDragImageData(event)
  if (!data) {
    return false
  }
  event.preventDefault()
  if (canDropImage(event)) {
    const range = getDragSelection(event)
    node.remove()
    const rangeSelection = $createRangeSelection()
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range)
    }
    $setSelection(rangeSelection)
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data)
  }
  return true
}

const getImageNodeInSelection = (): ImageNode | null => {
  const selection = $getSelection()
  if (!$isNodeSelection(selection)) {
    return null
  }
  const nodes = selection.getNodes()
  const node = nodes[0]
  return $isImageNode(node) ? node : null
}

const getDragImageData = (event: DragEvent): null | ImagePayload => {
  const dragData = event.dataTransfer?.getData("application/x-lexical-drag")
  if (!dragData) {
    return null
  }
  const { type, data } = JSON.parse(dragData)
  if (type !== "image") {
    return null
  }

  return data
}

declare global {
  interface DragEvent {
    rangeOffset?: number
    rangeParent?: Node
  }
}

const canDropImage = (event: DragEvent): boolean => {
  const target = event.target
  return !!(
    target &&
    target instanceof HTMLElement &&
    !target.closest("code, span.mpe-theme-image") &&
    target.parentElement &&
    target.parentElement.closest("div.mpe-editor")
  )
}

const getDragSelection = (event: DragEvent): Range | null | undefined => {
  let range
  const target = event.target as null | Element | Document
  const targetWindow =
    target == null
      ? null
      : target.nodeType === 9
        ? (target as Document).defaultView
        : (target as Element).ownerDocument.defaultView
  const domSelection = getDOMSelection(targetWindow)
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY)
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0)
    range = domSelection.getRangeAt(0)
  } else {
    throw Error(`Cannot get the selection when dragging`)
  }

  return range
}

const uploadImage = async (
  nodeKey: NodeKey,
  file: File,
  editor: LexicalEditor,
  getUploadURL: GetUploadURL,
) => {
  if (!file.type.startsWith("image/")) {
    throw new Error("Invalid file type, only image is allowed")
  }

  try {
    const uploadEndpoint = await getUploadURL(file.type)
    const resp = await fetch(uploadEndpoint.signedUrl, {
      method: "PUT",
      body: file,
    })

    if (!resp.ok) {
      throw new Error(`Fail to upload image (${file.name})`, { cause: resp })
    }

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if (!$isImageNode(node)) {
        throw new Error(
          "Cannot set finished uploading due to invalid node type",
        )
      }

      node.setFinishedUploading(uploadEndpoint.tmpUrl)
    })
  } catch (e) {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if (!$isImageNode(node)) {
        throw new Error(
          "Cannot set fail reason to image node due to invalid node type",
          { cause: e },
        )
      }

      node.setUploadFailed(e.message)
    })

    throw e
  }
}
