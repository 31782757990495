import {
  CriteriaWithPagination,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  Pagination,
} from "@elastic/eui"
import { css } from "@emotion/react"
import * as Unicons from "@iconscout/react-unicons"
import { theme } from "@reeeed-mp/ui-common"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

export type UploadItemProps<T> = {
  items?: T[]
  loading: boolean
  handleUpload: (files: FileList | null) => Promise<void>
  fileNameUpload?: string
  export?: () => void
  defaultFileName?: string
  warningText?: string
  duplicatedIds?: string[]
  errorIds: string[]
  tableColumns: Array<EuiBasicTableColumn<T>>
  pagination?: Pagination
  onChangePagination?:
    | ((criteria: CriteriaWithPagination<T>) => void)
    | undefined
  exampleTemplateStaticPath?: string
  duplicateIds?: string[]
}

export const UploadItemSection = <T,>(props: UploadItemProps<T>) => {
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "common",
  })
  const filePickerRef = useRef<any>()

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        {props.items ? (
          <EuiFlexGroup className="my-2 items-center">
            <EuiFlexItem grow={10}>
              <div className="flex flex-row items-center">
                <Unicons.UilFileAlt className="mr-2" />
                <EuiText size="s">
                  <p className="font-bold">
                    {props.fileNameUpload
                      ? props.fileNameUpload
                      : props.defaultFileName
                        ? props.defaultFileName
                        : "Product.xlsx"}
                  </p>
                </EuiText>
              </div>
              {props.export && (
                <EuiLink
                  className="mt-1"
                  css={css`
                    color: ${theme.colors.LIGHT.darkGrey};
                  `}
                  onClick={props.export}
                >
                  <EuiText size="xs">{editorT("download")}</EuiText>
                </EuiLink>
              )}
            </EuiFlexItem>

            <EuiFlexItem grow={1}>
              <EuiFilePicker
                ref={filePickerRef}
                className="items-end border-0"
                css={css`
                  .euiFilePicker__icon {
                    display: none;
                  }
                  .euiFilePicker__promptText {
                    color: ${theme.colors.LIGHT.primaryPurple} !important;
                    height: 100%;
                    & > div {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    }
                  }
                  .euiFilePicker__prompt {
                    padding: 0px;
                    border: none !important;
                    border-color: transparent !important;
                    background-color: transparent;
                    display: flex;

                    justify-content: center;
                    :nth-child(2) {
                      margin-left: 16px;
                    }
                  }
                  .euiFilePicker__promptText {
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                    margin-right: 8px;
                  }
                `}
                multiple={false}
                disabled={props.loading}
                onChange={props.handleUpload}
                isLoading={props.loading}
                display="default"
                initialPromptText={
                  <div className="item-center">
                    <Unicons.UilUpload className="mr-2" size={"14"} />
                    <EuiText size="xs">{editorT("re-upload")}</EuiText>
                  </div>
                }
                accept={".xlsx, .xls"}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : null}

        {props.warningText ? (
          <EuiText color="danger" size="xs">
            {props.warningText}
          </EuiText>
        ) : null}

        {props.duplicateIds && props.duplicateIds?.length > 0 && (
          <div>
            <EuiText color="danger" size="xs">
              {editorT("duplicate-row", {
                errorRowSize: props.duplicateIds.length ?? 0,
              })}
            </EuiText>
            <div>
              <ul className="list-inside list-disc">
                {props.duplicateIds.map((id: string) => {
                  return (
                    <li key={id} className="text-[12px] text-ci-red">
                      {id}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}

        {props.errorIds.length > 0 ? (
          <div className="mb-4">
            <EuiText color="danger" size="xs">
              {editorT("error-row", {
                errorRowSize: props.errorIds.length ?? 0,
              })}
            </EuiText>
            <div>
              <ul className="list-inside list-disc">
                {props.errorIds.map((e: string) => {
                  return (
                    <li
                      key={e}
                      className="text-dang text-[12px]"
                      css={css`
                        color: ${theme.colors.LIGHT.secondaryRed};
                      `}
                    >
                      {e}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        ) : null}

        {props.items ? (
          <EuiText className="mb-4 mt-2">
            <p className="font-bold">
              {` ${editorT("amount-product", {
                amount: props.pagination?.totalItemCount ?? 0,
              })}`}
            </p>
          </EuiText>
        ) : null}

        {props.items ? (
          <div
            className="border border-solid border-table"
            css={css`
              background-color: white;
              padding: 16px;
              border-radius: 8px;
            `}
          >
            {props.pagination && props.onChangePagination ? (
              <EuiBasicTable
                css={css`
                  .euiPagination__list {
                    margin: 0;
                  }

                  .euiPagination__item {
                    list-style: none;
                  }

                  &.clickable {
                    .euiTableRowCell {
                      cursor: pointer;
                    }
                  }
                `}
                items={props.items!}
                responsive={false}
                columns={props.tableColumns}
                pagination={props.pagination}
                onChange={props.onChangePagination}
              />
            ) : (
              <EuiBasicTable
                css={css`
                  .euiPagination__list {
                    margin: 0;
                  }

                  .euiPagination__item {
                    list-style: none;
                  }

                  &.clickable {
                    .euiTableRowCell {
                      cursor: pointer;
                    }
                  }
                `}
                responsive={false}
                items={props.items!}
                columns={props.tableColumns}
              />
            )}
          </div>
        ) : null}

        {!props.items ? (
          <div
            className="mt-2 flex flex-row"
            css={css`
              height: 40px;
            `}
          >
            <div className="mr-4">
              <EuiFilePicker
                css={css`
                  width: 173px;
                  height: 40px;
                  border-color: ${theme.colors.LIGHT.primaryPurple};
                  border-radius: 8px;
                  .euiFilePicker__icon {
                    display: none;
                  }
                  .euiFilePicker__promptText {
                    color: ${theme.colors.LIGHT.primaryPurple} !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    & > div {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    }
                  }
                  .euiFilePicker__prompt {
                    padding: 0px;
                    border: 1px solid ${theme.colors.LIGHT.primaryPurple};
                  }
                `}
                className="border-current bg-current"
                display="default"
                disabled={props.loading}
                isLoading={props.loading}
                initialPromptText={
                  <div className="ml-3 items-center">
                    <Unicons.UilFileAlt className="mr-2" />
                    <EuiText size="s">{editorT("upload-xls-xlsx")}</EuiText>
                  </div>
                }
                multiple={false}
                accept={".xlsx, .xls"}
                onChange={props.handleUpload}
              />
            </div>
            {props.exampleTemplateStaticPath ? (
              <div className="self-center">
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiText size="xs">
                      {editorT("template-for-upload")}
                    </EuiText>
                    <EuiLink
                      css={css`
                        color: ${theme.colors.LIGHT.primaryPurple};
                      `}
                      className="flex"
                      href={props.exampleTemplateStaticPath}
                      target="_blank"
                    >
                      <EuiText size="xs">{editorT("download")}</EuiText>
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            ) : null}
          </div>
        ) : null}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
