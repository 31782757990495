import { UploadItemSection } from "@app/component/upload-and-render"
import { useCallAsync } from "@app/lib/async"
import {
  mappingProductTypeInputToShopProductType,
  ProductTypeInput,
} from "@app/model/product-table"
import {
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiHorizontalRule,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { read as readXLSX, utils as utilsXLSX } from "xlsx"
import { Headline } from "../coupon-promotion/component"
import {
  addEbookColumns,
  AddEBookUser,
  formControlProps,
  useFreeEBookForm,
} from "./hook"
import { getFreeEBookTemplate } from "./hook/products"

export const FreeEBookCreatePage = () => {
  return <FreeEBookEditorPage />
}

const FreeEBookEditorPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const formControl = useFreeEBookForm(params.id || "create")

  const { t } = useTranslation()

  return (
    <div className="flex w-full max-w-screen-xl flex-col gap-y-8">
      <Headline
        text={t("free-e-book.editor.title")}
        onGoBackClick={() => navigate(-1)}
      />

      <div className="grid min-h-screen grid-cols-3 gap-2 py-6">
        <section className="col-span-full">
          <FreeEBookDetailForm formControl={formControl} />
          <EuiHorizontalRule />
          <FreeEBookProductsAction formControl={formControl} />
          <EuiHorizontalRule />
          <FreeEBookOptionalFields formControl={formControl} />
          <EuiHorizontalRule />
          <FreeEBookCommitButtons formControl={formControl} />
        </section>
      </div>
    </div>
  )
}

const FreeEBookCommitButtons: React.FC<formControlProps> = ({
  formControl,
}) => {
  const { t } = useTranslation()
  const { fn: onSubmit } = useCallAsync(async () => {
    formControl.setSubmitting(true)

    await formControl.submitForm()

    formControl.setSubmitting(false)
  })

  return (
    <div className="flex gap-2">
      <EuiButton
        isLoading={formControl.isSubmitting}
        onClick={onSubmit}
        minWidth={285}
        fill
      >
        {t("free-e-book.editor.save-btn")}
      </EuiButton>
    </div>
  )
}

const FreeEBookOptionalFields: React.FC<formControlProps> = ({
  formControl,
}) => {
  const { t } = useTranslation()

  const { noted } = formControl.values

  return (
    <div>
      <div className="flex items-center gap-2">
        <EuiText size="m">
          <h3>{t("free-e-book.editor.noted")}</h3>
        </EuiText>
        <EuiText size="s">
          <p className="text-eui-subduedText">
            {t("free-e-book.editor.optional")}
          </p>
        </EuiText>
      </div>

      <EuiTextArea
        className="mt-2"
        value={noted}
        onChange={(e) => formControl.setFieldValue("noted", e.target.value)}
        fullWidth
        style={{
          maxWidth: 736,
        }}
      />
    </div>
  )
}

const FreeEBookDetailForm: React.FC<formControlProps> = ({ formControl }) => {
  const { t } = useTranslation()

  const { name } = formControl.values

  return (
    <EuiFormRow label={t("free-e-book.editor.name")}>
      <EuiFieldText
        value={name || ""}
        onChange={(e) => formControl.setFieldValue("name", e.target.value)}
        fullWidth
        isInvalid={!!formControl.errors.name}
      />
    </EuiFormRow>
  )
}

const FreeEBookProductsAction: React.FC<formControlProps> = ({
  formControl,
}) => {
  const { t } = useTranslation()
  const [pageProductIndex, setPageProductIndex] = useState<number>(0)
  const [totalItemCount, setTotalItemCount] = useState<number>(0)

  const handleUploadProducts = async (files: FileList | null) => {
    if (files && files[0]) {
      const result = await files[0].arrayBuffer()
      const workbook = readXLSX(result)
      const wsname = workbook.SheetNames[0]
      const ws = workbook.Sheets[wsname]
      const resultInJson: (AddEBookUser & { remark?: string })[] =
        utilsXLSX.sheet_to_json(ws, {
          raw: false,
          dateNF: "dd/mm/yyyy",
        })

      setTotalItemCount(resultInJson.length)
      formControl.setFieldValue("addFreeDigitalContentGiveawayProducts", [
        ...resultInJson.map((item) => {
          const productType = item.productType as ProductTypeInput
          return {
            ...item,
            username: item.username?.toLowerCase(),
            productType: mappingProductTypeInputToShopProductType(productType),
            expiresAt: new Date(item.expiresAt!),
          }
        }),
      ])

      setPageProductIndex(0)
    }
  }

  return (
    <div className="flex flex-col gap-y-4">
      <EuiText className="mb-4">
        <h3>{t("free-e-book.editor.add-e-book-header")}</h3>
      </EuiText>
      <UploadItemSection
        errorIds={[]}
        export={getFreeEBookTemplate}
        loading={formControl.isSubmitting}
        handleUpload={handleUploadProducts}
        items={formControl.values.addFreeDigitalContentGiveawayProducts || []}
        tableColumns={addEbookColumns}
        pagination={{
          pageIndex: pageProductIndex,
          pageSize: 10,
          totalItemCount,
          showPerPageOptions: false,
        }}
        defaultFileName="Template.xlsx"
      />
    </div>
  )
}
