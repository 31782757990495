import { RootStore } from "@app/store"
import { createContext, useContext } from "react"

export const StoreCtx = createContext<RootStore | null>(null)

export const useStore = () => {
  const store = useContext(StoreCtx)
  if (!store) {
    throw new Error("component must be wrap with StoreProvider")
  }
  return store
}

export const useAuth = () => {
  return useStore().authStore
}
