import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiText,
} from "@elastic/eui"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"

type ConfirmActionModalProps = {
  title?: string
  description?: string
  textConfirmButton?: string
  textCloseButton?: string
  onConfirm: () => void
  onClose: () => void
}

export const ConfirmActionModal = observer((props: ConfirmActionModalProps) => {
  const {
    title,
    description,
    textConfirmButton,
    textCloseButton,
    onClose,
    onConfirm,
  } = props
  const { t } = useTranslation()

  return (
    <EuiModal
      className="book-category-page__modal"
      style={{ width: 575 }}
      onClose={onClose}
    >
      <EuiModalHeader>
        <EuiText>
          <h2>{title ? title : t("category.confirmation-delete")}</h2>
        </EuiText>
      </EuiModalHeader>
      <EuiModalBody>
        {
          <EuiText>
            <p>{description}</p>
          </EuiText>
        }

        <div className="mt-6 flex flex-row justify-end">
          <EuiButton color="primary" fill className="mr-4" onClick={onConfirm}>
            {textConfirmButton || t("common.confirm")}
          </EuiButton>
          <EuiButton className="mr-1 w-[128px] bg-white" onClick={onClose}>
            <EuiText size="xs">{textCloseButton || t("common.cancel")}</EuiText>
          </EuiButton>
        </div>
      </EuiModalBody>
    </EuiModal>
  )
})
