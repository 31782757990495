import "./tailwindcss.css" // need import to top of @elastic/eui because tailwindcss preflight override some css of @elastic/eui
import { EuiProvider } from "@elastic/eui"
import "@elastic/eui/dist/eui_theme_light.css"
import { Global } from "@emotion/react"
import { global, theme } from "@reeeed-mp/ui-common"
import "@reeeed-mp/ui-common/dist/styles/font.css"
import React from "react"
import { I18nextProvider } from "react-i18next"
import "./font.css"
import { StoreCtx } from "./hook/store"
import i18n from "./i18n"
import { ProtectedRouteLayout } from "./routes/protected"
import { RootStore } from "./store"

const store = new RootStore()

store.authStore.initial()

const App: React.FC = () => {
  return (
    <>
      <Global styles={global} />
      <I18nextProvider i18n={i18n}>
        <StoreCtx.Provider value={store}>
          <EuiProvider colorMode="LIGHT" modify={theme}>
            <ProtectedRouteLayout />
          </EuiProvider>
        </StoreCtx.Provider>
      </I18nextProvider>
    </>
  )
}

export default App
