import { useUnMount } from "@app/hook/life-cycle"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { hooks } from "@reeeed-mp/ui-common"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { BannerPanel } from "./component/bannel-panel"
import { useHomeManagementStore } from "./store/ctx"
import { useAvailableFeature } from "@app/hook/feature"

export const HomeManagement = () => {
  const { t } = useTranslation()
  const { t: homeManagementT } = useTranslation("translation", {
    keyPrefix: "homepage-management",
  })
  const feature = useAvailableFeature()
  const navigate = useNavigate()
  const homeManagementStore = useHomeManagementStore()
  const { setIsDirty } = hooks.useConfirmLeaveWithoutSaved(
    t("common.confirm-not-save-data"),
  )
  useUnMount(async () => {
    await setIsDirty(false)
  })

  useEffect(() => {
    const fn = async () => {
      await setIsDirty(homeManagementStore.edited)
    }

    fn()
  }, [homeManagementStore.edited, setIsDirty])

  if (homeManagementStore.initError) {
    return (
      <div
        css={css({
          backgroundColor: "white",
        })}
      >
        <EuiCallOut title={t("common.error")} color="danger" iconType="error">
          <p>{homeManagementStore.initError}</p>
        </EuiCallOut>
      </div>
    )
  }

  if (!homeManagementStore.ready) {
    return <EuiLoadingSpinner />
  }

  return (
    <div
      css={css({
        backgroundColor: "white",
      })}
    >
      <div className="mb-10">
        <EuiText className="font-bold">
          <h1
            css={css`
              font-size: 27px !important;
            `}
          >
            {homeManagementT("title")}
          </h1>
        </EuiText>
      </div>
      <EuiFlexGroup>
        <EuiFlexItem grow={7}>
          <EuiFlexGroup>
            <EuiFlexItem>
              <BannerPanel
                title="Highlight Banner"
                secondaryText={homeManagementT("banner-count", {
                  count:
                    homeManagementStore.homepage?.highlightBannerSection
                      ?.banners?.length ?? 0,
                })}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate("/home-management/highlight-banner")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup justifyContent="spaceBetween" className="mt-4">
            <EuiFlexItem grow={4}>
              <BannerPanel
                buttonText={t("common.change")}
                title="Sub Banner 1"
                onPressEdit={() => {
                  navigate("/home-management/sub-banner-1")
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={4}>
              <BannerPanel
                buttonText={t("common.change")}
                title="Sub Banner 2"
                onPressEdit={() => {
                  navigate("/home-management/sub-banner-2")
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={4}>
              <BannerPanel
                buttonText={t("common.change")}
                title="Sub Banner 3"
                onPressEdit={() => {
                  navigate("/home-management/sub-banner-3")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          {feature.novel && (
            <EuiFlexGroup className="mt-4">
              <EuiFlexItem>
                <BannerPanel
                  title={homeManagementT("new-release")}
                  buttonText={""}
                  toggleable
                  onToggle={async () => {
                    await homeManagementStore.update(
                      "newArrivalChapterSection",
                      !homeManagementStore.homepage?.newArrivalChapterSection,
                    )
                  }}
                  isToggle={
                    !!homeManagementStore.homepage?.newArrivalChapterSection
                  }
                  onPressEdit={() => {}}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          )}

          {feature.novel && (
            <EuiFlexGroup className="mt-4">
              <EuiFlexItem>
                <BannerPanel
                  title={homeManagementT("popular-novel")}
                  buttonText={""}
                  toggleable
                  onToggle={() => {
                    homeManagementStore.update(
                      "popularNovelChapterSection",
                      !homeManagementStore.homepage?.popularNovelChapterSection,
                    )
                  }}
                  isToggle={
                    homeManagementStore.homepage?.popularNovelChapterSection
                  }
                  onPressEdit={() => {}}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          )}

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("popular-writer")}
                secondaryText={homeManagementT("item-count", {
                  count:
                    homeManagementStore.homepage?.popularAuthorSection?.authors
                      ?.length ?? 0,
                })}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate("/home-management/writer")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("top-sale-product")}
                secondaryText={
                  feature.eBookAudio && feature.physical
                    ? homeManagementT("top-sale-product-description")
                    : undefined
                }
                buttonText={""}
                toggleable
                onToggle={() => {
                  homeManagementStore.update(
                    "popularSection",
                    !homeManagementStore.homepage?.popularSection,
                  )
                }}
                isToggle={homeManagementStore.homepage?.popularSection}
                onPressEdit={() => {}}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("new-arrival")}
                buttonText={""}
                toggleable
                onToggle={() => {
                  homeManagementStore.update(
                    "newArrivalSection",
                    !homeManagementStore.homepage?.newArrivalSection,
                  )
                }}
                isToggle={!!homeManagementStore.homepage?.newArrivalSection}
                onPressEdit={() => {}}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("square-banner")}
                secondaryText={homeManagementT("item-count", {
                  count:
                    homeManagementStore.homepage?.squareBannerSection?.length ??
                    0,
                })}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate("/home-management/square-banner")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title="Product Highlight 1"
                secondaryText={homeManagementT("recommended-book-by-week")}
                buttonText={t("common.edit")}
                toggleable
                onToggle={() => {
                  homeManagementStore.update("highlightProduct1Section", {
                    ...homeManagementStore.homepage?.highlightProduct1Section,
                    enabled:
                      !homeManagementStore.homepage?.highlightProduct1Section
                        ?.enabled,
                  })
                }}
                isToggle={
                  homeManagementStore.homepage?.highlightProduct1Section
                    ?.enabled
                }
                onPressEdit={() => {
                  navigate("/home-management/product-highlight-weekly")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title="Product Highlight 2"
                secondaryText={homeManagementT("recommended-book-by-month")}
                buttonText={t("common.edit")}
                toggleable
                onToggle={() => {
                  homeManagementStore.update("highlightProduct2Section", {
                    ...homeManagementStore.homepage?.highlightProduct2Section,
                    enabled:
                      !homeManagementStore.homepage?.highlightProduct2Section
                        ?.enabled,
                  })
                }}
                isToggle={
                  homeManagementStore.homepage?.highlightProduct2Section
                    ?.enabled
                }
                onPressEdit={() => {
                  navigate("/home-management/product-highlight-monthly")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("category-title")}
                secondaryText={homeManagementT("item-count", {
                  count:
                    homeManagementStore.homepage?.categorySection?.categories
                      ?.length ?? 0,
                })}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate("/home-management/category")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title="Product Highlight 3"
                secondaryText={homeManagementT("recently-book")}
                buttonText={t("common.edit")}
                toggleable
                onToggle={() => {
                  homeManagementStore.update("highlightProduct3Section", {
                    ...homeManagementStore.homepage?.highlightProduct3Section,
                    enabled:
                      !homeManagementStore.homepage?.highlightProduct3Section
                        ?.enabled,
                  })
                }}
                isToggle={
                  homeManagementStore.homepage?.highlightProduct3Section
                    ?.enabled
                }
                onPressEdit={() => {
                  navigate("/home-management/product-highlight-recently")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("recommended-shop-title")}
                secondaryText={homeManagementT("item-count", {
                  count:
                    homeManagementStore.homepage?.recommendShopSection?.shops
                      ?.length ?? 0,
                })}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate("/home-management/recommended-shop")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("secondary-banner")}
                secondaryText={homeManagementT("item-count", {
                  count:
                    homeManagementStore.homepage?.secondaryBannerSection
                      ?.length ?? 0,
                })}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate("/home-management/sub-banner")
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiFlexGroup className="mt-4 scroll-mb-28">
            <EuiFlexItem>
              <BannerPanel
                title={homeManagementT("recommended-product")}
                buttonText={""}
                toggleable
                onToggle={() => {
                  homeManagementStore.update(
                    "recommendProductSection",
                    !homeManagementStore.homepage?.recommendProductSection,
                  )
                }}
                isToggle={homeManagementStore.homepage?.recommendProductSection}
                onPressEdit={() => {}}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          {homeManagementStore.edited ? (
            <EuiButton
              fill
              className="mb-2"
              disabled={homeManagementStore.loading}
              color="primary"
              onClick={() => {
                homeManagementStore.submit()
              }}
            >
              {t("common.save")}
            </EuiButton>
          ) : null}
          {homeManagementStore.edited ? (
            <EuiButtonEmpty
              color="text"
              css={css`
                width: 240px;
              `}
              disabled={homeManagementStore.loading}
              onClick={() => {
                homeManagementStore.reset()
              }}
            >
              {homeManagementT("cancel-change")}
            </EuiButtonEmpty>
          ) : null}
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
