import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { genNewId } from "@app/model"
import {
  categoryFormValidator,
  CategoryHomeManagement,
} from "@app/model/homepage/category"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputImagePicker, useFormHelper } from "@reeeed-mp/ui-common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { upsertById } from "../helper"
import { useHomeManagementStore } from "../store/ctx"

export const EditorCategory: React.FC = () => {
  const { t } = useTranslation()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "homepage-management.category",
  })
  const { notificationStore } = useStore()
  const [loading, setLoading] = useState(false)
  const homeManagementStore = useHomeManagementStore()
  const [name, setName] = useState("")
  const { uploadFile } = useUploadFile()
  const navigate = useNavigate()
  const params = useParams()

  const onSubmit = async (
    values: CategoryHomeManagement,
  ): Promise<{ id: string | undefined }> => {
    const categories = upsertById(
      {
        id: values.id ? values.id : `${genNewId()}`,
        ...(values.cover?.fileInput?.tmpPath
          ? { image: values.cover?.fileInput?.tmpPath }
          : {}),
        name: values.name,
        url: values.url,
        downloadUrl: values.downloadUrl,
      },
      homeManagementStore.homepage?.categorySection?.categories,
    )

    homeManagementStore.update("categorySection", {
      categories,
    })

    return {
      id: "",
    }
  }

  const category =
    homeManagementStore.homepage?.categorySection?.categories?.find(
      (c) => c.id === params.id,
    )

  const initialValues = {
    id: category?.id,
    url: category?.url,
    name: category?.name,
    cover: category?.image
      ? {
          downloadUrl: category?.image,
          fileInput: undefined,
        }
      : undefined,
    downloadUrl:
      (category as CategoryHomeManagement)?.downloadUrl ?? category?.image,
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<CategoryHomeManagement, { id: string | undefined }>({
    id: params.id && params.id !== "create" ? params.id : undefined,
    initialValues,
    onGetItem: async (id) => {
      const category =
        homeManagementStore.homepage?.categorySection?.categories?.find(
          (c) => c.id === id,
        )

      setName(category?.name ?? "")

      return {
        id: category?.id,
        url: category?.url,
        name: category?.name,
        cover: category?.image
          ? {
              downloadUrl: category?.image,
              fileInput: undefined,
            }
          : undefined,
        downloadUrl:
          (category as CategoryHomeManagement)?.downloadUrl ?? category?.image,
      }
    },
    onError: () => {},
    onSubmit,
    onSubmitted: () => {
      navigate("/home-management/category")
    },
    validationSchema: categoryFormValidator,
  })

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      <div className="mb-10">
        <NavigationHeaderButton
          title={name ? name : editorT("add-category-title")}
          onClick={() => {
            navigate("/home-management/category")
          }}
        />
      </div>
      <FormInputImagePicker
        css={css`
          width: 256px !important;
          height: 256px !important;
          .euiFilePicker__promptText {
            width: 200px;
          }
          .euiImage {
            height: 256px !important;
            width: 256px !important;
            border-radius: 6px !important;
          }
          .euiImage__img {
            height: 256px !important;
            width: 256px !important;
            border-radius: 6px !important;
          }
        `}
        isInvalid={!!errors.cover}
        placeholder={
          <div className="flex flex-col text-center">
            <div>
              <p>{editorT("cover-category")}</p>
            </div>
            <div>
              <p className="whitespace-pre-wrap text-center text-sm">
                {editorT("cover-suggestion", {
                  size: "256x256 px",
                  type: ".jpg, .png",
                })}
              </p>
            </div>
          </div>
        }
        accept={`${contentType.JPG}, ${contentType.PNG}`}
        value={form?.downloadUrl || undefined}
        onChange={async (v: File | null) => {
          if (v) {
            try {
              setLoading(true)
              const { input, blob } = await uploadFile(v)
              setValue("cover.downloadUrl", blob)
              setValue("cover.fileInput", input)
              setValue("downloadUrl", blob)
            } catch (err: any) {
              if (err && err.message) {
                notificationStore.add({
                  title: "Error",
                  color: "danger",
                  text: err.message,
                })
              }
            } finally {
              setLoading(false)
            }
          }
        }}
      />

      <EuiFormRow
        isInvalid={!!errors.name}
        className="mt-6"
        label={editorT("name")}
      >
        <EuiFieldText
          isInvalid={!!errors.name}
          placeholder={t("common.please-fill")}
          value={form?.name}
          onChange={(e) => {
            setValue("name", e.target.value)
          }}
        />
      </EuiFormRow>

      <EuiFormRow className="mt-6" label={editorT("link")}>
        <EuiFieldText
          isInvalid={!!errors.url}
          placeholder={t("common.please-fill")}
          value={form?.url}
          onChange={(e) => {
            setValue("url", e.target.value)
          }}
        />
      </EuiFormRow>
      <div className="mt-6">
        <EuiButton
          css={css`
            width: 255px;
            height: 40px;
          `}
          fill
          disabled={loading}
          onClick={() => {
            submit()
          }}
        >
          <EuiText>
            <p>{form.id ? t("common.save") : editorT("add-category")}</p>
          </EuiText>
        </EuiButton>
        <EuiButtonEmpty
          css={css`
            width: 255px;
            height: 40px;
          `}
          color="text"
          disabled={loading}
          className="ml-4 items-center"
          onClick={() => {
            navigate("/home-management/category")
          }}
        >
          <EuiText>
            <p>{t("common.cancel")}</p>
          </EuiText>
        </EuiButtonEmpty>
      </div>
    </div>
  )
}
