import {
  AutoEmbedOption,
  EmbedConfig,
  EmbedMatchResult,
  URL_MATCHER,
} from "@lexical/react/LexicalAutoEmbedPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { LexicalEditor } from "lexical"
import { debounce } from "lodash-es"
import React, { useMemo, useState } from "react"
import * as ReactDOM from "react-dom"
import { ToolbarPlugins } from "../../../../types"
import useModal from "../../hook/modal"
import { CustomLexicalAutoEmbedPlugin } from "../../lexical-react/lexical-auto-embed-plugin"
import { Modal, TextInput } from "../../ui"
import { Button } from "../../ui/button"
import { INSERT_YOUTUBE_COMMAND } from "../youtube"
import { AutoEmbedMenuItem } from "./common-component"

interface PlaygroundEmbedConfig extends EmbedConfig {
  contentName: string
  icon?: JSX.Element
  exampleUrl: string
  keywords: Array<string>
  description?: string
  type: ToolbarPlugins
}

export const YoutubeEmbedConfig: PlaygroundEmbedConfig = {
  type: ToolbarPlugins.Youtube,
  contentName: "Youtube Video",
  exampleUrl: "https://www.youtube.com/watch?v=jNQXAC9IVRw",
  icon: <i className="mpe-icon mpe-youtube" />,
  insertNode: (editor: LexicalEditor, result: EmbedMatchResult) => {
    editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, result.id)
  },
  keywords: ["youtube", "video"],
  parseUrl: async (url: string) => {
    const match =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url)

    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null

    if (id != null) {
      return {
        id,
        url,
      }
    }

    return null
  },
}

const AutoEmbedMenu: React.FC<{
  selectedItemIndex: number | null
  onOptionClick: (option: AutoEmbedOption, index: number) => void
  onOptionMouseEnter: (index: number) => void
  options: Array<AutoEmbedOption>
}> = ({ options, selectedItemIndex, onOptionClick, onOptionMouseEnter }) => {
  return (
    <div className="mpe-typeahead-popover">
      <ul>
        {options.map((option: AutoEmbedOption, i: number) => (
          <AutoEmbedMenuItem
            index={i}
            isSelected={selectedItemIndex === i}
            onClick={() => onOptionClick(option, i)}
            onMouseEnter={() => onOptionMouseEnter(i)}
            key={option.key}
            option={option}
          />
        ))}
      </ul>
    </div>
  )
}

export function AutoEmbedDialog({
  embedConfig,
  onClose,
}: {
  embedConfig: PlaygroundEmbedConfig
  onClose: () => void
}): JSX.Element {
  const [text, setText] = useState("")
  const [editor] = useLexicalComposerContext()
  const [embedResult, setEmbedResult] = useState<EmbedMatchResult | null>(null)

  const validateText = useMemo(
    () =>
      debounce((inputText: string) => {
        const urlMatch = URL_MATCHER.exec(inputText)
        if (embedConfig != null && inputText != null && urlMatch != null) {
          Promise.resolve(embedConfig.parseUrl(inputText)).then(
            (parseResult) => {
              setEmbedResult(parseResult)
            },
          )
        } else if (embedResult != null) {
          setEmbedResult(null)
        }
      }, 200),
    [embedConfig, embedResult],
  )

  const onClick = () => {
    if (embedResult != null) {
      embedConfig.insertNode(editor, embedResult)
      onClose()
    }
  }

  return (
    <Modal onClose={onClose} title={embedConfig.contentName}>
      <TextInput
        label={embedConfig.contentName}
        onChange={(value) => {
          setText(value)
          validateText(value)
        }}
        value={text}
        placeholder={embedConfig.exampleUrl}
      />

      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          disabled={!embedResult}
          onClick={onClick}
          data-test-id={`${embedConfig.type}-embed-modal-submit-btn`}
        >
          Embed
        </Button>
      </div>
    </Modal>
  )
}

export const EmbedConfigs = [YoutubeEmbedConfig]

export default function AutoEmbedPlugin(): JSX.Element {
  const [modal, showModal] = useModal()

  const openEmbedModal = (embedConfig: PlaygroundEmbedConfig) => {
    showModal(`Embed ${embedConfig.contentName}`, (onClose) => (
      <AutoEmbedDialog embedConfig={embedConfig} onClose={onClose} />
    ))
  }

  const getMenuOptions = (
    activeEmbedConfig: PlaygroundEmbedConfig,
    embedFn: () => void,
    dismissFn: () => void,
  ) => {
    return [
      new AutoEmbedOption("Dismiss", {
        onSelect: dismissFn,
      }),
      new AutoEmbedOption(`Embed ${activeEmbedConfig.contentName}`, {
        onSelect: embedFn,
      }),
    ]
  }

  return (
    <>
      {modal}
      <CustomLexicalAutoEmbedPlugin<PlaygroundEmbedConfig>
        embedConfigs={EmbedConfigs}
        onOpenEmbedModalForConfig={openEmbedModal}
        getMenuOptions={getMenuOptions}
        menuRenderFn={(
          anchorElementRef,
          {
            selectedIndex,
            options,
            selectOptionAndCleanUp,
            setHighlightedIndex,
          },
        ) => {
          return anchorElementRef.current
            ? ReactDOM.createPortal(
                <div
                  className="mpe-typeahead-popover mpe-auto-embed-menu"
                  style={{
                    marginLeft: `${Math.max(
                      parseFloat(anchorElementRef.current.style.width) - 200,
                      0,
                    )}px`,
                    width: 200,
                  }}
                >
                  <AutoEmbedMenu
                    options={options}
                    selectedItemIndex={selectedIndex}
                    onOptionClick={(option: AutoEmbedOption, index: number) => {
                      setHighlightedIndex(index)
                      selectOptionAndCleanUp(option)
                    }}
                    onOptionMouseEnter={(index: number) => {
                      setHighlightedIndex(index)
                    }}
                  />
                </div>,
                anchorElementRef.current,
              )
            : null
        }}
      />
    </>
  )
}
