import { EuiSideNav } from "@elastic/eui"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import logo from "./se-ed.svg"
import "./style.scss"

type AdminRoute = {
  i18nKey: string
  icon: React.ReactElement
  path?: string
  component?: React.ReactElement
  provider?: (children: React.ReactNode) => React.ReactElement
}

type SubAdminRoute = {
  i18nKey: string
  path: string
  component: React.ReactElement
  hidden?: boolean
  disabled?: boolean
}

export type NavbarRoute = {
  route: AdminRoute
  subRoutes: SubAdminRoute[]
}

type LeftNavbarProps = {
  routes: NavbarRoute[]
}
export const LeftNavbar: React.FC<LeftNavbarProps> = (
  props: LeftNavbarProps,
) => {
  const { t } = useTranslation("translation", { keyPrefix: "nav" })
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className="navbar min-h-screen p-6">
      {/* <LogoHorizontalBlack className="mb-8 logo" /> */}
      <img src={logo} className="logo mb-8 ml-6 mt-6" alt="logo" />
      <EuiSideNav
        mobileBreakpoints={[]}
        items={props.routes.map((r) => ({
          id: r.route.i18nKey,
          name: t(r.route.i18nKey),
          icon: r.route.icon,
          isSelected: location.pathname === r.route.path,
          onClick: r.route.path
            ? () => {
                navigate(r.route.path!)
              }
            : undefined, // onClick === undefined Eui will render as non-clickable
          items: r.subRoutes
            .filter((s) => !s.hidden && !s.disabled)
            .map((s) => ({
              id: s.path,
              name: t(s.i18nKey),
              isSelected: location.pathname === s.path,
              onClick: () => {
                navigate(s.path)
              },
            })),
        }))}
      />
    </div>
  )
}
