import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from "@elastic/eui"
import { useTranslation } from "react-i18next"

type EditModalProps = {
  title: string
  subTitle?: string
  onClose: () => void
  onSubmit: () => void
  children: React.ReactNode
  submitText?: string
  closeText?: string
  loading?: boolean
}

export const EditModal = ({
  title,
  subTitle,
  onClose,
  onSubmit,
  loading,
  submitText,
  closeText,
  children,
}: EditModalProps) => {
  const { t } = useTranslation()
  if (!submitText) {
    submitText = t("common.save")
  }
  if (!closeText) {
    closeText = t("common.cancel")
  }
  return (
    <EuiModal
      className="book-category-page__modal"
      style={{ width: 575 }}
      onClose={onClose}
    >
      <EuiModalHeader className="flex flex-col items-start pb-6 pl-7 pt-7">
        <EuiModalHeaderTitle>
          <h2>{title}</h2>
        </EuiModalHeaderTitle>
        <EuiText size="s">
          <p>{subTitle}</p>
        </EuiText>
      </EuiModalHeader>
      <EuiModalBody>
        {children}
        <div className="mt-6 flex flex-row">
          <EuiButton
            fill
            fullWidth
            className="mr-4"
            color="primary"
            isDisabled={loading}
            style={{ maxWidth: 180 }}
            onClick={onSubmit}
          >
            {submitText}
          </EuiButton>
          <EuiButton
            color="text"
            style={{ width: 180 }}
            isDisabled={loading}
            onClick={onClose}
          >
            {closeText}
          </EuiButton>
        </div>
      </EuiModalBody>
    </EuiModal>
  )
}
