import { NavigationHeaderButton } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { EuiButton, EuiFieldText, EuiTab, EuiTabs, EuiText } from "@elastic/eui"
import { UilPlus, UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { HeroBannerItem, useGetHeroBanner } from "./hook/hero-banner"
import { useApi } from "@app/hook/api"
import dayjs from "dayjs"
import { useHomeManagementStore } from "../store/ctx"
import { useAlertDefaultErr } from "@app/hook/error"
import { ReOrderSelect, TableAction } from "../components/banner-list-action"
import { heroBannerPath, homepageManagementPath } from "../hook/path"
import { ImagePreview } from "../components/banner-common"
import { useDebounce } from "use-debounce"

export const HeroBannerListPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref = useRef() as TableRefImperative
  const { heroBannerSection } = useHomeManagementStore()
  const sectionKey = heroBannerSection?.sectionKey
  const { webUtility } = useApi()
  const {
    publishStatus,
    setPublishStatus,
    textSearch,
    setTextSearch,
    getHeroBanner,
  } = useGetHeroBanner()
  const [debouncedTextSearch] = useDebounce(textSearch, 500)

  const alertDefaultError = useAlertDefaultErr()

  const reorderBannerApi = webUtility(
    "/admin/section/{sectionKey}/hero-banner/{bannerKey}/order",
  )
    .method("post")
    .create()

  const deleteBannerApi = webUtility("/admin/hero-banner/{bannerKey}")
    .method("delete")
    .create({ sectionKey: true })

  const onEditHeroBanner = (bannerKey: string) => {
    navigate(heroBannerPath(bannerKey))
  }

  const deleteBanner = async (bannerKey: string) => {
    try {
      await deleteBannerApi({
        sectionKey,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const reorderBanner = async (bannerKey: string, order: string) => {
    try {
      await reorderBannerApi({
        order,
        sectionKey: sectionKey!,
        bannerKey,
      })
      await ref.current?.refetch()
    } catch (e) {
      alertDefaultError(e)
    }
  }

  const onGetItems: OnGetItems<HeroBannerItem> = async (props) => {
    const data = await getHeroBanner({
      filter: props.filter!,
      opts: props.opts,
    })

    return {
      items:
        data?.banners?.map((p) => {
          return {
            order: p.order!,
            id: p.id!,
            bannerKey: p.key!,
            title: p.title!,
            cover: p.cover!,
            link: p.link!,
            activePeriod: {
              startAt: dayjs(p.startAt!).toDate(),
              endAt: dayjs(p.endAt).toDate(),
            },
            createdAt: dayjs(p.createdAt!).toDate(),
            count: data.count || 0,
          }
        }) || [],

      count: data.count || 0,
    }
  }

  const columns = [
    {
      name: t("home-management.hero-banner.list.table.order-col"),
      render: (item: HeroBannerItem) => {
        return (
          <ReOrderSelect
            bannerKey={item.bannerKey!}
            sectionKey={sectionKey!}
            count={item.count}
            order={item.order!}
            onReorder={reorderBanner}
          />
        )
      },
      width: "100",
    },
    {
      field: "cover",
      name: t("home-management.hero-banner.list.table.cover-col"),
      render: (cover: HeroBannerItem["cover"]) => {
        return <ImagePreview src={cover} alt="cover" className="max-h-14" />
      },
    },
    {
      field: "title",
      name: t("home-management.hero-banner.list.table.name-col"),
    },
    {
      field: "activePeriod",
      name: t("home-management.hero-banner.list.table.active-period-col"),
      render: (activePeriod: HeroBannerItem["activePeriod"]) => {
        return t("time-range", {
          value: {
            startAt: activePeriod.startAt,
            endAt: activePeriod.endAt,
          },
        })
      },
      width: "35%",
    },
    {
      field: "createdAt",
      name: t("home-management.hero-banner.list.table.create-at-col"),
      render: (createdAt: HeroBannerItem["createdAt"]) => {
        return t("common.datetime", {
          value: createdAt ?? "",
        })
      },
    },
    {
      width: "50",
      actions: [
        {
          render: (item: HeroBannerItem) => {
            return (
              <TableAction
                onEdit={onEditHeroBanner}
                onDelete={deleteBanner}
                {...item}
                sectionKey={sectionKey!}
              />
            )
          },
        },
      ],
    },
  ]

  return (
    <div className="flex max-w-screen-xl flex-col gap-y-6">
      <div className="flex w-full items-center justify-between">
        <NavigationHeaderButton
          title={t("home-management.hero-banner.list.title")}
          onClick={() => {
            navigate(homepageManagementPath())
          }}
        />
        <EuiButton
          fill
          iconType={UilPlus}
          onClick={() => {
            navigate(heroBannerPath("create"))
          }}
        >
          {t("home-management.hero-banner.list.create")}
        </EuiButton>
      </div>
      <div className="flex justify-between">
        <EuiTabs>
          <EuiTab
            isSelected={publishStatus === "HERO_BANNER_STATE_ACTIVE"}
            onClick={() => setPublishStatus("HERO_BANNER_STATE_ACTIVE")}
          >
            <EuiText size="m" className="font-bold">
              <h5>{t("home-management.hero-banner.list.filter.active")}</h5>
            </EuiText>
          </EuiTab>
          <EuiTab
            isSelected={publishStatus === "HERO_BANNER_STATE_PENDING"}
            onClick={() => setPublishStatus("HERO_BANNER_STATE_PENDING")}
          >
            <EuiText size="m" className="font-bold">
              <h5>{t("home-management.hero-banner.list.filter.pending")}</h5>
            </EuiText>
          </EuiTab>
          <EuiTab
            isSelected={publishStatus === "HERO_BANNER_STATE_INACTIVE"}
            onClick={() => setPublishStatus("HERO_BANNER_STATE_INACTIVE")}
          >
            <EuiText size="m" className="font-bold">
              <h5>{t("home-management.hero-banner.list.filter.inactive")}</h5>
            </EuiText>
          </EuiTab>
        </EuiTabs>
        <EuiFieldText
          icon={<UilSearch />}
          placeholder={t("home-management.hero-banner.list.filter.text-search")}
          onChange={(e) => {
            setTextSearch(e.target.value)
          }}
        />
      </div>

      <Table<HeroBannerItem>
        ref={ref}
        columns={columns}
        sorter={{
          field: "order",
          direction: "desc",
        }}
        swrKey="/hero-banner-list"
        onGetItems={onGetItems}
        isCanSelect={false}
        filter={{
          textSearch: debouncedTextSearch,
          state: publishStatus,
        }}
      />
    </div>
  )
}
