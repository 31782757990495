import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { genNewId } from "@app/model"
import { BannerInput } from "@app/model/homepage/banner"
import {
  editorHighlightFormValidator,
  HighlightBannerForm,
} from "@app/model/homepage/highlight"
import {
  EuiButton,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputImagePicker, useFormHelper } from "@reeeed-mp/ui-common"
import moment from "moment"
import "moment/locale/th"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { upsertById } from "../helper"
import { useHomeManagementStore } from "../store/ctx"

export const EditorHighlightBanner: React.FC = () => {
  const { t } = useTranslation()
  const { uploadFile } = useUploadFile()
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const params = useParams()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "homepage-management.highlight-banner",
  })
  const [loading, setLoading] = useState(false)
  const homeManagementStore = useHomeManagementStore()

  const onSubmit = async (
    values: HighlightBannerForm,
  ): Promise<{ id: string | undefined }> => {
    const newHighlight = upsertById(
      {
        id: values.id ? values.id : `${genNewId()}`,
        name: values.name,
        url: values.url,
        imageAlt: values.imageAlt,
        startDate: values.startDate?.toISOString(),
        endDate: values.endDate?.toISOString(),
        ...(values.cover?.fileInput
          ? { image: values.cover?.fileInput?.tmpPath }
          : {}),
        downloadUrl: values.downloadUrl,
      },
      homeManagementStore.homepage?.highlightBannerSection?.banners,
    )
    homeManagementStore.update("highlightBannerSection", {
      ...homeManagementStore.homepage?.highlightBannerSection,
      banners: newHighlight,
    })

    return {
      id: "",
    }
  }

  const initialValues = {}
  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
    setValues,
  } = useFormHelper<HighlightBannerForm, { id: string | undefined }>({
    id: params.id && params.id !== "create" ? params.id : undefined,
    initialValues,
    onGetItem: async (id) => {
      const highlight: BannerInput | undefined =
        homeManagementStore.homepage?.highlightBannerSection?.banners?.find(
          (b) => {
            return b.id === id
          },
        )

      return {
        id: highlight?.id,
        imageAlt: highlight?.imageAlt,
        startDate: highlight?.startDate
          ? moment(highlight?.startDate)
          : undefined,
        endDate: highlight?.endDate ? moment(highlight?.endDate) : undefined,
        name: highlight?.name,
        downloadUrl: highlight?.downloadUrl ?? highlight?.image,
        url: highlight?.url,
        cover: highlight?.image
          ? {
              downloadUrl: highlight?.image,
              fileInput: undefined,
            }
          : undefined,
      }
    },
    onError: () => {},
    onSubmit,
    onSubmitted: async () => {
      navigate("/home-management/highlight-banner")
    },
    validationSchema: editorHighlightFormValidator,
  })

  useEffect(() => {
    const highlight: BannerInput | undefined =
      homeManagementStore.homepage?.highlightBannerSection?.banners?.find(
        (b) => {
          return b.id === params.id
        },
      )
    if (highlight) {
      setValues({
        id: highlight?.id,
        imageAlt: highlight?.imageAlt,
        startDate: highlight?.startDate
          ? moment(highlight?.startDate)
          : undefined,
        endDate: highlight?.endDate ? moment(highlight?.endDate) : undefined,
        name: highlight?.name,
        downloadUrl: highlight?.downloadUrl ?? highlight?.image,
        url: highlight?.url,
        cover: highlight?.image
          ? {
              downloadUrl: highlight?.image,
              fileInput: undefined,
            }
          : undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeManagementStore.homepage?.highlightBannerSection?.banners])

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      <div className="mb-10">
        <NavigationHeaderButton
          title={editorT("title")}
          onClick={() => {
            navigate(-1)
          }}
        />
      </div>
      <div>
        <div
          css={css`
            .euiFormRow {
              max-width: 736px !important;
            }
          `}
        >
          <EuiFormRow
            isInvalid={!!errors.name}
            label={editorT("name")}
            display={"row"}
            fullWidth
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              isInvalid={!!errors.name}
              fullWidth
              css={css`
                .euiFieldText {
                  max-width: 736px !important;
                }
              `}
              value={form?.name}
              onChange={(e) => {
                setValue("name", e.target.value)
              }}
              disabled={loading}
            />
          </EuiFormRow>
        </div>
      </div>
      <div>
        <div>
          <FormInputImagePicker
            css={css`
              width: 740px !important;
              height: 355px !important;
              .euiImage {
                width: 740px !important;
                height: 355px !important;
                border-radius: 6px !important;
                object-fit: cover;
              }
              .euiImage__img {
                width: 740px !important;
                height: 355px !important;
                border-radius: 6px !important;
              }
            `}
            isInvalid={!!errors.cover}
            disabled={loading}
            className="mt-6"
            placeholder={
              <div className="flex flex-col text-center">
                <div>
                  <p>{editorT("banner-image")}</p>
                </div>
                <div>
                  <p className="whitespace-pre-wrap text-center text-sm">
                    {editorT("cover-suggestion", {
                      size: "1844x880 px",
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            accept={`${contentType.JPG}, ${contentType.PNG}`}
            value={form?.downloadUrl || undefined}
            onChange={async (v: File | null) => {
              if (v) {
                try {
                  setLoading(true)
                  const { input, blob } = await uploadFile(v)
                  setValue("cover.downloadUrl", blob)
                  setValue("cover.fileInput", input)
                  setValue("downloadUrl", blob)
                } catch (err: any) {
                  if (err && err.message) {
                    notificationStore.add({
                      title: "Error",
                      color: "danger",
                      text: err.message,
                    })
                  }
                } finally {
                  setLoading(false)
                }
              }
            }}
          />
        </div>
      </div>
      <div className="pt-6">
        <div
          css={css`
            .euiFormRow {
              max-width: 736px !important;
            }
          `}
        >
          <EuiFormRow
            isInvalid={!!errors.url}
            label={t("common.link")}
            display={"row"}
            fullWidth
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              fullWidth
              css={css`
                .euiFormControlLayout__childrenWrapper {
                  min-width: 736px;
                }
              `}
              isInvalid={!!errors.url}
              value={form?.url}
              onChange={(e) => {
                setValue("url", e.target.value)
              }}
              disabled={loading}
            />
          </EuiFormRow>
        </div>
      </div>
      <EuiFlexGroup className="pt-6">
        <EuiFlexItem
          css={css`
            .euiFormRow {
              max-width: 736px !important;
            }
          `}
        >
          <EuiFormRow label={editorT("meta-tag")} display={"row"} fullWidth>
            <EuiFieldText
              placeholder={t("common.please-fill")}
              value={form?.imageAlt}
              fullWidth
              css={css`
                .euiFormControlLayout__childrenWrapper {
                  min-width: 736px;
                }
              `}
              onChange={(e) => {
                setValue("imageAlt", e.target.value)
              }}
              disabled={loading}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>{" "}
      <div className="pt-6">
        <div>
          <p
            css={css`
              margin: 0px !important;
              font-weight: 700;
              font-size: 12px;
            `}
          >
            {editorT("duration")}
          </p>
          <div
            css={css`
              max-width: 400px;
            `}
            className="flex items-center justify-between"
          >
            <EuiFormRow>
              <EuiDatePicker
                isInvalid={!!errors.startDate}
                selected={form?.startDate?.locale("th")}
                dateFormat={form?.startDate?.format("DD/MM/YYYY HH:mm น.")}
                onChange={(date) => {
                  setValue("startDate", date ?? moment().locale("th"))
                }}
                showTimeSelect={true}
              />
            </EuiFormRow>
            <EuiText className="mx-4">{editorT("to")}</EuiText>
            <EuiFormRow>
              <EuiDatePicker
                isInvalid={!!errors.endDate}
                disabled={!form?.startDate}
                minDate={form?.startDate}
                dateFormat={form?.endDate?.format("DD/MM/YYYY HH:mm น.")}
                minTime={
                  form?.startDate?.isSame(form.endDate, "day")
                    ? form?.startDate
                    : moment().hours(0).minutes(0)
                }
                maxTime={moment().hours(23).minutes(59)}
                selected={form?.endDate?.locale("th")}
                showTimeSelect={true}
                onChange={(date) => {
                  setValue("endDate", date ?? moment().locale("th"))
                }}
              />
            </EuiFormRow>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <EuiButton
          fill
          color="primary"
          className="mr-4"
          css={css`
            width: 255px;
          `}
          disabled={loading}
          onClick={() => {
            submit()
          }}
        >
          {form.id ? t("common.save") : editorT("create-banner")}
        </EuiButton>
        <EuiButton
          color="text"
          css={css`
            width: 255px;
          `}
          disabled={loading}
          onClick={() => {
            navigate("/home-management/highlight-banner")
          }}
        >
          {t("common.cancel")}
        </EuiButton>
      </div>
    </div>
  )
}
