import { Table, TableRefImperative } from "@app/component/table"
import { useStreamApi } from "@app/hook/api"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { getTranslationFromBankCode } from "@app/model/bank-account"
import { constants } from "@app/model/constants"
import { TempFile } from "@app/model/form"
import { definitions } from "@app/vendor/order-specs/order_service"
import {
  EuiBadge,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiFilePicker,
  EuiHorizontalRule,
  EuiModal,
  EuiProgress,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { Filter, OnGetItems, theme } from "@reeeed-mp/ui-common"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  UploadSellerValidateWithdrawError,
  useFindSellerWithdrawRequestApi,
} from "./hook"
import { useSearchParams } from "react-router-dom"

type Item = {
  id: string
  shopId: string
  shopName: string
  bankAccount: definitions["shop.v1.BankAccount"]
  totalWithdrawAmount: number
  withdrawFee: number
  createdAt: Date
  status: definitions["order.v1.WithdrawStatus"]
  remark?: string
  transferDate?: Date
}

export const WithdrawRequest: React.FC = () => {
  const { t } = useTranslation()
  const { order } = useStreamApi()
  const { uploadFile } = useUploadFile()
  const { notificationStore } = useStore()
  const ref = useRef() as TableRefImperative
  const getter = useFindSellerWithdrawRequestApi()

  const now = new Date()
  const [searchParams] = useSearchParams()
  const searchMonthParamKey = "month"
  const searchYearParamKey = "year"
  const searchMonthParam = searchParams.get(searchMonthParamKey)
  const searchYearParam = searchParams.get(searchYearParamKey)
  const currMonth = now.getMonth() + 1
  const currYear = now.getFullYear()
  const [filter, setFilter] = useState<Filter>({
    month: searchMonthParam || currMonth.toString(),
    year: Number(searchYearParam) || currYear,
  })
  const [downloadPercentage, setDownloadPercentage] = useState<number>(-1)
  const [uploadPercentage, setUploadPercentage] = useState<number>(-1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenUploadModal, setIsOpenUploadModal] = useState<boolean>(false)
  const [validateUploadErrorMessage, setValidateUploadErrorMessage] = useState<
    Array<UploadSellerValidateWithdrawError>
  >([])

  const cancelDownload = useRef<null | (() => void)>(null)
  const cancelUpload = useRef<null | (() => void)>(null)
  useEffect(() => {
    return () => {
      cancelDownload.current?.()
      cancelUpload.current?.()
    }
  }, [])

  const columns: Array<EuiBasicTableColumn<Item>> = [
    {
      field: "id",
      name: "Id",
    },
    {
      field: "shopId",
      name: "Shop Id",
    },
    {
      field: "shopName",
      name: t("withdraw-request.shop-name"),
    },
    {
      name: t("withdraw-request.bank-name"),
      render: (row: Item) => {
        const bankNameTran = getTranslationFromBankCode(
          row.bankAccount.bankCode!,
        )
        return (
          <div>
            <EuiText size="xs">{t(bankNameTran)}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("withdraw-request.bank-account-number"),
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.bankAccount.accountNumber}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("withdraw-request.bank-owner-name"),
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.bankAccount.ownerName}</EuiText>
          </div>
        )
      },
    },
    {
      field: "totalWithdrawAmount",
      name: t("withdraw-request.total-withdraw-amount"),
    },
    {
      field: "createdAt",
      name: t("withdraw-request.request-date"),
      sortable: false,
      render: (date: string | Date) => {
        return (
          <p className="caption p-center text-xs" style={{ margin: 0 }}>
            {date ? t("common.datetime", { value: date }) : "-"}
          </p>
        )
      },
    } as EuiBasicTableColumn<Item>,
    {
      name: t("withdraw-request.status-withdraw"),
      render: (row: Item) => {
        let color = ""
        if (row.status === "WITHDRAW_STATUS_FAILURE") {
          color = "#FF7E62"
        } else if (row.status === "WITHDRAW_STATUS_PENDING") {
          color = theme.colors.LIGHT.secondaryYellow
        } else {
          color = "#4dd2ca"
        }
        return (
          <EuiBadge color={color}>
            <EuiText size="xs">
              {t(`withdraw-request.status.${row.status}`)}
            </EuiText>
          </EuiBadge>
        )
      },
    },
    {
      field: "remark",
      name: t("withdraw-request.remark"),
    },
    {
      field: "transferDate",
      name: t("withdraw-request.transfer-date"),
      render: (date: string | Date) => {
        return (
          <p className="caption p-center text-xs" style={{ margin: 0 }}>
            {date ? t("common.datetime", { value: date }) : "-"}
          </p>
        )
      },
    } as EuiBasicTableColumn<Item>,
  ]

  const today = new Date()
  const lastDateOfMonth = new Date(today.getFullYear(), today.getMonth(), 0)

  const onUploadXlsx = async (files: FileList | null) => {
    setValidateUploadErrorMessage([])

    let xlsx: TempFile | undefined = undefined
    if (files?.length && files?.length > 0) {
      const file = files[0]
      const { input } = await uploadFile(file)
      xlsx = input
    } else {
      return
    }

    setIsLoading(true)
    setUploadPercentage(0)

    const uploadWithdraw = await order(
      "/withdraw-management/upload-seller-withdraw-result",
    )
      .method("post")
      .create()({
        xlsx,
      })
      .then((s) => s.getReader())
    cancelUpload.current = () => {
      uploadWithdraw.cancel()
    }

    try {
      /* eslint-disable no-constant-condition */
      while (true) {
        const data = await uploadWithdraw.read()
        if (data.done) {
          break
        }

        if (data.value.result?.validateUploadError?.validateUploadError) {
          const ins = validateUploadErrorMessage
          ins.push(data.value.result.validateUploadError.validateUploadError)
          setValidateUploadErrorMessage(ins)
        }

        if (data.value?.error) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: data.value?.error.message,
          })
        } else {
          const progress = data.value?.result?.progress?.percentageRatio
          setUploadPercentage(progress || 0)

          if (
            progress &&
            progress >= 100 &&
            validateUploadErrorMessage &&
            validateUploadErrorMessage.length === 0
          ) {
            notificationStore.add({
              color: "success",
              title: "Success",
            })
            setIsOpenUploadModal(false)
            setValidateUploadErrorMessage([])
            ref.current.refetch()
            break
          }
        }
      }
    } finally {
      setIsLoading(false)
      setUploadPercentage(-1)
      cancelUpload.current = null
    }
  }

  const downloadXlsx = async () => {
    setIsLoading(true)
    setDownloadPercentage(0)

    const downloadWithdrawRequest = await order(
      "/withdraw-management/download-seller-withdraw",
    )
      .method("get")
      .create()({
        "filter.month": filter.month,
        "filter.year": filter.year,
      })
      .then((s) => s.getReader())
    cancelDownload.current = () => {
      downloadWithdrawRequest.cancel()
    }

    try {
      /* eslint-disable no-constant-condition */
      while (true) {
        const data = await downloadWithdrawRequest.read()
        if (data.done) {
          break
        }

        if (data.value?.error) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: data.value?.error.message,
          })
        } else {
          setDownloadPercentage(
            data.value?.result?.reportProgress?.percentageRatio || 0,
          )
          if (data.value?.result?.reportProgress?.downloadUrl) {
            const downloadPath = data.value.result.reportProgress.downloadUrl
            const a = document.createElement("a")
            a.href = downloadPath
            a.click()
            break
          }
        }
      }
    } finally {
      setIsLoading(false)
      setDownloadPercentage(-1)
      cancelDownload.current = null
    }
  }

  const onGetItems: OnGetItems<Item> = async ({ filter, opts }) => {
    const rs = await getter(
      {
        month: filter!.month,
        year: filter!.year,
      },
      opts,
    )

    return {
      items:
        rs?.withdraws?.map((w) => {
          return {
            id: w.id!,
            shopId: w.shopId!,
            shopName: w.shopName!,
            bankAccount: w.bankAccount!,
            totalWithdrawAmount:
              Number(w.totalWithdrawAmount) / constants.power10OfDecimalPrice!,
            withdrawFee:
              Number(w.withdrawFee!) / constants.power10OfDecimalPrice,
            createdAt: new Date(w.createdAt!),
            status: w.status!,
            remark: w.remark,
            transferDate: w.transferDate ? new Date(w.transferDate) : undefined,
          }
        }) || [],
      count: Number(rs?.count || 0),
    }
  }

  return (
    <div>
      {isOpenUploadModal ? (
        <EuiModal
          onClose={() => {
            setIsOpenUploadModal(false)
            setValidateUploadErrorMessage([])
          }}
          style={{
            width: 800,
            height: 500,
          }}
          css={css(`
            padding: 25px;
            .euiModal__closeIcon {
              margin-top: 5px;
              margin-right: 5px;
            }
          `)}
        >
          <div className="mb-5 flex justify-center">
            <EuiText>
              <h2 className="mt-1 text-xl font-bold leading-8">
                {t("withdraw-request.upload-modal.title")}
              </h2>
            </EuiText>
          </div>

          <EuiFilePicker
            className="max-w-full"
            initialPromptText={t("withdraw-request.upload-modal.choose-file")}
            disabled={isLoading}
            display="default"
            accept={contentType.XLSX}
            onChange={onUploadXlsx}
          />
          {uploadPercentage !== -1 ? (
            <EuiProgress
              valueText={true}
              max={100}
              color="success"
              size="s"
              value={uploadPercentage}
            />
          ) : null}
          {validateUploadErrorMessage &&
          validateUploadErrorMessage.length > 0 ? (
            <div className="mt-6 overflow-y-scroll">
              <EuiText className="mt-1 flex">
                <h2 className="m-0 text-lg font-bold text-red">
                  {t("withdraw-request.upload-modal.validate-upload-error")}
                </h2>
              </EuiText>
              {validateUploadErrorMessage.map((vErrRow) => {
                return vErrRow.map((r) => {
                  return (
                    <div>
                      <EuiText className="mt-1 flex">
                        <p className="m-0 font-bold">
                          {`${t("withdraw-request.upload-modal.field")}: `}
                        </p>
                        <p className="ml-1">{r.field}</p>
                      </EuiText>
                      <EuiText className="flex">
                        <p className="m-0 font-bold">
                          {`${t("withdraw-request.upload-modal.row")}: `}
                        </p>
                        <p className="ml-1">{r.row}</p>
                      </EuiText>
                      <EuiText className="flex">
                        <p className="m-0 font-bold">
                          {`${t("withdraw-request.upload-modal.remark")}: `}
                        </p>
                        <p className="ml-1">{r.remark}</p>
                      </EuiText>
                      <EuiHorizontalRule />
                    </div>
                  )
                })
              })}
            </div>
          ) : null}
        </EuiModal>
      ) : null}
      <div className="flex justify-between">
        <div>
          <EuiText>
            <h2 className="font-bold">
              {t("withdraw-request.withdraw-request-title")}
            </h2>
          </EuiText>
        </div>
        <div className="flex justify-center">
          <EuiButtonEmpty
            className="mr-2 border border-solid px-3 py-4"
            onClick={() => setIsOpenUploadModal(true)}
            disabled={isLoading}
          >
            {t("withdraw-request.upload")}
          </EuiButtonEmpty>
          <div className="inline-flex flex-col">
            <EuiButton
              className="border border-solid px-3 py-4"
              onClick={downloadXlsx}
              isLoading={downloadPercentage !== -1}
              disabled={isLoading}
            >
              {t("withdraw-request.download")}
            </EuiButton>

            {downloadPercentage !== -1 && (
              <EuiProgress
                className="ml-[3px] mt-[-3px] w-32"
                value={downloadPercentage}
                max={100}
                size="xs"
                color="success"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 flex">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={["year", "month"]}
            css={css`
              .MuiInputBase-root {
                height: 40px;
              }
            `}
            maxDate={dayjs(lastDateOfMonth)}
            onChange={(d: any) => {
              setFilter({
                ...filter,
                month: d !== null ? d["$M"] + 1 : undefined,
                year: d !== null ? d["$y"] : undefined,
              })
            }}
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className="mt-4">
        <Table
          ref={ref}
          swrKey="/seller-withdraw-management"
          columns={columns}
          filter={filter}
          onGetItems={onGetItems}
          isCanSelect={false}
          sorter={{ field: "createdAt", direction: "desc" }}
        />
      </div>
    </div>
  )
}
