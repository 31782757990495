import { useApi } from "@app/hook/api"
import { useStore } from "@app/hook/store"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useHomeManagementStore } from "../../store/ctx"
import { homepageManagementPath } from "../../hook/path"

type NavigateFormProps = definitions["webutility.v1.HighlightMenuInput"]
type HighlightFormProps = definitions["webutility.v1.HighlightMenuInput"] & {
  fileInput?: string
}

const initialFormValues: NavigateFormProps = {
  name: "",
  link: "",
}

const validationSchema = Yup.object({
  name: Yup.string().required(),
  link: Yup.string().required(),
})

const validationSchemaHighlight = Yup.object({
  name: Yup.string().required(),
  link: Yup.string().required(),
  cover: Yup.string().notRequired(),
  fileInput: Yup.string().notRequired(),
})

export const useDeleteNavigationTab = () => {
  const { notificationStore } = useStore()
  const { webUtility } = useApi()
  const deleteApi = webUtility("/admin/menu/{menuKey}")
    .method("delete")
    .create({
      sectionKey: true,
    })

  const { mainMenuSection, refreshHomepageSection } = useHomeManagementStore()
  const sectionKey = mainMenuSection?.sectionKey

  const onDelete = async (menuKey: string) => {
    try {
      await deleteApi({ menuKey, sectionKey })
      notificationStore.add({
        title: "Success",
        color: "success",
      })
    } catch (error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: errToDefaultI18nKey(error),
      })
    }
    refreshHomepageSection()
  }

  return { onDelete }
}

export const useDeleteHighlightNavigationTab = () => {
  const { notificationStore } = useStore()
  const { webUtility } = useApi()

  const { mainMenuSection, refreshHomepageSection } = useHomeManagementStore()
  const sectionKey = mainMenuSection?.sectionKey

  const deleteApi = webUtility("/admin/highlight-menu/{highlightMenuKey}")
    .method("delete")
    .create({
      sectionKey: true,
    })

  const onDelete = async (highlightMenuKey: string) => {
    try {
      await deleteApi({ sectionKey, highlightMenuKey })
      notificationStore.add({
        title: "Success",
        color: "success",
      })
    } catch (error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: errToDefaultI18nKey(error),
      })
    }
    refreshHomepageSection()
  }

  return { onDelete }
}

export const useFormNavigation = (menuKey: string) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { mainMenuSection, refreshHomepageSection } = useHomeManagementStore()
  const sectionKey = mainMenuSection?.sectionKey
  const { webUtility } = useApi()

  const createApi = webUtility("/admin/menu").method("post").create()

  const getApi = webUtility("/admin/menu/{menuKey}").method("get").create()

  const updateApi = webUtility("/admin/menu/{menuKey}").method("put").create()

  const onSubmit = useCallback(
    async (
      input: NavigateFormProps,
    ): Promise<{ menuKey: string | undefined }> => {
      if (menuKey !== "create") {
        try {
          await updateApi({
            sectionKey,
            menuKey,
            input,
          })
          return { menuKey: undefined }
        } catch (error) {
          throw errToDefaultI18nKey(error)
        }
      }
      const resp = await createApi({
        sectionKey,
        input: {
          name: input.name,
          link: input.link,
        },
      })
      refreshHomepageSection()
      return { menuKey: resp.data.menuKey }
    },
    [updateApi, createApi, menuKey, refreshHomepageSection],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(homepageManagementPath())
  }

  const onGetItem = async (): Promise<NavigateFormProps> => {
    if (menuKey !== "create") {
      const resp = await getApi({
        sectionKey,
        menuKey,
      })
      return resp.data.menu!
    }
    return {
      ...initialFormValues,
    }
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<NavigateFormProps, { menuKey?: string }>({
    id: menuKey,
    validationSchema,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}

export const useFormHighlightNavigation = (highlightMenuKey: string) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { webUtility } = useApi()
  const { mainMenuSection } = useHomeManagementStore()
  const sectionKey = mainMenuSection?.sectionKey

  const createApi = webUtility("/admin/highlight-menu").method("post").create()

  const getApi = webUtility("/admin/highlight-menu/{highlightMenuKey}")
    .method("get")
    .create()

  const updateApi = webUtility("/admin/highlight-menu/{highlightMenuKey}")
    .method("put")
    .create()

  const onSubmit = useCallback(
    async (
      input: HighlightFormProps,
    ): Promise<{ highlightMenuKey: string | undefined }> => {
      if (highlightMenuKey !== "create") {
        try {
          await updateApi({
            sectionKey,
            highlightMenuKey,
            input: {
              icon: input.fileInput,
              name: input.name,
              link: input.link,
            },
          })
          return { highlightMenuKey: undefined }
        } catch (error) {
          throw errToDefaultI18nKey(error)
        }
      }
      const resp = await createApi({
        sectionKey,
        input: {
          name: input.name,
          icon: input.fileInput,
          link: input.link,
        },
      })
      return { highlightMenuKey: resp.data.highlightMenuKey }
    },
    [updateApi, createApi, highlightMenuKey, sectionKey],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(-1)
  }

  const onGetItem = async (): Promise<HighlightFormProps> => {
    if (highlightMenuKey !== "create") {
      const resp = await getApi({
        sectionKey,
        highlightMenuKey,
      })
      return {
        name: resp.data.highlightMenu?.name,
        link: resp.data.highlightMenu?.link,
        icon: resp.data.highlightMenu?.icon,
        fileInput: undefined,
      }
    }
    return {
      name: "",
      link: "",
      icon: undefined,
      fileInput: undefined,
    }
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<HighlightFormProps, { highlightMenuKey?: string }>(
    {
      id: highlightMenuKey,
      validationSchema: validationSchemaHighlight,
      onSubmit,
      onSubmitted,
      onGetItem,
      onError,
    },
  )

  return form
}

export const useUpdateMainMenu = (sectionKey: string) => {
  const { webUtility } = useApi()
  const { refreshHomepageSection } = useHomeManagementStore()
  const updateMainMenuDetailApi = webUtility("/admin/main-menu-section")
    .method("put")
    .create()

  const updateMainMenuDetail = async ({
    input,
  }: {
    input: {
      description: string
    }
  }) => {
    await updateMainMenuDetailApi({
      key: sectionKey,
      input: {
        description: input.description,
      },
    }).then(() => {
      refreshHomepageSection()
    })
  }

  return { updateMainMenuDetail }
}
