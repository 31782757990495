import { EuiButtonEmpty, EuiPanel, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"
import { theme } from "@reeeed-mp/ui-common"
import { ToggleVisible } from "./toggle-visible-button"
export const BannerPanel = (
  props: {
    title: string
    buttonText: string
    onPressEdit: () => void
    secondaryText?: string
    toggleable?: boolean
  } & {
    toggleable?: true
    onToggle?: () => void
    isToggle?: boolean
  },
) => {
  return (
    <EuiPanel
      css={css`
        background-color: #fbfcfd !important;
      `}
      className="relative flex h-56 w-full flex-col items-center justify-center border border-solid border-table shadow-none"
    >
      <EuiText>
        <h3
          css={css`
            font-size: 16px !important;
          `}
        >
          {props.title}
        </h3>
      </EuiText>
      {props.secondaryText ? (
        <p className="mb-0 mt-2 text-[12px]">{props.secondaryText}</p>
      ) : null}
      {props.buttonText ? (
        <EuiButtonEmpty
          className="mt-5"
          css={css`
            color: ${theme.colors.LIGHT.primaryPurple} !important;
          `}
          color="text"
          onClick={props.onPressEdit}
        >
          {props.buttonText}
        </EuiButtonEmpty>
      ) : null}
      {props.toggleable && (
        <ToggleVisible onChange={props.onToggle!} visible={props.isToggle!} />
      )}
    </EuiPanel>
  )
}
