import { constants } from "@app/model/constants"
import { priceInTransform } from "@app/utils/price"
import { EuiPanel } from "@elastic/eui"
import {
  MpTextEditor,
  parseEditorState,
  stringifiedEmptyEditorState,
} from "@reeeed-mp/text-editor"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { GalleriesCarousel } from "./carousel"
import { HideProductButton } from "./hide-product-button"
import { useGetEVoucher } from "./hook"
import { ProductHiddenBadge } from "./product-hidden-badge"
import { TitleAndButtonGroup } from "./product-title-button"
import { RevenueBox } from "./revenue-box"

type EVoucherImageGallery = {
  id: string
  url: string
}

export const EVoucherManagement: React.FC = () => {
  const { t } = useTranslation()
  const { shopId, productId } = useParams()
  const { data } = useGetEVoucher(shopId ?? "", productId ?? "")
  const [gallerySelected, setGallerySelected] = useState("cover")

  const adminEVoucher = data?.eVoucher
  const isBanned = adminEVoucher?.eVoucher?.eVoucher?.isBanned
  const isHidden = adminEVoucher?.eVoucher?.eVoucher?.isHidden

  const comingSoon = () => {
    alert("Coming soon")
  }

  const galleries: Array<EVoucherImageGallery> = []

  if (!adminEVoucher || !adminEVoucher.eVoucher) {
    return null
  }

  if (adminEVoucher.eVoucher.eVoucher?.coverImage?.url) {
    galleries.push({
      id: "cover",
      url: adminEVoucher.eVoucher.eVoucher.coverImage.url,
    })
  }

  if (adminEVoucher.eVoucher.eVoucher?.galleryImages) {
    for (const image of adminEVoucher.eVoucher.eVoucher.galleryImages) {
      if (image.file?.url) {
        galleries.push({
          id: image.id!,
          url: image.file.url,
        })
      }
    }
  }

  return (
    <div className="flex h-full w-full flex-col space-y-4 px-4">
      <div className="flex flex-row justify-between">
        <TitleAndButtonGroup />
        <HideProductButton onClick={comingSoon} isHide={isBanned} />
      </div>
      <EuiPanel
        hasBorder
        className="relative flex items-center justify-between"
      >
        <RevenueBox
          title={t("product.revenue-header")}
          text={t("product.revenue", {
            revenue:
              Number(adminEVoucher.revenue) / constants.power10OfDecimalPrice,
          })}
        />
        <RevenueBox
          border
          title={t("product.e-voucher-buy-count")}
          text={t("product.buy-count", {
            buyCount: adminEVoucher.buyCount,
          })}
        />
      </EuiPanel>
      <div className="flex w-full gap-10 pt-6">
        <div className="w-3/5">
          <div className="h-96 w-full shrink-0">
            <GalleriesCarousel
              desktopMode={true}
              ready={true}
              onChange={setGallerySelected}
              selected={gallerySelected}
              galleries={galleries}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-row">
            <div className="w-2/3 text-xl font-semibold">
              {adminEVoucher.eVoucher.eVoucher?.name}
            </div>
            <div className="w-1/3 justify-items-end">
              <ProductHiddenBadge isHidden={isHidden} />
            </div>
          </div>

          <div className="flex">
            <ProductDetailItem
              label={t("product.main-category")}
              value={adminEVoucher.eVoucher.categories?.[0].name ?? ""}
            />
            <ProductDetailItem
              label={t("product.tags")}
              value={adminEVoucher.eVoucher.eVoucher?.tags?.join(", ") ?? ""}
            />
          </div>

          <div className="flex">
            <ProductDetailItem
              label={t("product.price-header")}
              value={t("common.price", {
                value: priceInTransform(
                  adminEVoucher.eVoucher?.eVoucher?.price,
                ),
              })}
            />
            <ProductDetailItem
              label={t("e-voucher.usage-condition")}
              value={
                adminEVoucher.eVoucher.eVoucher?.includeUsageDetailInEmail
                  ? t("e-voucher.include-usage-detail-with-email")
                  : t("e-voucher.not-include-usage-detail-with-email")
              }
            />
          </div>

          <div className="flex">
            <ProductDetailItem
              label={t("product.detail")}
              value={
                <MpTextEditor
                  isEditable={false}
                  initEditorState={parseEditorState(
                    adminEVoucher?.eVoucher?.eVoucher?.productDetail ??
                      stringifiedEmptyEditorState,
                  )}
                />
              }
            />
          </div>
        </div>
      </div>

      {adminEVoucher?.eVoucher?.eVoucher?.usageDetail && (
        <div className="w-full pt-6">
          <div className="mb-2 text-xl font-semibold">
            {t("e-voucher.usage-detail")}
          </div>
          <div>
            <MpTextEditor
              isEditable={false}
              initEditorState={parseEditorState(
                adminEVoucher?.eVoucher?.eVoucher?.usageDetail ??
                  stringifiedEmptyEditorState,
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const ProductDetailItem: React.FC<{
  label: string
  value: React.ReactElement | string
}> = (props) => {
  return (
    <div className="w-1/2 grow">
      <div className="font-semibold">{props.label}</div>
      <div className="mt-2 text-gray-600">{props.value}</div>
    </div>
  )
}
