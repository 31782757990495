import {
  EuiCheckbox,
  EuiFieldText,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiRadioGroup,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import React from "react"
import { useTranslation } from "react-i18next"
import { LayoutStyleRadio } from "../components/style-radio"
import { UilInfoCircle, UilLayerGroup } from "@iconscout/react-unicons"
import {
  RecommendedProductDetailConfigProps,
  useUpdatePopularProducts,
  useUpdateRecommendedProducts,
} from "./hook/update-detail"
import { ProductAutoForm, ProductManualForm } from "./product-form"
import { useAdditionalSection, useHomeManagementStore } from "../store/ctx"
import { useFormSubmit, useInitializeForm } from "../hook/form"
import { publicConfig } from "@app/config"

const maxTitleNameLength = 50

export const PopularProductSection = () => {
  const { t } = useTranslation()
  const { selectedId } = useHomeManagementStore()

  const { sections } = useAdditionalSection()
  const form = useUpdatePopularProducts(selectedId!)

  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)

  return (
    <div className="flex flex-col gap-y-6">
      {isSubmitting && (
        <div className="absolute right-2 top-0">
          <EuiLoadingSpinner size="m" />
        </div>
      )}
      <div className="flex flex-col gap-y-2">
        <EuiFormRow
          label={t("home-management.product.name")}
          helpText={t("home-management.max-length-help-text", {
            current: form.values.title?.length || 0,
            max: maxTitleNameLength,
          })}
        >
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={form.values.title}
            maxLength={maxTitleNameLength}
            isInvalid={!!form.errors.title || form.values.title?.length === 0}
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </EuiFormRow>
        <EuiFormRow className="mt-0">
          <EuiCheckbox
            id="showTitle"
            label={t("home-management.product.show-title")}
            checked={form.values.shouldShowTitle}
            onChange={(e) => {
              form.setFieldValue("shouldShowTitle", e.target.checked)
            }}
          />
        </EuiFormRow>
      </div>

      <EuiFormRow label={t("home-management.product.display-mode")}>
        <EuiRadioGroup
          options={[
            {
              id: "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_BEST_SELLING_ALL_TIME",
              label: t("home-management.product.popular.auto"),
            },
            {
              id: "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED",
              label: t("home-management.product.popular.manual"),
            },
          ]}
          idSelected={
            form.values.displayOption ===
            "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED"
              ? "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED"
              : "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_BEST_SELLING_ALL_TIME"
          }
          onChange={(id) => {
            form.setFieldValue("displayOption", id)
            if (
              id !== "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED"
            ) {
              if (
                publicConfig.enableNovelProductTypeFlexibleHomepageManagement
              ) {
                form.setFieldValue("displayProductType", "PRODUCT_TYPE_NOVEL")
              } else if (
                publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement
              ) {
                form.setFieldValue(
                  "displayProductType",
                  "PRODUCT_TYPE_E_BOOK_AUDIO",
                )
              } else if (
                publicConfig.enableEVoucherProductTypeFlexibleHomepageManagement
              ) {
                form.setFieldValue(
                  "displayProductType",
                  "PRODUCT_TYPE_E_VOUCHER",
                )
              } else {
                form.setFieldValue(
                  "displayProductType",
                  "PRODUCT_TYPE_PHYSICAL",
                )
              }

              form.setFieldValue("shouldShowProductRanking", false)
              form.setFieldValue("adminSelectedProductKeys", [])
            }
          }}
        />
      </EuiFormRow>

      {form.values.displayOption !==
        "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED" && (
        <div
          className="flex w-full items-center gap-x-4 rounded-lg border border-solid !border-table
        !bg-grey-lightest px-4 py-2"
        >
          <UilInfoCircle className="text-ci-green" />
          <EuiText size="xs">
            {t("home-management.product.popular.auto-suggest-hint")}
          </EuiText>
        </div>
      )}

      <LayoutStyleRadio
        availableLayouts={[
          "POPULAR_PRODUCT_LAYOUT_OVERFLOW",
          "POPULAR_PRODUCT_LAYOUT_FIXED",
        ]}
        layout={form.values.layout!}
        onChange={(id) => {
          form.setFieldValue("layout", id)
        }}
      />

      {form.values.displayOption !==
      "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED" ? (
        <ProductAutoForm form={form} type="POPULAR_PRODUCT" />
      ) : (
        <ProductManualForm
          form={form}
          selectedSection={selectedSection}
          isSubmitting={isSubmitting}
        />
      )}
      <div>
        <div className="mt-4 flex flex-col gap-2">
          <EuiText size="xs">
            <h3>{t("home-management.main-menu.noted")}</h3>
          </EuiText>
          <EuiText size="xs">
            <p className="text-eui-subduedText">
              {t("home-management.main-menu.noted-hint")}
            </p>
          </EuiText>
        </div>

        <EuiTextArea
          className="mt-2"
          fullWidth
          value={form.values.description}
          onChange={(e) => form.setFieldValue("description", e.target.value)}
        />
      </div>
    </div>
  )
}

export const RecommmendedProductSection = () => {
  const { t } = useTranslation()
  const { selectedId } = useHomeManagementStore()
  const { sections } = useAdditionalSection()
  const form = useUpdateRecommendedProducts(selectedId!)

  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)

  return (
    <div className="flex flex-col gap-y-6 ">
      {isSubmitting && (
        <div className="absolute right-2 top-0">
          <EuiLoadingSpinner size="m" />
        </div>
      )}
      <div className="flex flex-col gap-y-2">
        <EuiFormRow
          label={t("home-management.product.name")}
          helpText={t("home-management.max-length-help-text", {
            current: form.values.title?.length || 0,
            max: maxTitleNameLength,
          })}
        >
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={form.values.title || ""}
            maxLength={maxTitleNameLength}
            isInvalid={!!form.errors.title || form.values.title?.length === 0}
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </EuiFormRow>
        <EuiFormRow className="mt-0">
          <EuiCheckbox
            id="showTitle"
            label={t("home-management.product.show-title")}
            checked={form.values.shouldShowTitle || false}
            onChange={(e) => {
              form.setFieldValue("shouldShowTitle", e.target.checked)
            }}
          />
        </EuiFormRow>
      </div>

      <EuiFormRow label={t("home-management.product.display-mode")}>
        <EuiRadioGroup
          options={[
            {
              id: "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_NEWEST",
              label: t("home-management.product.recommended.auto"),
            },
            {
              id: "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED",
              label: t("home-management.product.recommended.manual"),
            },
          ]}
          idSelected={
            form.values.displayOption ||
            "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_NEWEST"
          }
          onChange={(id) => {
            form.setFieldValue("displayOption", id)

            if (
              id !== "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED"
            ) {
              form.setFieldValue(
                "displayProductType",
                "DISPLAY_RECOMMENDED_PRODUCT_TYPE_ALL",
              )
            }
            form.setFieldValue("adminSelectedProductKeys", [])
          }}
        />
      </EuiFormRow>

      {form.values.displayOption ===
        "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_NEWEST" && (
        <div
          className="flex w-full items-center gap-x-4 rounded-lg border border-solid !border-table
        !bg-grey-lightest px-4 py-2
        "
        >
          <UilLayerGroup className="text-ci-yellow" />
          <EuiText size="xs">
            {t("home-management.product.recommended.auto-suggest-hint")}
          </EuiText>
        </div>
      )}

      <LayoutStyleRadio
        availableLayouts={[
          "RECOMMENDED_PRODUCT_LAYOUT_OVERFLOW",
          "RECOMMENDED_PRODUCT_LAYOUT_FIXED",
        ]}
        layout={form.values.layout!}
        onChange={(id) => {
          form.setFieldValue("layout", id)
        }}
      />

      {form.values.displayOption !==
      "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED" ? (
        <ProductAutoForm
          form={form as RecommendedProductDetailConfigProps}
          type="RECOMMENDED_PRODUCT"
        />
      ) : (
        <ProductManualForm
          form={form as RecommendedProductDetailConfigProps}
          selectedSection={selectedSection}
          isSubmitting={isSubmitting}
        />
      )}
      <div>
        <div className="mt-4 flex flex-col gap-2">
          <EuiText size="xs">
            <h3>{t("home-management.main-menu.noted")}</h3>
          </EuiText>
          <EuiText size="xs">
            <p className="text-eui-subduedText">
              {t("home-management.main-menu.noted-hint")}
            </p>
          </EuiText>
        </div>

        <EuiTextArea
          className="mt-2"
          fullWidth
          value={form.values.description}
          onChange={(e) => form.setFieldValue("description", e.target.value)}
        />
      </div>
    </div>
  )
}
