import { EditModal } from "@app/component"
import { UploadItemSection } from "@app/component/upload-and-render"
import { publicConfig } from "@app/config"
import { useStore } from "@app/hook/store"
import { sleep } from "@app/lib/time"
import {
  ProductTypeInput,
  extendProductsToProductsTable,
  mappingProductTypeInputToShopProductType,
} from "@app/model/product-table"
import {
  EuiButton,
  EuiFieldText,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiText,
} from "@elastic/eui"
import { appErrorToDefaultI18nKey, errToAppErr } from "@reeeed-mp/ui-common"
import { concat, uniqBy } from "lodash-es"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { read as readXlsx, utils as utilsXlsx } from "xlsx"
import { useGetHighlightProductApi } from "../home-management/hook/homepage"
import { useAddPrivileges, useGetManyUser } from "./hook/privilege"

const paginationSize = 20

type PrivilegeTable = {
  userId: string
  username: string
  productId: string
  productType: ProductTypeInput
  expiredAt: Date
}

export const PrivilegeEditor: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { notificationStore: notification } = useStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fileNameUpload, setFileNameUpload] = useState<string>("privilege.xlsx")
  const [allPrivilegesInTable, setAllPrivilegesInTable] = useState<
    PrivilegeTable[]
  >([])
  const [privilegeForRender, setPrivilegeForRender] =
    useState<PrivilegeTable[]>()
  const [errorProductIds, setErrorProductIds] = useState<string[]>([])
  const [errorUserIds, setErrorUserIds] = useState<string[]>([])
  const [pagePrivilegeIndex, setPagePrivilegeIndex] = useState<number>(0)
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false)
  const [passwordFeatureAccess, setPasswordFeatureAccess] = useState<string>("")
  const { get: getHighlightProduct } = useGetHighlightProductApi()
  const addPrivileges = useAddPrivileges()
  const getManyUser = useGetManyUser()

  const onSubmit = async () => {
    setIsLoading(true)
    let isSuccess = false

    try {
      const privileges = allPrivilegesInTable.map((p) => {
        return {
          userId: p.userId,
          productId: p.productId,
          productType: mappingProductTypeInputToShopProductType(p.productType),
          expiresAt: new Date(p.expiredAt).toISOString(),
        }
      })

      const chuckSize = 1000
      let i = 0
      while (i < privileges?.length) {
        const slicePrivileges = privileges.slice(i, i + chuckSize)
        await addPrivileges(slicePrivileges, passwordFeatureAccess)
        i += chuckSize
        await sleep(100)
      }

      isSuccess = true
    } catch (err) {
      const e = errToAppErr(err)
      const i18nKey = appErrorToDefaultI18nKey(e)
      notification.add({
        color: "danger",
        title: t("common.noti.error"),
        text: t(i18nKey),
      })
    } finally {
      setIsOpenModalConfirm(false)
      setIsLoading(false)
    }

    if (isSuccess) {
      notification.add({
        title: "Success",
        color: "success",
      })
      navigate("/privilege")
    }
  }

  return (
    <div className="w-full">
      <div className="flex items-center">
        <EuiIcon
          type="arrowLeft"
          size="l"
          color="text"
          onClick={() => navigate("/privilege")}
          className="cursor-pointer"
        />
        <div className="ml-2">
          <EuiText>
            <h2 className="font-bold">
              {t("privilege.editor.create-privilege")}
            </h2>
          </EuiText>
        </div>
      </div>

      <div className="mt-4">
        <UploadItemSection
          loading={isLoading}
          errorIds={concat(errorProductIds, errorUserIds)}
          fileNameUpload={fileNameUpload}
          handleUpload={async (files: FileList | null) => {
            setIsLoading(true)
            if (files && files[0]) {
              setFileNameUpload(files[0].name)
              const result = await files[0].arrayBuffer()
              const workbook = readXlsx(result)
              const wsname = workbook.SheetNames[0]
              const ws = workbook.Sheets[wsname]
              const xlsxInput: Array<{
                UserId: string
                ProductId: string
                ProductType: ProductTypeInput
                ExpiredAt: Date
              }> = utilsXlsx.sheet_to_json(ws, { raw: false })

              const userForFindIds = uniqBy(xlsxInput, "UserId").map(
                (a) => a.UserId,
              )
              const getUsers = await getManyUser(userForFindIds)
              const errUserIds: string[] = []
              for (const userId of userForFindIds) {
                const find = getUsers?.find((u) => u.id === userId)
                if (!find) {
                  errUserIds.push(`userId: ${userId}`)
                }
              }
              setErrorUserIds(errUserIds)

              const allPrivilegesCanUse: PrivilegeTable[] = []
              const errProductIds: string[] = []
              let i = 0
              const chuckSize = 1000
              while (i < xlsxInput.length) {
                const inputs = xlsxInput.slice(i, i + chuckSize)
                const productKeysForFind = []
                for (const input of inputs) {
                  productKeysForFind.push({
                    id: input.ProductId,
                    type: mappingProductTypeInputToShopProductType(
                      input.ProductType,
                    ),
                  })
                }

                const highlights = await getHighlightProduct(productKeysForFind)
                const mapping = extendProductsToProductsTable(
                  highlights?.products || [],
                  productKeysForFind,
                )

                // eslint-disable-next-line no-loop-func
                mapping.extendsProducts.forEach((p, idx) => {
                  if (p.id === "") {
                    errProductIds.push(
                      `productId: ${xlsxInput[idx + i].ProductId}`,
                    )
                    return
                  }

                  if (
                    p.type &&
                    p.type.length > 0 &&
                    p.type[0] === "PRODUCT_TYPE_E_BOOK_AUDIO"
                  ) {
                    const checkAudio = highlights?.products?.find(
                      (cP, idxCp) => {
                        return (
                          idx === idxCp &&
                          cP.product?.eBookAudio?.audio !== null
                        )
                      },
                    )
                    if (checkAudio) {
                      errProductIds.push(`productId: ${p.id}`)
                      return
                    }
                  }

                  const findProductInput = xlsxInput.find((pInput, idx2) => {
                    return pInput.ProductId === p.id && idx + i === idx2
                  })
                  if (findProductInput) {
                    const findUser = getUsers?.find(
                      (u) => u.id === findProductInput.UserId,
                    )
                    if (findUser) {
                      allPrivilegesCanUse.push({
                        userId: findUser.id,
                        username: findUser.username,
                        productId: p.id,
                        productType: findProductInput.ProductType,
                        expiredAt: findProductInput.ExpiredAt,
                      } as PrivilegeTable)
                    }
                  }
                })

                i += chuckSize
                await sleep(100)
              }

              setAllPrivilegesInTable(allPrivilegesCanUse)
              setTotalItemCount(allPrivilegesCanUse.length)
              setPrivilegeForRender(
                allPrivilegesCanUse.slice(0, paginationSize),
              )
              setErrorProductIds(errProductIds)
              setIsLoading(false)
            }
          }}
          items={privilegeForRender}
          tableColumns={[
            {
              field: "userId",
              name: (
                <EuiText className="font-bold" size="xs">
                  {t("privilege.editor.user-id")}
                </EuiText>
              ),
            },
            {
              field: "username",
              name: (
                <EuiText className="font-bold" size="xs">
                  {t("privilege.editor.username")}
                </EuiText>
              ),
            },
            {
              field: "productId",
              name: (
                <EuiText className="font-bold" size="xs">
                  {t("privilege.editor.product-id")}
                </EuiText>
              ),
            },
            {
              field: "productType",
              name: (
                <EuiText className="font-bold" size="xs">
                  {t("privilege.editor.product-type")}
                </EuiText>
              ),
            },
            {
              field: "expiredAt",
              name: (
                <EuiText className="font-bold" size="xs">
                  {t("privilege.editor.expired-at")}
                </EuiText>
              ),
            },
          ]}
          pagination={{
            pageIndex: pagePrivilegeIndex,
            pageSize: paginationSize,
            totalItemCount,
            showPerPageOptions: false,
          }}
          onChangePagination={async (p: {
            page: {
              index: number
              size: number
            }
          }) => {
            setPagePrivilegeIndex(p.page.index)
            setPrivilegeForRender(
              allPrivilegesInTable.slice(p.page.index, paginationSize),
            )
          }}
          exampleTemplateStaticPath={
            `${publicConfig.publicUrl  }/excel-template-upload/privilege.xlsx`
          }
        />
      </div>

      <EuiFormRow
        fullWidth
        isInvalid={false}
        className="my-8"
        label={t("common.password-feature-access")}
      >
        <EuiFieldText
          type="password"
          placeholder={t("common.password-feature-access")}
          value={passwordFeatureAccess}
          onChange={(e) => {
            setPasswordFeatureAccess(e.target.value)
          }}
        />
      </EuiFormRow>

      <div className="my-4">
        <EuiHorizontalRule />
      </div>

      {isOpenModalConfirm ? (
        <EditModal
          title={t("privilege.editor.alert-confirm-add-privilege", {
            count: totalItemCount,
          })}
          onSubmit={onSubmit}
          onClose={() => {
            setIsOpenModalConfirm(false)
          }}
          loading={isLoading}
        >
          <div></div>
        </EditModal>
      ) : null}

      <div className={"mt-6 w-48"}>
        <EuiButton
          color="primary"
          fill
          fullWidth
          onClick={() => {
            setIsOpenModalConfirm(true)
          }}
          disabled={allPrivilegesInTable.length === 0}
          isLoading={isLoading}
        >
          {t("common.save")}
        </EuiButton>
      </div>
    </div>
  )
}
