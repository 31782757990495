import { Fetcher, Middleware } from "openapi-typescript-fetch"
import { OpenapiPaths } from "openapi-typescript-fetch/dist/cjs/types"

export const newAuthMiddleware = (
  tokenGetter: () => string | undefined,
): Middleware => {
  return (url, init, next) => {
    const token = tokenGetter()
    if (token) {
      init.headers.set("Authorization", token)
    }
    return next(url, init)
  }
}

export const newFetcher = <Paths extends OpenapiPaths<Paths>>(
  endpoint: string,
  middlewares?: Middleware[],
) => {
  const fetcher = Fetcher.for<Paths>()
  fetcher.configure({
    baseUrl: endpoint,
    use: middlewares,
  })
  return fetcher
}

export const newStreamFetcher = <Paths extends OpenapiPaths<Paths>>(
  endpoint: string,
  tokenGetter: () => Promise<string | null>,
) => {
  return <P extends keyof Paths>(apiPath: P) => {
    return {
      method<M extends keyof Paths[P]>(apiMethod: M) {
        return {
          create<OP extends Paths[P][M]>() {
            return async (
              params: OP extends {
                parameters?: { body?: { body?: infer BodyParams } }
              }
                ? BodyParams
                : OP extends {
                      parameters?: { query?: infer QueryParams }
                    }
                  ? QueryParams
                  : never,
            ) => {
              const token = await tokenGetter()
              const headers = {
                "Content-Type": "application/json",
                ...(token
                  ? {
                      Authorization: token,
                    }
                  : {}),
              }

              let queryStr = ""
              let body = undefined
              if (apiMethod === "get") {
                queryStr = getQueryString(params as Record<string, unknown>)
              } else {
                body = JSON.stringify(params)
              }

              return fetch(`${endpoint}${apiPath as string}${queryStr}`, {
                method: apiMethod as string,
                headers,
                body,
              }).then((resp) => {
                let cancelled = false
                return new ReadableStream<
                  OP extends {
                    responses: { 200: { schema: infer ResponseSchema } }
                  }
                    ? ResponseSchema
                    : unknown
                >({
                  async start(controller) {
                    if (!resp.body) {
                      controller.close()
                    } else {
                      try {
                        let buffer = ""
                        const decoder = new TextDecoder("utf-8")
                        const reader = resp.body.getReader()

                        const process = () => {
                          while (buffer.includes("\n")) {
                            const newlineIndex = buffer.indexOf("\n")
                            const json = buffer.substring(0, newlineIndex)
                            buffer = buffer.substring(newlineIndex + 1)
                            const jsonObject = JSON.parse(json)
                            controller.enqueue(jsonObject)
                          }
                        }

                        /* eslint-disable no-constant-condition */
                        while (1) {
                          const result = await reader.read()
                          if (result.done) {
                            break
                          }
                          if (cancelled) {
                            await reader.cancel()
                            break
                          }
                          buffer += decoder.decode(result.value, {
                            stream: !result.done,
                          })
                          process()
                        }
                        controller.close()
                      } catch (err) {
                        controller.error(err)
                      }
                    }
                  },
                  cancel() {
                    cancelled = true
                  },
                })
              })
            }
          },
        }
      },
    }
  }
}
const getQueryString = (params: Record<string, unknown>): string => {
  const qStr: string[] = []
  const encode = (key: string, value: unknown) =>
    `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`

  Object.keys(params).forEach((key) => {
    const val = params[key]
    if (val != null) {
      if (Array.isArray(val)) {
        val.forEach((val) => qStr.push(encode(key, val)))
      } else {
        qStr.push(encode(key, val))
      }
    }
  })

  if (qStr.length > 0) {
    return `?${qStr.join("&")}`
  }

  return ""
}
