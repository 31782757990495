export interface Item {
  id: string
  order: number
}

export type ItemGroup<T extends Item> = {
  isOpen: boolean
  items: Array<T>
}
export type ItemGroups<T extends Item> = Array<ItemGroup<T>>

export const groupIdxToPagination = (
  groupIdx: number,
  itemPerGroup: number,
) => {
  return { skip: groupIdx * itemPerGroup, limit: itemPerGroup }
}

export const paginationToGroupIdx = (skip: number, limit: number) =>
  skip / limit
