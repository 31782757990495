import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { genNewId } from "@app/model"
import {
  SecondaryBannerForm,
  SecondaryBannerFormValidator,
  SecondaryBannerInput,
} from "@app/model/homepage/secondary-banner"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputImagePicker, useFormHelper } from "@reeeed-mp/ui-common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { upsertById } from "../helper"
import { useHomeManagementStore } from "../store/ctx"

export const EditorSubBanner: React.FC = () => {
  const { t } = useTranslation()
  const { t: bannerT } = useTranslation("translation", {
    keyPrefix: "homepage-management.banner",
  })
  const navigate = useNavigate()
  const { uploadFile } = useUploadFile()
  const [loading, setLoading] = useState(false)
  const { notificationStore } = useStore()
  const params = useParams()
  const homeManagementStore = useHomeManagementStore()

  const onSubmit = async (value: SecondaryBannerForm) => {
    const newSecondaryBanner = upsertById(
      {
        link: value.link,
        id: value.id ? value.id : genNewId(),
        cover: value.fileInput?.tmpPath || undefined,
        alt: value.alt,
        downloadUrl: value.downloadUrl,
      },
      homeManagementStore.homepage?.secondaryBannerSection,
    )
    homeManagementStore.update("secondaryBannerSection", newSecondaryBanner)

    return {
      id: "",
    }
  }

  const subBanner: SecondaryBannerInput | undefined =
    homeManagementStore.homepage?.secondaryBannerSection?.find(
      (s) => s.id === params.id,
    )

  const initialValues = {
    id: subBanner?.id,
    link: subBanner?.link,
    cover: subBanner?.cover,
    alt: subBanner?.alt,
    fileInput: undefined,
    downloadUrl: subBanner?.downloadUrl ?? subBanner?.cover,
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<SecondaryBannerForm, { id: string | undefined }>({
    id: params.id && params.id !== "create" ? params.id : undefined,
    initialValues,
    onGetItem: async (id) => {
      const subBanner: SecondaryBannerInput | undefined =
        homeManagementStore.homepage?.secondaryBannerSection?.find(
          (s) => s.id === id,
        )
      return {
        id: subBanner?.id,
        link: subBanner?.link,
        cover: subBanner?.cover,
        alt: subBanner?.alt,
        fileInput: undefined,
        downloadUrl: subBanner?.downloadUrl ?? subBanner?.cover,
      }
    },
    onError: () => {},
    onSubmit,
    onSubmitted: () => {
      navigate("/home-management/sub-banner")
    },
    validationSchema: SecondaryBannerFormValidator,
  })

  return (
    <div className="p-2">
      <div className="mb-10">
        <NavigationHeaderButton
          title={form.id ? bannerT("edit-banner") : bannerT("add-banner")}
          onClick={() => {
            navigate("/home-management/sub-banner")
          }}
        />
      </div>
      {
        <>
          <FormInputImagePicker
            isInvalid={!!errors.cover}
            disabled={loading}
            css={css`
              width: 740px !important;
              height: 355px !important;
              .euiFilePicker__promptText {
                width: 740px;
              }
              .euiImage {
                width: 740px !important;
                height: 355px !important;
                border-radius: 6px !important;
              }
              .euiImage__img {
                border-radius: 6px !important;
              }
            `}
            placeholder={
              <div className="flex flex-col text-center  ">
                <div>
                  <p className="mb-0">{bannerT("banner-image")}</p>
                </div>
                <div>
                  <p className="mb-0 whitespace-pre-wrap text-center text-sm">
                    {bannerT("cover-suggestion", {
                      size: "580x277 px",
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            accept={`${contentType.JPG}, ${contentType.PNG}`}
            value={form?.downloadUrl || undefined}
            onChange={async (v: File | null) => {
              if (v) {
                try {
                  setLoading(true)
                  const { input, blob } = await uploadFile(v)
                  setValue("downloadUrl", blob)
                  setValue("fileInput", input)
                } catch (err: any) {
                  if (err && err.message) {
                    notificationStore.add({
                      title: "Error",
                      color: "danger",
                      text: err.message,
                    })
                  }
                } finally {
                  setLoading(false)
                }
              }
            }}
          />
          <EuiFormRow
            isInvalid={!!errors.alt}
            className="mt-6"
            label={t("common.alt")}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              isInvalid={!!errors.alt}
              value={form?.alt}
              onChange={(e) => {
                setValue("alt", e.target.value)
              }}
            />
          </EuiFormRow>
          <EuiFormRow
            isInvalid={!!errors.link}
            className="mt-6"
            label={t("common.link")}
          >
            <EuiFieldText
              placeholder={t("common.please-fill")}
              isInvalid={!!errors.link}
              value={form?.link}
              onChange={(e) => {
                setValue("link", e.target.value)
              }}
            />
          </EuiFormRow>
          <div className="mt-10 flex flex-row">
            <EuiButton
              fill
              css={css`
                width: 255px;
                height: 40px;
              `}
              disabled={loading}
              onClick={() => {
                submit()
              }}
            >
              {t("common.save")}
            </EuiButton>
            <EuiButtonEmpty
              css={css`
                width: 255px;
                height: 40px;
              `}
              className="ml-4 items-center"
              color="text"
              onClick={() => {
                navigate("/home-management/sub-banner")
              }}
            >
              {t("common.cancel")}
            </EuiButtonEmpty>
          </div>
        </>
      }
    </div>
  )
}
