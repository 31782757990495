import {
  ConfirmDeleteModal,
  NavigationHeaderButton,
  TextEditor,
} from "@app/component"
import { publicConfig } from "@app/config"
import { useUnMount } from "@app/hook/life-cycle"
import {
  useDeleteStaticPage,
  useGetStaticPage,
  useUpdateContentReservedStaticPage,
  useUpsertStaticPage,
} from "@app/hook/static-page"
import { useStore } from "@app/hook/store"
import { aggInvalidArgErr } from "@app/utils/error"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_static_page_service"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSwitch,
} from "@elastic/eui"
import { UilCopy, UilTrash } from "@iconscout/react-unicons"
import {
  EditorState,
  ToolbarPlugins,
  isEditorStateReadyToExport,
  parseEditorState,
  stringifiedEmptyEditorState,
  stringifyEditorStateOrThrow,
  whyEditorStateIsNotReadyToExport,
} from "@reeeed-mp/text-editor"
import "@reeeed-mp/text-editor/dist/styles.css"
import {
  appErrorToDefaultI18nKey,
  errToAppErr,
  hooks,
  useFormHelper,
} from "@reeeed-mp/ui-common"
import _ from "lodash-es"
import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

const { useCallAsync } = hooks

const frontEndStaticUri = `${publicConfig.webBaseUri}${publicConfig.webStaticRoute}`
const frontEndMaxWidth = 1240
const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/

type Form = {
  id: string
  name: string
  slug: string
  publish: boolean
  content: EditorState | null
  group: StaticPageGroup
}

type StaticPage = definitions["webutility.admin.v1.StaticPage"]
type StaticPageGroup = definitions["webutility.admin.v1.StaticPageGroup"]

const inTransForm = (d: StaticPage): Form => {
  return {
    id: d.id!,
    name: d.linkName!,
    slug: d.slug!,
    publish: d.publishStatus === "STATIC_PAGE_PUBLISH_STATUS_SHOW",
    content: parseEditorState(
      d.content ? d.content : stringifiedEmptyEditorState,
    ),
    group: d.group!,
  }
}

const outTransForm = (d: Form): StaticPage => {
  return {
    id: d.id,
    slug: d.slug,
    linkName: d.name,
    content: d.content
      ? stringifyEditorStateOrThrow(d.content)
      : stringifiedEmptyEditorState,
    publishStatus: d.publish
      ? "STATIC_PAGE_PUBLISH_STATUS_SHOW"
      : "STATIC_PAGE_PUBLISH_STATUS_HIDE",
    group: d.group,
  }
}

export const StaticPageEditor: React.FC = () => {
  const { t } = useTranslation()
  const { t: errT } = useTranslation("translation", {
    keyPrefix: "static-page.editor.errors",
  })
  const params = useParams()
  const id = params.id === "create" ? "" : (params.id as string)
  const navigate = useNavigate()
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const { notificationStore } = useStore()
  const getStaticPage = useGetStaticPage()
  const {
    fn: updateContentReservedStaticPage,
    loading: updatingContentReservedStaticPage,
  } = useCallAsync(useUpdateContentReservedStaticPage())
  const { fn: upsertStaticPage, loading: upsertingStaticPage } = useCallAsync(
    useUpsertStaticPage(),
  )
  const { fn: deleteStaticPage, loading: deletingStaticPage } = useCallAsync(
    useDeleteStaticPage(),
  )
  const { isDirty, setIsDirty } = hooks.useConfirmLeaveWithoutSaved(
    t("common.confirm-not-save-data"),
  )
  useUnMount(async () => {
    await setIsDirty(false)
  })

  const initialValues: Form = {
    id,
    name: "",
    slug: "",
    publish: true,
    content: id ? null : parseEditorState(stringifiedEmptyEditorState),
    group: "STATIC_PAGE_GROUP_CUSTOM",
  }

  const {
    getFieldProps,
    submitForm,
    values,
    setFieldValue,
    setValues,
    setErrors,
    errors,
    values: form,
  } = useFormHelper<Form, StaticPage>({
    id,
    initialValues,
    onGetItem: async () => {
      const res = await getStaticPage(id)
      return inTransForm(res!)
    },
    onSubmit: async (form) => {
      const staticPage = outTransForm(form)
      let res: StaticPage | undefined
      if (form.group === "STATIC_PAGE_GROUP_CUSTOM") {
        res = await upsertStaticPage(staticPage)
      } else {
        res = await updateContentReservedStaticPage(
          staticPage.id!,
          staticPage.content!,
        )
      }

      return res!
    },
    onSubmitted: async (res) => {
      notificationStore.add({
        title: "Success",
        color: "success",
        text: t("common.noti.saved"),
      })
      setValues(inTransForm(res))

      await setIsDirty(false)
      navigate(`/static-page/editor/${res.id}`, { replace: true })
    },
    onError: (err) => {
      const e = errToAppErr(err)
      if (
        e["@type"] ===
        "type.googleapis.com/foundation.error.InvalidArgumentsError"
      ) {
        const fieldErrs = aggInvalidArgErr(e.details)
        setErrors({ name: fieldErrs["linkName"], slug: fieldErrs["slug"] })
        return
      }
      const i18nKey = appErrorToDefaultI18nKey(e)
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t(i18nKey),
      })
    },
    validate: (v: Form) => {
      if (
        !_.isEmpty(v) &&
        !isDirty &&
        (id ? !_.isEqual(initialValues, form) : !_.isEqual(initialValues, v))
      ) {
        setIsDirty(true)
      }

      if (!slugRegex.test(v.slug)) {
        return { slug: "invalid" }
      }
    },
    beforeSubmit: (v: Form) => {
      if (v.content && !isEditorStateReadyToExport(v.content)) {
        const reasons = whyEditorStateIsNotReadyToExport(v.content)
        for (const reason of reasons) {
          notificationStore.add({
            color: "warning",
            text: t(`editor.warning.${reason}`),
          })
        }

        return false
      }

      return true
    },
    validateOnChange: true,
  })
  const canEditOnlyContent = form.group !== "STATIC_PAGE_GROUP_CUSTOM"
  const isAboutUs = form.group === "STATIC_PAGE_GROUP_ABOUT_US"

  return (
    <>
      <div className="mb-8">
        <NavigationHeaderButton
          title={
            canEditOnlyContent
              ? isAboutUs
                ? t("static-page.editor.title-about-us")
                : form.name
              : t("static-page.editor.title")
          }
          onClick={() => navigate(-1)}
        />
      </div>
      <EuiForm>
        {!canEditOnlyContent && (
          <EuiFormRow
            label={t("static-page.editor.fields.name")}
            error={
              errors.name &&
              errT(`name.${errors.name}`)
            }
            isInvalid={!!errors.name}
          >
            <EuiFieldText
              isInvalid={!!errors.name}
              {...getFieldProps("name")}
            />
          </EuiFormRow>
        )}
        <EuiFormRow
          label={t("static-page.editor.fields.slug")}
          error={
            errors.slug &&
            errT(`slug.${errors.slug}`)
          }
          isInvalid={!!errors.slug}
        >
          <EuiFieldText
            isInvalid={!!errors.slug}
            disabled={canEditOnlyContent}
            {...getFieldProps("slug")}
            prepend={
              isAboutUs
                ? `${publicConfig.webBaseUri}/`
                : `${frontEndStaticUri}/`
            }
            append={
              <div className="cursor-pointer">
                <CopyToClipboard
                  text={
                    isAboutUs
                      ? `${publicConfig.webBaseUri}/${form.slug}`
                      : `${frontEndStaticUri}/${form.slug}`
                  }
                  onCopy={() =>
                    notificationStore.add({
                      color: "success",
                      text: t("common.noti.copied-to-clip-board"),
                    })
                  }
                >
                  <EuiButtonEmpty size="xs">
                    <UilCopy />
                  </EuiButtonEmpty>
                </CopyToClipboard>
              </div>
            }
          />
        </EuiFormRow>

        <EuiFormRow
          label={t("static-page.editor.fields.content")}
          fullWidth
          css={{ maxWidth: frontEndMaxWidth }}
        >
          <TextEditor
            value={form.content}
            onChange={(state) => setFieldValue("content", state)}
            plugins={[
              ToolbarPlugins.UndoRedo,
              ToolbarPlugins.BlockType,
              ToolbarPlugins.RichText,
              ToolbarPlugins.Alignment,
              ToolbarPlugins.Link,
              ToolbarPlugins.ImageUrl,
              ToolbarPlugins.ImageUpload,
              ToolbarPlugins.Youtube,
            ]}
          />
        </EuiFormRow>

        {!canEditOnlyContent && (
          <EuiFormRow label={t("static-page.editor.fields.publish")}>
            <EuiSwitch
              label={t(
                `static-page.editor.values.publish.${
                  values.publish ? "show" : "hide"
                }`,
              )}
              aria-describedby={id}
              checked={values.publish}
              onChange={(e) => {
                setFieldValue("publish", e.target.checked)
              }}
            />
          </EuiFormRow>
        )}
        <EuiFormRow fullWidth>
          <div className="flex justify-between">
            <EuiButton
              isLoading={
                upsertingStaticPage || updatingContentReservedStaticPage
              }
              onClick={submitForm}
              color="primary"
              fill
              css={{ minWidth: 180 }}
            >
              {t("common.save")}
            </EuiButton>
            {!canEditOnlyContent && (
              <EuiButtonEmpty
                className="ml-4"
                color="danger"
                onClick={() => setOpenConfirmDelete(true)}
              >
                <div className="flex">
                  <div>
                    <UilTrash
                      height="16px"
                      width="16px"
                      className="mr-2"
                      style={{ marginTop: "2px" }}
                    />
                  </div>
                  <div>
                    <span>{t("common.delete")}</span>
                  </div>
                </div>
              </EuiButtonEmpty>
            )}
          </div>
        </EuiFormRow>
      </EuiForm>
      {openConfirmDelete && (
        <ConfirmDeleteModal
          isDeleting={deletingStaticPage}
          description=""
          onClose={() => setOpenConfirmDelete(false)}
          onConfirm={async () => {
            await deleteStaticPage(id)
            setOpenConfirmDelete(false)
            navigate(-1)
          }}
        />
      )}
    </>
  )
}
