import { EuiButtonEmpty } from "@elastic/eui"
import { css } from "@emotion/react"
import { UilSort } from "@iconscout/react-unicons"
import { useTranslation } from "react-i18next"

export const ReorderButton = (props: {
  onPress: () => void
  disabled?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <EuiButtonEmpty
      isDisabled={props.disabled}
      css={css`
        .euiButtonEmpty__text {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .euiButtonEmpty__content {
          padding: 0px !important;
        }
        width: 160px;
      `}
      color="text"
      className="flex flex-row"
      disabled={props.disabled}
      onClick={props.onPress}
    >
      <UilSort className="mr-3" />
      <p className="mb-0">{t("common.reorder")}</p>
    </EuiButtonEmpty>
  )
}
