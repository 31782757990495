import { Title } from "@app/component"
import { useStreamApi } from "@app/hook/api"
import { useDownloadReport } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import {
  EuiButton,
  EuiProgress,
  EuiSelect,
  EuiSelectOption,
} from "@elastic/eui"
import { errToDefaultI18nKey } from "@reeeed-mp/ui-common"
import dayjs from "dayjs"
import { ApiError } from "openapi-typescript-fetch"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const CoinBalanceReportPage: React.FC = () => {
  const { t } = useTranslation()
  const { notificationStore } = useStore()
  const { payment } = useStreamApi()
  const api = payment("/admin/export-coin-balance-report")
    .method("get")
    .create()
  const { download, loadingPercentage, error } = useDownloadReport(api, {
    autoDownload: true,
  })

  useEffect(() => {
    if (error) {
      const key = errToDefaultI18nKey(new ApiError({ data: error } as any))
      notificationStore.add({
        title: t("common.noti.error"),
        color: "danger",
        text: t(key),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationStore, error])

  const now = dayjs()
  const [selectedYear, setSelectedYear] = React.useState<number>(now.year())
  const [selectedMonth, setSelectedMonth] = React.useState<number | undefined>(
    now.month(),
  )
  const reportRetentionInYears = 3

  const yearOptions: EuiSelectOption[] = Array.from(
    { length: reportRetentionInYears + 1 }, // + 1 for index 0
    (_, i) => now.year() - i,
  ).map((y) => {
    return {
      value: y,
      text: y,
    }
  })

  const monthOptions: EuiSelectOption[] = [
    {
      value: 1,
      text: t("common.month.january"),
    },
    {
      value: 2,
      text: t("common.month.february"),
    },
    {
      value: 3,
      text: t("common.month.march"),
    },
    {
      value: 4,
      text: t("common.month.april"),
    },
    {
      value: 5,
      text: t("common.month.may"),
    },
    {
      value: 6,
      text: t("common.month.june"),
    },
    {
      value: 7,
      text: t("common.month.july"),
    },
    {
      value: 8,
      text: t("common.month.august"),
    },
    {
      value: 9,
      text: t("common.month.september"),
    },
    {
      value: 10,
      text: t("common.month.october"),
    },
    {
      value: 11,
      text: t("common.month.november"),
    },
    {
      value: 12,
      text: t("common.month.december"),
    },
  ]

  return (
    <div className="max-w-screen-xl">
      <Title text={t("nav.coin-balance-report")} />
      <div className="flex w-1/2 gap-1">
        <EuiSelect
          options={yearOptions}
          value={selectedYear}
          onChange={(evt) => {
            setSelectedYear(parseInt(evt.target.value, 10))
            setSelectedMonth(undefined)
          }}
        />

        <EuiSelect
          hasNoInitialSelection
          options={monthOptions.map((m: EuiSelectOption) => {
            if (selectedYear === now.year()) {
              if ((m.value as number) > now.month()) {
                m.disabled = true
              }
            }

            if (selectedYear === now.year() - 2) {
              if ((m.value as number) < now.month()) {
                m.disabled = true
              }
            }

            return m
          })}
          value={selectedMonth}
          onChange={(evt) => {
            setSelectedMonth(parseInt(evt.target.value, 10))
          }}
        />
        <EuiButton
          color="primary"
          fill
          disabled={selectedYear === undefined || selectedMonth === undefined}
          className="my-0"
          isLoading={loadingPercentage !== null}
          onClick={() =>
            download({
              "filter.month": selectedMonth,
              "filter.year": selectedYear,
            })
          }
        >
          {t("common.download")}
        </EuiButton>
      </div>
      <div className="mt-4">
        {loadingPercentage ? (
          <EuiProgress
            value={loadingPercentage}
            max={100}
            size="m"
            color="success"
            className="w-1/2"
          />
        ) : null}
      </div>
    </div>
  )
}
