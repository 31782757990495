import { definitions } from "@app/vendor/shop-specs/shop_service"
export const getTranslationFromBankCode = (
  bankCode: definitions["shop.v1.BankCode"],
): string => {
  let bankNameTran = "-"

  if (bankCode === "BANK_CODE_BBL") {
    bankNameTran = "bank.name.bangkok-bank"
  }

  if (bankCode === "BANK_CODE_KBANK") {
    bankNameTran = "bank.name.kasikorn-bank"
  }

  if (bankCode === "BANK_CODE_KTB") {
    bankNameTran = "bank.name.krungthai-bank"
  }

  if (bankCode === "BANK_CODE_TTB") {
    bankNameTran = "bank.name.ttb-bank"
  }

  if (bankCode === "BANK_CODE_SCB") {
    bankNameTran = "bank.name.siam-commercial-bank"
  }

  if (bankCode === "BANK_CODE_BAY") {
    bankNameTran = "bank.name.bank-of-ayudhya"
  }

  if (bankCode === "BANK_CODE_KKP") {
    bankNameTran = "bank.name.kiatnakin-phatra-bank"
  }

  if (bankCode === "BANK_CODE_TISCO") {
    bankNameTran = "bank.name.tisco-bank"
  }

  if (bankCode === "BANK_CODE_UOBT") {
    bankNameTran = "bank.name.united-overseas-bank"
  }

  if (bankCode === "BANK_CODE_TCD") {
    bankNameTran = "bank.name.thai-credit-retail-bank"
  }

  if (bankCode === "BANK_CODE_LHFG") {
    bankNameTran = "bank.name.land-and-house-bank"
  }

  if (bankCode === "BANK_CODE_ICBCT") {
    bankNameTran = "bank.name.ICBC-bank"
  }

  if (bankCode === "BANK_CODE_SME") {
    bankNameTran = "bank.name.sme-bank"
  }

  if (bankCode === "BANK_CODE_BAAC") {
    bankNameTran = "bank.name.baac-bank"
  }

  if (bankCode === "BANK_CODE_EXIM") {
    bankNameTran = "bank.name.baac-bank"
  }

  if (bankCode === "BANK_CODE_GSB") {
    bankNameTran = "bank.name.government-bank"
  }

  if (bankCode === "BANK_CODE_GHB") {
    bankNameTran = "bank.name.gh-bank"
  }

  if (bankCode === "BANK_CODE_OTHER") {
    bankNameTran = "bank.name.other"
  }

  return bankNameTran
}
