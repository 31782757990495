import { useApi, useStreamApi } from "@app/hook/api"
import { definitions } from "@app/vendor/promotion-specs/promotion_service"
import { definitions as shopDefinitions } from "@app/vendor/shop-specs/shop_service"
import { definitions as webBffDefinitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { OnGetItems } from "@reeeed-mp/ui-common"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import useSWR from "swr"

export type ListPlatformCouponPromotionResponse =
  definitions["promotion.admin.v1.ListPlatformCouponResponse"]
export type PlatformCouponCartPromotionSetup =
  definitions["promotion.admin.v1.PlatformCouponCartPromotionSetup"]
export type CreatePlatformCouponResponse =
  definitions["promotion.admin.v1.CreatePlatformCouponResponse"]
export type GetPlatformCouponResponse =
  definitions["promotion.admin.v1.GetPlatformCouponResponse"]
export type ConditionWithProductParticipate =
  definitions["promotion.admin.v1.ConditionWithProductParticipate"]
export type Filter =
  definitions["promotion.admin.v1.ListPlatformCouponRequest.Filter"]
export type GetCategoriesInPromotionResponse =
  definitions["promotion.admin.v1.GetCategoriesInPromotionResponse"]
type PublishStatus = definitions["promotion.v1.PublishStatus"]
type PromotionType =
  definitions["promotion.v1.PlatformCouponPromotionDiscountType"]
export type UserInPromotion =
  definitions["promotion.admin.v1.UserInPromotion"] & {
    username: string
    email: string
  }

export type UpdateProductInPromotionInput = {
  addProducts?: definitions["promotion.admin.v1.ProductCondition"][]
  isDeleteAllProduct?: boolean
  addShopsIds?: string[]
  deleteShopsIds?: string[]
  addCategoryIds?: string[]
  deleteCategoryIds?: string[]
}

export type GetAllShopResponse =
  shopDefinitions["shop.admin.v1.GetAllShopResponse"]

export type Item = {
  id: string
  name: string | undefined
  note: string | undefined
  code: string | undefined
  type: string | undefined
  platformSubsidize: string | undefined
  sellerSubsidize: string | undefined
  startAt: Date
  endAt: Date
  discount: string | undefined
  discountPercentage: string | undefined
  onlyGift: boolean | undefined
  isForSelectedUser: boolean
}

export const useListPromotion = () => {
  const [params] = useSearchParams()
  const { t } = useTranslation()
  const [selectedItems, setSelectedItems] = useState<Item[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<Filter>({
    publishStatus: params.get("publishStatus")
      ? (params.get("publishStatus") as PublishStatus)
      : "PUBLISH_STATUS_ACTIVE",
    textSearch: params.get("textSearch")
      ? (params.get("textSearch") as string)
      : undefined,
    type: params.get("type")
      ? (params.get("type") as PromotionType)
      : undefined,
  })

  const getList = useGetListPromotion()
  const onGetItems: OnGetItems<Item> = async ({ filter, opts }) => {
    setLoading(true)
    const list = await getList({
      filter,
      opts,
    })
    setLoading(false)

    return {
      count: Number(list?.count || 0),
      items:
        list?.platformCouponPromotion?.map(
          (p: definitions["promotion.admin.v1.PlatformCouponPromotion"]) => {
            return {
              id: p.id || "",
              name: p.name,
              note: p.note,
              code: p.code,
              type:
                p.deliveryDiscountMethod !== undefined
                  ? t("promotion.list.delivery-discount")
                  : p.productDiscountMethod?.onlyGift
                    ? t("promotion.list.gift-discount")
                    : t("promotion.list.product-discount"),
              platformSubsidize: t("promotion.list.platform-subsidize", {
                platformSub:
                  p.productDiscountMethod !== undefined
                    ? p.productDiscountMethod?.platformSubsidizePercent
                    : p.deliveryDiscountMethod?.platformSubsidizePercent,
              }),
              sellerSubsidize: t("promotion.list.seller-subsidize", {
                sellerSub:
                  p.productDiscountMethod !== undefined
                    ? 100 -
                      Number(p.productDiscountMethod?.platformSubsidizePercent)
                    : 100 -
                      Number(
                        p.deliveryDiscountMethod?.platformSubsidizePercent,
                      ),
              }),
              startAt: new Date(p.startAt!),
              endAt: new Date(p.endAt!),
              discount:
                p.productDiscountMethod !== undefined
                  ? p.productDiscountMethod?.productDiscountCustom?.discount
                  : p.deliveryDiscountMethod?.discount,
              discountPercentage:
                p.productDiscountMethod?.productDiscountPercentage !== undefined
                  ? p.productDiscountMethod?.productDiscountPercentage
                      ?.discountPercentage
                  : undefined,
                  onlyGift: p.productDiscountMethod?.onlyGift,
              isForSelectedUser: !!p.isForSelectedUsers,
            }
          },
        ) || [],
    }
  }

  return {
    loading,
    setLoading,
    onGetItems,
    selectedItems,
    setSelectedItems,
    filter,
    setFilter,
  }
}

export const usePromotionApi = () => {
  const { promotion } = useApi()
  const { webBFF } = useStreamApi()
  const list = promotion("/list-platform-coupon").method("get").create()
  const create = promotion("/create-platform-coupon").method("post").create()
  const update = promotion("/update-platform-coupon/{id}")
    .method("put")
    .create()
  const endPromotion = promotion("/end-platform-coupon/{id}")
    .method("put")
    .create()
  const deletePromotion = promotion("/delete-platform-coupon/{id}")
    .method("delete")
    .create()
  const get = promotion("/get-platform-coupon/{id}").method("get").create()
  const countCouponUsage = promotion(
    "/count-platform-coupon-usage/{promotionId}",
  )
    .method("get")
    .create()
  const countTopupCouponUsage = promotion(
    "/count-topup-coupon-promotion-usage/{promotionId}",
  )
    .method("get")
    .create()
  const updateProductInPromotion = promotion(
    "/update-product-in-promotion/{promotionId}",
  )
    .method("put")
    .create()
  const getShopsInPromotion = promotion("/get-shops-in-promotion/{promotionId}")
    .method("get")
    .create()
  const getCategoriesInPromotion = promotion(
    "/get-categories-in-promotion/{promotionId}",
  )
    .method("get")
    .create()
  const setUserInPromotion = promotion(
    "/platform-coupon-promotion/{promotionId}/set-user-in-promotion",
  )
    .method("post")
    .create()
  const getAllUserInPromotion = promotion(
    "/platform-coupon-promotion/{promotionId}/get-all-user-in-promotion",
  )
    .method("get")
    .create()
  const downloadUserInPromotionReport = webBFF(
    "/admin/report/platform-coupon-user-in-promotion",
  )
    .method("get")
    .create()

  return {
    list,
    create,
    update,
    endPromotion,
    deletePromotion,
    get,
    countCouponUsage,
    countTopupCouponUsage,
    updateProductInPromotion,
    getShopsInPromotion,
    getCategoriesInPromotion,
    setUserInPromotion,
    getAllUserInPromotion,
    downloadUserInPromotionReport,
  }
}

type ListPromotionInput = {
  filter?: Filter
  opts: definitions["foundation.finder.PaginationOption"]
}

export const useGetListPromotion = () => {
  const { list } = usePromotionApi()
  return useCallback(
    async (
      input: ListPromotionInput,
    ): Promise<ListPlatformCouponPromotionResponse | undefined> => {
      const { data } = await list({
        "filter.textSearch": input.filter?.textSearch,
        "filter.publishStatus": input.filter?.publishStatus,
        "filter.type": input.filter?.type,
        "filter.sorter": "PLATFORM_COUPON_SORTER_UPDATED_AT_DESC",
        "option.limit": input.opts.limit,
        "option.skip": input.opts.skip,
      })
      return data
    },
    [list],
  )
}

export const useEndPromotion = () => {
  const { endPromotion } = usePromotionApi()
  return useCallback(
    async (
      id: string,
    ): Promise<
      definitions["promotion.admin.v1.EndPlatformCouponResponse"] | undefined
    > => {
      const { data } = await endPromotion({
        id,
      })
      return data
    },
    [endPromotion],
  )
}

export const useDeletePromotion = () => {
  const { deletePromotion } = usePromotionApi()
  return useCallback(
    async (
      id: string,
    ): Promise<
      definitions["promotion.admin.v1.DeletePlatformCouponResponse"] | undefined
    > => {
      const { data } = await deletePromotion({
        id,
      })
      return data
    },
    [deletePromotion],
  )
}

export const useGetPromotion = () => {
  const { get } = usePromotionApi()
  return useCallback(
    async (id: string): Promise<GetPlatformCouponResponse | undefined> => {
      const { data } = await get({
        id,
      })
      return data
    },
    [get],
  )
}

export const useGetPromotionSWR = (id: string, disableAutoFetch: boolean) => {
  const { get } = usePromotionApi()
  const { data, error, mutate, isLoading } = useSWR(
    "/get-platform-coupon",
    async (): Promise<GetPlatformCouponResponse> => {
      const res = await get({ id })
      return res.data
    },
    {
      revalidateOnFocus: !disableAutoFetch,
    },
  )

  return {
    data,
    loading: isLoading,
    error,
    mutate,
  }
}

export const useCountCouponUsage = (promotionId: string) => {
  const { countCouponUsage } = usePromotionApi()
  const { data, error, mutate, isLoading } = useSWR(
    "/count-platform-coupon-usage",
    async (): Promise<number> => {
      const res = await countCouponUsage({ promotionId })
      return res.data ? Number(res.data.count) : 0
    },
  )

  return {
    data,
    loading: isLoading,
    error,
    mutate,
  }
}

export const useCreatePromotion = () => {
  const { create } = usePromotionApi()
  return useCallback(
    async (
      input: PlatformCouponCartPromotionSetup,
    ): Promise<string | undefined> => {
      const { data } = await create({
        platformCoucherCartPromotionSetup: input,
      })
      return data.id
    },
    [create],
  )
}

export const useUpdatePromotion = () => {
  const { update } = usePromotionApi()
  return useCallback(
    async (
      id: string,
      input: PlatformCouponCartPromotionSetup,
    ): Promise<CreatePlatformCouponResponse | undefined> => {
      const { data } = await update({
        id,
        platformCoucherCartPromotionSetup: input,
      })
      return data
    },
    [update],
  )
}

export const useUpdateProductInPromotion = () => {
  const { updateProductInPromotion } = usePromotionApi()
  return useCallback(
    async (
      promotionId: string,
      input: UpdateProductInPromotionInput,
    ): Promise<
      | definitions["promotion.admin.v1.UpdateProductInPromotionResponse"]
      | undefined
    > => {
      const { data } = await updateProductInPromotion({
        promotionId,
        ...input,
      })
      return data
    },
    [updateProductInPromotion],
  )
}

export const useSetUserInPromotion = () => {
  const { setUserInPromotion } = usePromotionApi()
  return useCallback(
    async (promotionId: string, userIds: string[]): Promise<undefined> => {
      await setUserInPromotion({
        promotionId,
        userIds,
      })

      return
    },
    [setUserInPromotion],
  )
}

export const useGetAllUserInPromotion = () => {
  const { getAllUserInPromotion } = usePromotionApi()
  return useCallback(
    async (
      promotionId: string,
    ): Promise<definitions["promotion.admin.v1.UserInPromotion"][]> => {
      const { data } = await getAllUserInPromotion({
        promotionId,
      })
      return data.users || []
    },
    [getAllUserInPromotion],
  )
}

export const useGetShopsInPromotion = () => {
  const { getShopsInPromotion } = usePromotionApi()
  return useCallback(
    async (
      promotionId: string,
    ): Promise<
      definitions["promotion.admin.v1.GetShopsInPromotionResponse"] | undefined
    > => {
      const { data } = await getShopsInPromotion({
        promotionId,
      })
      return data
    },
    [getShopsInPromotion],
  )
}

export const useGetCategoriesInPromotion = () => {
  const { getCategoriesInPromotion } = usePromotionApi()
  return useCallback(
    async (
      promotionId: string,
    ): Promise<
      | definitions["promotion.admin.v1.GetCategoriesInPromotionResponse"]
      | undefined
    > => {
      const { data } = await getCategoriesInPromotion({
        promotionId,
      })
      return data
    },
    [getCategoriesInPromotion],
  )
}

export const useBffApi = () => {
  const { webBff } = useApi()

  const getProductInPromotion = useMemo(
    () =>
      webBff("/admin/product-in-promotion/{promotionId}")
        .method("get")
        .create(),
    [webBff],
  )

  return {
    getProductInPromotion,
  }
}

export const useGetProductInPromotion = () => {
  const { getProductInPromotion } = useBffApi()
  return useCallback(
    async (
      promotionId: string,
      skip?: number,
      limit?: number,
    ): Promise<
      | webBffDefinitions["webbff.admin.v1.FindProductInPromotionResponse"]
      | undefined
    > => {
      const { data } = await getProductInPromotion({
        promotionId,
        "option.limit": limit,
        "option.skip": skip,
      })
      return data
    },
    [getProductInPromotion],
  )
}

export const useShopApi = () => {
  const { shop } = useApi()

  const allShop = shop("/all-shop").method("get").create()
  const allCategory = shop("/all-category").method("get").create()

  return {
    allShop,
    allCategory,
  }
}

export const useGetAllShop = () => {
  const { allShop } = useShopApi()
  return useCallback(async (): Promise<GetAllShopResponse | undefined> => {
    const { data } = await allShop({})
    return data
  }, [allShop])
}

export const useGetAllShopSWR = () => {
  const { allShop } = useShopApi()
  return useSWR(
    "/all-shop",
    async (): Promise<shopDefinitions["shop.admin.v1.GetAllShopResponse"]> => {
      const res = await allShop({})
      return res.data
    },
  )
}

export const useFindAllCategory = (discountType: string) => {
  const { allCategory } = useShopApi()

  return useSWR(
    [`/all-category`, discountType],
    async (): Promise<Array<shopDefinitions["shop.v1.Category"]>> => {
      const res = await allCategory({
        "filter.prefix": discountType === "gift" ? "book" : "",
      })
      return res.data.categories || []
    },
  )
}

export const useGetProductInPromotionSWR = (
  promotionId: string,
  limit: number,
  skip: number,
  shouldFetch: boolean,
  disableAutoFetch: boolean,
) => {
  const { getProductInPromotion } = useBffApi()
  return useSWR(
    shouldFetch
      ? `/admin/product-in-promotion/${promotionId}?page=${skip}`
      : null,
    async (): Promise<
      webBffDefinitions["webbff.admin.v1.FindProductInPromotionResponse"]
    > => {
      const res = await getProductInPromotion({
        promotionId,
        "option.limit": limit,
        "option.skip": skip,
      })
      return res.data
    },
    {
      revalidateOnFocus: !disableAutoFetch,
      revalidateIfStale: !disableAutoFetch,
      revalidateOnReconnect: !disableAutoFetch,
    },
  )
}

export const useGetShopsInPromotionSWR = (
  promotionId: string,
  shouldFetch: boolean,
  disableAutoFetch: boolean,
) => {
  const { getShopsInPromotion } = usePromotionApi()
  return useSWR(
    shouldFetch ? `/get-shops-in-promotion/${promotionId}` : null,
    async (): Promise<
      definitions["promotion.admin.v1.GetShopsInPromotionResponse"]
    > => {
      const res = await getShopsInPromotion({
        promotionId,
      })
      return res.data
    },
    {
      revalidateOnFocus: !disableAutoFetch,
      revalidateIfStale: !disableAutoFetch,
      revalidateOnReconnect: !disableAutoFetch,
    },
  )
}

export const useGetCategoriesInPromotionSWR = (
  promotionId: string,
  shouldFetch: boolean,
  disableAutoFetch: boolean,
) => {
  const { getCategoriesInPromotion } = usePromotionApi()
  return useSWR(
    shouldFetch ? `/get-categories-in-promotion/${promotionId}` : null,
    async (): Promise<GetCategoriesInPromotionResponse> => {
      const res = await getCategoriesInPromotion({
        promotionId,
      })
      return res.data
    },
    {
      revalidateOnFocus: !disableAutoFetch,
      revalidateIfStale: !disableAutoFetch,
      revalidateOnReconnect: !disableAutoFetch,
    },
  )
}
