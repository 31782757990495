import { useNavigate } from "react-router-dom"
import { useAdditionalSection, useHomeManagementStore } from "../store/ctx"
import { BannerConfigSection, BannerList } from "../components/banner-common"
import {
  useUpdateFixedMediumBanner,
  useUpdateOverflowMediumBanner,
} from "./hook/update"
import { useFetchBanners } from "../small-banner/hook/banner"
import { useFormSubmit, useInitializeForm } from "../hook/form"
import { fixedMediumBannerPath, overflowMediumBannerPath } from "../hook/path"
import { MenuTypes } from "../hook/model"

export const FixedMediumBannerConfigSection = () => {
  const { selectedId } = useHomeManagementStore()
  const { activeBanner } = useFetchBanners(
    selectedId!,
    MenuTypes.midBannerContained,
  )

  const { sections } = useAdditionalSection()
  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail
  const form = useUpdateFixedMediumBanner(selectedId!, selectedSection!)
  const navigate = useNavigate()

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)

  return (
    <BannerConfigSection
      type={MenuTypes.midBannerContained}
      availableLayouts={["MEDIUM_BANNER_LAYOUT_FIXED"]}
      form={form}
      isLoading={isSubmitting}
    >
      <BannerList
        menu={MenuTypes.midBannerContained}
        activeBanner={activeBanner}
        pendingBanner={undefined}
        form={form}
        onClickEdit={(bannerKey) => {
          navigate(fixedMediumBannerPath(selectedId!, bannerKey))
        }}
        onClickManage={() => {
          navigate(fixedMediumBannerPath(selectedId!))
        }}
      />
    </BannerConfigSection>
  )
}

export const OverflowMediumBannerConfigSection = () => {
  const { selectedId } = useHomeManagementStore()
  const { activeBanner, pendingBanner } = useFetchBanners(
    selectedId!,
    MenuTypes.midBannerOverflow,
  )

  const { sections } = useAdditionalSection()
  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail

  const form = useUpdateOverflowMediumBanner(selectedId!, selectedSection!)
  const navigate = useNavigate()

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)

  return (
    <BannerConfigSection
      type={MenuTypes.midBannerOverflow}
      availableLayouts={["MEDIUM_BANNER_LAYOUT_OVERFLOW"]}
      form={form}
      isLoading={isSubmitting}
    >
      <BannerList
        menu={MenuTypes.midBannerOverflow}
        activeBanner={activeBanner}
        pendingBanner={pendingBanner}
        form={form}
        onClickEdit={(bannerKey) => {
          navigate(overflowMediumBannerPath(selectedId!, bannerKey))
        }}
        onClickManage={() => {
          navigate(overflowMediumBannerPath(selectedId!))
        }}
      />
    </BannerConfigSection>
  )
}
