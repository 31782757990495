import { ConfirmDeleteModal } from "@app/component"
import {
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
} from "@elastic/eui"
import {
  UilCopy,
  UilEllipsisV,
  UilMultiply,
  UilPen,
  UilPlay,
  UilTrash,
} from "@iconscout/react-unicons"
import { useState } from "react"
import { useTranslation } from "react-i18next"

type RowOption = {
  id: string
  isActive: boolean
  loading: boolean
  onCopyURL: (id: string) => void
  onEdit: (id: string) => void
  onActive: (id: string) => void
  onDelete: (id: string) => void
}

export const Options: React.FC<RowOption> = (props: RowOption) => {
  const { t } = useTranslation()
  const [isOpenPopOver, setIsOpenPopOver] = useState<boolean>(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false)
  const [funcActionConfirmButton, setFuncActionConfirmButton] = useState<
    ((id: string) => void) | undefined
  >(undefined)

  return (
    <EuiPopover
      id={`${props.id}-actions`}
      key={`${props.id}-actions`}
      button={
        <UilEllipsisV
          size={"14"}
          onClick={() => {
            setIsOpenPopOver(!isOpenPopOver)
          }}
        />
      }
      isOpen={isOpenPopOver}
      closePopover={() => setIsOpenPopOver(false)}
      anchorPosition="leftCenter"
    >
      <EuiContextMenuPanel
        items={[
          <EuiContextMenuItem
            key="copy-url"
            icon={<UilCopy size={"16"} />}
            onClick={() => {
              props.onCopyURL(props.id)
              setIsOpenPopOver(false)
            }}
          >
            {t("landing-page.copy-url")}
          </EuiContextMenuItem>,
          <EuiContextMenuItem
            key="edit"
            icon={<UilPen size={"16"} />}
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(props.id)
              }
              setIsOpenPopOver(false)
            }}
          >
            {t("landing-page.edit")}
          </EuiContextMenuItem>,
          <EuiContextMenuItem
            key="delete"
            icon={<UilTrash size={"16"} />}
            onClick={() => {
              setFuncActionConfirmButton(() => props.onDelete)
              setIsOpenModalConfirm(true)
            }}
          >
            {t("landing-page.delete")}
          </EuiContextMenuItem>,
          props.isActive ? (
            <EuiContextMenuItem
              key={`end-${props.id}`}
              className="text-ci-red"
              icon={<UilMultiply size={"16"} />}
              onClick={() => {
                setFuncActionConfirmButton(() => props.onActive)
                setIsOpenModalConfirm(true)
              }}
            >
              {t("landing-page.deactivate")}
            </EuiContextMenuItem>
          ) : (
            <EuiContextMenuItem
              key={`end-${props.id}`}
              className="text-ci-green"
              icon={<UilPlay size={"16"} />}
              onClick={() => {
                setFuncActionConfirmButton(() => props.onActive)
                setIsOpenModalConfirm(true)
              }}
            >
              {t("landing-page.activate")}
            </EuiContextMenuItem>
          ),
        ]}
      />
      {isOpenModalConfirm ? (
        <ConfirmDeleteModal
          onClose={() => {
            setIsOpenPopOver(false)
            setIsOpenModalConfirm(false)
          }}
          onConfirm={async () => {
            if (funcActionConfirmButton) {
              funcActionConfirmButton(props.id)
            }

            setIsOpenPopOver(false)
            setIsOpenModalConfirm(false)
          }}
          isDeleting={props.loading}
          textConfirmButton={t("common.confirm")}
          title={t("common.please-confirm")}
          description=""
        />
      ) : null}
    </EuiPopover>
  )
}
