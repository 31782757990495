import { EuiFormRow, EuiSelect, EuiSwitch } from "@elastic/eui"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ProductUploadFromXLSX } from "../components/xlsx-upload"
import {
  mappingProductTypeInputToShopProductType,
  ProductTypeInput,
} from "@app/model/product-table"
import { read as readXlsx, utils as utilsXlsx } from "xlsx"
import { definitions as utilityDef } from "@app/vendor/web-utility-specs/web_utility_service"
import {
  PopularProductDetailConfigProps,
  ProductSectionError,
  RecommendedProductDetailConfigProps,
  useAdminProductApi,
} from "./hook/update-detail"
import { publicConfig } from "@app/config"
import { useStore } from "@app/hook/store"

export const ProductAutoForm = ({
  form,
  type,
}: {
  form: PopularProductDetailConfigProps | RecommendedProductDetailConfigProps
  type: "RECOMMENDED_PRODUCT" | "POPULAR_PRODUCT"
}) => {
  const { t } = useTranslation()

  const popularProductTypeOptions: {
    value: utilityDef["shop.v1.ProductType"]
    text: string
    hidden?: boolean
  }[] = [
    {
      value: "PRODUCT_TYPE_UNSPECIFIED",
      text: t("home-management.product.menu-list.all"),
      hidden: type === "POPULAR_PRODUCT",
    },
    {
      value: "PRODUCT_TYPE_NOVEL",
      text: t("home-management.product.menu-list.novel"),
      hidden: !publicConfig.enableNovelProductTypeFlexibleHomepageManagement,
    },
    {
      value: "PRODUCT_TYPE_E_BOOK_AUDIO",
      text: t("home-management.product.menu-list.ebook-audio"),
      hidden:
        !publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement,
    },
    {
      value: "PRODUCT_TYPE_PHYSICAL",
      text: t("home-management.product.menu-list.physical"),
    },
    {
      value: "PRODUCT_TYPE_E_VOUCHER",
      text: t("home-management.product.menu-list.e-voucher"),
      hidden: !publicConfig.enableEVoucherProductTypeFlexibleHomepageManagement,
    },
  ]

  const recommendedProductTypeOptions: {
    value: utilityDef["webutility.v1.DisplayRecommendedProductType"]
    text: string
    hidden?: boolean
  }[] = [
    {
      value: "DISPLAY_RECOMMENDED_PRODUCT_TYPE_ALL",
      text: t("home-management.product.menu-list.all"),
      hidden:
        !publicConfig.enableNovelProductTypeFlexibleHomepageManagement &&
        !publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement,
    },
    {
      value: "DISPLAY_RECOMMENDED_PRODUCT_TYPE_NOVEL",
      text: t("home-management.product.menu-list.novel"),
      hidden: !publicConfig.enableNovelProductTypeFlexibleHomepageManagement,
    },
    {
      value: "DISPLAY_RECOMMENDED_PRODUCT_TYPE_E_BOOK_AUDIO",
      text: t("home-management.product.menu-list.ebook-audio"),
      hidden:
        !publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement,
    },
    {
      value: "DISPLAY_RECOMMENDED_PRODUCT_TYPE_PHYSICAL",
      text: t("home-management.product.menu-list.physical"),
    },
    {
      value: "DISPLAY_RECOMMENDED_PRODUCT_TYPE_E_VOUCHER",
      text: t("home-management.product.menu-list.e-voucher"),
      hidden: !publicConfig.enableEVoucherProductTypeFlexibleHomepageManagement,
    },
  ]

  const durationOption: {
    value: utilityDef["webutility.v1.PopularProductSectionDisplayOption"]
    text: string
  }[] = [
    {
      value: "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_BEST_SELLING_ALL_TIME",
      text: t("home-management.product.duration.all-time"),
    },
    {
      value: "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_BEST_SELLING_WEEKLY",
      text: t("home-management.product.duration.weekly"),
    },
    {
      value: "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_BEST_SELLING_MONTHLY",
      text: t("home-management.product.duration.monthly"),
    },
  ]

  return (
    <>
      <EuiFormRow label={t("home-management.product.type")}>
        <>
          <EuiSelect
            options={
              type === "POPULAR_PRODUCT"
                ? popularProductTypeOptions
                : recommendedProductTypeOptions
            }
            value={form.values.displayProductType}
            onChange={(e) =>
              form.setFieldValue("displayProductType", e.target.value)
            }
          />
          {!!form.errors.displayProductType &&
            form.errors.displayProductType ===
              ProductSectionError.ProductTypeIsNotSupported && (
              <p className="mt-2 text-sm text-ci-red">
                {t(`home-management.error.${form.errors.displayProductType}`)}
              </p>
            )}
        </>
      </EuiFormRow>
      {type === "POPULAR_PRODUCT" && (
        <>
          <EuiFormRow label={t("home-management.product.duration-label")}>
            <EuiSelect
              options={durationOption}
              value={form.values.displayOption}
              onChange={(e) =>
                form.setFieldValue("displayOption", e.target.value)
              }
            />
          </EuiFormRow>
          {form.values.layout === "POPULAR_PRODUCT_LAYOUT_OVERFLOW" && (
            <EuiSwitch
              label={t("home-management.product.show-number")}
              checked={form.values?.shouldShowProductRanking!}
              onChange={(e) => {
                form.setFieldValue("shouldShowProductRanking", e.target.checked)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

const enableNovelProductType =
  publicConfig.enableNovelProductTypeFlexibleHomepageManagement
const enableEBookAudioProductType =
  publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement
const enableEVoucherProductType =
  publicConfig.enableEVoucherProductTypeFlexibleHomepageManagement

export const ProductManualForm = ({
  form,
  selectedSection,
  isSubmitting,
}: {
  form: PopularProductDetailConfigProps | RecommendedProductDetailConfigProps
  selectedSection: { [key: string]: any } | undefined
  isSubmitting: boolean
}) => {
  const { t } = useTranslation()
  const { notificationStore: notification } = useStore()
  const { getProduct, loading } = useAdminProductApi()
  const [errorsIds, setErrorsIds] = React.useState<string[]>([])

  useEffect(() => {
    validateProduct()
  }, [])

  const validateProduct = async () => {
    if (!form.values.adminSelectedProductKeys) {
      return
    }

    if (form.values.adminSelectedProductKeys?.length === 0) {
      return
    }

    const products = await getProduct(
      form.values.adminSelectedProductKeys.map((p) => {
        return {
          id: p.productId,
          type: p.productType,
        }
      }),
    )
    const tempErrorIds: string[] = []
    if (
      products &&
      products?.products &&
      (products?.products ?? []).length > 0
    ) {
      for (let i = 0; i < (products?.products ?? []).length; i++) {
        const p = (products?.products ?? [])[i].product
        if (p === null) {
          tempErrorIds.push(
            form.values.adminSelectedProductKeys[i].productId ?? "",
          )
        }
      }

      setErrorsIds(tempErrorIds)
    }

    if (tempErrorIds.length === 0) {
      setErrorsIds([])
    }

    return tempErrorIds
  }

  const filePickerRef = useRef<any>()

  const handleUploadProducts = async (files: FileList | null) => {
    let existRawProduct: {
      id: string
      type: ProductTypeInput
    }[] = []
    const productKeys: utilityDef["webutility.v1.ProductKey"][] = []
    if (files && files[0]) {
      const result = await files[0].arrayBuffer()
      const workbook = readXlsx(result)
      const wsname = workbook.SheetNames[0]
      const ws = workbook.Sheets[wsname]
      existRawProduct = utilsXlsx.sheet_to_json(ws)
      const checkDuplicate: { [key: string]: boolean } = {}
      const products = await getProduct(
        existRawProduct.map((p) => {
          return {
            id: p.id,
            type: mappingProductTypeInputToShopProductType(p.type),
          }
        }),
      )
      const tempErrorIds: string[] = []
      if (
        products &&
        products?.products &&
        (products?.products ?? []).length > 0
      ) {
        for (let i = 0; i < (products?.products ?? []).length; i++) {
          const p = (products?.products ?? [])[i].product
          if (p === null) {
            tempErrorIds.push(existRawProduct[i].id)
          }
        }

        setErrorsIds(tempErrorIds)
      }

      if (tempErrorIds.length === 0) {
        setErrorsIds([])
      }

      existRawProduct = existRawProduct.filter((p) => {
        return !tempErrorIds.includes(p.id)
      })

      for (let i = 0; i < existRawProduct.length; i++) {
        const productType = mappingProductTypeInputToShopProductType(
          existRawProduct[i].type,
        )
        if (
          (!enableNovelProductType && productType === "PRODUCT_TYPE_NOVEL") ||
          (!enableEBookAudioProductType &&
            productType === "PRODUCT_TYPE_E_BOOK_AUDIO") ||
          (!enableEVoucherProductType &&
            productType === "PRODUCT_TYPE_E_VOUCHER")
        ) {
          notification.add({
            title: "Error",
            color: "danger",
            text: t("home-management.error.product-type-is-not-supported"),
          })
          return
        }

        if (
          existRawProduct[i].id === undefined ||
          productType === "PRODUCT_TYPE_UNSPECIFIED"
        ) {
          notification.add({
            title: "Error",
            color: "danger",
            text: t("home-management.error.invalid-format-spreadsheet"),
          })
          return
        }
        if (!checkDuplicate[existRawProduct[i].id]) {
          checkDuplicate[existRawProduct[i].id] = true
          productKeys.push({
            productId: existRawProduct[i].id,
            productType,
          })
        }
      }
    }

    form.setFieldValue("adminSelectedProductKeys", productKeys)

    if (filePickerRef && filePickerRef.current) {
      filePickerRef?.current?.removeFiles()
    }
  }

  return (
    <div
      className="flex w-full items-center gap-x-4 rounded-lg border border-solid !border-table
          !bg-grey-lightest px-4 py-2 pb-4"
      css={{
        "*": {
          background: "transparent !important",
        },
      }}
    >
      <ProductUploadFromXLSX
        form={form}
        isSubmitting={isSubmitting || loading}
        selectedSection={selectedSection}
        handleUploadProducts={handleUploadProducts}
        errorIds={errorsIds}
      />
    </div>
  )
}
