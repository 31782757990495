import type {
  BaseSelection,
  LexicalEditor,
  NodeKey,
  NodeSelection,
  RangeSelection,
} from "lexical"
import "./image-component.css"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection"
import { mergeRegister } from "@lexical/utils"
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  DRAGSTART_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical"
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { ImageResizer } from "../../ui/image-resizer"
import { $isImageNode } from "./image-node"
import {
  OverlayFailedToUploadImage,
  OverlayUploadingImage,
} from "./overlay-image-component"

export const ImageComponent: React.FC<{
  altText: string
  height: "inherit" | number
  width: "inherit" | number
  nodeKey: NodeKey
  resizable: boolean
  src: string
  isUploading?: boolean
  isUploadFailed?: boolean
}> = ({
  src,
  altText,
  nodeKey,
  width,
  height,
  resizable,
  isUploading,
  isUploadFailed,
}) => {
  const imageRef = useRef<null | HTMLImageElement>(null)
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey)
  const [isResizing, setIsResizing] = useState<boolean>(false)
  const [editor] = useLexicalComposerContext()
  const [selection, setSelection] = useState<BaseSelection | null>(null)
  const activeEditorRef = useRef<LexicalEditor | null>(null)
  const [isLoadImageError, setIsLoadImageError] = useState<boolean>(false)

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload
        event.preventDefault()
        const node = $getNodeByKey(nodeKey)
        if ($isImageNode(node)) {
          node.remove()
          return true
        }
      }
      return false
    },
    [isSelected, nodeKey],
  )

  useEffect(() => {
    let isMounted = true
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        if (isMounted) {
          setSelection(editorState.read(() => $getSelection()))
        }
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        (payload) => {
          const event = payload

          if (isResizing) {
            return true
          }
          if (event.target === imageRef.current) {
            if (event.shiftKey) {
              setSelected(!isSelected)
            } else {
              clearSelection()
              setSelected(true)
            }
            return true
          }

          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        (event) => {
          if (event.target === imageRef.current) {
            // TODO This is just a temporary workaround for FF to behave like other browsers.
            // Ideally, this handles drag & drop too (and all browsers).
            event.preventDefault()
            return true
          }
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW,
      ),
    )
    return () => {
      isMounted = false
      unregister()
    }
  }, [
    clearSelection,
    editor,
    isResizing,
    isSelected,
    nodeKey,
    onDelete,
    setSelected,
  ])

  const onResizeEnd = (
    nextWidth: "inherit" | number,
    nextHeight: "inherit" | number,
  ) => {
    // Delay hiding the resize bars for click case
    setTimeout(() => {
      setIsResizing(false)
    }, 200)

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isImageNode(node)) {
        node.setWidthAndHeight(nextWidth, nextHeight)
      }
    })
  }

  const onResizeStart = () => {
    setIsResizing(true)
  }

  const draggable =
    isSelected &&
    $isNodeSelection(selection) &&
    !isResizing &&
    !isUploading &&
    !isUploadFailed
  const isFocused = isSelected || isResizing
  const isResizable =
    !isLoadImageError &&
    resizable &&
    $isNodeSelection(selection) &&
    isFocused &&
    !isUploading &&
    !isUploadFailed

  return (
    <Suspense fallback={null}>
      <>
        <div draggable={draggable} style={{ position: "relative" }}>
          <img
            className={`mpe-no-image-placeholder ${
              isFocused
                ? `mpe-focused ${
                    !isLoadImageError && $isNodeSelection(selection)
                      ? "mpe-draggable"
                      : ""
                  }`
                : undefined
            }`}
            src={src}
            alt={altText}
            ref={imageRef}
            style={
              isLoadImageError
                ? { height: "406px", width: "329px" }
                : {
                    height,
                    width,
                  }
            }
            draggable="false"
            onError={(e) => {
              setIsLoadImageError(true)
            }}
          />

          {isUploadFailed ? (
            <OverlayFailedToUploadImage />
          ) : isUploading ? (
            <OverlayUploadingImage />
          ) : null}
        </div>
        {isResizable && (
          <ImageResizer
            editor={editor}
            imageRef={imageRef}
            onResizeStart={onResizeStart}
            onResizeEnd={onResizeEnd}
          />
        )}
      </>
    </Suspense>
  )
}
