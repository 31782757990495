import { ConfirmDeleteModal, Title } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { useStore } from "@app/hook/store"
import { useCallAsync } from "@app/lib/async"
import {
  EuiBasicTableColumn,
  EuiFieldText,
  EuiFormRow,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilSearch } from "@iconscout/react-unicons"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Item, useDeletePrivilege, useListPrivilege } from "./hook/privilege"
import { PrivilegeAction } from "./utils"
import { useDebounce } from "use-debounce"

export const Privilege: React.FC = () => {
  const { t } = useTranslation()
  const { notificationStore: notification } = useStore()
  const navigate = useNavigate()
  const [idForDelete, setIdForDelete] = useState<string | undefined>(undefined)
  const { loading, onGetItems, filter, setFilter } = useListPrivilege()
  const { fn: deletePrivilege, loading: isDeletingPrivilege } =
    useCallAsync(useDeletePrivilege())
  const [debounceSearchUserId] = useDebounce(filter.userId, 500)

  const onDeletePrivilege = (id: string) => {
    setIdForDelete(id)
  }

  const columns: Array<EuiBasicTableColumn<Item>> = [
    {
      name: t("privilege.list.user-id"),
      truncateText: true,
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.userId}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("privilege.list.username"),
      truncateText: true,
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.userName}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("privilege.list.product-name"),
      truncateText: true,
      width: "300px",
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.productName}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("privilege.list.product-type"),
      truncateText: true,
      render: (row: Item) => {
        return (
          <div>
            <EuiText size="xs">{row.productType}</EuiText>
          </div>
        )
      },
    },
    {
      name: t("privilege.list.expired-at"),
      truncateText: true,
      field: "expiredAt",
      sortable: true,
      render: (row: Item) => {
        return row.expiredAt ? (
          <div>{t("common.datetime", { value: new Date(row.expiredAt) })}</div>
        ) : (
          "-"
        )
      },
    },
    {
      name: t("privilege.list.created-at"),
      truncateText: true,
      field: "createdAt",
      render: (row: Item) => {
        return row.createdAt ? (
          <div>{t("common.datetime", { value: new Date(row.createdAt) })}</div>
        ) : (
          "-"
        )
      },
    },
    {
      align: "right",
      width: "40px",
      isExpander: true,
      onClick: (e) => {
        e.stopPropagation()
      },
      render: (row: Item) => {
        return <PrivilegeAction id={row.id} onDelete={onDeletePrivilege} />
      },
    },
  ]

  const ref = useRef() as TableRefImperative
  return (
    <div>
      <Title
        text={t("nav.privilege")}
        btn={{
          iconName: "plus",
          text: t("privilege.editor.create-privilege"),
          onClick: () => navigate("/privilege/create"),
        }}
      />

      <EuiFormRow fullWidth isInvalid={false} className="my-8">
        <EuiFieldText
          placeholder={t("privilege.list.search-user-id-placeholder")}
          value={filter.userId || ""}
          onChange={(e) => {
            setFilter({
              userId: e.target.value,
            })
          }}
          style={{
            width: 376,
          }}
          icon={<UilSearch />}
        />
      </EuiFormRow>

      <Table<Item>
        ref={ref}
        swrKey="/privilege"
        columns={columns}
        onGetItems={onGetItems}
        filter={{
          userId: debounceSearchUserId,
        }}
        sorter={{ field: "expiredAt", direction: "desc" }}
        loading={loading}
        css={css`
          .p-center {
            p {
              margin: auto 0;
            }
          }
        `}
        isCanSelect={false}
      />

      {idForDelete && (
        <ConfirmDeleteModal
          isDeleting={isDeletingPrivilege}
          description={""}
          onClose={() => {
            setIdForDelete(undefined)
          }}
          onConfirm={async () => {
            await deletePrivilege(idForDelete)
            notification.add({
              title: "Success",
              color: "success",
            })
            ref.current.refetch()
            setIdForDelete(undefined)
          }}
        />
      )}
    </div>
  )
}
