import { useApi } from "@app/hook/api"
import { useSearchParams } from "react-router-dom"
import { Filter } from "@reeeed-mp/ui-common"
import { useEffect, useState } from "react"
import { BannerDetailProps } from "../../hero-banner/hook/hero-banner"
import { mapBannerToDetail } from "../../small-banner/hook/banner"
import { MenuTypes } from "../../hook/model"

export type WriterBannerItem = {
  order: string
  id: string
  cover: string
  name: string
  link: string
  createdAt: Date
  bannerKey: string
  numberOfWorks: string
  count: string
}

export type WriterBannerList = {
  id: string
  cover: string
  name: string
  link: string
  createdAt: Date
  bannerKey: string
  numberOfWorks: string
}

export const useGetWriterBanner = (sectionKey: string) => {
  const searchParamKey = "textSearch"
  const [searchParams] = useSearchParams()
  const [textSearch, setTextSearch] = useState(
    searchParams.get(searchParamKey) || "",
  )
  const { webUtility } = useApi()

  const getWriterBannerApi = webUtility("/admin/writer-banner")
    .method("get")
    .create()

  const getWriterBanner = async ({ filter, opts }: Filter) => {
    const resp = await getWriterBannerApi({
      sectionKey,
      "filter.name": filter?.textSearch,
      "pagination.limit": opts?.limit || 6,
      "pagination.skip": opts?.skip || 0,
    })

    return resp.data
  }

  return {
    getWriterBanner,
    textSearch,
    setTextSearch,
  }
}

export const useFetchWriterBanners = (sectionKey: string) => {
  const { getWriterBanner } = useGetWriterBanner(sectionKey)

  const [activeBanner, setActiveBanner] = useState<BannerDetailProps>({
    banner: [],
    total: "0",
  })

  useEffect(() => {
    const fetchBanners = async () => {
      const active = await getWriterBanner({})
      setActiveBanner(mapBannerToDetail(active, MenuTypes.writer))
    }
    fetchBanners()
  }, [sectionKey])

  return { activeBanner }
}
