import { publicConfig } from "@app/config"

export const useAvailableFeature = () => {
  const eBookAudio = publicConfig.eBookAudio
  const novel = publicConfig.novel
  const physical = publicConfig.physical
  const eVoucher = publicConfig.eVoucher

  return {
    eBookAudio,
    novel,
    physical,
    eVoucher,
  }
}
