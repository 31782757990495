import { EuiIcon, EuiPanel } from "@elastic/eui"
import React from "react"
import { Item } from "./common"

export const ChapterItem = <T extends Item>(props: {
  item: T
  mutate: () => void
  itemComp: (item: T, mutate: () => void) => React.ReactNode
}) => {
  const { item, itemComp, mutate } = props

  return (
    <div className="hover:bg-slate-100">
      <EuiPanel paddingSize="s" hasBorder={false} hasShadow={false}>
        <div className="flex items-center justify-between">
          <div>
            <EuiPanel color="transparent" paddingSize="s">
              <EuiIcon type="grab" />
            </EuiPanel>
          </div>
          {itemComp(item, mutate)}
        </div>
      </EuiPanel>
    </div>
  )
}
