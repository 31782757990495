import { InitialEditorStateType } from "@lexical/react/LexicalComposer"
import { EditorState } from "lexical"
import { GetUploadURL } from "./editors/lexical/plugins/image/common"

export enum ToolbarPlugins {
  UndoRedo = "undo-redo",
  BlockType = "block-type",
  RichText = "rich-text",
  Alignment = "alignment",
  Link = "link",
  ImageUrl = "image-url",
  ImageUpload = "image-upload",
  Youtube = "youtube",
}

export type ReadOnlyEditorProps = {
  isEditable: false
}

export type EditableEditorProps = {
  isEditable: true
  onEditorStateChange: (state: EditorState) => void
  toolbarPlugins: Array<ToolbarPlugins>
  getUploadURL?: GetUploadURL
  maxUploadImage?: number
  allowedLinkPrefixes?: Array<string>
}

export type CommonEditorProps = {
  isEditable: boolean
  initEditorState: InitialEditorStateType
  onError?: (error: Error) => void
  placeholder?: string
}

export type EditorProps = CommonEditorProps &
  (ReadOnlyEditorProps | EditableEditorProps)
