import { SmallModal } from "@app/component/modal/small"
import React, { useEffect } from "react"
import { useHomeManagementStore } from "../store/ctx"
import { useTranslation } from "react-i18next"

export const ModalConfirmation = ({ hasDraft }: { hasDraft: boolean }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isShown, setIsShown] = React.useState(false)
  const { discardChanges } = useHomeManagementStore()
  const { t } = useTranslation()
  useEffect(() => {
    if (hasDraft) {
      setIsOpen(true)
    }
  }, [hasDraft])

  const handleKeepEditing = () => {
    setIsOpen(false)
    setIsShown(true)
  }

  const handleDiscardEditing = () => {
    discardChanges()
    setIsOpen(false)
    setIsShown(true)
  }

  return (
    <SmallModal
      cancelBtnText={t("home-management.modal-confirmation.cancel")}
      confirmBtnText={t("home-management.modal-confirmation.confirm")}
      onCancel={handleDiscardEditing}
      onConfirm={handleKeepEditing}
      isOpen={isOpen && !isShown}
      header={t("home-management.modal-confirmation.header")}
    ></SmallModal>
  )
}
