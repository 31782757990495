import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import * as Yup from "yup"
import { FileForm } from "../form"

export type BannerForm = {
  id?: string
  url?: string
  imageAlt?: string
  fileInput?: FileForm["fileInput"]
  downloadUrl?: string
  image?: string
}

export type BannerInput = definitions["webutility.admin.v1.BannerInput"] & {
  downloadUrl?: string
}

export const bannerFormValidator = Yup.object({
  url: Yup.string().required(),
  downloadUrl: Yup.string().required(),
})
