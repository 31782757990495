import React from "react"
import { EuiButton, EuiButtonEmpty, EuiPopover } from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { UilPlus } from "@iconscout/react-unicons"
import { useState } from "react"
import { homeMenuListOptions } from "../hook/model"
import { useHomeManagementStore } from "../store/ctx"

export const NewAdditionalSectionButton = (props: { disabled: boolean }) => {
  const { createPageSection } = useHomeManagementStore()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <EuiPopover
      closePopover={() => setIsOpen(false)}
      isOpen={isOpen}
      anchorPosition="downCenter"
      button={
        <EuiButton
          fill
          minWidth={170}
          iconType={UilPlus}
          disabled={props.disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          {t("home-management.button.add-main-row")}
        </EuiButton>
      }
    >
      <div className="flex w-52 flex-col gap-y-2">
        {homeMenuListOptions
          .filter((menu) => !menu.hidden)
          .map((menu) => (
            <EuiButtonEmpty
              key={menu.value}
              onClick={async () => {
                await createPageSection?.(menu.value)
                setIsOpen(false)
              }}
            >
              {menu.text}
            </EuiButtonEmpty>
          ))}
      </div>
    </EuiPopover>
  )
}
