import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import {
  BannerForm,
  bannerFormValidator,
  BannerInput,
} from "@app/model/homepage/banner"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputImagePicker, useFormHelper } from "@reeeed-mp/ui-common"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useHomeManagementStore } from "../store/ctx"

export const EditorBanner = (props: {
  title: string
  page: "subBanner1" | "subBanner2" | "subBanner3"
}) => {
  const { t } = useTranslation()
  const { t: editorT } = useTranslation("translation", {
    keyPrefix: "homepage-management.banner",
  })
  const { notificationStore } = useStore()
  const [loading, setLoading] = useState(false)
  const { uploadFile } = useUploadFile()
  const navigate = useNavigate()
  const homeManagementStore = useHomeManagementStore()

  const onSubmit = async (
    values: BannerForm,
  ): Promise<{ id: string | undefined }> => {
    const input = {
      id: values.id,
      url: values.url,
      imageAlt: values.imageAlt,
      downloadUrl: values.downloadUrl,
      ...(values.fileInput?.tmpPath
        ? { image: values.fileInput?.tmpPath }
        : { image: values.image }),
    }

    homeManagementStore.update("highlightBannerSection", {
      ...homeManagementStore.homepage?.highlightBannerSection,
      [props.page]: input,
    })

    return {
      id: "",
    }
  }

  const initialValues = {
    ...homeManagementStore.homepage?.highlightBannerSection?.[props.page],
    downloadUrl:
      (
        homeManagementStore.homepage?.highlightBannerSection?.[
          props.page
        ] as BannerInput
      )?.downloadUrl ??
      homeManagementStore.homepage?.highlightBannerSection?.[props.page]?.image,
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<BannerForm, { id: string | undefined }>({
    id: undefined,
    initialValues,
    onGetItem: async () => {
      return homeManagementStore.homepage?.highlightBannerSection?.[props.page]
    },
    onError: () => {},
    onSubmit,
    onSubmitted: () => {
      navigate("/home-management", { replace: true })
    },
    validationSchema: bannerFormValidator,
  })

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      <div className="mb-10">
        <NavigationHeaderButton
          title={props.title}
          onClick={() => {
            navigate("/home-management")
          }}
        />
      </div>
      <div>
        <EuiFormRow
          css={css`
            .euiFormRow__fieldWrapper {
              width: 736px !important;
            }
          `}
          isInvalid={!!errors.downloadUrl}
        >
          <FormInputImagePicker
            isInvalid={!!errors.downloadUrl}
            css={css`
              width: 740px !important;
              height: 355px !important;
              .euiImage {
                border-radius: 6px !important;
                width: 740px !important;
                height: 355px !important;
                object-fit: cover;
              }
              .euiImage__img {
                width: 740px !important;
                height: 355px !important;
                border-radius: 6px !important;
              }
            `}
            placeholder={
              <div className="flex flex-col text-center">
                <div>
                  <p>{editorT("banner-image")}</p>
                </div>
                <div>
                  <p className="whitespace-pre-wrap text-center text-sm">
                    {editorT("cover-suggestion", {
                      size: "580x277 px",
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            accept={`${contentType.JPG}, ${contentType.PNG}`}
            value={form?.downloadUrl || undefined}
            onChange={async (v: File | null) => {
              if (v) {
                try {
                  setLoading(true)
                  const { input, blob } = await uploadFile(v)
                  setValue("fileInput", input)
                  setValue("downloadUrl", blob)
                } catch (err: any) {
                  if (err && err.message) {
                    notificationStore.add({
                      title: "Error",
                      color: "danger",
                      text: err.message,
                    })
                  }
                } finally {
                  setLoading(false)
                }
              }
            }}
          />
        </EuiFormRow>
      </div>

      <EuiFormRow
        isInvalid={!!errors.url}
        label={t("common.link")}
        className="pt-6"
        css={css`
          .euiFormRow__fieldWrapper {
            width: 736px !important;
          }
        `}
        fullWidth
      >
        <EuiFieldText
          placeholder={t("common.please-fill")}
          isInvalid={!!errors.url}
          fullWidth
          value={form?.url}
          onChange={(e) => {
            setValue("url", e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        isInvalid={!!errors.imageAlt}
        label={t("common.meta")}
        className="pt-6"
        css={css`
          .euiFormRow__fieldWrapper {
            width: 736px !important;
          }
        `}
        fullWidth
      >
        <EuiFieldText
          placeholder={t("common.please-fill")}
          value={form?.imageAlt}
          isInvalid={!!errors.imageAlt}
          fullWidth
          onChange={(e) => {
            setValue("imageAlt", e.target.value)
          }}
        />
      </EuiFormRow>
      <div className="flex items-center pt-10">
        <EuiButton
          css={css`
            width: 255px;
            height: 40px;
          `}
          fill
          disabled={loading}
          className="mr-4"
          color="primary"
          onClick={() => {
            submit()
          }}
        >
          {form.downloadUrl ? t("common.save") : editorT("create-banner")}
        </EuiButton>
        <EuiButtonEmpty
          css={css`
            width: 255px;
            height: 40px;
          `}
          color="text"
          disabled={loading}
          onClick={() => {
            navigate("/home-management")
          }}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </div>
  )
}
