import { NotiPayload } from "@app/store/notification"
import { errToDefaultI18nKey, pipe } from "@reeeed-mp/ui-common"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "./store"

export const useAlertDefaultErr = () => {
  const { t } = useTranslation()
  const { notificationStore } = useStore()

  return pipe(errToDefaultI18nKey)
    .p(t)
    .p(toNotiPayload)
    .p(notificationStore.add.bind(notificationStore)).fn
}

export const useAlertIfDefaultErr = (errs: any[]) => {
  const alertDefaultErr = useAlertDefaultErr()
  const [prevErrs, setPrevErrs] = useState(errs)
  let change = false
  let i = 0

  if (errs.length !== prevErrs.length) {
    change = true
  }
  while (!change && i < errs.length) {
    if (prevErrs[i] !== errs[i]) {
      change = true
    }
    i++
  }
  if (!change) {
    return
  }
  errs.forEach((e) => {
    if (e) {
      alertDefaultErr(e)
    }
  })
  setPrevErrs(errs)
}

const toNotiPayload = (msg: string): NotiPayload => {
  return { title: "Error", color: "danger", text: msg }
}
