import { useApi } from "@app/hook/api"
import { useStore } from "@app/hook/store"
import { useHomeMenuList } from "../../hook/model"
import { useDebouncedCallback } from "use-debounce"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { isEqual } from "lodash-es"

type SmallBannerDetailProps = {
  title?: string
  description?: string
  layout?: definitions["webutility.v1.SmallBannerLayout"]
  shouldShowTitle?: boolean
  shouldShowBannerTitle?: boolean
}

export type SmallBannerDetailConfigProps = ReturnType<
  typeof useUpdateSmallBanner
>

export const useUpdateSmallBanner = (
  sectionKey: string,
  initialValues: SmallBannerDetailProps,
) => {
  const { notificationStore } = useStore()
  const { webUtility } = useApi()
  const { mutate } = useHomeMenuList()

  const updateSmallBannerApi = webUtility("/admin/small-banner-section")
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(
    async (input: SmallBannerDetailProps) => {
      if (isEqual(input, initialValues)) {
        return
      }
      try {
        await updateSmallBannerApi({
          sectionKey,
          input: {
            title: input.title,
            description: input.description,
            layout: input.layout,
            shouldShowTitle: input.shouldShowTitle,
            shouldShowBannerTitle: input.shouldShowBannerTitle,
          },
        })
        mutate()
        return { sectionKey }
      } catch (error) {
        throw errToDefaultI18nKey(error)
      }
    },
    300,
  ) as (
    form: SmallBannerDetailProps,
    sectionKey?: string,
  ) => Promise<{ sectionKey?: string }>

  const onSubmitted = () => {}

  const onGetItem = async (): Promise<SmallBannerDetailProps> => {
    return {}
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: err.message,
      })
    }
  }

  const form = useFormHelper<SmallBannerDetailProps, { sectionKey?: string }>({
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}
