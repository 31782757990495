import { Title } from "@app/component"
import {
  EuiFieldSearch,
  EuiFormRow,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { theme } from "@reeeed-mp/ui-common"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { BasicTableLandingPage } from "./components/basic-table"

export const LandingPageManagement = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const landingPageTabStatus = [
    t("landing-page.index.activate"),
    t("landing-page.index.deactivate"),
  ]
  const [searchParams] = useSearchParams()
  const searchTextParamKey = "searchText"
  const isActiveParamKey = "isActive"
  const searchTextParam = searchParams.get(searchTextParamKey)
  const isActiveParam = searchParams.get(isActiveParamKey)
  const [isActive, setIsActive] = useState<boolean>(
    isActiveParam === "false" ? false : true,
  )
  const [searchText, setSearchText] = useState<string>(searchTextParam || "")
  const [debouncedSearchText] = useDebounce(searchText, 1000)

  return (
    <div>
      <Title
        text={t("landing-page.index.landing-page")}
        btn={{
          iconName: "plus",
          text: t("landing-page.index.create-landing-page"),
          onClick: () => navigate("/landing-page/create"),
        }}
      />
      <div className="mb-6 flex w-full flex-auto justify-between">
        <div className="self-center">
          <EuiTabs
            css={css({
              ".euiTab": {
                ".euiTab__content": {
                  color: "#69707d !important",
                },
                "&.euiTab-isSelected": {
                  ".euiTab__content": {
                    color: `${theme.colors.LIGHT.primaryPurple} !important`,
                  },
                },
              },
              "&.euiTabs--bottomBorder": {
                boxShadow: "none",
              },
            })}
            size="l"
          >
            {landingPageTabStatus.map((s, i) => (
              <EuiTab
                key={s}
                isSelected={isActive === !i}
                onClick={() => {
                  setIsActive(!i)
                }}
              >
                <EuiText size="m" className="font-bold">
                  {s}
                </EuiText>
              </EuiTab>
            ))}
          </EuiTabs>
        </div>

        <EuiFormRow fullWidth isInvalid={false}>
          <EuiFieldSearch
            placeholder={t("landing-page.index.search-landing-page")}
            value={searchText}
            onChange={(e) => {
              e.preventDefault()
              setSearchText(e.target.value)
            }}
            style={{
              width: 376,
            }}
          />
        </EuiFormRow>
      </div>
      <BasicTableLandingPage
        isActive={isActive}
        searchText={debouncedSearchText}
      />
    </div>
  )
}
