import { NavigationHeaderButton } from "@app/component"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiText,
} from "@elastic/eui"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { ImagePicker } from "./image-picker"
import { contentType } from "@app/hook/file"
import { useFormHighlightNavigation, useFormNavigation } from "./hook"
import { homepageManagementPath } from "../hook/path"

const MenuForm = ({
  title,
  setTitle,
  link,
  setLink,
  handleSave,
  icon,
  setIcon,
  setFileInput,
}: {
  menu: "mainMenu" | "highlightMenu"
  title: string
  setTitle: (name: string) => void
  link: string
  setLink: (name: string) => void
  handleSave: () => void
  icon?: string
  setIcon?: (name: string, input: any) => void
  setFileInput?: (name: string) => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  return (
    <div className="w-full max-w-screen-sm">
      <NavigationHeaderButton
        title={
          id === "create"
            ? t("home-management.main-menu.editor.title.create")
            : t("home-management.main-menu.editor.title.edit")
        }
        onClick={() => {
          navigate(homepageManagementPath())
        }}
      />
      <div className="mt-6 flex flex-col gap-y-8">
        <EuiFormRow label={t("common.name")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={title}
            maxLength={25}
            onChange={(e) => setTitle(e.target.value)}
          />
        </EuiFormRow>
        {setIcon && setFileInput && (
          <div className=" flex items-center gap-x-4">
            <ImagePicker
              value={icon}
              error={undefined}
              setLoading={setLoading}
              onChange={(input, blob) => {
                setIcon(blob, input.tmpPath)
              }}
              acceptType={contentType.SVG}
            />
            <EuiText>
              <p>{t("home-management.main-menu.highlight-icon-hint")}</p>
            </EuiText>
          </div>
        )}
        <EuiFormRow className="" label={t("common.link")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </EuiFormRow>
      </div>

      <div className="mt-10 flex flex-row">
        <EuiButton fill fullWidth onClick={handleSave} disabled={loading}>
          {t("common.save")}
        </EuiButton>
        <EuiButtonEmpty
          className="ml-4 w-full items-center"
          color="text"
          onClick={() => {
            navigate(homepageManagementPath())
          }}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </div>
  )
}

export const EditMainMenu = () => {
  const { id } = useParams<{ id: string }>()

  const formControl = useFormNavigation(id!)

  const setName = (name: string) => {
    formControl.setValues({ ...formControl.values, name })
  }

  const setLink = (link: string) => {
    formControl.setValues({ ...formControl.values, link })
  }

  return (
    <MenuForm
      menu="mainMenu"
      title={formControl.values.name || ""}
      setTitle={setName}
      link={formControl.values.link || ""}
      setLink={setLink}
      handleSave={formControl.submitForm}
    />
  )
}

export const EditHighlightMenu = () => {
  const { id } = useParams<{ id: string }>()

  const formControl = useFormHighlightNavigation(id!)

  const setName = (name: string) => {
    formControl.setValues({
      ...formControl.values,
      name,
    })
  }

  const setLink = (link: string) => {
    formControl.setValues({
      ...formControl.values,
      link,
    })
  }
  const setIcon = (icon: string, input: any) => {
    formControl.setValues({
      ...formControl.values,
      icon,
      fileInput: input,
    })
  }
  const setFileInput = (input: string) => {
    formControl.setValues({
      ...formControl.values,
      fileInput: input,
    })
  }

  return (
    <MenuForm
      menu="mainMenu"
      title={formControl.values?.name || ""}
      setTitle={setName}
      link={formControl.values?.link || ""}
      setLink={setLink}
      icon={formControl.values.icon}
      setIcon={setIcon}
      handleSave={formControl.submitForm}
      setFileInput={setFileInput}
    />
  )
}
