export const extractTextFromLexical = (
  lexicalValue: string | undefined,
): string => {
  interface Lexical {
    root: Children
  }

  interface Children {
    children: (Children | TextRoot)[]
  }

  interface TextRoot {
    type: string
    text: string
  }

  if (lexicalValue === "" || lexicalValue === undefined) {
    return ""
  }

  const jsonLexical: Lexical = JSON.parse(lexicalValue)
  const extractText: string[] = []

  const jsonToText = (jsonLexical: Children) => {
    for (const child of jsonLexical.children) {
      if ("children" in child) {
        jsonToText(child)
      } else if ("text" in child) {
        extractText.push(child.text)
      }
    }
  }

  jsonToText(jsonLexical.root)

  return extractText.join(" ")
}
