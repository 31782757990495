import dayjs from "dayjs"
import "dayjs/locale/th"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationResources from "./asset/translation/resources.json"
const buddhistEra = require("dayjs/plugin/buddhistEra")
dayjs.extend(buddhistEra)

i18n.use(initReactI18next).init({
  resources: translationResources,
  lng: "th",
  fallbackLng: "th",
  interpolation: {
    escapeValue: false,
  },
})

i18n.services.formatter?.add("datetime", (value, lng, options) => {
  return dayjs(value).locale("th").format(options?.format)
})

i18n.services.formatter?.add(
  "time-range",
  ({ startAt, endAt }, lng, options) => {
    if (!startAt) {
      return "แบบร่าง"
    }

    const start = dayjs(startAt).locale("th").format(options?.format)
    const end = endAt ? dayjs(endAt).locale("th").format(options?.format) : "∞"
    return `${start} - ${end}`
  },
)

i18n.services.formatter?.add(
  "coupon-effect",
  ({
    isDiscount,
    isByAmount,
    amountValue,
    percentageValue,
    percentageHaveMaxAmount,
    percentageMaxAmount,
  }) => {
    const unit = isDiscount ? "บาท" : "Coin"
    const value = isByAmount ? amountValue : percentageValue

    let s = `${isDiscount ? "ลด" : "เพิ่ม"} ${value || 0} ${
      isByAmount ? unit : "%"
    }`
    if (percentageHaveMaxAmount) {
      s += ` สูงสุด ${percentageMaxAmount || 0} ${unit}`
    }
    return s
  },
)

i18n.services.formatter?.add(
  "topup-package-name",
  ({ getCoin, chargeAmount }) => {
    return `${getCoin || 0} Coin / ${chargeAmount || 0} บาท`
  },
)

export default i18n
