import { useApi } from "@app/hook/api"
import { definitions as homepageDef } from "@app/vendor/web-utility-specs/web_utility_service"
import useSWR from "swr"
import { t } from "i18next"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { errToDefaultI18nKey } from "@reeeed-mp/ui-common"
import { useStore } from "@app/hook/store"
import { useHomeManagementStore } from "../store/ctx"
import { useAlertDefaultErr } from "@app/hook/error"
import { publicConfig } from "@app/config"

export type LayoutTypes =
  | homepageDef["webutility.v1.RecommendedProductLayout"]
  | homepageDef["webutility.v1.HeroBannerLayout"]
  | homepageDef["webutility.v1.PopularProductLayout"]
  | homepageDef["webutility.v1.SmallBannerLayout"]
  | homepageDef["webutility.v1.WriterBannerLayout"]
  | "MEDIUM_BANNER_LAYOUT_FIXED"
  | "MEDIUM_BANNER_LAYOUT_OVERFLOW"
  | homepageDef["webutility.v1.NewArrivalChapterProductSectionLayout"]

export enum MenuTypes {
  mainMenu = "mainMenu",
  heroBanner = "heroBanner",
  midBannerOverflow = "midBannerOverflow",
  midBannerContained = "midBannerContained",
  smallBanner = "smallBanner",
  writer = "writer",
  popularProduct = "popularProduct",
  recommendedProduct = "recommendedProduct",
  newArrivalChapterProduct = "newArrivalChapterProduct",
}

export type HomeMenuItem = homepageDef["webutility.admin.v1.PageLayout"]
type MenuOption = {
  value: MenuTypes
  text: string
  hidden?: boolean
}

export const homeMenuListOptions: MenuOption[] = Object.values(MenuTypes)
  .filter((m) => {
    if (m === MenuTypes.newArrivalChapterProduct) {
      return publicConfig.enableNovelProductTypeFlexibleHomepageManagement
    }

    return true
  })
  .map(
    (menuType): MenuOption => ({
      value: menuType,
      text: t(
        `home-management.menu-list.${menuType.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}`,
      ),
      hidden: [MenuTypes.mainMenu, MenuTypes.heroBanner].includes(menuType),
    }),
  )

export const useCreatePageSection = () => {
  const { webUtility } = useApi()
  const { notificationStore } = useStore()

  const addNewSectionApi = webUtility("/admin/page-section")
    .method("post")
    .create()

  const createNewSection = async (type: MenuTypes) => {
    const mapTypeToLayout: Partial<
      Record<MenuTypes, homepageDef["webutility.v1.PageSectionType"]>
    > = {
      mainMenu: "PAGE_SECTION_TYPE_MAIN_MENU",
      heroBanner: "PAGE_SECTION_TYPE_HERO_BANNER",
      popularProduct: "PAGE_SECTION_TYPE_POPULAR_PRODUCT",
      recommendedProduct: "PAGE_SECTION_TYPE_RECOMMENDED_PRODUCT",
      smallBanner: "PAGE_SECTION_TYPE_SMALL_BANNER",
      midBannerContained: "PAGE_SECTION_TYPE_MEDIUM_BANNER_FIXED",
      midBannerOverflow: "PAGE_SECTION_TYPE_MEDIUM_BANNER_OVERFLOW",
      writer: "PAGE_SECTION_TYPE_WRITER_BANNER",
    }

    if (publicConfig.enableNovelProductTypeFlexibleHomepageManagement) {
      mapTypeToLayout.newArrivalChapterProduct =
        "PAGE_SECTION_TYPE_NEW_ARRIVAL_CHAPTER_PRODUCT"
    }

    try {
      const resp = await addNewSectionApi({
        type: mapTypeToLayout[type],
      })

      return resp.data.sectionKey
    } catch (e: any) {
      const err = e.getActualType().data
      if (err.details[0].details[0].field === "productType") {
        notificationStore.add({
          text: t("home-management.error.product-type-is-not-supported"),
          color: "danger",
        })
      } else {
        notificationStore.add({
          text: errToDefaultI18nKey(e),
          color: "danger",
        })
      }
    }
  }

  return {
    createNewSection,
  }
}

export const useHomeMenuList = () => {
  const { webUtility } = useApi()

  const {
    data: homeMenuListData,
    isValidating,
    error,
    mutate,
  } = useSWR(
    "/flexible-home-management",
    async (): Promise<
      definitions["webutility.admin.v1.GetHomepageLayoutResponse"]
    > => {
      try {
        const res = await webUtility("/admin/homepage-layout")
          .method("get")
          .create()({})
        return res.data
      } catch (error) {
        return {
          pageLayout: {
            id: "",
            sections: [],
          },
        }
      }
    },
    {
      revalidateOnFocus: false,
    },
  )

  return { homeMenuList: homeMenuListData, error, mutate, isValidating }
}

export const useDeleteAdditionalSection = () => {
  const { webUtility } = useApi()

  const deleteApi = webUtility("/admin/section/{sectionKey}")
    .method("delete")
    .create()

  const deleteSection = async (sectionKey: string) => {
    await deleteApi({
      sectionKey,
    })
  }

  return { deleteSection }
}

export const useCommitHomeManagement = (id: string) => {
  const { webUtility } = useApi()
  const { notificationStore: notification } = useStore()
  const alertDefaultErr = useAlertDefaultErr()

  const commitApi = webUtility("/admin/homepage-layout/commit")
    .method("post")
    .create()

  const resetApi = webUtility("/admin/homepage-layout/discard")
    .method("post")
    .create()

  const commit = async () => {
    try {
      await commitApi({
        id,
      }).then(() => {
        notification.add({
          title: "Success",
          color: "success",
        })
      })
    } catch (e) {
      alertDefaultErr(e)
    }
  }

  const reset = async () => {
    try {
      await resetApi({
        id,
      })
    } catch (e) {
      alertDefaultErr(e)
    }
  }

  return { commit, reset }
}

export const useReorderAdditionalSection = () => {
  const { webUtility } = useApi()
  const { mutate } = useHomeMenuList()
  const { heroBannerSection, mainMenuSection } = useHomeManagementStore()
  const reorderApi = webUtility("/admin/page-section/reorder")
    .method("post")
    .create()

  const reorder = async (sectionKeys: string[]) => {
    await reorderApi({
      sectionKeys: [
        mainMenuSection?.sectionKey!,
        heroBannerSection?.sectionKey!,
        ...sectionKeys,
      ],
    })

    mutate()
  }

  return { reorder }
}

export type MappedAdditionalSection = {
  sectionKey: string
  count?: string
  menu: MenuTypes
  detail: { [key: string]: any }
}

export const mapAdditionalSectionToMenu = (
  section: homepageDef["webutility.admin.v1.LayoutSection"],
): MappedAdditionalSection => {
  if (section.heroBannerSection) {
    const heroBannerSection = section.heroBannerSection
    return {
      sectionKey: heroBannerSection.sectionKey!,
      menu: MenuTypes.heroBanner,
      detail: {
        ...heroBannerSection.heroBannerSection,
      },
    }
  }

  if (section.popularProductSection) {
    const popularProductSection = section.popularProductSection
    return {
      sectionKey: popularProductSection.sectionKey!,
      count:
        popularProductSection.popularProductSection?.displayOption ===
        "POPULAR_PRODUCT_SECTION_DISPLAY_OPTION_ADMIN_SELECTED"
          ? popularProductSection.productCount || "0"
          : undefined,
      menu: MenuTypes.popularProduct,
      detail: {
        ...popularProductSection.popularProductSection,
        adminSelectedProducts: popularProductSection.adminSelectedProducts,
      },
    }
  }

  if (section.recommendedProductSection) {
    const recommendedProductSection = section.recommendedProductSection
    return {
      sectionKey: recommendedProductSection.sectionKey!,
      count:
        recommendedProductSection.recommendedProductSection?.displayOption ===
        "RECOMMENDED_PRODUCT_SECTION_DISPLAY_OPTION_NEWEST"
          ? undefined
          : recommendedProductSection.productCount || "0",
      menu: MenuTypes.recommendedProduct,
      detail: {
        ...recommendedProductSection.recommendedProductSection,
        adminSelectedProducts: recommendedProductSection.adminSelectedProducts,
      },
    }
  }

  if (section.smallBannerSection) {
    const smallBannerSection = section.smallBannerSection
    return {
      sectionKey: smallBannerSection.sectionKey!,
      count: smallBannerSection.bannerCount || "0",
      menu: MenuTypes.smallBanner,
      detail: {
        ...smallBannerSection.smallBannerSection,
      },
    }
  }

  if (section.writerBannerSection) {
    const writerBannerSection = section.writerBannerSection
    return {
      sectionKey: writerBannerSection.sectionKey!,
      count: writerBannerSection.bannerCount || "0",
      menu: MenuTypes.writer,
      detail: {
        ...writerBannerSection.writerBannerSection,
      },
    }
  }

  if (section.fixedMediumBannerSection) {
    const mediumBannerSection = section.fixedMediumBannerSection
    return {
      sectionKey: mediumBannerSection.sectionKey!,
      count: mediumBannerSection.bannerCount || "0",
      menu: MenuTypes.midBannerContained,
      detail: {
        layout: "MEDIUM_BANNER_LAYOUT_FIXED",
        ...mediumBannerSection.fixedMediumBannerSection,
      },
    }
  }

  if (section.overflowMediumBannerSection) {
    const mediumBannerSection = section.overflowMediumBannerSection
    return {
      sectionKey: mediumBannerSection.sectionKey!,
      count: mediumBannerSection.bannerCount || "0",
      menu: MenuTypes.midBannerOverflow,
      detail: {
        layout: "MEDIUM_BANNER_LAYOUT_OVERFLOW",
        ...mediumBannerSection.overflowMediumBannerSection,
      },
    }
  }

  if (section.newArrivalChapterProductSection) {
    const newArrivalChapterProductSection =
      section.newArrivalChapterProductSection
    return {
      sectionKey: newArrivalChapterProductSection.sectionKey!,
      menu: MenuTypes.newArrivalChapterProduct,
      detail: {
        layout: "NEW_ARRIVAL_CHAPTER_PRODUCT_SECTION_LAYOUT_OVERFLOW",
        ...newArrivalChapterProductSection.newArrivalChapterProductSection,
      },
    }
  }

  throw new Error("Invalid section")
}

export const useSelectedMenu = (selectedId: string): MenuTypes | undefined => {
  const { homeMenuList } = useHomeManagementStore()
  const sections = homeMenuList?.sections || []

  const sectionWithKey = sections.flatMap((section) => {
    const keys: { sectionKey?: string; menu: MenuTypes }[] = []

    if (section.heroBannerSection) {
      keys.push({
        sectionKey: section.heroBannerSection.sectionKey,
        menu: MenuTypes.heroBanner,
      })
    }
    if (section.mainMenuSection) {
      keys.push({
        sectionKey: section.mainMenuSection.sectionKey,
        menu: MenuTypes.mainMenu,
      })
    }
    if (section.popularProductSection) {
      keys.push({
        sectionKey: section.popularProductSection.sectionKey,
        menu: MenuTypes.popularProduct,
      })
    }
    if (section.recommendedProductSection) {
      keys.push({
        sectionKey: section.recommendedProductSection.sectionKey,
        menu: MenuTypes.recommendedProduct,
      })
    }

    if (section.smallBannerSection) {
      keys.push({
        sectionKey: section.smallBannerSection.sectionKey,
        menu: MenuTypes.smallBanner,
      })
    }

    if (section.writerBannerSection) {
      keys.push({
        sectionKey: section.writerBannerSection.sectionKey,
        menu: MenuTypes.writer,
      })
    }

    if (section.fixedMediumBannerSection) {
      keys.push({
        sectionKey: section.fixedMediumBannerSection.sectionKey,
        menu: MenuTypes.midBannerContained,
      })
    }

    if (section.overflowMediumBannerSection) {
      keys.push({
        sectionKey: section.overflowMediumBannerSection.sectionKey,
        menu: MenuTypes.midBannerOverflow,
      })
    }

    if (section.newArrivalChapterProductSection) {
      keys.push({
        sectionKey: section.newArrivalChapterProductSection.sectionKey,
        menu: MenuTypes.newArrivalChapterProduct,
      })
    }

    return keys
  })

  return sectionWithKey.find((section) => section.sectionKey === selectedId)
    ?.menu
}

export enum ErrorSectionType {
  Title = "Title",
  Product = "Product",
}

export type ErrorSectionTypeResult = {
  key: string
  errorType: ErrorSectionType
}

export const validateProductError = async (
  homeLayout: definitions["webutility.admin.v1.PageLayout"],
  productsLoader: (
    productKeys: definitions["webutility.admin.v1.HighlightProductKey"][],
  ) => Promise<string[] | undefined>,
): Promise<ErrorSectionTypeResult[]> => {
  const keys = []

  for (const section of homeLayout.sections ?? []) {
    if (section.recommendedProductSection) {
      keys.push({
        sectionKey: section.recommendedProductSection.sectionKey,
        keys: section.recommendedProductSection.recommendedProductSection
          ?.adminSelectedProductKeys,
      })
    }

    if (section.popularProductSection) {
      keys.push({
        sectionKey: section.popularProductSection.sectionKey,
        keys: section.popularProductSection.popularProductSection
          ?.adminSelectedProductKeys,
      })
    }
  }
  const allErrorProductSections = []
  if (keys) {
    for (const key of keys) {
      const existProductIDs = await productsLoader(
        key.keys?.map((k) => {
          return {
            id: k.productId,
            type: k.productType,
          }
        }) ?? [],
      )

      if (!existProductIDs) {
        return []
      }

      const errorProductSections = key.keys?.filter(
        (k) => !existProductIDs?.includes(k.productId ?? ""),
      )

      if (errorProductSections && errorProductSections.length > 0) {
        allErrorProductSections.push({
          key: key.sectionKey ?? "",
          errorType: ErrorSectionType.Product,
        })
      }
    }
  }

  return allErrorProductSections
}

export const validateHomeLayout = async (
  homeLayout: definitions["webutility.admin.v1.PageLayout"],
  productsLoader: (
    productKeys: definitions["webutility.admin.v1.HighlightProductKey"][],
  ) => Promise<string[] | undefined>,
): Promise<ErrorSectionTypeResult[]> => {
  const mappedSections = [...(homeLayout.sections || [])]
    .slice(1)
    .map(mapAdditionalSectionToMenu)

  const sectionWithInvalidTitle = mappedSections
    .filter((section) => section.detail.title === "")
    .map((section) => {
      return {
        key: section.sectionKey,
        errorType: ErrorSectionType.Title,
      }
    })

  const errorProductSections = await validateProductError(
    homeLayout,
    productsLoader,
  )

  return [...sectionWithInvalidTitle, ...errorProductSections]
}
