import {
  COMMAND_PRIORITY_HIGH,
  DOMConversionMap,
  LexicalNode,
  ParagraphNode,
  SerializedParagraphNode,
  TabNode,
} from "lexical"

export class ExtendedParagraphNode extends ParagraphNode {
  static getType() {
    return "extended-paragraph"
  }

  static importDOM(): DOMConversionMap {
    const importers = super.importDOM()
    return {
      ...importers,
      p: (node: HTMLElement) => {
        const paragraph = node as HTMLParagraphElement

        if (isGoogleDocsIndentParagraph(paragraph)) {
          const paragraphNode = new ParagraphNode()
          paragraphNode.setIndent(parseInt(paragraph.style.marginLeft, 10) / 36)

          return {
            conversion: () => ({
              node: paragraphNode,
              after: (
                childLexicalNodes: Array<LexicalNode>,
              ): Array<LexicalNode> => {
                return [new TabNode(), ...childLexicalNodes]
              },
            }),
            priority: COMMAND_PRIORITY_HIGH,
          }
        }

        return importers.p(node)
      },
    }
  }

  exportJson(): SerializedParagraphNode {
    return { ...super.exportJSON(), type: "extended-paragraph", version: 1 }
  }
}

function isGoogleDocsIndentParagraph(node: HTMLParagraphElement): boolean {
  return node.style.textIndent === "36pt"
}
