import type { LexicalNode } from "lexical"

import { MenuOption } from "@lexical/react/LexicalNodeMenuPlugin"
import * as React from "react"

export class AutoEmbedOption extends MenuOption {
  title: string
  onSelect: (targetNode: LexicalNode | null) => void
  constructor(
    title: string,
    options: {
      onSelect: (targetNode: LexicalNode | null) => void
    },
  ) {
    super(title)
    this.title = title
    this.onSelect = options.onSelect.bind(this)
  }
}

export const AutoEmbedMenuItem: React.FC<{
  index: number
  isSelected: boolean
  onClick: () => void
  onMouseEnter: () => void
  option: AutoEmbedOption
}> = ({ index, isSelected, onClick, onMouseEnter, option }) => {
  let className = "mpe-item"
  if (isSelected) {
    className += " mpe-selected"
  }

  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <span className="mpe-text">{option.title}</span>
    </li>
  )
}
