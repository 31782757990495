import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useCallback } from "react"
import useSWR from "swr"

export const useGetSeo = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (id: string) => {
      const { data } = await webUtility("/admin/seo/{id}")
        .method("get")
        .create()({ id })
      return data.seo
    },
    [webUtility],
  )
}

export const useGetAllSeo = () => {
  const { webUtility } = useApi()

  return useSWR("/admin/seo", async () => {
    const resp = await webUtility("/admin/all-seo").method("get").create()({})

    return resp.data.seo
  })
}

export const useUpdateSeo = () => {
  const { webUtility } = useApi()

  return useCallback(
    async (seo: definitions["webutility.admin.v1.UpdateSeoRequest"]) => {
      const { data } = await webUtility("/admin/seo").method("post").create()(
        seo,
      )
      return data
    },
    [webUtility],
  )
}

export const useGetCategory = () => {
  const { shop } = useApi()

  return useSWR("/all-category", async () => {
    const resp = await shop("/all-category").method("get").create()({})

    return resp.data.categories
  })
}
