import { useStore } from "@app/hook/store"
import { EuiToast, useEuiBackgroundColorCSS, useEuiTheme } from "@elastic/eui"
import { observer } from "mobx-react"
import React from "react"

export const GlobalNotificationToasts: React.FC = observer(() => {
  const { notificationStore: store } = useStore()
  const bg = useEuiBackgroundColorCSS()
  const theme = useEuiTheme()

  return (
    <div className="fixed left-1/2 top-[58px] z-[99999] flex -translate-x-1/2 flex-col-reverse gap-4">
      {store.toasts.map((toast) => {
        const backgroundStyles = toast.color ? [bg[toast.color]] : undefined
        const textColor = toast.color
          ? theme.euiTheme.colors[toast.color]
          : undefined

        return (
          <EuiToast
            {...toast}
            key={toast.id}
            className="min-w-[320px] drop-shadow-xl"
            css={[
              backgroundStyles,
              {
                span: {
                  color: textColor,
                  textTransform: "capitalize",
                },
              },
            ]}
          >
            {toast.text}
          </EuiToast>
        )
      })}
    </div>
  )
})
