import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiText,
} from "@elastic/eui"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
type ConfirmDeleteModalProps = {
  description: string
  isDeleting?: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string
  textConfirmButton?: string
}

export const ConfirmDeleteModal = observer((props: ConfirmDeleteModalProps) => {
  const {
    description,
    onClose: onCloseModal,
    onConfirm: onPressDelete,
    isDeleting,
    title,
    textConfirmButton,
  } = props
  const { t } = useTranslation()

  return (
    <EuiModal
      className="book-category-page__modal"
      style={{ width: 575 }}
      onClose={onCloseModal}
    >
      <EuiModalHeader>
        <EuiText>
          <h2>{title ? title : t("category.confirmation-delete")}</h2>
        </EuiText>
      </EuiModalHeader>
      <EuiModalBody>
        {
          <EuiText>
            <p>{description}</p>
          </EuiText>
        }

        <div className="mt-6 flex flex-row justify-end">
          <EuiButton
            color="text"
            className="mr-4"
            onClick={onCloseModal}
            isDisabled={isDeleting}
          >
            {t("common.cancel")}
          </EuiButton>
          <EuiButton
            color="danger"
            fill
            onClick={onPressDelete}
            isLoading={isDeleting}
          >
            {textConfirmButton ? textConfirmButton : t("common.delete")}
          </EuiButton>
        </div>
      </EuiModalBody>
    </EuiModal>
  )
})
