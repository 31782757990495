import { definitions } from "@app/vendor/promotion-specs/promotion_service"
import { errUnExpectPaymentChannel } from "./errors"

export type PaymentChannel =
  | "bank"
  | "credit-card"
  | "true-wallet-online"
  | "line-pay"
  | "shopee-pay"
  | "prompt-pay"
  | "coin"

export const paymentTypeJsonToModel = (
  p: definitions["payment.v1.PaymentChannel"],
): PaymentChannel => {
  switch (p) {
    case "PAYMENT_CHANNEL_2C2P_BANK":
      return "bank"
    case "PAYMENT_CHANNEL_2C2P_CREDIT_CARD":
      return "credit-card"
    case "PAYMENT_CHANNEL_2C2P_LINE_PAY":
      return "line-pay"
    case "PAYMENT_CHANNEL_2C2P_SHOPEE_PAY":
      return "shopee-pay"
    case "PAYMENT_CHANNEL_2C2P_TRUE_WALLET_ONLINE":
      return "true-wallet-online"
    case "PAYMENT_CHANNEL_PAYSOL_PROMPT_PAY":
      return "prompt-pay"
    case "PAYMENT_CHANNEL_COIN":
      return "coin"
    default:
      throw errUnExpectPaymentChannel(p)
  }
}

export const paymentTypeModelToJson = (
  p: PaymentChannel,
): definitions["payment.v1.PaymentChannel"] => {
  switch (p) {
    case "bank":
      return "PAYMENT_CHANNEL_2C2P_BANK"
    case "credit-card":
      return "PAYMENT_CHANNEL_2C2P_CREDIT_CARD"
    case "line-pay":
      return "PAYMENT_CHANNEL_2C2P_LINE_PAY"
    case "shopee-pay":
      return "PAYMENT_CHANNEL_2C2P_SHOPEE_PAY"
    case "true-wallet-online":
      return "PAYMENT_CHANNEL_2C2P_TRUE_WALLET_ONLINE"
    case "prompt-pay":
      return "PAYMENT_CHANNEL_PAYSOL_PROMPT_PAY"
    case "coin":
      return "PAYMENT_CHANNEL_COIN"
    default:
      throw new Error("payment type to json: should not reach here")
  }
}
