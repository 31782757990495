import { useNavigate } from "react-router-dom"
import { useAdditionalSection, useHomeManagementStore } from "../store/ctx"
import { BannerConfigSection, BannerList } from "../components/banner-common"
import { useUpdateWriterBanner } from "./hook/update-detail"
import { useFetchWriterBanners } from "./hook/banner"
import { useFormSubmit, useInitializeForm } from "../hook/form"
import { writerBannerPath } from "../hook/path"
import { MenuTypes } from "../hook/model"

export const WriterBannerConfigSection = () => {
  const { selectedId } = useHomeManagementStore()
  const { activeBanner } = useFetchWriterBanners(selectedId!)
  const { sections } = useAdditionalSection()
  const selectedSection = sections.find((section) => {
    return section.sectionKey === selectedId
  })?.detail

  const form = useUpdateWriterBanner(selectedId!, selectedSection!)
  const navigate = useNavigate()

  useInitializeForm(form, selectedSection, selectedId!)

  const isSubmitting = useFormSubmit(form, selectedSection)
  return (
    <BannerConfigSection
      type={MenuTypes.writer}
      form={form}
      availableLayouts={[
        "WRITER_BANNER_LAYOUT_OVERFLOW",
        "WRITER_BANNER_LAYOUT_CONTAINED",
      ]}
      isLoading={isSubmitting}
    >
      <BannerList
        menu={MenuTypes.writer}
        activeBanner={activeBanner}
        pendingBanner={undefined}
        onClickEdit={(bannerKey) => {
          navigate(writerBannerPath(selectedId!, bannerKey))
        }}
        onClickManage={() => {
          navigate(writerBannerPath(selectedId!))
        }}
      />
    </BannerConfigSection>
  )
}
