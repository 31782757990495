import { ConfirmDeleteModal } from "@app/component/modal-confirm-delete"
import { EuiButtonEmpty, EuiPopover } from "@elastic/eui"
import { css, Interpolation, Theme } from "@emotion/react"
import {
  UilEllipsisV,
  UilLink,
  UilPen,
  UilTrash,
} from "@iconscout/react-unicons"
import { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"

export const HomeManagementPopOver = (props: {
  onPressEdit: () => void
  onPressDelete: () => void
  url: string
  css?: Interpolation<Theme>
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)

  return (
    <EuiPopover
      button={
        <div className="relative flex flex-row justify-between">
          <UilEllipsisV
            width={16}
            height={16}
            onClick={() => {
              setOpen((oldState) => !oldState)
            }}
          />
        </div>
      }
      isOpen={open}
      closePopover={() => {
        setOpen((oldState) => !oldState)
      }}
      hasArrow={false}
      anchorPosition="downRight"
      css={props.css}
    >
      <div className="flex flex-col items-start justify-start">
        <EuiPopover
          className="text-center"
          button={
            <CopyToClipboard
              text={props.url}
              onCopy={() => {
                setIsCopied(true)
              }}
            >
              <EuiButtonEmpty
                css={css`
                  width: 100%;
                  .euiButtonEmpty__content {
                    display: flex;
                    justify-content: flex-start;
                  }
                `}
                color={"text"}
              >
                <div className="flex flex-row items-center">
                  <UilLink width={16} height={16} className="mr-4" />

                  <p>{t("common.copy-url")}</p>
                </div>
              </EuiButtonEmpty>
            </CopyToClipboard>
          }
          isOpen={isCopied}
          closePopover={() => {
            setIsCopied(false)
          }}
          anchorPosition="upCenter"
        >
          {"Copied!"}
        </EuiPopover>

        <EuiButtonEmpty
          css={css`
            width: 100%;
            .euiButtonEmpty__content {
              display: flex;
              justify-content: flex-start;
            }
          `}
          onClick={props.onPressEdit}
          color={"text"}
        >
          <div className="flex flex-row items-center justify-start">
            <UilPen width={16} height={16} className="mr-4" />

            <p>{t("common.edit")}</p>
          </div>
        </EuiButtonEmpty>
        <EuiButtonEmpty
          css={css`
            width: 100%;
            .euiButtonEmpty__content {
              display: flex;
              justify-content: flex-start;
            }
          `}
          onClick={() => {
            setIsOpenModalConfirm(true)
          }}
          color={"danger"}
        >
          <div className="flex flex-row items-center justify-start">
            <UilTrash width={16} height={16} className="mr-4" />

            <p>{t("common.delete")}</p>
          </div>
        </EuiButtonEmpty>
      </div>
      {isOpenModalConfirm ? (
        <ConfirmDeleteModal
          onClose={() => {
            setIsOpenModalConfirm(false)
          }}
          onConfirm={async () => {
            setIsOpenModalConfirm(false)
            props.onPressDelete()
          }}
          textConfirmButton={t("common.confirm")}
          title={t("common.please-confirm")}
          description=""
        />
      ) : null}
    </EuiPopover>
  )
}
