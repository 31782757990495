import { Title } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { operations } from "@app/vendor/order-specs/order_service"
import { EuiFieldText } from "@elastic/eui"
import { UilSearch } from "@iconscout/react-unicons"
import { OnGetItems } from "@reeeed-mp/ui-common"
import dayjs from "dayjs"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { FreeEBookItem, useGetFreeEBookList } from "./hook"
import { useDebounce } from "use-debounce"

export const FreeEBookList = () => {
  const { t } = useTranslation()
  const ref = useRef() as TableRefImperative
  const { find, textSearch, setTextSearch } = useGetFreeEBookList()
  const [debounceTextSearch] = useDebounce(textSearch, 500)
  const navigate = useNavigate()

  const onGetItems: OnGetItems<FreeEBookItem> = async (props) => {
    let sorter: operations["AdminOrderService_ListAddFreeDigitalContentGiveaway"]["parameters"]["query"]["sorter"] =
      undefined
    if (props.sorter && props.sorter.field === "createdAt") {
      if (props.sorter.direction === "asc") {
        sorter = "ADD_FREE_DIGITAL_CONTENT_GIVEAWAY_SORTER_CREATED_AT_ASC"
      } else {
        sorter = "ADD_FREE_DIGITAL_CONTENT_GIVEAWAY_SORTER_CREATED_AT_DESC"
      }
    }

    const data = await find({
      filter: props.filter,
      opts: props.opts,
      sorter,
    })

    return {
      items:
        data?.items?.map((p) => {
          return {
            id: p.id!,
            name: p.name!,
            numberOfItemsDistributed: p.numberOfItemsDistributed!,
            createdAt: dayjs(p.createdAt!).toDate(),
          }
        }) || [],

      count: data.count!,
    }
  }

  const columns = [
    {
      field: "name",
      name: t("free-e-book.table.name"),
      width: "30%",
    },
    {
      field: "numberOfItemsDistributed",
      name: t("free-e-book.table.amount"),
    },
    {
      field: "createdAt",
      name: t("free-e-book.table.created-at"),
      render: (date: Date) => {
        return t("common.datetime", {
          value: date,
        })
      },
      sortable: true,
    },
  ]

  return (
    <div className="grid gap-y-8">
      <div className="flex w-full items-center justify-between">
        <Title
          text={t("free-e-book.title")}
          btn={{
            iconName: "plus",
            text: t("free-e-book.add-btn"),
            onClick: () => navigate("/free-e-book/create"),
          }}
        />
      </div>
      <div className="flex justify-between">
        <EuiFieldText
          icon={<UilSearch />}
          value={textSearch}
          placeholder={t("free-e-book.search-placeholder")}
          onChange={(e) => {
            setTextSearch(e.target.value)
          }}
        />
      </div>
      <Table<FreeEBookItem>
        ref={ref}
        columns={columns}
        sorter={{
          field: "createdAt",
          direction: "desc",
        }}
        swrKey="/free-e-book"
        onGetItems={onGetItems}
        isCanSelect={false}
        filter={{
          textSearch: debounceTextSearch,
        }}
        onRowClick={(item) => {
          navigate(`/free-e-book/detail/${item.id}`)
        }}
      />
    </div>
  )
}
