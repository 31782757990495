import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"

export const useCampaignCoupon = () => {
  const { webUtility } = useApi()

  const getCampaignCouponBanner = async () => {
    const res = await webUtility("/campaign-coupon-banner")
      .method("get")
      .create()({})
    return res.data
  }

  const storeCampaignCouponBanner = async (
    input: definitions["webutility.admin.v1.StoreCampaignCouponBannerRequest.CampaignCouponInput"],
  ) => {
    await webUtility("/campaign-coupon-banner").method("post").create()({
      input,
    })
  }

  return {
    storeCampaignCouponBanner,
    getCampaignCouponBanner,
  }
}
