import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { TempFile } from "@app/model/form"
import { GREY_BUTTON } from "@app/utils/color"
import {
  DropResult,
  EuiButton,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
  euiDragDropReorder,
} from "@elastic/eui"
import { css } from "@emotion/css"
import * as Unicons from "@iconscout/react-unicons"
import { FormInputImagePicker } from "@reeeed-mp/ui-common"
import React from "react"
import { useTranslation } from "react-i18next"
import { CategoryBanner } from "../model"

type CategoryBannerProps = {
  banners: CategoryBanner[]
  setBanners: (banners: CategoryBanner[]) => void
}

function UploadBannerImage({
  imageUrl,
  setImage,
}: {
  imageUrl?: string
  setImage: (tempFile: TempFile, blobImage: string) => void
}) {
  const { t } = useTranslation("translation")
  const [loading, setLoading] = React.useState(false)
  const [display, setDisplay] = React.useState<string | undefined>(undefined)
  const { uploadFile } = useUploadFile()
  const { notificationStore } = useStore()

  React.useEffect(() => {
    setDisplay(imageUrl)
  }, [imageUrl])

  return (
    <FormInputImagePicker
      disabled={loading}
      css={css`
        width: 100% !important;
        height: 120px !important;
        .euiImage {
          border-radius: 6px !important;
          width: 100% !important;
          height: 120px !important;
        }
        .euiImage__img {
          width: 100% !important;
          height: 120px !important;
          border-radius: 6px !important;
        }
      `}
      placeholder={
        <div className="flex flex-col text-center">
          <div>
            <p>{t("homepage-management.banner.banner-image")}</p>
          </div>
          <div>
            <p className="whitespace-pre-wrap text-center text-sm">
              {t("homepage-management.banner.cover-suggestion", {
                size: "1240x348px",
                type: ".jpg, .png",
              })}
            </p>
          </div>
        </div>
      }
      accept={`${contentType.JPG}, ${contentType.PNG}`}
      value={display || undefined}
      onChange={async (v: File | null) => {
        if (v) {
          try {
            setLoading(true)
            const { input, blob } = await uploadFile(v)
            setImage(input, blob)
            setDisplay(blob)
          } catch (err: any) {
            if (err && err.message) {
              notificationStore.add({
                title: "Error",
                color: "danger",
                text: err.message,
              })
            }
          } finally {
            setLoading(false)
          }
        }
      }}
    />
  )
}

export default function CategoryBannersBox({
  banners,
  setBanners,
}: CategoryBannerProps) {
  const { t } = useTranslation("translation")

  const setBannerLink =
    (idx: number) =>
    (elem: React.ChangeEvent<HTMLInputElement>): void => {
      if (!banners) return
      setBanners(
        banners.map((b, i) =>
          i === idx ? { ...b, link: elem.target.value } : b,
        ),
      )
    }

  const setBannerImage =
    (idx: number) =>
    (tempFile: TempFile, blobImage: string): void => {
      if (!banners) return
      setBanners(
        banners.map((b, i) =>
          i === idx ? { ...b, fileInput: tempFile, blobImage } : b,
        ),
      )
    }

  const newBannerFormGroup = () =>
    banners && setBanners([...banners, { id: "", imageUrl: "", link: "" }])

  const removeBannerAt = (idx: number) =>
    banners && setBanners(banners.filter((_, i) => idx !== i))

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (source && destination && banners) {
      setBanners(euiDragDropReorder(banners, source.index, destination.index))
    }
  }

  return (
    <>
      <h2 className="mt-4 font-bold">{t("category.banner.in-page")}</h2>
      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiDroppable
          droppableId="CUSTOM_HANDLE_DROPPABLE_AREA"
          className="p-0 shadow-none"
          spacing="m"
          withPanel
        >
          {banners.map((b, idx) => (
            <EuiDraggable
              spacing="m"
              key={`eui-draggable-${idx}`}
              index={idx}
              draggableId={`eui-draggable-${idx}`}
              customDragHandle={true}
              hasInteractiveChildren={true}
              className="py-4"
              css={css`
                border-bottom: 1px solid #f4f4f4;
              `}
            >
              {(provided) => (
                <EuiPanel paddingSize="xs" className="shadow-none">
                  <EuiFlexGroup alignItems="center" gutterSize="xs">
                    <EuiFlexItem grow={false}>
                      <EuiPanel
                        color="transparent"
                        paddingSize="xs"
                        {...provided.dragHandleProps}
                        aria-label="Drag Handle"
                      >
                        <EuiIcon type="grab" />
                      </EuiPanel>
                    </EuiFlexItem>

                    <EuiFlexGroup className="gap-4" direction="column">
                      <UploadBannerImage
                        key={`upload-banner-image-${idx}`}
                        imageUrl={b.imageUrl || b.blobImage}
                        setImage={setBannerImage(idx)}
                      />

                      <EuiFlexGroup className="gap-2">
                        <EuiFlexItem>
                          <EuiFieldText
                            key={`link_${idx}`}
                            controlOnly
                            placeholder={t("category.banner.input-link")}
                            onChange={setBannerLink(idx)}
                            value={b.link || ""}
                          />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            fill
                            className="bg-gray-200"
                            css={css`
                              text-decoration: none !important;
                              border: none;
                            `}
                            onClick={() => removeBannerAt(idx)}
                          >
                            <div className="flex flex-row items-center">
                              <Unicons.UilTrashAlt
                                color="black"
                                className="mr-2"
                                size="18"
                              />
                              <EuiText className="mb-0 " color="default">
                                {t("category.banner.delete")}
                              </EuiText>
                            </div>
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexGroup>
                  </EuiFlexGroup>
                </EuiPanel>
              )}
            </EuiDraggable>
          ))}
        </EuiDroppable>
      </EuiDragDropContext>
      {banners.length < 5 && (
        <EuiButton
          fill
          fullWidth
          className="mt-4"
          css={css`
            background-color: ${GREY_BUTTON} !important;
            text-decoration: none !important;
            border: none;
          `}
          onClick={newBannerFormGroup}
        >
          <div className="flex flex-row items-center">
            <Unicons.UilPlus color="black" className="mr-2" size="18" />
            <EuiText className="mb-0 " color="default">
              {t("category.banner.add-banner")}
            </EuiText>
          </div>
        </EuiButton>
      )}
    </>
  )
}
