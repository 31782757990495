import { ConfirmDeleteModal } from "@app/component"
import { Table, TableRefImperative } from "@app/component/table"
import { Title } from "@app/component/title"
import {
  StaticPage,
  useDeleteStaticPage,
  useGetStaticPages,
  useSetStaticPagePublishStatus,
} from "@app/hook/static-page"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_static_page_service"
import {
  EuiBadge,
  EuiBasicTableColumn,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiPopover,
  EuiSwitch,
} from "@elastic/eui"
import { UilEllipsisV, UilPen, UilTrash } from "@iconscout/react-unicons"
import { hooks } from "@reeeed-mp/ui-common"
import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const { useCallAsync } = hooks

type StaticPageSummaryProps = {
  group: definitions["webutility.admin.v1.StaticPageGroup"]
}

export const StaticPageSummary: React.FC<StaticPageSummaryProps> = ({
  group,
}) => {
  const getStaticPagesFn = useGetStaticPages()
  const { fn: getStaticPages, loading: loadingStaticPages } = useCallAsync(() =>
    getStaticPagesFn({ group }),
  )
  const { t } = useTranslation()
  const navigate = useNavigate()
  const setPublishStatus = useSetStaticPagePublishStatus()
  const [staticPageToDel, setStaticPageToDel] = useState<{
    id: string
    name: string
  } | null>(null)
  const ref = useRef() as TableRefImperative
  const deleteStaticPageFn = useDeleteStaticPage()
  const { fn: deleteStaticPage, loading: deletingStaticPage } =
    useCallAsync(deleteStaticPageFn)

  const columns: Array<EuiBasicTableColumn<StaticPage>> = [
    {
      field: "linkName",
      sortable: false,
      name: t("static-page.list.fields.name"),
      truncateText: true,
    },
    {
      field: "slug",
      name: t("static-page.list.fields.slug"),
    },
    {
      name: t("static-page.list.fields.status"),
      render: (row: StaticPage) => {
        const publishStatus = row.publishStatus!
        switch (publishStatus) {
          case "STATIC_PAGE_PUBLISH_STATUS_UNSPECIFIED":
            throw new Error("publish status unspecified should not happen")
          case "STATIC_PAGE_PUBLISH_STATUS_CANNOT_EDIT":
            return (
              <EuiBadge color="success">
                {t("static-page.list.values.status.show")}
              </EuiBadge>
            )
          default:
            return (
              <EuiSwitch
                label={t(
                  `static-page.list.values.status.${
                    publishStatus === "STATIC_PAGE_PUBLISH_STATUS_SHOW"
                      ? "show"
                      : "hide"
                  }`,
                )}
                aria-describedby={row.id}
                checked={publishStatus === "STATIC_PAGE_PUBLISH_STATUS_SHOW"}
                compressed
                onChange={async (e) => {
                  e.stopPropagation()
                  await setPublishStatus(row.id, e.target.checked)
                  ref.current.refetch()
                }}
              />
            )
        }
      },
    },
    {
      name: t("static-page.list.fields.created-at"),
      render: (row: StaticPage) => {
        return row.createdAt ? (
          <div>{t("common.datetime", { value: new Date(row.createdAt) })}</div>
        ) : (
          "-"
        )
      },
    },
    {
      name: t("static-page.list.fields.updated-at"),
      render: (row: StaticPage) => {
        return (
          <div>{t("common.datetime", { value: new Date(row.updatedAt!) })}</div>
        )
      },
    },
    {
      align: "right",
      width: "40px",
      isExpander: true,
      render: (row: StaticPage) => {
        const name = row.linkName!
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Action
              id={row.id}
              onEdit={(id) => navigate(`/static-page/editor/${id}`)}
              onDelete={(id) => setStaticPageToDel({ id, name })}
              cannotDelete={row.group !== "STATIC_PAGE_GROUP_CUSTOM"}
            />
          </div>
        )
      },
    },
  ]

  const getTitle = () => {
    switch (group) {
      case "STATIC_PAGE_GROUP_POLICY":
        return t("nav.static-page.policy")
      case "STATIC_PAGE_GROUP_CUSTOM":
        return t("nav.static-page.custom")
      case "STATIC_PAGE_GROUP_LOYALTY":
        return t("nav.static-page.loyalty")
      case "STATIC_PAGE_GROUP_ABOUT_US":
        return t("nav.static-page.about-us")
      default:
        return ""
    }
  }

  return (
    <>
      <Title
        text={getTitle()}
        btn={
          group === "STATIC_PAGE_GROUP_CUSTOM"
            ? {
                iconName: "plus",
                text: t("common.create"),
                onClick() {
                  navigate("/static-page/editor/create")
                },
              }
            : undefined
        }
      />
      <Table<StaticPage>
        ref={ref}
        swrKey={`/get-static-page/${group}`}
        onGetItems={getStaticPages}
        loading={loadingStaticPages}
        columns={columns}
        isCanSelect={false}
        sorter={{ field: "linkName", direction: "desc" }}
        onRowClick={(row) => navigate(`/static-page/editor/${row.id}`)}
      />
      {staticPageToDel && (
        <ConfirmDeleteModal
          isDeleting={deletingStaticPage}
          description={staticPageToDel.name}
          onClose={() => {
            setStaticPageToDel(null)
          }}
          onConfirm={async () => {
            await deleteStaticPage(staticPageToDel.id)
            ref.current.refetch()
            setStaticPageToDel(null)
          }}
        />
      )}
    </>
  )
}

type ActionProps = {
  id: string
  onDelete: (id: string) => void
  onEdit: (id: string) => void
  cannotDelete?: boolean
}

const Action: React.FC<ActionProps> = ({
  id,
  onEdit,
  onDelete,
  cannotDelete = false,
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <EuiPopover
      isOpen={open}
      closePopover={() => setOpen(false)}
      anchorPosition="leftCenter"
      button={<UilEllipsisV size={14} onClick={() => setOpen(!open)} />}
    >
      <EuiContextMenuPanel
        items={[
          <EuiContextMenuItem
            key={`edit-${id}`}
            icon={<UilPen size={16} />}
            onClick={() => {
              setOpen(false)
              onEdit(id)
            }}
          >
            {t("common.edit")}
          </EuiContextMenuItem>,
          <EuiContextMenuItem
            key={`del-${id}`}
            icon={<UilTrash size={16} />}
            className={`text-ci-red ${cannotDelete && "hidden"}`}
            onClick={() => {
              setOpen(false)
              onDelete(id)
            }}
          >
            {t("common.delete")}
          </EuiContextMenuItem>,
        ]}
      />
    </EuiPopover>
  )
}
