import { TempFile } from "@app/model/form"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { useCallback, useEffect, useRef, useState } from "react"
import { useApi } from "./api"

export enum contentType {
  EPUB = "application/epub+zip",
  PDF = "application/pdf",
  MP3 = "audio/mpeg",
  PNG = "image/png",
  JPG = "image/jpeg",
  SVG = "image/svg+xml",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export const useUploadFile = () => {
  const { authWeb: auth } = useApi()
  const getter = auth("/upload-url").method("post").create()
  const uploadFile = useCallback(
    async (
      file: File,
    ): Promise<{
      input: TempFile
      blob: string
    }> => {
      const { data: signedUrl } = await getter({
        contentType: file.type,
      })

      await fetch(signedUrl.signedUrl!, {
        method: "PUT",
        body: file,
      })

      return {
        input: {
          name: file.name,
          tmpPath: signedUrl.tmpUrl,
        },
        blob: URL.createObjectURL(file),
      }
    },
    [getter],
  )

  const uploadFiles = useCallback(
    async (
      files: File[],
    ): Promise<{
      inputs: TempFile[]
      blobs: string[]
    }> => {
      const result: {
        inputs: TempFile[]
        blobs: string[]
      } = {
        inputs: [],
        blobs: [],
      }

      await Promise.all(
        files.map(async (file) => {
          const r = await uploadFile(file)
          result.inputs.push(r.input)
          result.blobs.push(r.blob)
        }),
      )

      return result
    },
    [uploadFile],
  )

  return {
    uploadFile,
    uploadFiles,
  }
}

type DownloadReportProgress = {
  result?: definitions["foundation.report.ReportProgressResponse"]
  error?: definitions["google.rpc.Status"]
}

export type DownloadReportAPI = (
  ...args: any[]
) => Promise<ReadableStream<DownloadReportProgress>>

export const useDownloadReport = (
  fn?: DownloadReportAPI,
  opt?: { autoDownload?: boolean },
) => {
  const [loadingPercentage, setLoadingPercentage] = useState<number | null>(
    null,
  )
  const cancelDownload = useRef<null | (() => void)>(null)
  const [error, setError] = useState<any>(null)
  const [downloadURL, setDownloadURL] = useState<null | string>(null)

  useEffect(() => {
    return () => {
      cancelDownload.current?.()
    }
  }, [])

  const download = async (...args: Parameters<DownloadReportAPI>) => {
    if (!fn) {
      return
    }

    cancelDownload.current?.()
    setLoadingPercentage(0)
    setError(null)
    setDownloadURL(null)

    try {
      const r = await fn(...args).then((r) => r.getReader())
      cancelDownload.current = () => {
        r.cancel()
      }

      /* eslint-disable no-constant-condition */
      while (true) {
        const data = await r.read()

        if (data.done) {
          break
        }

        if (data.value?.error) {
          setError(data.value.error)
          break
        } else {
          setLoadingPercentage(data.value?.result?.percentageRatio || 0)
          if (data.value?.result?.downloadUrl) {
            const downloadPath = data.value.result.downloadUrl
            if (opt?.autoDownload) {
              const a = document.createElement("a")
              a.href = downloadPath
              a.click()
            }
            setDownloadURL(downloadPath)
            break
          }
        }
      }
    } finally {
      setLoadingPercentage(null)
      cancelDownload.current = null
    }
  }

  return {
    loadingPercentage,
    download,
    downloadURL,
    error,
  }
}
