import { publicConfig } from "@app/config"

export const strPriceToStrTenPowerPrice = (price: string | undefined) => {
  return price
    ? (Number(price) * publicConfig.price.tenPowerOfDecimals).toString()
    : undefined
}

export const tenPowerPriceToStrPrice = (price: string | undefined) => {
  return price
    ? (Number(price) / publicConfig.price.tenPowerOfDecimals).toString()
    : undefined
}
