import { idSeparator } from "@app/model"
import {
  EuiAccordion,
  EuiButtonEmpty,
  EuiIcon,
  EuiPanel,
  EuiText,
  EuiTitle,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { theme } from "@reeeed-mp/ui-common"
import React, { useState } from "react"

type categoryData = {
  id?: string | undefined
  name?: string | undefined
  parentId?: string | undefined
}[]

export const SeoPanel = (props: {
  key: string | undefined
  title: string | undefined
  buttonText: string
  onPressEdit: () => void
  parentId: string | undefined
  secondaryText?: string
  accordion?: boolean
  bgColor?: string
  width?: string
  categoryData?: categoryData
  children?: React.ReactNode
}) => {
  const [open, setOpen] = useState(false)
  const turnOnAccordion =
    props.categoryData?.filter(
      (category) =>
        category.id !== props.parentId &&
        category.id?.includes(idSeparator) &&
        category.id?.startsWith(props.parentId || ""),
    ).length !== 0 && props.accordion

  return (
    <div>
      <EuiPanel
        css={css({
          backgroundColor: `${props.bgColor ? props.bgColor : "#ffffff"} !important`,
          width: props.width ? props.width : "100%",
          minHeight: 125,
        })}
        className="flex h-full flex-col border border-solid border-table shadow-none"
      >
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <EuiTitle size="xs">
              <h4>{props.title}</h4>
            </EuiTitle>
            <EuiButtonEmpty
              className="h-8 w-12 underline"
              css={css({
                color: `${theme.colors.LIGHT.primaryPurple  }!important`,
              })}
              onClick={props.onPressEdit}
            >
              {props.buttonText}
            </EuiButtonEmpty>
          </div>
          <EuiText className="mb-5" size="s">
            {props.secondaryText ? (
              <p className="line-clamp-2">{props.secondaryText}</p>
            ) : null}
          </EuiText>
          {turnOnAccordion ? (
            <EuiAccordion
              id={props.parentId || ""}
              arrowDisplay="none"
              forceState={open ? "open" : "closed"}
              onToggle={setOpen}
              buttonContent={
                <div className="flex items-center justify-between gap-2">
                  <EuiText
                    size="s"
                    css={css({
                      color: `${theme.colors.LIGHT.primaryPurple  }!important`,
                    })}
                  >
                    <p className="font-medium">{`${open ? "Collapse" : "Expand"}`}</p>
                  </EuiText>
                  {open ? (
                    <EuiIcon type="arrowDown" size="m" />
                  ) : (
                    <EuiIcon type="arrowRight" size="m" />
                  )}
                </div>
              }
            >
              <div className="flex flex-col gap-2 pt-2">{props.children}</div>
            </EuiAccordion>
          ) : (
            props.children
          )}
        </div>
      </EuiPanel>
    </div>
  )
}
