import { Title } from "@app/component"
import { extractTextFromLexical } from "@app/lib/lexical"
import { EuiTitle } from "@elastic/eui"
import { t } from "i18next"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useGetAllSeo, useGetCategory } from "./hook"

import { categoryLevel } from "@app/model/category"
import { extractCategoryIdFromSeoId, seoHomepageId } from "@app/model/seo"
import { SeoPanel } from "./component/seo-panel"

export const SeoPage: React.FC = () => {
  const navigate = useNavigate()
  const { data: allSeo } = useGetAllSeo()
  const { data: categories } = useGetCategory()

  const homepageSeo =
    allSeo &&
    allSeo
      .filter((seo) => seo.id === seoHomepageId)
      .map((seo) => seo.description)[0]

  const isChildId = (
    parentId: string | undefined,
    childId: string | undefined,
  ) => {
    if (parentId === undefined || childId === undefined) {
      return false
    }
    return childId.startsWith(parentId)
  }

  return (
    <div>
      <Title text={t("nav.seo-editor")} />
      <div className="mb-8 flex flex-col gap-4">
        <EuiTitle size="s">
          <h3>{"Static Page"}</h3>
        </EuiTitle>
        <SeoPanel
          key={seoHomepageId}
          title="Homepage"
          parentId={seoHomepageId}
          secondaryText={extractTextFromLexical(homepageSeo)}
          buttonText={t("common.edit")}
          onPressEdit={() => {
            navigate("/seo-editor/homepage")
          }}
          bgColor="#fbfcfd"
          width="832px"
        />
      </div>

      <div className="flex flex-col gap-4">
        <EuiTitle size="s">
          <h3>{"Products"}</h3>
        </EuiTitle>
        {categories &&
          categories.map((categoryLevelOne) => {
            return categoryLevel(categoryLevelOne.id) === 1 ? (
              <SeoPanel //Level 1
                key={categoryLevelOne.id}
                title={categoryLevelOne.name}
                parentId={categoryLevelOne.id}
                secondaryText={extractTextFromLexical(
                  allSeo &&
                    allSeo
                      .filter(
                        (seo) =>
                          categoryLevelOne.id ===
                          extractCategoryIdFromSeoId(seo.id),
                      )
                      .map((seo) => seo.description)[0],
                )}
                buttonText={t("common.edit")}
                onPressEdit={() => {
                  navigate(`/seo-editor/${categoryLevelOne.id}`)
                }}
                width="832px"
                bgColor="#fbfcfd"
                children={categories.map((categoryLevelTwo) => {
                  return isChildId(categoryLevelOne.id, categoryLevelTwo.id) &&
                    categoryLevel(categoryLevelTwo.id) === 2 ? (
                    <SeoPanel //Level 2
                      key={categoryLevelTwo.id}
                      title={categoryLevelTwo.name}
                      parentId={categoryLevelTwo.id}
                      secondaryText={extractTextFromLexical(
                        allSeo &&
                          allSeo
                            .filter(
                              (seo) =>
                                categoryLevelTwo.id ===
                                extractCategoryIdFromSeoId(seo.id),
                            )
                            .map((seo) => seo.description)[0],
                      )}
                      buttonText={t("common.edit")}
                      onPressEdit={() => {
                        navigate(`/seo-editor/${categoryLevelTwo.id}`)
                      }}
                      categoryData={categories}
                      accordion={true}
                      children={categories.map((categoryLevelThree) => {
                        return isChildId(
                          categoryLevelTwo.id,
                          categoryLevelThree.id,
                        ) && categoryLevel(categoryLevelThree.id) === 3 ? (
                          <SeoPanel //Level 3
                            key={categoryLevelThree.id}
                            title={categoryLevelThree.name}
                            parentId={categoryLevelThree.id}
                            secondaryText={extractTextFromLexical(
                              allSeo &&
                                allSeo
                                  .filter(
                                    (seo) =>
                                      categoryLevelThree.id ===
                                      extractCategoryIdFromSeoId(seo.id),
                                  )
                                  .map((seo) => seo.description)[0],
                            )}
                            buttonText={t("common.edit")}
                            onPressEdit={() => {
                              navigate(`/seo-editor/${categoryLevelThree.id}`)
                            }}
                          />
                        ) : null
                      })}
                    />
                  ) : null
                })}
              />
            ) : null
          })}
      </div>
    </div>
  )
}
