import { EuiRadioGroup } from "@elastic/eui"
import React from "react"
import { LayoutBlockPreview } from "./layout-preview"
import { LayoutTypes } from "../hook/model"

type LayoutStyleRadioProps = {
  availableLayouts: LayoutTypes[]
  layout: LayoutTypes
  onChange: (layout: LayoutTypes) => void
}

export const LayoutStyleRadio = (props: LayoutStyleRadioProps) => {
  const availableLayouts = props.availableLayouts.map((layout) => {
    return {
      id: layout,
      label: (
        <LayoutBlockPreview
          variant={layout}
          isSelected={props.layout === layout}
        />
      ),
    }
  })

  return (
    <div>
      <EuiRadioGroup
        className="flex flex-wrap items-baseline gap-x-4"
        options={availableLayouts}
        idSelected={props.layout}
        onChange={(id) => props.onChange(id as LayoutTypes)}
        css={{
          ".euiRadio .euiRadio__circle": {
            display: "none",
          },
          ".euiRadio .euiRadio__input ~ .euiRadio__label": {
            padding: 0,
          },
        }}
      />
    </div>
  )
}
