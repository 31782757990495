import { createHeadlessEditor } from "@lexical/headless"
import { $getRoot, EditorState, LexicalNode } from "lexical"
import { $isImageNode, allNodes } from "../nodes"
import { uniq } from "lodash-es"
import { EditorBusyReason } from "../constant/error"

export const isEditorStateEmpty = (
  editorState: EditorState | undefined,
): boolean => {
  if (!editorState) {
    return true
  }

  try {
    if (typeof editorState === "object") {
      return textCountByEditorState(editorState) === 0
    }

    if (typeof editorState === "string") {
      return (
        textCountByEditorState(
          createHeadlessEditor({
            nodes: allNodes,
          }).parseEditorState(editorState),
        ) === 0
      )
    }
  } catch (e) {
    console.error("isEditorStateEmpty error:", e)
  }

  return true
}

const textCountByEditorState = (e: EditorState): number => {
  let textLength = 0
  e.read(() => {
    const root = $getRoot()
    const children = root.getChildren()
    textLength += textCountByChildren(children)
  })

  return textLength
}

const textCountByChildren = (children: Array<LexicalNode>): number => {
  let textLength = 0

  for (const child of children) {
    textLength += child.getTextContentSize()
    if (textLength > 0) {
      break
    }
  }

  return textLength
}

export const parseEditorState = (
  stringifiedEditorState: string,
): EditorState => {
  try {
    return createHeadlessEditor({
      nodes: allNodes,
    }).parseEditorState(stringifiedEditorState)
  } catch (e) {
    throw new Error("fail to parse editor state", { cause: e })
  }
}

export const stringifyEditorStateOrThrow = (
  editorState: EditorState | undefined,
): string | undefined => {
  try {
    if (typeof editorState === "object") {
      if (!isEditorStateReadyToExport(editorState)) {
        throw new Error("Editor is not ready to export")
      }

      return JSON.stringify(editorState.toJSON())
    }
  } catch (e) {
    console.error("stringifyEditorState error:", e)
    throw e
  }

  return undefined
}

export const isEditorStateReadyToExport = (
  editorState: EditorState,
): boolean => {
  return whyEditorStateIsNotReadyToExport(editorState).length === 0
}

export const whyEditorStateIsNotReadyToExport = (editorState: EditorState) => {
  let reasons: EditorBusyReason[] = []

  for (const nodeMap of editorState._nodeMap) {
    if ($isImageNode(nodeMap[1])) {
      if (nodeMap[1].isUploading) {
        reasons.push(EditorBusyReason.ImagesBeingUploaded)
      }

      if (nodeMap[1].uploadFailed) {
        reasons.push(EditorBusyReason.ImagesFailedUpload)
      }
    }
  }

  return uniq(reasons)
}
