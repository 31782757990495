import { useApi } from "@app/hook/api"
import { valueOf } from "@app/model/type"
import { definitions as adminDefinitions } from "@app/vendor/payment-specs/payment_service"
import { useState } from "react"
import useSWR from "swr"

type ListAddCoinHistoryProps = {
  sorter?: adminDefinitions["payment.admin.v1.AddCoinHistorySorter"]
  option?: { limit: number; skip: number }
}

export const useAddCoinUsers = () => {
  const { payment } = useApi()
  const addCoin = payment("/admin/add-user-coin").method("post").create()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>()
  const getAddCoinUserDetail = () => {}
  const addCoinUsers = async (props: {
    name: string
    noted?: string
    addCoinUsers: {
      userId: string
      amount: string
    }[]
    password: string
    idempotentId: string
  }) => {
    try {
      setLoading(true)
      const result = await addCoin({
        name: props.name,
        noted: props.noted,
        addCoinUsers: props.addCoinUsers,
        password: props.password,
        idempotentId: props.idempotentId,
      })

      return {
        id: result.data.id,
        errorUserIds: result.data.errorUserIds,
      }
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }
  return {
    getAddCoinDetail: getAddCoinUserDetail,
    addCoin: addCoinUsers,
    loading,
    error,
  }
}

export const useListAddCoinHistory = (params: ListAddCoinHistoryProps) => {
  const { payment } = useApi()

  return useSWR(
    ["/list-add-coin-history", params],
    async (props: [string, ListAddCoinHistoryProps]) => {
      const result = await payment("/admin/find-add-coin-history")
        .method("get")
        .create()({
        sorter: props[1].sorter,
        "option.limit": props[1].option?.limit,
        "option.skip": props[1].option?.skip,
      })
      return result.data
    },
  )
}

export const useGetAddCoinHistory = (id: string) => {
  const { payment } = useApi()

  return useSWR(["get-add-coin-history", id], async (args: string[]) => {
    const result = await payment(`/admin/add-coin-history/{id}`)
      .method("get")
      .create()({
      id: args[1],
    })
    return result.data
  })
}

type GetAddCoinHistoryUserProps = {
  historyId: string
  limit: number
  skip: number
}

export const useGetAddCoinHistoryUser = ({
  historyId,
  limit,
  skip,
}: GetAddCoinHistoryUserProps) => {
  const { webBff } = useApi()
  return useSWR(
    ["get-add-coin-history-user", historyId, limit, skip],
    async (args: valueOf<GetAddCoinHistoryUserProps>[]) => {
      const result = await webBff(`/admin/add-coin-history/{historyId}/user`)
        .method("get")
        .create()({
        historyId: args[1] as string,
        limit: args[2] as number,
        skip: args[3] as number,
      })
      return result.data
    },
  )
}
