import React from "react"
import { NavigationHeaderButton } from "@app/component"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiDatePicker,
  EuiFieldText,
  EuiFormRow,
  EuiText,
} from "@elastic/eui"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { contentType } from "@app/hook/file"
import { ImagePicker, KBToMB } from "../main-menu/image-picker"
import { HeroBannerError, useFormBanner } from "./hook"
import moment, { Moment } from "moment"
import { homepageManagementPath } from "../hook/path"

const maximumSizeInKB = 3000

export const HeroBannerEditorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const formControl = useFormBanner(id!)

  return (
    <div className="w-full max-w-screen-md">
      <NavigationHeaderButton
        title={t(
          id === "create"
            ? "home-management.hero-banner.editor.title.create"
            : "home-management.hero-banner.editor.title.edit",
        )}
        onClick={() => {
          navigate(-1)
        }}
      />
      <div className="mt-6 flex flex-col gap-y-8">
        <EuiFormRow label={t("common.name")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.title}
            maxLength={25}
            onChange={(e) => formControl.setFieldValue("title", e.target.value)}
            isInvalid={!!formControl.errors.title}
          />
        </EuiFormRow>
        <div className=" flex items-center gap-x-4">
          <ImagePicker
            value={formControl.values.cover}
            error={formControl.errors.cover}
            setLoading={setLoading}
            onChange={(input, blob) => {
              formControl.setValues({
                ...formControl.values,
                cover: blob,
                fileInput: input,
              })
            }}
            maxFileSizeInKB={maximumSizeInKB}
            acceptType={`${contentType.JPG},${contentType.PNG}`}
            placeholder={
              <div className="flex flex-col text-center">
                <div>
                  <p>{t("home-management.hero-banner.editor.banner")}</p>
                </div>
                <div>
                  <p className="whitespace-pre-wrap text-center text-sm">
                    {t("home-management.cover-suggestion-with-maximum-size", {
                      size: "1844x880 px",
                      type: ".jpg, .png",
                      maximumSize: KBToMB(maximumSizeInKB),
                    })}
                  </p>
                </div>
              </div>
            }
          />
        </div>
        <EuiFormRow className="" label={t("common.link")}>
          <>
            <EuiFieldText
              placeholder={t("common.please-fill")}
              value={formControl.values.link}
              onChange={(e) =>
                formControl.setFieldValue("link", e.target.value)
              }
              isInvalid={!!formControl.errors.link}
            />
            {!!formControl.errors.link &&
              formControl.errors.link === HeroBannerError.url && (
                <p className="mt-2 text-sm text-ci-red">
                  {t(`home-management.error.${formControl.errors.link}`)}
                </p>
              )}
          </>
        </EuiFormRow>
        <EuiFormRow className="" label={t("Meta Tag")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.metaTag}
            onChange={(e) =>
              formControl.setFieldValue("metaTag", e.target.value)
            }
            isInvalid={!!formControl.errors.metaTag}
          />
        </EuiFormRow>
        <EuiFormRow label={t("ระยะเวลา")}>
          <div className="flex w-full items-center gap-x-4">
            <EuiDatePicker
              showTimeSelect
              selected={
                formControl.values.startAt
                  ? moment(formControl.values.startAt)
                  : undefined
              }
              minDate={moment()}
              minTime={moment().startOf("day")}
              maxTime={moment().endOf("day")}
              onChange={(date: Moment) => {
                formControl.setFieldValue("startAt", date.toDate())
              }}
              isInvalid={!!formControl.errors.startAt}
              timeIntervals={10}
            />
            <EuiText size="s">
              <span>{t("common.to")}</span>
            </EuiText>
            <EuiDatePicker
              showTimeSelect
              selected={
                formControl.values.endAt
                  ? moment(formControl.values.endAt)
                  : undefined
              }
              minDate={moment()}
              minTime={moment().startOf("day")}
              maxTime={moment().endOf("day")}
              onChange={(date: Moment) => {
                formControl.setFieldValue("endAt", date.toDate())
              }}
              isInvalid={!!formControl.errors.endAt}
              timeIntervals={10}
            />
          </div>
        </EuiFormRow>
      </div>

      <div className="mt-10 flex flex-row">
        <EuiButton
          fill
          fullWidth
          onClick={formControl.submitForm}
          isLoading={loading || formControl.isSubmitting}
        >
          {t("common.save")}
        </EuiButton>
        <EuiButtonEmpty
          className="ml-4 w-full items-center"
          color="text"
          onClick={() => {
            navigate(homepageManagementPath())
          }}
          disabled={loading || formControl.isSubmitting}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </div>
  )
}
