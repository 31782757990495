import { useApi } from "@app/hook/api"
import { mappingShopProductTypeToProductTypeInput } from "@app/model/product-table"
import { definitions } from "@app/vendor/order-specs/order_service"
import { definitions as webBffDefinitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { OnGetItems } from "@reeeed-mp/ui-common"
import { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

export type Filter = {
  userId?: string | undefined | null
  opts?: definitions["foundation.finder.PaginationOption"]
}

export type Item = {
  id: string
  userId: string | undefined
  userName: string | undefined
  productName: string | undefined
  productType: string | undefined
  expiredAt: Date
  createdAt: Date
}

export const useListPrivilege = () => {
  const [params] = useSearchParams()
  const searchUserIdParamKey = "userId"
  const [selectedItems, setSelectedItems] = useState<Item[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<Filter>({
    userId: params.get(searchUserIdParamKey)
      ? params.get(searchUserIdParamKey)
      : undefined,
  })

  const getList = useFindPrivilege()
  const onGetItems: OnGetItems<Item> = async ({ filter, opts }) => {
    setLoading(true)

    const filterQuery = {
      opts,
    } as Filter

    if (filter && filter.userId) {
      filterQuery.userId = filter.userId
    }

    const list = await getList(filterQuery)
    setLoading(false)

    return {
      count: Number(list?.count || 0),
      items:
        list?.privilege?.map(
          (
            p: webBffDefinitions["webbff.admin.v1.FindPrivilegesResponse.Privilege"],
          ): Item => {
            return {
              id: p.privilege?.id || "",
              userId: p.user?.id || "",
              userName: p.user?.username,
              productName: p.product?.eBookAudio
                ? p.product.eBookAudio.name
                : p.product?.novel
                  ? p.product.novel.name
                  : "",
              productType: mappingShopProductTypeToProductTypeInput(
                p.privilege?.productType!,
              ),
              expiredAt: new Date(p.privilege?.expiresAt!),
              createdAt: new Date(p.privilege?.createdAt!),
            }
          },
        ) || [],
    }
  }

  return {
    loading,
    setLoading,
    onGetItems,
    selectedItems,
    setSelectedItems,
    filter,
    setFilter,
  }
}

export const useDeletePrivilege = () => {
  const { order } = useApi()
  return useCallback(
    async (id: string) => {
      const { data } = await order("/privilege/{id}").method("delete").create()(
        {
          id,
        },
      )
      return data
    },
    [order],
  )
}

export const useAddPrivileges = () => {
  const { order } = useApi()
  return useCallback(
    async (
      privileges: definitions["order.admin.v1.AddPrivilegeRequest.AddPrivilege"][],
      passwordAdminAccess: string,
    ) => {
      const { data } = await order("/privilege").method("post").create()({
        privileges,
        passwordAdminAccess,
      })
      return data
    },
    [order],
  )
}

export const useGetManyUser = () => {
  const { authAdmin } = useApi()
  return useCallback(
    async (userIds: string[]) => {
      const { data } = await authAdmin("/users").method("get").create()({
        userIds,
      })
      return data.users
    },
    [authAdmin],
  )
}

const useFindPrivilege = () => {
  const { webBff } = useApi()
  const list = useMemo(
    () => webBff("/privilege").method("get").create(),
    [webBff],
  )

  return useCallback(
    async (
      input: Filter,
    ): Promise<
      webBffDefinitions["webbff.admin.v1.FindPrivilegesResponse"] | undefined
    > => {
      const { data } = await list({
        userId: input.userId ? input.userId : undefined,
        "option.limit": input.opts?.limit,
        "option.skip": input.opts?.skip,
      })
      return data
    },
    [list],
  )
}
