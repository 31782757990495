import { TextEditor } from "@app/component"
import { useApi } from "@app/hook/api"
import { useUnMount } from "@app/hook/life-cycle"
import { useStore } from "@app/hook/store"
import { definitions } from "@app/vendor/admin-auth-specs/admin_auth_service"
import { EuiButton, EuiDescriptionList, EuiText } from "@elastic/eui"
import {
  EditorState,
  ToolbarPlugins,
  isEditorStateReadyToExport,
  parseEditorState,
  stringifyEditorStateOrThrow,
  whyEditorStateIsNotReadyToExport,
} from "@reeeed-mp/text-editor"
import {
  appErrorToDefaultI18nKey,
  errToAppErr,
  hooks,
} from "@reeeed-mp/ui-common"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"

export const ConsentEditorPage: React.FC<{
  consentType: definitions["auth.v1.ConsentType"]
}> = ({ consentType }) => {
  const { t } = useTranslation()
  const { notificationStore } = useStore()
  const { authAdmin } = useApi()
  const [consent, setConsent] = React.useState<
    definitions["auth.v1.Consent"] | undefined
  >(undefined)
  const [content, setContent] = React.useState<EditorState | undefined>(
    undefined,
  )
  const { isDirty, setIsDirty } = hooks.useConfirmLeaveWithoutSaved(
    t("common.confirm-not-save-data"),
  )

  const getConsent = useCallback(
    async (consentType: definitions["auth.v1.ConsentType"]) => {
      try {
        setIsDirty(false)
        setConsent(undefined)
        setContent(undefined)

        const data = await authAdmin("/consent").method("get").create()({
          consentType,
        })

        if (data?.data?.consent) {
          setConsent(data.data.consent)
          setContent(parseEditorState(data.data.consent.content!))
        }
      } catch (error) {
        const e = errToAppErr(error)
        const i18nKey = appErrorToDefaultI18nKey(e)
        notificationStore.add({
          color: "danger",
          title: t("common.noti.error"),
          text: t(i18nKey),
        })
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authAdmin,
      notificationStore,
      setIsDirty,
      setConsent,
      setContent,
      appErrorToDefaultI18nKey,
      errToAppErr,
    ],
  )

  useEffect(() => {
    getConsent(consentType)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentType])

  useUnMount(async () => {
    await setIsDirty(false)
  })

  const { fn: save, loading } = hooks.useCallAsync(async () => {
    if (!content) {
      return
    }

    if (!isEditorStateReadyToExport(content)) {
      const reasons = whyEditorStateIsNotReadyToExport(content)
      for (const reason of reasons) {
        notificationStore.add({
          color: "warning",
          text: t(`editor.warning.${reason}`),
        })
      }
    }

    try {
      await authAdmin("/consent").method("post").create()({
        consentType,
        content: stringifyEditorStateOrThrow(content),
      })

      getConsent(consentType)
      await setIsDirty(false)
      notificationStore.add({
        color: "success",
        title: t("common.noti.success"),
        text: t("common.noti.saved"),
      })
    } catch (error: unknown) {
      const e = errToAppErr(error)
      const i18nKey = appErrorToDefaultI18nKey(e)
      notificationStore.add({
        color: "danger",
        title: t("common.noti.error"),
        text: t(i18nKey),
      })
    }
  })

  return (
    <div className="flex max-w-screen-xl flex-col gap-4">
      <EuiText>
        <h2>{t(consentType)}</h2>
      </EuiText>
      <EuiDescriptionList
        compressed
        type="column"
        className="w-fit"
        listItems={[
          {
            title: t("common.version"),
            description: consent?.version?.toString() || "-",
          },
          {
            title: t("consent.updated-at"),
            description: t("common.datetime", {
              value: consent?.createdAt,
            }),
          },
          {
            title: t("consent.updated-by"),
            description: consent?.createdBy || "-",
          },
        ]}
      />

      <TextEditor
        onChange={async (e) => {
          setContent(e)

          if (!isDirty) {
            await setIsDirty(true)
          }
        }}
        value={content}
        plugins={[
          ToolbarPlugins.UndoRedo,
          ToolbarPlugins.BlockType,
          ToolbarPlugins.RichText,
          ToolbarPlugins.Alignment,
          ToolbarPlugins.ImageUrl,
          ToolbarPlugins.ImageUpload,
          ToolbarPlugins.Youtube,
        ]}
      />

      <EuiButton onClick={save} isLoading={loading}>
        {t("common.save")}
      </EuiButton>
    </div>
  )
}
