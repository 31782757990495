export type Price = number

export function priceToString(p: Price, digit = 2): string {
  return (p / 1e6).toFixed(digit)
}

export function stringToPrice(s: string, opt?: { noDigit?: boolean }): Price {
  const sNum = Number(s)
  if (isNaN(sNum)) {
    return 0
  }
  if (opt?.noDigit) {
    return Math.floor(sNum) * 1e6
  }
  return Math.floor(sNum * 1e2) * 1e4
}

export function priceStrToNum(s?: string): number {
  if (!s) {
    return 0
  }
  const sNum = Number(s)
  if (isNaN(sNum)) {
    return 0
  }
  return sNum / 1e6
}
