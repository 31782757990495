import {
  EuiButton,
  EuiDatePicker,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiRadioGroup,
  EuiSelect,
  EuiText,
} from "@elastic/eui"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import moment, { Moment } from "moment"
import { css } from "@emotion/css"
type Props = {
  onClose: () => void
  onConfirm: (res: Result) => void
}

type SummaryOrderSortType = "sortByBuyDate" | "sortBySuccessDate"

type Result = {
  startDate: Moment
  endDate: Moment
  downloadPreOrderOnly: boolean
  downloadSummaryOrder: boolean
  summaryOrderSortType?: SummaryOrderSortType
}
const dateRange = 30

export const DatePickerModal = observer((props: Props) => {
  const { onClose, onConfirm } = props
  const { t } = useTranslation()
  const [downloadPreOrderOnly, setDownloadPreOrderOnly] = useState(false)
  const [downloadSummaryOrder, setDownloadSummaryOrder] = useState(false)
  const [summaryOrderSortType, setSummaryOrderSortType] =
    useState<SummaryOrderSortType>("sortByBuyDate")
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined)
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined)
  const [minDateFrom, setMinDateFrom] = useState<Moment | undefined>(undefined)
  const [maxDateFrom, setMaxDateFrom] = useState<Moment | undefined>(undefined)
  const [minDateTo, setMinDateTo] = useState<Moment | undefined>(undefined)
  const [maxDateTo, setMaxDateTo] = useState<Moment | undefined>(undefined)

  return (
    <EuiModal className="book-category-page__modal" onClose={onClose}>
      <EuiModalHeader>
        <EuiText>
          <h2>
            {t("order-management.list-page.download-order-management-title")}
          </h2>
        </EuiText>
      </EuiModalHeader>
      <EuiModalBody>
        <div className="flex flex-col gap-y-4">
          <div className="flex w-full flex-row items-center justify-between gap-x-2">
            <div className="flex flex-col gap-y-1">
              <EuiText>
                <p>{t("common.start")}</p>
              </EuiText>
              <EuiFormRow className="my-0 ">
                <EuiDatePicker
                  selected={startDate}
                  minDate={minDateFrom}
                  maxDate={maxDateFrom}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date)
                      setMinDateTo(date)
                      setMaxDateTo(moment(date).add(dateRange, "d"))
                    }
                  }}
                />
              </EuiFormRow>
            </div>
            <div className="flex flex-col gap-y-1">
              <EuiText>
                <p>{t("common.end")}</p>
              </EuiText>
              <EuiFormRow className="my-0">
                <EuiDatePicker
                  selected={endDate}
                  minDate={minDateTo}
                  maxDate={maxDateTo}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date)
                      setMinDateFrom(moment(date).add(-dateRange, "d"))
                      setMaxDateFrom(date)
                    }
                  }}
                />
              </EuiFormRow>
            </div>
          </div>
          <EuiSelect
            fullWidth
            prepend={t("order-management.list-page.filter-header")}
            options={[
              {
                value: "all",
                text: t("order-management.list-page.detailed-order"),
              },
              {
                value: "summary",
                text: t("order-management.list-page.summary-order"),
              },
              {
                value: "preorder",
                text: t("order-management.list-page.filter-pre-order"),
              },
            ]}
            onChange={(e) => {
              setDownloadPreOrderOnly(e.target.value === "preorder")
              setDownloadSummaryOrder(e.target.value === "summary")
            }}
          />
          {downloadSummaryOrder ? (
            <EuiRadioGroup
              className="flex gap-4"
              css={css`
                .euiRadioGroup__item + .euiRadioGroup__item {
                  margin-top: 0px;
                  margin-left: 8px;
                }
              `}
              options={[
                {
                  id: "sortByBuyDate",
                  label: t("order-management.list-page.buy-date"),
                },
                {
                  id: "sortBySuccessDate",
                  label: t("order-management.list-page.updated-date"),
                },
              ]}
              idSelected={
                summaryOrderSortType == "sortByBuyDate"
                  ? "sortByBuyDate"
                  : "sortBySuccessDate"
              }
              onChange={(e) => {
                setSummaryOrderSortType(e as SummaryOrderSortType)
              }}
            />
          ) : null}
        </div>
        <div className="mt-6 flex flex-row justify-end gap-x-4">
          <EuiButton
            color="primary"
            fill
            disabled={startDate === undefined || endDate === undefined}
            onClick={() => {
              onConfirm({
                startDate: startDate!,
                endDate: endDate!,
                downloadPreOrderOnly,
                downloadSummaryOrder,
                summaryOrderSortType,
              })
            }}
          >
            {t("common.download")}
          </EuiButton>
          <EuiButton color="text" onClick={onClose}>
            {t("common.cancel")}
          </EuiButton>
        </div>
      </EuiModalBody>
    </EuiModal>
  )
})
