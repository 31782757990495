import React from "react"
import "./image-component.css"

export const OverlayUploadingImage: React.FC = () => {
  return (
    <div className="mpe-image-overlay mpe-bg-transparent-white">
      <div className="mpe-image-overlay-inner">
        <div className="mpe-loader" />
        <p>Uploading</p>
      </div>
    </div>
  )
}

export const OverlayFailedToUploadImage: React.FC = () => {
  return (
    <div className="mpe-image-overlay mpe-bg-transparent-red">
      <div className="mpe-image-overlay-inner mpe-font-white">
        <p>Upload failed</p>
      </div>
    </div>
  )
}
