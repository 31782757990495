import React from "react"
import { useTranslation } from "react-i18next"
import { CodeStatus } from "../hook/code-detail"
import { theme } from "@reeeed-mp/ui-common"
import { EuiBadge } from "@elastic/eui"

export const CodeStatusBadge = ({ status }: { status: CodeStatus }) => {
  const { t } = useTranslation()
  if (!status) {
    return null
  }

  let text = ""
  let color = ""
  if (status === CodeStatus.active) {
    color = theme.colors.LIGHT.secondaryYellow
    text = t("code-redemption.status.active")
  } else if (status === CodeStatus.inactive) {
    color = theme.colors.LIGHT.lightGrey
    text = t("code-redemption.status.inactive")
  } else if (status === CodeStatus.expired) {
    color = theme.colors.LIGHT.secondaryRed
    text = t("code-redemption.status.expired")
  } else {
    color = theme.colors.LIGHT.secondaryGreen
    text = t("code-redemption.status.used")
  }
  return <EuiBadge color={color}>{text}</EuiBadge>
}
