import { LIGHTEST_GREY } from "@app/utils/color"
import { EuiAvatar, EuiCard } from "@elastic/eui"
import { css, SerializedStyles } from "@emotion/react"
import { UilGripHorizontalLine } from "@iconscout/react-unicons"
import { HomeManagementPopOver } from "../pop-over"
import React from "react"

type Props = {
  id: string
  title: string
  onPressDelete: () => void
  onPressEdit: () => void
  editMode?: boolean
  url?: string
  imageUrl?: string
  description?: string
  cardCss?: SerializedStyles
  imageCss?: SerializedStyles
  isAvatar?: boolean
  titleCss?: SerializedStyles
}
export const HomeManagementCard: React.FC<Props> = (props: Props) => {
  return (
    <>
      <EuiCard
        title={
          <h2
            className="line-clamp-1"
            css={css(
              `
              font-size: 14px !important;
              padding: 0px !important;
              margin: 0px !important;
            `,
              props.titleCss,
            )}
          >
            {props.title}
          </h2>
        }
        icon={
          <HomeManagementPopOver
            url={props.url ?? ""}
            onPressDelete={props.onPressDelete}
            onPressEdit={props.onPressEdit}
            css={css`
              left: calc(100%) !important;
              top: 16px !important;
            `}
          />
        }
        className="  relative border border-solid border-grey-light"
        css={css(
          props.cardCss
            ? props.cardCss
            : `
                width: 256px;
                height: 262px;
              `,
          `      box-shadow: none !important;
                .euiCard__description{
                  text-align: left; 
                  max-width: 255px;  
                }
               
                .euiCard__content {
                  margin-top: 0px !important;
                }
              `,
        )}
        image={
          <div className="mt-4 flex justify-center">
            {props.imageUrl ? (
              props.isAvatar ? (
                <EuiAvatar
                  css={css`
                    width: 64px;
                    height: 64px;
                  `}
                  color="plain"
                  name={""}
                  imageUrl={props.imageUrl}
                />
              ) : (
                <img
                  css={css(
                    `
                    width: 90px !important;
                    height: 180px;
                    margin-bottom: 16px !important;
                  `,
                    props.imageCss ? props.imageCss : "",
                  )}
                  alt={props.description ?? ""}
                  src={props.imageUrl}
                />
              )
            ) : (
              <div
                css={css`
                  width: 60px;
                  height: 60px;
                `}
              />
            )}
          </div>
        }
        description={
          props.description ? (
            <p
              className="line-clamp-2"
              css={css({
                color: LIGHTEST_GREY,
                fontSize: 12,
              })}
            >
              {props.description}
            </p>
          ) : (
            ""
          )
        }
      >
        {props.editMode ? (
          <UilGripHorizontalLine
            css={css`
              position: absolute;
              bottom: calc(100% - 36px) !important;

              left: 16px !important;
            `}
          />
        ) : null}
      </EuiCard>
    </>
  )
}
