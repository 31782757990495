import {
  DraggableProvidedDragHandleProps,
  EuiButtonIcon,
  EuiIcon,
  EuiPanel,
  EuiText,
} from "@elastic/eui"
import { UilExclamation, UilTrash } from "@iconscout/react-unicons"
import classNames from "classnames"
import React from "react"
import { LayoutBlockPreview } from "./layout-preview"
import { homeMenuListOptions, LayoutTypes, MenuTypes } from "../hook/model"
import { useTranslation } from "react-i18next"

export const CardBlock = (props: {
  dragHandleProps?: DraggableProvidedDragHandleProps
  draggable?: boolean
  onSelect?: () => void
  isSelected?: boolean
  onRemove?: () => void
  layout?: LayoutTypes
  className?: string
  name?: string
  count?: string
  menu: MenuTypes | ""
  hasError?: boolean
}) => {
  const { t } = useTranslation()
  const cardMenu = homeMenuListOptions.find((menu) => menu.value === props.menu)
  const unit =
    cardMenu?.value === "mainMenu"
      ? t("home-management.unit.menu")
      : cardMenu?.value === "recommendedProduct" ||
          cardMenu?.value === "popularProduct"
        ? t("home-management.unit.item")
        : t("home-management.unit.banner")

  return (
    <div
      onClick={props.onSelect}
      className={classNames(
        "relative flex h-24 items-center justify-between self-auto rounded-md border border-solid px-4 py-2",
        props.className,
        props.hasError && "border-2 border-ci-red",
        props.isSelected
          ? "border-2 border-ci-primary !bg-ci-primary-ex-light"
          : "!border-table !bg-grey-lightest",
      )}
    >
      {props.hasError && (
        <UilExclamation
          size="14"
          className="absolute -left-1.5 -top-1.5 rounded-full bg-ci-red text-white"
        />
      )}

      <div className="flex items-center gap-4">
        {props.draggable && (
          <EuiPanel
            color="transparent"
            paddingSize="none"
            {...props.dragHandleProps}
            aria-label="Drag Handle"
          >
            <EuiIcon type="grab" />
          </EuiPanel>
        )}
        <div>
          <EuiText size="m" className="font-bold">
            <span>{props.name || cardMenu?.text}</span>
          </EuiText>
          <EuiText size="xs">
            <p>
              {props.menu && cardMenu?.text && (
                <>
                  {cardMenu.text}
                  {props.count && (
                    <>
                      {" "}
                      | {props.count} {unit}
                    </>
                  )}
                </>
              )}
            </p>
          </EuiText>
        </div>
      </div>
      <div className="flex items-center gap-4">
        {props.layout && <LayoutBlockPreview variant={props.layout} />}
        {props.onRemove && (
          <EuiButtonIcon
            iconType={UilTrash}
            onClick={(e: any) => {
              e.stopPropagation()
              props.onRemove?.()
            }}
            aria-label="Delete Section"
          />
        )}
      </div>
    </div>
  )
}
