import { SmallModal } from "@app/component/modal/small"
import { useStreamApi } from "@app/hook/api"
import { useAlertIfDefaultErr } from "@app/hook/error"
import { useDownloadReport } from "@app/hook/file"
import { definitions } from "@app/vendor/order-specs/order_service"
import { EuiButton, EuiDatePicker, EuiProgress, EuiText } from "@elastic/eui"
import moment, { Moment } from "moment"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

const dateRange = 30

type ShopCouponRedemptionReportDownloadModalProps = {
  open: boolean
  onClose: () => void
  campaignId?: string
  doDownloadFile: (params: {
    filter: definitions["order.v1.ShopCouponRedemptionReportFilter"]
  }) => Promise<void>
  loadingPercentage: number | null
  error: any
}

export const CodeRedemptionReportButton = () => {
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation()
  const { webBFF } = useStreamApi()
  const {
    download: downloadReport,
    loadingPercentage,
    error: downloadReportError,
  } = useDownloadReport(
    webBFF("/admin/report/shop-coupon-redemption").method("post").create(),
    {
      autoDownload: true,
    },
  )

  return (
    <>
      <CodeRedemptionReportModal
        onClose={() => setOpenModal(false)}
        open={openModal}
        doDownloadFile={downloadReport}
        loadingPercentage={loadingPercentage}
        error={downloadReportError}
      />

      <EuiButton
        onClick={() => {
          setOpenModal(true)
        }}
        minWidth={285}
        fill
      >
        {t("code-redemption.download-report-btn")}
      </EuiButton>
    </>
  )
}

export const CodeRedemptionReportModal: React.FC<
  ShopCouponRedemptionReportDownloadModalProps
> = ({
  open,
  onClose,
  campaignId,
  doDownloadFile,
  loadingPercentage,
  error: downloadReportError,
}) => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState<Moment | undefined>(undefined)
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined)
  const [minDateFrom, setMinDateFrom] = useState<Moment | undefined>(undefined)
  const [maxDateFrom, setMaxDateFrom] = useState<Moment | undefined>(undefined)
  const [minDateTo, setMinDateTo] = useState<Moment | undefined>(undefined)
  const [maxDateTo, setMaxDateTo] = useState<Moment | undefined>(undefined)

  const clearOrderReportDownloadState = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setMinDateFrom(undefined)
    setMaxDateFrom(undefined)
    setMinDateTo(undefined)
    setMaxDateTo(undefined)
  }

  useAlertIfDefaultErr([downloadReportError])

  return (
    <SmallModal
      loading={loadingPercentage != null}
      css={{ zIndex: 1000 }}
      className="w-full max-w-screen-sm"
      onConfirm={async () => {
        if (startDate && endDate) {
          await doDownloadFile({
            filter: {
              campaignId,
            },
          }).finally(() => {
            clearOrderReportDownloadState()
            onClose()
          })
        }
      }}
      confirmBtnText={t("code-redemption.report.download")}
      onCancel={() => {
        onClose()
        setStartDate(undefined)
        setEndDate(undefined)
      }}
      cancelBtnText={t("code-redemption.report.cancel")}
      header={t("code-redemption.report.header")}
      body={
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-y-1">
              <EuiText>
                <p>{t("code-redemption.report.from")}</p>
              </EuiText>
              <EuiDatePicker
                preventOpenOnFocus
                selected={startDate}
                minDate={minDateFrom}
                maxDate={maxDateFrom}
                onChange={(date) => {
                  if (date) {
                    setStartDate(date)
                    setMinDateTo(date)
                    setMaxDateTo(moment(date).add(dateRange, "d"))
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <EuiText>
                <p>{t("code-redemption.report.to")}</p>
              </EuiText>
              <EuiDatePicker
                preventOpenOnFocus
                selected={endDate}
                minDate={minDateTo}
                maxDate={maxDateTo}
                onChange={(date) => {
                  if (date) {
                    setEndDate(date)
                    setMinDateFrom(moment(date).add(-dateRange, "d"))
                    setMaxDateFrom(date)
                  }
                }}
              />
            </div>
          </div>
          {loadingPercentage != null && (
            <EuiProgress value={loadingPercentage} max={100} />
          )}
        </div>
      }
      isOpen={open}
    />
  )
}
