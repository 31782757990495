import { Title } from "@app/component"
import { Table } from "@app/component/table"
import { useApi } from "@app/hook/api"
import { priceStrToNum } from "@app/model"
import { definitions as authDefinitions } from "@app/vendor/admin-auth-specs/admin_auth_service"
import { definitions } from "@app/vendor/payment-specs/payment_service"
import {
  EuiBadge,
  EuiBasicTableColumn,
  EuiFieldText,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilSearch } from "@iconscout/react-unicons"
import { OnGetItems, theme } from "@reeeed-mp/ui-common"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { useGetPaymentChannelText } from "./util"

type Topup = {
  id: string
  username: string
  amount: number
  price: number
  status: definitions["payment.admin.v1.TopupStatus"]
  channel: definitions["payment.v1.PaymentChannel"]
  createdAt: string
}

type TopupStatus = definitions["payment.admin.v1.TopupStatus"]

export const TopupHistoryList = () => {
  const { t } = useTranslation()
  const { webBff } = useApi()
  const navigate = useNavigate()
  const finder = webBff("/admin/topup").method("get").create()
  const [searchParams] = useSearchParams()
  const searchUsernameParamKey = "username"
  const statusParamKey = "status"
  const searchUsernameParam = searchParams.get(searchUsernameParamKey)
  const statusParam = searchParams.get(statusParamKey) as TopupStatus
  const defaultStatus = "TOPUP_STATUS_UNSPECIFIED"
  const [status, setStatus] = useState<TopupStatus>(
    statusParam || defaultStatus,
  )
  const [searchUsername, setSearchUsername] = useState(
    searchUsernameParam || "",
  )
  const [debouncedSearchUsername] = useDebounce(searchUsername, 500)
  const getPaymentChannel = useGetPaymentChannelText()

  const inTransform = (
    input: definitions["payment.admin.v1.Topup"],
    users: authDefinitions["auth.v1.User"][],
  ): Topup => {
    const username = users.find((user) => user.id === input.userId)?.username
    return {
      id: input.id ?? "",
      username: username ?? "",
      amount: parseInt(input.getCoin ?? "0"),
      price: parseInt(input.chargeAmount ?? "0"),
      status:
        input.status !== undefined ? input.status : "TOPUP_STATUS_UNSPECIFIED",
      channel: input.channel || "PAYMENT_CHANNEL_UNSPECIFIED",
      createdAt: input.createdAt ?? new Date().toISOString(),
    }
  }

  const columns: EuiBasicTableColumn<Topup>[] = [
    {
      field: "id",
      name: t("topup-history.list.table.id"),
    },
    {
      field: "username",
      name: t("topup-history.list.table.username"),
    },
    {
      field: "channel",
      name: t("topup-history.list.table.channel"),
      render: (value: definitions["payment.v1.PaymentChannel"]) => {
        return (
          <div>
            <EuiText size="s">
              <p>{getPaymentChannel(value)}</p>
            </EuiText>
          </div>
        )
      },
    },
    {
      field: "status",
      name: t("topup-history.list.table.status"),
      render: (value: definitions["payment.admin.v1.TopupStatus"]) => {
        return (
          <div>
            <EuiBadge
              color={
                value === "TOPUP_STATUS_SUCCESS"
                  ? "success"
                  : value === "TOPUP_STATUS_PENDING"
                    ? "default"
                    : "danger"
              }
            >
              {t(`topup-history.status.${value}`)}
            </EuiBadge>
          </div>
        )
      },
    },
    {
      field: "amount",
      name: t("topup-history.list.table.amount"),
      render: (value: string) => {
        return (
          <div>
            {t("common.number", {
              number: priceStrToNum(value),
            })}
          </div>
        )
      },
    },
    {
      field: "price",
      name: t("topup-history.list.table.price"),
      render: (value: string) => {
        return (
          <div>
            {t("common.number", {
              number: priceStrToNum(value),
            })}
          </div>
        )
      },
    },
    {
      field: "createdAt",
      sortable: false,
      name: t("topup-history.list.table.created-at"),
      render: (value: string) => {
        return (
          <div>
            {t("datetime", {
              value,
            })}
          </div>
        )
      },
    },
  ]

  const onGetItems: OnGetItems<Topup> = useCallback(
    async (props) => {
      const resp = await finder({
        "option.limit": props.opts.limit,
        "option.skip": props.opts.skip,
        status: props.filter?.status,
        username: props.filter?.username || undefined,
      })

      if (!resp.ok) {
        return {
          items: [],
          count: 0,
        }
      }

      const user = resp.data.user ?? []

      return {
        items: (resp.data.topup ?? []).map((t) => {
          return inTransform(t, user)
        }),
        count: parseInt(resp.data.count ?? "0"),
      }
    },
    [finder],
  )

  return (
    <div>
      <Title text={t("topup-history.list.title")} />
      <div className="mb-6 flex items-center justify-between">
        <EuiFieldText
          placeholder={t("topup-history.list.search-username")}
          value={searchUsername}
          onChange={(e) => {
            setSearchUsername(e.target.value)
          }}
          icon={<UilSearch />}
        />
      </div>
      <EuiTabs
        className="mb-4"
        css={css({
          ".euiTab": {
            ".euiTab__content": {
              color: "#69707d !important",
            },
            "&.euiTab-isSelected": {
              ".euiTab__content": {
                color: `${theme.colors.LIGHT.primaryPurple} !important`,
              },
            },
          },
          "&.euiTabs--bottomBorder": {
            boxShadow: "none",
          },
        })}
        size="l"
      >
        <EuiTab
          isSelected={status === "TOPUP_STATUS_UNSPECIFIED"}
          onClick={() => {
            setStatus("TOPUP_STATUS_UNSPECIFIED")
          }}
        >
          <EuiText size="m" className="font-bold">
            {t(`topup-history.status.TOPUP_STATUS_UNSPECIFIED`)}
          </EuiText>
        </EuiTab>
        <EuiTab
          isSelected={status === "TOPUP_STATUS_SUCCESS"}
          onClick={() => {
            setStatus("TOPUP_STATUS_SUCCESS")
          }}
        >
          <EuiText size="m" className="font-bold">
            {t(`topup-history.status.TOPUP_STATUS_SUCCESS`)}
          </EuiText>
        </EuiTab>
        <EuiTab
          isSelected={status === "TOPUP_STATUS_PENDING"}
          onClick={() => {
            setStatus("TOPUP_STATUS_PENDING")
          }}
        >
          <EuiText size="m" className="font-bold">
            {t(`topup-history.status.TOPUP_STATUS_PENDING`)}
          </EuiText>
        </EuiTab>
        <EuiTab
          isSelected={status === "TOPUP_STATUS_CANCEL"}
          onClick={() => {
            setStatus("TOPUP_STATUS_CANCEL")
          }}
        >
          <EuiText size="m" className="font-bold">
            {t(`topup-history.status.TOPUP_STATUS_CANCEL`)}
          </EuiText>
        </EuiTab>
      </EuiTabs>
      <Table
        columns={columns}
        onGetItems={onGetItems}
        isCanSelect={false}
        swrKey={`/order`}
        filter={{
          status,
          username: debouncedSearchUsername,
        }}
        sorter={{ field: "createdAt", direction: "desc" }}
        onRowClick={(item) => {
          navigate(`/topup-history/${item.id}`)
        }}
      />
    </div>
  )
}
