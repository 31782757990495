import { NavigationHeaderButton } from "@app/component/navigation-header-button"
import { contentType, useUploadFile } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { genNewId } from "@app/model"
import {
  WriterForm,
  writerFormValidator,
  WriterInput,
} from "@app/model/homepage/writer"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiTextArea,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { FormInputImagePicker, useFormHelper } from "@reeeed-mp/ui-common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { upsertById } from "../helper"
import { useHomeManagementStore } from "../store/ctx"

export const EditorWriter: React.FC = () => {
  const { t } = useTranslation()
  const { t: writerT } = useTranslation("translation", {
    keyPrefix: "homepage-management.writer",
  })
  const { uploadFile } = useUploadFile()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const { notificationStore } = useStore()
  const homeManagementStore = useHomeManagementStore()
  const navigate = useNavigate()
  const params = useParams()

  const onSubmit = async (values: WriterForm) => {
    const newWriters = upsertById(
      {
        id: values.id ? values.id : genNewId(),
        name: values.name,
        authorIntroduction: values.introduction,
        url: values.url,
        downloadUrl: values.downloadUrl,
        productCount: values.productCount,
        ...(values.cover?.fileInput?.tmpPath
          ? { avatar: values.cover?.fileInput?.tmpPath }
          : {}),
      },
      homeManagementStore.homepage?.popularAuthorSection?.authors,
    )
    homeManagementStore.update("popularAuthorSection", {
      authors: newWriters,
    })
    return {
      id: "",
    }
  }

  const writer: WriterInput | undefined =
    homeManagementStore.homepage?.popularAuthorSection?.authors?.find((a) => {
      return params.id === a.id
    })

  const initialValues = {
    id: writer?.id,
    name: writer?.name,
    url: writer?.url,
    introduction: writer?.authorIntroduction,
    productCount: writer?.productCount,
    cover: writer?.avatar
      ? {
          downloadUrl: writer?.avatar,
          fileInput: undefined,
        }
      : undefined,
    downloadUrl: writer?.downloadUrl ?? writer?.avatar,
  }

  const {
    values: form,
    setFieldValue: setValue,
    submitForm: submit,
    errors,
  } = useFormHelper<WriterForm, { id: string | undefined }>({
    id: params.id && params.id !== "create" ? params.id : undefined,
    initialValues,
    onGetItem: async (id) => {
      const writer: WriterInput | undefined =
        homeManagementStore.homepage?.popularAuthorSection?.authors?.find(
          (a) => {
            return id === a.id
          },
        )
      setName(writer?.name ?? "")
      return {
        id: writer?.id,
        name: writer?.name,
        url: writer?.url,
        introduction: writer?.authorIntroduction,
        productCount: writer?.productCount,
        cover: writer?.avatar
          ? {
              downloadUrl: writer?.avatar,
              fileInput: undefined,
            }
          : undefined,
        downloadUrl: writer?.downloadUrl ?? writer?.avatar,
      }
    },
    onError: () => {},
    onSubmit,
    onSubmitted: () => {
      navigate("/home-management/writer")
    },
    validationSchema: writerFormValidator,
  })

  return (
    <div
      className="p-2"
      css={css({
        backgroundColor: "white",
        minHeight: "100vh",
      })}
    >
      <NavigationHeaderButton
        title={name ? name : writerT("add-writer")}
        onClick={() => {
          navigate("/home-management/writer")
        }}
      />
      <FormInputImagePicker
        className="mt-10"
        isInvalid={!!errors.cover}
        css={css`
          width: 240px !important;
          height: 240px !important;
          .euiFilePicker__promptText {
            width: 240px;
          }
          .euiImage {
            width: 240px !important;
            height: 240px !important;
            border-radius: 6px !important;
          }
          .euiImage__img {
            border-radius: 6px !important;
          }
        `}
        placeholder={
          <div className="flex flex-col text-center">
            <div>
              <p>{writerT("writer-avatar")}</p>
            </div>
            <div>
              <p className="whitespace-pre-wrap  text-center text-sm">
                {writerT("cover-suggestion", {
                  size: "480x480 px",
                  type: ".jpg, .png",
                })}
              </p>
            </div>
          </div>
        }
        accept={`${contentType.JPG}, ${contentType.PNG}`}
        value={form?.downloadUrl || undefined}
        onChange={async (v: File | null) => {
          if (v) {
            try {
              setLoading(true)
              const { input, blob } = await uploadFile(v)
              setValue("cover.downloadUrl", blob)
              setValue("cover.fileInput", input)
              setValue("downloadUrl", blob)
            } catch (err: any) {
              if (err && err.message) {
                notificationStore.add({
                  title: "Error",
                  color: "danger",
                  text: err.message,
                })
              }
            } finally {
              setLoading(false)
            }
          }
        }}
      />
      <EuiFormRow
        isInvalid={!!errors.name}
        className="mt-6"
        label={writerT("name")}
      >
        <EuiFieldText
          placeholder={t("common.please-fill")}
          isInvalid={!!errors.name}
          value={form?.name}
          onChange={(e) => {
            setValue("name", e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        className="mt-6"
        isInvalid={!!errors.productCount}
        label={writerT("product-count")}
      >
        <EuiFieldText
          placeholder={t("common.please-fill")}
          isInvalid={!!errors.productCount}
          value={form?.productCount}
          min={0}
          type="number"
          onChange={(e) => {
            setValue("productCount", parseInt(e.target.value))
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        className="mt-6"
        isInvalid={!!errors.url}
        label={t("common.link")}
      >
        <EuiFieldText
          placeholder={t("common.please-fill")}
          isInvalid={!!errors.url}
          value={form?.url}
          onChange={(e) => {
            setValue("url", e.target.value)
          }}
        />
      </EuiFormRow>
      <EuiFormRow
        className="mt-6"
        isInvalid={!!errors.introduction}
        label={writerT("writer-introduction")}
      >
        <EuiTextArea
          placeholder={t("common.please-fill")}
          isInvalid={!!errors.introduction}
          value={form?.introduction ?? ""}
          onChange={(e) => {
            setValue("introduction", e.target.value)
          }}
        />
      </EuiFormRow>
      <div className="mt-10 flex flex-row">
        <EuiButton
          fill
          css={css`
            width: 255px;
            height: 40px;
          `}
          onClick={() => {
            submit()
          }}
          disabled={loading}
        >
          {t("common.save")}
        </EuiButton>
        <EuiButtonEmpty
          css={css`
            width: 255px;
            height: 40px;
          `}
          className="ml-4 items-center"
          color="text"
          disabled={loading}
          onClick={() => {
            navigate("/home-management/writer")
          }}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </div>
  )
}
