import { EuiText } from "@elastic/eui"
import { UilAngleRight, UilCheck } from "@iconscout/react-unicons"
import classNames from "classnames"
import React from "react"
import { LayoutTypes } from "../hook/model"
import { useTranslation } from "react-i18next"

export const LayoutBlockPreview = (props: {
  variant?: LayoutTypes
  isSelected?: boolean
}) => {
  const { t } = useTranslation()

  if (props.variant?.includes("UNSPECIFIED")) {
    return null
  }

  const getLayoutText = () => {
    if (props.variant?.includes("HERO_BANNER")) {
      return t(`home-management.HERO_BANNER_LAYOUT_FULL_SCREEN`)
    }
    if (props.variant?.includes("TWO_ROWS")) {
      return t(`home-management.PRODUCT_LAYOUT_OVERFLOW_TWO_ROWS`)
    }
    if (props.variant?.includes("OVERFLOW")) {
      return t(`home-management.PRODUCT_LAYOUT_OVERFLOW`)
    }
    return t(`home-management.PRODUCT_LAYOUT_FIXED`)
  }

  return (
    <div className="relative h-20 w-24">
      {props.isSelected && (
        <UilCheck
          size={16}
          fill="white"
          className="absolute -right-1 -top-1 rounded-full bg-primary"
        />
      )}
      <div
        className={classNames(
          "h-full w-full select-none rounded-md",
          props.isSelected
            ? "border-2 border-solid border-ci-primary bg-ci-primary-ex-light"
            : "border-2 border-transparent bg-ci-grey-light",
        )}
      >
        <div className="absolute bottom-7 left-2 right-2 top-2">
          {props.variant === "HERO_BANNER_LAYOUT_FULL_SCREEN" ? (
            <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20"></div>
          ) : null}

          {props.variant === "POPULAR_PRODUCT_LAYOUT_OVERFLOW" ||
          props.variant === "RECOMMENDED_PRODUCT_LAYOUT_OVERFLOW" ||
          props.variant === "SMALL_BANNER_LAYOUT_OVERFLOW" ||
          props.variant === "WRITER_BANNER_LAYOUT_OVERFLOW" ||
          props.variant === "MEDIUM_BANNER_LAYOUT_OVERFLOW" ? (
            <div className="relative grid h-full w-full grid-cols-3 justify-evenly gap-1">
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <UilAngleRight
                size={32}
                className="absolute -right-1 bottom-0 top-0 h-full text-white"
              />
            </div>
          ) : null}

          {props.variant === "POPULAR_PRODUCT_LAYOUT_FIXED" ||
          props.variant === "SMALL_BANNER_LAYOUT_FIXED" ||
          props.variant === "RECOMMENDED_PRODUCT_LAYOUT_FIXED" ||
          props.variant === "WRITER_BANNER_LAYOUT_CONTAINED" ? (
            <div className="grid h-full w-full grid-cols-3 grid-rows-2 justify-evenly gap-1">
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
            </div>
          ) : null}

          {props.variant === "MEDIUM_BANNER_LAYOUT_FIXED" ? (
            <div className="grid h-full w-full grid-cols-3 justify-evenly gap-1">
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
            </div>
          ) : null}
          {props.variant === "SMALL_BANNER_LAYOUT_OVERFLOW_TWO_ROWS" ? (
            <div className="grid h-full w-full grid-cols-3 justify-evenly gap-1">
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <div className="h-full w-full rounded-sm bg-ci-black bg-opacity-20" />
              <UilAngleRight
                size={32}
                className="absolute -right-1 bottom-0 top-0 h-full text-white"
              />
            </div>
          ) : null}
        </div>
        <EuiText
          color="gray"
          size="xs"
          className="absolute bottom-1.5 left-0 right-0 w-full text-center"
        >
          {getLayoutText()}
        </EuiText>
      </div>
    </div>
  )
}
