import {
  EuiButton,
  EuiDatePicker,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiRadio,
  EuiText,
  useGeneratedHtmlId,
} from "@elastic/eui"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import moment, { Moment } from "moment"

type Props = {
  onClose: () => void
  onConfirm: (res: Result) => void
}

enum ShopReportType {
  All = "all",
  BYDATE = "by-date",
}

type Result = {
  startDate?: Moment
  endDate?: Moment
}

const dateRange = 90

export const ShopReportModal = observer((props: Props) => {
  const { onClose, onConfirm } = props
  const { t } = useTranslation()
  const [shopReportType, setShopReportType] = useState(ShopReportType.All)

  const [startDate, setStartDate] = useState<Moment | undefined>(undefined)
  const [endDate, setEndDate] = useState<Moment | undefined>(undefined)
  const [minDateFrom, setMinDateFrom] = useState<Moment | undefined>(undefined)
  const [maxDateFrom, setMaxDateFrom] = useState<Moment | undefined>(undefined)
  const [minDateTo, setMinDateTo] = useState<Moment | undefined>(undefined)
  const [maxDateTo, setMaxDateTo] = useState<Moment | undefined>(undefined)

  const isDownloadDisabled =
    shopReportType === ShopReportType.All
      ? false
      : startDate === undefined || endDate === undefined

  const allRadioButtonId = useGeneratedHtmlId({ prefix: "radioButton" })
  const byDateRadioButtonId = useGeneratedHtmlId({ prefix: "radioButton" })

  useEffect(() => {
    if (shopReportType === ShopReportType.All) {
      setStartDate(undefined)
      setEndDate(undefined)
      setMinDateTo(undefined)
      setMaxDateTo(undefined)
    }
  }, [shopReportType])

  return (
    <EuiModal className="book-category-page__modal" onClose={onClose}>
      <EuiModalHeader>
        <EuiText>
          <h2>{t("seller-management.report.download-shop-report")}</h2>
        </EuiText>
      </EuiModalHeader>
      <EuiModalBody>
        <div className="flex flex-col gap-y-4 ">
          <h3 className="font-bold">{t("seller-management.report.filter")}</h3>
          <div className="flex flex-row items-center gap-x-12">
            <EuiRadio
              id={allRadioButtonId}
              label={t("seller-management.report.all")}
              checked={shopReportType === ShopReportType.All}
              onChange={() => {
                setShopReportType(ShopReportType.All)
              }}
            />
            <EuiRadio
              id={byDateRadioButtonId}
              label={t("seller-management.report.by-date")}
              checked={shopReportType === ShopReportType.BYDATE}
              onChange={() => {
                setShopReportType(ShopReportType.BYDATE)
              }}
            />
          </div>
          {shopReportType === ShopReportType.BYDATE && (
            <div className="flex w-full flex-row items-center justify-between gap-x-2">
              <div className="flex flex-col gap-y-1">
                <EuiText>
                  <p>{t("common.start")}</p>
                </EuiText>
                <EuiFormRow className="my-0">
                  <EuiDatePicker
                    selected={startDate}
                    minDate={minDateFrom}
                    maxDate={maxDateFrom}
                    onChange={(date) => {
                      if (date) {
                        setStartDate(date)
                        setMinDateTo(date)
                        setMaxDateTo(moment(date).add(dateRange, "d"))
                      }
                    }}
                  />
                </EuiFormRow>
              </div>
              <div className="flex flex-col gap-y-1">
                <EuiText>
                  <p>{t("common.end")}</p>
                </EuiText>
                <EuiFormRow className="my-0">
                  <EuiDatePicker
                    selected={endDate}
                    minDate={minDateTo}
                    maxDate={maxDateTo}
                    onChange={(date) => {
                      if (date) {
                        setEndDate(date)
                        setMinDateFrom(moment(date).add(-dateRange, "d"))
                        setMaxDateFrom(date)
                      }
                    }}
                  />
                </EuiFormRow>
              </div>
            </div>
          )}
        </div>
        <div className="mt-8 flex flex-row justify-end gap-x-4">
          <EuiButton
            color="primary"
            fill
            disabled={isDownloadDisabled}
            onClick={() => {
              onConfirm({
                startDate,
                endDate,
              })
            }}
          >
            {t("common.download")}
          </EuiButton>
          <EuiButton color="text" onClick={onClose}>
            {t("common.cancel")}
          </EuiButton>
        </div>
      </EuiModalBody>
    </EuiModal>
  )
})
