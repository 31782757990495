import { EditModal } from "@app/component"
import { publicConfig } from "@app/config"
import { splitIds } from "@app/model"
import { useLazyGetCategories } from "@app/page/category/hook/category"
import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormRow,
} from "@elastic/eui"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import CategoryBannersBox from "../component/category-banners-box"
import {
  Category,
  CategoryBanner,
  categoryBannerIntoRequest,
  getCategoriesByLevel,
  isRestrictedCategory,
} from "../model"

export type StoreCategoryProps = {
  onCloseModal: () => void
  onSubmit: (
    name: string,
    slug: string,
    parentId?: string,
    banners?: CategoryBanner[],
  ) => void
  onError: (msg: string) => void
  editing?: {
    id: string
    name: string
    slug: string
    banners: CategoryBanner[]
  }
  creatingCategoryLevel?: number
}

export const StoreCategoryModal: React.FC<StoreCategoryProps> = (props) => {
  const [newName, setNewName] = useState(props.editing?.name ?? "")
  const [newSlug, setNewSlug] = useState(props.editing?.slug ?? "")
  const [errorSlug, setErrorSlug] = useState<string>()
  const [errorParentCategory, setErrorParentCategory] = useState<string>()
  const [banners, setBanners] = useState<CategoryBanner[]>(
    () => props.editing?.banners || [],
  )
  const [selectedMainOption, setSelectedMainOption] = useState<
    EuiComboBoxOptionOption<Category>[]
  >([])
  const [selectedSubOption, setSelectedSubOption] = useState<
    EuiComboBoxOptionOption<Category>[]
  >([])

  const { t: commonT } = useTranslation()
  const { t } = useTranslation("translation", { keyPrefix: "category" })
  const { data: categories } = useLazyGetCategories(
    props.creatingCategoryLevel !== undefined,
  )
  const mainCategories = getCategoriesByLevel(categories || [], 1)
  const subCategories = getCategoriesByLevel(categories || [], 2)

  let title = ""
  let addCategoryLabel = ""
  let categorySlug = ""

  if (props.editing) {
    const splitedId = splitIds(props.editing.id)
    switch (splitedId.length) {
      case 3: {
        categorySlug = t("nested-sub-category-url")
        addCategoryLabel = t("nested-sub-category-name")
        title = t("edit-nested-sub-category")
        break
      }
      case 2: {
        categorySlug = t("sub-category-url")
        addCategoryLabel = t("sub-category-name")
        title = t("edit-sub-category")
        break
      }
      default: {
        categorySlug = t("main-category-url")
        addCategoryLabel = t("main-category-name")
        title = t("edit-main-category")
      }
    }
  }

  if (props.creatingCategoryLevel) {
    if (props.creatingCategoryLevel === 1) {
      categorySlug = t("main-category-url")
      addCategoryLabel = t("main-category-name")
      title = t("add-main-category")
    }

    if (props.creatingCategoryLevel === 2) {
      categorySlug = t("sub-category-url")
      addCategoryLabel = t("sub-category-name")
      title = t("add-sub-category")
    }

    if (props.creatingCategoryLevel === 3) {
      categorySlug = t("nested-sub-category-url")
      addCategoryLabel = t("nested-sub-category-name")
      title = t("add-nested-sub-category")
    }
  }

  const onSubmit = () => {
    let parentId = undefined
    if (selectedMainOption.length > 0) {
      parentId = selectedMainOption[0].id ?? undefined
    }
    if (selectedSubOption.length > 0) {
      parentId = selectedSubOption[0].id ?? undefined
    }

    let hasError = false
    if (!newSlug) {
      setErrorSlug(`${commonT("common.please-fill")} ${categorySlug}`)
      hasError = true
    }

    if (
      props.creatingCategoryLevel &&
      props.creatingCategoryLevel > 1 &&
      !parentId
    ) {
      setErrorParentCategory(commonT("common.please-fill"))
      hasError = true
    }

    if (hasError) {
      return
    }

    const bannersWithoutBlank = banners
      .filter((b) => b.fileInput || b.imageUrl)
      .map(categoryBannerIntoRequest)

    props.onSubmit(newName, newSlug, parentId, bannersWithoutBlank)
    props.onCloseModal()
  }

  return (
    <EditModal title={title} onClose={props.onCloseModal} onSubmit={onSubmit}>
      <>
        {props.creatingCategoryLevel && props.creatingCategoryLevel >= 2 ? (
          <EuiFormRow
            label={t("main-category")}
            fullWidth
            className="mb-4"
            isInvalid={!!errorParentCategory && selectedMainOption.length === 0}
            error={errorParentCategory}
          >
            <EuiComboBox<Category>
              aria-label="main-category"
              placeholder={commonT("common.please-fill")}
              isClearable={true}
              fullWidth
              options={mainCategories.map((m) => ({
                id: m.id,
                label: m.name ?? "",
              }))}
              singleSelection={{ asPlainText: true }}
              selectedOptions={selectedMainOption}
              onChange={setSelectedMainOption}
              isInvalid={
                !!errorParentCategory && selectedMainOption.length === 0
              }
            />
          </EuiFormRow>
        ) : null}
        {props.creatingCategoryLevel === 3 ? (
          <EuiFormRow
            label={t("sub-category")}
            fullWidth
            className="mb-4"
            isInvalid={!!errorParentCategory && selectedSubOption.length === 0}
            error={errorParentCategory}
          >
            <EuiComboBox<Category>
              aria-label="sub-category"
              placeholder={commonT("common.please-fill")}
              isClearable={true}
              selectedOptions={selectedSubOption}
              options={
                selectedMainOption.length > 0
                  ? subCategories.reduce(
                      (acc, s) =>
                        splitIds(s.id || "")[0] === selectedMainOption[0].id
                          ? [...acc, { id: s.id, label: s.name ?? "" }]
                          : acc,
                      [] as EuiComboBoxOptionOption<Category>[],
                    )
                  : []
              }
              singleSelection={{ asPlainText: true }}
              onChange={setSelectedSubOption}
              fullWidth
              isInvalid={
                !!errorParentCategory && selectedSubOption.length === 0
              }
            />
          </EuiFormRow>
        ) : null}

        <EuiFlexGrid direction="row" className="gap-y-4">
          <EuiFlexItem>
            <EuiFormRow label={addCategoryLabel} fullWidth>
              <EuiFieldText
                fullWidth
                controlOnly
                placeholder={commonT("common.please-fill")}
                onChange={(v) => setNewName(v.target.value)}
                value={newName || ""}
                disabled={isRestrictedCategory(props.editing?.id)}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow
              label={categorySlug}
              isInvalid={!!errorSlug && !newSlug}
              error={errorSlug}
              fullWidth
            >
              <EuiFieldText
                fullWidth
                isInvalid={!!errorSlug && !newSlug}
                prepend={`${publicConfig.webBaseUri}/`}
                placeholder={commonT("common.please-fill")}
                onChange={(v) => setNewSlug(v.target.value)}
                value={newSlug}
                disabled={isRestrictedCategory(props.editing?.id)}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGrid>

        <CategoryBannersBox banners={banners} setBanners={setBanners} />
      </>
    </EditModal>
  )
}
