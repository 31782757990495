import {
  copyToClipboard,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiPopover,
} from "@elastic/eui"

import {
  UilAngleDown,
  UilEllipsisV,
  UilLink,
  UilPen,
  UilTrash,
} from "@iconscout/react-unicons"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

export const ReOrderSelect = (props: {
  bannerKey: string
  sectionKey: string
  count: number
  order: string
  onReorder: (bannerKey: string, order: string) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <EuiPopover
      button={
        <EuiButtonEmpty
          color="ghost"
          className="rounded-md bg-ci-grey-light text-ci-grey"
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <div className="flex items-center justify-between gap-x-2">
            <span>{props.order}</span>
            <UilAngleDown size={24} />
          </div>
        </EuiButtonEmpty>
      }
      isOpen={isOpen}
      anchorPosition="downCenter"
      closePopover={() => setIsOpen((isOpen) => !isOpen)}
      panelPaddingSize="s"
    >
      <div className="flex flex-col items-start gap-2">
        {Array.from({ length: props.count }).map((_, idx) => {
          return (
            <EuiButtonEmpty
              className="w-full"
              key={idx}
              onClick={async () => {
                await props.onReorder(props.bannerKey, (idx + 1).toString())
                setIsOpen(false)
              }}
            >
              {idx + 1}
            </EuiButtonEmpty>
          )
        })}
      </div>
    </EuiPopover>
  )
}

export const TableAction = (props: {
  onEdit: (bannerKey: string) => void
  onDelete: (bannerKey: string) => void
  link: string
  bannerKey: string
  sectionKey: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <EuiPopover
        button={
          <EuiButtonIcon
            iconType={UilEllipsisV}
            onClick={() => setIsOpen((isOpen) => !isOpen)}
          ></EuiButtonIcon>
        }
        isOpen={isOpen}
        anchorPosition="leftCenter"
        closePopover={() => setIsOpen((isOpen) => !isOpen)}
        panelPaddingSize="s"
      >
        <div className="flex w-44 flex-col items-start gap-2">
          <EuiButtonEmpty
            iconType={UilLink}
            color="text"
            onClick={() => {
              copyToClipboard(props.link)
              setIsOpen(false)
            }}
          >
            {t("common.copy-url")}
          </EuiButtonEmpty>
          <EuiButtonEmpty
            iconType={UilPen}
            color="text"
            onClick={() => props.onEdit(props.bannerKey)}
          >
            {t("common.edit")}
          </EuiButtonEmpty>
          <EuiButtonEmpty
            iconType={UilTrash}
            color="text"
            onClick={() => props.onDelete(props.bannerKey)}
          >
            {t("common.delete")}
          </EuiButtonEmpty>
        </div>
      </EuiPopover>
    </>
  )
}
