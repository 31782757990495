import BestExpressPng from "@app/image/icon/best-express.png"
import DhlSvg from "@app/image/icon/dhl.svg"
import FlashExpressSvg from "@app/image/icon/flash-express.svg"
import JAndTSvg from "@app/image/icon/j&t-express.svg"
import KerrySvg from "@app/image/icon/kerry-express.svg"
import NinjaVanSvg from "@app/image/icon/ninja-van.svg"
import ShopeeExpressSvg from "@app/image/icon/shopee-express.svg"
import ThaiPostEmsSvg from "@app/image/icon/thaipost-ems.svg"
import ThaiPostRegisterSvg from "@app/image/icon/thaipost-register.svg"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"

export type Courier = {
  variant: CourierVariant
  isActive: boolean
}

export enum CourierVariant {
  DEFAULT = "default",
  PICKUP = "pickup",
  DROPOFF = "dropoff",
}

export enum CourierProvider {
  SHIPPOP_COURIER_THAILAND_POST = "SHIPPOP_COURIER_THAILAND_POST",
  SHIPPOP_COURIER_EMS = "SHIPPOP_COURIER_EMS",
  SHIPPOP_COURIER_DHL = "SHIPPOP_COURIER_DHL",
  SHIPPOP_COURIER_KERRY = "SHIPPOP_COURIER_KERRY",
  SHIPPOP_COURIER_J_AND_T = "SHIPPOP_COURIER_J_AND_T",
  SHIPPOP_COURIER_FLASH_EXPRESS = "SHIPPOP_COURIER_FLASH_EXPRESS",
  SHIPPOP_COURIER_NINJA_VAN = "SHIPPOP_COURIER_NINJA_VAN",
  SHIPPOP_COURIER_BEST_EXPRESS = "SHIPPOP_COURIER_BEST_EXPRESS",
  SHIPPOP_COURIER_SHOPEE_EXPRESS = "SHIPPOP_COURIER_SHOPEE_EXPRESS",
}

export const shippopCourierToCourierProvider = (
  s: definitions["delivery.v1.ShippopCourier"],
): CourierProvider => {
  const c = s.replace(new RegExp(/(_DROPOFF|_PICKUP)/), "")

  return CourierProvider[c as keyof typeof CourierProvider]
}

export const deliveryMethodMapping = [
  {
    image: ThaiPostRegisterSvg,
    title: "Thaipost - Registered Mail",
    courier: CourierProvider.SHIPPOP_COURIER_THAILAND_POST,
  },
  {
    image: ThaiPostEmsSvg,
    title: "Thaipost - EMS",
    courier: CourierProvider.SHIPPOP_COURIER_EMS,
  },
  {
    image: DhlSvg,
    title: "DHL",
    courier: CourierProvider.SHIPPOP_COURIER_DHL,
  },
  {
    image: KerrySvg,
    title: "Kerry Express",
    courier: CourierProvider.SHIPPOP_COURIER_KERRY,
    variants: [CourierVariant.PICKUP, CourierVariant.DROPOFF],
  },
  {
    image: JAndTSvg,
    title: "J&T Express",
    courier: CourierProvider.SHIPPOP_COURIER_J_AND_T,
    variants: [CourierVariant.PICKUP, CourierVariant.DROPOFF],
  },
  {
    image: FlashExpressSvg,
    title: "Flash Express",
    courier: CourierProvider.SHIPPOP_COURIER_FLASH_EXPRESS,
  },
  {
    image: NinjaVanSvg,
    title: "Ninja Van",
    courier: CourierProvider.SHIPPOP_COURIER_NINJA_VAN,
  },
  {
    image: BestExpressPng,
    title: "Best Express",
    courier: CourierProvider.SHIPPOP_COURIER_BEST_EXPRESS,
  },
  {
    image: ShopeeExpressSvg,
    title: "Shopee Xpress",
    courier: CourierProvider.SHIPPOP_COURIER_SHOPEE_EXPRESS,
    variants: [CourierVariant.PICKUP],
  },
]

// Refs: https://developers.shippop.com/seller/general/tracking_order
// | "wait"
// | "booking"
// | "invalid"
// | "shipping"
// | "package_detail"
// | "complete"
// | "return"
// | "cancel"
// | "pending_transfer"
// | "transferred"
// | "cancel_transfer"

export const isShippopStatusComplete = (status?: string) => {
  return status === "complete"
}

export const isShippopStatusFailed = (status?: string) => {
  return (
    status === "invalid" ||
    status === "cancel" ||
    status === "return" ||
    status === "pending_transfer" ||
    status === "transferred" ||
    status === "cancel_transfer"
  )
}
