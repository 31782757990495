import { Title } from "@app/component"
import { useTranslation } from "react-i18next"
import { useGetAddCoinHistory, useGetAddCoinHistoryUser } from "./hook"
import {
  EuiAccordion,
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiPagination,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
} from "@elastic/eui"
import { constants } from "@app/model/constants"
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom"
import { hooks } from "@reeeed-mp/ui-common"

const AddReeeedCoinStat = (props: { title: string; value: number }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-2 rounded-lg bg-lightest px-10 py-4	">
      <EuiText>
        <p>{props.title}</p>
      </EuiText>
      <EuiText>
        <h2 className="text-primary">{props.value}</h2>
      </EuiText>
    </div>
  )
}

const PaginationSize = 10

export const DetailAddReeeedCoin = (props: { id: string }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetAddCoinHistory(props.id)
  const { query, setQuery } = hooks.useURLQuery()
  const currentPage = query.page ? Number(query.page) - 1 : 0

  const { data: userData } = useGetAddCoinHistoryUser({
    historyId: props.id,
    limit: PaginationSize,
    skip: currentPage * PaginationSize,
  })

  const navigate = useNavigate()
  const getUserRow = () => {
    return userData?.addCoinHistoryUser?.map((historyUser) => {
      const username = userData.user?.find((user) => {
        return user.id == historyUser.userId
      })?.username
      const email = userData.emails?.find((email) => {
        return email.userId == historyUser.userId
      })?.email
      return (
        <EuiTableRow>
          <EuiTableRowCell>{historyUser.userId}</EuiTableRowCell>
          <EuiTableRowCell>{username}</EuiTableRowCell>
          <EuiTableRowCell>{email}</EuiTableRowCell>
          <EuiTableRowCell>
            {Number(historyUser.coin ?? "0") / constants.power10OfDecimalPrice}
          </EuiTableRowCell>
        </EuiTableRow>
      )
    })
  }

  if (isLoading) {
    return null
  }

  return (
    <div>
      <Title
        backBtn={{
          onClick: () => {
            navigate(-1)
          },
        }}
        text={data?.history?.name ?? ""}
      />
      <div className="mb-4 flex flex-row items-center gap-x-4">
        <EuiText>
          <p className="text-xs text-eui-darkShade">
            {t("add-reeeed-coin.editor.detail.added-at")}
          </p>
        </EuiText>
        <EuiText>
          <p
            style={{
              fontSize: "10.5px",
            }}
          >
            {data?.history?.createdAt
              ? t("common.datetime", {
                  value: data?.history?.createdAt,
                })
              : "-"}
          </p>
        </EuiText>
      </div>
      <div className="flex flex-row gap-x-2">
        {[
          {
            title: t("add-reeeed-coin.editor.detail.total-user"),
            value: Number(data?.history?.totalUser ?? "0"),
          },
          {
            title: t("add-reeeed-coin.editor.detail.total-coin"),
            value:
              Number(data?.history?.totalCoin ?? "0") /
              constants.power10OfDecimalPrice,
          },
        ].map((stat) => {
          return <AddReeeedCoinStat title={stat.title} value={stat.value} />
        })}
      </div>
      {data?.history?.noted ? (
        <div className="mt-4 flex flex-col">
          <EuiText>
            <h4>{t("add-reeeed-coin.editor.detail.noted")}</h4>
          </EuiText>
          <EuiText>
            <p>{data?.history?.noted}</p>
          </EuiText>
        </div>
      ) : null}

      <EuiAccordion
        id="accordion"
        className="mt-10 h-full border-t-0"
        arrowDisplay="right"
        css={css`
          .euiAccordion__button {
            max-width: 240px;
            width: 100%;
          }
          .euiAccordion__triggerWrapper {
            .euiButtonIcon {
              transform: rotate(90deg) !important;
            }
            .euiAccordion__iconButton-isOpen {
              transform: rotate(270deg) !important;
            }
          }

          .euiAccordion__childWrapper {
            height: 100% !important;
          }

          .euiAccordion__triggerWrapper {
            padding: 0;
          }
        `}
        buttonClassName="flex flex-row items-center gap-x-2"
        buttonContent={
          <EuiText>
            <h3>{t("add-reeeed-coin.editor.detail.user-list")}</h3>
          </EuiText>
        }
        initialIsOpen={true}
      >
        <EuiTable className="mt-4 h-full">
          <EuiTableHeader>
            <EuiTableHeaderCell>
              {t("add-reeeed-coin.editor.detail.table.id")}
            </EuiTableHeaderCell>
            <EuiTableHeaderCell>
              {t("add-reeeed-coin.editor.detail.table.username")}
            </EuiTableHeaderCell>
            <EuiTableHeaderCell>
              {t("add-reeeed-coin.editor.detail.table.email")}
            </EuiTableHeaderCell>
            <EuiTableHeaderCell>
              {t("add-reeeed-coin.editor.detail.table.coin")}
            </EuiTableHeaderCell>
          </EuiTableHeader>
          {getUserRow()}
        </EuiTable>
        {userData && Number(userData?.count ?? "0") >= PaginationSize && (
          <div className="mt-2 flex w-full flex-row justify-end">
            <EuiPagination
              aria-label="Add Reeeed Coin User Table"
              pageCount={Math.ceil(
                Number(userData?.count ?? "0") / PaginationSize,
              )}
              activePage={currentPage}
              onPageClick={(pageIndex) => {
                setQuery(
                  { ...query, page: String(pageIndex + 1) },
                  { replace: true },
                )
              }}
            />
          </div>
        )}
      </EuiAccordion>
    </div>
  )
}
