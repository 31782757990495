import { constants } from "@app/model/constants"
import { definitions } from "@app/vendor/promotion-specs/promotion_service"
import moment from "moment"
import {
  CouponEffect,
  CouponEffectType,
  CouponType,
  TopupCoupon,
} from "./types"

const effectToAPI = (
  effectType: CouponEffectType,
  effect: CouponEffect,
): definitions["promotion.v1.TopupCouponEffect"] => {
  const out: definitions["promotion.v1.TopupCouponEffect"] = {}

  switch (effectType) {
    case CouponEffectType.Amount:
      if (effect?.amount?.value) {
        out.amount = String(
          effect.amount.value * constants.power10OfDecimalPrice,
        )
      }
      break
    case CouponEffectType.Percentage:
      out.percentage = {}
      if (effect?.percentage) {
        out.percentage.ratio = String(
          effect.percentage.value! * constants.power10OfHundred,
        )
        if (effect.percentage.haveMaxAmount) {
          out.percentage.maxAmount = String(
            effect.percentage.maxAmount! * constants.power10OfDecimalPrice,
          )
        }
      }
      break
    default:
      throw new Error(`unsupport coupon-effect-type=[${effectType}]`)
  }

  return out
}

export const formToAPI = (
  form: TopupCoupon,
): definitions["promotion.admin.v1.TopupCouponPromotionInput"] => {
  const data: definitions["promotion.admin.v1.TopupCouponPromotionInput"] = {
    code: form.code,
    name: form.name,
    quota: form.limitQuota ? form.quota : undefined,
    note: form.note,
    additional: form.additional,
    collectCodeStartAt: form.isCanCollectCodeEarly
      ? form.collectCodeStartAt?.toISOString()
      : undefined,
    startAt: form.startAt && form.startAt.toISOString(),
    endAt: form.endAt && form.endAt.toISOString(),
    joinPackage: {},
    hideFromCollectPage: form.hideFromCollectPage,
  }

  if (form.applyToAllPackage) {
    data.joinPackage!.all = true
  } else {
    data.joinPackage!.package = {
      ids: form.joinPackage,
    }
  }

  const effect = effectToAPI(form.effectType, form.effect!)
  switch (form.couponType) {
    case CouponType.ExtraCoin:
      data.extraCoin = effect
      break
    case CouponType.Discount:
      data.discount = effect
      break
    default:
      throw new Error(`unsupport coupon-type=[${form.couponType}]`)
  }

  return data
}

export const apiToForm = (
  data: definitions["promotion.admin.v1.GetTopupCouponPromotionResponse"],
): TopupCoupon => {
  const p = data.promotion!
  const joinPackage = data.joinPackage!

  const e =
    Object.keys(p?.discount || {}).length > 0 ? p.discount : p.extraCoin!

  const { effect, effectType } = effectAPIToform(e!)

  const out: TopupCoupon = {
    code: p.code,
    name: p.name,
    limitQuota: typeof p.quota === "number",
    quota: p.quota,

    note: p.note,
    additional: p.additional,

    isCanCollectCodeEarly: !!p.collectCodeStartAt,
    collectCodeStartAt: p.collectCodeStartAt
      ? moment(p.collectCodeStartAt)
      : undefined,
    startAt: p.startAt ? moment(p.startAt) : undefined,
    endAt: p.endAt ? moment(p.endAt) : undefined,

    couponType:
      p.discount && Object.keys(p.discount).length > 0
        ? CouponType.Discount
        : CouponType.ExtraCoin,
    effectType,
    effect,

    applyToAllPackage: !!joinPackage.all,
    joinPackage: joinPackage?.package?.ids,
    hideFromCollectPage: !!p.hideFromCollectPage,
  }

  return out
}

const effectAPIToform = (
  e: definitions["promotion.v1.TopupCouponEffect"],
): { effect: CouponEffect; effectType: CouponEffectType } => {
  if (e.amount) {
    return {
      effectType: CouponEffectType.Amount,
      effect: {
        amount: {
          value: Number(e.amount!) / constants.power10OfDecimalPrice,
        },
      },
    }
  }

  return {
    effectType: CouponEffectType.Percentage,
    effect: {
      percentage: {
        value: Number(e.percentage!.ratio) / constants.power10OfHundred,
        haveMaxAmount: !!e.percentage?.maxAmount,
        maxAmount: e.percentage?.maxAmount
          ? Number(e.percentage.maxAmount) / constants.power10OfDecimalPrice
          : undefined,
      },
    },
  }
}
