import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  ErrorSectionType,
  ErrorSectionTypeResult,
  HomeMenuItem,
  mapAdditionalSectionToMenu,
  MappedAdditionalSection,
  MenuTypes,
  useCommitHomeManagement,
  useCreatePageSection,
  useDeleteAdditionalSection,
  useHomeMenuList,
  validateHomeLayout,
  validateProductError,
} from "../hook/model"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useStore } from "@app/hook/store"
import { useTranslation } from "react-i18next"
import { useAdminProductApi } from "../products/hook/update-detail"

export enum NavigationMenuType {
  mainMenu = "mainMenu",
  highlightMenu = "highlightMenu",
}

type HomeManagementContextProps = {
  homeMenuList?: HomeMenuItem
  heroBannerSection?: definitions["webutility.admin.v1.AdminHeroBannerSection"]
  mainMenuSection?: definitions["webutility.admin.v1.AdminMainMenuSection"]
  selectedId?: string
  setSelectedId: React.Dispatch<React.SetStateAction<string | undefined>>
  mainMenuTab: NavigationMenuType
  setMainMenuTab: React.Dispatch<React.SetStateAction<NavigationMenuType>>
  commitChanges: (
    homeLayout: definitions["webutility.admin.v1.PageLayout"],
  ) => Promise<void>
  discardChanges: () => Promise<void>
  createPageSection?: (type: MenuTypes) => Promise<unknown>
  refreshHomepageSection: () => Promise<{ [key: string]: any } | undefined>
  refreshing: boolean
  removeSection: (sectionKey: string) => Promise<void>
  errors: ErrorSectionTypeResult[]
  setErrors: React.Dispatch<React.SetStateAction<ErrorSectionTypeResult[]>>
}

export const HomeManagementContext = createContext<HomeManagementContextProps>({
  homeMenuList: undefined,
  selectedId: undefined,
  setSelectedId: () => {},
  mainMenuTab: NavigationMenuType.mainMenu,
  setMainMenuTab: () => {},
  commitChanges: async () => {},
  discardChanges: async () => {},
  createPageSection: async () => undefined,
  refreshHomepageSection: async () => undefined,
  refreshing: false,
  removeSection: async () => {},
  errors: [],
  setErrors: () => {},
})

export const FlexibleHomeManagementProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const {
    homeMenuList,
    mutate: refreshHomepageSection,
    isValidating: refreshing,
  } = useHomeMenuList()
  const { createNewSection } = useCreatePageSection()
  const { getExistProductIDs } = useAdminProductApi()
  const { deleteSection } = useDeleteAdditionalSection()
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
  const [mainMenuTab, setMainMenuTab] = useState<NavigationMenuType>(
    NavigationMenuType.mainMenu,
  )
  const [errors, setErrors] = useState<ErrorSectionTypeResult[]>([])
  const { commit, reset } = useCommitHomeManagement(
    homeMenuList?.pageLayout?.id!,
  )
  const { t } = useTranslation()
  const { notificationStore } = useStore()

  const commitChanges = async (
    homeLayout: definitions["webutility.admin.v1.PageLayout"],
  ) => {
    const invalidSectionKeys = await validateHomeLayout(
      homeLayout,
      getExistProductIDs,
    )

    if (invalidSectionKeys.length > 0) {
      setSelectedId(invalidSectionKeys[0].key)
      setErrors(invalidSectionKeys)
      if (invalidSectionKeys[0].errorType === ErrorSectionType.Title) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: t("home-management.error.title-missing"),
        })
      } else if (invalidSectionKeys[0].errorType === ErrorSectionType.Product) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: t("home-management.error.product-is-deleted-or-not-exist"),
        })
      }

      return
    }

    setErrors([])

    await commit()
    await refreshHomepageSection()
  }
  const discardChanges = async () => {
    setSelectedId(undefined)
    setErrors([])
    await reset()
    await refreshHomepageSection()
  }

  const createPageSection = async (type: MenuTypes) => {
    const resp = await createNewSection(type)
    await refreshHomepageSection()
    return resp
  }

  const removeSection = async (sectionKey: string) => {
    if (!sectionKey) {
      return
    }
    await deleteSection(sectionKey)

    await refreshHomepageSection()
  }

  const validateProductSection = async () => {
    if (!homeMenuList?.pageLayout) {
      return
    }

    const invalidSectionKeys = await validateProductError(
      homeMenuList?.pageLayout,
      getExistProductIDs,
    )

    if (invalidSectionKeys.length > 0) {
      setErrors(invalidSectionKeys)
    }
  }

  useEffect(() => {
    validateProductSection()
  }, [homeMenuList?.pageLayout?.sections])

  if (!homeMenuList) {
    return null
  }
  return (
    <HomeManagementContext.Provider
      value={{
        homeMenuList: homeMenuList.pageLayout,
        heroBannerSection: homeMenuList.pageLayout?.sections?.find(
          (s) => s.heroBannerSection,
        )?.heroBannerSection,
        mainMenuSection: homeMenuList.pageLayout?.sections?.find(
          (s) => s.mainMenuSection,
        )?.mainMenuSection,
        mainMenuTab,
        setMainMenuTab,
        selectedId,
        setSelectedId,
        commitChanges,
        discardChanges,
        refreshHomepageSection,
        refreshing,
        createPageSection,
        removeSection,
        errors,
        setErrors,
      }}
    >
      {children}
    </HomeManagementContext.Provider>
  )
}

export const useHomeManagementStore = () => {
  const context = useContext(HomeManagementContext)
  if (!context) {
    throw new Error(
      "useHomeManagementStore must be used within a HomeManagementProvider",
    )
  }
  return context
}

export const useAdditionalSection = () => {
  const [sections, setSections] = useState<MappedAdditionalSection[]>([])
  const { homeMenuList } = useHomeManagementStore()

  useEffect(() => {
    const skipUniqueSectionsCount = 2
    const additionalSections =
      homeMenuList?.sections?.slice(skipUniqueSectionsCount) || []
    setSections(
      additionalSections.map((section) => {
        return mapAdditionalSectionToMenu(section)
      }),
    )
  }, [homeMenuList])

  return {
    sections,
    setSections,
  }
}
