import { DownloadReportForm } from "@app/component"
import { Title } from "@app/component/title"
import { useStreamApi } from "@app/hook/api"
import { useDownloadReport } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const SalePackageCoinReport: React.FC = () => {
  const { t } = useTranslation()
  const { notificationStore: notification } = useStore()
  const { payment } = useStreamApi()
  const { download, loadingPercentage, error } = useDownloadReport(
    payment("/admin/sale-package-coin-report").method("get").create(),
    { autoDownload: true },
  )

  useEffect(() => {
    if (error) {
      notification.add({
        color: "danger",
        title: t("common.noti.error"),
        text: error.message,
      })
    }
  }, [error, notification, t])

  return (
    <>
      <Title text={t("nav.sale-package-coin-title")} />
      <DownloadReportForm
        onSubmit={(p) => {
          download({
            "filter.startDate": p.startDate?.format("YYYY-MM-DD"),
            "filter.endDate": p.endDate?.format("YYYY-MM-DD"),
          })
        }}
        loadingPercentage={loadingPercentage}
      />
    </>
  )
}
