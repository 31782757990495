import { EuiSwitch } from "@elastic/eui"

type ToggleVisibleProps = {
  onChange: () => void
  visible: boolean
}

export const ToggleVisible = (props: ToggleVisibleProps) => {
  return (
    <div className="z-20 mt-5 cursor-pointer">
      <EuiSwitch
        onChange={props.onChange}
        checked={props.visible}
        label="Toggle visible"
        showLabel={false}
        aria-label="Toggle visible"
      />
    </div>
  )
}
