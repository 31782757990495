import React from "react"
import {
  EuiButton,
  EuiButtonIcon,
  EuiCheckbox,
  EuiFieldText,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSplitPanel,
  EuiText,
  EuiTextArea,
} from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { LayoutStyleRadio } from "../components/style-radio"
import { LayoutTypes, MenuTypes } from "../hook/model"
import { UilAngleRight, UilCog } from "@iconscout/react-unicons"
import classNames from "classnames"
import { BannerDetailProps } from "../small-banner/hook/banner"
import { SmallBannerDetailConfigProps } from "../small-banner/hook/update-detail"
import {
  FixedBannerDetailConfigProps,
  OverflowBannerDetailConfigProps,
} from "../medium-banner/hook/update"
import { WriterBannerDetailConfigProps } from "../writer-banner/hook/update-detail"

const BANNER_MAX_TITLE_LENGTH = 50

export const ImagePreview = (props: {
  src: string
  alt: string
  className?: string
}) => {
  return (
    <img
      src={props.src}
      alt={props.alt}
      className={classNames(
        "max-h-10 w-auto rounded-md bg-gray-50 object-cover",
        props.className,
      )}
    />
  )
}

const MenuPanel = (
  props: BannerDetailProps["banner"][number] & {
    className?: string
    onClick?: () => void
  },
) => {
  const { t } = useTranslation()
  return (
    <EuiPanel
      hasShadow={false}
      hasBorder
      className={classNames(
        "flex h-20 items-center justify-between rounded-md border border-solid px-4 py-2",
        props.className,
      )}
    >
      <EuiPanel
        paddingSize="s"
        aria-label="Drag Handle"
        hasShadow={false}
        color="transparent"
        className="flex items-center gap-x-2"
      >
        <ImagePreview src={props.cover} alt={props.name} />
        <div>
          <EuiText className="font-bold">{props.name}</EuiText>
          {props.duration && (
            <EuiText size="xs" color="subdued">
              {t("date-range", {
                value: props.duration,
              })}
            </EuiText>
          )}
          {props.numberOfWorks && (
            <EuiText size="xs" color="subdued">
              {t("home-management.number-of-works", {
                count: parseInt(props.numberOfWorks, 10),
              })}
            </EuiText>
          )}
        </div>
      </EuiPanel>
      <div className="ml-auto flex items-center gap-x-2">
        <EuiButtonIcon
          iconType={() => <UilAngleRight width={20} height={20} />}
          onClick={props.onClick}
          size="m"
          aria-label="Edit"
        />
      </div>
    </EuiPanel>
  )
}

type BannerListProps = {
  menu: Partial<MenuTypes>
  activeBanner: BannerDetailProps
  toggleSwitch?: React.ReactNode
  pendingBanner?: BannerDetailProps
  onClickManage: () => void
  onClickEdit: (bannerKey: string) => void
} & (
  | {
      menu: MenuTypes.smallBanner | MenuTypes.writer
    }
  | {
      menu: MenuTypes.midBannerOverflow
      pendingBanner: BannerDetailProps
      form: OverflowBannerDetailConfigProps
    }
  | {
      menu: MenuTypes.midBannerContained
      form: FixedBannerDetailConfigProps
    }
)

export const BannerList = ({
  menu,
  activeBanner,
  pendingBanner,
  toggleSwitch,
  onClickEdit,
  onClickManage,
}: BannerListProps) => {
  const { t } = useTranslation()
  return (
    <>
      <EuiSplitPanel.Outer hasShadow={false} hasBorder>
        <EuiSplitPanel.Inner color="subdued" grow={false}>
          <EuiText className="font-bold">
            {t("home-management.banner.banner-list")}
          </EuiText>
        </EuiSplitPanel.Inner>
        {!activeBanner || (menu === "midBannerOverflow" && !pendingBanner) ? (
          <div className="grid h-40 w-full place-items-center">
            <EuiLoadingSpinner size="xl" />
          </div>
        ) : (
          <EuiSplitPanel.Inner grow>
            <div className="flex flex-col gap-y-6">
              <EuiButton
                className="w-36"
                iconType={UilCog}
                onClick={onClickManage}
              >
                {t("home-management.banner.manage-button")}
              </EuiButton>
              {toggleSwitch}
              <div className="flex flex-col gap-y-2">
                <EuiText size="s" className="font-bold">
                  {
                    t("home-management.banner.active-count", {
                      count: Number(activeBanner.total) || 0,
                    }) as string
                  }
                </EuiText>
                <div className="flex flex-col gap-y-4">
                  {activeBanner.banner.map((banner) => (
                    <MenuPanel
                      id={banner.id}
                      duration={banner.duration}
                      numberOfWorks={banner.numberOfWorks}
                      name={banner.name}
                      cover={banner.cover}
                      link={banner.link}
                      key={banner.id}
                      onClick={() => onClickEdit(banner.bannerKey!)}
                    />
                  ))}
                </div>
              </div>
              {pendingBanner && (
                <div className="flex flex-col gap-y-2">
                  <EuiText size="s" className="font-bold">
                    {
                      t("home-management.banner.pending-count", {
                        count: Number(pendingBanner.total) || 0,
                      }) as string
                    }
                  </EuiText>
                  <div className="flex flex-col gap-y-4">
                    {pendingBanner.banner?.map((banner) => (
                      <MenuPanel
                        id={banner.id!}
                        name={banner.name!}
                        cover={banner.cover!}
                        link={banner.link!}
                        duration={{
                          startAt: banner.duration?.startAt!,
                          endAt: banner.duration?.endAt!,
                        }}
                        key={banner.id}
                        onClick={() => onClickEdit(banner.bannerKey!)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </EuiSplitPanel.Inner>
        )}
      </EuiSplitPanel.Outer>
    </>
  )
}

export const BannerConfigSection = (
  props: {
    type: MenuTypes
    availableLayouts: LayoutTypes[]
    children: React.ReactNode
    isLoading: boolean
  } & (
    | {
        type: MenuTypes.smallBanner
        form: SmallBannerDetailConfigProps
      }
    | {
        type: MenuTypes.midBannerOverflow
        form: OverflowBannerDetailConfigProps
      }
    | {
        type: MenuTypes.midBannerContained
        form: FixedBannerDetailConfigProps
      }
    | {
        type: MenuTypes.writer
        form: WriterBannerDetailConfigProps
      }
  ),
) => {
  const { t } = useTranslation()
  const form = props.form

  return (
    <div className="flex flex-col gap-y-6">
      {props.isLoading && (
        <div className="absolute right-2 top-0">
          <EuiLoadingSpinner size="m" />
        </div>
      )}
      <div className="flex flex-col gap-y-2">
        <EuiFormRow
          label={t("home-management.banner.name")}
          helpText={t("home-management.max-length-help-text", {
            current: form.values.title?.length,
            max: BANNER_MAX_TITLE_LENGTH,
          })}
        >
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={form.values.title}
            maxLength={BANNER_MAX_TITLE_LENGTH}
            isInvalid={!!form.errors.title}
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </EuiFormRow>
        <EuiFormRow className="mt-0">
          <EuiCheckbox
            id="showTitle"
            label={t("home-management.banner.show-title")}
            checked={form.values.shouldShowTitle}
            onChange={(e) => {
              form.setFieldValue("shouldShowTitle", e.target.checked)
            }}
          />
        </EuiFormRow>
      </div>

      <LayoutStyleRadio
        availableLayouts={props.availableLayouts}
        layout={form.values.layout!}
        onChange={(id) => {
          form.setFieldValue("layout", id)
        }}
      />
      {props.children}
      <div>
        <div className="mt-4 flex flex-col gap-2">
          <EuiText size="xs">
            <h3>{t("home-management.main-menu.noted")}</h3>
          </EuiText>
          <EuiText size="xs">
            <p className="text-eui-subduedText">
              {t("home-management.main-menu.noted-hint")}
            </p>
          </EuiText>
        </div>

        <EuiTextArea
          className="mt-2"
          fullWidth
          value={form.values.description}
          onChange={(e) => form.setFieldValue("description", e.target.value)}
        />
      </div>
    </div>
  )
}
