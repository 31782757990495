declare global {
  interface Window {
    _env_: {
      [key: string]: string
    }
  }
}

export const publicConfig = {
  price: {
    decimals: 6,
    tenPowerOfDecimals: 1e6,
  },
  shopEndpoint:
    window._env_?.REACT_APP_SHOP_SERVICE_ENDPOINT || "http://localhost:10002",
  shopSellerEndpoint:
    window._env_?.REACT_APP_SHOP_SELLER_SERVICE_ENDPOINT ||
    "http://localhost:10001",
  authWebEndpoint:
    window._env_?.REACT_APP_AUTH_WEB_SERVICE_ENDPOINT ||
    "http://localhost:10201",
  authAdminEndpoint:
    window._env_?.REACT_APP_AUTH_ADMIN_SERVICE_ENDPOINT ||
    "http://localhost:10202",
  paymentEndpoint:
    window._env_?.REACT_APP_PAYMENT_SERVICE_ENDPOINT ||
    "http://localhost:10401",
  promotionEndpoint:
    window._env_?.REACT_APP_PROMOTION_SERVICE_ENDPOINT ||
    "http://localhost:10703",
  webBffEndpoint:
    window._env_?.REACT_APP_WEB_BFF_SERVICE_ENDPOINT ||
    "http://localhost:20000",
  webUtilityEndpoint:
    window._env_?.REACT_APP_WEB_UTILITY_SERVICE_ENDPOINT ||
    "http://localhost:10302",
  orderEndpoint:
    window._env_?.REACT_APP_ORDER_SERVICE_ENDPOINT || "http://localhost:10103",
  eLibraryEndpoint:
    window._env_?.REACT_APP_ELIBRARY_SERVICE_ENDPOINT ||
    "http://localhost:11001",
  deliveryEndpoint:
    window._env_?.REACT_APP_DELIVERY_SERVICE_ENDPOINT ||
    "http://localhost:10905",
  webBaseUri: window._env_?.REACT_APP_WEB_BASE_URL || "http://localhost:3000",
  webStaticRoute: window._env_?.REACT_APP_WEB_STATIC_ROUTE || "/static",
  publicUrl: window._env_?.REACT_APP_PUBLIC_URL || "",
  richTextUploadImageMaxFileCount: window._env_
    ?.RICH_TEXT_UPLOAD_IMAGE_MAX_FILE_COUNT
    ? parseInt(window._env_.RICH_TEXT_UPLOAD_IMAGE_MAX_FILE_COUNT)
    : 20,
  chapterPerVolume: {
    condition: 100,
    small: 20,
    big: 50,
  },
  featureGiftCode: window._env_?.FEATURE_GIFT_CODE === "true",
  novel: window._env_?.FEATURE_NOVEL === "true" || false,
  eBookAudio: window._env_?.FEATURE_EBOOK_AUDIO === "true" || false,
  physical: window._env_?.FEATURE_PHYSICAL === "true" || false,
  eVoucher: window._env_?.FEATURE_E_VOUCHER === "true" || false,
  isFeatureInternationalShipping:
    window._env_?.FEATURE_INTERNATIONAL_SHIPPING === "true" || false,
  isFeatureCancelOrder: window._env_?.FEATURE_CANCEL_ORDER === "true" || false,
  defaultMaxProductRowInPromotion: window._env_
    ?.DEFAULT_MAX_PRODUCT_IN_ROW_PROMOTION
    ? parseInt(window._env_?.DEFAULT_MAX_PRODUCT_IN_ROW_PROMOTION, 10)
    : 100000,
  enableNovelProductTypeFlexibleHomepageManagement:
    window._env_?.ENABLE_NOVEL_PRODUCT_TYPE_FLEXIBLE_HOMEPAGE_MANAGEMENT ===
      "true" || false,
  enableEBookAudioProductTypeFlexibleHomepageManagement:
    window._env_
      ?.ENABLE_EBOOK_AUDIO_PRODUCT_TYPE_FLEXIBLE_HOMEPAGE_MANAGEMENT ===
      "true" || false,
  enableEVoucherProductTypeFlexibleHomepageManagement:
    window._env_?.ENABLE_E_VOUCHER_PRODUCT_TYPE_FLEXIBLE_HOMEPAGE_MANAGEMENT ===
      "true" || false,
  loyalty: window._env_?.FEATURE_LOYALTY === "true" || false,
  enableFlexibleHomepageManagement:
    window._env_?.ENABLE_FLEXIBLE_HOMEPAGE_MANAGEMENT === "true" || false,
}
