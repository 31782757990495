import { useApi } from "@app/hook/api"
import { useDebouncedCallback } from "use-debounce"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useHomeManagementStore } from "../../store/ctx"
import * as Yup from "yup"
import { publicConfig } from "@app/config"
import { useStore } from "@app/hook/store"
import { useTranslation } from "react-i18next"
import { useState } from "react"

type PopularProductDetailProps =
  definitions["webutility.v1.PopularProductSection"] & {
    adminSelectedProducts?: definitions["webutility.admin.v1.AdminSelectedProductWithShop"][]
    sectionKey?: string
  }

const enableAllProductType =
  publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement ||
  publicConfig.enableNovelProductTypeFlexibleHomepageManagement
const enableNovelProductType =
  publicConfig.enableNovelProductTypeFlexibleHomepageManagement
const enableEBookAudioProductType =
  publicConfig.enableEBookAudioProductTypeFlexibleHomepageManagement
const enableEVoucherProductType =
  publicConfig.enableEVoucherProductTypeFlexibleHomepageManagement

export enum ProductSectionError {
  ProductTypeIsNotSupported = "product-type-is-not-supported",
}
export const useUpdatePopularProducts = (sectionKey: string) => {
  const { webUtility } = useApi()
  const { refreshHomepageSection } = useHomeManagementStore()
  const notificationStore = useStore().notificationStore
  const { t } = useTranslation()
  const updatePopularProductApi = webUtility("/admin/popular-product-section")
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(
    async (input: PopularProductDetailProps) => {
      try {
        await updatePopularProductApi({
          sectionKey,
          input: {
            title: input.title,
            description: input.description,
            layout: input.layout,
            shouldShowTitle: input.shouldShowTitle,
            adminSelectedProductKeys: input.adminSelectedProductKeys,
            displayOption: input.displayOption,
            displayProductType: input.displayProductType,
            shouldShowProductRanking: input.shouldShowProductRanking,
          },
        }).then(() => {
          refreshHomepageSection()
        })
        return { id: sectionKey }
      } catch (error) {
        if (error instanceof Error) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: t(errToDefaultI18nKey(error)),
          })
        }
      }
    },
    300,
  ) as (
    form: PopularProductDetailProps,
    id?: string,
  ) => Promise<{ id?: string }>

  const onSubmitted = () => {}

  const onGetItem = async (): Promise<PopularProductDetailProps> => {
    return {}
  }

  const form = useFormHelper<PopularProductDetailProps, { id?: string }>({
    id: sectionKey,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError: (err: any) => {
      if (err instanceof Error) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: t(errToDefaultI18nKey(err)),
        })
      }
    },
    validationSchema: Yup.object({
      displayProductType: Yup.string().test(
        "supported-product-type",
        ProductSectionError.ProductTypeIsNotSupported,
        (productType) => {
          if (productType === undefined) return true
          return (
            productType === "PRODUCT_TYPE_PHYSICAL" ||
            (productType === "PRODUCT_TYPE_E_BOOK_AUDIO" &&
              enableEBookAudioProductType) ||
            (productType === "PRODUCT_TYPE_NOVEL" && enableNovelProductType) ||
            (productType === "PRODUCT_TYPE_E_VOUCHER" &&
              enableEVoucherProductType)
          )
        },
      ),
    }),
  })

  return form
}

export type PopularProductDetailConfigProps = ReturnType<
  typeof useUpdatePopularProducts
>

type RecommendedProductDetailProps =
  definitions["webutility.v1.RecommendedProductSection"] & {
    adminSelectedProductKeys?: {
      productKey: string
      productType: definitions["shop.v1.ProductType"]
    }[]
    sectionKey?: string
  }

export const useUpdateRecommendedProducts = (sectionKey: string) => {
  const { webUtility } = useApi()
  const { refreshHomepageSection } = useHomeManagementStore()
  const notificationStore = useStore().notificationStore
  const { t } = useTranslation()

  const updatePopularProductApi = webUtility(
    "/admin/recommended-product-section",
  )
    .method("put")
    .create()

  const onSubmit = useDebouncedCallback(
    async (input: RecommendedProductDetailProps) => {
      try {
        await updatePopularProductApi({
          sectionKey,
          input: {
            title: input.title,
            description: input.description,
            layout: input.layout,
            shouldShowTitle: input.shouldShowTitle,
            adminSelectedProductKeys: input.adminSelectedProductKeys,
            displayOption: input.displayOption,
            displayProductType: input.displayProductType,
          },
        }).then(() => {
          refreshHomepageSection()
        })
        return { id: sectionKey }
      } catch (error) {
        if (error instanceof Error) {
          notificationStore.add({
            title: "Error",
            color: "danger",
            text: t(errToDefaultI18nKey(error)),
          })
        }
      }
    },
    300,
  ) as (
    form: RecommendedProductDetailProps,
    id?: string,
  ) => Promise<{ id?: string }>

  const onSubmitted = () => {}

  const onGetItem = async (): Promise<RecommendedProductDetailProps> => {
    return {}
  }

  const form = useFormHelper<RecommendedProductDetailProps, { id?: string }>({
    id: sectionKey,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError: (err: any) => {
      if (err instanceof Error) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: t(errToDefaultI18nKey(err)),
        })
      }
    },
    validationSchema: Yup.object({
      displayProductType: Yup.string().test(
        "supported-product-type",
        ProductSectionError.ProductTypeIsNotSupported,
        (productType) => {
          if (productType === undefined) return true
          return (
            (productType === "DISPLAY_RECOMMENDED_PRODUCT_TYPE_ALL" &&
              enableAllProductType) ||
            productType === "DISPLAY_RECOMMENDED_PRODUCT_TYPE_PHYSICAL" ||
            (productType === "DISPLAY_RECOMMENDED_PRODUCT_TYPE_E_BOOK_AUDIO" &&
              enableEBookAudioProductType) ||
            (productType === "DISPLAY_RECOMMENDED_PRODUCT_TYPE_NOVEL" &&
              enableNovelProductType) ||
            (productType === "DISPLAY_RECOMMENDED_PRODUCT_TYPE_E_VOUCHER" &&
              enableEVoucherProductType)
          )
        },
      ),
    }),
  })

  return form
}

export type RecommendedProductDetailConfigProps = ReturnType<
  typeof useUpdatePopularProducts
>

export const useAdminProductApi = () => {
  const { webBff } = useApi()
  const [loading, setLoading] = useState(false)
  const getter = webBff("/admin/product").method("post").create()
  const [error, setError] = useState<unknown>()
  const { notificationStore } = useStore()
  const { t } = useTranslation()

  const getProduct = async (
    productKeys: definitions["webutility.admin.v1.HighlightProductKey"][],
  ) => {
    setLoading(true)
    try {
      if (productKeys.length === 0) {
        return undefined
      }

      const { data } = await getter({
        productKeys,
      })

      return data
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const getExistProductIDs = async (
    productKeys: definitions["webutility.admin.v1.HighlightProductKey"][],
  ) => {
    try {
      if (productKeys.length === 0) {
        return []
      }

      const products = await getProduct(productKeys)
      return products?.products
        ?.map((p) => {
          if (p.product?.eBookAudio?.id) {
            return p.product?.eBookAudio?.id
          }

          if (p.product?.novel?.id) {
            return p.product?.novel?.id
          }

          if (p.product?.physicalProduct?.id) {
            return p.product?.physicalProduct?.id
          }

          if (p.product?.eVoucher?.id) {
            return p.product?.eVoucher?.id
          }

          return ""
        })
        .filter((id) => id)
    } catch (err) {
      if (err instanceof Error) {
        notificationStore.add({
          title: "Error",
          color: "danger",
          text: t(errToDefaultI18nKey(err)),
        })
      }
    }
  }

  return {
    error,
    getProduct,
    loading,
    getExistProductIDs,
  }
}
