import { EuiButtonEmpty } from "@elastic/eui"
import { UilEye, UilEyeSlash } from "@iconscout/react-unicons"
import { useTranslation } from "react-i18next"

export const HideProductButton: React.FC<{
  onClick: () => void
  isHide: boolean | undefined
}> = (props) => {
  const { t } = useTranslation()

  return props.isHide ? (
    <EuiButtonEmpty
      color="text"
      className="h-8 w-32 border border-solid border-primary text-primary"
      onClick={props.onClick}
    >
      <div className="flex items-center space-x-2">
        <UilEye width={16} height={16} />
        <div className="text-s">{t("shop-information.unhide")}</div>
      </div>
    </EuiButtonEmpty>
  ) : (
    <EuiButtonEmpty
      color="text"
      className="h-8 w-32 border border-solid border-eui-darkShade text-eui-darkShade"
      onClick={props.onClick}
    >
      <div className="flex items-center space-x-2">
        <UilEyeSlash width={16} height={16} />
        <div className="text-s">{t("shop-information.hide")}</div>
      </div>
    </EuiButtonEmpty>
  )
}
