import { EuiText } from "@elastic/eui"
import classNames from "classnames"
import React from "react"

type withdrawDataCardProps = {
  section: {
    title: string
    data: {
      label: string
      value: string | React.ReactNode
    }[]
  }[]

  containerTextCss?: string
}

export const WithdrawAccountCard = (props: withdrawDataCardProps) => {
  return (
    <div className="mb-4 rounded-lg border border-solid border-grey-light px-6 pb-6	">
      {props.section.map((s) => {
        return (
          <>
            <EuiText size="m" className="pt-6">
              <h4 className="font-bold">{s.title}</h4>
            </EuiText>
            <div>
              <div className="flex flex-col">
                {s.data.map((d) => {
                  return (
                    <div
                      className={classNames(
                        "mt-4 flex flex-row items-center ",
                        props.containerTextCss,
                      )}
                    >
                      <EuiText className="">
                        <h6 className="mb-0 w-[170px] text-[10.5px] font-bold leading-[14px]">
                          {d.label}
                        </h6>
                      </EuiText>
                      {d.value}
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}
