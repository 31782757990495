import * as Yup from "yup"
import { FileForm } from "../form"

export type CategoryHomeManagement = {
  id?: string
  cover?: FileForm
  name?: string
  url?: string
  downloadUrl?: string
}

export const categoryFormValidator = Yup.object({
  name: Yup.string().required(),
  url: Yup.string().required(),
  cover: Yup.object()
    .shape({
      fileInput: Yup.object().shape({
        tmpPath: Yup.string(),
        name: Yup.string(),
      }),
      downloadUrl: Yup.string(),
    })
    .default(undefined)
    .required(),
})
