import { EuiButton, EuiButtonEmpty, EuiIcon, EuiText } from "@elastic/eui"
import { css } from "@emotion/react"

type TitleProps = {
  text: string
  btn?: {
    text: string
    onClick: () => void
    iconName?: string
    style?: React.CSSProperties
  }
  backBtn?: {
    onClick: () => void
  }
}

export const Title: React.FC<TitleProps> = ({ text, btn, backBtn }) => {
  return (
    <div className={"mb-6 flex w-full justify-between"}>
      {backBtn ? (
        <EuiButtonEmpty
          css={css({
            ".euiButtonEmpty__content": {
              padding: "0px",
            },
            ".euiButtonContent": {
              padding: "0px",
            },
            ".euiButtonEmpty__text": {
              display: "flex",
              "flex-direction": "row",
              "align-items": "center",
            },
          })}
          color="text"
          onClick={() => {
            backBtn.onClick()
          }}
        >
          <EuiIcon type="arrowLeft" size="l" className="mr-2" />
          <EuiText>
            <h2 className="font-bold">{text}</h2>
          </EuiText>
        </EuiButtonEmpty>
      ) : (
        <EuiText>
          <h2 className="font-bold">{text}</h2>
        </EuiText>
      )}
      {btn && (
        <EuiButton
          color="primary"
          fill
          iconType={btn.iconName ? btn.iconName : undefined}
          style={{ width: 240, ...btn.style }}
          onClick={btn.onClick}
        >
          <EuiText size="xs">{btn.text}</EuiText>
        </EuiButton>
      )}
    </div>
  )
}
