import { ConfirmDeleteModal } from "@app/component"
import { useStore } from "@app/hook/store"
import { joinIds, splitIds } from "@app/model"
import { mapErrToI18n } from "@app/utils/error"
import { DropResult, EuiText, euiDragDropReorder } from "@elastic/eui"
import { appErrorToDefaultI18nKey, errToAppErr } from "@reeeed-mp/ui-common"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useCategoryAPI, useGetCategories } from "./hook/category"
import { StoreCategoryModal, StoreCategoryProps } from "./modal/store"
import { Category, bookCategoryIdPrefix, getCategoriesByLevel } from "./model"
import { CategoryPanel } from "./panel"

enum CategoryLevel {
  Parent = 0,
  Child = 1,
  GrandChild = 2,
}

export const CategoryPage: React.FC = () => {
  const { notificationStore: noti } = useStore()

  const {
    categories,
    isLoading: getCategoryLoading,
    fetch: fetchCategories,
  } = useGetCategories({
    onError: (err) =>
      noti.add({
        title: "Error",
        color: "danger",
        text: err.message,
      }),
  })

  const {
    loading: categoryLoading,
    create: createCategory,
    update: updateCategory,
    changeOrder: changeCategoryOrder,
    deleteOne: deleteOneCategory,
  } = useCategoryAPI()
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined)
  const [creatingCategoryLevel, setCreatingCategoryLevel] = useState<
    number | undefined
  >(undefined)
  const [editCategory, setEditCategory] = useState<Category | undefined>()
  const [deleteCategory, setDeleteCategory] = useState<Category | undefined>()
  const { t } = useTranslation("translation", { keyPrefix: "category" })
  const [isReOrdering, setIsReOrdering] = useState(false)
  const isLoading = getCategoryLoading && categoryLoading && isReOrdering
  const level1Categories = getCategoriesByLevel(categories, 1)
  const level2Categories = getCategoriesByLevel(categories, 2).filter((c) => {
    if (selectedCategoryId) {
      const parentIds = splitIds(selectedCategoryId)
      if (parentIds.length >= 1) {
        return c.parentId === parentIds[0]
      }
    }

    return false
  })

  const level3Categories = getCategoriesByLevel(categories, 3).filter((c) => {
    if (selectedCategoryId) {
      const parentIds = splitIds(selectedCategoryId)
      if (parentIds.length >= 2) {
        return c.parentId === joinIds(parentIds.slice(0, 2))
      }
    }

    return false
  })

  const getConfirmDeleteDescription = (
    splitIds: string[] | undefined,
  ): string => {
    if (!splitIds) {
      return ""
    }

    if (splitIds[0] === bookCategoryIdPrefix && splitIds.length === 1) {
      return ""
    }

    switch (splitIds.length) {
      case 1:
        return `${t("main-category")}: ${deleteCategory?.name}`
      case 2:
        return `${t("sub-category")}: ${deleteCategory?.name}`
      case 3:
        return `${t("nested-sub-category")}: ${deleteCategory?.name}`
    }

    return ""
  }

  const notificationHandler = {
    onError: (err: unknown) => {
      noti.add({
        title: "Error",
        color: "danger",
        text: t(mapErrToI18n("err", err)),
      })
    },
    onSuccess: () => {
      noti.add({ color: "success", title: "Success" })
    },
  }

  const onSubmit: StoreCategoryProps["onSubmit"] = async (
    name,
    slug,
    parentId,
    banners,
  ) => {
    if (editCategory?.id) {
      await updateCategory({
        data: {
          ...editCategory,
          id: editCategory.id,
          name,
          slug,
          banners,
        },
        ...notificationHandler,
      })

      await fetchCategories()

      return
    }

    await createCategory({
      data: {
        name,
        slug,
        parentId,
        banners,
      },
      ...notificationHandler,
    })

    await fetchCategories()
  }

  const newOnReOrder = (level: CategoryLevel) => async (result: DropResult) => {
    if (!result.source || !result.destination) {
      return
    }

    if (result.source.index === result.destination.index) {
      return
    }

    setIsReOrdering(true)

    try {
      let affectedCategories: Array<Category> = []
      switch (level) {
        case CategoryLevel.Parent:
          affectedCategories = euiDragDropReorder(
            level1Categories,
            result.source.index,
            result.destination!.index,
          )
          break
        case CategoryLevel.Child:
          affectedCategories = euiDragDropReorder(
            level2Categories,
            result.source.index,
            result.destination!.index,
          )
          break
        case CategoryLevel.GrandChild:
          affectedCategories = euiDragDropReorder(
            level3Categories,
            result.source.index,
            result.destination!.index,
          )
          break
      }

      const optimisticUpdatedCategories = categories.map((c) => {
        for (let i = 0; i < affectedCategories.length; i++) {
          const ac = affectedCategories[i]
          if (ac.id === c.id) {
            ac.order = i
          }
        }

        return c
      })

      await fetchCategories(
        async () => {
          await changeCategoryOrder({
            data: {
              id: result.draggableId,
              newOrder: result.destination!.index,
            },
            ...notificationHandler,
          })

          return optimisticUpdatedCategories
        },
        {
          optimisticData: optimisticUpdatedCategories,
        },
      )
    } catch (err) {
      const e = errToAppErr(err)
      const i18nKey = appErrorToDefaultI18nKey(e)
      noti.add({
        color: "danger",
        title: t("common.noti.error"),
        text: t(i18nKey),
      })
    }

    setIsReOrdering(false)
  }

  return (
    <div className="flex flex-col bg-white">
      <div className="flex justify-between">
        <div>
          <EuiText className="mb-6">
            <h2 className="font-bold">{t("title")}</h2>
          </EuiText>
        </div>
      </div>
      <div>
        <EuiText className="mb-2 font-bold">{t("main-category")}</EuiText>
      </div>
      {creatingCategoryLevel || editCategory ? (
        <div>
          <StoreCategoryModal
            creatingCategoryLevel={creatingCategoryLevel}
            editing={
              editCategory
                ? {
                    id: editCategory.id,
                    name: editCategory.name,
                    slug: editCategory.slug,
                    banners: editCategory.banners || [],
                  }
                : undefined
            }
            onCloseModal={() => {
              setEditCategory(undefined)
              setCreatingCategoryLevel(undefined)
              fetchCategories()
            }}
            onSubmit={onSubmit}
            onError={(msg) => {
              noti.add({
                title: "Error",
                color: "danger",
                text: msg,
              })
            }}
          />
        </div>
      ) : null}
      {deleteCategory ? (
        <ConfirmDeleteModal
          description={getConfirmDeleteDescription(splitIds(deleteCategory.id))}
          onConfirm={async () => {
            await deleteOneCategory({
              id: deleteCategory.id,
              ...notificationHandler,
            })
            setDeleteCategory(undefined)
            await fetchCategories()
          }}
          onClose={() => {
            setDeleteCategory(undefined)
          }}
        />
      ) : null}

      <div className="grid grid-cols-3 grid-rows-1 gap-x-8">
        <CategoryPanel
          selectedId={selectedCategoryId}
          categories={level1Categories}
          onCreate={() => setCreatingCategoryLevel(1)}
          onPressStore={setEditCategory}
          onPressDelete={setDeleteCategory}
          onSelectedCategoryId={setSelectedCategoryId}
          loading={isLoading}
          onDragEnd={newOnReOrder(CategoryLevel.Parent)}
        />

        <CategoryPanel
          selectedId={selectedCategoryId}
          categories={level2Categories}
          onCreate={() => setCreatingCategoryLevel(2)}
          onPressStore={setEditCategory}
          onPressDelete={setDeleteCategory}
          onSelectedCategoryId={setSelectedCategoryId}
          loading={isLoading}
          onDragEnd={newOnReOrder(CategoryLevel.Child)}
        />

        <CategoryPanel
          selectedId={selectedCategoryId}
          categories={level3Categories}
          onCreate={() => setCreatingCategoryLevel(3)}
          onPressStore={setEditCategory}
          onPressDelete={setDeleteCategory}
          onSelectedCategoryId={setSelectedCategoryId}
          loading={isLoading}
          onDragEnd={newOnReOrder(CategoryLevel.GrandChild)}
        />
      </div>
    </div>
  )
}
