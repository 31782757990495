export const seoHomepageId = "homepage"
export const seoIdPrefix = "category"
export const seoIdSeparator = "."

export const extractCategoryIdFromSeoId = (
  seoId: string | undefined,
): string => {
  if (seoId === undefined) {
    return ""
  }
  if (seoId.startsWith(seoIdPrefix)) {
    return seoId.split(seoIdSeparator)[1]
  } else {
    return seoId
  }
}
