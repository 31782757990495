import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { Klass, LexicalNode, LexicalNodeReplacement } from "lexical"
import { ImageNode } from "./image/image-node"
import { YouTubeNode } from "./youtube/node"

export const allNodes: Array<Klass<LexicalNode> | LexicalNodeReplacement> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  ImageNode,
  YouTubeNode,
  LinkNode,
  AutoLinkNode,
]
