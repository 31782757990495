import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { publicConfig } from "@app/config"
import { TitleAndButtonGroup } from "./product-title-button"
import { useApi } from "@app/hook/api"
import { constants } from "@app/model/constants"
import {
  MpTextEditor,
  parseEditorState,
  stringifiedEmptyEditorState,
} from "@reeeed-mp/text-editor"
import { PaginationOption } from "@reeeed-mp/ui-common"
import React from "react"
import { ChapterList } from "@app/component/chapter-list"
import { useGetNovel } from "./hook"
import { ProductHiddenBadge } from "./product-hidden-badge"

export const NovelManagement = () => {
  const { t } = useTranslation()
  const { shopId, productId } = useParams()

  const shopProduct = useGetNovel(shopId ?? "", productId ?? "")
  const novel = shopProduct.data?.novel
  const isHidden = novel?.novel?.novel?.isHidden

  return (
    <div className="flex h-full w-full flex-col space-y-4 px-4">
      <TitleAndButtonGroup />
      <div className="flex w-full flex-row rounded-md border border-solid border-slate-300 py-3">
        <div className="flex w-1/2 flex-col py-4">
          <div className="w-full text-center">
            {t("product.revenue-header")}
          </div>
          <div className="mt-2 w-full text-center text-xl font-semibold">
            {t("product.revenue", {
              revenue: Number(novel?.revenue) / constants.power10OfDecimalPrice,
            })}
          </div>
        </div>
        <div className="flex w-1/2 flex-col border border-y-0 border-r-0 border-solid border-slate-300 py-4">
          <div className="w-full text-center">{t("product.sell-count")}</div>
          <div className="mt-2 w-full text-center text-xl font-semibold">
            {t("product.buy-count", {
              buyCount: novel?.buyCount,
            })}
          </div>
        </div>
      </div>
      <div className="flex gap-4 w-full flex-row pt-6">
        <div className="w-96">
          {novel?.novel?.coverUrl ? (
            <img
              src={novel?.novel?.coverUrl}
              alt=""
              className="w-60 rounded-lg"
            />
          ) : (
            <div className="h-96 w-60 rounded-lg bg-slate-200"></div>
          )}
        </div>
        <div className="w-full">
          <div className="flex">
            <div className="w-2/3 text-xl font-semibold">
              {novel?.novel?.novel?.name}
            </div>
            <div className="w-1/3 justify-items-end">
              <ProductHiddenBadge isHidden={isHidden} />
            </div>
          </div>
          {novel?.novel?.novel?.updateFrequency ? (
            <div className="mt-4">{novel?.novel?.novel?.updateFrequency}</div>
          ) : null}
          {novel?.novel?.novel?.tags?.length ? (
            <div className="mt-4 flex flex-wrap text-base">
              {(novel?.novel?.novel?.tags ?? []).map((tag) => (
                <div className="mb-2 mr-2 rounded-md bg-slate-200 px-2 py-1">
                  {tag}
                </div>
              ))}
            </div>
          ) : null}
          <div className="mt-4 flex flex-row text-base">
            <div className="w-1/2">
              <div className="font-semibold">{t("product.license-type")}</div>
              <div className="mt-2 text-gray-600">
                {t(
                  `product.${novel?.novel?.novel?.licenseType ?? "LICENSE_TYPE_UNSPECIFIED"}`,
                )}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-semibold">{t("product.category")}</div>
              <div className="mt-2 text-gray-600">
                {(novel?.novel?.categories ?? []).map((category, index) => (
                  <span>
                    {index === 0 ? category?.name : `, ${category?.name}`}
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row text-base">
            <div className="w-1/2">
              <div className="font-semibold">{t("product.author")}</div>
              <div className="mt-2 text-gray-600">
                {novel?.novel?.novel?.author ?? "-"}
              </div>
            </div>
            <div className="w-1/2">
              <div className="font-semibold">{t("product.rating")}</div>
              <div className="mt-2 text-gray-600">
                {t(
                  `product.${novel?.novel?.novel?.rating ?? "CONTENT_RATING_UNSPECIFIED"}`,
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-row text-base">
            <div className="w-full">
              <div className="font-semibold">{t("product.translator")}</div>
              <div className="mt-2 text-gray-600">
                {novel?.novel?.novel?.translator ?? "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border border-x-0 border-t-0 border-solid border-slate-300 py-6">
        <div className="mb-2 text-xl font-semibold">
          {t("product.introduction")}
        </div>
        <MpTextEditor
          isEditable={false}
          initEditorState={parseEditorState(
            novel?.novel?.novel?.introduction ?? stringifiedEmptyEditorState,
          )}
        />
      </div>
      <div className="w-full py-6">
        <div className="text-xl font-semibold">{t("product.list")}</div>
        <div className="mt-2 text-slate-400">
          {t("product.chapter-count", {
            count: Number(novel?.chapterCount),
          })}
        </div>
        {Number(novel?.chapterCount) > 0 ? (
          <div className="mt-2">
            <ListNovelChapters
              novelId={novel?.novel?.novel?.id ?? ""}
              chapterCount={Number(novel?.chapterCount)}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

type NovelChapterProps = {
  id: string
  order: number
  name: string
  price?: number
  rentalPrice?: number
  isPublishing: boolean
  isTicketEnabled: boolean
  novelId: string
  isFree: boolean
}

const ListNovelChapters: React.FC<{
  novelId: string
  chapterCount: number
}> = ({ novelId, chapterCount }) => {
  const { shop } = useApi()
  const { t } = useTranslation()

  const getNovelChapters = async (novelId: string, opt: PaginationOption) => {
    const getChapters = shop("/novel/{novelId}/chapter").method("get").create()
    const resp = await getChapters({
      novelId: novelId ?? "",
      "option.limit": opt.limit,
      "option.skip": opt.skip,
    })
    return resp.data.novelChapters
  }

  const onGetItems = useCallback(
    async (opt: {
      skip: number
      limit: number
    }): Promise<Array<NovelChapterProps>> => {
      const sellerNovelChapters = await getNovelChapters(novelId, opt)
      const now = new Date()

      return (sellerNovelChapters ?? []).map((chapter): NovelChapterProps => {
        return {
          id: chapter.id!,
          novelId: novelId!,
          order: chapter.order!,
          name: chapter.name!,
          price:
            chapter.price !== undefined && !isNaN(parseInt(chapter.price))
              ? parseInt(chapter.price) / publicConfig.price.tenPowerOfDecimals
              : undefined,
          rentalPrice:
            chapter.isRentable &&
            chapter.rental?.price !== undefined &&
            !isNaN(parseInt(chapter.rental.price))
              ? parseInt(chapter.rental.price) /
                publicConfig.price.tenPowerOfDecimals
              : undefined,
          isPublishing: chapter.publishAt
            ? new Date(chapter.publishAt) <= now
            : false,
          isTicketEnabled: !!chapter.isTicketEnabled,
          isFree: !!chapter.isFree,
        }
      })
    },
    [getNovelChapters, novelId],
  )

  const getChapterPerVolume = (chapterCount: number) => {
    return chapterCount < publicConfig.chapterPerVolume.condition
      ? publicConfig.chapterPerVolume.small
      : publicConfig.chapterPerVolume.big
  }

  return (
    <ChapterList<NovelChapterProps>
      emptyStateComp={() => <NovelChapterEmptyState />}
      getItems={onGetItems}
      itemComp={(item) => <ItemComp item={item} />}
      limitItemPerGroup={getChapterPerVolume(chapterCount || 0)}
      totalItemCount={chapterCount || 0}
      accordionPrefix={t("product.chapter-prefix")}
    />
  )
}

export const NovelChapterEmptyState = () => {
  return <div className="flex w-full items-center justify-center">no data</div>
}

export const ItemComp: React.FC<{
  item: NovelChapterProps
}> = ({ item }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex w-full flex-row">
        <div className="flex w-1/2">
          <div className="w-2/6 pl-2">
            {t("product.chapter", {
              chapter: item.order,
            })}
          </div>
          <div className="w-4/6 font-semibold">{item.name}</div>
        </div>
        <div className="flex w-1/2">
          <div className="w-2/6 text-center">
            {item.isTicketEnabled ?? "Ticket"}
          </div>
          <div className="w-3/6 text-right">
            {t("product.novel-chapter-sell-price", {
              price: item.price,
            })}
            {item.rentalPrice &&
              t("product.novel-chapter-rent-price", {
                price: item.rentalPrice,
              })}
          </div>
          <div className="w-1/6"></div>
        </div>
      </div>
    </>
  )
}
