import { useAvailableFeature } from "@app/hook/feature"
import { useStore } from "@app/hook/store"
import { constants } from "@app/model/constants"
import {
  EuiButton,
  EuiFieldNumber,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiToolTip,
} from "@elastic/eui"
import { css } from "@emotion/react"
import {
  UilBook,
  UilBooks,
  UilHeadphonesAlt,
  UilShoppingBag,
  UilTicket,
} from "@iconscout/react-unicons"
import { useFormHelper } from "@reeeed-mp/ui-common"
import React, { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useSWR from "swr"
import * as Yup from "yup"
import { useShop } from "../hook"

type ShopRevenueForm = {
  eBookPlatformRevenueRatio?: number
  audioPlatformRevenueRatio?: number
  novelPlatformRevenueRatio?: number
  physicalProductPlatformRevenueRatio?: number
  eVoucherPlatformRevenueRatio?: number
}
const formValidator = Yup.object({})

const ShopRevenueRatioToPercentage = (ratio: string): number => {
  return parseInt(ratio, 10) / constants.power10OfHundred
}

const FormRevenueCard = (props: { title: string; form: ReactNode }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="w-1/3">
        <EuiText size="m">{props.title}</EuiText>
      </div>
      <div className="flex w-2/3 gap-2">{props.form}</div>
    </div>
  )
}

export const ContainerShopRevenueCard = (props: { editable?: boolean }) => {
  const { storeShopRevenue, getShopRevenue } = useShop()
  const [shopRevenueModal, setShopRevenueModal] = useState(false)
  const { shopId } = useParams()
  const { t } = useTranslation()
  const { t: shopT } = useTranslation("translation", {
    keyPrefix: "shop-information",
  })
  const { notificationStore: notification } = useStore()

  const feature = useAvailableFeature()

  const {
    data: shopRevenue,
    isLoading: isLoadingShopRevenue,
    mutate,
  } = useSWR([shopId, "/shop-revenue"], async () => {
    if (shopId) {
      return await getShopRevenue(shopId ?? "")
    }
    return
  })

  const {
    values: form,
    setFieldValue: setValue,
    setValues,
    submitForm: submit,
    getError,
  } = useFormHelper<ShopRevenueForm, { id: string | undefined }>({
    id: undefined,
    onGetItem: async (): Promise<ShopRevenueForm | undefined> => {
      return {}
    },
    onSubmit: async (form): Promise<{ id: string | undefined }> => {
      await storeShopRevenue({
        audioPlatformRevenueRatio: (
          (form.audioPlatformRevenueRatio ?? 30) * constants.power10OfHundred
        ).toString(),
        eBookPlatformRevenueRatio: (
          (form.eBookPlatformRevenueRatio ?? 30) * constants.power10OfHundred
        ).toString(),
        physicalProductPlatformRevenueRatio: (
          (form.physicalProductPlatformRevenueRatio ?? 30) *
          constants.power10OfHundred
        ).toString(),
        novelPlatformRevenueRatio: (
          (form.novelPlatformRevenueRatio ?? 30) * constants.power10OfHundred
        ).toString(),
        eVoucherPlatformRevenueRatio: (
          (form.eVoucherPlatformRevenueRatio ?? 7) * constants.power10OfHundred
        ).toString(),
        shopId,
      })
      return {
        id: "",
      }
    },
    onSubmitted: () => {
      notification.add({
        title: "Success",
        color: "success",
      })
      setShopRevenueModal(false)
      mutate()
    },
    onError: (msg: string) => {
      notification.add({
        title: "Error",
        color: "danger",
        text: msg,
      })
      setShopRevenueModal(false)
    },
    validationSchema: formValidator,
  })

  return (
    <>
      {shopRevenueModal ? (
        <EuiModal
          onClose={() => {
            setShopRevenueModal(false)
          }}
          css={css({
            maxHeight: "90vh",
            width: "500px",
          })}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              {shopT("shop-revenue.title")}
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <div className="flex flex-col gap-4">
              {feature.eBookAudio && (
                <FormRevenueCard
                  title={shopT("shop-revenue.e-book")}
                  form={
                    <>
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.platform")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={form.eBookPlatformRevenueRatio}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) < 0 ||
                            Number(e.target.value) > 100
                          ) {
                            return
                          }

                          setValue("eBookPlatformRevenueRatio", e.target.value)
                        }}
                        min={0}
                        max={100}
                        isInvalid={!!getError("eBookPlatformRevenueRatio")}
                      />
                      <EuiFieldNumber
                        prepend={t("promotion.editor.seller")}
                        compressed
                        placeholder={t("common.please-fill")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={100 - Number(form.eBookPlatformRevenueRatio)}
                        min={0}
                        disabled
                      />
                    </>
                  }
                />
              )}

              {feature.eBookAudio && (
                <FormRevenueCard
                  title={shopT("shop-revenue.audio-book")}
                  form={
                    <>
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.platform")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={form.audioPlatformRevenueRatio}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) < 0 ||
                            Number(e.target.value) > 100
                          ) {
                            return
                          }

                          setValue("audioPlatformRevenueRatio", e.target.value)
                        }}
                        min={0}
                        max={100}
                        isInvalid={!!getError("audioPlatformRevenueRatio")}
                      />
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.seller")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={100 - Number(form.audioPlatformRevenueRatio)}
                        min={0}
                        disabled
                      />
                    </>
                  }
                />
              )}

              {feature.novel && (
                <FormRevenueCard
                  title={shopT("shop-revenue.novel")}
                  form={
                    <>
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.platform")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={form.novelPlatformRevenueRatio}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) < 0 ||
                            Number(e.target.value) > 100
                          ) {
                            return
                          }

                          setValue("novelPlatformRevenueRatio", e.target.value)
                        }}
                        min={0}
                        max={100}
                        isInvalid={!!getError("novelPlatformRevenueRatio")}
                      />
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.seller")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={100 - Number(form.novelPlatformRevenueRatio)}
                        min={0}
                        disabled
                      />
                    </>
                  }
                />
              )}

              {feature.physical && (
                <FormRevenueCard
                  title={shopT("shop-revenue.physical")}
                  form={
                    <>
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.platform")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={form.physicalProductPlatformRevenueRatio}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) < 0 ||
                            Number(e.target.value) > 100
                          ) {
                            return
                          }

                          setValue(
                            "physicalProductPlatformRevenueRatio",
                            e.target.value,
                          )
                        }}
                        min={0}
                        max={100}
                        isInvalid={
                          !!getError("physicalProductPlatformRevenueRatio")
                        }
                      />

                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.seller")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={
                          100 - Number(form.physicalProductPlatformRevenueRatio)
                        }
                        min={0}
                        disabled
                      />
                    </>
                  }
                />
              )}

              {feature.eVoucher && (
                <FormRevenueCard
                  title={shopT("shop-revenue.e-voucher")}
                  form={
                    <>
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.platform")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={form.eVoucherPlatformRevenueRatio}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) < 0 ||
                            Number(e.target.value) > 100
                          ) {
                            return
                          }

                          setValue(
                            "eVoucherPlatformRevenueRatio",
                            e.target.value,
                          )
                        }}
                        min={0}
                        max={100}
                        isInvalid={!!getError("eVoucherPlatformRevenueRatio")}
                      />
                      <EuiFieldNumber
                        compressed
                        placeholder={t("common.please-fill")}
                        prepend={t("promotion.editor.seller")}
                        append={
                          <EuiToolTip content="content">
                            <EuiText size="s">
                              {t("promotion.editor.percentage-symbol")}
                            </EuiText>
                          </EuiToolTip>
                        }
                        value={100 - Number(form.eVoucherPlatformRevenueRatio)}
                        min={0}
                        disabled
                      />
                    </>
                  }
                />
              )}
            </div>
          </EuiModalBody>
          <EuiModalFooter>
            <div className="mt-4 flex w-full flex-row justify-between gap-x-4">
              <EuiButton
                fullWidth
                color="text"
                onClick={() => {
                  setShopRevenueModal(false)
                }}
              >
                {t("common.cancel")}
              </EuiButton>
              <EuiButton
                fill
                fullWidth
                onClick={() => {
                  submit()
                }}
              >
                {t("common.save")}
              </EuiButton>
            </div>
          </EuiModalFooter>
        </EuiModal>
      ) : null}
      <ShopRevenueCard
        editable={props.editable}
        onPressEdit={() => {
          setShopRevenueModal(true)

          setValues({
            eBookPlatformRevenueRatio: ShopRevenueRatioToPercentage(
              shopRevenue?.data?.shopRevenueRatio?.eBookPlatformRevenueRatio ??
                "3000",
            ),
            audioPlatformRevenueRatio: ShopRevenueRatioToPercentage(
              shopRevenue?.data?.shopRevenueRatio?.audioPlatformRevenueRatio ??
                "3000",
            ),
            physicalProductPlatformRevenueRatio: ShopRevenueRatioToPercentage(
              shopRevenue?.data?.shopRevenueRatio
                ?.physicalProductPlatformRevenueRatio ?? "3000",
            ),
            novelPlatformRevenueRatio: ShopRevenueRatioToPercentage(
              shopRevenue?.data?.shopRevenueRatio?.novelPlatformRevenueRatio ??
                "3000",
            ),
            eVoucherPlatformRevenueRatio: ShopRevenueRatioToPercentage(
              shopRevenue?.data?.shopRevenueRatio
                ?.eVoucherPlatformRevenueRatio ?? "700",
            ),
          })
        }}
        loading={isLoadingShopRevenue}
        platformEBookRatio={ShopRevenueRatioToPercentage(
          shopRevenue?.data?.shopRevenueRatio?.eBookPlatformRevenueRatio ??
            "3000",
        )}
        platformAudioRatio={ShopRevenueRatioToPercentage(
          shopRevenue?.data?.shopRevenueRatio?.audioPlatformRevenueRatio ??
            "3000",
        )}
        platformPhysicalRatio={ShopRevenueRatioToPercentage(
          shopRevenue?.data?.shopRevenueRatio
            ?.physicalProductPlatformRevenueRatio ?? "3000",
        )}
        platformNovelRatio={ShopRevenueRatioToPercentage(
          shopRevenue?.data?.shopRevenueRatio?.novelPlatformRevenueRatio ??
            "3000",
        )}
        platformEVoucherRatio={ShopRevenueRatioToPercentage(
          shopRevenue?.data?.shopRevenueRatio?.eVoucherPlatformRevenueRatio ??
            "700",
        )}
      />
    </>
  )
}

type ShopRevenueProp = {
  platformEBookRatio: number
  platformAudioRatio: number
  platformNovelRatio: number
  platformPhysicalRatio: number
  platformEVoucherRatio: number
  loading: boolean
  onPressEdit: () => void
  editable?: boolean
}

type CommissionType = {
  icon: React.ReactElement
  title: string
  platformRatio: number
  color: string
}

const ShopRevenueCard: React.FC<ShopRevenueProp> = (props: ShopRevenueProp) => {
  const { t } = useTranslation()
  const feature = useAvailableFeature()

  const commissionTypes: CommissionType[] = []

  if (feature.eBookAudio) {
    commissionTypes.push({
      icon: <UilBook width={20} />,
      title: t("shop-information.shop-revenue.e-book"),
      platformRatio: props.platformEBookRatio,
      color: "#00BFB3",
    })
    commissionTypes.push({
      icon: <UilHeadphonesAlt width={20} />,
      title: t("shop-information.shop-revenue.audio-book"),
      platformRatio: props.platformAudioRatio,
      color: "#F57D27",
    })
  }

  if (feature.eVoucher) {
    commissionTypes.push({
      icon: <UilTicket width={20} />,
      title: t("shop-information.shop-revenue.e-voucher"),
      platformRatio: props.platformEVoucherRatio,
      color: "#F2B602",
    })
  }

  if (feature.physical) {
    commissionTypes.push({
      icon: <UilShoppingBag width={20} />,
      title: t("shop-information.shop-revenue.physical"),
      platformRatio: props.platformPhysicalRatio,
      color: "#F04E98",
    })
  }

  if (feature.novel) {
    commissionTypes.push({
      icon: <UilBooks width={20} />,
      title: t("shop-information.shop-revenue.novel"),
      platformRatio: props.platformNovelRatio,
      color: "#478CF4",
    })
  }

  return (
    <div className="flex flex-col justify-start space-y-8 rounded-xl border border-solid border-[#D3DAE6] p-6">
      <div className="text-m font-bold">
        {t("shop-information.shop-revenue.title")}
      </div>
      <div className="flex flex-wrap justify-center gap-2">
        {commissionTypes.map((c) => (
          <div
            key={c.title}
            className="flex-shrink flex-grow basis-1/4 rounded-xl border-solid px-3 py-2"
            css={css({
              borderColor: c.color,
              color: c.color,
              borderWidth: "1.5px",
            })}
          >
            <div className="flex items-center justify-between">
              <div
                className="font-bold"
                css={css({
                  fontFamily: "Inter",
                  fontSize: "18px",
                })}
              >
                {t("common.percentage", {
                  value: 100 - c.platformRatio,
                })}
              </div>
              {c.icon}
            </div>
            <div
              className="text-s text-black"
              css={css({
                marginTop: "18px",
              })}
            >
              {c.title}
            </div>
          </div>
        ))}
      </div>

      {props.editable && !props.loading && (
        <div className="h-full w-full">
          <EuiButton fill onClick={props.onPressEdit} fullWidth>
            {t("shop-information.edit")}
          </EuiButton>
        </div>
      )}
    </div>
  )
}
