import { promotionCodeRegex } from "@app/model/promotion-code"
import { Moment } from "moment"
import * as Yup from "yup"

export enum CouponType {
  ExtraCoin = 0,
  Discount,
}

export enum CouponEffectType {
  Percentage = 0,
  Amount,
}

export type CouponEffect = {
  percentage?: {
    value?: number
    haveMaxAmount?: boolean
    maxAmount?: number
  }
  amount?: {
    value?: number
  }
}

export type TopupCoupon = {
  code?: string
  name?: string
  limitQuota: boolean
  quota?: number

  note?: string
  additional?: string

  isCanCollectCodeEarly: boolean
  collectCodeStartAt?: Moment
  startAt?: Moment
  endAt?: Moment

  couponType: CouponType
  effectType: CouponEffectType
  effect?: CouponEffect

  applyToAllPackage: boolean
  joinPackage?: Array<string>
  hideFromCollectPage: boolean
}

export const formSchema: Yup.ObjectSchema<TopupCoupon> = Yup.object({
  code: Yup.string().matches(promotionCodeRegex).required(),
  name: Yup.string().required(),
  limitQuota: Yup.boolean().required(),
  quota: Yup.number(),

  note: Yup.string(),
  additional: Yup.string(),

  isCanCollectCodeEarly: Yup.boolean().required(),
  collectCodeStartAt: Yup.mixed(),
  startAt: Yup.mixed(),
  endAt: Yup.mixed(),

  couponType: Yup.number().required().required(),
  effectType: Yup.number().required().required(),
  effect: Yup.object({}).when("effectType", {
    is: CouponEffectType.Percentage,
    then: (obj) =>
      obj.shape({
        percentage: Yup.object({
          value: Yup.number().required().min(0),
          haveMaxAmount: Yup.boolean(),
          maxAmount: Yup.number(),
        }).required(),
      }),
    otherwise: (obj) =>
      obj.shape({
        amount: Yup.object({
          value: Yup.number().required().min(0),
        }).required(),
      }),
  }),
  applyToAllPackage: Yup.boolean().required(),
  joinPackage: Yup.array().of(Yup.string().defined()),
  hideFromCollectPage: Yup.boolean().required(),
})
