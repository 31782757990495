import { mappingShopProductTypeToProductTypeInput } from "@app/model/product-table"
import { definitions as webbffDefinitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { LandingPageProduct } from "../type"

export const extendProductsToProductsTable = (
  extendsProducts: Array<webbffDefinitions["webbff.admin.v1.ExtendProduct"]>,
): {
  extendsProducts: LandingPageProduct[]
  errorIDs: string[]
} => {
  const errorIDs: string[] = []

  const newExtendsProducts = extendsProducts.map((p) => {
    let id = ""
    let name = ""
    let productType: definitions["shop.v1.ProductType"] =
      "PRODUCT_TYPE_UNSPECIFIED"

    if (p.product?.eBookAudio && p.product.eBookAudio.id) {
      productType = "PRODUCT_TYPE_E_BOOK_AUDIO"
      name = p.product.eBookAudio.name ?? ""
      id = p.product.eBookAudio.id ?? ""
    } else if (p.product?.novel && p.product.novel.id) {
      productType = "PRODUCT_TYPE_NOVEL"
      if (!name) {
        name = p.product.novel.name ?? ""
      }
      if (!id) {
        id = p.product.novel?.id ?? ""
      }
    } else if (p.product?.physicalProduct && p.product.physicalProduct.id) {
      productType = "PRODUCT_TYPE_PHYSICAL"
      if (!name) {
        name = p.product.physicalProduct.name ?? ""
      }
      if (!id) {
        id = p.product.physicalProduct.id ?? ""
      }
    } else if (p.product?.eVoucher && p.product.eVoucher.id) {
      productType = "PRODUCT_TYPE_E_VOUCHER"
      if (!name) {
        name = p.product.eVoucher.name ?? ""
      }
      if (!id) {
        id = p.product.eVoucher.id ?? ""
      }
    }

    return {
      type: mappingShopProductTypeToProductTypeInput(productType),
      id,
      name,
      shopName: p.shop?.name ?? "",
    }
  })

  return {
    extendsProducts: newExtendsProducts,
    errorIDs,
  }
}
