import { Title } from "@app/component"
import { Table } from "@app/component/table"
import { useApi, useStreamApi } from "@app/hook/api"
import { useDownloadReport } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import { constants } from "@app/model/constants"
import { operations } from "@app/vendor/order-specs/order_service"
import { definitions } from "@app/vendor/web-bff-specs/web_bff_service"
import {
  EuiBasicTableColumn,
  EuiButton,
  EuiFieldText,
  EuiProgress,
  EuiSelect,
  EuiSwitch,
  EuiText,
} from "@elastic/eui"
import { UilReceipt, UilSearch } from "@iconscout/react-unicons"
import { OnGetItems, presetTimeColumn } from "@reeeed-mp/ui-common"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { OrderStatusBadge } from "./common"
import { DatePickerModal } from "@app/component/modal-date-picker"
import { useDebounce } from "use-debounce"

type OrderItem = {
  id: string
  orderType: "Digital" | "Physical"
  username: string
  productCount: number
  price: number
  shopName: string
  createdAt: string
  updatedAt: string
  status: definitions["order.v1.OrderStatus"]
  isPreOrder: boolean
  taxInvoice: boolean
}

const inTransform = (
  data: definitions["webbff.seller.v1.Order"],
): OrderItem => {
  if (data.digitalContentOrder) {
    const order = data.digitalContentOrder
    const totalPrice = parseInt(order.price || "0", 10)
    const totalDiscount = parseInt(order.discount || "0", 10)
    const netPrice = totalPrice - totalDiscount
    const productCount = order.products?.reduce((acc, cur) => {
      return acc + (cur.amount || 0)
    }, 0)
    return {
      id: order.id || "-",
      orderType: "Digital",
      username: order.user?.username || "-",
      productCount: productCount || 0,
      price: netPrice / constants.power10OfDecimalPrice,
      shopName: order.shop?.shopName || "-",
      createdAt: order.createdAt!,
      updatedAt: order.updatedAt!,
      status: order.status!,
      isPreOrder: false,
      taxInvoice: !!order.taxInvoice,
    }
  }

  if (data.physicalOrder) {
    const order = data.physicalOrder
    const totalPrice = parseInt(order.price || "0", 10)
    const totalDiscount = parseInt(order.discount || "0", 10)
    const netPrice = totalPrice - totalDiscount
    const productCount = order.products?.reduce((acc, cur) => {
      return acc + (cur.amount || 0)
    }, 0)
    const isPreOrder =
      order?.products?.some((product) => product.isPreOrder === true) ?? false

    return {
      id: order.id || "-",
      orderType: "Physical",
      username: order.user?.username || "-",
      productCount: productCount || 0,
      price: netPrice / constants.power10OfDecimalPrice,
      shopName: order.shop?.shopName || "-",
      createdAt: order.createdAt!,
      updatedAt: order.updatedAt!,
      status: order.status!,
      isPreOrder,
      taxInvoice: !!order.taxInvoice,
    }
  }

  throw new Error("Invalid order type")
}

type PreOrderTypeFilter = definitions["order.v1.PreOrderTypeFilter"]

export const OrderManagementPage: React.FC = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const searchOrderIdParamKey = "orderId"
  const preOrderTypeFilterParamKey = "preOrderTypeFilter"
  const hasTaxInvoiceParamKey = "hasTaxInvoice"
  const searchOrderIdParam = searchParams.get(searchOrderIdParamKey)
  const preOrderTypeFilterParam = searchParams.get(preOrderTypeFilterParamKey)
  const hasTaxInvoiceParam = searchParams.get(hasTaxInvoiceParamKey)
  const [orderId, setOrderId] = useState(searchOrderIdParam || "")
  const [debounceOrderId] = useDebounce(orderId, 500)
  const { webBff } = useApi()
  const finder = webBff("/admin/order").method("get").create()
  const navigate = useNavigate()
  const { notificationStore: notification } = useStore()
  const { webBFF } = useStreamApi()
  const { download, loadingPercentage, error } = useDownloadReport(
    webBFF("/admin/report/order").method("post").create(),
    { autoDownload: true },
  )
  const {
    download: downloadSummaryReport,
    loadingPercentage: loadingSummaryReportPercentage,
    error: summaryReportError,
  } = useDownloadReport(
    webBFF("/admin/report/summary-order").method("post").create(),
    { autoDownload: true },
  )
  const defaultPreOrderTypeFilter = "PRE_ORDER_TYPE_FILTER_UNSPECIFIED"
  const [preOrderTypeFilter, setPreOrderTypeFilter] =
    useState<PreOrderTypeFilter>(
      (preOrderTypeFilterParam as PreOrderTypeFilter) ||
        defaultPreOrderTypeFilter,
    )
  const [hasTaxInvoice, setHasTaxInvoice] = useState<boolean>(
    hasTaxInvoiceParam === "true" ? true : false,
  )
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const columns: EuiBasicTableColumn<OrderItem>[] = [
    {
      name: t("order-management.list-page.column.order-id"),
      render: (order: OrderItem) => {
        const orderIdWithoutRunningNumber = order.id.substring(0, 16)
        const orderRunningNumber = order.id.substring(16)

        return (
          <div className="flex flex-col gap-1">
            <div>
              <span>{orderIdWithoutRunningNumber}</span>
              <span className="text-ci-grey-mid">{orderRunningNumber}</span>
            </div>
            {order.isPreOrder ? (
              <div className="w-fit rounded-md bg-slate-200 px-2 py-1 align-middle text-xs font-medium">
                {t("order-management.detail-page.pre-order")}
              </div>
            ) : null}
          </div>
        )
      },
    },
    {
      field: "orderType",
      name: t("order-management.list-page.column.order-type"),
      width: "70px",
    },
    {
      field: "username",
      name: t("order-management.list-page.column.username"),
      width: "120px",
    },
    {
      field: "productCount",
      name: t("order-management.list-page.column.product-count"),
      width: "110px",
      render: (value: number) => {
        return t("number", { number: value })
      },
    },
    {
      field: "price",
      name: t("order-management.list-page.column.price"),
      width: "100px",
      render: (value: number) => {
        return t("number", { number: value })
      },
    },
    {
      field: "shopName",
      name: t("order-management.list-page.column.shop-name"),
    },
    {
      field: "taxInvoice",
      name: t("order-management.list-page.column.tax-invoice"),
      truncateText: true,
      render(taxInvoice: boolean) {
        if (taxInvoice) {
          return (
            <EuiText className="flex items-center gap-x-2 text-sm">
              <UilReceipt size="16" />
              {t("order-management.list-page.filter-tax-invoice")}
            </EuiText>
          )
        }
        return ""
      },
    },
    {
      ...presetTimeColumn({
        field: "createdAt",
        name: t("order-management.list-page.column.checkout-time"),
      }),
      sortable: true,
      name: t("order-management.list-page.column.checkout-time"),
      width: "150px",
    } as EuiBasicTableColumn<OrderItem>,
    {
      ...presetTimeColumn({
        field: "updatedAt",
        name: t("order-management.list-page.column.update-time"),
      }),
      sortable: true,
      name: t("order-management.list-page.column.update-time"),
      width: "150px",
    } as EuiBasicTableColumn<OrderItem>,
    {
      field: "status",
      name: t("order-management.list-page.column.status"),
      width: "150px",
      render: (value: definitions["order.v1.OrderStatus"]) => {
        return <OrderStatusBadge status={value} />
      },
    },
  ]

  const onGetItems: OnGetItems<OrderItem> = useCallback(
    async (props) => {
      let sorter: operations["AdminOrderService_FindOrder"]["parameters"]["query"]["sorter"] =
        undefined
      if (props.sorter && props.sorter.field === "createdAt") {
        if (props.sorter.direction === "asc") {
          sorter = "SORTER_CREATED_AT_ASC"
        } else {
          sorter = "SORTER_CREATED_AT_DESC"
        }
      } else if (props.sorter && props.sorter.field === "updatedAt") {
        if (props.sorter.direction === "asc") {
          sorter = "SORTER_UPDATED_AT_ASC"
        } else {
          sorter = "SORTER_UPDATED_AT_DESC"
        }
      }

      const resp = await finder({
        "option.limit": props.opts.limit,
        "option.skip": props.opts.skip,
        "filter.orderIdStartWith": props.filter?.orderId,
        "filter.preOrderTypeFilter": props.filter?.preOrderTypeFilter,
        "filter.hasTaxInvoice": props.filter?.hasTaxInvoice,
        sorter,
      })

      if (!resp.ok) {
        return {
          items: [],
          count: 0,
        }
      }

      return {
        items: resp.data.orders!.map(inTransform),
        count: resp.data.total!,
      }
    },
    [finder],
  )

  useEffect(() => {
    if (error || summaryReportError) {
      notification.add({
        color: "danger",
        title: t("common.noti.error"),
        text: error.message,
      })
    }
  }, [error, summaryReportError, notification, t])

  return (
    <div>
      <Title text={t("order-management.list-page.title")} />
      <div className="mb-6 flex items-center justify-between">
        <div
          className="flex items-center justify-between gap-x-1"
          style={{ width: 1000 }}
        >
          <EuiFieldText
            placeholder={t("order-management.list-page.order-id")}
            value={orderId}
            onChange={(e) => {
              setOrderId(e.target.value)
            }}
            icon={<UilSearch />}
          />
          <div className="w-80">
            <EuiSelect
              prepend={t("order-management.list-page.list-filter-header")}
              value={preOrderTypeFilter}
              options={[
                {
                  value: "PRE_ORDER_TYPE_FILTER_UNSPECIFIED",
                  text: t("common.all"),
                },
                {
                  value: "PRE_ORDER_TYPE_FILTER_NON_PRE_ORDER",
                  text: t("order-management.list-page.filter-non-pre-order"),
                },
                {
                  value: "PRE_ORDER_TYPE_FILTER_PRE_ORDER",
                  text: t("order-management.list-page.filter-pre-order"),
                },
              ]}
              onChange={(e) => {
                setPreOrderTypeFilter(
                  e.target.value as definitions["order.v1.PreOrderTypeFilter"],
                )
              }}
            />
          </div>
          <EuiSwitch
            label={
              <div className="flex flex-row items-center">
                {t("order-management.list-page.filter-tax-invoice")}
              </div>
            }
            className="flex w-60 items-center"
            compressed
            checked={hasTaxInvoice ? true : false}
            onChange={(e) => {
              setHasTaxInvoice(e.target.checked)
            }}
          />
        </div>
        {openDownloadModal && (
          <DatePickerModal
            onClose={() => {
              setOpenDownloadModal(false)
            }}
            onConfirm={(res) => {
              if (res.downloadSummaryOrder) {
                downloadSummaryReport(
                  res.summaryOrderSortType === "sortByBuyDate"
                    ? {
                        filter: {
                          createdAt: {
                            from: res.startDate.startOf("day").toISOString(),
                            to: res.endDate.endOf("day").toISOString(),
                          },
                        },
                      }
                    : {
                        filter: {
                          updatedAt: {
                            from: res.startDate.startOf("day").toISOString(),
                            to: res.endDate.endOf("day").toISOString(),
                          },
                          statuses: ["ORDER_STATUS_SUCCEEDED"],
                        },
                      },
                )
                setOpenDownloadModal(false)
              } else {
                download({
                  filter: {
                    createdAt: {
                      from: res.startDate.startOf("day").toISOString(),
                      to: res.endDate.endOf("day").toISOString(),
                    },
                    isPreOrderOnly: res.downloadPreOrderOnly,
                  },
                })
                setOpenDownloadModal(false)
              }
            }}
          />
        )}
        <div className="flex flex-col gap-y-4">
          <EuiButton
            color="primary"
            fill
            disabled={
              loadingPercentage !== null ||
              loadingSummaryReportPercentage !== null
            }
            onClick={() => {
              setOpenDownloadModal(true)
            }}
          >
            {t("order-management.list-page.download-order-management-title")}
          </EuiButton>
          {(loadingPercentage !== null ||
            loadingSummaryReportPercentage !== null) && (
            <EuiProgress
              size="xs"
              color="primary"
              value={loadingPercentage || loadingSummaryReportPercentage || 0}
            />
          )}
        </div>
      </div>

      <Table
        columns={columns}
        onGetItems={onGetItems}
        isCanSelect={false}
        swrKey={`/order`}
        filter={{
          orderId: debounceOrderId,
          preOrderTypeFilter,
          hasTaxInvoice,
        }}
        sorter={{ field: "updatedAt", direction: "desc" }}
        onRowClick={(item) => {
          navigate(`/order-management/${item.id}`)
        }}
      />
    </div>
  )
}
