import "./button.css"

import * as React from "react"
import { ReactNode } from "react"

export const Button: React.FC<{
  "data-test-id"?: string
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick: () => void
  small?: boolean
  title?: string
}> = ({
  "data-test-id": dataTestId,
  children,
  className,
  onClick,
  disabled,
  small,
  title,
}) => {
  return (
    <button
      disabled={disabled}
      className={`mpe-button__root ${disabled ? "mpe-button__disabled" : ""} ${small ? "mpe-button__small" : ""} ${className}`}
      onClick={onClick}
      title={title}
      aria-label={title}
      {...(dataTestId && { "data-test-id": dataTestId })}
    >
      {children}
    </button>
  )
}
