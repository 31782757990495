import { newApi } from "@app/api"
import {
  DataState,
  PublishTimeline,
  TopupPackage,
  TotalTopupPackage,
  errUnAuth,
  errUnsupportDiffPaymentChannel,
  genNewId,
  isNewId,
  paymentTypeToPackageType,
  validatePackage,
} from "@app/model"
import {
  PaymentChannel,
  paymentTypeJsonToModel,
  paymentTypeModelToJson,
} from "@app/model/payment"
import { RootStore } from "@app/store"
import { definitions } from "@app/vendor/payment-specs/payment_service"
import { makeAutoObservable, runInAction } from "mobx"

type MergedPackage = TopupPackage & { status: "original" | "update" | "new" }

type PackageToStore = {
  newPackages: Map<string, TopupPackage>
  updatePackages: Map<string, TopupPackage>
}
export class TopupPackageStore {
  rootStore: RootStore
  packages: DataState<Map<PaymentChannel, TopupPackage[]>> = {}
  totalPackage: DataState<TotalTopupPackage[]> = {}
  deletePackageState: DataState<null> = {}
  packagesToStore: DataState<PackageToStore> = {
    data: {
      newPackages: new Map(),
      updatePackages: new Map(),
    },
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true })
    this.rootStore = rootStore
  }

  get newPackages() {
    if (!this.packagesToStore.data?.newPackages) {
      return []
    }
    return Array.from(this.packagesToStore.data.newPackages.values())
  }

  get updatePackages() {
    if (!this.packagesToStore.data?.updatePackages) {
      return []
    }
    return Array.from(this.packagesToStore.data.updatePackages.values())
  }

  // mergedPackages merge recordedPackages, updatedPackages and newPackages order by [record & update, new]
  mergedPackages(channel: PaymentChannel): MergedPackage[] {
    const recordPackages = this.packages.data?.get(channel)
    if (!recordPackages) {
      return []
    }
    const out: MergedPackage[] = []
    const updatePackages = this.packagesToStore.data?.updatePackages
    if (!updatePackages) {
      recordPackages.forEach((p) => out.push({ ...p, status: "original" }))
    } else {
      recordPackages.forEach((p) => {
        const update = updatePackages.get(p.id)
        if (update) {
          out.push({ ...update, status: "update" })
        } else {
          out.push({ ...p, status: "original" })
        }
      })
    }
    const newPackages = this.newPackages.sort((a, b) => a.getCoin - b.getCoin)
    newPackages.forEach((p) => out.push({ ...p, status: "new" }))
    return out
  }

  async getTotalTopupPackage() {
    const authStore = this.rootStore.authStore
    if (!authStore.isLoggedIn) {
      throw errUnAuth
    }
    this.totalPackage.loading = true
    this.totalPackage.data = []
    this.totalPackage.error = null
    try {
      const res = await newApi(authStore)
        .payment("/admin/count-topup-package")
        .method("get")
        .create()({})

      runInAction(() => {
        this.totalPackage.loading = false

        // TODO: remove coin channel from api
        res.data.packages = res.data.packages?.filter(
          (p) => p.paymentChannel !== "PAYMENT_CHANNEL_COIN",
        )

        this.totalPackage.data =
          res.data.packages?.map(topupPackageCountToModel) || []
      })
    } catch (err) {
      runInAction(() => {
        this.totalPackage.loading = false
        if (err instanceof Error) {
          this.totalPackage.error = err
        }
      })
    }
  }

  async deleteTopupPackage({ id, paymentType }: TopupPackage) {
    if (isNewId(id)) {
      this.deleteNewTopupPackageInBuffer(id)
      return
    }
    const auth = this.rootStore.authStore
    if (!auth.isLoggedIn) {
      throw errUnAuth
    }
    const timeoutId = setTimeout(() => {
      this.deletePackageState.loading = true
    }, 1000)
    this.deletePackageState.error = null
    try {
      await newApi(auth)
        .payment("/admin/topup-package/{packageId}")
        .method("delete")
        .create()({ packageId: id })

      if (this.packagesToStore.data?.updatePackages) {
        this.packagesToStore.data.updatePackages.delete(id)
      }

      this.getTotalTopupPackage()
      this.getTopupPackageByPaymentType(paymentType)
    } catch (err) {
      runInAction(() => {
        if (err instanceof Error) {
          this.deletePackageState.error = err
        }
      })
    } finally {
      runInAction(() => {
        clearTimeout(timeoutId)
        this.deletePackageState.loading = false
      })
    }
  }

  async getTopupPackageByPaymentType(t: PaymentChannel) {
    const authStore = this.rootStore.authStore
    if (!authStore.isLoggedIn) {
      throw errUnAuth
    }
    this.packages.loading = true
    if (!this.packages.data) {
      this.packages.data = new Map()
    }
    this.packages.data.delete(t)
    this.packages.error = null
    try {
      const res = await newApi(authStore)
        .payment("/admin/topup-package/{paymentChannel}")
        .method("get")
        .create()({ paymentChannel: paymentTypeModelToJson(t) })

      runInAction(() => {
        this.packages.loading = false
        if (res.data.package) {
          this.packages.data!.set(
            t,
            res.data.package.map((p) =>
              topupPackageJsonToModel(p, res.data.paymentChannel!),
            ),
          )
        }
      })
    } catch (err) {
      runInAction(() => {
        this.packages.loading = false
        if (err instanceof Error) {
          this.packages.error = err
        }
      })
    }
  }

  async storeTopupPackages() {
    const newPackagesArr = this.newPackages
    const updatePackagesArr = this.updatePackages
    if (newPackagesArr.length + updatePackagesArr.length === 0) {
      return
    }
    const [ok, paymentType] = ensureAllPackageHaveSamePaymentChannel([
      ...newPackagesArr,
      ...updatePackagesArr,
    ])
    if (!ok || paymentType === null) {
      throw errUnsupportDiffPaymentChannel
    }
    const authStore = this.rootStore.authStore
    if (!authStore.isLoggedIn) {
      throw errUnAuth
    }
    this.packagesToStore.loading = true
    this.packagesToStore.error = null
    try {
      await newApi(authStore)
        .payment("/admin/topup-package/{paymentChannel}")
        .method("post")
        .create()({
        paymentChannel: paymentTypeModelToJson(paymentType),
        create: newPackagesArr.map((p) => topupPackageToJson(p)),
        update: updatePackagesArr.map((p) => topupPackageToJson(p, p.id)),
      })
      runInAction(() => {
        this.packagesToStore.loading = false
        this.packagesToStore.data = {
          newPackages: new Map(),
          updatePackages: new Map(),
        }
      })
      this.getTotalTopupPackage()
      this.getTopupPackageByPaymentType(paymentType)
    } catch (err) {
      runInAction(() => {
        this.packagesToStore.loading = false
        if (err instanceof Error) {
          this.packagesToStore.error = err
        }
      })
    }
  }

  upsertTopupPackageInBuffer(p: Partial<TopupPackage>) {
    const err = validatePackage(p)
    if (err) {
      throw new Error("invalid topup package")
    }
    const pkg = p as TopupPackage
    if (!pkg.id || isNewId(pkg.id)) {
      this.storeNewTopupPackageInBuffer(pkg)
    } else {
      this.storeUpdateTopupPackageInBuffer(pkg)
    }
  }

  storeNewTopupPackageInBuffer(pkg: TopupPackage) {
    if (!pkg.id) {
      pkg.id = genNewId()
    }
    this.packagesToStore.data!.newPackages.set(pkg.id, pkg)
  }

  storeUpdateTopupPackageInBuffer(pkg: TopupPackage) {
    this.packagesToStore.data!.updatePackages.set(pkg.id, pkg)
  }

  deleteNewTopupPackageInBuffer(newId: string) {
    this.packagesToStore.data!.newPackages.delete(newId)
  }

  clearPackageToStoreBuffer() {
    this.packagesToStore.data = {
      newPackages: new Map(),
      updatePackages: new Map(),
    }
  }
}

function toPublishTimeline(start: string, end?: string): PublishTimeline {
  const out: PublishTimeline = {
    start: new Date(start),
  }
  if (end) {
    out.end = new Date(end)
  }
  return out
}

function ensureAllPackageHaveSamePaymentChannel(
  pkgs: TopupPackage[],
): [boolean, PaymentChannel | null] {
  let paymentType: PaymentChannel | null = null
  let ok = true
  let i = 0

  while (ok && i < pkgs.length) {
    if (i === 0) {
      paymentType = pkgs[i].paymentType
    }
    ok = paymentType === pkgs[i].paymentType
    i++
  }
  if (ok && i === pkgs.length) {
    return [true, paymentType]
  }
  return [false, null]
}

function topupPackageToJson(
  p: TopupPackage,
  id?: string,
): definitions["payment.admin.v1.TopupPackageUpdate"] {
  const pkg: definitions["payment.admin.v1.TopupPackage"] = {
    getCoin: String(p.getCoin),
    chargeAmount: String(p.chargeAmount),
  }
  if (p.publishTimeline) {
    if (p.publishTimeline.start) {
      pkg.publishStart = p.publishTimeline.start.toISOString()
    }
    if (p.publishTimeline.end) {
      pkg.publishEnd = p.publishTimeline.end.toISOString()
    }
  }
  return { package: pkg, id }
}

function topupPackageJsonToModel(
  d: definitions["payment.admin.v1.TopupPackage"],
  c: definitions["payment.v1.PaymentChannel"],
): TopupPackage {
  const t = paymentTypeJsonToModel(c)
  return {
    id: d.id!,
    type: paymentTypeToPackageType(t),
    paymentType: t,
    getCoin: Number(d.getCoin) || 0,
    chargeAmount: Number(d.chargeAmount) || 0,
    publishTimeline: d.publishStart
      ? toPublishTimeline(d.publishStart, d.publishEnd)
      : null,
  }
}

function topupPackageCountToModel(
  d: definitions["payment.admin.v1.TopupPackageCount"],
): TotalTopupPackage {
  if (!d.paymentChannel) {
    throw new Error("unexpect payment channel empty")
  }
  const paymentType = paymentTypeJsonToModel(d.paymentChannel)
  return {
    total: Number(d.total) || 0,
    type: paymentTypeToPackageType(paymentType),
    paymentType,
  }
}
