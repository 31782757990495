import type { EditorThemeClasses } from "lexical"
import "./theme.css"

const theme: EditorThemeClasses = {
  paragraph: "mpe-theme-paragraph",
  heading: {
    h1: "mpe-theme-h1",
    h2: "mpe-theme-h2",
    h3: "mpe-theme-h3",
    h4: "mpe-theme-h4",
    h5: "mpe-theme-h5",
    h6: "mpe-theme-h6",
  },
  quote: "mpe-theme-quote",
  text: {
    bold: "mpe-theme-textBold",
    italic: "mpe-theme-textItalic",
    strikethrough: "mpe-theme-textStrikethrough",
    underline: "mpe-theme-textUnderline",
    underlineStrikethrough: "mpe-theme-textUnderlineStrikethrough",
    superscript: "mpe-theme-super-script",
    subscript: "mpe-theme-sup-script",
  },
  list: {
    ol: "mpe-theme-ol",
    ul: "mpe-theme-ul",
  },
  image: "mpe-theme-image",
  embedBlock: {
    base: "mpe-embed-block",
    focus: "mpe-embed-blockFocus",
  },
  link: "mpe-link",
}

export default theme
