import { utils as utilsXlsx, writeFile } from "xlsx"

export const getProductsTemplate = async () => {
  const data = [["id", "type"]]

  const exampleDataArray = [
    ["ID ของ Physical Product", "physical-product"],
    ["ID ของ EBookAudio", "e-book-audio"],
    ["ID ของ Novel", "novel"],
    ["ID ของ EVoucher", "e-voucher"],
  ]

  const worksheet = utilsXlsx.aoa_to_sheet(data)
  worksheet["!cols"] = Array(data[0].length).fill({ wpx: 100 })

  const workbook = utilsXlsx.book_new()
  utilsXlsx.book_append_sheet(workbook, worksheet, "template")
  utilsXlsx.sheet_add_json(worksheet, exampleDataArray, {
    skipHeader: true,
    origin: "A2",
  })

  writeFile(workbook, "product-highlight-template.xlsx")
}
