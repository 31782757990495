import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import React, { useState } from "react"
import { countUploadImageNode } from "../plugins/image/common"
import { $isImageNode } from "../nodes"

export const Footer: React.FC<{ maxUploadImage?: number }> = (props) => {
  const [editor] = useLexicalComposerContext()
  const [count, setCount] = useState(0)

  editor.registerUpdateListener(({ editorState }) => {
    let c = 0
    for (const [nodeType, node] of editorState._nodeMap) {
      if ($isImageNode(node) && node.srcType() === "upload") {
        c += 1
      }
    }

    setCount(c)
  })

  if (props.maxUploadImage === undefined) {
    return null
  }

  return (
    <div
      style={{
        borderTop: "1px solid #e0e5ee",
      }}
    >
      <div
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "end",
          margin: "5px",
          color: "gray",
          fontSize: "10px",
        }}
      >
        <div>
          <span
            style={{
              color: count > props.maxUploadImage ? "red" : "inherit",
            }}
          >
            image(s): {count} / {props.maxUploadImage}
          </span>
        </div>
      </div>
    </div>
  )
}
