import { definitions as webbffDefinitions } from "@app/vendor/web-bff-specs/web_bff_service"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { variantSeparator } from "./variant"

export const extendProductsToProductsTable = (
  extendsProducts: Array<webbffDefinitions["webbff.admin.v1.ExtendProduct"]>,
  productKey: Array<{
    id?: string
    type?: definitions["shop.v1.ProductType"]
    sku?: string
  }>,
): {
  extendsProducts: Array<{
    id: string
    name: string
    type: Array<definitions["shop.v1.ProductType"]>
    sku: string
    shopName: string
    variantName: string
  }>
  errorIDs: string[]
} => {
  const errorIDs: string[] = []

  const newExtendsProducts =
    extendsProducts.map((p, idx) => {
      let id = ""
      let name = ""
      const productType: Array<definitions["shop.v1.ProductType"]> = []
      let variantName = ""
      let sku = ""

      if (p.product?.eBookAudio && p.product.eBookAudio.id) {
        productType.push("PRODUCT_TYPE_E_BOOK_AUDIO")
        name = p.product.eBookAudio.name ?? ""
        id = p.product.eBookAudio.id ?? ""
      } else if (p.product?.novel && p.product.novel.id) {
        productType.push("PRODUCT_TYPE_NOVEL")
        if (!name) {
          name = p.product.novel.name ?? ""
        }
        if (!id) {
          id = p.product.novel?.id ?? ""
        }
      } else if (p.product?.physicalProduct && p.product.physicalProduct.id) {
        productType.push("PRODUCT_TYPE_PHYSICAL")
        if (!name) {
          name = p.product.physicalProduct.name ?? ""
        }
        if (!id) {
          id = p.product.physicalProduct.id ?? ""
        }

        if (productKey.length > 0 && productKey[idx].sku) {
          sku = productKey[idx].sku!
          const physicalProduct = p.product.physicalProduct
          const findVariantBySku = physicalProduct.variants?.find(
            (v) => v.sku === productKey[idx].sku,
          )
          if (findVariantBySku) {
            const findMainAtt =
              physicalProduct.mainAttributeSet?.attributes?.find(
                (m) => m.id === findVariantBySku?.mainAttributeId,
              )
            const findSubAtt =
              physicalProduct.subAttributeSet?.attributes?.find(
                (s) => s.id === findVariantBySku?.subAttributeId,
              )
            if (findMainAtt && findSubAtt) {
              variantName = `${findMainAtt.name}${variantSeparator}${findSubAtt.name}`
            }
          }
        }
      } else {
        errorIDs.push(productKey[idx].id ?? "")
      }

      return {
        type: productType,
        id,
        name,
        sku,
        shopName: p.shop?.name ?? "",
        variantName,
      }
    }) ?? []

  return {
    extendsProducts: newExtendsProducts,
    errorIDs,
  }
}

export type ProductTypeInput =
  | "e-book-audio"
  | "physical-product"
  | "novel"
  | "e-voucher"
  | ""

export const mappingProductTypeInputToShopProductType = (
  productType: ProductTypeInput,
): definitions["shop.v1.ProductType"] => {
  switch (productType) {
    case "e-book-audio":
      return "PRODUCT_TYPE_E_BOOK_AUDIO"
    case "physical-product":
      return "PRODUCT_TYPE_PHYSICAL"
    case "novel":
      return "PRODUCT_TYPE_NOVEL"
    case "e-voucher":
      return "PRODUCT_TYPE_E_VOUCHER"
    default:
      return "PRODUCT_TYPE_UNSPECIFIED"
  }
}

export const mappingShopProductTypeToProductTypeInput = (
  productType: definitions["shop.v1.ProductType"],
): ProductTypeInput => {
  switch (productType) {
    case "PRODUCT_TYPE_E_BOOK_AUDIO":
      return "e-book-audio"
    case "PRODUCT_TYPE_PHYSICAL":
      return "physical-product"
    case "PRODUCT_TYPE_NOVEL":
      return "novel"
    case "PRODUCT_TYPE_E_VOUCHER":
      return "e-voucher"
    case "PRODUCT_TYPE_UNSPECIFIED":
    default:
      return ""
  }
}

export type ProductKeyInputXlsx = {
  id: string
  type: ProductTypeInput
}

export type ProductTable = {
  id: string
  name: string
  type: ProductTypeInput
  shopName: string
}
