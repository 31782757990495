import ScrollToTop from "@app/component/scroll-top"
import { GlobalNotificationToasts } from "@app/component/toast"
import { publicConfig } from "@app/config"
import { useAuth } from "@app/hook/store"
import { CategoryPage, StaticPageSummary, TopupPackagePage } from "@app/page"
import { CampaignCouponBannerEditor } from "@app/page/campaign-coupon"
import { CoinBalanceReportPage } from "@app/page/coin-balance-report"
import { ConsentEditorPage } from "@app/page/consent"
import { PromotionPage } from "@app/page/coupon-promotion/discount"
import { PromotionDetail } from "@app/page/coupon-promotion/discount/detail"
import { PromotionEditor } from "@app/page/coupon-promotion/discount/edior"
import { ListTopupCouponPromotionPage } from "@app/page/coupon-promotion/topup"
import { TopupCouponPromotionDetail } from "@app/page/coupon-promotion/topup/detail"
import { TopupCouponPromotionEditor } from "@app/page/coupon-promotion/topup/editor"
import { FreeEBooDetailPage } from "@app/page/free-e-book/detail"
import { FreeEBookCreatePage } from "@app/page/free-e-book/editor"
import { FreeEBookList } from "@app/page/free-e-book/list"
import { GiftCodeList } from "@app/page/gift-code/list"
import { FlexibleHomeManagementProvider } from "@app/page/home-management-2/store/ctx"
import { HomeManagementProvider } from "@app/page/home-management/store/ctx"
import { LandingPageManagement } from "@app/page/landing-page"
import { DetailLandingPage } from "@app/page/landing-page/detail"
import { EditorLandingPage } from "@app/page/landing-page/editor"
import { LoginPage } from "@app/page/login"
import {
  OrderManagementDetailPage,
  OrderManagementPage,
} from "@app/page/order-management"
import { Privilege } from "@app/page/privilege"
import { PrivilegeEditor } from "@app/page/privilege/editor"
import { EditorAddReeeedCoinPage } from "@app/page/reeeed-coin/editor"
import { AddReeeedCoinListPage } from "@app/page/reeeed-coin/list"
import { SalePackageCoinReport } from "@app/page/sale-package-coin-report"
import { DetailSellerManagement } from "@app/page/seller-management/detail"
import { ListSellerManagement } from "@app/page/seller-management/list"
import { EBookAudioManagement } from "@app/page/seller-management/product/e-book-audio"
import { NovelManagement } from "@app/page/seller-management/product/novel"
import { PhysicalProductManagement } from "@app/page/seller-management/product/physical-product"
import { VerifySellerManagement } from "@app/page/seller-management/verify"
import { SellerRevenueReport } from "@app/page/seller-revenue"
import { SeoPage } from "@app/page/seo"
import { SeoPageEditor } from "@app/page/seo/editor"
import { StaticPageEditor } from "@app/page/static-page/editor"
import { TopupHistoryDetail } from "@app/page/topup-history/detail"
import { TopupHistoryList } from "@app/page/topup-history/list"
import { UserListPage } from "@app/page/user-list"
import { WithdrawRequest } from "@app/page/withdraw-request"
import { css } from "@emotion/react"
import {
  UilBox,
  UilCoins,
  UilFileAlt,
  UilFileSearchAlt,
  UilPen,
  UilPercentage,
  UilShop,
  UilUsersAlt,
  UilWebGrid,
} from "@iconscout/react-unicons"
import { observer } from "mobx-react"
import React from "react"
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import { LeftNavbar, NavbarRoute } from "../../component/navbar/left"
import { TopNavbar } from "../../component/navbar/top/navbar"

import { ComingSoonPage } from "@app/component/coming-soon"
import { EVoucherManagement } from "@app/page/seller-management/product/e-voucher"
import { SellerManagementSetting } from "@app/page/seller-management/setting"
import {
  flexibleHomepageManagementRoute,
  flexibleHomepageManagementRouteWithSuffix,
} from "./flexible-home-management-route"
import { homepageManagementRoute } from "./home-management-route"
import { CodeRedemptionListPage } from "@app/page/code-redemption/list"
import { CodeRedemptionCodeDetailPage } from "@app/page/code-redemption/view"

const activeHomepageManagementRoute: NavbarRoute["subRoutes"] =
  publicConfig.enableFlexibleHomepageManagement
    ? flexibleHomepageManagementRoute
    : [...homepageManagementRoute, ...flexibleHomepageManagementRouteWithSuffix]

const routes: NavbarRoute[] = [
  {
    route: {
      i18nKey: "promotion-title",
      icon: <UilPercentage width="16" height="16" />,
    },
    subRoutes: [
      {
        path: "/promotion-code",
        i18nKey: "promotion-code",
        component: <PromotionPage />,
      },
      {
        path: "/topup-coupon-promotion",
        i18nKey: "topup-coupon-promotion",
        component: <ListTopupCouponPromotionPage />,
      },
      {
        path: "/topup-coupon-promotion/:id",
        i18nKey: "",
        component: <TopupCouponPromotionEditor />,
        hidden: true,
      },
      {
        path: "/topup-coupon-promotion/detail/:id",
        i18nKey: "",
        component: <TopupCouponPromotionDetail />,
        hidden: true,
      },
      {
        path: "/campaign-coupon",
        i18nKey: "campaign-coupon",
        component: <CampaignCouponBannerEditor />,
      },
      {
        path: "/reeeed-coin/:id",
        i18nKey: "reeeed-coin",
        component: <EditorAddReeeedCoinPage />,
        hidden: true,
      },
      {
        path: "/reeeed-coin",
        i18nKey: "reeeed-coin",
        component: <AddReeeedCoinListPage />,
      },
      {
        path: "/privilege",
        i18nKey: "privilege",
        component: <Privilege />,
        disabled: !publicConfig.eBookAudio,
      },
      {
        i18nKey: "",
        path: "/privilege/create",
        component: <PrivilegeEditor />,
        hidden: true,
      },
      {
        path: "/promotion/:id",
        i18nKey: "",
        component: <PromotionEditor />,
        hidden: true,
      },
      {
        path: "/promotion/detail/:id",
        i18nKey: "",
        component: <PromotionDetail />,
        hidden: true,
      },
      {
        path: "/free-e-book",
        i18nKey: "free-e-book",
        component: <FreeEBookList />,
        disabled: !publicConfig.eBookAudio,
      },
      {
        path: "/free-e-book/:id",
        i18nKey: "",
        component: <FreeEBookCreatePage />,
        hidden: true,
        disabled: !publicConfig.eBookAudio,
      },
      {
        path: "/free-e-book/detail/:id",
        i18nKey: "",
        component: <FreeEBooDetailPage />,
        hidden: true,
        disabled: !publicConfig.eBookAudio,
      },
      {
        path: "/gift-code",
        i18nKey: "gift-code",
        component: <GiftCodeList />,
        disabled: !publicConfig.featureGiftCode,
      },
    ],
  },
  {
    route: {
      i18nKey: "banner-title",
      icon: <UilWebGrid width={16} height={16} />,
      provider: (children) => <React.Fragment>{children}</React.Fragment>,
    },
    subRoutes: [
      ...activeHomepageManagementRoute,
      {
        path: "/landing-page",
        i18nKey: "landing-page",
        component: <LandingPageManagement />,
      },
      {
        path: "/landing-page/:id",
        i18nKey: "landing-page",
        component: <EditorLandingPage />,
        hidden: true,
      },
      {
        path: "/landing-page/detail/:id",
        i18nKey: "landing-page",
        component: <DetailLandingPage />,
        hidden: true,
      },
    ],
  },
  {
    route: {
      i18nKey: "seller-title",
      icon: <UilShop width={16} height={16} />,
    },
    subRoutes: [
      {
        path: "/seller-management",
        i18nKey: "seller-management-title",
        component: <ListSellerManagement />,
      },
      {
        path: "/seller-management/:shopId",
        i18nKey: "seller-management-title",
        component: <DetailSellerManagement />,
        hidden: true,
      },
      {
        path: "/seller-management/:shopId/setting",
        i18nKey: "seller-management-title",
        component: <SellerManagementSetting />,
        hidden: true,
      },
      {
        path: "/seller-management/:shopId/e-book-audio/:productId",
        i18nKey: "seller-management-title",
        component: <EBookAudioManagement />,
        hidden: true,
      },
      {
        path: "/seller-management/:shopId/e-voucher/:productId",
        i18nKey: "seller-management-title",
        component: <EVoucherManagement />,
        hidden: true,
      },
      {
        path: "/seller-management/:shopId/novel/:productId",
        i18nKey: "seller-management-title",
        component: <NovelManagement />,
        hidden: true,
      },
      {
        path: "/seller-management/:shopId/physical-product/:productId",
        i18nKey: "seller-management-title",
        component: <PhysicalProductManagement />,
        hidden: true,
      },
      {
        path: "/seller-approve/:shopId",
        i18nKey: "seller-management-title",
        component: <VerifySellerManagement />,
        hidden: true,
      },
      {
        path: "/withdraw-request",
        i18nKey: "withdraw-request-title",
        component: <WithdrawRequest />,
      },
      {
        path: "/order-management",
        i18nKey: "order-management",
        component: <OrderManagementPage />,
      },
      {
        path: "/order-management/:orderId",
        i18nKey: "",
        component: <OrderManagementDetailPage />,
        hidden: true,
      },
    ],
  },
  {
    route: {
      i18nKey: "product-management-title",
      icon: <UilBox width="16" height="16" />,
    },
    subRoutes: [
      {
        path: "/category",
        i18nKey: "book-category-management",
        component: <CategoryPage />,
      },
    ],
  },
  {
    route: {
      i18nKey: "user-list",
      icon: <UilUsersAlt width="16" height="16" />,
      path: "/user-list",
      component: <UserListPage />,
    },
    subRoutes: [],
  },
  {
    route: {
      i18nKey: "financial-report-title",
      icon: <UilCoins width={16} height={16} />,
    },
    subRoutes: [
      {
        path: "/sale-package-coin-report",
        i18nKey: "sale-package-coin-title",
        component: <SalePackageCoinReport />,
      },
      {
        path: "/seller-revenue",
        i18nKey: "seller-revenue",
        component: <SellerRevenueReport />,
      },
      {
        path: "/topup-history",
        i18nKey: "topup-history",
        component: <TopupHistoryList />,
      },
      {
        path: "/topup-history/:id",
        i18nKey: "",
        component: <TopupHistoryDetail />,
        hidden: true,
      },
      {
        path: "/coin-balance-report",
        i18nKey: "coin-balance-report",
        component: <CoinBalanceReportPage />,
      },
      {
        path: "/coupon-redemption",
        i18nKey: "coupon-redemption",
        component: <CodeRedemptionListPage />,
      },
      {
        path: "/coupon-redemption/:id",
        i18nKey: "coupon-redemption-usage",
        component: <CodeRedemptionCodeDetailPage />,
        hidden: true,
      },
    ],
  },
  {
    route: {
      i18nKey: "topup-package",
      icon: <UilCoins width="16" height="16" />,
      path: "/topup-package",
      component: <TopupPackagePage />,
    },
    subRoutes: [],
  },
  {
    route: {
      i18nKey: "seo-editor",
      icon: <UilFileSearchAlt width="16" height="16" />,
      path: "/seo-editor",
      component: <SeoPage />,
    },
    subRoutes: [
      {
        i18nKey: "",
        path: "/seo-editor/:id",
        component: <SeoPageEditor />,
        hidden: true,
      },
    ],
  },
  {
    route: {
      i18nKey: "static-page.title",
      icon: <UilFileAlt width="16" height="16" />,
    },
    subRoutes: [
      {
        path: "/static-page/policy",
        i18nKey: "static-page.policy",
        component: <StaticPageSummary group="STATIC_PAGE_GROUP_POLICY" />,
      },
      {
        path: "/static-page/custom",
        i18nKey: "static-page.custom",
        component: <StaticPageSummary group="STATIC_PAGE_GROUP_CUSTOM" />,
      },
      {
        path: "/static-page/loyalty",
        i18nKey: "static-page.loyalty",
        component: <StaticPageSummary group="STATIC_PAGE_GROUP_LOYALTY" />,
        hidden: !publicConfig.loyalty,
        disabled: !publicConfig.loyalty,
      },
      {
        path: "/static-page/about-us",
        i18nKey: "static-page.about-us",
        component: <StaticPageSummary group="STATIC_PAGE_GROUP_ABOUT_US" />,
      },
      {
        i18nKey: "",
        path: "/static-page/editor/:id",
        component: <StaticPageEditor />,
        hidden: true,
      },
    ],
  },
  {
    route: {
      i18nKey: "consent.title",
      icon: <UilPen width="16" height="16" />,
    },
    subRoutes: [
      {
        path: "/consent/user-terms",
        i18nKey: "consent.user-terms",
        component: <ConsentEditorPage consentType="CONSENT_TYPE_USER_TERMS" />,
      },
      {
        path: "/consent/seller-terms",
        i18nKey: "consent.seller-terms",
        component: (
          <ConsentEditorPage consentType="CONSENT_TYPE_SELLER_TERMS" />
        ),
      },
    ],
  },
]

const topNavbarHeight = 48

const LoggedInLayout = () => {
  return (
    <React.Fragment>
      <LeftNavbar routes={routes} />
      <div className="w-full bg-white">
        <TopNavbar />

        <div
          css={{
            minHeight: `calc(100vh - ${topNavbarHeight}px)`,
            backgroundColor: "white",
          }}
          className="bg-white p-8"
        >
          <ScrollToTop />
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  )
}

export const ProtectedRouteLayout: React.FC = observer(() => {
  const auth = useAuth()

  if (!auth.isLoggedIn) {
    return <LoginPage />
  }
  const containerWidth = css({ minWidth: "1200px" })

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<LoggedInLayout />}>
        {routes
          .map((p, idx) => {
            const rs = []
            if (p.route && p.route.path && p.route.component) {
              rs.push(
                <Route
                  key={idx}
                  path={p.route.path}
                  element={p.route.component}
                />,
              )
            }

            if (p.subRoutes) {
              p.subRoutes.forEach((sub, sIdx) => {
                if (sub.disabled) {
                  return (
                    <Route
                      key={idx}
                      path={sub.path}
                      index={sub.path === "/"}
                      element={<ComingSoonPage />}
                    />
                  )
                }
                if (sub.path && sub.component) {
                  rs.push(
                    <Route
                      key={`${idx}-${sIdx}`}
                      path={sub.path}
                      element={sub.component}
                    />,
                  )
                }
              })
            }

            if (p.route.provider) {
              return (
                <React.Fragment key={idx}>
                  {p.route.provider(rs)}
                </React.Fragment>
              )
            }

            return rs
          })
          .flat()}
      </Route>,
    ),
  )

  return (
    <div className="flex" css={containerWidth}>
      <GlobalNotificationToasts />
      {publicConfig.enableFlexibleHomepageManagement ? (
        <FlexibleHomeManagementProvider>
          <RouterProvider router={router} />
        </FlexibleHomeManagementProvider>
      ) : (
        <HomeManagementProvider>
          <FlexibleHomeManagementProvider>
            <RouterProvider router={router} />
          </FlexibleHomeManagementProvider>
        </HomeManagementProvider>
      )}
    </div>
  )
})
