import {
  EuiAccordion,
  EuiCheckbox,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFieldSearch,
  EuiHorizontalRule,
  EuiPanel,
  EuiText,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { isDefined, theme } from "@reeeed-mp/ui-common"
import React, { useCallback, useEffect, useMemo, useState } from "react"

type ObjectKeyCheckbox = { [key: string]: boolean }

type ConditionSelected = {
  title: string
  options: CategoryOption[]
  selectedConditionOptions: EuiComboBoxOptionOption<string>[]
  setSelectedConditionOptions: (s: EuiComboBoxOptionOption<string>[]) => void
  checkbox: ObjectKeyCheckbox
  setCheckbox: (s: ObjectKeyCheckbox) => void
  classNames?: string
}

type CategoryOption = {
  id: string
  name: string
  parentId?: string
  level: number
}

type CategorySelectProps = {
  onChange: (updatedChecked: Record<string, boolean>) => void
  checked?: Record<string, boolean>
  parentId?: string
  options: CategoryOption[]
}

export const CategorySelectSection = (props: ConditionSelected) => {
  const [currFilterOptions, setFilterCurrOptions] = useState(
    props.options || [],
  )
  const [searchText, setSearchText] = useState("")

  const handleCheckboxChange = (updatedCheckbox: ObjectKeyCheckbox) => {
    props.setCheckbox(updatedCheckbox)

    const newSelectedOptions = props.options
      .filter((option) => updatedCheckbox[option.id])
      .map((option) => ({
        label: option.name,
        value: option.id,
      }))

    props.setSelectedConditionOptions(newSelectedOptions)
  }

  return (
    <div className={props.classNames}>
      <EuiText size="s" className="font-bold">
        {props.title}
      </EuiText>
      <EuiComboBox
        css={css`
          &.euiComboBox {
            max-width: inherit;
          }
          .euiFormControlLayout {
            max-width: inherit;
          }
          .euiComboBox__inputWrap {
            max-width: inherit;
          }
          .euiFormControlLayoutClearButton {
            background-color: ${theme.colors.LIGHT.midGrey};
          }
          .euiBadge {
            background-color: #e0e5ee;
          }
        `}
        selectedOptions={props.selectedConditionOptions}
        isClearable
        noSuggestions
        onChange={(selectedOptions) => {
          props.setSelectedConditionOptions(selectedOptions)

          const updatedCheckbox: ObjectKeyCheckbox = {}
          for (const selected of selectedOptions) {
            if (selected.value) {
              updatedCheckbox[selected.value] = true
            }
          }
          props.setCheckbox(updatedCheckbox)
        }}
      />

      <div
        className={`mt-4 max-h-96 w-full max-w-full overflow-scroll rounded-md p-8`}
        css={css`
          border: solid 2px ${theme.colors.LIGHT.lightGrey};

          .euiFormControlLayout {
            max-width: inherit !important;
          }
          .euiFieldSearch {
            max-width: inherit !important;
          }
        `}
      >
        <EuiFieldSearch
          className="max-w-full"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value)
            if (e.target.value !== "") {
              const regexFilter = new RegExp(`^.*${e.target.value}.*`, "i")

              const matchedOptions = props.options.filter((option) =>
                regexFilter.test(option.name),
              )

              const parentOptions = matchedOptions
                .map((option) => {
                  if (option.parentId) {
                    return props.options.find((o) => o.id === option.parentId)!
                  }
                  return option
                })
                .filter(isDefined)

              const uniqueOptions = Array.from(
                new Set([...parentOptions, ...matchedOptions]),
              )

              setFilterCurrOptions(uniqueOptions)
            } else {
              setFilterCurrOptions(props.options)
            }
          }}
          isClearable={true}
        />

        <div className="my-4">
          <EuiHorizontalRule />
        </div>

        <div className="flex flex-col gap-2">
          <CategorySelect
            options={currFilterOptions.map((c) => ({
              id: c.id!,
              label: c.name!,
              name: c.name!,
              level: c.level!,
              parentId: c.parentId!,
            }))}
            checked={props.checkbox}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </div>
  )
}

const CategorySelect = React.memo(
  ({ onChange, checked = {}, parentId, options }: CategorySelectProps) => {
    const [openCategories, setOpenCategories] = useState<
      Record<string, boolean>
    >({})
    const [localChecked, setLocalChecked] =
      useState<Record<string, boolean>>(checked)

    useEffect(() => {
      setLocalChecked(checked)
    }, [checked])

    const toggleCategory = useCallback((id: string) => {
      setOpenCategories((prev) => ({
        ...prev,
        [id]: !prev[id],
      }))
    }, [])

    const getAllChildren = useCallback(
      (parentId: string): string[] => {
        const directChildren = options
          .filter((o) => o.parentId === parentId)
          .map((o) => o.id)

        return directChildren.reduce(
          (acc, childId) => [...acc, childId, ...getAllChildren(childId)],
          directChildren,
        )
      },
      [options],
    )

    const handleCheckboxChange = useCallback(
      (id: string, isChecked: boolean) => {
        const updatedChecked = { ...localChecked }
        const children = getAllChildren(id)

        updatedChecked[id] = isChecked
        children.forEach((childId) => {
          updatedChecked[childId] = isChecked
        })

        setLocalChecked(updatedChecked)
        onChange(updatedChecked)
      },
      [localChecked, getAllChildren, onChange],
    )

    const optionAtLevel = useMemo(() => {
      return options.filter((o) => o.parentId === parentId)
    }, [options, parentId])

    return (
      <>
        {optionAtLevel.map((option) => {
          const isOpen = openCategories[option.id] || false
          const children = options.filter((o) => o.parentId === option.id)

          const CheckboxPanel = () => {
            return (
              <div className="flex justify-between">
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <EuiCheckbox
                    css={{ "& .euiCheckbox__label": { fontWeight: 700 } }}
                    label={option.name}
                    checked={localChecked[option.id] || false}
                    id={`${option.id}-checkbox`}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      handleCheckboxChange(option.id, e.target.checked)
                    }
                  />
                </div>
              </div>
            )
          }

          if (children.length === 0) {
            return (
              <EuiPanel hasBorder key={option.id}>
                {CheckboxPanel()}
              </EuiPanel>
            )
          }

          return (
            <EuiPanel hasBorder key={option.id}>
              <EuiAccordion
                id={option.id}
                forceState={isOpen ? "open" : "closed"}
                onToggle={() => toggleCategory(option.id)}
                css={{
                  "& .euiAccordion__buttonContent": { width: "100%" },
                }}
                buttonElement="div"
                buttonContent={CheckboxPanel()}
                arrowDisplay="right"
              >
                {isOpen && children.length > 0 && (
                  <div className="flex flex-col gap-2 py-4">
                    <CategorySelect
                      onChange={onChange}
                      options={options}
                      checked={localChecked}
                      parentId={option.id}
                    />
                  </div>
                )}
              </EuiAccordion>
            </EuiPanel>
          )
        })}
      </>
    )
  },
)
