import { useStore } from "@app/hook/store"
import { useNavigate } from "react-router-dom"
import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useCallback } from "react"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import * as Yup from "yup"
import { smallBannerPath } from "../../hook/path"
import { useTranslation } from "react-i18next"

type BannerFormProps = definitions["webutility.v1.SmallBannerInput"] & {
  fileInput?: string
}

const validationSchema = Yup.object({
  title: Yup.string().required(),
  link: Yup.string().required(),
  metaTag: Yup.string().required(),
  cover: Yup.string().notRequired(),
  fileInput: Yup.string().notRequired(),
})

export const useFormSmallBanner = (sectionKey: string, bannerKey: string) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { webUtility } = useApi()
  const { t } = useTranslation()

  const createSmallBannerApi = webUtility("/admin/small-banner")
    .method("post")
    .create()

  const getSmallBannerApi = webUtility("/admin/small-banner/{bannerKey}")
    .method("get")
    .create()

  const updateSmallBannerApi = webUtility("/admin/small-banner/{bannerKey}")
    .method("put")
    .create()

  const onSubmit = useCallback(
    async (
      input: BannerFormProps,
    ): Promise<{ bannerKey: string | undefined }> => {
      if (bannerKey !== "create") {
        try {
          await updateSmallBannerApi({
            bannerKey,
            sectionKey,
            input: {
              metaTag: input.metaTag,
              cover: input.fileInput,
              link: input.link,
              title: input.title,
            },
          })
          return { bannerKey: undefined }
        } catch (error) {
          throw errToDefaultI18nKey(error)
        }
      }

      if (input.fileInput === undefined) {
        throw new Error(t("home-management.error.banner-cover-missing"))
      }

      const resp = await createSmallBannerApi({
        sectionKey,
        input: {
          metaTag: input.metaTag,
          cover: input.fileInput,
          link: input.link,
          title: input.title,
        },
      })
      return { bannerKey: resp.data.bannerKey }
    },
    [updateSmallBannerApi, createSmallBannerApi, bannerKey, sectionKey],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(smallBannerPath(sectionKey))
  }

  const onGetItem = async (): Promise<BannerFormProps> => {
    if (bannerKey !== "create") {
      const resp = await getSmallBannerApi({
        bannerKey,
        sectionKey,
      })
      const data =
        resp.data as definitions["webutility.admin.v1.GetSmallBannerResponse"]
      return {
        cover: data.banner!.cover,
        link: data.banner!.link,
        title: data.banner!.title,
        metaTag: data.banner!.metaTag,
      }
    }
    return {}
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t(errToDefaultI18nKey(err)),
      })
    }
  }

  const form = useFormHelper<BannerFormProps, { bannerKey?: string }>({
    id: bannerKey,
    validationSchema,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}
