import { useStore } from "@app/hook/store"
import { useNavigate } from "react-router-dom"
import { useApi } from "@app/hook/api"
import { definitions } from "@app/vendor/web-utility-specs/web_utility_service"
import { useCallback } from "react"
import { errToDefaultI18nKey, useFormHelper } from "@reeeed-mp/ui-common"
import * as Yup from "yup"
import { writerBannerPath } from "../../hook/path"
import { useTranslation } from "react-i18next"

type BannerFormProps = definitions["webutility.v1.WriterBannerInput"] & {
  fileInput?: string
}

const validationSchema = Yup.object({
  authorName: Yup.string().required(),
  link: Yup.string().required(),
  introduction: Yup.string().required(),
  numberOfWorks: Yup.number().required(),
  cover: Yup.string().notRequired(),
  fileInput: Yup.string().notRequired(),
})

export const useFormWriterBanner = (sectionKey: string, bannerKey: string) => {
  const { notificationStore } = useStore()
  const navigate = useNavigate()
  const { webUtility } = useApi()
  const { t } = useTranslation()
  const createWriterBannerApi = webUtility("/admin/writer-banner")
    .method("post")
    .create()

  const getWriterBannerApi = webUtility("/admin/writer-banner/{bannerKey}")
    .method("get")
    .create()

  const updateWriterBannerApi = webUtility("/admin/writer-banner/{bannerKey}")
    .method("put")
    .create()

  const onSubmit = useCallback(
    async (
      input: BannerFormProps,
    ): Promise<{ bannerKey: string | undefined }> => {
      if (bannerKey !== "create") {
        try {
          await updateWriterBannerApi({
            bannerKey,
            sectionKey,
            input: {
              numberOfWorks: input.numberOfWorks,
              introduction: input.introduction,
              authorName: input.authorName,
              cover: input.fileInput,
              link: input.link,
            },
          })
          return { bannerKey: undefined }
        } catch (error) {
          throw errToDefaultI18nKey(error)
        }
      }

      if (input.fileInput === undefined) {
        throw new Error(t("home-management.error.banner-cover-missing"))
      }

      const resp = await createWriterBannerApi({
        sectionKey,
        input: {
          numberOfWorks: input.numberOfWorks,
          introduction: input.introduction,
          authorName: input.authorName,
          cover: input.fileInput,
          link: input.link,
        },
      })
      return { bannerKey: resp.data.bannerKey }
    },
    [createWriterBannerApi, updateWriterBannerApi, bannerKey, sectionKey],
  )

  const onSubmitted = () => {
    notificationStore.add({
      title: "Success",
      color: "success",
    })

    navigate(writerBannerPath(sectionKey))
  }

  const onGetItem = async (): Promise<BannerFormProps> => {
    if (bannerKey !== "create") {
      const resp = await getWriterBannerApi({
        bannerKey,
        sectionKey,
      })
      const data =
        resp.data as definitions["webutility.admin.v1.GetWriterBannerResponse"]
      return {
        cover: data.banner!.cover,
        link: data.banner!.link,
        numberOfWorks: data.banner!.numberOfWorks,
        introduction: data.banner!.introduction,
        authorName: data.banner!.authorName,
      }
    }
    return {}
  }

  const onError = (err: any) => {
    if (err instanceof Error) {
      notificationStore.add({
        title: "Error",
        color: "danger",
        text: t(errToDefaultI18nKey(err)),
      })
    }
  }

  const form = useFormHelper<BannerFormProps, { bannerKey?: string }>({
    id: bannerKey,
    validationSchema,
    onSubmit,
    onSubmitted,
    onGetItem,
    onError,
  })

  return form
}
