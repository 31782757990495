import React from "react"
import { NavigationHeaderButton } from "@app/component"
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFormRow,
  EuiTextArea,
} from "@elastic/eui"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { contentType } from "@app/hook/file"
import { ImagePicker } from "../main-menu/image-picker"
import { useFormWriterBanner } from "./hook"

export const WriterBannerEditorPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { bannerKey, sectionKey } = useParams()
  const formControl = useFormWriterBanner(sectionKey!, bannerKey!)

  const navigateBack = () => {
    navigate(-1)
  }
  return (
    <div className="w-full max-w-screen-sm">
      <NavigationHeaderButton
        title={
          bannerKey === "create"
            ? t("home-management.writer.add-writer")
            : t("home-management.writer.edit-writer")
        }
        onClick={navigateBack}
      />
      <div className="mt-6 flex flex-col gap-y-8">
        <EuiFormRow label={t("common.name")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.authorName}
            maxLength={25}
            onChange={(e) =>
              formControl.setFieldValue("authorName", e.target.value)
            }
            isInvalid={!!formControl.errors.authorName}
          />
        </EuiFormRow>
        <div className="flex items-center gap-x-4">
          <ImagePicker
            value={formControl.values.cover}
            error={undefined}
            setLoading={formControl.setSubmitting}
            onChange={async (input, blob) => {
              formControl.setValues({
                ...formControl.values,
                cover: blob,
                fileInput: input.tmpPath,
              })
            }}
            acceptType={`${contentType.JPG},${contentType.PNG}`}
            placeholder={
              <div className="flex flex-col text-center">
                <div>
                  <p>{t("home-management.writer.banner")}</p>
                </div>
                <div>
                  <p className="whitespace-pre-wrap text-center text-sm">
                    {t("home-management.cover-suggestion", {
                      size: "240x240 px",
                      type: ".jpg, .png",
                    })}
                  </p>
                </div>
              </div>
            }
            width="220"
            height="220"
          />
        </div>

        <EuiFormRow label={t("home-management.writer.number-of-work")}>
          <>
            <EuiFieldText
              placeholder={t("common.please-fill")}
              value={formControl.values.numberOfWorks}
              maxLength={25}
              onChange={(e) =>
                formControl.setFieldValue("numberOfWorks", e.target.value)
              }
              isInvalid={!!formControl.errors.numberOfWorks}
            />
            {!!formControl.errors.numberOfWorks && (
              <p className="mt-2 text-sm text-ci-red">
                {t("home-management.error.number-of-works")}
              </p>
            )}
          </>
        </EuiFormRow>

        <EuiFormRow className="" label={t("common.link")}>
          <EuiFieldText
            placeholder={t("common.please-fill")}
            value={formControl.values.link}
            onChange={(e) => formControl.setFieldValue("link", e.target.value)}
            isInvalid={!!formControl.errors.link}
          />
        </EuiFormRow>

        <EuiFormRow
          className="mt-6"
          isInvalid={!!formControl.errors.introduction}
          label={t("home-management.writer.introduction")}
        >
          <EuiTextArea
            placeholder={t("common.please-fill")}
            isInvalid={!!formControl.errors.introduction}
            value={formControl?.values.introduction ?? ""}
            onChange={(e) => {
              formControl.setFieldValue("introduction", e.target.value)
            }}
            css={{
              fontFamily: "inherit",
            }}
          />
        </EuiFormRow>
      </div>
      <div className="mt-10 flex flex-row">
        <EuiButton
          fill
          fullWidth
          onClick={formControl.submitForm}
          disabled={formControl.isSubmitting}
        >
          {t("common.save")}
        </EuiButton>
        <EuiButtonEmpty
          className="ml-4 w-full items-center"
          color="text"
          onClick={navigateBack}
        >
          {t("common.cancel")}
        </EuiButtonEmpty>
      </div>
    </div>
  )
}
