import { DownloadReportForm } from "@app/component"
import { Title } from "@app/component/title"
import { useStreamApi } from "@app/hook/api"
import { useDownloadReport } from "@app/hook/file"
import { useStore } from "@app/hook/store"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const SellerRevenueReport: React.FC = () => {
  const { t } = useTranslation()
  const { notificationStore: notification } = useStore()
  const { webBFF } = useStreamApi()
  const { download, loadingPercentage, error } = useDownloadReport(
    webBFF("/admin/report/sale").method("post").create(),
    { autoDownload: true },
  )

  useEffect(() => {
    if (error) {
      notification.add({
        color: "danger",
        title: t("common.noti.error"),
        text: error.message,
      })
    }
  }, [error, notification, t])

  return (
    <>
      <Title text={t("nav.seller-revenue")} />
      <DownloadReportForm
        onSubmit={(p) => {
          download({
            filter: {
              timeRange: {
                from: p.startDate?.startOf("day").toISOString(),
                to: p.endDate?.endOf("day").toISOString(),
              },
            },
          })
        }}
        loadingPercentage={loadingPercentage}
      />
    </>
  )
}
