import React from "react"
import { HTMLInputTypeAttribute } from "react"

import "./text-input.css"

type Props = Readonly<{
  label: string
  onChange: (val: string) => void
  placeholder?: string
  value: string
  type?: HTMLInputTypeAttribute
}>

export const TextInput: React.FC<Props> = ({
  label,
  value,
  onChange,
  placeholder = "",
  type = "text",
}) => {
  return (
    <div className="mpe-input-wrapper">
      {label !== "" && <label className="mpe-input-label">{label}</label>}
      <input
        type={type}
        className="mpe-input-input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </div>
  )
}
