import { ConfirmDeleteModal, NavigationHeaderButton } from "@app/component"
import { publicConfig } from "@app/config"
import {
  CriteriaWithPagination,
  EuiBadge,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiHorizontalRule,
  EuiText,
  EuiToolTip,
  Pagination,
} from "@elastic/eui"
import { css } from "@emotion/react"
import { UilCopy, UilPen, UilTrash } from "@iconscout/react-unicons"
import {
  MpTextEditor,
  parseEditorState,
  stringifiedEmptyEditorState,
} from "@reeeed-mp/text-editor"
import { theme } from "@reeeed-mp/ui-common"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
  toProductTypeInput,
  useDeleteLandingPage,
  useGetLandingPageProductsSWR,
  useGetLandingPageSWR,
} from "./hooks"
import { ExtendProduct, LandingPageProduct, Product } from "./type"

const toProductItem = (exProduct: ExtendProduct): LandingPageProduct => {
  if (!exProduct.product || !exProduct.shop) {
    return {
      id: "",
      name: "",
      type: "",
      shopName: "",
    }
  }

  const key = Object.keys(exProduct.product as Product)[0] as keyof Product
  const p = (exProduct?.product as Product)[key]
  return {
    id: p?.id || "",
    name: p?.name || "",
    type: toProductTypeInput(key),
    shopName: exProduct.shop?.name || "",
  }
}

const StatusBadge: React.FC<{ isActive?: boolean }> = ({ isActive }) => {
  const { t } = useTranslation()
  const renderStatus = isActive
    ? { className: "bg-green", text: "landing-page.detail.activate" }
    : { className: "bg-red", text: "landing-page.detail.deactivate" }

  return (
    <div className="w-full">
      <EuiBadge className={renderStatus!.className}>
        <EuiText size="xs">{t(renderStatus!.text)}</EuiText>
      </EuiBadge>
    </div>
  )
}

export const DetailLandingPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const [openConfrimDelete, setOpenConfirmDelete] = useState(false)
  const { data = {} } = useGetLandingPageSWR(params.id || "")
  const { data: landinPageProducts } = useGetLandingPageProductsSWR(
    data.landingPage?.products,
  )
  const removeLandingPage = useDeleteLandingPage()

  const pageSize = 60
  const productToItems = (start: number, end: number) =>
    landinPageProducts?.products?.slice(start, end).map(toProductItem) || []
  const [items, setItems] = useState<LandingPageProduct[]>([])

  const tableColumns: EuiBasicTableColumn<LandingPageProduct>[] = [
    {
      field: "id",
      name: (
        <EuiText>
          <h4>{t("landing-page.header-table.id")}</h4>
        </EuiText>
      ),
    },
    {
      field: "name",
      name: (
        <EuiText>
          <h4>{t("landing-page.header-table.product-name")}</h4>
        </EuiText>
      ),
    },
    {
      field: "type",
      name: (
        <EuiText>
          <h4>{t("landing-page.header-table.type")}</h4>
        </EuiText>
      ),
    },
    {
      field: "shopName",
      name: (
        <EuiText>
          <h4>{t("landing-page.header-table.shopName")}</h4>
        </EuiText>
      ),
    },
  ]

  const paginateHandler = ([pagination, setPagination]: [
    Pagination,
    React.Dispatch<React.SetStateAction<Pagination>>,
  ]): {
    pagination: Pagination
    setPagination: React.Dispatch<React.SetStateAction<Pagination>>
    onChangePagination:
      | ((criteria: CriteriaWithPagination<LandingPageProduct>) => void)
      | undefined
  } => ({
    pagination,
    setPagination,
    onChangePagination: async (p: {
      page: {
        index: number
        size: number
      }
    }) => {
      setPagination((prev) => ({ ...prev, pageIndex: p.page.index }))
      const start = p.page.index * p.page.size
      const end = start + p.page.size
      setItems(productToItems(start, end))
    },
  })

  const { pagination, setPagination, onChangePagination } = paginateHandler(
    useState<Pagination>({
      pageIndex: 0,
      pageSize,
      totalItemCount: data.landingPage?.products?.length || 0,
      showPerPageOptions: false,
    }),
  )

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize,
      totalItemCount: data.landingPage?.products?.length || 0,
      showPerPageOptions: false,
    })
    setItems(
      landinPageProducts?.products?.slice(0, pageSize).map(toProductItem) || [],
    )
  }, [
    data.landingPage?.products?.length,
    setPagination,
    landinPageProducts?.products,
  ])

  const copyURL = () => {
    navigator.clipboard.writeText(
      `${publicConfig.webBaseUri}/pages/${data.landingPage?.slug}`,
    )
  }

  return (
    <>
      <div className="w-full">
        <div className="mb-9 flex flex-row justify-between">
          <NavigationHeaderButton
            title={data.landingPage?.title || ""}
            onClick={() => {
              navigate(-1)
            }}
          />
          <div className="flex flex-row">
            <EuiButtonEmpty
              className="ml-4"
              color="danger"
              onClick={() => {
                setOpenConfirmDelete(true)
              }}
            >
              <div className="flex">
                <div>
                  <UilTrash
                    height="16px"
                    width="16px"
                    className="mr-2"
                    style={{ marginTop: "2px" }}
                  />
                </div>
                <div>
                  <span>{t("common.delete")}</span>
                </div>
              </div>
            </EuiButtonEmpty>

            <EuiButton
              className={"ml-2"}
              style={{
                width: "200px",
                backgroundColor: theme.colors.LIGHT.lightGrey,
                color: theme.colors.LIGHT.darkGrey,
              }}
              color="primary"
              fill
              onClick={() => {
                navigate(`../../${params.id}`, { relative: "path" })
              }}
            >
              <div className="flex place-items-center">
                <UilPen size={"16"} className={"mr-1"}></UilPen>
                {t("common.edit")}
              </div>
            </EuiButton>
          </div>
        </div>

        <div className="mb-9 flex flex-col gap-y-8">
          <StatusBadge isActive={data.landingPage?.isActive} />

          <EuiFieldText
            onClick={copyURL}
            readOnly
            append={
              <EuiToolTip content="Copy URL">
                <EuiButton
                  onClick={copyURL}
                  className="flex gap-x-2 bg-gray-200 text-ci-grey"
                >
                  <UilCopy size={"16"} />
                  <p className="text-sm">{t("landing-page.copy-url")}</p>
                </EuiButton>
              </EuiToolTip>
            }
            value={`${publicConfig.webBaseUri}/pages/${data.landingPage?.slug}`}
            className="cursor-pointer border-none bg-ci-grey-light shadow-none outline-0"
          />

          <img
            className="w-full rounded-2xl"
            height={348}
            src={data.landingPage?.image || ""}
            alt={data.landingPage?.image || ""}
          />

          <EuiHorizontalRule />

          <EuiText className="mb-2">
            <h3>{t("landing-page.editor.detail")}</h3>
          </EuiText>
          <MpTextEditor
            isEditable={false}
            initEditorState={parseEditorState(
              data.landingPage?.detail
                ? data.landingPage?.detail
                : stringifiedEmptyEditorState,
            )}
          />

          <EuiHorizontalRule />

          {items && (
            <>
              <div className="ml-1 flex flex-col gap-y-4 font-bold">
                <h1 className="text-2xl">
                  {t("landing-page.detail.product-list")}
                </h1>
                <p className="text-sm">
                  {pagination.totalItemCount} {t("landing-page.list-sum")}
                </p>
              </div>
              <div
                className="border border-solid border-table"
                css={css`
                  background-color: white;
                  padding: 16px;
                  border-radius: 8px;
                `}
              >
                {pagination && onChangePagination ? (
                  <EuiBasicTable
                    css={css`
                      .euiPagination__list {
                        margin: 0;
                      }

                      .euiPagination__item {
                        list-style: none;
                      }

                      &.clickable {
                        .euiTableRowCell {
                          cursor: pointer;
                        }
                      }
                    `}
                    items={items}
                    responsive={false}
                    columns={tableColumns}
                    pagination={pagination}
                    onChange={onChangePagination}
                  />
                ) : (
                  <EuiBasicTable
                    css={css`
                      .euiPagination__list {
                        margin: 0;
                      }

                      .euiPagination__item {
                        list-style: none;
                      }

                      &.clickable {
                        .euiTableRowCell {
                          cursor: pointer;
                        }
                      }
                    `}
                    items={items}
                    responsive={false}
                    columns={tableColumns}
                  />
                )}
              </div>
            </>
          )}

          <EuiHorizontalRule />

          <EuiText className="mb-2">
            <h3>{t("landing-page.editor.seo-text")}</h3>
          </EuiText>
          <MpTextEditor
            isEditable={false}
            initEditorState={parseEditorState(
              data.landingPage?.seoText
                ? data.landingPage?.seoText
                : stringifiedEmptyEditorState,
            )}
          />
        </div>
      </div>
      {openConfrimDelete && (
        <ConfirmDeleteModal
          description=""
          onClose={() => setOpenConfirmDelete(false)}
          onConfirm={async () => {
            if (data.landingPage?.id) {
              removeLandingPage(data.landingPage?.id)
            }
            setOpenConfirmDelete(false)
            navigate(-1)
          }}
        />
      )}
    </>
  )
}
